import {Injectable, Inject, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse, HttpEvent} from '@angular/common/http';
import {CustomErrorHandler} from './error.handler';
import {Observable, Subject} from 'rxjs';
import {InContactSdkConfig} from '../sdk.config';
import {FacLocalStorageService} from 'src/app/sdk/storage/fac-local-storage.service';
import {map, filter, delay, catchError} from 'rxjs/operators';
import {HttpParameterCodec} from '@angular/common/http';

/*
    Angular will not URL Encode +, default to custom encoder that utilizes (de|en)codeURIComponent
*/
class CustomHttpParameterCodec implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }
    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }
    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}
@Injectable()
export class BaseApi {
    protected path: string;
    protected model: any;

    constructor(
        @Inject(HttpClient) protected httpClient: HttpClient,
        @Inject(FacLocalStorageService) protected facLocalStorageSrv: FacLocalStorageService,
        @Optional() @Inject(CustomErrorHandler) protected errorHandler: CustomErrorHandler,
    ) {}

    public get resource_server_base_uri() {
        return this.facLocalStorageSrv.resource_server_base_uri;
    }

    private processRouteParams(url, routeParams: any = {}) {
        Object.keys(routeParams).forEach((key: string) => {
            url = url.replace(new RegExp(':' + key + '(/|$)', 'g'), routeParams[key] + '$1');
        });
        return url;
    }

    public GET_Request<T>(url: string, routeParams: any = {}, urlParams: any = {}, customHeaders?: Function) {
        return this.request<T>('GET', url, routeParams, urlParams, {}, customHeaders);
    }

    public POST_Request<T>(url: string, routeParams: any = {}, urlParams: any = {}, body: any = {}, customHeaders?: Function) {
        return this.request<T>('POST', url, routeParams, urlParams, body, customHeaders);
    }

    public PUT_Request<T>(url: string, routeParams: any = {}, urlParams: any = {}, body: any = {}, customHeaders?: Function) {
        return this.request<T>('PUT', url, routeParams, urlParams, body, customHeaders);
    }

    public PATCH_Request<T>(url: string, routeParams: any = {}, urlParams: any = {}, body: any = {}, customHeaders?: Function) {
        return this.request<T>('PATCH', url, routeParams, urlParams, body, customHeaders);
    }

    public DELETE_Request<T>(url: string, routeParams: any = {}, urlParams: any = {}, customHeaders?: Function) {
        return this.request<T>('DELETE', url, routeParams, urlParams, {}, customHeaders);
    }

    public request<T>(method: string, url: string, routeParams: any = {}, urlParams: any = {}, postBody: any = {}, customHeaders?: Function): Observable<T> {
        routeParams = routeParams ?? {};
        urlParams = urlParams ?? {};
        postBody = postBody ?? {};
        // Transpile route variables to the actual request Values
        url = this.processRouteParams(url, routeParams);

        let httpParams = new HttpParams({encoder: new CustomHttpParameterCodec()});

        // Headers to be sent
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');

        // Authenticate request
        headers = this.authenticate(headers);

        // let body: any;

        // const postBodyKeys = typeof postBody === 'object' ? Object.keys(postBody) : [];
        // if (postBodyKeys.length === 1) {
        //     body = postBody[postBodyKeys.shift()];
        // } else {
        //     body = postBody;
        // }

        if (typeof customHeaders === 'function') {
            headers = customHeaders(headers);
        }

        Object.keys(urlParams).forEach(paramKey => {
            let paramValue = urlParams[paramKey];
            paramValue = typeof paramValue === 'object' ? JSON.stringify(paramValue) : paramValue;
            httpParams = httpParams.append(paramKey, paramValue);
        });

        let responseType: any = 'json';
        if (headers.get('Accept').includes('text/plain')) {
            responseType = 'blob';
        }

        const request = new HttpRequest(method as any, url, postBody, {
            headers,
            responseType,
            params: httpParams,
        });

        return this.httpClient.request<T>(request).pipe(
            filter(event => event instanceof HttpResponse),
            delay(0),
            map<HttpResponse<T>, T>((res: HttpResponse<T>) => res.body),
            catchError<any, any>((e: any) => this.errorHandler.handleError(e)),
        );
    }

    public authenticate<T>(headers: HttpHeaders): HttpHeaders {
        headers = headers.append('Authorization', `${InContactSdkConfig.AuthPrefix}${this.facLocalStorageSrv.access_token}`);

        return headers;
    }
}
