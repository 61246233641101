import {Component, OnInit, HostListener, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {AddressBookService} from './address-book.service';
import {HotKeyPressSerivce} from '../.././core/_services/hot-key-press.service';

import {AddressBookModel, Campaign, Agent, Team} from '../.././core/_models/address-book.model';
import {AddressBookEntriesModel} from '../.././core/_models/address-book-entries.model';
import {AgentSkillAssignmentsModel} from '../.././core/InContact/models/agent-skill-assignments.model';
import {FocusDirective} from '../../shared/directives/focus.directive';

@Component({
    selector: 'app-address-book',
    templateUrl: './address-book.component.html',
    styleUrls: ['./address-book.component.css'],
})
export class AddressBookComponent implements OnInit {
    public options: options[];
    public TypeFilter: string;
    public keyword: string;
    public addressBooks: AddressBookModel[];
    agentSkills: AgentSkillAssignmentsModel[];
    selectedAddressBookEntry = new AddressBookEntriesModel();
    selectedOption: options;
    selectedStatus = 'All';

    public dropdownFocus = new EventEmitter<boolean>(false);
    public listFocus = false;
    public selectedOptionParam: string;

    // @ViewChild('listItem') listItem:ElementRef;

    constructor(
        private agentService: IcAgentSessionService,
        private route: ActivatedRoute,
        private addressBookService: AddressBookService,
        private hotKeyPressSerivce: HotKeyPressSerivce,
    ) {
        //this.TypeFilter = 'agents_directory';
    }

    ngOnInit() {
        this.options = [];
        this.options.push(new options(-1, 'Agents'));
        this.options.push(new options(-2, 'Skills'));
        this.selectedOption = this.options[0];
        this.addressBooks = [];

        const teamId = this.agentService.getAuthContext().team_id;
        const agentId = this.agentService.getSessionInfo().AgentId;

        this.route.params.subscribe(params => {
            if (params.selectedOption !== undefined) {
                this.selectedOptionParam = params.selectedOption;

                if (params.selectedOption !== 'Address-Book') {
                    this.selectedOption = this.options[
                        this.options.findIndex(e => e.optionName === params.selectedOption)
                    ];
                } else if (this.options.length > 2) {
                    this.selectedOption = this.options[2];
                }
            }
        });

        this.agentService.agentSkillAssignments.subscribe(skills => {
            this.agentSkills = skills;

            this.addressBookService.getAddressBook(agentId).subscribe(addBooks => {
                addBooks.forEach(addBookElement => {
                    //if (addBookElement.campaigns === undefined && addBookElement.skills === undefined && addBookElement.teams === undefined && addBookElement.agents === undefined)
                    if (this.filterAddressBook(addBookElement)) {
                        this.options.push(
                            new options(
                                addBookElement.addressBookId,
                                addBookElement.addressBookName,
                                addBookElement.addressBookType,
                            ),
                        );
                    }

                    if (this.selectedOptionParam === 'Address-Book' && this.options.length > 2) {
                        this.selectedOption = this.options[2];
                    }
                });

                //this.filterAddressBook(addBookElement);
            });
        });
    }

    filterAddressBook(addBookElement: AddressBookModel): boolean {
        return true;

        /* var retVal = false;
    this.agentSkills.forEach(skill => {

      if (addBookElement.skills) {
        for (let i of addBookElement.skills) {
          if (i.skillId === skill.skillId) {
            //this.options.push(new options(addBookElement.addressBookId, addBookElement.addressBookName));
            //return true;
            retVal = true;
            break;
          }
        }
      }

      if (addBookElement.campaigns) {
        for (let i of addBookElement.campaigns) {
          if (i.campaignId === skill.campaignId) {
            //this.options.push(new options(addBookElement.addressBookId, addBookElement.addressBookName));
            //return true;
            retVal = true;
            break;
          }
        }
      }

      if (addBookElement.agents) {
        for (let i of addBookElement.agents) {
          if (i.agentId === skill.agentId) {
            //this.options.push(new options(addBookElement.addressBookId, addBookElement.addressBookName));
            //return true;
            retVal = true;
            break;
          }
        }
      }

      if (addBookElement.teams) {
        for (let i of addBookElement.teams) {
          if (i.teamId === skill.teamId) {
            //this.options.push(new options(addBookElement.addressBookId, addBookElement.addressBookName));
            //return true;
            retVal = true;
            break;
          }
        }
      }
    });
    return retVal; */
    }

    onChange(event) {
        this.selectedOption = event;
    }

    onStatusChange(value: string) {
        this.selectedStatus = value;
    }

    @HostListener('window:keydown', ['$event'])
    hotkeys(event: KeyboardEvent) {
        const key = event.keyCode;
        if (this.hotKeyPressSerivce.altPress(event)) {
            switch (key) {
                case 65:
                    this.dropdownFocus.emit(true);
                    event.preventDefault();
                    break;
            }
        }
    }
}

export class options {
    optionId: number;
    optionName: string;
    type?: string;
    constructor(id: number, value: string, type?: string) {
        this.optionId = id;
        this.optionName = value;
        this.type = type;
    }
}
