import {Component, Inject, OnInit} from '@angular/core';
import {DialogRef} from 'ngx-modialog-7';
import {ApplicationContextService} from 'src/app/core/_services/application-context.service';
import {CRM_APPLICATION_TYPES} from 'src/app/global';

@Component({
    selector: 'app-search-zendesk-users-dialog',
    templateUrl: './search-zendesk-users-dialog.component.html',
    styleUrls: ['./search-zendesk-users-dialog.component.scss'],
})
export class SearchZendeskUsersDialogComponent implements OnInit {
    public text: string | null = null;

    public selectedUser: number | null = null;

    public constructor(public dialog: DialogRef<any>, @Inject(ApplicationContextService) public appContext: ApplicationContextService) {
        //
    }

    ngOnInit(): void {
        //
    }

    public get CRM_APPLICATION_TYPES() {
        return CRM_APPLICATION_TYPES;
    }

    public listItemClick(event: any) {
        this.appContext.crmIntegrationService.onManualUserSelection(this.appContext.crmIntegrationService?.contactVM, event.value);
    }

    public search() {
        this.appContext.crmIntegrationService.customCrmUsersSearch(this.text).subscribe(res => {
            console.log(res);
        });
    }

    public createTicket() {
        this.appContext.crmIntegrationService.createCrmTicketManuallay();
        this.selectedUser = null;
        this.dialog.close();
    }
}
