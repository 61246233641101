import { Injectable, ViewChild, ElementRef, Directive } from '@angular/core';

@Directive()
@Injectable()
export class HotKeyPressSerivce {
    @ViewChild('listItem') listItem: ElementRef;
    // public listFocus = new EventEmitter<boolean>(false);

    ctrlShiftPress(event: KeyboardEvent) {
        let key;
        let isShift;
        if (window.event) {
            key = event.keyCode;
            isShift = !!event.shiftKey; // typecast to boolean
        } else {
            key = event.which;
            isShift = !!event.shiftKey;
        }
        if (isShift && event.ctrlKey) {
            switch (key) {
                case 16: // ignore shift key
                    break;
                default:
                    return true;
            }
        }
    }

    altPress(event: KeyboardEvent) {
        let key;
        let isAlt;
        if (window.event) {
            key = event.keyCode;
            isAlt = !!event.altKey;
        } else {
            key = event.which;
            isAlt = !!event.altKey;
        }

        if (isAlt) {
            switch (key) {
                case 18:
                    break;
                default:
                    event.preventDefault();
                    return true;
            }
        }
    }
}
