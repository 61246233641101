import {Component, OnInit, HostListener, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CallbackService} from '../../core/_services/callbacks.service';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';

@Component({
    selector: 'app-scheduled-callbacks',
    templateUrl: './scheduled-callbacks.component.html',
    //styleUrls: ['./address-book.component.css'],
})
export class ScheduledCallbacksComponent implements OnInit {
    skills: any[] = [];
    callbacks: any[] = [];
    constructor(private agentSessionEventsService: IcAgentSessionService, private callbackService: CallbackService) {
        callbackService.callbacks.subscribe((r: any[]) => {
            this.callbacks = r.filter(cb => cb.target == 'A');
        });
    }
    ngOnInit() {
        this.skills = this.agentSessionEventsService.agentOutboundSkills.value;
    }

    getSkillName(skillId) {
        const skill = this.skills.find(obj => obj.skillId == skillId);
        if (skill) {
            return skill.skillName;
        } else {
            return '';
        }
    }
}
