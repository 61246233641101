import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppDefaultLayoutComponent} from '../layout/app-default-layout/app-default-layout.component';

import {AboutComponent} from './about.component';

const routes: Routes = [
    {
        path: '',
        component: AppDefaultLayoutComponent,
        children: [{path: 'about', component: AboutComponent}],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AboutRoutingModule {}
