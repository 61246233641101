import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthenticationComponent} from './component/authentication.component';
import {LoginStepOneComponent} from './login-step-one/login-step-one.component';
import {LoginStepTwoComponent} from './login-step-two/login-step-two.component';

import {SsoCallbackComponent} from './sso-callback/sso-callback.component';

const routes: Routes = [
    {
        path: 'authentication',
        component: AuthenticationComponent,
        children: [
            {
                path: '',
                redirectTo: 'login-step-one',
                pathMatch: 'full',
            },
            {
                path: 'login-step-one',
                component: LoginStepOneComponent,
            },
            {
                path: 'login-step-two',
                component: LoginStepTwoComponent,
            },
            {
                path: 'sso-callback',
                component: SsoCallbackComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LoginRoutingModule {}
