import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';
import {AuthenticationContextModel} from 'src/app/core/InContact/models/authentication-context.model';
import {BaseApi, CustomErrorHandler} from 'src/app/sdk/core';
import {FacLocalStorageService} from 'src/app/sdk/storage';

@Injectable({
    providedIn: 'root',
})
export class IcAuthenticationApi extends BaseApi {
    constructor(
        @Inject(HttpClient) httpClient: HttpClient,
        @Inject(FacLocalStorageService) protected facLocalStorageSrv: FacLocalStorageService,
        @Optional() @Inject(CustomErrorHandler) protected errorHandler: CustomErrorHandler,
    ) {
        super(httpClient, facLocalStorageSrv, errorHandler);
    }

    authenticateUserApi(username: string, password: string) {
        const token64 = btoa(InContactApiSettings.clientId + ':' + InContactApiSettings.clientSecret); //base64 encoding
        //let headers = new Headers({ 'Authorization': 'Basic '  + token64, 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' });
        // let headers = new Headers({Authorization: 'Basic ' + token64, 'Content-Type': 'application/json'});

        // let options = new RequestOptions({headers: headers});
        const parameters = {grant_type: 'password', username, password, scope: ''};

        return this.POST_Request<AuthenticationContextModel>(InContactApiSettings.agentLoginUrl, null, null, parameters, (headers: HttpHeaders) => {
            headers = headers.set('Authorization', `Basic ${token64}`);
            return headers;
        });

        // return this.http.post(InContactApiSettings.agentLoginUrl, parameters, options).pipe(
        //     map(this.OnSuccess),
        //     catchError(err => this.onSendRequestRawError(err)),
        // );
    }

    refreshToken(refreshToken: string): Observable<AuthenticationContextModel | any> {
        // console.log(InContactApiSettings.clientId, InContactApiSettings.clientSecret, refreshToken);
        const token64 = btoa(InContactApiSettings.clientId + ':' + InContactApiSettings.clientSecret); //base64 encoding
        //let headers = new Headers({ 'Authorization': 'Basic '  + token64, 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' });
        // const headers = new Headers({Authorization: 'Basic ' + token64, 'Content-Type': 'application/json'});

        const parameters = {grant_type: 'refresh_token', refresh_token: refreshToken};

        return this.POST_Request(this.facLocalStorageSrv.getSettings().refresh_token_server_uri, null, null, parameters, (headers: HttpHeaders) => {
            headers = headers.set('Authorization', `Basic ${token64}`);
            return headers;
        });

        // let options = new RequestOptions({headers: headers});

        // return this.http.post(refreshTokeUrl, parameters, options).pipe(
        //     map(this.OnSuccess),
        //     catchError(err => this.onSendRequestRawError(err)),
        // );
    }
}
