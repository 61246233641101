import {ServerConfigModel, SoundConfigModel, UserPreferencesModel} from './models';
import {PhoneConfigModel} from './models/phone-config.model';

export const WEB_RTC_USER_AGENT = 'FrontlineAgentConsole';

export const DefaultUserPref: UserPreferencesModel = new UserPreferencesModel({
    hideLocalVideo: false, // when open remote video hide local video.
    videoSize: 'Small', // Default,Tiny,X Small,Small,Medium,Large,X Large,Huge, or Custom
    answeringMachine: {
        // For compatibility with phone prototype with answering machine.
        use: true,
        startDelay: 16,
        recordDuration: 20,
        newRecord: false,
    },
    videoCustom: {
        local: {
            size: 'Tiny',
            top: 'auto',
            left: 'auto',
        },
        remote: {
            size: 'Small',
            top: 'auto',
            left: 'auto',
        },
    },
    conference: {
        layout: 'compact',
        fps: 10,
        size: 'Small',
    },
    version: '3-May-2020',
});

export const DefaultSoundConfig: SoundConfigModel = new SoundConfigModel({
    generateTones: {
        // Phone ringing, busy and other tones vary in different countries.
        // Please see: https://www.itu.int/ITU-T/inr/forms/files/tones-0203.pdf

        /* Germany
         ringingTone: [{ f: 425, t: 1.0 }, { t: 4.0 }],
         busyTone: [{ f: 425, t: 0.48 }, { t: 0.48 }],
         disconnectTone: [{ f: 425, t: 0.48 }, { t: 0.48 }],
         autoAnswerTone: [{ f: 425, t: 0.3 }]
        */

        /* France
         ringingTone: [{f:400, t:1.5}, {t:3.5}],
         busyTone: [{ f: 400, t: 0.5 }, { t: 0.5 }],
         disconnectTone: [{ f: 400, t: 0.5 }, { t: 0.5 }],
         autoAnswerTone: [{ f: 400, t: 0.3 }]
        */

        /* Great Britain */
        ringingTone: [{f: [400, 450], t: 0.4}, {t: 0.2}, {f: [400, 450], t: 0.4}, {t: 2.0}],
        busyTone: [{f: 400, t: 0.375}, {t: 0.375}],
        disconnectTone: [{f: 400, t: 0.375}, {t: 0.375}],
        autoAnswerTone: [{f: 400, t: 0.3}],
    },
    downloadSounds: [
        {ring: 'ring1'}, // ring1 by default (user can select other ring)
        'bell',
    ],
    play: {
        outgoingCallProgress: {name: 'ringingTone', loop: true, volume: 0.2},
        busy: {name: 'busyTone', volume: 0.2, repeat: 4},
        disconnect: {name: 'disconnectTone', volume: 0.2, repeat: 3},
        autoAnswer: {name: 'autoAnswerTone', volume: 0.2},
        incomingCall: {name: 'ring', loop: true, volume: 1.0, dropDisabled: true},
        incomingMessage: {name: 'bell', volume: 1.0},
        dtmf: {volume: 0.15},
    },
    version: '3-May-2020',
});

export const DefaultPhoneConfig = new PhoneConfigModel({
    reconnectIntervalMin: 2,
    reconnectIntervalMax: 30,
    registerExpires: 600,
    useSessionTimer: false,
    keepAlivePing: 10,
    keepAlivePong: 10,
    keepAliveStats: 60,
    keepAliveDist: false,
    restoreCall: true,
    restoreCallMaxDelay: 20,
    dtmfUseWebRTC: true,
    dtmfDuration: null,
    dtmfInterToneGap: null,
    avoidTwoWayHold: true,
    enableAddVideo: true,
    addLoggerTimestamp: true,
    useWebrtcTracer: false,
    chromeAudioConstraints:
        'echoCancellation,googEchoCancellation,googEchoCancellation2,googDAEchoCancellation,googAutoGainControl,googNoiseSuppression,googNoiseSuppression,googHighpassFilter,googTypingNoiseDetection,googAudioMirroring',
    version: '25-Mar-2018',
    modes: {
        chrome_rtp_timeout_fix: 13,
    },
    constraints: {
        chrome: {audio: {echoCancellation: true}},
        firefox: {audio: {echoCancellation: true}},
    },
});

export const DefaultServerConfig = new ServerConfigModel({
    domain: 'audiocodes.com',
    addresses: ['wss://webrtclab.audiocodes.com'],
    iceServers: ['74.125.140.127:19302', '74.125.143.127:19302'],
    version: '3-May-2020',
});
