<div class="modal-header">
    <button type="button" class="close" (click)="dialog.close()" data-dismiss="modal">&times;</button>
    <h4 class="modal-title">Search User</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-xs-12 mb-5">
            <div class="p-d-flex">
                <span class="p-input-icon-left width-100x">
                    <i class="pi pi-search"></i>
                    <input class="width-100x" type="text" pInputText [(ngModel)]="text" placeholder="Search" />
                </span>
                <span class="ml-3">
                    <p-button label="Search" [disabled]="!text" (click)="search()"></p-button>
                </span>
            </div>
        </div>
        <div class="col-xs-12">
            <p-listbox
                *ngIf="appContext.selectedCrmApplication?.applicationType === CRM_APPLICATION_TYPES.ZENDESK"
                [options]="appContext.crmIntegrationService.CrmUsersList"
                [(ngModel)]="selectedUser"
                optionLabel="name"
                optionValue="id"
                (onClick)="listItemClick($event)"
            ></p-listbox>
        </div>
        <div class="col-xs-12 p-mt-3">
            <div class="p-d-flex">
                <div class="p-ml-auto">
                    <p-button class="p-mr-2" label="Create Ticket" [disabled]="!selectedUser" (click)="createTicket()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div>
