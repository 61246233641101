<div class="panel panel-default panel-top">
    <div class="panel-heading">
        <!-- <i class="fa fa-comment-o fa-lg" aria-hidden="true" title="{{ 'Buttons.Transfer' | translate }}"></i> -->
        <i class="fa fa-comment-o fa-lg" aria-hidden="true" title="{{ 'Buttons.Transfer' }}"></i>&nbsp;
        <!-- {{ 'Buttons.Transfer' | translate }} -->
        {{ 'Buttons.Transfer' }}
        {{ context.contactType }}
        <!-- {{ context.contactType + '.Title' | translate }} -->
        <!--{{ context.selectedRoom.roomName }}-->
        &nbsp;

        <div class="pull-right">
            <!--<a  (click)="openTransferChat()"><i class="fa fa-mail-forward" title="{{ 'ActiveContact.Buttons.Transfer' | translate }}"></i></a>-->
            <!-- <i
                (click)="cancel()"
                class="fa fa-times fa-lg"
                title="{{ 'Chat.EndChat' | translate }}"
                aria-hidden="true"
            ></i> -->
            <i (click)="cancel()" class="fa fa-times fa-lg" title="{{ 'Chat.EndChat' }}" aria-hidden="true"></i>
        </div>
    </div>

    <div>
        <!-- Default panel contents -->
        <!--<div class="panel-heading"><i class="fa fa-user-times fa-lg" aria-hidden="true" title="{{ 'Disposition.Title' | translate }}"></i>&nbsp;  {{ 'Disposition.Title' | translate }}</div>-->

        <form #dispositionForm="ngForm" class="form-horizontal chat-form">
            <div class="form-group">
                <div class="col-md-6 col-md-offset-3">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-filter" aria-hidden="true"></i></span>
                        <select (change)="onChange($event.target.value)" name="type" class="form-control">
                            <!--<option [ngValue]="preSelected">{{preSelected.dispositionName}}</option>-->
                            <option *ngFor="let option of context.options"> {{ option }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div *ngIf="showSkills" class="form-group">
                <div class="col-md-6 col-md-offset-3">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                        <select
                            [(ngModel)]="context.selectedSkill"
                            name="selected_skill"
                            #selected_skill="ngModel"
                            class="form-control"
                        >
                            <option *ngFor="let skill of context.skillslist" [ngValue]="skill">
                                {{ skill.SkillName }}</option
                            >
                        </select>
                    </div>
                </div>
            </div>

            <div *ngIf="showAgents" class="form-group">
                <div class="col-md-6 col-md-offset-3">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                        <select
                            [(ngModel)]="context.selectedAgent"
                            name="selected_agent"
                            #selected_agent="ngModel"
                            class="form-control"
                        >
                            <optgroup label="Online / Available">
                                <option *ngFor="let agent of context.agentStateList" [ngValue]="agent">
                                    {{ agent.firstName }} {{ agent.lastName }}
                                </option>
                            </optgroup>
                            <optgroup label="Offline">
                                <option *ngFor="let agent of context.offlineAgents" [ngValue]="agent" disabled>
                                    {{ agent.firstName }} {{ agent.lastName }}</option
                                >
                            </optgroup>
                        </select>
                    </div>
                </div>
            </div>

            <!--<div class="form-group">
                <div class="col-md-6 col-md-offset-3">
                    <textarea [(ngModel)]="dispositionNotes" name="notes" #notes="ngModel" class="form-control" id="notes" rows="3"></textarea>
                </div>
            </div>-->
            <div class="form-group">
                <div class="col-md-6 col-md-offset-3">
                    <button (click)="transferChat()" class="btn btn-primary btn-sm">Transfer</button>&nbsp;
                    <button (click)="cancel()" class="btn btn-warning btn-sm">Cancel</button>
                </div>
            </div>
        </form>
        <!--</div>   -->
    </div>
</div>
