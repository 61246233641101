import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {BaseApi, CustomErrorHandler} from 'src/app/sdk/core';
import {ScreenAgentConfig} from 'src/app/sdk/sdk.config';
import {FacLocalStorageService} from 'src/app/sdk/storage';
import {ScreenAgentPayloadModel} from '../../models/screen-agent';

@Injectable()
export class ScreenAgentApi extends BaseApi {
    constructor(
        @Inject(HttpClient) httpClient: HttpClient,
        @Inject(FacLocalStorageService) protected facLocalStorageSrv: FacLocalStorageService,
        @Inject(LocalStorageService) protected localStorageSrv: LocalStorageService,
        @Optional() @Inject(CustomErrorHandler) protected errorHandler: CustomErrorHandler,
    ) {
        super(httpClient, facLocalStorageSrv, errorHandler);
    }

    public connectToAgent(agentUuid: string) {
        return this.POST_Request<string>(
            `${ScreenAgentConfig.ApiPath}/screenagent/connect`,
            undefined,
            undefined,
            new ScreenAgentPayloadModel({
                agentCorrelationKey: agentUuid,
            }),
            (headers: Headers) => headers.set('Accept', 'text/plain; charset=utf-8'),
        );
    }

    public disconnectToAgent(agetnUuid: string) {
        return this.POST_Request<string>(
            `${ScreenAgentConfig.ApiPath}/screenagent/disconnect`,
            undefined,
            undefined,
            new ScreenAgentPayloadModel({
                agentCorrelationKey: agetnUuid,
            }),
            (headers: Headers) => headers.set('Accept', 'text/plain; charset=utf-8'),
        );
    }
}
