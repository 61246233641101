import {Component, OnInit, ViewContainerRef, ViewChild, ElementRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ErrorModel} from '../../.././core/_models/error.model';
import {EmailContactViewmodel} from '../../.././core/_models/_viewmodels/email-contact-viewmodel';
import {MessageService} from '../../.././core/_services/message.service';

import {EmailContactService} from '../../../core/_services/email-contact.service';
// import {DialogService} from '../../.././core/_services/dialog.service';
import {ContactDataContainerService} from '../../../core/_services/contactDataContainer.service';
import {CurrentContact} from '../../.././core/_models/current-contact.model';

// import {Overlay} from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal';
// import {Modal} from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal/plugins/bootstrap';

@Component({
    selector: 'app-email-inbound',
    templateUrl: './email-inbound.component.html',
    styleUrls: ['./email-inbound.component.css'],
})
export class EmailInboundComponent implements OnInit {
    public showEmailDisposition: boolean;
    public currentContactTickets: CurrentContact[];
    @ViewChild('emailDisplay') emailDisplay: ElementRef;
    //@ViewChild('#emailDisplay') emailDisplay;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public emailContactService: EmailContactService,
        // private dialogService: DialogService,
        private contactDataContainer: ContactDataContainerService,
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        /*     this.emailContactService.eMailData.subscribe(
          email => this.email = email
        ).unsubscribe();

        this.emailContactService.showEmailDisposition.subscribe(
          show => this.showEmailDisposition = show
        );
     */
        const me = this;
        this.createTextEditor();
        this.emailContactService.currentContactViewModels.subscribe(value => {
            const activeOnes = value.filter(eml => eml.contact.isActive());
            if (activeOnes.length > 0) {
                this.emailContactService.email = activeOnes[0];
                this.emailContactService.email.replyModeEntered.subscribe(val => {
                    if (val) {
                        this.createTextEditor();
                    }
                });
            } else {
                this.router.navigate(['active-contact']);
                /* setTimeout(() => {
          this.router.navigate(['']);
        }, 1000); */
            }
        });

        setTimeout(() => {
            //$(".emailDisplay_readonly").contents().find("body").html(me.email.decodeBodyHtml());
        }, 100);

        /*  this.contactDataContainer.CurrentContactArray.subscribe(
       values => {
         this.currentContactTickets = values;
         this.updateContactTickets();
       }); */
    }

    get emailAttachments() {
        return this.emailContactService.email?.contact?.attachmentsList ?? [];
    }

    DownloadAttachment(attachment: {name: string; url: string}) {
        this.emailContactService.downloadFile(attachment.url);
    }

    createTextEditor() {
        const me = this;

        setTimeout(() => {
            //var emailDate = "testing 23"; //new Date(me.email.contact.sentDate).toString();
            ($('#email_body') as any).summernote({
                height: 130, //set editable area's height
                callbacks: {
                    onChange(contents, $editable) {
                        //console.log('onChange:', contents, $editable);
                        me.emailContactService.email.reply.bodyHTML = contents;
                    },
                    onInit() {
                        console.log('Summernote is launched');
                        if (me.emailContactService.email.contact.isInbound) {
                            ($('#email_body') as any).summernote('code', me.emailContactService.email.decodeBodyHtml());
                            const node = document.createElement('div');

                            node.innerHTML = `<p></p><hr /><br /><br /> From: ${me.emailContactService.email.contact.toAddress}<br />Sent: ${new Date(
                                me.emailContactService.email.contact.sentDate,
                            )}<br /> To:  ${me.emailContactService.email.contact.toAddress}<br />Subject: ${
                                me.emailContactService.email.contact.subject
                            }<br /><br /><br />`;
                            ($('#email_body') as any).summernote('insertNode', node);
                        }
                    },
                },
                toolbar: [
                    // [groupName, [list of button]]
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['font', ['strikethrough', 'superscript', 'subscript']],
                    ['fontsize', ['fontsize']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['height', ['height']],
                ],
            });
            //console.log($('#email_body'))
        }, 500);
    }

    discard() {
        //this.emailContactService.discard(this.email);
    }

    reply() {
        //this.emailContactService.showReply("reply",this.email);
    }

    replyAll() {
        //this.emailContactService.showReply("replyAll",this.email);
    }

    forward() {
        //this.emailContactService.showReply("forward",this.email);
    }

    transfer() {
        //this.emailContactService.showReply("transfer",this.email);
    }

    updateContactTickets() {
        //Update ticket for active emails
        /*  let contact = this.getCurrentContact(this.email.contactId);

     if (contact !== undefined)
       this.email.ticketId = contact.ticketId; */
    }

    getCurrentContact(contactId) {
        return this.currentContactTickets.find(e => e.contactId === contactId);
    }

    handleOnQuickReplyInserting($event) {
        //$("#email_body").summernote("code", $event.content + this.email.reply.bodyHTML);
        //$("#email_body").summernote('editor.insertText', 'testing string');
        const node = document.createElement('div');
        node.innerHTML = $event.content;
        // @param {Node} node
        //
        ($('#email_body') as any).summernote('editor.restoreRange');
        ($('#email_body') as any).summernote('editor.focus');
        //$('#email_body').summernote('pasteHTML', $event.content);
        //$('#email_body').summernote('editor.insertText', 'This text should appear at the cursor');
        ($('#email_body') as any).summernote('insertNode', node);
    }

    onEmailDisplayReady(msg: string) {
        //alert('this alert is from code behind.')
        $('.emailDisplay_readonly').contents().find('body').html(this.emailContactService.email.decodeBodyHtml());
    }
}
