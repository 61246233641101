import {Pipe, PipeTransform} from '@angular/core';

import {AgentStateModel} from '../../core/_models/agent-state.model';

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
    transform(array: any[], filterItems: string, filterName: string, keyword: string) {
        if (keyword !== '') {
            switch (filterItems) {
                case 'agents':
                    if (filterName === 'firstName')
                        {return array.filter(e => e.firstName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);}
                case 'skills':
                    return array.filter(e => e.SkillName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);
                case 'addressbook':
                    return array.filter(
                        e =>
                            e.firstName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
                            e.lastName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
                            e.mobile.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
                            e.phone.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
                            e.email.toLowerCase().indexOf(keyword.toLowerCase()) !== -1,
                    );
                case 'call-history':
                    return array.filter(
                        e =>
                            e.skillName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
                            (e.isOutbound == 'False' &&
                                e.fromAddr.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
                            (e.isOutbound == 'True' && e.toAddr.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
                            e.contactStartDate.toLowerCase().indexOf(keyword.toLowerCase()) !== -1,
                    );
                default:
                    break;
            }
        } else {
            return array;
        }
    }
}
