<div class="page-heading">
    <ul class="nav nav-pills">
        <li>
            <h4>
                <i
                    class="glyphicon glyphicon-calendar fa-lg"
                    aria-hidden="true"
                    title="{{ 'App_Component.Footer.CallHistory' | translate }}"
                >
                </i
                >&nbsp;{{ 'App_Component.Footer.Callbacks' | translate }}
            </h4>
        </li>
        <li style="flex-grow: 1;"></li>
        <li>
            <a
                routerLink="/callbacks/new"
                style="display: flex; align-items: center; justify-content: center; margin: 2px;"
                class="btn btn-circle btn-default btn-xs"
                aria-haspopup="true"
                aria-expanded="true"
            >
                <i class="glyphicon glyphicon-plus" aria-hidden="true"></i>
            </a>
        </li>
    </ul>
</div>

<div class="page-content" *ngIf="callbacks.length > 0">
    <ul class="list-group">
        <li
            *ngFor="let callback of callbacks"
            class="list-group-item"
            style="padding: 5px; display: grid; grid-template-columns: auto 1fr; align-items: center; grid-gap: 10px;"
        >
            <i class="fa fa-calendar fa-2x"></i>
            <a tabindex="i" #listItem name="listItem" role="link" [routerLink]="[callback.callbackId]">
                <div class="row item-row">
                    <div class="col-xs-6 nopadding">
                        <div class="col-xs-12 item-text">{{ callback.firstName }} {{ callback.lastName }}</div>
                        <div class="col-xs-12 item-text" style="color: #666;">
                            {{ callback.callbackTime | date: 'short' }}<br />{{ getSkillName(callback.skillId) }}
                        </div>
                        <button
                            *ngIf="false"
                            class="btn btn-primary btn-sm"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                        >
                            {{ 'AddressBook.Agents.Call' | translate }}
                            <span class="caret"></span>
                        </button>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>

<div class="page-content" style="display: flex; align-items: center;" *ngIf="callbacks.length == 0">
    <p style="padding: 15px;">
        No callbacks are schedules for you. Click <strong>"+"</strong> to create new scheduled callback.
    </p>
</div>
