<form #dispositionForm="ngForm" *ngIf="!contact.dispositionSubmitted" (ngSubmit)="setDisposition()">
    <div class="row" style="margin-bottom: 5px">
        <div class="col-xs-12">
            <div class="form-group disposition-form-group">
                <div class="col-xs-12 nopadding">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="fa fa-filter" aria-hidden="true"></i>
                        </span>
                        <span *ngIf="false">{{ contact.appliedDisposition.dispositionId }}</span>
                        <select
                            #disposition
                            (change)="selectDispo($event, disposition.value, '')"
                            [attr.required]="contact.requireDisposition ? 'required' : null"
                            [(ngModel)]="dispositionId"
                            required
                            name="type"
                            class="form-control disposition-options"
                        >
                            <!-- <option [ngValue]="preSelected">{{preSelected.dispositionName}}</option> -->
                            <option selected value="">Please select value</option>
                            <option
                                *ngFor="let disposition of contact.dispositions"
                                [attr.selected]="
                                    contact.appliedDisposition && disposition.dispositionId == contact.appliedDisposition.dispositionId ? '' : null
                                "
                                [value]="disposition.dispositionId"
                            >
                                {{ disposition.dispositionName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- {{ contact.dispositions | json }} -->

    <div *ngIf="contact.skillInfo?.allowSecondaryDisposition" class="form-group disposition-form-group" style="margin-bottom: 5px">
        <div class="col-xs-12 nopadding">
            <div class="input-group">
                <span class="input-group-addon">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                </span>
                <select
                    #secondary_disposition
                    (change)="selectDispo($event, secondary_disposition.value, 'secondary')"
                    name="secondary_disposition"
                    class="form-control disposition-options"
                >
                    <option disabled value="">Please select value</option>
                    <option
                        *ngFor="let disposition of contact.dispositions"
                        [attr.selected]="
                            contact.appliedSecondaryDisposition && disposition.dispositionId == contact.appliedSecondaryDisposition.dispositionId ? '' : null
                        "
                        [value]="disposition.dispositionId"
                    >
                        {{ disposition.dispositionName }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <!-- {{ selectedDisposition | json }} -->

    <div class="row" *ngIf="selectedDisposition && selectedDisposition.requireRescheduleDate === 'True'" style="margin-bottom: 5px">
        <div class="col-xs-6">
            <input [(ngModel)]="model.date" name="date" class="form-control" [min]="minDate" type="date" required />
        </div>

        <div class="col-xs-6" style="display: flex">
            <ul class="nav nav-pills" style="margin-left: auto">
                <li>
                    <input type="number" max="12" min="1" class="time-bit" name="hour" [(ngModel)]="model.hour" step="1" />

                    <!-- <div *ngIf="true" class="dropdown">
                        <button
                            class="btn btn-default dropdown-toggle"
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            style="padding: 3px; margin: 2px"
                        >
                            {{ model.hour }}
                            <span class="caret"></span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <div class="container" style="width: 200px">
                                <div class="row" style="margin: 0px">
                                    <ul class="nav nav-pills nav-justified">
                                        <li>
                                            <button type="button" (click)="setHour('01')" class="btn btn-default">01</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setHour('02')" class="btn btn-default">02</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setHour('03')" class="btn btn-default">03</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setHour('04')" class="btn btn-default">04</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row" style="margin: 0px">
                                    <ul class="nav nav-pills nav-justified">
                                        <li>
                                            <button type="button" (click)="setHour('05')" class="btn btn-default">05</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setHour('06')" class="btn btn-default">06</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setHour('07')" class="btn btn-default">07</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setHour('08')" class="btn btn-default">08</button>
                                        </li>
                                    </ul>
                                </div>

                                <div class="row" style="margin: 0px">
                                    <ul class="nav nav-pills nav-justified">
                                        <li>
                                            <button type="button" (click)="setHour('09')" class="btn btn-default">09</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setHour('10')" class="btn btn-default">10</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setHour('11')" class="btn btn-default">11</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setHour('12')" class="btn btn-default">12</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </li>
                <li>
                    <input type="number" value="0" step="1" class="time-bit" name="minute" [(ngModel)]="model.minute" max="59" min="0" />
                    <!-- <div *ngIf="true" class="dropdown">
                        <button
                            class="btn btn-default dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            style="padding: 3px; margin: 2px"
                        >
                            {{ model.minute }}
                            <span class="caret"></span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <div class="container" style="width: 200px">
                                <div class="row" style="margin: 0px">
                                    <ul class="nav nav-pills nav-justified">
                                        <li>
                                            <button type="button" (click)="setMinute('00')" class="btn btn-default">00</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setMinute('05')" class="btn btn-default">05</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setMinute('10')" class="btn btn-default">10</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setMinute('15')" class="btn btn-default">15</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row" style="margin: 0px">
                                    <ul class="nav nav-pills nav-justified">
                                        <li>
                                            <button type="button" (click)="setMinute('20')" class="btn btn-default">20</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setMinute('25')" class="btn btn-default">25</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setMinute('30')" class="btn btn-default">30</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setMinute('35')" class="btn btn-default">35</button>
                                        </li>
                                    </ul>
                                </div>

                                <div class="row" style="margin: 0px">
                                    <ul class="nav nav-pills nav-justified">
                                        <li>
                                            <button type="button" (click)="setMinute('40')" class="btn btn-default">40</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setMinute('45')" class="btn btn-default">45</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setMinute('50')" class="btn btn-default">50</button>
                                        </li>
                                        <li>
                                            <button type="button" (click)="setMinute('55')" class="btn btn-default">55</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </li>
                <li>
                    <div class="dropdown">
                        <button
                            class="btn btn-default dropdown-toggle"
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            style="padding: 3px; margin: 2px"
                        >
                            {{ model.ampm }}
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <li><a (click)="setAMPM('AM')">AM</a></li>
                            <li><a (click)="setAMPM('PM')">PM</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>

        <div class="col-xs-12">
            <!-- {{ model.date }}T{{ model.hour }}:{{ model.minute }}:00{{ model.timezone }} -->
            <select #timezone [(ngModel)]="model.timezone" name="timezone" class="form-control disposition-options">
                <option *ngFor="let timezone of timezones" [value]="timezone.offset">
                    {{ timezone.displayName }}
                </option>
            </select>
        </div>

        <div class="col-xs-12">
            <input class="form-control ticket-text" name="primaryCallbackNumber" [(ngModel)]="primaryCallbackNumber" />
        </div>
    </div>

    <div class="row" style="margin-bottom: 5px">
        <div class="col-xs-12">
            <div class="form-group disposition-form-group">
                <div class="col-xs-12 nopadding">
                    <div class="input-group">
                        <!--<span class="input-group-addon btn btn-success" id="sizing-addon3" (click)="grabTicket(contactId)" title="Grab Ticket" >T#</span>-->
                        <span class="input-group-addon" id="sizing-addon3" title="Grab Ticket">T#</span>
                        <input class="form-control ticket-text" name="ticketId" [disabled]="disableTicketField" [(ngModel)]="contact.ticketId" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <div class="form-group disposition-form-group">
                <div class="col-xs-12 nopadding">
                    <textarea
                        accesskey="n"
                        [(ngModel)]="contact.appliedDispositionComments"
                        name="notes"
                        #notes="ngModel"
                        class="form-control"
                        rows="2"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group disposition-form-group">
        <div class="col-xs-12 nopadding">
            <ng-content select="[last-one]"></ng-content>
        </div>
    </div>

    <div class="form-group disposition-form-group" *ngIf="mode == 'normal'">
        <div class="">
            <button type="submit" [disabled]="!this.contact.appliedDisposition" class="btn btn-primary btn-sm pull-right">Submit</button>
        </div>
    </div>
</form>
<!--</div>   -->
