import './polyfills.ts';

import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import {AppModule} from './app/app.module';
// import * as jQuery from 'jquery';
import {TrackJS} from 'trackjs';

if (window.env === 'prod') {
    TrackJS.install({
        token: '2c1ea071451d443c986e4484962dcc7f',
        application: 'fac',
        version: window.env,
    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log);
