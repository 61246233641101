import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CallHistoryRoutingModule} from './call-history.routing';
import {CallHistoryComponent} from './call-history.component';
// import { QueueService }     from './call-history.service';

import {SharedModule} from '../../shared/shared.module';
//import { FilterPipe } from '../../shared/pipes/filter.pipe';
// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';

@NgModule({
    imports: [BrowserModule, FormsModule, CallHistoryRoutingModule, SharedModule],
    declarations: [CallHistoryComponent],
    exports: [CallHistoryComponent],
    providers: [],
})
export class CallHistoryModule {}
