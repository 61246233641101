import {Injectable} from '@angular/core';
import {ContactServiceBase} from './contact.service.base';
import {Email} from '../_models/email.model';
import {ApplicationContextService} from './application-context.service';
import {Observable, Subscription, throwError} from 'rxjs';
import {EmailContactViewmodel} from '../_models/_viewmodels/email-contact-viewmodel';
import {Router} from '@angular/router';
import {ContactBase} from '../_models/contact-base';
import {downloadFile, extensionMimeTypes} from 'src/app/global';

@Injectable()
export class EmailContactService extends ContactServiceBase<Email, EmailContactViewmodel> {
    public email: EmailContactViewmodel;
    private parkedEmailsTimer = null;
    constructor(private router: Router, appContext: ApplicationContextService) {
        super(Email, EmailContactViewmodel, appContext);
        this.title = 'Email Contacts'; //--> Get Translation

        appContext.emailContactService = this;
    }

    processContactEvent(event: any) {
        if (event.FinalState === 'False' && event.IsInbound === 'False') {
            this.appContext.crmIntegrationService.updateEmailContactUserData(this.email);
        }
        return super.processContactEvent(event);
    }

    getContactListToDisplay(): Array<Email> {
        const vals = this.currentContacts.value.filter((v, i) => !v.finalState || v.isParked());
        return vals;
    }

    newOutboundEmail(skillId: string, toAddress?: string): Observable<any> {
        return this.appContext.inContactAPIService.emailOutbound(skillId, toAddress);
    }

    onContactActivated(contact: Email) {
        super.onContactActivated(contact);
        if (contact.isInbound) {
            //this.router.navigate(['email-contact'], { skipLocationChange: false });
            this.navigateTo(['email-contact'], {skipLocationChange: false});
        } else {
            this.navigateTo(['email-contact'], {skipLocationChange: false});
        }
        //throw "testing with throwing error what happens";
    }

    downloadFile(filePath: string) {
        this.appContext.inContactAPIService.getFile(filePath).subscribe(
            (res: any) => {
                if (res?.files) {
                    const ext = res.files.fileName.split('.').pop();

                    downloadFile(res.files.fileName, res.files.file, extensionMimeTypes[ext]);
                }
                console.log('file res: ', res);
            },
            err => {
                console.log('get file error', err);
            },
        );
    }

    transferContact(contact: ContactBase, data: any): Observable<ContactBase> {
        return new Observable(r => {
            this.appContext.inContactAPIService.transferEmail(contact.contactId, data).subscribe(
                res => {
                    //this.messageService.setCallMessage.next("Chat has been transfered successfully to " + data.transferType);
                    r.next(contact);
                },
                err => throwError(err),
            );
        });
    }

    parkEmail(contact: Email): Observable<Email> {
        return new Observable(r => {
            this.appContext.inContactAPIService.parkEmail(contact.contactId, {...contact.rawData, attachmentNames: '', attachments: ''}).subscribe(
                res => {
                    r.next(contact);
                },
                err => {
                    //r.throw(err);
                    this.handleContactError(err);
                },
            );
        });
    }

    unparkEmail(contact: Email, imidiate: boolean = true): Observable<Email> {
        return new Observable(r => {
            const stateUnavailable = this.appContext.agentSessionService.currentAgentState.value == 'Unavailable';
            this.appContext.inContactAPIService.unparkEmail(contact.contactId, imidiate && stateUnavailable).subscribe(
                res => {
                    r.next(contact);
                    if (!imidiate) {
                        this.removeContact(contact).subscribe();
                        this.updateAllViewmodels();
                    }
                },
                err => {
                    throwError(err);
                },
            );
        });
    }

    updateParkedEmails() {
        if (this.parkedEmailsTimer) {
            return;
        }
        const me = this;
        window.clearTimeout(me.parkedEmailsTimer);
        this.appContext.agentSessionService.agentId.subscribe(v => {
            if (`${v}` == `0`) {
                return;
            }
            const subscription = me.appContext.inContactAPIService.getParkedEmails(`${v}`).subscribe(result => {
                this.addParkedEmailsToContactList(result);
            });
        });
    }

    private addParkedEmailsToContactList(parkedEmailsData: any[]) {
        parkedEmailsData.forEach((v, i) => {
            const data = {
                ContactId: v.contactId,
                LastStateChangeTime: v.lastUpdateTime,
                LastStateChangeTimeUTC: v.lastUpdateTime,
                StartTime: v.contactStart,
                StartTimeUTC: v.contactStart,
                Status: 'Parked',
                Subject: v.subject,
                Skill: v.skillId,
                FromAddress: v.fromAddr,
                FinalState: 'True',
                IsInbound: 'True',
                isParkedInqueue: v.targetAgentId != '0',
                isFakeEvent: true,
            }; //Object.assign({}, v)
            this.processContactEvent(data);
        });
    }

    private addEmailCounter = 0;
    private timerSubscription: Subscription = null;
    onServiceStarted() {
        super.onServiceStarted();
        this.updateParkedEmails();

        this.appContext.agentSessionService.businessUnit.subscribe(bu => {
            if (bu.businessUnitId && !bu.isMultiContactHandling && !this.timerSubscription) {
                this.timerSubscription = this.appContext.applicationTimer.subscribe(r => {
                    this.addEmailCounter++;
                    if (this.addEmailCounter >= 30) {
                        this.addEmailCounter = 0;
                        try {
                            const currentEmailContactsCount = this.currentContactViewModels.value.length;
                            if (currentEmailContactsCount > 0) {
                                const agent = this.appContext.agentSessionService.loadAgent();
                                if (agent && agent.info && currentEmailContactsCount <= agent.info.maxEmailAutoParkingLimit) {
                                    this.appContext.inContactAPIService.addEmail().subscribe();
                                }
                            }
                        } catch (addTextError) {}
                    }
                });
            }
        });
    }

    onServiceStoped() {
        super.onServiceStoped();
        window.clearInterval(this.parkedEmailsTimer);
        this.parkedEmailsTimer = null;
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
    }
}
