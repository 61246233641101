import {NgModule} from '@angular/core';

import {LoginRoutingModule} from './authentication.routes';
import {SharedModule} from '../../shared/shared.module';
import {SelectCrmModalComponent} from '../../core/uac-crm-integration/select-crm-form/select-crm.component';
import {LoginStepOneComponent} from './login-step-one/login-step-one.component';
import {AuthenticationComponent} from './component/authentication.component';
import {LoginStepTwoComponent} from './login-step-two/login-step-two.component';
import {AuthenticationService} from './services/authentication.service';
import {SsoCallbackComponent} from './sso-callback/sso-callback.component';

// import {ModalModule} from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal';
// import {HotkeyModule, HotkeysService, HotkeyOptions} from 'angular2-hotkeys';

// import {CircleComponent} from 'ng2-spin-kit/app/spinner/circle'
// import * as spinner from 'ng2-spin-kit/app/spinners';
// import {BusyModule} from 'angular2-busy';

@NgModule({
    imports: [LoginRoutingModule, SharedModule],
    declarations: [AuthenticationComponent, LoginStepOneComponent, LoginStepTwoComponent, SsoCallbackComponent],
    providers: [AuthenticationService],
    entryComponents: [SelectCrmModalComponent],
})
export class AuthenticationModule {}
