<ng-template #modal>
    <div class="modal-header">
        <h4 class="modal-title">{{dialogTitle}}</h4>
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div class="modal-body">{{dialogMessage}}</div>
    <div>
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="reject()">Reject</button>
        <button type="button" class="btn btn-primary" (click)="accept()">Accept</button>
    </div>
</ng-template>
<!--
<div>
    <button (click)="openPreview()">Test Open Preview</button>
</div> -->

<ul style="display: none">
    <li *ngFor="let contact of incomingContacts">
        <audio autoplay="contact.ringtoneUrl" [loop]="contact.ringtoneLoop">
            <source src="{{contact.ringtoneUrl}}" type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    </li>
</ul>

<!-- <app-spinner [isRunning]="isRunning()"></app-spinner> -->
<!-- Fixed navbar -->

<ul style="display: none">
    <li *ngFor="let contact of incomingContacts">
        <audio autoplay [loop]="contact.settings.ringtoneLoop">
            <source [src]="contact.settings.ringtoneUrl" type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    </li>
</ul>

<div class="reset-size" (click)="resetWindowSize()" #resetSize></div>
<div class="drag-x-axis" #dragXAxis></div>
<div class="drag-y-axis" #dragYAxis></div>

<div class="app-container">
    <!-- <app-header></app-header> -->
    <!-- <div *ngIf="false" class="app-contacts-bar">
        <span style="flex: 1">Contacts</span>
        <ng-container *ngFor="let action of appContext.callContactService.actions">
            <button
                role="button"
                title="{{ action.text | translate }}"
                (click)="appContext.callContactService.executeServiceAction(action.action)"
                routerLinkActive="active"
                class="btn btn-primary-outline no-border"
                [disabled]="!action.isAvailable()"
                *ngIf="action.isVisible()"
            >
                <i class="fa fa-{{action.icon}}" aria-hidden="true"></i> {{action.text}}
            </button>
        </ng-container>

        <a href="javascript:;" *ngIf="legStatus == 'Disconnected'" (click)="connectLeg()"
            ><i class="fa fa-link fa-flip-horizontal" aria-hidden="true"></i
        ></a>
    </div> -->
    <!-- [ngClass]="{authenticated:isAuthenticated && isSessionStarted, notAuthenticated: !isAuthenticated || !isSessionStarted}" -->
    <!-- <div class="app-body">
      </div> -->
    <router-outlet></router-outlet>

    <!-- <app-default-footer></app-default-footer> -->
    <div *ngIf="true" class="footer-branding-container">
        <ul class="nav nav-pills footer-branding">
            <li style="padding-left: 5px"><img class="logo" src="../../assets/images/fac_logo_trns.png" /></li>
            <li class="poweredby">powered by Frontline Services</li>
            <audio id="audio_remote" autoplay></audio>
        </ul>
    </div>
</div>
<ng2-toasty [position]="'top-right'"></ng2-toasty>
<ngx-ui-loader></ngx-ui-loader>
