import {Component, Inject, Input, OnInit} from '@angular/core';
import {ContactViewBaseModel, ContactActionModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {SoftPhoneService} from 'src/app/sdk/counter-path/services/softphone/softphone.service';
import {WebRTCService} from 'src/app/core/InContact/web-rtc/web-rtc.service';
import {ApplicationContextService} from 'src/app/core/_services/application-context.service';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
import {DialogRef, overlayConfigFactory} from 'ngx-modialog-7';
import {UserSelectionDialogComponent} from 'src/app/shared/modals/user-selection-dialog/user-selection-dialog.component';
import {TicketSelectionDialogComponent} from 'src/app/shared/modals/ticket-selection-dialog/ticket-selection-dialog.component';
import {SearchZendeskUsersDialogComponent} from '../../modals/search-zendesk-users-dialog/search-zendesk-users-dialog.component';
import {CRM_APPLICATION_TYPES} from 'src/app/global';

declare let $: JQueryStatic;
declare let Bloodhound;

@Component({
    selector: 'app-contact-item',
    templateUrl: './contact-item.template.html',
    styleUrls: ['./contact-item.component.scss'],
})
export class ContactItemComponent {
    @Input()
    contactVM: ContactViewBaseModel<any & ContactBase>;

    @Input() mode = 'normal';

    diloagRef: DialogRef<any>;
    searchDiloagRef: DialogRef<any>;

    constructor(@Inject(ApplicationContextService) public appContext: ApplicationContextService, @Inject(DialogService) public diloagSrv: DialogService) {}

    public get allowUserSearch() {
        return (
            this.appContext.selectedCrmApplication?.applicationType === CRM_APPLICATION_TYPES.ZENDESK &&
            this.appContext.crmIntegrationService.data?.processed &&
            !this.appContext.crmIntegrationService.data?.zendeskUser &&
            this.contactVM?.contact?.isActive() &&
            !this.contactVM?.settings?.creatUser
        );
    }

    savePreselectedDisposition() {
        if (this.contactVM.contact.appliedDisposition) {
            this.contactVM.preDeispositionSet = true;
        }
    }

    onSliderInput(newValue) {
        console.log(newValue);
    }

    onCustomUserSearch() {
        this.searchDiloagRef = this.diloagSrv.openModal(
            SearchZendeskUsersDialogComponent,
            overlayConfigFactory({
                dialogClass: 'modal-dialog',
            }),
        );
    }

    onSelectUser(event: any) {
        this.diloagRef = this.diloagSrv.openModal(
            UserSelectionDialogComponent,
            overlayConfigFactory({
                dialogClass: 'modal-dialog',
                contactVM: this.contactVM,
            }),
        );
    }

    onSelectTicket(event: any) {
        this.diloagRef = this.diloagSrv.openModal(
            TicketSelectionDialogComponent,
            overlayConfigFactory({
                dialogClass: 'modal-dialog',
            }),
        );
    }
}
