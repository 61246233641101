import {Injectable} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {AuthenticationContextModel} from 'src/app/core/InContact/models/authentication-context.model';
import {AuthenticationContextService} from 'src/app/core/InContact/_services/authentication-context.service';
import {of as observableOf, throwError} from 'rxjs';
import {IcAuthenticationApi} from '../../api';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {IcOpenIdApi} from '../../api/openid/ic-openid.api';
import {parseJwt} from 'src/app/global';
import {AccessTokenPayloadModel, IdTokenPayloadModel} from '../../models';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class IcAuthService {
    private refreshTokenTimer = null;
    private _authenticationContext: AuthenticationContextModel;

    private refreshTokenTries = 0;

    public get authenticationContext() {
        return this._authenticationContext;
    }
    public set authenticationContext(val: AuthenticationContextModel) {
        this._authenticationContext = val;
    }

    constructor(
        private localStorageService: LocalStorageService,
        private authenticationContextService: AuthenticationContextService,
        private icAuthenticationApi: IcAuthenticationApi,
        private icOpenIdApi: IcOpenIdApi,
    ) {}

    clearScheduleRefreshTimer() {
        window.clearTimeout(this.refreshTokenTimer);
    }

    authenticateUserByCode(email: string, code: string, redirect_uri: string) {
        return this.icOpenIdApi.getTokenByCode(email, code, redirect_uri).pipe(
            map(res => {
                const accessTokenPayload = parseJwt<AccessTokenPayloadModel>(res.access_token);
                const idTokenPayload = parseJwt<IdTokenPayloadModel>(res.id_token);
                const authContext = new AuthenticationContextModel({
                    access_token: res.access_token,
                    id_token: res.id_token,
                    refresh_token: res.refresh_token,
                    agent_id: `${accessTokenPayload.icAgentId}`,
                    scope: accessTokenPayload.icScope,
                    refresh_token_server_uri: `https://api-${idTokenPayload.icClusterId}.${idTokenPayload.icDomain}/InContactAuthorizationServer/Token`,
                    resource_server_base_uri: `https://api-${idTokenPayload.icClusterId}.${idTokenPayload.icDomain}/inContactAPI/`,
                    expires_in: res.expires_in,
                    expires_at: accessTokenPayload.exp,
                    token_type: res.token_type,
                });

                this.authenticationContext = authContext;
                this.authenticationContextService.authenticationContextModel = authContext;
                this.authenticationContextService.saveAuthContext(authContext);

                return authContext;
            }),
        );
    }

    refreshTokenSSO(email: string) {
        return this.icOpenIdApi.refreshTokenCxone(email, this.authenticationContext.refresh_token).pipe(
            map(res => {
                const accessTokenPayload = parseJwt<AccessTokenPayloadModel>(res.access_token);
                const idTokenPayload = parseJwt<IdTokenPayloadModel>(this.authenticationContext.id_token);

                const authContext = new AuthenticationContextModel({
                    access_token: res.access_token,
                    id_token: this.authenticationContext.id_token,
                    refresh_token: res.refresh_token,
                    agent_id: `${accessTokenPayload.icAgentId}`,
                    scope: accessTokenPayload.icScope,
                    refresh_token_server_uri: 'https://cxone.niceincontact.com/openid/token',
                    resource_server_base_uri: `https://api-${idTokenPayload.icClusterId}.${idTokenPayload.icDomain}/inContactAPI/`,
                    expires_in: res.expires_in,
                    expires_at: accessTokenPayload.exp,
                    token_type: res.token_type,
                });

                this.authenticationContext = authContext;
                this.authenticationContextService.authenticationContextModel = authContext;
                this.authenticationContextService.saveAuthContext(authContext);
                return res;
            }),
        );
        // .refreshToken(this.authenticationContextService.authenticationContextModel.refresh_token)
        // .pipe(
        //     map(
        //         res => {
        //             const accessTokenPayload = parseJwt<AccessTokenPayloadModel>(res.access_token);

        //             const authContext = new AuthenticationContextModel({
        //                 access_token: res.access_token,
        //                 id_token: this.authenticationContext.id_token,
        //                 refresh_token: res.refresh_token,
        //                 agent_id: `${accessTokenPayload.icAgentId}`,
        //                 scope: accessTokenPayload.icScope,
        //                 refresh_token_server_uri: ''
        //             })
        //             //this._localStorageService.set("currentAgent", agent);
        //             this.authenticationContextService.authenticationContextModel = res;
        //             this.authenticationContextService.saveAuthContext(res);
        //             // this.authenticationContextService.tryReloadState();
        //             //this._inContactSessionService.startSession(res,agent.sessionValue,agent.sessionValue);
        //             // this.scheduleRefreshToken();
        //             return observableOf(true);
        //         },
        //         error => {
        //             return observableOf(error);
        //         },
        //     ),
        // );
    }

    authenticateUser(username: string, password: string): Observable<AuthenticationContextModel> {
        return this.icAuthenticationApi.authenticateUserApi(username, password).pipe(
            map(res => {
                const accessTokenPayload = parseJwt<AccessTokenPayloadModel>(res.access_token);

                res.expires_at = accessTokenPayload.exp;
                this.authenticationContext = res;
                this.authenticationContextService.authenticationContextModel = res;

                //this._localStorageService.set("currentAgent", agent);
                this.authenticationContextService.saveAuthContext(res);
                //this._inContactSessionService.startSession(res,agent.sessionValue,agent.sessionValue);
                //this.scheduleRefreshToken();
                return res;
            }),
            catchError(e => throwError(e)),
        );
    }

    scheduleRefreshToken() {
        if (!this.authenticationContextService.authenticationContextModel) {
            return;
        }

        if (!this.authenticationContextService.authenticationContextModel.expires_at) {
            return;
        }
        const timeoutMiliseconds =
            moment(new Date(this.authenticationContextService.authenticationContextModel.expires_at * 1000)).diff(new Date()) - 5 * 60 * 1000;

        // let timeout = (this.authenticationContextService.authenticationContextModel.expires_in - 600) * 1000; //10000; //

        // timeoutMiliseconds = timeoutMiliseconds - 53 * 60 * 1000;
        // console.log(timeoutMiliseconds);

        const me = this;
        me.refreshTokenTries = 0;
        // console.log('scheduled timeout for refresh token');
        this.clearScheduleRefreshTimer();
        this.refreshTokenTimer = window.setTimeout(() => {
            me.refreshTokenRequester();
        }, timeoutMiliseconds);
    }

    private refreshTokenRequester = () => {
        const me = this;

        if (InContactApiSettings.isSSOLogin) {
            me.refreshTokenSSO(this.authenticationContextService.getUserName()).subscribe(
                res => {
                    console.log(res);
                    me.scheduleRefreshToken();
                },
                error => {
                    console.log(error);
                    setTimeout(() => {
                        if (me.refreshTokenTries < 3) {
                            me.refreshTokenRequester();
                            me.refreshTokenTries++;
                        }
                    }, 1 * 60 * 1000);
                },
            );
        } else {
            me.icAuthenticationApi.refreshToken(me.authenticationContextService.authenticationContextModel.refresh_token).subscribe(
                res => {
                    const accessTokenPayload = parseJwt<AccessTokenPayloadModel>(res.access_token);

                    res.expires_at = accessTokenPayload.exp;
                    this.authenticationContext = res;

                    this.authenticationContextService.authenticationContextModel = res;
                    //this._localStorageService.set("currentAgent", agent);
                    this.authenticationContextService.saveAuthContext(res);
                    this.authenticationContextService.tryReloadState();
                    //this._inContactSessionService.startSession(res,agent.sessionValue,agent.sessionValue);
                    me.scheduleRefreshToken();
                    return observableOf(true);
                },
                error => {
                    setTimeout(() => {
                        if (me.refreshTokenTries < 3) {
                            me.refreshTokenRequester();
                            me.refreshTokenTries++;
                        }
                    }, 1 * 60 * 1000);
                    return observableOf(error);
                },
            );
        }
    };
}
