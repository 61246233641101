import {CrmServiceBase, CustomSearchResult} from './base.crm-service';
import {Injectable} from '@angular/core';
import {ContactViewBaseModel} from '../../_models/_viewmodels/contact-view-base.model';
import {ContactBase} from '../../_models/contact-base';
import {Observable, Subject} from 'rxjs';
import {CrmDataHolderViewModel} from '../../_models/crm';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {AnyObject, parseQueryString} from 'src/app/global';
import {
    DynamicsActivityPointerModel,
    DynamicsCaseModel,
    DynamicsContactModel,
    DynamicsEmailActivityModel,
    DynamicsEnvironmentModel,
    DynamicsPhoneCallModel,
} from '../../_models/crm/dynamics';
import {EmailContactViewmodel} from '../../_models/_viewmodels/email-contact-viewmodel';
import {DynamicsTaskModel} from '../../_models/crm/dynamics/models/dynamics-task.model';
import {KustomerAdapter} from './kustomer.adapter';
import {ApplicationContextService} from '../application-context.service';
import {LocalStorageService} from 'ngx-webstorage';
import {MessageService} from '../message.service';
import {KustomerResponseModel, KustomerUserAttributesModel} from '../../_models/crm/kustomer';
import {EventEmitter} from 'stream';
import {Router} from '@angular/router';
import {DexieLogsService} from 'src/app/sdk/dexie/services';

const kustomerLog = (...message: any[]) => {
    console.log('::KUSTOMER:: -> ', ...message);
};

@Injectable()
export class KustomerCrmSerivce extends CrmServiceBase {
    private currentSystemEnv: DynamicsEnvironmentModel = null;
    public kustomerAdapter: KustomerAdapter = null;

    private callData: AnyObject = {};

    public currentKustomerUser: KustomerResponseModel<KustomerUserAttributesModel> = null;

    constructor(
        protected localStorageSrv: LocalStorageService,
        protected messagesSrv: MessageService,
        protected dexieLogsSrv: DexieLogsService,
        public router: Router,
    ) {
        super(localStorageSrv, messagesSrv, dexieLogsSrv);
        // this.localStorageService = new LocalStorageService();
        // this.init();
        this.kustomerAdapter = new KustomerAdapter();
    }

    init() {
        // console.log(window.Promise);

        try {
            const me = this;

            // kustomerLog('initilizing...', me.kustomerAdapter);
            window.Kustomer.initialize(
                {
                    debug: true,
                },
                (data: any) => {
                    // kustomerLog('Kustomer Initilized', data, me.kustomerAdapter);
                    me.loadCurrentUser();

                    me.onNumberDialed();
                    me.onRoutingStatusChange();

                    me.kustomerAdapter.fetchUserStatuses().then(res => {
                        kustomerLog('statuses', res);
                    });

                    me.initDone$.next(true);
                    me.initDone$.complete();
                },
            );

            // return new Promise((resolve, reject) => {
            //     reject();
            // }).catch(err => {
            //     if (true) {
            //         return Promise.resolve();
            //     }
            //     return Promise.reject(err);
            // });
        } catch (error) {
            kustomerLog('Error getEnvironment: ' + error);
        }
    }

    public onNumberDialed() {
        const me = this;
        window.Kustomer?.on('number-dialed', n => {
            me.router.navigate(['/dialpad'], {queryParams: {phoneNumber: n, fromKustomer: 1}});
        });
    }

    private onRoutingStatusChange() {
        const me = this;
        me.subjects$['routing-status-change'] = new Subject();

        window.Kustomer.on('routing-status-change', statusData => {
            me.subjects$['routing-status-change'].next(statusData);
        });
    }

    public loadCurrentUser() {
        this.kustomerAdapter
            .fetchCurrentUser()
            .then((res: KustomerResponseModel<KustomerUserAttributesModel>) => {
                // kustomerLog('Kustomer User: ', res);
                this.currentKustomerUser = res;
                this.kustomerAdapter._currentUser = this.currentKustomerUser;
            })
            .catch(err => {
                kustomerLog('Kustomer Error', err);
            });
    }

    updateCrmTicketWithContactDetails(contactVM: ContactViewBaseModel<ContactBase>): Observable<ContactViewBaseModel<ContactBase>> {
        super.updateCrmTicketWithContactDetails(contactVM);

        const context = {mappings: contactVM.crmFieldMappings};
        const noteObject = contactVM.toContactDetailsObject(false);

        const currentContact = contactVM.contact;

        // const agentPartyObj = this.currentSystemEnv.toActivityPartyModel(currentContact.isInbound ? 2 : 1);
        // const secondParty = this.crmDataHolder
        //     ? new DynamicsContactModel(this.crmDataHolder.dynamicsUser).toActivityPartyModel(currentContact.isInbound ? 1 : 2)
        //     : null;

        const entityName = 'task';
        const entityObj: DynamicsActivityPointerModel = null;

        // this.callData = {
        //     call: {
        //         id: contactVM.contact.contactId,
        //         note: htmlstr,
        //     },
        // };
        if (!this.callData.call?.id) {
            this.callData.call = {
                id: contactVM.contact.contactId,
            };
        }
        // this.callData.call.note = htmlstr;

        // kustomerLog('createNoteCallback : ', this.callData);
        if (!!noteObject) {
            // this.createNoteCallback(this.callData);
            this.kustomerAdapter.findAndUpdateMessageMeta(this.callData, noteObject);
        }

        // if (
        //     currentContact.contactType === 'Call'
        //     // || currentContact.contactType === 'Voicemail'
        // ) {
        //     entityName = 'phonecall';
        //     entityObj = new DynamicsPhoneCallModel({
        //         phonecall_activity_parties: [agentPartyObj, secondParty],
        //         phonenumber: currentContact.DNIS,
        //         directioncode: !currentContact.isInbound,
        //         description: htmlstr,
        //         subject: `${currentContact.isInbound ? 'Inbound' : 'Outbound'} ${currentContact.contactType}`,
        //     });
        // }

        // else if (currentContact.contactType === 'Email') {
        //     entityName = 'email';
        //     // console.log('after end contact: ', currentContact.isInbound, this.crmDataHolder.dynamicsUser);
        //     entityObj = new DynamicsEmailActivityModel({
        //         directioncode: !currentContact.isInbound,
        //         description: htmlstr,

        //         subject: `${currentContact.isInbound ? 'Inbound' : 'Outbound'} Email`,
        //         email_activity_parties: [agentPartyObj, secondParty],
        //     });
        // } else if (currentContact.contactType === 'Chat') {
        //     entityName = 'task';
        //     entityObj = new DynamicsTaskModel({
        //         description: htmlstr,

        //         subject: `${currentContact.isInbound ? 'Inbound' : 'Outbound'} Chat`,
        //         task_activity_parties: [agentPartyObj, secondParty],
        //     });
        // } else if (currentContact.contactType === 'WorkItem') {
        //     entityName = 'task';
        //     entityObj = new DynamicsTaskModel({
        //         description: htmlstr,

        //         subject: 'Workitem Activity',
        //         task_activity_parties: [agentPartyObj, ...(secondParty ? [secondParty] : [])],
        //     });
        // }

        return new Observable(obsrver => {
            if (false && !!entityName && !!entityObj) {
                // if (context.mappings) {
                //     let mappings = context.mappings.filter(map => {
                //         return map.value && map.value.length > 0;
                //     });
                //     if (mappings) {
                //         for (const mapping of mappings) {
                //             entityObj[mapping.value] =
                //                 (!entityObj[mapping.value] ? '' : entityObj[mapping.value] + ', ') + contactVM.getContactPropertyValueByKey(mapping.name);
                //         }
                //     }
                // }
                // this.createActivity(entityName, entityObj)
                //     .then(
                //         (res: any) => {
                //             // console.log('create activity res: ', res);
                //             obsrver.next(contactVM);
                //         },
                //         err => {
                //             console.log('create activity error: ', err);
                //             obsrver.next(contactVM);
                //         },
                //     )
                //     .finally(() => {
                //         obsrver.complete();
                //     });
            } else {
                obsrver.complete();
            }
        });
    }

    protected customCrmSearch(viewmodel: ContactViewBaseModel<ContactBase>, searchString: string, data: any) {
        return new Observable<{found: boolean; type: string}>(obsvr => {
            searchString = searchString.split('+').join(' ');
            const parts = searchString.split('?$');

            if (parts.length < 2) {
                obsvr.next({found: false, type: null});
                obsvr.complete();
                return;
            }

            const entityName = parts[0];
            searchString = `$${parts[1]}`;

            // console.log('searching for: ', entityName, searchString);

            obsvr.next({found: false, type: null});
            obsvr.complete();

            // Microsoft.CIFramework.searchAndOpenRecords(entityName, `?${decodeURI(searchString)}`, false)
            //     .then(
            //         result => {
            //             const resultData: {[key: string]: DynamicsContactModel} = JSON.parse(result);

            //             if (entityName === 'contact') {
            //                 data.dynamicsUserResult = [];
            //                 for (const key in resultData) {
            //                     data.dynamicsUserResult.push(resultData[key]);
            //                 }

            //                 if (data.dynamicsUserResult.length === 1) {
            //                     data.dynamicsUser = data.dynamicsUserResult[0];

            //                     obsvr.next({found: true, type: 'user'});
            //                     return;
            //                 }
            //             } else if (entityName === 'case') {
            //                 data.dynamicsCaseResult = [];
            //                 for (const key in resultData) {
            //                     data.dynamicsCaseResult.push(resultData[key]);
            //                 }

            //                 if (data.dynamicsCaseResult.length === 1) {
            //                     data.dynamicsCase = data.dynamicsCaseResult[0];

            //                     obsvr.next({found: true, type: 'case'});
            //                     return;
            //                 }
            //             }

            //             obsvr.next({found: false, type: null});
            //         },
            //         error => {
            //             console.log(error.message);
            //             // handle error conditions
            //         },
            //     )
            //     .catch(err => {
            //         console.log('findContact Error: ', err);

            //         obsvr.error(err);
            //     })
            //     .finally(() => {
            //         obsvr.complete();
            //     });
        });
    }

    endCallCallback(data) {
        this.kustomerAdapter._taskStatus = 'ended';

        this.kustomerAdapter.handleSaveLog(data);
    }

    createNoteCallback(data) {
        this.kustomerAdapter._taskStatus = 'ended';
        this.kustomerAdapter.createNote(data);
    }

    incomingCallCallback(data) {
        this.kustomerAdapter._taskStatus = 'wip';

        this.kustomerAdapter.handleCallReceived(data).catch(err => {
            kustomerLog('incomingCallCallback error', err);
        });
    }

    public processEndedCallContact(contact: ContactBase) {
        // const callData = {
        //     call: {
        //         id: contact.contactId,
        //     },
        // };
        if (!this.callData.call?.id) {
            this.callData.call = {
                id: contact.contactId,
            };
        }
        this.callData.call.endedAt = new Date().toISOString();

        if (!this.callData.meta) {
            this.callData.meta = {};
        }
        this.callData.meta.endedAt = new Date().toISOString();

        // kustomerLog('processEndedCallContact', this.callData);

        this.endCallCallback(this.callData);
    }

    public processIncomingCallContact(contact: ContactBase) {
        const from = contact.ANI;
        const to = contact.DNIS;

        // if (from[0] === '0') {
        //     from = from.substring(1, from.length);
        // }
        // if (to[0] === '0') {
        //     to = to.substring(1, to.length);
        // }

        // from = from[0] === '+' ? from : `+${from}`;
        // to = to[0] === '+' ? to : `+${to}`;

        this.callData = {
            call: {
                id: contact.contactId,
                direction: contact.isInbound ? 'inbound' : 'outbound',
                from,
                to,
                placedAt: new Date().toISOString(),
            },
            task: {
                attributes: {
                    caller: from,
                },
            },
        };

        // kustomerLog('processIncomingCallContact Call Data: ', this.callData);

        this.incomingCallCallback(this.callData);
    }

    processContact(contactVM: ContactViewBaseModel<ContactBase>) {
        const settings = contactVM.settings;
        this.crmDataHolder = null;

        const data: CrmDataHolderViewModel = new CrmDataHolderViewModel({
            zendeskUserResult: null,
            ticketResult: null,
            zendeskUser: null,
            dynamicsUserResult: null,
            dynamicsUser: null,
            dynamicsCase: null,
            dynamicsCaseResult: null,
            kustommerUserResult: null,
            kustommerUser: null,
        });

        const process: Observable<any> = new Observable(obsv => {
            obsv.next(data);
            obsv.complete();
        });

        if (contactVM.contact.ticketPayload?.ticketId) {
            // process = process.pipe(
            //     mergeMap((v, d) => this.getCrmTicket(contactVM, data, contactVM.contact.ticketPayload.ticketId!)),
            //     mergeMap((v, d) => {
            //         return this.OpenCrmTicket(contactVM, data);
            //     }),
            // );
            // process.subscribe(r => {
            //     if (data.ticket) {
            //         contactVM.contact.ticketId = `${data.ticket.id}`;
            //         contactVM['ticketId'] = data.ticket.id;
            //         contactVM.contactService.currentContactViewModels.value
            //             .filter(x => x.contact.contactId == contactVM.contact.contactId)
            //             .forEach(x => {
            //                 x.contact.ticketId = data.ticket.id;
            //             });
            //     }
            //     this.addToProcessedContacts(contactVM);
            // });
        } else if (!contactVM.isCrmProcessable()) {
            return;
        } else {
            const obj = this.getProcessedContact(contactVM);

            if (obj) {
                if (!contactVM.contact.ticketId || `${contactVM.contact.ticketId}`.trim().length == 0) {
                    data.ticket = {id: obj.ticketId};
                    contactVM.contact.ticketId = obj.ticketId;
                    //process = process.flatMap((v, d) => this.OpenCrmTicket(contactVM, data));
                } else if (contactVM.contact.ticketId && `${contactVM.contact.ticketId}`.trim().length > 0) {
                    contactVM.contact.ticketId = obj.ticketId;
                    data.ticket = {id: contactVM.contact.ticketId};
                    //process = process.flatMap((v, d) => this.OpenCrmTicket(contactVM, data));
                }
                return;
            } else {
                this.addToProcessedContacts(contactVM);

                const customSearch = false;

                const screenPopUrl = contactVM.contact.ScreenPopUrl;

                if (!!screenPopUrl && screenPopUrl.includes('ScreenPopPrintVars')) {
                    const parts = screenPopUrl.split('?');
                    if (parts.length === 2) {
                        const params = parseQueryString(parts[1]);
                        if (Object.keys(params).length > 0) {
                            contactVM.contact.ScreenPopUrlVariables = [params];
                        }
                    }
                }

                const customSearchResult: CustomSearchResult = {foundUser: false, foundTicket: false};
                const customSearchPromise = new Promise(async (resolve: (sRes: CustomSearchResult) => void, reject) => {
                    // if (contactVM.contact.ScreenPopUrlVariables?.length) {
                    //     for (const popVar of contactVM.contact.ScreenPopUrlVariables) {
                    //         for (const key of Object.keys(popVar)) {
                    //             const result = await this.customCrmSearch(contactVM, popVar[key], data).toPromise();

                    //             // console.log('custom search result', result, data);

                    //             if (result.found) {
                    //                 customSearch = true;
                    //                 if (result.type === 'case') {
                    //                     this.OpenCrmTicket(contactVM, data);

                    //                     customSearchResult.foundTicket = true;
                    //                     resolve(customSearchResult);
                    //                     return;
                    //                 } else if (result.type === 'user') {
                    //                     customSearchResult.foundUser = true;
                    //                     this.OpenCrmUser(contactVM, data);
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                    resolve(customSearchResult);
                });

                customSearchPromise.then(customSearchPromiseResult => {
                    this.callData = {
                        call: {
                            id: contactVM.contact.contactId,
                            answeredAt: new Date().toISOString(),
                        },
                    };

                    // this.callData.call.answeredAt = new Date().toISOString();

                    // kustomerLog('handleAnswerCall', this.callData);
                    this.kustomerAdapter.handleAnswerCall(this.callData).then(v => {
                        kustomerLog(v);
                    });

                    // if (!customSearchPromiseResult.foundTicket) {
                    //     if (settings.creatTicketAlways) {
                    //         //Priority 1
                    //         process = process.pipe(
                    //             mergeMap((v, d) => {
                    //                 if (!data.dynamicsUser) {
                    //                     // if (settings.searchUser) {
                    //                     if (settings.searchForLevel1 === 'Contact') {
                    //                         return this.findCrmUser(contactVM, data).pipe(
                    //                             catchError(
                    //                                 err =>
                    //                                     new Observable(r => {
                    //                                         r.next(data);
                    //                                         r.complete();
                    //                                     }),
                    //                             ),
                    //                         );
                    //                     } else {
                    //                         return this.buildCrmUserObject(contactVM, data).pipe(
                    //                             mergeMap((v, d) =>
                    //                                 this.createCrmUser(contactVM, data).pipe(
                    //                                     catchError(
                    //                                         err =>
                    //                                             new Observable(r => {
                    //                                                 r.next(data);
                    //                                                 r.complete();
                    //                                             }),
                    //                                     ),
                    //                                 ),
                    //                             ),
                    //                         );
                    //                     }
                    //                 } else {
                    //                     return new Observable(sub => {
                    //                         sub.next(data);
                    //                         sub.complete();
                    //                     });
                    //                 }
                    //             }),
                    //             mergeMap((v, d) => {
                    //                 if (data.dynamicsUser) {
                    //                     if (settings.creatUserAlways) {
                    //                         return this.buildCrmUserObject(contactVM, data).pipe(
                    //                             mergeMap((v, d) =>
                    //                                 this.createCrmUser(contactVM, data).pipe(
                    //                                     catchError(
                    //                                         err =>
                    //                                             new Observable(r => {
                    //                                                 r.next(data);
                    //                                                 r.complete();
                    //                                             }),
                    //                                     ),
                    //                                 ),
                    //                             ),
                    //                             mergeMap((v, d) => this.buildCrmTicketObject(contactVM, data)),
                    //                             mergeMap((v, d) => this.createCrmTicket(contactVM, data)),
                    //                             mergeMap((v, d) => this.OpenCrmTicket(contactVM, data)),
                    //                         );
                    //                     } else {
                    //                         return this.buildCrmTicketObject(contactVM, data).pipe(
                    //                             mergeMap((v, d) => this.createCrmTicket(contactVM, data)),
                    //                             mergeMap((v, d) => this.OpenCrmTicket(contactVM, data)),
                    //                         );
                    //                     }
                    //                 } else {
                    //                     if (data.dynamicsUserResult && data.dynamicsUserResult.length > 1) {
                    //                         //return this.notifyUser(contactVM, { "Subject": "Multiple Records Found", "MessageText":
                    // `${data.userResult.count} users found against ${contactVM.configMap.incontactSearchField}
                    // ${contactVM.getContactPropertyValueByKey(contactVM.configMap.incontactSearchField)}` });
                    //                         return this.buildCrmUserObject(contactVM, data).pipe(
                    //                             mergeMap((v, d) =>
                    //                                 this.createCrmUser(contactVM, data).pipe(
                    //                                     catchError(
                    //                                         err =>
                    //                                             new Observable(r => {
                    //                                                 r.next(data);
                    //                                                 r.complete();
                    //                                             }),
                    //                                     ),
                    //                                 ),
                    //                             ),
                    //                             mergeMap((v, d) => this.buildCrmTicketObject(contactVM, data)),
                    //                             mergeMap((v, d) => this.createCrmTicket(contactVM, data)),
                    //                             mergeMap((v, d) => this.OpenCrmTicket(contactVM, data)),
                    //                         );
                    //                     } else {
                    //                         return this.buildCrmUserObject(contactVM, data).pipe(
                    //                             mergeMap((v, d) =>
                    //                                 this.createCrmUser(contactVM, data).pipe(
                    //                                     catchError(
                    //                                         err =>
                    //                                             new Observable(r => {
                    //                                                 r.next(data);
                    //                                                 r.complete();
                    //                                             }),
                    //                                     ),
                    //                                 ),
                    //                             ),
                    //                             mergeMap((v, d) => this.buildCrmTicketObject(contactVM, data)),
                    //                             mergeMap((v, d) => this.createCrmTicket(contactVM, data)),
                    //                             mergeMap((v, d) => this.OpenCrmTicket(contactVM, data)),
                    //                         );
                    //                     }
                    //                 }
                    //             }),
                    //         );
                    //     } else {
                    //         if (!data.dynamicsUser) {
                    //             if (settings.creatUserAlways) {
                    //                 process = process.pipe(
                    //                     mergeMap((v, d) => this.buildCrmUserObject(contactVM, data)),
                    //                     mergeMap((v, d) => this.createCrmUser(contactVM, data)),
                    //                 );
                    //                 // } else if (settings.searchUser) {
                    //             } else if (settings.searchForLevel1 === 'Contact') {
                    //                 process = process.pipe(
                    //                     mergeMap((v, d) => {
                    //                         return this.findCrmUser(contactVM, data);
                    //                     }),
                    //                     mergeMap((v, d) => {
                    //                         // console.log(data.dynamicsUserResult, data.dynamicsUserResult?.length);
                    //                         if (data?.dynamicsUserResult?.length == 1) {
                    //                             // data.zendeskUser = data.dynamicsUserResult.results[0];
                    //                             return this.OpenCrmUser(contactVM, data);
                    //                             // return Observable.create(r => r.next(contactVM, data));

                    //                             // return new Observable<any>(obr => {
                    //                             //     obr.next({...data});
                    //                             //     obr.complete();
                    //                             // });
                    //                         } else if (data.dynamicsUserResult && data.dynamicsUserResult.length > 1) {
                    //                             return this.notifyUser(contactVM, {
                    //                                 Subject: 'Multiple Records Found',
                    //                                 MessageText: `${data.dynamicsUserResult.length} users found against ${
                    //                                     contactVM.configMap.incontactSearchField
                    //                                 } ${contactVM.getContactPropertyValueByKey(contactVM.configMap.incontactSearchField)}`,
                    //                             });
                    //                         } else {
                    //                             // console.log('create new user: ', settings);
                    //                             if (settings.creatTicketAlways || settings.creatUser) {
                    //                                 return this.buildCrmUserObject(contactVM, data).pipe(
                    //                                     mergeMap(d => this.createCrmUser(contactVM, data)),
                    //                                     mergeMap(d => this.OpenCrmUser(contactVM, data)),
                    //                                 );
                    //                             }
                    //                         }
                    //                         return new Observable(sub => {
                    //                             sub.next(data);
                    //                             sub.complete();
                    //                         });
                    //                     }),
                    //                 );
                    //             } else if (settings.creatUser) {
                    //                 process = process.pipe(
                    //                     mergeMap((v, d) => this.buildCrmUserObject(contactVM, data)),
                    //                     mergeMap(d => this.createCrmUser(contactVM, data)),
                    //                 );
                    //             }
                    //         }

                    //         // if (settings.searchTicket) {
                    //         if (settings.findRelated && settings.searchForLevel2 === 'Incident') {
                    //             process = process.pipe(
                    //                 mergeMap((v, d) => this.findCrmTicket(contactVM, data)),
                    //                 mergeMap(d => {
                    //                     // console.log('going to open ticket', contactVM.contact.ticketId);
                    //                     if (data.dynamicsCase) {
                    //                         return this.OpenCrmTicket(contactVM, data);
                    //                     } else if (data.dynamicsCaseResult && data.dynamicsCaseResult.length == 0) {
                    //                         if (settings.creatTicket) {
                    //                             return this.buildCrmTicketObject(contactVM, data).pipe(
                    //                                 mergeMap(dt => this.createCrmTicket(contactVM, data)),
                    //                                 mergeMap(d => this.OpenCrmTicket(contactVM, data)),
                    //                             );
                    //                         }
                    //                     } else if (d.dynamicsCaseResult && d.dynamicsCaseResult.length == 1) {
                    //                         return this.OpenCrmTicket(contactVM, data);
                    //                     } else if (d.dynamicsCaseResult && d.dynamicsCaseResult.length > 1) {
                    //                         return this.OpenCrmUser(contactVM, data);
                    //                     }
                    //                     return new Observable<any>(sub => {
                    //                         sub.next(data);
                    //                         sub.complete();
                    //                     });
                    //                 }),
                    //             );
                    //         } else if (settings.creatTicket) {
                    //             process = process.pipe(
                    //                 mergeMap((v, d) => this.buildCrmTicketObject(contactVM, data)),
                    //                 mergeMap(dt => this.createCrmTicket(contactVM, data)),
                    //                 mergeMap(d => this.OpenCrmTicket(contactVM, data)),
                    //             );
                    //         }
                    //     }
                    // }

                    process.subscribe(r => {
                        this.crmDataHolder = data;
                        // if (data.dynamicsCase) {
                        //     // contactVM.contact.ticketId = 'testing_123';
                        //     contactVM.contact.ticketId = `${data.dynamicsCase.ticketnumber}`;

                        //     if (contactVM.contact.contactType === 'WorkItem') {
                        //         contactVM['ticketId'] = data.dynamicsCase.incidentid;
                        //     } else {
                        //         contactVM['ticketId'] = data.dynamicsCase.ticketnumber;
                        //     }

                        //     contactVM.contactService.currentContactViewModels.value
                        //         .filter(x => x.contact.contactId == contactVM.contact.contactId)
                        //         .forEach(x => {
                        //             x.contact.ticketId = data.dynamicsCase.ticketnumber;
                        //         });

                        //     /*  setTimeout(() => {
                        //          this.processContact(contactVM);
                        //      }, 5000); */
                        // }

                        this.addToProcessedContacts(contactVM);
                    });
                });
            }
        }

        /*
        if (settings && settings.searchUser) { // user search is enabled
            var searchVal = contact[context.configMap.incontactSearchField];
            process = process.flatMap(d => this.findCrmUser());
        }
        if (settings && settings.creatTicket) { // create ticket is enabled, create ticket
            process = process.flatMap(d => this.createCrmTicket()).flatMap(d => this.OpenCrmTicket());

        }
        else { //Dont search user
            if (settings && settings.creatUser) {//Just only Create user enabled
                //just only create user
                this.alwaysCreateUser(data, context);
            }
        }
            */
    }

    protected findCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable<any>(observer => {
            const searchField = viewmodel.configMap.zendeskSearchField;
            const searchOperator = viewmodel.configMap.zendeskSearchOperator;
            const value = viewmodel.contact.rawData[viewmodel.configMap.incontactSearchField];

            if (!value || value.trim().length == 0) {
                data.dynamicsUserResult = [];
                // data.userResult.count = 0;
                observer.next(data);
                observer.complete();
                return;
            }

            Microsoft.CIFramework.searchAndOpenRecords('contact', `?$filter=${searchField} eq '${value}'`, false)
                .then(
                    result => {
                        const userData: {[key: string]: DynamicsContactModel} = JSON.parse(result);
                        // console.log('search crm user result: ', userData, typeof userData, userData.length, Object.keys(userData));

                        data.dynamicsUserResult = [];
                        for (const key in userData) {
                            data.dynamicsUserResult.push(userData[key]);
                        }

                        // console.log(`Record values: Full Name: ${res[0].fullname}, Telephone Number: ${res[0].telephone1}`);
                        // perform operations on record retrieval and opening

                        if (data.dynamicsUserResult?.length == 1) {
                            data.dynamicsUser = data.dynamicsUserResult[0];
                        }
                        if (data.dynamicsUserResult.length === 0) {
                            this.messagesSrv.notify({
                                Type: 'info',
                                Subject: 'User Not found',
                                MessageText: 'No User found on Dynamics.',
                                timeout: 10000,
                            });
                        }
                        observer.next(data);
                        observer.complete();
                    },
                    error => {
                        console.log(error.message);
                        // handle error conditions
                    },
                )
                .catch(err => {
                    console.log('findContact Error: ', err);
                });
        });
    }

    protected createCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(r => {
            // try {
            //     window.zClient
            //         .request({
            //             url: '/api/v2/users.json',
            //             type: 'POST',
            //             data: {user: data.userObject},
            //         })
            //         .then(
            //             result => {
            //                 data.zendeskUser = result.user;
            //                 r.next(data);
            //             },
            //             error => {
            //                 // r.error(this.handleError('user', error));
            //                 // r.next(data);
            //             },
            //         );
            // } catch (error) {
            //     //throw this.handleError('user', error);
            //     //r.next(data);
            // } finally {
            //     //return subject;
            // }

            const jsonData = JSON.stringify(data.userObject);
            Microsoft.CIFramework.createRecord('contact', jsonData).then(
                result => {
                    const res = JSON.parse(result);

                    data.dynamicsUser = new DynamicsContactModel();
                    data.dynamicsUser.contactid = res.id;
                    // console.log('created user: ', res);

                    r.next(res);
                    r.complete();
                },
                error => {
                    console.log(error);
                    //handle error conditions

                    r.complete();
                },
            );
        });
    }

    protected OpenCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable<any>(observer => {
            const contactid = data.dynamicsUser.contactid;

            Microsoft.CIFramework.searchAndOpenRecords('contact', `?$filter=contactid eq '` + `${contactid}` + `'`, false)
                .then(
                    result => {
                        const userData: Array<DynamicsContactModel> = JSON.parse(result);
                        // console.log('search result: ', userData, typeof userData);
                        // data.dynamicsUserResult = userData;
                        // // console.log(`Record values: Full Name: ${res[0].fullname}, Telephone Number: ${res[0].telephone1}`);
                        // // perform operations on record retrieval and opening

                        // if (data.dynamicsUserResult?.length == 1) {
                        //     data.dynamicsUser = data.dynamicsUserResult[0];
                        // }
                        // if (userData?.length === 0) {
                        //     this.messagesSrv.notify({
                        //         Type: 'info',
                        //         Subject: 'User Not found',
                        //         MessageText: 'No User found on Dynamics.',
                        //         timeout: 10000,
                        //     });
                        // }

                        data.dynamicsUserResult = [];
                        for (const key in userData) {
                            data.dynamicsUserResult.push(userData[key]);
                        }

                        if (data.dynamicsUserResult?.length == 1) {
                            data.dynamicsUser = data.dynamicsUserResult[0];
                        }

                        observer.next(data);
                        observer.complete();
                    },
                    error => {
                        console.log(error.message);
                        // handle error conditions
                    },
                )
                .catch(err => {
                    console.log('findContact Error: ', err);
                });
        });
    }

    //bb002798-a605-ec11-94ef-000d3a59bf1c

    protected findCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable<any>(observer => {
            if (data.dynamicsUser) {
                Microsoft.CIFramework.searchAndOpenRecords('incident', `$filter=customerid_contact/contactid eq ${data.dynamicsUser.contactid}`, true).then(
                    contactRes => {
                        const caseResObj = JSON.parse(contactRes);
                        // console.log('incident response object: ', caseResObj);

                        data.dynamicsCaseResult = [];
                        for (const key in caseResObj) {
                            data.dynamicsCaseResult.push(caseResObj[key]);
                        }

                        if (data.dynamicsCaseResult?.length == 1) {
                            data.dynamicsCase = data.dynamicsCaseResult[0];
                            viewmodel.contact.ticketId = data.dynamicsCase.ticketnumber;
                        }

                        if (data.dynamicsCaseResult.length === 0) {
                            this.caseNotFound();
                        }

                        observer.next(data);
                        observer.complete();
                    },
                    error => {
                        console.log(error);
                        observer.complete();
                        //handle error conditions
                    },
                );
                //             // const jsonData = JSON.stringify({
                //             //     // 'primarycontactid@odata.bind': `/contacts(${data.dynamicsUser.contactid})`,
                //             //     'customerid_contact@odata.bind': `/contacts(${data.dynamicsUser.contactid})`,
                //             //     description: 'Testing create case functionality',
                //             //     title: 'demo title',
                //             // });
                //             // console.log('new case data', jsonData);
                //             Microsoft.CIFramework.searchAndOpenRecords('incident', "?$filter=customerid_contact eq '" +
                //  `${data.dynamicsUser.contactid}` + "'", false).then(
                //                 contactRes => {
                //                     const contactResObj = JSON.parse(contactRes);

                //                     console.log('incident response object: ', contactResObj);
                //                 },
                //                 error => {
                //                     console.log(error);
                //                     //handle error conditions
                //                 },
                //             );
            } else {
                observer.complete();
            }
        });
    }

    protected getCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: any, id: string): Observable<any> {
        return new Observable<any>(observer => {
            Microsoft.CIFramework.retrieveRecord('incident', id + '').then(
                contactRes => {
                    const caseResObj = JSON.parse(contactRes);

                    console.log('getCrm ticket', caseResObj);

                    data.dynamicsCaseResult = [];
                    if (!!caseResObj) {
                        data.dynamicsCaseResult = [caseResObj];
                    }

                    if (data.dynamicsCaseResult?.length == 1) {
                        data.dynamicsCase = data.dynamicsCaseResult[0];
                        viewmodel.contact.ticketId = data.dynamicsCase.ticketnumber;
                    }

                    if (data.dynamicsCaseResult.length === 0) {
                        this.caseNotFound();
                    }

                    observer.next(data);
                    observer.complete();
                },
                error => {
                    this.caseNotFound();
                    console.log('error: ', error);
                    observer.complete();
                    //handle error conditions
                },
            );
        });
    }

    private caseNotFound() {
        this.messagesSrv.notify({
            Type: 'info',
            Subject: 'Case Not found',
            MessageText: 'No Case found on Dynamics.',
            timeout: 10000,
        });
    }

    protected OpenCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(observer => {
            Microsoft.CIFramework.searchAndOpenRecords('incident', `$filter=incidentid eq ${data.dynamicsCase.incidentid}`, false).then(
                contactRes => {
                    observer.next(data);
                    observer.complete();
                },
                error => {
                    console.log(error);
                    observer.complete();
                },
            );
        });
    }

    protected createCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(observer => {
            const obj = JSON.stringify(data.caseObject);
            Microsoft.CIFramework.createRecord('incident', obj).then(res => {
                const resObj = JSON.parse(res);
                // console.log('create incident res', resObj);

                if (resObj.id) {
                    Microsoft.CIFramework.searchAndOpenRecords('incident', `$filter=incidentid eq ${resObj.id}`, true).then(
                        contactRes => {
                            const caseResObj = JSON.parse(contactRes);

                            data.dynamicsCaseResult = [];
                            for (const key in caseResObj) {
                                data.dynamicsCaseResult.push(caseResObj[key]);
                            }

                            if (data.dynamicsCaseResult?.length == 1) {
                                data.dynamicsCase = data.dynamicsCaseResult[0];
                                viewmodel.contact.ticketId = data.dynamicsCase.ticketnumber;
                            }

                            observer.next(data);
                            observer.complete();
                        },
                        error => {
                            console.log(error);
                            observer.complete();
                            //handle error conditions
                        },
                    );
                } else {
                    observer.complete();
                }
            });

            // window.zClient
            //     .request({
            //         url: '/api/v2/tickets.json',
            //         type: 'POST',
            //         data: {ticket: data.ticketObject},
            //     })
            //     .then(tic => {
            //         data.ticket = tic.ticket;
            //         observer.next(data);
            //         viewmodel.contact.ticketId = tic.ticket.id;
            //         observer.complete();
            //     })
            //     .catch(error => {
            //         // observer.throw(error);
            //         observer.error(error);
            //     });
        });
    }

    protected buildCrmUserObject(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(oberver => {
            const obj: any = {};
            const settings = viewmodel.settings;
            const configMap = viewmodel.configMap;

            console.log('config map: ', configMap);
            // console.log('viewmodel : ', viewmodel);

            const icSearchFieldValue = viewmodel.getContactPropertyValueByKey(configMap.incontactSearchField ? configMap.incontactSearchField : 'ContactID');
            //data.contact[(configMap.incontactSearchField ? configMap.incontactSearchField : "ContactID")];
            const zdSearchFieldName = configMap.zendeskSearchField;
            obj.firstname = configMap.userTitle;
            obj.lastname = icSearchFieldValue;
            if (zdSearchFieldName) {
                obj[zdSearchFieldName] = icSearchFieldValue;
            }

            if (configMap.isCallContact && settings.e164enabled) {
                obj.shared_phone_number = true;
            }
            data.userObject = obj;

            oberver.next(data);
            oberver.complete();
        });
    }

    protected buildCrmTicketObject(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(oberver => {
            const contactSettings = viewmodel.settings; //this.getContactSettings(data.contact);
            const configMap = viewmodel.configMap;
            const phone = viewmodel.contact.rawData[configMap.incontactSearchField];
            const user = data.zendeskUser;

            const obj: DynamicsCaseModel = new DynamicsCaseModel({
                title: !contactSettings.ticketSubject ? `New Ticket` : viewmodel.replaceTokensWithValues(contactSettings.ticketSubject),
            });
            // if (contactSettings.defaultGroup && parseInt(contactSettings.defaultGroup)) {
            //     obj.group_id = parseInt(contactSettings.defaultGroup);
            // }
            // if (contactSettings.ticketTags) {
            //     obj.tags = viewmodel.replaceTokensWithValues(contactSettings.ticketTags);
            // }

            obj.description = '';
            if (viewmodel.contact.contactType == 'EmailContactEvent') {
                //obj.comment.body = this.getContactString(data, context);
                obj.description = viewmodel.toContactString();
            } else {
                //obj.comment.body = (!contactSettings.ticketComments ? 'New ticket details...' :
                // this.replaceTokensWithValues(contactSettings.ticketComments, data, context));
                obj.description = !contactSettings.ticketComments ? 'New ticket details...' : viewmodel.replaceTokensWithValues(contactSettings.ticketComments);
            }

            // obj.comment.public = false;
            // obj._customerid_value = data.dynamicsUser.contactid;
            obj.bindCustomer(data.dynamicsUser.contactid);

            // obj.assignee_id = this.currentZendeskUser.id;
            // obj.via_id = this.setTicketAudit(viewmodel.contact.rawData);

            data.caseObject = obj;

            oberver.next(obj);
        });
    }
}
