import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {overlayConfigFactory} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {LocalStorageService} from 'ngx-webstorage';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';
import {integrationManagerService} from 'src/app/core/uac-crm-integration/integrationManager.service';
import {SelectCrmModalComponent} from 'src/app/core/uac-crm-integration/select-crm-form/select-crm.component';
import {Agent} from 'src/app/core/_models/agent.model';
import {NextEventService} from 'src/app/core/_services/next-events.service';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
import {IcOpenIdApi} from 'src/app/sdk/incontact/api/openid/ic-openid.api';
import {LoginWithCodeResponse} from 'src/app/sdk/incontact/models';
import {IcAuthService, IcSecurityProfileService} from 'src/app/sdk/incontact/services';
import moment from 'moment';
import {IcAgentSessionService} from 'src/app/sdk/incontact/services/agent-sessions/agent-session-events.service';
import {ConstantPool} from '@angular/compiler';
import {FacLocalStorageService} from 'src/app/sdk/storage';
import {TrackJS} from 'trackjs';
import {Subscription} from 'rxjs';
import {CrmApplicationModel, AgentPublicProfileViewModel} from 'src/app/sdk/fac-crm/models';
import {environment} from 'src/environments/environment';
import {ErrorModel} from 'src/app/core/_models/error.model';
import {ErrorService} from 'src/app/core/_services/error.service';

@Injectable()
export class AuthenticationService {
    langs: Array<string> = [];
    currentLang = '';

    private _agentPublicProfile: AgentPublicProfileViewModel = new AgentPublicProfileViewModel();
    set agentPublicProfile(data: AgentPublicProfileViewModel) {
        this._agentPublicProfile = new AgentPublicProfileViewModel(data);
    }
    get agentPublicProfile() {
        return this._agentPublicProfile;
    }

    private loginWithCodeResponse: LoginWithCodeResponse = null;
    private isAuthenticated = false;

    constructor(
        private icAgentSessionSrv: IcAgentSessionService,
        private translateSrv: TranslateService,
        private integrationManager: integrationManagerService,
        private loaderSrv: NgxUiLoaderService,
        private dialogSrv: DialogService,
        private icOpenIdApi: IcOpenIdApi,
        private router: Router,
        private icAuthSrv: IcAuthService,
        private localStorageService: LocalStorageService,
        private nextEventSrv: NextEventService,
        private errorService: ErrorService,
        private localStorageSettingsSrv: FacLocalStorageService,
        @Inject(IcSecurityProfileService) private securityProfileSrv: IcSecurityProfileService,
    ) {
        this.icAgentSessionSrv.updateIsSessionStarted(false);

        this.translateSrv.addLangs(environment.languages);
        this.langs = environment.languages;

        this.agentPublicProfile = this.localStorageService.retrieve('agentPublicProfile');

        if (this.agentPublicProfile?.company?.inContactSettings) {
            const icSettings = this.agentPublicProfile.company.inContactSettings;
            if (!!icSettings) {
                InContactApiSettings.agentSettings = this.agentPublicProfile.settings;
                InContactApiSettings.useCompanySSOSettings = this.agentPublicProfile.useCompanySSOSettings;
                InContactApiSettings.setSettings = icSettings;
            }
        }

        window.addEventListener('message', event => {
            if (event.data?.type === 'code') {
                console.log(event.data.code);
                // this.icOpenIdApi.getTokenByCode(event.data.code, InContactApiSettings.SSO_Callback).subscribe(
                //     res => {
                //         console.log(res);
                //         this.loginWithCodeResponse = res;

                //     },
                //     error => {
                //         console.log(error);
                //     },
                // );
                this.loaderSrv.start();
                this.icAuthSrv.authenticateUserByCode(this.agentPublicProfile.email, event.data.code, InContactApiSettings.SSO_Callback).subscribe(
                    res => {
                        this.loaderSrv.stop();
                        if (res.access_token) {
                            this.isAuthenticated = true;
                        } else {
                            this.isAuthenticated = false;
                        }
                        // this.icAuthSrv.refreshTokenSSO().subscribe(
                        //     refreshResult => {},
                        //     refreshError => {},
                        // );
                        this.router.navigate(['/authentication/login-step-two'], {
                            queryParams: {
                                type: 'code',
                            },
                        });
                    },
                    err => {
                        this.loaderSrv.stop();
                        this.isAuthenticated = false;
                    },
                );
            }
        });

        this.icAgentSessionSrv.sessionState.subscribe(state => {
            if (state === 'joined' || state === 'started') {
                this.nextEventSrv.GetNextEvent();
                this.router.navigate(['/active-contact']);

                try {
                    TrackJS.configure({
                        version: `${window.env} (${environment.VERSION})`,
                        userId: this.agentPublicProfile?.id + '',
                        sessionId: `${this.agentPublicProfile?.companyId}`,
                    });
                } catch (error) {
                    console.log(error);
                }

                // const jwtPayload = this.localStorageSettingsSrv.getJwtPayload();
                // if (!!jwtPayload) {
                //     this.securityProfileSrv.LoadSecurityProfile(jwtPayload.icSPId);
                // }
            }
        });

        this.loadPreviousSession();
    }

    public get webRTCAllowed() {
        if (this.agentPublicProfile?.company) {
            const found = this.agentPublicProfile?.crmApplications?.find(x => !!x.agentLoginTypeSettings?.WebRTC);
            return this.agentPublicProfile?.company?.webRTCEnabled && !!found;
        }
        return false;
    }

    public get phoneNumberSessionAllowed() {
        const found = this.agentPublicProfile?.crmApplications?.find(x => !!x.agentLoginTypeSettings?.PhoneNumber);
        return !!found;
    }

    public get stationIdSessionAllowed() {
        const found = this.agentPublicProfile?.crmApplications?.find(x => !!x.agentLoginTypeSettings?.StationId);
        return !!found;
    }

    loadPreviousSession() {
        if (this.icAgentSessionSrv.isUserAuthenticated()) {
            this.icAgentSessionSrv.authenticationContextService.tryReloadState();
            // const context = this.icAgentSessionSrv.getAuthContext();

            this.selectCRMApplication(this.agentPublicProfile, true);

            this.integrationManager.selectedCRMSubject.subscribe(res => {
                if (res) {
                    this.icAgentSessionSrv
                        .joinSession(this.icAgentSessionSrv.agent.sessionType)
                        .then(join => {
                            this.icAuthSrv.scheduleRefreshToken();
                            //this.inContactAPIService.scheduleRefreshToken();
                            this.icAgentSessionSrv.onSessionJoined();
                        })
                        .catch(sessionErr => {
                            this.icAgentSessionSrv.clear();
                        });
                }
            });
        }
    }

    LoginWithSSO() {
        const url =
            `${InContactApiSettings.ssoBaseUri}/auth/authorize?` +
            `client_id=${this.agentPublicProfile.company?.getClientId}` +
            `&login_hint=${this.agentPublicProfile.email}` +
            `&redirect_uri=${InContactApiSettings.SSO_Callback}` +
            `&scope=openid` +
            `&response_type=code`;
        // `&nonce=mayytljp578`
        console.log(url);
        const result = window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
        // console.log(result);
    }

    selectCRMApplication(result: AgentPublicProfileViewModel, isReload = false) {
        this.integrationManager.crmApplications = result.crmApplications;

        if (this.icAgentSessionSrv.applicationType && this.icAgentSessionSrv.applicationType !== '') {
            this.integrationManager.crmApplications = this.integrationManager.crmApplications.filter(
                e => e.applicationType == this.icAgentSessionSrv.applicationType,
            );
        }
        if (this.integrationManager.crmApplications.length > 1) {
            // this.spinnerService.isRunning = false;
        }
        switch (this.integrationManager.crmApplications.length) {
            case 0:
                this.afterCRMSelection(null, isReload);
                break;
            case 1:
                this.afterCRMSelection(this.integrationManager.crmApplications[0], isReload);
                break;
            default:
                //Launch
                this.openCrmSelectionDialog(result);
                break;
        }
    }

    loginWithCredentials() {
        localStorage.removeItem('businessunitinfo');

        return this.icAuthSrv.authenticateUser(this.icAgentSessionSrv.agent.userName, this.icAgentSessionSrv.agent.password).subscribe(
            res => {
                // console.log('login with password response ', res);
                this.loaderSrv.stop();
                this.afterAuthentication(res);
            },
            error => {
                this.loaderSrv.stop();
                this.icAgentSessionSrv.ProcessError(error);
                // this.spinnerService.isRunning = false;
            },
        );
    }

    private selectedCRMSubject$: Subscription;
    afterCRMSelection(selectCRM: CrmApplicationModel, isReload = false) {
        if (this.selectedCRMSubject$) {
            this.selectedCRMSubject$.unsubscribe();
        }

        if (selectCRM) {
            this.integrationManager.initSelectedCRM(selectCRM).then();

            this.selectedCRMSubject$ = this.integrationManager.selectedCRMSubject.subscribe((res: any) => {
                if (res) {
                    if (res.status) {
                        // this.spinnerService.isRunning = false;
                        this.ProcessAPIError(res.status, res.statusText);
                    } else {
                        if (!isReload) {
                            this.loaderSrv.start();
                            if (InContactApiSettings.isSSOLogin) {
                                if (this.isAuthenticated) {
                                    this.afterAuthentication(null);
                                }
                            } else {
                                this.loginWithCredentials();
                            }
                        }
                    }
                }
            });
        } else {
            // this.spinnerService.isRunning = false;
            this.ProcessAPIError('500', 'No CRM Application has been assigned to this user.');
        }
    }

    afterAuthentication(crm) {
        this.icAgentSessionSrv.saveAgentToLocalStorage(this.icAgentSessionSrv.agent);

        this.icAgentSessionSrv.onAuthenticated();
        // this.spinnerService.isRunning = false;
    }

    openCrmSelectionDialog(agentProfile: AgentPublicProfileViewModel) {
        // var res = this.modal.open(SelectCrmComponent, overlayConfigFactory({num1: 2, num2: 3}, BSModalContext));
        const modalRef = this.dialogSrv.openModal(
            SelectCrmModalComponent,
            overlayConfigFactory({num1: 2, num2: 3, dialogClass: 'modal-dialog'}, BSModalContext),
        );

        // modalRef.content.onClose.subscribe((result: boolean) => {
        //     console.log(this.integrationManager.selectedCRMFromDialog);
        //     this.spinnerService.isRunning = true;
        //     if (result && this.integrationManager.selectedCRMFromDialog) {
        //         this.afterCRMSelection(this.integrationManager.selectedCRMFromDialog);
        //     } else {
        //         this.backToLogin();
        //     }
        // });

        modalRef.result
            .then(result => {
                //console.log(this.integrationManager.selectedCRM)
                // this.spinnerService.isRunning = true;
                if (result && this.integrationManager.selectedCRMFromDialog) {
                    this.afterCRMSelection(this.integrationManager.selectedCRMFromDialog);
                } else {
                    this.loaderSrv.stop();
                }
            })
            .catch(err => {
                this.loaderSrv.stop();
            });
    }

    ProcessAPIError(errorNo, errorMsg) {
        const errorModel = new ErrorModel(errorNo, errorMsg);
        this.errorService.onAPIError(errorModel);
    }
}
