import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject, Observable} from 'rxjs';

import {InContactAPIService} from '../.././core/InContact/incontact-api.service';
import {SkillsModel} from '../.././core/InContact/models/skills.model';
import {DispositionModel} from '../.././core/_models/disposition.model';
import {ErrorModel} from '../.././core/_models/error.model';
import {Events, NextStates} from '.././InContact/models/events.model';

import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {APISystemObjectsService} from './api-system-objects.service';
import {ContactDataContainerService} from '../../core/_services/contactDataContainer.service';

@Injectable()
export class DispositionService {
    error: ErrorModel;

    public useDisposition = new BehaviorSubject<boolean>(false);
    public useSecondaryDisp = new BehaviorSubject<boolean>(false);

    public skillId: number;

    public showDisposition = new BehaviorSubject<boolean>(false);
    public skillName = new BehaviorSubject<string>('');
    public contactId = new BehaviorSubject<number>(0);
    public dispositionList$: BehaviorSubject<DispositionModel[]>;

    constructor(
        private inContactAPIService: InContactAPIService,
        private agentSessionService: IcAgentSessionService,
        private systemObjects: APISystemObjectsService,
        private contactDataContainer: ContactDataContainerService,
    ) {
        const dispositions = [];
        this.dispositionList$ = new BehaviorSubject<DispositionModel[]>(dispositions);
    }

    showDispositions(event: Events) {
        let skills: SkillsModel[];
        this.skillId = parseInt(event.Skill);

        this.systemObjects.getSkillInfo(this.skillId).subscribe(skillInfo => {
            skills = skillInfo;

            if (skills[0] !== undefined || skills[0] !== null) {
                if (skills[0].requireDisposition || (skills[0].dispositions && skills[0].dispositions.length > 0)) {
                    this.useDisposition.next(true);
                    this.useSecondaryDisp.next(skills[0].allowSecondaryDisposition);
                    this.showDisposition.next(true);
                    this.skillName.next(event.Skill);
                    this.contactId.next(event.ContactID);
                    this.getDispositionList(this.skillName.getValue());
                } else {
                    this.clearDispositions();
                }
            }
        });
    }

    clearDispositions() {
        this.showDisposition.next(false);
        this.skillName.next(null);
        this.contactId.next(null);
        this.skillId = 0;
    }

    getDispositionList(skillName: string) {
        this.inContactAPIService.getDispositionList(skillName).subscribe(disposition => {
            this.dispositionList$.next(disposition);
        });
    }

    setDisposition(contactId: number, dispositionId: string, Notes: string, secondaryId: string, useSecondaryDisp: boolean): Observable<any> {
        const contactDisp = this.dispositionList$.value.filter(disp => disp.dispositionId == dispositionId);
        const contactSecDisp = this.dispositionList$.value.filter(disp => disp.dispositionId == secondaryId);
        this.contactDataContainer.DispositionData = {
            primaryDisposition: contactDisp.length > 0 ? contactDisp[0] : null,
            secondaryDisposition: contactSecDisp.length > 0 ? contactSecDisp[0] : null,
            notes: Notes,
        };

        return this.inContactAPIService.setDisposition(contactId, dispositionId, Notes, secondaryId, useSecondaryDisp).pipe(
            map(
                res => {
                    this.showDisposition.next(false);
                },
                err => {
                    this.showDisposition.next(true);
                },
            ),
        );
    }
}
