import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ChatContactService} from '../../../core/_services/chat-contact.service';
import {EmailContactService} from '../../../core/_services/email-contact.service';
import {CallContactService} from '../../../core/_services/call-contact.service';
import {VoiceMailContactService} from '../../../core/_services/voice-mail-contact.service';
import {WorkItemContactService} from '../../../core/_services/work-item-contact.service';
import {ApplicationContextService} from '../../../core/_services/application-context.service';
import {AgentSkillAssignmentsModel} from '../../../core/InContact/models/agent-skill-assignments.model';
import {THIS_EXPR} from '@angular/compiler/src/output/output_ast';
import {AnyObject} from 'src/app/global';
import {FacLocalStorageService} from 'src/app/sdk/storage';

@Component({
    selector: 'app-active-contact',
    templateUrl: './contact-display.template.html',
    //styleUrls: ['./active-contact.component.css'],
})
export class ContactDisplayComponent implements OnInit, AfterViewInit, OnDestroy {
    private chatContacts: any = [];
    private emailContacts: any = [];
    outboundEmailSkills: any[] = [];
    submenus: any = {};
    legStatus = '';
    agent: any;
    parkedEmailTimer = null;

    //contact_ob_call_menu: boolean = false;

    addContactDropdownMenus: Array<AnyObject> = [];

    constructor(
        public chatContactService: ChatContactService,
        public emailcontactService: EmailContactService,
        public callContactService: CallContactService,
        public voicemailContactService: VoiceMailContactService,
        public workitemContactService: WorkItemContactService,
        public appContext: ApplicationContextService,
        protected facLocalStorageSrv: FacLocalStorageService,
    ) {
        //chatContactService.currentContacts.subscribe(chats => { this.chatContacts = chats });
        //emailcontactService.currentContacts.subscribe(emails => { this.emailContacts = emails });

        this.appContext.agentSessionService.legStatusLabel$.subscribe(r => {
            this.legStatus = r;
            console.log(r);
        });
        this.agent = this.appContext.agentSessionService.loadAgent();

        if (this.agent?.info?.requestContact) {
            this.addContactDropdownMenus.push({
                id: 'new-inbound-contact',
                text: 'New Inbound Contact',
                priority: 1,
            });
        }
    }

    ngOnInit() {
        this.addContactDropdownMenus.push({
            id: 'new-outbound-call',
            text: 'New Outbound Call',
            priority: 2,
            children: [
                {
                    id: 'dial-number',
                    text: 'Dial Number',
                    routerLink: '/dialpad',
                    priority: 1,
                },
                {
                    id: 'call-an-address-book',
                    text: 'Call an Address Book',
                    routerLink: '/address-book',
                    priority: 2,
                },
                {
                    id: '#divider',
                    priority: 3,
                },
                {
                    id: 'dial-from-history',
                    text: 'Dial from History',
                    routerLink: '/call-history',
                    priority: 4,
                },
            ],
        });

        this.appContext.agentSessionService.agentOutboundSkills.subscribe((skills: AgentSkillAssignmentsModel[]) => {
            //this.outboundCallSkills = skills.filter(s => s.isOutboundCallSkill());
            this.outboundEmailSkills = skills.filter(s => s.isOutboundEmaillSkill());

            // console.log('outbound email skills: ', this.outboundEmailSkills);

            const childLinks = this.outboundEmailSkills.map(x => ({
                id: x.skillId,
                text: x.skillName,
                type: 'new-outbound-email',
            }));

            if (this.outboundEmailSkills?.length) {
                this.addContactDropdownMenus = [
                    ...this.addContactDropdownMenus,
                    {
                        id: 'new-outbound-email',
                        text: 'New Outbound Email',
                        priority: 3,
                        children: childLinks,
                    },
                ];
            }
        });

        // window.addEventListener('message', event => {
        //     if (event?.data?.messageType === 'Loaded') {
        //         console.log(document.querySelector('iframe'), event);

        //         event.target[0].postMessage(
        //             {
        //                 messageType: 'Token',
        //                 token: this.facLocalStorageSrv.access_token,
        //                 issuer: 'MAX',
        //                 isCentral: false,
        //                 urls: {
        //                     authBaseUrl: 'https://na1.nice-incontact.com/',
        //                     inContactBaseUrl: 'https://home-c32.nice-incontact.com',
        //                 },
        //             },
        //             '*',
        //         );
        //     }
        // });
    }

    ngAfterViewInit() {
        this.stopParkedEmailsTimer();
        this.emailcontactService.updateParkedEmails();
        this.startParkedEmailsTimer();
    }

    ngOnDestroy() {
        this.stopParkedEmailsTimer();
    }

    TestDynamics() {
        //bb002798-a605-ec11-94ef-000d3a59bf1c
        //"4f82a751-bd09-ec11-b6e6-002248043e41"

        // Microsoft.CIFramework.searchAndOpenRecords(
        //     'systemuser',
        //     `?$filter=internalemailaddress eq 'ali.shahzad@frontline.group'`,
        //     // `$filter=phonecall_activity_parties/contactid eq bb002798-a605-ec11-94ef-000d3a59bf1c`,
        //     true,
        // ).then(
        //     contactRes => {
        //         const contactResObj = JSON.parse(contactRes);
        //         console.log('phonecall response object: ', contactResObj);
        //     },
        //     error => {
        //         console.log(error);
        //         //handle error conditions
        //     },
        // );

        Microsoft.CIFramework.searchAndOpenRecords(
            'phonecall',
            `?$filter=activityid eq 'e0761f73-a01b-ec11-b6e6-002248097d35'`,
            // `$filter=phonecall_activity_parties/contactid eq bb002798-a605-ec11-94ef-000d3a59bf1c`,
            true,
        ).then(
            contactRes => {
                const contactResObj = JSON.parse(contactRes);
                console.log('phonecall response object: ', contactResObj);
            },
            error => {
                console.log(error);
                //handle error conditions
            },
        );

        // Microsoft.CIFramework.searchAndOpenRecords(
        //     'incident',
        //     `$filter=customerid_contact/contactid eq '9a25c5ca-a91b-ec11-b6e6-002248097d35'`,
        //     // "?$filter=telephone1 eq '01111436937312137'",
        //     // `$filter=phonecall_activity_parties/contactid eq bb002798-a605-ec11-94ef-000d3a59bf1c`,
        //     true,
        // ).then(
        //     contactRes => {
        //         const contactResObj = JSON.parse(contactRes);
        //         console.log('phonecall response object: ', contactResObj);
        //     },
        //     error => {
        //         console.log(error);
        //         //handle error conditions
        //     },
        // );

        // Microsoft.CIFramework.retrieveRecord('incident', '45a9eedb-ab1b-ec11-b6e6-002248097d35').then(
        //     contactRes => {
        //         const caseResObj = JSON.parse(contactRes);
        //         console.log('incident response object: ', caseResObj);
        //     },
        //     error => {
        //         //handle error conditions
        //     },
        // );

        // const jsonData = JSON.stringify({
        //     subject: 'email subject',
        //     sender: 'ali.shahzad@frontline.group',
        //     description: 'phone call description',
        // });
        // Microsoft.CIFramework.createRecord('email', jsonData).then(
        //     result => {
        //         const res = JSON.parse(result);

        //         console.log('created email: ', res);
        //     },
        //     error => {
        //         console.log(error);
        //     },
        // );
    }

    onAddContactDropdownClick(menu: AnyObject) {
        console.log(menu);
        if (menu?.type === 'new-outbound-email') {
            this.outboundEmail(menu.id);
        }
    }

    btnClick(e, selector: string) {
        if (!selector) {
        }
        // console.log(e);
        e.preventDefault();
        e.stopPropagation();
        for (const o in this.submenus) {
            if (o == selector) {
                continue;
            }
            this.submenus[o] = false;
        }
        this.submenus[selector] = !this.submenus[selector];
    }

    outboundEmail(skillId: string) {
        this.emailcontactService.newOutboundEmail(skillId).subscribe();
    }

    addContact() {
        this.appContext.agentSessionService.addContact();
    }

    connectLeg() {
        this.appContext.webRtcSrv.isConnectAgentLeg = true;
        this.appContext.inContactAPIService.dialLeg().subscribe();
    }

    startParkedEmailsTimer() {
        const me = this;
        this.parkedEmailTimer = setInterval(() => {
            me.emailcontactService.updateParkedEmails();
        }, 10000);
    }

    stopParkedEmailsTimer() {
        clearInterval(this.parkedEmailTimer);
    }
}
