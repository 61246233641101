import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {ActiveContactRoutingModule} from './active-contact.routing';
//import { ActiveContactComponent } from './active-contact.component';
import {ContactDisplayComponent} from './contact-display/contact.display.component';
import {ContactGroupComponent} from './components/contact-group/contact-group.component';
// import { QueueService }     from './call-history.service';

import {SharedModule} from '../../shared/shared.module';

// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import {TransferActiveContactModalComponent} from '../../shared/transfer-active-contact-modal/transfer-active-contact.component';

import {UacCrmIntegrationModule} from '../../core/uac-crm-integration/uac-crm-integration.module';

//import { IndicatorsFilterPipe } from '../../shared/pipes/indicators.filter';
//import { ContactActionFilter } from '../../shared/pipes/commonFilters';
import {ActiveContactIndicatorsComponent} from './components/indicators.menu';
import {ChatContactItemComponent} from './components/chatContactItem/chat-contact-item.component';
import {DispositionComponent} from '../../shared/disposition/disposition.component';
/* import { ContactElevationComponent } from '../contact-elevation/contact-elevation.component'; */

@NgModule({
    imports: [BrowserModule, FormsModule, ActiveContactRoutingModule, SharedModule, UacCrmIntegrationModule],
    declarations: [
        ContactDisplayComponent,
        ActiveContactIndicatorsComponent,
        ChatContactItemComponent,
        ContactGroupComponent /* , ContactElevationComponent */,
    ],
    exports: [ContactDisplayComponent, ContactGroupComponent],
    providers: [],
    entryComponents: [TransferActiveContactModalComponent],
})
export class ActiveContactModule {}
