import {Injectable, Inject, Optional} from '@angular/core';
import {DispositionModel} from '../.././core/_models/disposition.model';
import {CurrentContact} from '../.././core/_models/current-contact.model';
// import {LocalStorageService, SessionStorageService} from 'ng2-webstorage';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Injectable()
export class ContactDataContainerService {
    public CurrentContacts: any = {};
    public DispositionData: any = {};
    public currentTicket: string;

    public serviceError = new BehaviorSubject<any>(null);
    public CurrentContactArray = new BehaviorSubject(new Array<CurrentContact>());
    constructor(private sesstionStorageService: SessionStorageService, private localStorageService: LocalStorageService) {}

    handleError(error: any) {
        this.serviceError.next(error);
    }

    getCurrentContact(contactId) {
        return this.CurrentContactArray.getValue().find(e => e.contactId === contactId);
    }

    findCurrentTicketByContact(contactId) {
        return this.CurrentContactArray.getValue().findIndex(e => e.contactId === contactId);
    }

    removeCurrentTicket(contactId) {
        const index = this.findCurrentTicketByContact(contactId);
        this.CurrentContactArray.getValue().splice(index, 1);
    }

    updateContactTicketId(contactId, ticketId) {
        const index = this.findCurrentTicketByContact(contactId);

        if (index > -1) {
            const contacts = this.CurrentContactArray.getValue();
            contacts[index].ticketId = ticketId;

            this.CurrentContactArray.next(contacts);

            this.localStorageService.store('handledContacts', contacts);
        }
    }
}
