import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {DispositionComponent} from './disposition.component';

// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import {FocusDirective} from '../directives/focus.directive';

// export function createTranslateLoader(http: Http) {
//     return new TranslateStaticLoader(http, './assets/i18n', '.json');
// }

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        // TranslateModule.forRoot({
        //     provide: TranslateLoader,
        //     useFactory: createTranslateLoader,
        //     deps: [Http],
        // }),
    ],
    declarations: [DispositionComponent, FocusDirective],
    exports: [DispositionComponent, FocusDirective],
    providers: [],
})
export class DispositionModule {}
