import {Injectable} from '@angular/core';
import {Observable, Subject, BehaviorSubject} from 'rxjs';

@Injectable()
/** Dummy version of an authenticated user service */
export class IncomingContactService {
    public incomingContacts: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public Add(contactInfo: any) {
        console.log('adding contact');
        const existing = this.incomingContacts.getValue();
        const index = existing.findIndex(e => e.ContactID === contactInfo.ContactID);
        if (index == -1) {
            existing.push(contactInfo);
            this.incomingContacts.next(existing);
        }
    }

    public remove(contactId: string) {
        console.log('removing contact');
        const existing = this.incomingContacts.getValue();
        const index = existing.findIndex(e => e.ContactID === contactId);
        if (index > -1) {
            const contact = existing[index];
            existing.splice(index, 1);

            this.incomingContacts.next(existing);
            console.log('contact removed');
        }
    }
}
