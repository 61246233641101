import {Component, Inject, Input, OnInit} from '@angular/core';
import {DialogRef} from 'ngx-modialog-7';
import {ContactViewBaseModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {ApplicationContextService} from 'src/app/core/_services/application-context.service';
import {CRM_APPLICATION_TYPES} from 'src/app/global';

@Component({
    selector: 'app-ticket-selection-dialog',
    templateUrl: './ticket-selection-dialog.component.html',
    styleUrls: ['./ticket-selection-dialog.component.scss'],
})
export class TicketSelectionDialogComponent implements OnInit {
    public selectedTicket: number | string | null = null;

    @Input()
    contactVM: ContactViewBaseModel<any & ContactBase>;

    constructor(public dialog: DialogRef<any>, @Inject(ApplicationContextService) public appContext: ApplicationContextService) {
        //
    }

    ngOnInit(): void {
        //
    }

    public get CRM_APPLICATION_TYPES() {
        return CRM_APPLICATION_TYPES;
    }

    public listItemClick(event: any) {
        this.appContext.crmIntegrationService.onManualTicketSelection(this.contactVM, event.value);
    }

    public createTicket() {
        this.appContext.crmIntegrationService.createCrmTicketManuallay();
        this.selectedTicket = null;
        this.dialog.close();
    }

    public ok() {
        this.dialog.close();
    }
}
