import {Component, OnInit, ViewContainerRef, ViewEncapsulation, ElementRef, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {ChatRoomModel, Message} from '../.././core/_models/chat-room.model';
import {ChatContactService} from '../.././core/_services/chat-contact.service';
import {MessageService} from '../.././core/_services/message.service';

// import {
//     Modal,
//     BSModalContext,
// } from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal/plugins/bootstrap';
import {TransferActiveContactModalComponent, ViewModel} from '../../shared/transfer-active-contact-modal/transfer-active-contact.component';
// import {Overlay, overlayConfigFactory} from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal';
// import {LocalStorageService, SessionStorageService} from 'ng2-webstorage';
import {ContactDataContainerService} from '../../core/_services/contactDataContainer.service';
import {CurrentContact} from '../.././core/_models/current-contact.model';
import {ChatContactViewmodel} from 'src/app/core/_models/_viewmodels/chat-contact-viewmodel';
import {ApplicationContextService} from 'src/app/core/_services/application-context.service';
import {ContactViewBaseModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {SessionStorageService} from 'ngx-webstorage';

declare let $: JQueryStatic;
declare let Bloodhound;

@Component({
    selector: 'app-chat',
    //templateUrl: './chat.component.1.html',
    //styleUrls: ['./chat.component.css'],
    templateUrl: './chat.component1.html',
    styleUrls: ['./chat.component.scss'],
    // providers: [Modal],
})
export class ChatComponent implements OnInit {
    public activeViewModel: ChatContactViewmodel;
    public contacts: ChatContactViewmodel[] = [];

    public message: string;

    private endTypingIntervalId: any;
    private isTypinig = false;
    public ChatPatronTypingStatus: any = {};
    private parentContact: ContactViewBaseModel<ContactBase>;

    public newButtonEnabled = false;

    @ViewChild('chatPanel') private chatPanel: ElementRef;

    stateListData = [
        {name: 'Alabama', abbreviation: 'AL'},
        {name: 'Alaska', abbreviation: 'AK'},
        {name: 'AmericanSamoa', abbreviation: 'AS'},
        {name: 'Arizona', abbreviation: 'AZ'},
        {name: 'Arkansas', abbreviation: 'AR'},
        {name: 'California', abbreviation: 'CA'},
        {name: 'Colorado', abbreviation: 'CO'},
        {name: 'Connecticut', abbreviation: 'CT'},
        {name: 'Delaware', abbreviation: 'DE'},
        {name: 'DistrictOfColumbia', abbreviation: 'DC'},
        {name: 'FederatedStatesOfMicronesia', abbreviation: 'FM'},
        {name: 'Florida', abbreviation: 'FL'},
        {name: 'Georgia', abbreviation: 'GA'},
        {name: 'Guam', abbreviation: 'GU'},
        {name: 'Hawaii', abbreviation: 'HI'},
        {name: 'Idaho', abbreviation: 'ID'},
        {name: 'Illinois', abbreviation: 'IL'},
        {name: 'Indiana', abbreviation: 'IN'},
        {name: 'Iowa', abbreviation: 'IA'},
        {name: 'Kansas', abbreviation: 'KS'},
        {name: 'Kentucky', abbreviation: 'KY'},
        {name: 'Louisiana', abbreviation: 'LA'},
        {name: 'Maine', abbreviation: 'ME'},
        {name: 'MarshallIslands', abbreviation: 'MH'},
        {name: 'Maryland', abbreviation: 'MD'},
        {name: 'Massachusetts', abbreviation: 'MA'},
        {name: 'Michigan', abbreviation: 'MI'},
        {name: 'Minnesota', abbreviation: 'MN'},
        {name: 'Mississippi', abbreviation: 'MS'},
        {name: 'Missouri', abbreviation: 'MO'},
        {name: 'Montana', abbreviation: 'MT'},
        {name: 'Nebraska', abbreviation: 'NE'},
        {name: 'Nevada', abbreviation: 'NV'},
        {name: 'NewHampshire', abbreviation: 'NH'},
        {name: 'NewJersey', abbreviation: 'NJ'},
        {name: 'NewMexico', abbreviation: 'NM'},
        {name: 'NewYork', abbreviation: 'NY'},
        {name: 'NorthCarolina', abbreviation: 'NC'},
        {name: 'NorthDakota', abbreviation: 'ND'},
        {name: 'NorthernMarianaIslands', abbreviation: 'MP'},
        {name: 'Ohio', abbreviation: 'OH'},
        {name: 'Oklahoma', abbreviation: 'OK'},
        {name: 'Oregon', abbreviation: 'OR'},
        {name: 'Palau', abbreviation: 'PW'},
        {name: 'Pennsylvania', abbreviation: 'PA'},
        {name: 'PuertoRico', abbreviation: 'PR'},
        {name: 'RhodeIsland', abbreviation: 'RI'},
        {name: 'SouthCarolina', abbreviation: 'SC'},
        {name: 'SouthDakota', abbreviation: 'SD'},
        {name: 'Tennessee', abbreviation: 'TN'},
        {name: 'Texas', abbreviation: 'TX'},
        {name: 'Utah', abbreviation: 'UT'},
        {name: 'Vermont', abbreviation: 'VT'},
        {name: 'VirginIslands', abbreviation: 'VI'},
        {name: 'Virginia', abbreviation: 'VA'},
        {name: 'Washington', abbreviation: 'WA'},
        {name: 'WestVirginia', abbreviation: 'WV'},
        {name: 'Wisconsin', abbreviation: 'WI'},
        {name: 'Wyoming', abbreviation: 'WY'},
    ];
    typeAheadSetup = {
        customTemplate: '<div> {{item.name}}</div>',
        //    timeDelay: number;
        // type: 'static', //static || dynamic.  default value is dynamic
        placeHolder: 'State name',
        textPrperty: 'name',
        valueProperty: 'abbreviation',
        searchProperty: 'name',
        onSelect: (selectedItem: any) => {
            console.log(selectedItem);
        },
        asynchDataCall: (value: string, cb: any) => {
            const result = this.stateListData.filter((item: any) => item.name.indexOf(value) !== -1);
            //you can place your webservice call here
            setTimeout(() => {
                cb(result);
            }, 200);
        },
        //staticDataFilterkey: any;
        //staticData: stateListData
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public chatService: ChatContactService,
        private sessionStorageService: SessionStorageService,
        private contactDataContainer: ContactDataContainerService,
        private messageService: MessageService,
        private appContext: ApplicationContextService,
    ) {
        this.chatService.ChatPatronTypingStatus.subscribe(result => {
            this.ChatPatronTypingStatus = result;
            setTimeout(() => {
                this.scrollToBottom();
            }, 800);
        });

        chatService.currentContactViewModels.subscribe(contacts => {
            this.contacts = contacts;
            //if (this.contacts.length == 0)
            //this.router.navigate(["active-contact"]);
            const activeOnes = this.contacts.filter(c => c.contact.isActive());
            if (activeOnes.length > 0) {
                this.activeViewModel = activeOnes[0];

                setTimeout(() => {
                    this.scrollToBottom();
                }, 800);
            } else {
                const disconnectedOne = this.contacts.filter(c => c.contact.isDispositionVisible());

                if (disconnectedOne.length > 0) {
                    this.activeViewModel = disconnectedOne[0];
                } else {
                    //this.router.navigate(["active-contact"]);
                    this.chatService.navigateTo(['active-contact']);
                }
            }
        });
    }

    ngOnInit() {
        const me = this;
        this.messageService.setWindowTitle.next('Chat');
        this.activeViewModel.contact.unread = false;
        this.chatService.newChatMessage.subscribe(msg => {
            this.scrollToBottom();
        });

        this.appContext.inContactAPIService.getQuickReplies().subscribe(qr => {
            me.stateListData = qr
                .filter(item => item.skills.filter(x => x.skillId == this.activeViewModel.contact.skill).length > 0)
                .map(item => ({id: item.quickReplyId, title: item.title, content: item.content, keyWords: item.keyWords}));

            const states = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                local: me.stateListData,
            });

            states.initialize();

            const jqSelector = $(`input.chat-typeahead`);

            function nflTeamsWithDefaults(q, sync) {
                if (q === '') {
                    sync(states.all());
                } else {
                    states.search(q, sync);
                }
            }

            (jqSelector as any).typeahead(
                {
                    minLength: 0,
                    highlight: true,
                },
                {
                    name: 'quick-replies',
                    display: 'title',
                    source: nflTeamsWithDefaults,
                },
            );
        });
    }
    ngAfterviewInit() {}

    showChatRoom(chatRoom: any) {}

    addChat() {}

    scrollToBottom(): void {
        try {
            setTimeout(() => {
                this.chatPanel.nativeElement.scrollTop = this.chatPanel.nativeElement.scrollHeight;
            }, 2);
        } catch (err) {}
    }

    sendMessage() {
        if (this.message === '' || this.message === undefined) {return;}

        this.chatService.sendChatMessage(this.activeViewModel.contact.contactId, this.message);
        this.message = '';
    }

    sendTypingStatus() {
        const me = this;
        clearTimeout(me.endTypingIntervalId);
        if (!me.isTypinig) {
            me.chatService.sendTypingStatus({
                contactId: this.activeViewModel.contact.contactId,
                isTyping: true,
                isTextEntered: true,
            });
            me.isTypinig = true;
        }

        me.endTypingIntervalId = setTimeout(() => {
            me.chatService.sendTypingStatus({
                contactId: this.activeViewModel.contact.contactId,
                isTyping: false,
                isTextEntered: false,
            });
            me.isTypinig = false;
        }, 5000);
    }

    activateChat(contactId: string) {
        const chatContact = this.contacts.reduce(r => {
            if (r.contact.contactId == contactId) {return r;}
        });
        if (!chatContact) {return;}
        this.chatService.activateContact(chatContact.contact).subscribe(result => {
            this.activeViewModel = chatContact;
        });
    }

    handleOnQuickReplyInserting($event) {
        console.log($event);
        this.chatService.sendChatMessage(this.activeViewModel.contact.contactId, $event.content);
    }
}
