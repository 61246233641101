import {NgModule} from '@angular/core';

import {InContactAPIService} from './incontact-api.service';
import {AuthenticationContextService} from './_services/authentication-context.service';
import {FacLocalStorageService} from '../../sdk/storage/fac-local-storage.service';
import {ResourceOwnerPasswordAuthorizerApi} from 'src/app/sdk/incontact/api';
import {WebRTCService} from './web-rtc/web-rtc.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [InContactAPIService, WebRTCService, AuthenticationContextService, ResourceOwnerPasswordAuthorizerApi, FacLocalStorageService],
})
export class InContactModule {}
