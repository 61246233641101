export class PreviewScheduleViewModel {
    public primaryCallbackNumber: string;
    public primaryCallbackTime: string;
    public primaryDispositionId: number;
    public primaryDispositionNotes: string;

    constructor(data?: Partial<PreviewScheduleViewModel>) {
        Object.assign(this, data);
    }
}
