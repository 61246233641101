<div class="panel panel-default panel-top-most">
    <!-- Default panel contents -->
    <div role="heading" aria-level="3" class="panel-heading">
        <i
            class="fa fa-volume-control-phone fa-lg"
            aria-hidden="true"
            title="{{ 'App_Component.Footer.AutomatedOutbound' | translate }}"
        ></i
        >&nbsp; {{ 'App_Component.Footer.AutomatedOutbound' | translate }}
    </div>

    <div class="form-horizontal">
        <div class="form-group outbound-form-group">
            <div class="col-md-6 col-md-offset-3">
                <div class="input-group">
                    <!-- <span *ngIf="isPCSKillLoggedIn">{{dialerCampaign}}</span>
          <select *ngIf="!isPCSKillLoggedIn" [(ngModel)]="selectedSkill" name="search_filter" class="form-control">
            <option [ngValue]="preSelected">{{preSelected.skillName}}</option>
            <option *ngFor="let skill of skillslist" [ngValue]="skill">{{skill.skillName}}</option>
          </select>
          <span class="input-group-btn">
            <a *ngIf="!isPCSKillLoggedIn" (click)="loginPCSkill(selectedSkill)" class="btn btn-default">Login &nbsp;
              <i class="fa fa-sign-in" aria-hidden="true"></i>
            </a>
            <a *ngIf="isPCSKillLoggedIn" (click)="logoutPCSkill(selectedSkill)" class="btn btn-default">Logout &nbsp;
              <i class="fa fa-sign-in" aria-hidden="true"></i>
            </a>
          </span> -->
                    <span *ngIf="dialerCampaign">{{ dialerCampaign }}</span>
                    <select
                        *ngIf="!dialerCampaign"
                        [(ngModel)]="selectedSkill"
                        name="search_filter"
                        class="form-control"
                    >
                        <option [ngValue]="preSelected">{{ preSelected.skillName }}</option>
                        <option *ngFor="let skill of skillslist" [ngValue]="skill">{{ skill.skillName }}</option>
                    </select>
                    <span class="input-group-btn">
                        <a *ngIf="!dialerCampaign" (click)="loginPCSkill(selectedSkill)" class="btn btn-default"
                            >Login &nbsp;
                            <i class="fa fa-sign-in" aria-hidden="true"></i>
                        </a>
                        <a *ngIf="dialerCampaign" (click)="logoutPCSkill(selectedSkill)" class="btn btn-default"
                            >Logout &nbsp;
                            <i class="fa fa-sign-in" aria-hidden="true"></i>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
