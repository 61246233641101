<div class="page-content">
    <!-- Default panel contents -->
    <!-- <div class="panel-heading"><i class="fa fa-sort-amount-asc fa-lg" aria-hidden="true"></i>&nbsp; Queue</div>
 -->

    <table class="table table-hover">
        <thead>
            <tr>
                <td colspan="4" class="page-heading"><i class="fa fa-tasks fa-lg" aria-hidden="true"></i> Queue</td>
            </tr>
            <tr>
                <th>{{ 'Queue.Columns.Media' | translate }}</th>
                <th>{{ 'Queue.Columns.Skill' | translate }}</th>
                <th>{{ 'Queue.Columns.Queue' | translate }}</th>
                <th>Wait</th>
            </tr>
        </thead>
        <tbody *ngIf="showPersonalQueue">
            <tr>
                <td colspan="4">
                    Personal Queue
                </td>
            </tr>
            <tr *ngFor="let queue of personalQueue" class="row-queue">
                <td class="col-1">
                    <div *ngIf="queue.mediaType === '1'">
                        <i class="fa fa-envelope-o fa-lg" aria-hidden="true"> </i> E-Mail
                    </div>
                    <div *ngIf="queue.mediaType === '3'">
                        <i class="fa fa-comment-o fa-lg" aria-hidden="true"> </i> Chat
                    </div>
                    <div *ngIf="queue.mediaType === '4'">
                        <i class="fa fa-phone fa-lg" aria-hidden="true"> </i> Phone Call
                    </div>
                    <div *ngIf="queue.mediaType === '5'">
                        <i class="fa fa-file-audio-o fa-lg" aria-hidden="true"> </i> Voice Mail
                    </div>
                    <div *ngIf="queue.mediaType === '6'">
                        <i class="fa fa-building-o fa-lg" aria-hidden="true"> </i> Work Item
                    </div>
                    <!--{{queue.mediaType}}-->
                </td>
                <td class="col-2">{{ queue.skillName }}</td>
                <td class="col-3">
                    <span class="badge">{{ queue.queueCount }}</span>
                </td>
                <td class="col-4">
                    <span class="badge">{{ queue.longestQueueTimeInSeconds }}</span>
                </td>
            </tr>
        </tbody>

        <tbody *ngIf="showGeneralQueue">
            <tr>
                <td colspan="4">
                    General Queue
                </td>
            </tr>

            <tr *ngFor="let queue of generalQueue" class="row-queue">
                <td class="col-1">
                    <div *ngIf="queue.mediaType === '1'">
                        <i class="fa fa-envelope-o fa-lg" aria-hidden="true"> </i> E-Mail
                    </div>
                    <div *ngIf="queue.mediaType === '3'">
                        <i class="fa fa-comment-o fa-lg" aria-hidden="true"> </i> Chat
                    </div>
                    <div *ngIf="queue.mediaType === '4'">
                        <i class="fa fa-phone fa-lg" aria-hidden="true"> </i> Phone Call
                    </div>
                    <div *ngIf="queue.mediaType === '5'">
                        <i class="fa fa-file-audio-o fa-lg" aria-hidden="true"> </i> Voice Mail
                    </div>
                    <div *ngIf="queue.mediaType === '6'">
                        <i class="fa fa-building-o fa-lg" aria-hidden="true"> </i> Work Item
                    </div>
                </td>
                <td class="col-2">{{ queue.skillName }}</td>
                <td class="col-3">
                    <span class="badge">{{ queue.queueCount }}</span>
                </td>
                <td class="col-4">
                    <span class="badge">{{ queue.longestQueueTimeInSeconds }}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!--
        <div *ngIf="showPersonalQueue" class="panel panel-default">
        <div role="heading" aria-level="4" class="panel-heading">Personal Queue</div>
        <div class="panel-body panel-body-uac">
          
        </div>
      </div>
      <div *ngIf="showGeneralQueue" class="panel panel-default">
        <div role="heading" aria-level="4" class="panel-heading">General Queue</div>
        <div class="panel-body panel-body-uac">
          <tr *ngFor="let queue of generalQueue" class="row-queue">

            <td class="col-1">
              <div *ngIf="queue.mediaType ==='1'"><i class="fa fa-envelope-o fa-lg" aria-hidden="true"> </i> E-Mail
              </div>
              <div *ngIf="queue.mediaType ==='3'"><i class="fa fa-comment-o fa-lg" aria-hidden="true"> </i> Chat
              </div>
              <div *ngIf="queue.mediaType ==='4'"><i class="fa fa-phone fa-lg" aria-hidden="true"> </i> Phone Call
              </div>
              <div *ngIf="queue.mediaType ==='5'"><i class="fa fa-file-audio-o fa-lg" aria-hidden="true"> </i> Voice
                Mail</div>
              <div *ngIf="queue.mediaType ==='6'"><i class="fa fa-building-o fa-lg" aria-hidden="true"> </i> Work Item
              </div>
              
            </td>
            <td class="col-2">{{queue.skillName}}</td>
            <td class="col-3"><span class="badge">{{queue.queueCount}}</span></td>
            <td class="col-4"><span class="badge">{{queue.longestQueueTimeInSeconds}}</span></td>
          </tr>
        </div>
      </div>
-->
