import {AnyObject} from 'src/app/global';

export class LbNgxDropdownModel {
    text: string;
    id: string;
    icon?: string;
    routerLink?: string;
    priority?: any;
    items?: Array<LbNgxDropdownModel>;
    original: AnyObject;

    constructor(data?: Partial<LbNgxDropdownModel>) {
        Object.assign(this, data);
    }
}
