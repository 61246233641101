<div style="position: absolute; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
    <div
        style="
            min-width: 360px;
            padding: 15px;
            border: solid 1px #999999;
            box-sizing: border-box;
            box-shadow: 0px 8px 17px 1px #999;
        "
    >
        <div
            style="
                width: 200px;
                height: 50px;
                background: #fff url('/assets/images/NICE inContact2.png') no-repeat top left;
                background-position: center center;
                background-size: contain;
            "
            class="p-mx-auto p-my-5"
        ></div>
        <div>
            <router-outlet></router-outlet>
        </div>

        <div class="col-xs-12" style="margin-top: 10px">
            <div class="row" *ngIf="false">
                <div class="col-xs-12 text-center mt-3">
                    <img style="height: 48px" class="logo" src="/assets/images/fac_logo_trns.png" />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12" class="text-right">
                    <h6>
                        Version {{ version }} <small *ngIf="devMode">(build 202003)</small>
                        <br />
                        <a *ngIf="false" href="http://www.frontlineservicesinc.com">www.frontlineservicesinc.com</a>
                    </h6>
                </div>
            </div>
        </div>
    </div>
</div>
