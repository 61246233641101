import {ContactBase} from './contact-base';

export class WorkItemModel extends ContactBase {
    public type: string;
    public workItemId: number;
    public workItemPayload: string;
    public workItemType: string;

    constructor(data: any) {
        super(data);

        this.contactIcon = 'file-word';
        this.contactType = 'WorkItem';

        this.workItemId = data.WorkItemId;
        this.workItemPayload = data.WorkItemPayload;
        this.workItemType = data.WorkItemType;

        this.supportsEmailElevation = true;
        this.supportsPhoneElevation = true;
    }

    isRequiredToBeAcceptedWhenIncoming() {
        return true;
    }
}
