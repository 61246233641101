<form novalidate #agentForm="ngForm" (ngSubmit)="Next()">
    <fieldset>
        <div>
            <text-field
                class="mb-10"
                [(ngModel)]="icAgentSessionSrv.agent.userName"
                [fieldConfig]="{
                    type: 'text',
                    id: 'userName',
                    tabindex: 1,
                    iconClass: 'fa fa-user-shield'
                }"
                name="Username"
                [placeholder]="'Login.Labels.Username' | translate"
                [label]="'Login.Labels.Username' | translate"
                [required]="true"
            ></text-field>

            <div class="form-group text-right">
                <button [disabled]="agentForm.invalid" type="submit" class="btn btn-success p-px-5">
                    {{ 'Login.Labels.Next' | translate }}
                </button>
            </div>
        </div>
    </fieldset>
</form>
