<div class="page-heading">
    <ul class="nav nav-pills">
        <li>
            <h4>
                <i
                    class="fa fa-tty fa-lg"
                    aria-hidden="true"
                    title="{{ 'App_Component.Footer.CallHistory' | translate }}"
                ></i
                >&nbsp; {{ 'App_Component.Footer.CallHistory' | translate }}
            </h4>
        </li>
        <li>
            <div class="col-xs-6 col-md-6 nopadding">
                <div class="input-group">
                    <span class="input-group-addon">
                        <i class="fa fa-sort-numeric-up" aria-hidden="true"></i>
                    </span>
                    <select
                        [ngModel]="orderBy"
                        (ngModelChange)="onOrderByFilterChange($event)"
                        name="orderByFilter"
                        #orderByFilter="ngModel"
                        class="form-control"
                    >
                        <option [value]="0">{{ 'CallHistory.SearchFilter.Date' | translate }}</option>
                        <option [value]="1">{{ 'CallHistory.SearchFilter.PhoneNo' | translate }}</option>
                        <option [value]="2">{{ 'CallHistory.SearchFilter.Skill' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="col-xs-6 col-md-6 nopadding">
                <div class="input-group">
                    <span class="input-group-addon">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                    </span>
                    <select
                        [ngModel]="daycount"
                        (ngModelChange)="onDateFilterChange($event)"
                        name="dateFilter"
                        #dateFilter="ngModel"
                        class="form-control"
                    >
                        <option [value]="0">{{ 'CallHistory.DateFilter.Today' | translate }}</option>
                        <option [value]="7">{{ 'CallHistory.DateFilter.Last7Days' | translate }}</option>
                        <option [value]="30">{{ 'CallHistory.DateFilter.Last30Days' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="col-xs-12 col-md-12 nopadding">
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control"
                        accesskey="S"
                        [(ngModel)]="keyword"
                        name="inputKeyword"
                        #inputKeyword="ngModel"
                        placeholder="Search for (Phone, Email or Skill Name)"
                    />
                    <span class="input-group-btn">
                        <a class="btn btn-default">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                    </span>
                </div>
            </div>
        </li>
    </ul>
</div>

<div class="page-content" style="top: 160px;">
    <ul class="list-group">
        <li
            *ngFor="let contact of contacthistory | filter: 'call-history':'all':keyword; let i = index"
            class="list-group-item"
            style="padding: 5px;"
        >
            <a
                tabindex="i"
                #listItem
                name="listItem"
                role="link"
                href="javascript:void(0)"
                (keyup.enter)="dialPhone(contact.fromAddr, contact.skillId)"
            >
            </a>
            <div class="row item-row">
                <div class="col-xs-6">
                    <div class="col-xs-12 item-text">
                        <div *ngIf="contact.mediaTypeId === '1'">
                            <i class="fa fa-envelope-o fa-lg" aria-hidden="true"> </i> E-Mail
                        </div>
                        <div *ngIf="contact.mediaTypeId === '3'">
                            <i class="fa fa-comment-o fa-lg" aria-hidden="true"> </i> Chat
                        </div>
                        <div *ngIf="contact.mediaTypeId === '4'">
                            <i class="fa fa-phone fa-lg" aria-hidden="true"> </i> Phone Call
                        </div>
                        <div *ngIf="contact.mediaTypeId === '5'">
                            <i class="fa fa-file-audio-o fa-lg" aria-hidden="true"> </i> Voice Mail
                        </div>
                        <div *ngIf="contact.mediaTypeId === '6'">
                            <i class="fa fa-building-o fa-lg" aria-hidden="true"> </i> Work Item
                        </div>
                    </div>
                    <div *ngIf="contact.isOutbound == 'False'" class="col-xs-12 item-text">
                        {{ contact.fromAddr }}
                    </div>
                    <div *ngIf="contact.isOutbound == 'True'" class="col-xs-12 item-text">
                        {{ contact.toAddr }}
                    </div>
                    <div class="col-xs-12 item-skill">{{ contact.skillName }}</div>
                </div>
                <div class="col-xs-6">
                    <div class="item-text item-date">{{ contact.contactStartDate | date: 'short' }}</div>

                    <div *ngIf="contact.mediaTypeId === '4'" class="col-xs-12 pull-right timer-column">
                        <!-- <div *ngIf="contact.isOutbound == 'False'" role="button" dropdown class="dropup">
                <button class="btn btn-primary btn-sm" *ngIf="false" type="button">
                  {{ 'AddressBook.Agents.Call' | translate }}
                </button>
                <a id="single-button" title="dial number" dropdownToggle aria-haspopup="true" aria-expanded="false" aria-labelledby="skill">
                    <i class="fa fa-phone phone-key-align"></i>
                  </a>
                <ul aria-labelledby="skill" #outboundSkills id="outbound-list_{{contact.contactId}}" dropdownMenu keyboardNav="true" class="dropdown-menu scrollable-menu btn-block scrollable-menu-uac">
                  <li role="menuitem" *ngFor="let skill of agent.outboundSkills" style="color:#444;line-height:20px">
                    <a style="color:#444;line-height:20px" aria-labelledby="skill" role="link" href="javascript:void(0)" class="scrollable-menu-uac-items"
                      (click)="dialPhone(contact.fromAddr,skill)">{{skill.skillName}}</a>
                    <span id="skill" class="sr-only">{{skill.skillName}}</span>
                  </li>
                </ul>
              </div> -->
                        <div class="btn-group" *ngIf="contact.isOutbound == 'False'">
                            <button
                                class="btn btn-primary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton_{{ contact.contactId }}"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {{ 'AddressBook.Agents.Call' | translate }}...
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right">
                                <li class="dropdown-item" *ngFor="let skill of agent.outboundSkills">
                                    <a
                                        style="color: #444; line-height: 20px;"
                                        aria-labelledby="skill"
                                        role="link"
                                        href="javascript:void(0)"
                                        class="scrollable-menu-uac-items"
                                        (click)="dialPhone(contact.fromAddr, skill.skillId)"
                                        >{{ skill.skillName }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <button
                            class="btn btn-primary"
                            type="submit"
                            *ngIf="contact.isOutbound == 'True'"
                            (click)="dialPhone(contact.toAddr, contact.skillId)"
                        >
                            {{ 'AddressBook.Agents.Call' | translate }}...
                        </button>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
