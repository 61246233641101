import {Component, OnInit, Input, HostListener, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';

import {APISystemObjectsService} from '../../../core/_services/api-system-objects.service';
import {IcAgentSessionService} from '../../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {CallContactService} from '../../../core/_services/call-contact.service';
import {HotKeyPressSerivce} from '../../../core/_services/hot-key-press.service';
import {SkillsModel} from '../../../core/InContact/models/skills.model';
import {SessionInfo} from '../../../core/InContact/models/session-info.model';

import {CallContactModel} from 'src/app/core/_models/call-contact';

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.css'],
})
export class SkillsComponent implements OnInit {
    allskills: SkillsModel[];
    calls: CallContactModel[];
    @Input()
    keyword: string;

    constructor(
        private apiSysObj: APISystemObjectsService,
        private callContactService: CallContactService,
        private hotKeyPressSerivce: HotKeyPressSerivce,
        public router: Router,
    ) {}

    ngOnInit() {
        this.allskills = [];

        this.apiSysObj.getAllSkills().subscribe(res => {
            this.allskills = res.filter(e => e.MediaTypeId === '4' && e.IsOutbound === 'False' && e.IsActive === 'True');
        });

        this.callContactService.currentContacts.subscribe(res => {
            this.calls = res;
        });
    }

    dialSkill(skillName: string) {
        const call = this.calls[0];
        if (call && call.status !== 'Holding') {
            this.callContactService.holdContact(call).subscribe(res => {
                setTimeout(() => {
                    this.callContactService.dialSkill(skillName);
                }, 1000);
            });
        } else {
            this.callContactService.dialSkill(skillName);
        }
        this.router.navigate(['/active-contact']);
    }

    @ViewChild('listItem') listItem: ElementRef;

    @HostListener('window:keydown', ['$event'])
    hotkeys(event: KeyboardEvent) {
        const key = event.keyCode;
        if (this.hotKeyPressSerivce.altPress(event)) {
            switch (key) {
                case 76: //L
                    $(this.listItem.nativeElement).focus();
                    event.preventDefault();
                    break;
            }
        }
    }
}
