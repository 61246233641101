import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';

@Component({
    selector: 'app-sso-callback',
    templateUrl: './sso-callback.component.html',
    styleUrls: ['./sso-callback.component.scss'],
})
export class SsoCallbackComponent implements OnInit, AfterViewInit {
    code: string = null;
    constructor(private activatedRoute: ActivatedRoute, private localStorageService: LocalStorageService) {}

    ngOnInit() {
        // let icApiSettings = this.localStorageService.retrieve('IncontactApiSettings');
        // InContactApiSettings.useCompanySSOSettings = result.useCompanySSOSettings;
        // InContactApiSettings.setSettings = icApiSettings;

        this.code = this.activatedRoute.snapshot.queryParams.code;
        console.log('sso-callback params: ', this.activatedRoute.snapshot.queryParamMap);
    }

    ngAfterViewInit() {}
}
