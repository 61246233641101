import {Component, OnInit} from '@angular/core';

import {QueueService} from './queue.service';

import {ContactStates} from '../.././core/InContact/models/contact-states.model';
import {Agent} from '../.././core/_models/agent.model';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {MessageService} from '../.././core/_services/message.service';
import {FccCommonService} from '../.././core/_services/fcc-common.service';

@Component({
    selector: 'app-queue',
    templateUrl: './queue.component.html',
    styleUrls: ['./queue.component.css'],
})
export class QueueComponent implements OnInit {
    private queuelist: ContactStates[];
    private runQueueTimer: boolean;
    private agentId: number;
    private serverTimeOffset;
    private queueTimer: any;

    public personalQueue: ContactStates[];
    public generalQueue: ContactStates[];
    public queueUpdateCount = 0;
    public showPersonalQueue: boolean;
    public showGeneralQueue: boolean;

    constructor(
        private queueService: QueueService,
        private agentSessionService: IcAgentSessionService,
        private messageService: MessageService,
        private fccCommonService: FccCommonService,
    ) {
        this.agentSessionService.serverTimeOffset.subscribe(time => {
            this.serverTimeOffset = time;
        });
    }

    ngOnInit() {
        //this.getQueue();
        this.queuelist = [];
        this.startQueueTimer();
        this.messageService.setWindowTitle.next('Queue');
    }

    startQueueTimer() {
        this.getQueue();
        this.queueTimer = setInterval(() => {
            if (this.runQueueTimer === false) {
                this.stopQueueTimer();
                return;
            }

            if (this.queuelist.length > 0) {this.updateElapsedQueueTime();}

            if (this.queueUpdateCount > 5) {
                this.queueUpdateCount = 0;
                this.getQueue();
            }
            this.queueUpdateCount++;
        }, 1000);
    }

    stopQueueTimer() {
        this.runQueueTimer = false;
        this.queueUpdateCount = 0;
        clearInterval(this.queueTimer);
    }

    getQueue() {
        this.runQueueTimer = true;
        this.agentId = this.agentSessionService.agentId.getValue();

        if (this.agentId !== undefined && this.agentId !== 0) {
            this.queueService.getContactState(this.agentId).subscribe(
                list => {
                    this.queueUpdateCount = 0;

                    if (list.length > 0) {
                        this.queuelist = list;

                        this.queuelist.forEach(element => {
                            this.setInqueueTime(element);
                        });

                        this.personalQueue = this.queuelist.filter(
                            element => element.agentId === this.agentId.toString(),
                        );
                        this.generalQueue = this.queuelist.filter(
                            element => element.agentId === '0' || element.agentId !== this.agentId.toString(),
                        );

                        this.showPersonalQueue = this.personalQueue.length > 0;
                        this.showGeneralQueue = this.generalQueue.length > 0;

                        this.messageService.setCallMessage2.next(
                            'You are on Queue Screen. There are total ' + this.queuelist.length + ' calls in queue.',
                        );
                    }

                    //this.startQueueTimer();
                },
                err => {
                    if (err.status === 0) {
                        setTimeout(() => err, 5000);
                    } else if (err.status === 401) {
                        this.agentSessionService.reAuthenticateUser();
                    }
                },
            );
        }
    }

    updateElapsedQueueTime() {
        this.queuelist.forEach(element => {
            this.setInqueueTime(element);
        });
    }

    setInqueueTime(rec: ContactStates) {
        let currentDate;
        let dif;

        let serverTimeOffset;
        this.agentSessionService.serverTimeOffset.subscribe(serverTime => {
            serverTimeOffset = serverTime;

            currentDate = new Date().valueOf() + serverTimeOffset;
            dif = new Date(currentDate.valueOf() - new Date(rec.earliestQueueTimeInUTC).valueOf()).getTime();
            rec.longestQueueTimeInSeconds = this.fccCommonService.getFormattedTime(dif);
        });
    }

    public setClasses(mediaType: string): string {
        let className: string;

        switch (mediaType) {
            case '1':
                className = 'fa fa-phone fa-lg';
                break;
            case '2':
                className = 'fa fa-commenting-o fa-lg';
                break;
            case '3':
                className = 'fa fa-commenting-o fa-lg';
                break;
            case '4':
                className = 'fa fa-commenting-o fa-lg';
                break;
            case '5':
                className = 'fa fa-file-audio-o fa-lg';
                break;
        }

        return className;
    }

    ngOnDestroy() {
        this.stopQueueTimer();
    }
}
