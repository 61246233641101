import {Component, OnInit, ViewContainerRef, ViewEncapsulation, HostListener, ViewChild, ElementRef, Input} from '@angular/core';
import {ContactBase} from '../../core/_models/contact-base';
import {ContactServiceBase, contactServiceAction} from '../../core/_services/contact.service.base';
import {ContactViewBaseModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {DispositionModel} from 'src/app/core/_models/disposition.model';
import moment from 'moment';
import {InContactAPIService} from 'src/app/core/InContact/incontact-api.service';
import {DispositionService} from 'src/app/core/_services/disposition.service';
import {ApplicationContextService} from 'src/app/core/_services/application-context.service';

@Component({
    selector: 'active-contact-disposition',
    templateUrl: './disposition.component.html',
})
export class DispositionComponent implements OnInit {
    @Input()
    contact: ContactBase;
    @Input() mode = 'normal';
    @Input() viewModel: ContactViewBaseModel<ContactBase>;

    @Input()
    contactService: ContactServiceBase<ContactBase, ContactViewBaseModel<ContactBase>>;

    public dispositionList: Array<DispositionModel> = [];
    public preSelected: any = {};
    public selectedDisposition: DispositionModel | null;
    public dispositionId = '';

    public disposition: any = {};
    dispositionFocus: any = {};
    private tempDispo = null;

    public primaryCallbackNumber: string | null = null;

    public model: any = {displayTimezone: {}};

    public timezones: Array<any> = [];

    public constructor(
        private incontactApiService: InContactAPIService,
        public dispositionSrv: DispositionService,
        public appContext: ApplicationContextService,
    ) {}

    public ngOnInit(): void {
        const m = moment(new Date());
        const tempDt = m.add(1, 'hour');

        this.dispositionId = null;
        this.selectedDisposition = null;

        this.model = {
            minute: tempDt.format('mm'),
            hour: tempDt.format('hh'),
            ampm: tempDt.format('A'),
            scheduleType: 'Me',
            date: tempDt.format('YYYY-MM-DD'),
            timezone: m.format('Z'),
            displayTimezone: {},
        };

        this.model.timezone = this.model.timezone?.replace('+', '');

        this.primaryCallbackNumber = this.contact.ANI;

        this.dispositionSrv.dispositionList$.subscribe(res => {
            this.dispositionList = res;
        });

        if (this.contact?.skillInfo?.skillId) {
            this.dispositionSrv.getDispositionList(this.contact.skillInfo.skillId);
        }

        if (this.timezones.length === 0) {
            this.incontactApiService.getTimezones().subscribe((resp: any[]) => {
                this.timezones = resp;
                // this.timezones.push(...this.daylightTimezones);
                // var localTimezoneName = this.getTimezoneName();

                // //localTimezoneName = localTimezoneName.replace('Daylight', 'Standard').trim();
                // this.timezones
                //     .filter(tz => {
                //         return tz.offset == this.model.timezone.replace('+', '');
                //     })
                //     .filter(tz => `${tz.standardName}`.toLocaleLowerCase() == localTimezoneName.toLocaleLowerCase())
                //     //
                //     .map(tz => {
                //         //if (`${tz.standardName}`.toLocaleLowerCase() == localTimezoneName.toLocaleLowerCase()) {
                //         this.displayTimezone = tz.standardName;
                //         //}
                //     });
                // if (`standardName`.length == 0) {
                //     this.timezones
                //         .filter(tz => {
                //             return tz.offset == this.model.timezone;
                //         })
                //         .map(tz => (this.displayTimezone = tz));
                // }
            });
        }

        this.viewModel.isCrmProcessable;
    }

    public get disableTicketField() {
        return (this.contact.ticketId && this.appContext.selectedCrmApplication?.otherSettings?.lockTicketField) || false;
    }

    setHour(h: string) {
        this.model.hour = h;
    }

    setMinute(m: string) {
        this.model.minute = m;
    }
    setAMPM(m: string) {
        this.model.ampm = m;
    }

    getTimezoneName() {
        const today = new Date();
        const short = today.toLocaleDateString(undefined);
        const full = today.toLocaleDateString(undefined, {timeZoneName: 'long'});

        // Trying to remove date from the string in a locale-agnostic way
        const shortIndex = full.indexOf(short);
        if (shortIndex >= 0) {
            const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

            // by this time `trimmed` should be the timezone's name with some punctuation -
            // trim it from both sides
            return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
        } else {
            // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
            return full;
        }
    }

    public get minDate() {
        return moment(new Date()).format('YYYY-MM-DD');
    }

    setDisposition() {
        if (!this.contact.appliedDisposition) {return;}

        let hour = parseInt(this.model.hour);
        if (this.model.ampm == 'PM') {
            if (hour < 12) {
                hour = hour + 12;
            }
        } else if (this.model.ampm == 'AM') {
            if (hour == 12) {hour = 0;}
        }
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        const formattedMinute = `${this.model.minute}`.length == 1 ? `0${this.model.minute}` : `${this.model.minute}`;

        const formattedTimezone = (this.model.timezone + '').indexOf('-') == -1 ? `+${this.model.timezone}` : `${this.model.timezone}`;

        let time = null;

        if (this.selectedDisposition?.requireRescheduleDate === 'True') {
            time = `${this.model.date}T${formattedHour}:${formattedMinute}:00${formattedTimezone}`;
            // time = `${this.model.date}T${this.model.hour}:${this.model.minute}:00${
            //     this.model.timezone[0] === '-' ? this.model.timezone : `+${this.model.timezone}`
            // }`;
        } else {
            this.primaryCallbackNumber = null;
        }

        // console.log(this.contact.appliedDisposition.dispositionId, time, this.primaryCallbackNumber);
        // return;

        if (this.mode == 'normal') {
            this.contact.dispositionSubmitted = true;
            this.contactService.applyDisposition(this.contact, this.primaryCallbackNumber, time).subscribe(
                () => {
                    //this.contactService.log("Disposition applied!")
                    this.contact.dispositionSubmitted = true;
                },
                error => {
                    this.contact.dispositionSubmitted = false;
                },
            );
        } else if (this.mode == 'related') {this.viewModel.preDeispositionSet = true;}
    }

    selectDispo($event, dispositionId, dispoType) {
        this.selectedDisposition = this.dispositionList.find((v, i) => v.dispositionId == dispositionId);
        // console.log(dispositionId, this.selectedDisposition);

        if (!dispoType || dispoType == 'primary') {
            this.viewModel.contact.appliedDisposition = this.selectedDisposition;
        } else if (dispoType == 'secondary') {
            this.viewModel.contact.appliedSecondaryDisposition = this.selectedDisposition;
        }
        // selectedDisposition.map((v, i) => {
        //     if (!dispoType || dispoType == 'primary') {
        //         this.viewModel.contact.appliedDisposition = v;
        //     } else if (dispoType == 'secondary') {
        //         this.viewModel.contact.appliedSecondaryDisposition = v;
        //     }
        // });
    }
}
