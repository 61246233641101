import {Injectable, ViewContainerRef} from '@angular/core';
// import { Http, Headers, Response } from '@angular/http';
import {Observable, BehaviorSubject, Subject} from 'rxjs';

// import {Overlay} from 'angular2-modal';
// import {Modal} from 'angular2-modal/plugins/bootstrap';

// import {TranslateService} from 'ng2-translate';
// import {ModalComponent} from 'ng2-bs3-modal/ng2-bs3-modal';
// import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {DialogComponent} from './dialog.component';
import {ContainerContent, OverlayConfig, DialogRef, Overlay} from 'ngx-modialog-7';
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';

@Injectable()
export class DialogService {
    title: string;
    message: string;

    onClose: Subject<boolean>;

    public incomingRequest = new BehaviorSubject<boolean>(false);
    public incomingRequestEvent = new BehaviorSubject<any>(null);
    public closeDialog = new BehaviorSubject<boolean>(false);

    constructor(
        // private translate: TranslateService
        private modalSrv: Modal, // public modal: Modal,
    ) {
        this.onClose = new Subject<boolean>();
        //overlay.defaultViewContainer = vcRef;
    }

    openModal(content: ContainerContent, config?: OverlayConfig) {
        return this.modalSrv.open(content, config);
    }

    close(modal: DialogRef<any>) {
        if (modal) {
            modal.close();
        }
    }

    confirmDialog(title: string, message: string, okText: string, cancelText: string, tagClass?: string) {
        // return this.modalSrv.show(DialogComponent, {
        //     initialState: {
        //         title,
        //         message,
        //         okButtonText: okText,
        //         cancelButtonText: cancelText,
        //     },
        // });
        return (
            this.modalSrv
                .confirm()
                .bodyClass('modal-body ' + (tagClass ? tagClass : ''))
                //.dialogClass("testing134")
                .size('sm')
                .isBlocking(true)
                .showClose(false)
                .keyboard(27)
                .okBtn(okText)
                .cancelBtn(cancelText)
                .title(title)

                .body(message)
                //       .body(` <div role="dialog" aria-labelledby="dialog1Title" aria-describedby="dialog1Desc">
                //  <h2 id="dialog1Title">Subscribtion Form</h2>
                //  <p id="dialog1Desc">We will not share this information with third parties.</p>`+message +`</div >`)
                .open()
        );

        //  res.then((resultPromise) => {
        //     return resultPromise.result.then((result) =>
        //     {
        //         this.result = result;
        //         OK(this.result);
        //     },
        //     () => this.result = 'Rejected!');
        // });
    }

    // promptDialog(title: string, message: string, okText: string, cancelText: string) {
    //     return this.modal
    //         .prompt()
    //         .size('lg')
    //         .isBlocking(true)
    //         .showClose(true)
    //         .keyboard(27)
    //         .title(title)
    //         .body(message)
    //         .okBtn(okText)
    //         .cancelBtn(cancelText)
    //         .open();
    // }
}
