export class LbNgxDropdownConfigModel {
    idKey: string;
    textKey: string;
    iconKey?: string;
    childKey?: string;
    orderBy?: string;

    constructor(data?: Partial<LbNgxDropdownConfigModel>) {
        Object.assign(this, data);
    }
}
