import {AnyObject} from 'src/app/global';
import {CompanyModel} from './company.model';
import {CrmApplicationModel} from './crm-application.model';

export class AgentPublicProfileViewModel {
    active: boolean;
    applicationType: string;
    company: CompanyModel;
    companyId: number;
    crmApplications: Array<CrmApplicationModel>;
    dateCreated: Date;
    dateModified: Date;
    email: string;
    firstName: string;
    id: number;
    isAdmin: boolean;
    lastName: string;
    settings: AnyObject;
    useCompanySSOSettings: boolean;

    constructor(data?: Partial<AgentPublicProfileViewModel>) {
        if (data?.company) {
            data.company = new CompanyModel(data.company);
        }
        Object.assign(this, data);
    }
}
