import {Component, OnInit, Input, AfterViewInit} from '@angular/core';

import {IcAgentSessionService} from 'src/app/sdk/incontact/services/agent-sessions/agent-session-events.service';
import {AgentSkillAssignmentsModel} from 'src/app/core/InContact/models/agent-skill-assignments.model';
import {ContactServiceBase} from 'src/app/core/_services/contact.service.base';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {ContactViewBaseModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ChatContactService} from 'src/app/core/_services/chat-contact.service';
import {EmailContactService} from 'src/app/core/_services/email-contact.service';
import {CallContactService} from 'src/app/core/_services/call-contact.service';
import {VoiceMailContactService} from 'src/app/core/_services/voice-mail-contact.service';
import {WorkItemContactService} from 'src/app/core/_services/work-item-contact.service';

@Component({
    selector: 'app-contact-elevation',
    template: `<div style="padding:10px; min-width: 300px;">
        <ul *ngIf="elevationAction == 'elevate_to_phone'" class="nav">
            <li><input type="tel" class="form-control" [(ngModel)]="phone" placeholder="phone" /></li>
            <li class="dropdown-submenu">
                <a class="test" tabindex="-1" href="javascript:;" (click)="showHidePhone($event)">Skill <span class="caret"></span></a>
                <ul class="dropdown-menu" [style.display]="showPhoneDropDown ? 'block' : 'none'">
                    <li *ngFor="let skill of outboundCallSkills">
                        <a tabindex="-1" href="javascript:;" (click)="elevateToPhone(skill.skillName, skill.skillId)">{{ skill.skillName }}</a>
                    </li>
                </ul>
            </li>
        </ul>

        <ul *ngIf="elevationAction == 'elevate_to_email'" class="nav">
            <li><input type="tel" class="form-control" [(ngModel)]="email" placeholder="Email" /></li>

            <li class="dropdown-submenu">
                <a class="test" tabindex="-1" (click)="showHideEmail($event)">Skill <span class="caret"></span></a>
                <ul class="dropdown-menu" [style.display]="showEmailDropdown ? 'block' : 'none'">
                    <li *ngFor="let skill of outboundEmailSkills">
                        <a tabindex="-1" href="javascript:;" (click)="elevateToEmail(skill.skillName, skill.skillId)">{{ skill.skillName }}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>`,
    //styleUrls: ['./contact-elevation.scss'],
})
export class ContactElevationComponent implements OnInit, AfterViewInit {
    @Input()
    masterContactId: string;
    @Input()
    elevationAction: string;
    @Input()
    contactService: ContactServiceBase<ContactBase, ContactViewBaseModel<ContactBase>>;

    phone: string;
    email: string;

    outboundCallSkills: any[] = [];
    outboundEmailSkills: any[] = [];

    showPhoneDropDown = false;
    showEmailDropdown = false;

    constructor(
        private chatContactService: ChatContactService,
        private emailcontactService: EmailContactService,
        private callContactService: CallContactService,
        private voicemailContactService: VoiceMailContactService,
        private workitemContactService: WorkItemContactService,
        private agentSessionEventsService: IcAgentSessionService,
    ) {
        //chatContactService.currentContacts.subscribe(chats => { this.chatContacts = chats });
        //emailcontactService.currentContacts.subscribe(emails => { this.emailContacts = emails });
        this.agentSessionEventsService.agentOutboundSkills.subscribe((skills: AgentSkillAssignmentsModel[]) => {
            this.outboundCallSkills = skills.filter(s => s.isOutboundCallSkill());
            this.outboundEmailSkills = skills.filter(s => s.isOutboundEmaillSkill());
        });
    }

    showHidePhone(event) {
        event.stopPropagation();
        event.preventDefault();
        this.showPhoneDropDown = !this.showPhoneDropDown;
    }

    showHideEmail(event) {
        event.stopPropagation();
        event.preventDefault();
        this.showEmailDropdown = !this.showEmailDropdown;
    }

    ngOnInit() {
        // $('.dropdown-submenu a.test').off("click", "**");
    }

    ngAfterViewInit() {
        //document.querySelector('.dropdown-submenu a.test').removeEventListener('click',);
        /*  document.querySelector('.dropdown-submenu a.test').addEventListener('click', (e) => {
             console.log($('.dropdown-submenu a.test').next('ul').is(':visible'));
             if (!$('.dropdown-submenu a.test').next('ul').is(':visible')) {
                 $('.dropdown-submenu a.test').next('ul').show();
             } else {
                 $('.dropdown-submenu a.test').next('ul').hide();
             }

             //$(this).next('ul').toggle();
             e.stopPropagation();
             e.preventDefault();
         }); */
        /*  $('.dropdown-submenu a.test').off("click", "**").on("click", function (e) {
             console.log($(this).next('ul').is(':visible'));
             $(this).next('ul').toggle();
             e.stopPropagation();
             e.preventDefault();
         }); */
    }

    elevateToPhone(skillName: string, skillId: string) {
        this.contactService.elevateContact({
            elevateTo: 'phone',
            masterContactId: this.masterContactId,
            poc: this.phone,
            skillName,
            skillId,
        });
    }

    elevateToEmail(skillName: string, skillId: string) {
        this.contactService.elevateContact({
            elevateTo: 'email',
            masterContactId: this.masterContactId,
            poc: this.email,
            skillName,
            skillId,
        });
    }
}
