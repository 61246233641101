import {
    Component,
    OnInit,
    OnDestroy,
    ViewContainerRef,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
    TemplateRef,
    Inject,
    AfterViewChecked,
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {AgentService} from './core/_services/agent.service';
// import {TranslateService} from 'ng2-translate';
import {NextEventService} from './core/_services/next-events.service';
import {ChatContactService} from './core/_services/chat-contact.service';
import {WorkItemContactService} from './core/_services/work-item-contact.service';
import {IcAgentSessionService} from './sdk/incontact/services/agent-sessions/agent-session-events.service';
import {Events} from './core/InContact/models/events.model';
import {AgentStateModel} from './core/_models/agent-state.model';
import {Agent} from './core/_models/agent.model';
import {InContactApiSettings} from './core/InContact/incontact-api-settings';
import {ApplicationContextService} from './core/_services/application-context.service';

// import {Overlay} from 'angular2-modal';
// import {Modal} from 'angular2-modal/plugins/bootstrap';

import {SpinnerService} from './core/_components/spinner/spinner.service';

import {EventLogService} from './core/_services/event-log.service';
import {ErrorService} from './core/_services/error.service';
import {DialogService} from './shared/modals/dialog/dialog.service';
import {ConfigService} from './core/_services/config.service';

// import { AlertModule } from 'ng2-bootstrap/ng2-bootstrap';
// import {AlertModule} from 'ng2-bootstrap';

import {HostListener} from '@angular/core';

declare let $: JQueryStatic;
// declare var $:any;

// import {ModalComponent} from 'ng2-bs3-modal/ng2-bs3-modal';
import {MySubscriptionHandler} from './shared/classes';
import {DialogRef, ModalOverlay, Overlay, overlayConfigFactory} from 'ngx-modialog-7';
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {MenuItem} from 'primeng/api';
import {SoftPhoneService} from './sdk/counter-path/services/softphone/softphone.service';
import {LocalStorageService} from 'ngx-webstorage';
import {PreviewRescheduleComponent} from './screens/scheduled-callbacks/preview-reschedule/preview-reschedule.component';
import {fromEvent} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {FacLocalStorageService} from './sdk/storage';
// import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-root',
    // host: {'(window:keydown)': 'hotkeys($event)'},
    //templateUrl: './app.component.html',
    //styleUrls: ['./app.component.css'],
    templateUrl: './app.component.1.html',
    styleUrls: ['./app.component.1.scss'],
})
export class AppComponent extends MySubscriptionHandler implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    showStatus = true;
    // public isAuthenticated: boolean;
    public isSessionStarted: boolean;
    public ErrorMsg: string;
    public agentService: AgentService;
    public event: Events;
    public selectedAgentState: string;
    visiableAgentStates: AgentStateModel[];
    // public agent: Agent;
    public isRequesting: boolean;
    public startTimeElapsed: string;

    public dialogTitle: string;
    public dialogMessage: string;
    legStatus = '';

    public statusDropdownFocus = new EventEmitter<boolean>();

    private chatSwitchDelay = 5000;
    private workitemSwitchDelay = 5000;
    private goToChatAfterAccepted = true;
    public incomingContacts: any[] = [];
    public agentIndicators: Array<any> = [];

    public totalActiveContacts: number;

    @ViewChild('modal') modal: TemplateRef<any>;
    modalRef: DialogRef<any>;

    @ViewChild('dragXAxis') dragXAxis: ElementRef<any>;
    @ViewChild('dragYAxis') dragYAxis: ElementRef<any>;
    @ViewChild('resetSize') resetSize: ElementRef<any>;

    // @ViewChild('statusDropdown') el: ElementRef;
    // @ViewChild('modal') modal: TemplateRef<any>;
    // @ViewChild('logoutModal') logoutModal: TemplateRef<any>;
    // logoutModalRef: DialogRef<any>;
    // modalRef: DialogRef<any>;

    constructor(
        // private translate: TranslateService,
        public viewContainerRef: ViewContainerRef,
        private router: Router,
        private userService: AgentService,
        private nextEventService: NextEventService,
        private eventLogService: EventLogService,
        public agentEventsService: IcAgentSessionService,
        //public modalDialog: ModalComponent,
        private spinnerService: SpinnerService,
        private errorService: ErrorService,
        private dialogService: DialogService,
        private chatContactService: ChatContactService,
        private workItemContactService: WorkItemContactService,
        private activatedRoute: ActivatedRoute,
        private configService: ConfigService,
        public appContext: ApplicationContextService,
        private modalSrv: Modal,
        private softPhoneSrv: SoftPhoneService,
        private localStorageSettingSrv: FacLocalStorageService,
        @Inject(DOCUMENT) private document: HTMLDocument,
    ) {
        super();

        // this.modalSrv.overlay

        this.agentService = userService;

        this.router.navigate(['active-contact']);

        //this.isLoggedIn = this.agentService.userLoggedIn();

        // translate.addLangs(['en', 'fr']);
        // translate.setDefaultLang('en');

        // let browserLang = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

        // this.isAuthenticated = false;
        this.isSessionStarted = false;
        // this.agentEventsService.updateisAuthenticated(false);
        // this.agentEventsService.updateIsSessionStarted(false);

        if (this.agentEventsService.reload() === false) {
            console.log('redirecting to login');
            // this.router.navigate(['/authentication/login-step-one']);
        } else {
            // if (this.isAuthenticated && this.isSessionStarted) {
            //   this.nextEventService.GetNextEvent();
            // }
        }

        this.addSubscription$ = this.configService.configuations.subscribe(config => {
            this.chatSwitchDelay = config.chatScreenSwitchDelay;
            this.workitemSwitchDelay = config.workitemSwitchDisplay;
            this.goToChatAfterAccepted = config.goToChatAfterChatIsAccepted;
        });

        this.addSubscription$ = this.appContext.allContactsCollection.subscribe(contacts => {
            this.incomingContacts = contacts.filter(x => x.contact.isIncoming() && x.settings?.ringtoneUrl && x.settings?.ringtoneUrl?.length > 0);
            // console.log('incomingContacts', this.incomingContacts);
        });

        this.addSubscription$ = this.agentEventsService.agentIndicators.subscribe(indicators => {
            this.agentIndicators = indicators;
            // console.log('indicators', indicators);
        });

        this.addSubscription$ = this.appContext.agentSessionService.legStatusLabel$.subscribe(r => {
            this.legStatus = r;
        });

        /* this.incomingContactService.incomingContacts.subscribe(contacts => {
      this.incomingContacts = contacts;
      console.log(this.incomingContacts);
    }); */
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.initDrag();
        }, 1000);
    }

    private xAxisDragingStart = false;
    private yAxisDragingStart = false;

    private initialWidth = 360;
    private initialHeight = 540;

    resetWindowSize() {
        if (window.zClient) {
            this.initialWidth = 360;
            this.initialHeight = 540;
            // window.zClient.invoke('resize', {width: `${this.initialWidth}px`, height: `${this.initialHeight}px`});
        }
    }

    initDrag() {
        const oldSizeStr = this.localStorageSettingSrv.getItem('WindowSize');
        const oldSize = JSON.parse(oldSizeStr);
        if (window.zClient && oldSize?.width && oldSize.height) {
            window.zClient.invoke('resize', oldSize);
        }

        this.dragXAxis.nativeElement.addEventListener('mousedown', event => {
            this.xAxisDragingStart = true;
        });

        this.dragYAxis.nativeElement.addEventListener('mousedown', event => {
            // console.log('mouse down y', event);
            this.yAxisDragingStart = true;
        });

        window.document.onmousemove = event => {
            if (this.xAxisDragingStart) {
                // console.log('x drag', event.clientX, window.screen.width - event.screenX);
                if (window.screen.width - event.screenX >= 360) {
                    if (window.zClient) {
                        this.initialWidth = window.screen.width - event.screenX;
                        const size = {width: `${window.screen.width - event.screenX}px`, height: `${this.initialHeight}px`};
                        window.zClient.invoke('resize', size);
                        this.localStorageSettingSrv.setItem('WindowSize', JSON.stringify(size));
                    }
                }
            }

            if (this.yAxisDragingStart) {
                // console.log('y drag ', window.screen.height, event.screenY);
                if (event.screenY >= 770) {
                    if (window.zClient) {
                        // this.initialHeight = window.screen.height - event.screenY;
                        this.initialHeight = event.screenY - 230;
                        const size = {width: `${this.initialWidth}px`, height: `${event.screenY - 230}px`};
                        window.zClient.invoke('resize', size);
                        this.localStorageSettingSrv.setItem('WindowSize', JSON.stringify(size));
                    }
                }
            }
        };

        window.document.onmouseup = event => {
            if (this.xAxisDragingStart) {
                // if (this.initialWidth - event.clientX >= 360) {
                //         if (window.zClient) {
                //             this.initialWidth = this.initialWidth - event.clientX;
                //             window.zClient.invoke('resize', {width: `${this.initialWidth}px`, height: `${this.initialHeight}px`});
                //         }
                // }
            }

            // if (this.yAxisDragingStart) {
            //     if (this.initialHeight + event.clientY >= 540) {
            //         if (window.zClient) {
            //             this.initialHeight = this.initialHeight + event.clientY - 540;
            //             window.zClient.invoke('resize', {width: `${this.initialWidth}px`, height: `${this.initialHeight}px`});
            //         }
            //     }
            // }

            this.xAxisDragingStart = false;
            this.yAxisDragingStart = false;
        };
    }

    addToast() {
        // // Just add default Toast with title only
        // this.toastyService.default('Hi there');
        // // Or create the instance of ToastOptions
        // var toastOptions: ToastOptions = {
        //     title: 'My title',
        //     msg: 'The message',
        //     showClose: true,
        //     timeout: 5000,
        //     theme: 'default',
        //     onAdd: (toast: ToastData) => {
        //         console.log('Toast ' + toast.id + ' has been added!');
        //     },
        //     onRemove: function (toast: ToastData) {
        //         console.log('Toast ' + toast.id + ' has been removed!');
        //     },
        // };
        // // Add see all possible types in one shot
        // this.toastyService.info(toastOptions);
        // this.toastyService.success(toastOptions);
        // this.toastyService.wait(toastOptions);
        // this.toastyService.error(toastOptions);
        // this.toastyService.warning(toastOptions);
    }
    ngAfterViewChecked() {
        let index;

        // if (this.el !== undefined) {
        //     $(this.el.nativeElement).on('keypress', function () {
        //         let $current = $(this);
        //         // attach key listener when dropdown is shown
        //         $(document).keypress(function (e) {
        //             // get the key that was pressed
        //             let key = String.fromCharCode(e.which);
        //             // look at all of the items to find a first char match
        //             $current.find('li').each(function (idx, item) {
        //                 $(item).removeClass('active'); // clear previous active item

        //                 //let ch = $(item).text().trim().charAt(0).toLowerCase();

        //                 if ($(item).text().trim().charAt(0).toLowerCase() === key) {
        //                     // set the item to selected (active)
        //                     $(item).addClass('active');
        //                     $(item).find('a').focus();
        //                 }
        //             });

        //             e.stopImmediatePropagation();
        //         });
        //     });

        //     // unbind key event when dropdown is hidden
        //     $(this.el.nativeElement).on('hide.bs.dropdown', function () {
        //         $(document).unbind('keypress');
        //     });
        // }
    }

    ngOnDestroy() {
        this.agentEventsService.clear();
        this.unsubscribeAllSubscriptions();
    }

    connectLeg() {
        this.appContext.inContactAPIService.dialLeg().subscribe();
    }

    ngOnInit() {
        this.agentEventsService.applicationType = this.getParameterByName('applicationType', window.location.href);
        // this.addSubscription$ = this.agentEventsService.isAuthenticated.subscribe(
        //     auth => (this.isAuthenticated = auth),
        // );

        this.addSubscription$ = this.agentEventsService.isSessionStarted.subscribe(started => (this.isSessionStarted = started));

        // this.addSubscription$ = this.agentEventsService.currentAgentState.subscribe(state => {
        //     this.agent.currentAgentState = state;
        // });

        // this.addSubscription$ = this.agentEventsService.agentStates.subscribe(event => {
        //     this.visiableAgentStates = this.agentEventsService.getVisibleAgentStates(event);

        //     this.addSubscription$ = this.agentEventsService.selectedAgentState.subscribe(state => {
        //         this.agent.selectedAgentState = this.visiableAgentStates[1];
        //     });
        // });

        this.addSubscription$ = this.dialogService.incomingRequest.subscribe(request => {
            if (request) {
                this.dialogTitle = this.dialogService.title;
                this.dialogMessage = this.dialogService.message;
                this.open();
            }
        });

        this.addSubscription$ = this.dialogService.closeDialog.subscribe(result => {
            if (result) {
                this.close();
            }
        });
    }

    open() {
        this.modalRef = this.dialogService.openModal(
            this.modal,
            overlayConfigFactory({
                dialogClass: 'modal-dialog',
            }),
        );
    }

    close() {
        this.dialogService.close(this.modalRef);
    }

    title = 'Welcom to my site!';

    public isGreen = false;
    public isBlue = true;

    getParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    setClasses() {
        return this.agentService.setClasses();
    }

    // agentStateChanged(state: AgentStateModel) {
    //     let result;

    //     if (state !== undefined) this.agentEventsService.agentStateChanged(state);
    // }

    // confirmEndSession() {
    //     // this.logoutModalRef = this.modalSrv.open(
    //     //     this.logoutModal,

    //     // );
    //     this.logoutModalRef = this.dialogService.openModal(
    //         this.logoutModal,
    //         overlayConfigFactory({
    //             isBlocking: true,
    //             dialogClass: 'modal-dialog',
    //         }),
    //     );
    //     // if (this.appContext.allContactsCollection.value.length > 0) {
    //     //     this.logoutModalRef = this.dialogService.openModal(
    //     //         this.logoutModal,
    //     //         overlayConfigFactory({
    //     //             isBlocking: true,
    //     //             dialogClass: 'modal-dialog',
    //     //         }),
    //     //     );
    //     // } else this.endSession();
    // }

    isRunning() {
        return this.spinnerService.isRunning;
    }

    getEventLog() {
        const events = this.eventLogService.getEventData();

        events.forEach(element => {});
    }

    // showStatusBar(): boolean {
    //     return this.isAuthenticated && this.showStatus;
    // }

    accept() {}

    reject() {}

    /* accept() {
    let event: Events = this.dialogService.incomingRequestEvent.getValue();
    if (event.Type === "ChatContactEvent") {
      this.chatContactService.acceptContact(event);
      this.modal.close();
    }
    else if (event.Type === "WorkItemContactEvent") {
      this.workItemContactService.acceptWorkItem(event);
      this.modal.close();
      setTimeout(() => {
        this.router.navigate(['/active-contact']);
      }, this.workitemSwitchDelay);
    }
  }

  reject() {
    let event: Events = this.dialogService.incomingRequestEvent.getValue();
    if (event.Type === "ChatContactEvent")
      this.chatContactService.rejectChat(event);
    else if (event.Type === "WorkItemContactEvent")
      this.workItemContactService.rejectWorkItem(event);

    this.modal.dismiss();
  } */

    @HostListener('window:keydown', ['$event'])
    hotkeys(event: KeyboardEvent) {
        let key;
        let isShift;
        let isAlt;

        if (window.event) {
            key = event.keyCode;
            isShift = !!event.shiftKey; // typecast to boolean
            isAlt = !!event.altKey;
        } else {
            key = event.which;
            isShift = !!event.shiftKey;
            isAlt = !!event.altKey;
        }
        if (isShift && event.ctrlKey) {
            switch (key) {
                case 16: // ignore shift key
                    break;
                case 49: //1
                    this.router.navigate(['/queue']);
                    event.preventDefault();
                    break;
                case 50: //2
                    this.router.navigate(['/dialpad']);
                    event.preventDefault();
                    break;
                case 51: //3
                    this.router.navigate(['/address-book', {selectedOption: 'Agents'}]);
                    event.preventDefault();
                    break;
                case 52: //4
                    this.router.navigate(['/address-book', {selectedOption: 'Skills'}]);
                    event.preventDefault();
                    break;
                case 53: //5
                    this.router.navigate(['/call-history']);
                    event.preventDefault();
                    break;
                case 54: //6
                    this.router.navigate(['/address-book', {selectedOption: 'Address-Book'}]);
                    event.preventDefault();
                    break;
                case 65: //A
                    let state = new AgentStateModel('Available', '', 'Available', false);
                    this.agentEventsService.agentStateChanged(state);
                    event.preventDefault();
                    break;
                case 67: //C
                    this.router.navigate(['/active-contact']);
                    event.preventDefault();
                    break;
                case 69: //E
                    // this.endSession();
                    event.preventDefault();
                    break;
                case 85: //U
                    state = new AgentStateModel('Unavailable', '', 'Unavailable', false);
                    this.agentEventsService.agentStateChanged(state);
                    event.preventDefault();
                    break;
                default:
                    //alert(key);
                    // do stuff here?
                    break;
            }
        } else if (isAlt) {
            switch (key) {
                case 18:
                    break;
                case 48:
                    this.statusDropdownFocus.emit(true);
                    event.preventDefault();
                    break;
            }
        }
    }
}
