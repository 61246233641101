import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ContactServiceBase, contactServiceAction} from './contact.service.base';
import {CallContactModel} from '../_models/call-contact';
import {ApplicationContextService} from './application-context.service';
import {Observable, Subscription} from 'rxjs';
import {CallContactViewmodel} from '../_models/_viewmodels/call-contact-viewmodel';
import {Router} from '@angular/router';
import {AgentStateModel} from '../_models/agent-state.model';
import {DialogRef, overlayConfigFactory} from 'ngx-modialog-7';
import {PreviewRescheduleComponent} from 'src/app/screens/scheduled-callbacks/preview-reschedule/preview-reschedule.component';
import {WebRTCService} from '../InContact/web-rtc/web-rtc.service';
import {CRM_APPLICATION_TYPES} from 'src/app/global';

@Injectable()
export class CallContactService extends ContactServiceBase<CallContactModel, CallContactViewmodel> {
    public isMuteAvailable = () => !this.agentMuted && this.notDisconnectedCount > 0;
    public isUnMuteAvailable = () => this.agentMuted;

    public previewRescheduleRef: DialogRef<any>;

    constructor(private router: Router, appContext: ApplicationContextService, public webRtcSrv: WebRTCService) {
        super(CallContactModel, CallContactViewmodel as any, appContext);
        appContext.callContactService = this;

        this.title = 'Call Contacts';

        const me = this;
        this.currentContacts.subscribe(contacts => {
            this.activeCount = contacts.filter(x => x.isActive()).length;
            // console.log('active contact', this.activeCount, contacts);
            // setTimeout(() => {
            //     if (contacts.length > 0) {
            //         this.webRtcSrv.answerCall('+1700499991007');
            //     }
            // }, 3000);

            if (
                contacts.length > 0 &&
                contacts[0].isIncoming()
                // || contacts[0].isDialing()
            ) {
                this.webRtcSrv.playIncomingCallAudio();
            }
            if (contacts.length > 0 && (contacts[0].isActive() || contacts[0].isDisconnected())) {
                this.webRtcSrv.stopAudioPlay();
            }
        });

        this.currentContacts.subscribe(contacts => {
            this.notDisconnectedCount = contacts.filter(x => !x.isDisconnected()).length;
        });

        this.actions.push(
            new contactServiceAction({
                icon: 'users',
                text: 'Conference',
                action: 'conference',
                isVisible: () => me.count > 1,
            }),
            new contactServiceAction({
                icon: 'exchange-alt',
                text: 'Transfer',
                action: 'transfer',
                isVisible: () => me.count > 1,
            }),
            new contactServiceAction({
                icon: 'microphone-alt-slash',
                text: 'Mute',
                action: 'mute',
                isAvailable: me.isMuteAvailable,
                isVisible: me.isMuteAvailable,
            }),
            new contactServiceAction({
                icon: 'microphone-alt',
                text: 'Un-Mute',
                action: 'unmute',
                isAvailable: () => me.agentMuted,
                isVisible: () => me.agentMuted,
            }),
        );
    }
    agentMuted = false;
    private activeCount = 0;
    private notDisconnectedCount = 0;

    processMute(event: any) {
        this.agentMuted = event.AgentMuted === 'True';
    }

    muteAgent(): Observable<any> {
        return this.appContext.inContactAPIService.muteAgent();
    }

    unMuteAgent(): Observable<any> {
        return this.appContext.inContactAPIService.unMuteAgent();
    }

    executeServiceAction(action: string) {
        switch (action) {
            case 'mute':
                this.muteAgent().subscribe();
                break;
            case 'unmute':
                this.unMuteAgent().subscribe();
                break;

            case 'conference':
                this.conferenceCalls().subscribe();
                break;
            case 'transfer':
                this.transferCalls().subscribe();
                break;

            default:
                super.executeServiceAction(action);
                break;
        }
    }

    //#region contact processing
    maskRecording(contact: CallContactModel): Observable<CallContactModel> {
        return new Observable(r => {
            this.appContext.inContactAPIService
                .maskRecording(contact.contactId)
                .subscribe(result => {
                    r.next(contact);
                })
                .add(() => {
                    r.complete();
                });
        });
    }

    unmaskRecording(contact: CallContactModel): Observable<CallContactModel> {
        return new Observable(r => {
            this.appContext.inContactAPIService
                .unmaskRecording(contact.contactId)
                .subscribe(result => {
                    r.next(contact);
                })
                .add(() => {
                    r.complete();
                });
        });
    }

    startRecording(contact: CallContactModel): Observable<CallContactModel> {
        return new Observable(r => {
            this.appContext.inContactAPIService
                .recordCall(contact.contactId)
                .subscribe(result => {
                    r.next(contact);
                })
                .add(() => {
                    r.complete();
                });
        });
    }

    openPreviewScheduler(contactId: string, contactNumber: string) {
        // this.previewRescheduleRef = this.dialogService.openModal(
        //     this.previewReschedule,
        //     overlayConfigFactory({
        //         dialogClass: 'modal-dialog',
        //     }),
        // );

        this.previewRescheduleRef = this.appContext.dialogService.openModal(
            PreviewRescheduleComponent,
            overlayConfigFactory({
                contactId,
                contactNumber,
                dialogClass: 'modal-dialog',
            }),
        );
    }

    //#endregion

    //#region Outbound

    consultAgent(agentUsername: string) {
        this.holdExistingContact().subscribe(holdResult => {
            this.appContext.inContactAPIService.consultAgent(agentUsername).subscribe(
                result => {
                    this.log(result);
                },
                error => {
                    this.handleContactError(error);
                },
            );
        });

        this.router.navigate(['/active-contact']);
    }

    dialAgent(agentUsername: string) {
        this.holdExistingContact().subscribe(
            holdResult => {
                this.appContext.inContactAPIService.dialAgent(agentUsername).subscribe(result => {
                    this.log(result);
                });
            },
            error => {
                this.handleContactError(error);
            },
        );

        this.router.navigate(['/active-contact']);
    }

    dialPhone(n: string, skill: string) {
        this.holdExistingContact().subscribe(holdResult => {
            this.appContext.inContactAPIService.dialPhone(n, skill).subscribe(
                result => {
                    this.webRtcSrv.setOutgoingCall();
                    this.log(result);
                },
                error => {
                    this.handleContactError(error);
                },
            );
        });
    }

    dialSkill(skill: string) {
        this.holdExistingContact().subscribe(holdResult => {
            this.appContext.inContactAPIService.dialSkill(skill).subscribe(result => {
                this.webRtcSrv.setOutgoingCall();
                this.log(result);
            });
        });
    }
    //#endregion

    sendDtmf(code: string, toneDurationMS: string, toneSpacingMS: string) {
        this.appContext.inContactAPIService.sendDtmf(code, toneDurationMS, toneSpacingMS).subscribe(result => {
            this.log(result);
        });
    }

    transferCalls() {
        return this.appContext.inContactAPIService.transferCalls();
        /* .subscribe(res => {
            this.messageService.setCallMessage2.next('Your call has been trasfered now.');
        }); */
    }

    conferenceCalls() {
        return this.appContext.inContactAPIService.conferenceCalls();
        /* .subscribe(res => {
            this.messageService.setCallMessage2.next('Your are in conference now.');
        }); */
    }

    protected onContactActivated(contact: CallContactModel) {
        super.onContactActivated(contact);
        if (!(contact.omniGroupId && contact.omniGroupId != '0' && contact.omniGroupId != contact.contactId)) {
            //this.router.navigate(['active-contact']);
            this.navigateTo(['active-contact']);
        }
    }

    protected onAfterNewContactAdded(contact: CallContactModel) {
        return super.onAfterNewContactAdded(contact).pipe(
            map(c => {
                if (contact.isDialing() || contact.isRining() || contact.isIncoming()) {
                    this.navigateTo(['active-contact']);
                }

                if (contact.isIncoming() || contact.isDialing()) {
                    if (this.appContext.selectedCrmApplication?.applicationType === CRM_APPLICATION_TYPES.KUSTOMER) {
                        this.appContext.crmIntegrationService.processIncomingCallContact(contact);
                    }
                }
            }),
        );
    }

    public handleDisconnectedContat(contact: CallContactModel): void {
        if (this.appContext.selectedCrmApplication?.applicationType === CRM_APPLICATION_TYPES.KUSTOMER) {
            this.appContext.crmIntegrationService.processEndedCallContact(contact);
        }
    }

    //#region Private Methods
    private holdExistingContact(): Observable<any> {
        return new Observable(observer => {
            if (this.currentContacts.value.length > 0) {
                const firstContact = this.currentContacts.value[0];
                if (firstContact.isDisconnected() && firstContact.canHaveDisposition() && firstContact.skillInfo.requireDisposition) {
                    this.appContext.messageService.notify({
                        Subject: 'Invalid State',
                        MessageText: 'Invalid State - Please check your existing contacts.',
                        Type: 'warning',
                    });
                    return;
                } else if (firstContact.isDisconnected() && !firstContact.skillInfo.requireDisposition /* !firstContact.canHaveDisposition() */) {
                    const agetnState = new AgentStateModel('Available', '', 'Available', false);
                    this.appContext.agentSessionService.agentStateChanged(agetnState);
                    setTimeout(() => {
                        observer.next(true);
                    }, 500);
                } else if (firstContact && !firstContact.isHolding()) {
                    this.holdContact(firstContact).subscribe(r => {
                        observer.next(true);
                    });
                } else {
                    observer.next(true);
                }
            } else {
                observer.next(true);
            }
        });
    }
    //#endregion
}
