import {Injectable} from '@angular/core';

import {Agent} from '.././_models/agent.model';

import {Observable} from 'rxjs';

// import {LocalStorageService, SessionStorageService} from 'ng2-webstorage';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable()
/** Dummy version of an authenticated user service */
export class AgentService {
    public isGreen = false;
    public isCyborg = false;
    public isCerulean = true;

    constructor(private _localStorageService: LocalStorageService, private icAgentSessionSrv: IcAgentSessionService) {}

    public setClasses() {
        const classes = {
            'theme-green': this.isGreen, // true
            'theme-cyborg': this.isCyborg, // false
            'theme-cerulean': this.isCerulean,
        };
        return '';
    }
}
