import {Component, OnInit} from '@angular/core';

// import {
//     DialogRef,
//     ModalComponent,
//     CloseGuard,
// } from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal';
// import {BSModalContext} from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal/plugins/bootstrap';
import {integrationManagerService} from '../integrationManager.service';
// import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {DialogRef} from 'ngx-modialog-7';
import {TranslateService} from '@ngx-translate/core';
import {AnyObject} from 'src/app/global';
import {CrmApplicationModel} from 'src/app/sdk/fac-crm/models';

export class ViewModel {
    public contactType: string;
    public agentId: number;
}

/**
 * A Sample of how simple it is to create a new window, with its own injects.
 */
@Component({
    selector: 'select-crm-form',
    templateUrl: './select-crm.component.html',
    styleUrls: ['./select-crm.component.css'],
})
export class SelectCrmModalComponent implements OnInit {
    public onClose: Subject<boolean>;

    // public contactType: string;
    // public agentId: number;

    public selectedOption: CrmApplicationModel;
    public showAgents: boolean;
    public showSkills: boolean;
    crmApplication: Array<CrmApplicationModel> = [];

    private context: ViewModel;

    constructor(public dialog: DialogRef<ViewModel>, private integrationManager: integrationManagerService, public translate: TranslateService) {
        this.context = dialog.context;
        //this.context.options = ['Select  transfer type','Agents', 'Skills'];
        // dialog.setCloseGuard(this);
    }

    ngOnInit() {
        this.onClose = new Subject();

        this.crmApplication = this.integrationManager.crmApplications;
        this.selectedOption = this.crmApplication[0];
    }

    onChange(option) {
        this.selectedOption = option;

        if (option === 'Agents') {
            this.showAgents = true;
            this.showSkills = false;
        } else if (option === 'Skills') {
            this.showAgents = false;
            this.showSkills = true;
        }
    }
    onKeyUp(value) {
        this.dialog.close();
    }

    OK() {
        this.integrationManager.selectedCRMFromDialog = this.selectedOption;
        this.dialog.close(true);
    }

    cancel() {
        this.dialog.close(false);
    }
    // beforeDismiss(): boolean {
    //   return true;
    // }

    // beforeClose(): boolean {
    //   return this.wrongAnswer;
    // }
}
