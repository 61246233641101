import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DialogRef, overlayConfigFactory} from 'ngx-modialog-7';
import {AgentSettingsDialogComponent} from 'src/app/shared/modals/agent-settings-diloag/agent-settings-diloag.component';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
import {IcAgentSessionService} from 'src/app/sdk/incontact/services/agent-sessions/agent-session-events.service';
import {MySubscriptionHandler} from 'src/app/shared/classes';

@Component({
    selector: 'app-default-footer',
    templateUrl: './default-footer.component.html',
    styleUrls: ['./default-footer.component.scss'],
})
export class DefaultFooterComponent extends MySubscriptionHandler implements OnInit, AfterViewInit {
    totalActiveContacts = 0;
    agentIndicators: Array<any> = [];
    personalConnectionEnabled: boolean;

    diloagRef: DialogRef<any>;

    constructor(public icAgentSessionSrv: IcAgentSessionService, public diloagSrv: DialogService) {
        super();
    }

    ngOnInit() {
        this.icAgentSessionSrv.totalActiveContacts.subscribe(count => (this.totalActiveContacts = count));

        this.addSubscription$ = this.icAgentSessionSrv.agentIndicators.subscribe(indicators => {
            this.agentIndicators = indicators;
            // console.log('indicators', indicators);
        });

        this.addSubscription$ = this.icAgentSessionSrv.personalConnectionEnabled.subscribe(enabled => (this.personalConnectionEnabled = enabled));
    }
    ngAfterViewInit() {}

    SettingsClick() {
        this.diloagRef = this.diloagSrv.openModal(
            AgentSettingsDialogComponent,
            overlayConfigFactory({
                dialogClass: 'modal-dialog',
            }),
        );
    }
}
