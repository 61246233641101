import {NgModule} from '@angular/core';

import {WorkItemsRoutingModule} from './work-items.routing';
import {WorkItemsComponent} from './work-items.component';

import {SharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [WorkItemsRoutingModule, SharedModule],
    declarations: [WorkItemsComponent],
    exports: [WorkItemsComponent],
    providers: [],
})
export class WorkItemModule {}
