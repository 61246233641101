import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {InContactAPIService} from '.././InContact/incontact-api.service';
// import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {MessageService} from '../_services/message.service';
import {MarqueeMessageModel} from 'src/app/sdk/incontact/models';

@Injectable()
export class MarqueeMessagesService {
    private messageIds: Array<string> = [];

    public constructor(
        // private toastyService: ToastyService,
        // private toastyConfig: ToastyConfig,
        private inContactApiService: InContactAPIService,
        private agentSessionService: IcAgentSessionService,
        private messageService: MessageService,
    ) {}

    UpdateMarqueeMessages(event: any) {
        // var me = this;
        // me.toastyService.clearAll();
        this.inContactApiService
            .getMarqueeMessages(this.agentSessionService.agentId.value)
            .subscribe((messages: Array<MarqueeMessageModel>) => {
                const toShow = messages.filter(m => !this.messageIds.includes(m.MessageId));
                this.messageIds.push(...messages.map(x => x.MessageId));
                toShow.forEach(item => {
                    // var milisec = item.ExpireTimer * 60 * 1000;
                    //zenApp.fcc.common.showNotification('<b>' + item.Subject + '</b><br/>' + item.MessageText, 'notice', milisec);
                    // var toastOptions: ToastOptions = {
                    //     title: item.Subject,
                    //     msg: item.MessageText,
                    //     showClose: true,
                    //     timeout: milisec,
                    //     //theme: 'default',
                    //     onAdd: (toast: ToastData) => {
                    //         //console.log('Toast ' + toast.id + ' has been added!');
                    //     },
                    //     onRemove: function (toast: ToastData) {
                    //         //console.log('Toast ' + toast.id + ' has been removed!');
                    //     },
                    // };
                    // me.toastyService.info(toastOptions);
                    // this.toasterSrv.show('INFO', item.Subject, item.MessageText, undefined, milisec);
                    if (!!item.Subject && !!item.MessageText) {
                        this.messageService.notify({
                            Subject: item.Subject,
                            MessageText: item.MessageText,
                            Type: 'info',
                            ExpireTimer: +item.ExpireTimer,
                        });
                    }
                });
            });
    }

    ClearAll() {
        // this.toastyService.clearAll();
    }
}
