import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DialogRef} from 'ngx-modialog-7';
import {WebRTCService} from 'src/app/core/InContact/web-rtc/web-rtc.service';
import {IcSecurityProfileService} from 'src/app/sdk/incontact/services';
import {IcAgentSessionService} from 'src/app/sdk/incontact/services/agent-sessions/agent-session-events.service';
import {FacLocalStorageService} from 'src/app/sdk/storage';
import {ViewModel} from 'src/app/shared/transfer-active-contact-modal/transfer-active-contact.component';

@Component({
    selector: 'app-agent-settings-dialog',
    templateUrl: './agent-settings-diloag.component.html',
    styleUrls: ['./agent-settings-diloag.component.scss'],
})
export class AgentSettingsDialogComponent implements OnInit, AfterViewInit {
    public autoAccept = 'No';
    public acceptSaved = false;
    public ringSaved = false;

    public ringtone = '';

    public volume = 10;

    public rigntonesBasePath = '/assets/webrtc/sounds/';

    constructor(
        public dialog: DialogRef<ViewModel>,
        private agentSessionEventSrv: IcAgentSessionService,
        private securityProfileSrv: IcSecurityProfileService,
        public facLocalStorageSrv: FacLocalStorageService,
        public webRtcSrv: WebRTCService,
    ) {
        //
    }

    ngOnInit(): void {
        if (!!this.facLocalStorageSrv.getItem('AutoAcceptInboundCall')) {
            this.autoAccept = this.facLocalStorageSrv.getItem('AutoAcceptInboundCall');
        }

        // this.securityProfileSrv.securityProfileBS.subscribe(securityProfile => {

        // });

        this.ringtone = this.webRtcSrv.currentRingTone();
        this.volume = +this.facLocalStorageSrv.getItem('volume') || 10;
        this.webRtcSrv.setVolume(this.volume);
    }

    ngAfterViewInit(): void {
        //
    }

    public volumeChange() {
        this.webRtcSrv.setVolume(this.volume);
        this.facLocalStorageSrv.setItem('volume', `${this.volume}`);
    }

    public RingtoneChanged() {
        if (this.ringtone) {
            this.webRtcSrv.setRingtone(this.ringtone);

            setTimeout(() => {
                this.ringSaved = true;
                setTimeout(() => {
                    this.ringSaved = false;
                }, 3000);
            }, 100);
        }
    }

    public Play() {
        if (this.ringtone) {
            const audio = new Audio(this.rigntonesBasePath + this.ringtone + '.mp3');
            audio.volume = this.volume / 10;
            audio.play();
        }
    }

    public onAutoAccept(s: string) {
        this.autoAccept = s;
        this.facLocalStorageSrv.setItem('AutoAcceptInboundCall', this.autoAccept);
        this.webRtcSrv.decideAutoAccept();
        setTimeout(() => {
            this.acceptSaved = true;
            setTimeout(() => {
                this.acceptSaved = false;
            }, 3000);
        }, 100);
    }

    public get showAutoAcceptSetting() {
        return (
            this.agentSessionEventSrv.isWebRTCSession &&
            this.securityProfileSrv.hasPermission('AgentSoftPhoneAutoAccept', 'Enable') &&
            this.securityProfileSrv.hasPermission('AgentSoftPhoneAutoAccept', 'AgentConfig')
        );
    }
}
