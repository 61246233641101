import {ApplicationContextService} from '../../_services/application-context.service';
import {LOCATION_INITIALIZED} from '@angular/common';
import {ContactBase} from '../contact-base';
import {ContactServiceBase} from 'src/app/core/_services/contact.service.base';
import {AfterViewInit} from '@angular/core';
// import {Overlay, overlayConfigFactory} from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal';
import {TransferActiveContactModalComponent} from '../../../shared/transfer-active-contact-modal/transfer-active-contact.component';
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {overlayConfigFactory} from 'ngx-modialog-7';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
import {CrmDataHolderViewModel} from '../crm';
import {AnyObject, timeUnits} from 'src/app/global';
// import {BsModalService} from 'ngx-bootstrap/modal';

export class ContactViewBaseModel<T extends ContactBase> {
    public actions: ContactActionModel[] = [];
    public configMap: any = {};
    public settings: any = {};
    public crmFieldMappings: any = [];
    public omniGroupId: string = null;
    public preDeispositionSet = false;
    public showActiveContactDisposition = false;

    public contactType: string;
    public contactIcon: string;
    public OmniGroupRelatedContacts: ContactViewBaseModel<ContactBase>[] = [];

    public OmniChildContacts: ContactViewBaseModel<ContactBase>[] = [];

    //public canElevateToEmail: Function = () => { return this.contact.supportsEmailElevation && !this.contact.isDisconnected() &&
    // this.appContext.emailContactService.currentContacts.value.filter(v => v.omniGroupId && (v.omniGroupId == this.contact.contactId)).length == 0 };
    // public canElevateToEmail: Function = () => { return this.contact.supportsEmailElevation && !this.contact.isDisconnected() &&
    // this.appContext.allContactsCollection.value.filter(x => this.contact.omniGroupId && x.contact.contactId == this.contact.omniGroupId &&
    //x.contact.contactType == "Email").length == 0 && this.OmniGroupRelatedContacts.filter(v => v.contact.contactType == "Email").length == 0 };

    public canElevateToEmail: Function = () =>
        this.contact.supportsEmailElevation &&
        !this.contact.isDisconnected() &&
        this.appContext.allContactsCollection.value.filter(
            x =>
                x.contact.contactType == 'Email' &&
                x.contact.omniGroupId &&
                (x.contact.omniGroupId == this.contact.contactId || x.contact.omniGroupId == this.contact.omniGroupId),
        ).length == 0;
    public canElevateToPhone: Function = () =>
        this.contact.supportsPhoneElevation && !this.contact.isDisconnected() && this.appContext.callContactService.currentContacts.value.length == 0;

    public isEmailElevationVisible: Function = () => this.canElevateToEmail();
    public isPhoneElevationVisible: Function = () => this.canElevateToPhone();

    public contactCustomFieldsMapping: any = {
        _recordingFilePath: () => this.replaceTokensWithValues(this.settings.recordingFilePath),
        _starttime: () => this.ConvertToLocalDate(new Date(this.contact.startTimeUTC)),
        _duration: () => this.getLengthString(new Date(this.contact.startTime), new Date(this.contact.lastStateChangeTime)),
        _skillName: () => this.contact.skillName,
        _campaignNo: () => this.contact.skillInfo.campaignId,
        _campaignName: () => this.contact.skillInfo.campaignName,
        _dispName: () => (this.contact.appliedDisposition ? this.contact.appliedDisposition.dispositionName : ''),
        _dispCode: () => (this.contact.appliedDisposition ? this.contact.appliedDisposition.dispositionId : ''),
        _secDisp: () => (this.contact.appliedSecondaryDisposition ? this.contact.appliedSecondaryDisposition.dispositionName : ''),
        _secDispCode: () => (this.contact.appliedSecondaryDisposition ? this.contact.appliedSecondaryDisposition.dispositionId : ''),
        _dispNotes: () => this.contact.appliedDispositionComments,
        _agentName: () => {
            const agent = this.appContext.agentSessionService.loadAgent();
            return `${agent.info.firstName}  ${agent.info.lastName}`;
        },
        _agentNo: () => {
            const agent = this.appContext.agentSessionService.loadAgent();
            return `${agent.info.agentId}`;
        },
    };

    protected getLengthString(date1: Date, date2: Date) {
        const diff = date2.valueOf() - date1.valueOf();
        // let mins = Math.floor(diff / 1000 / 60);
        // let secs = (diff / 1000) % 60;
        // return (
        //     ('00' + mins).substr(('00' + mins).length - 2, ('00' + mins).length) + ':' + ('00' + secs).substr(('00' + secs).length - 2, ('00' + secs).length)
        // );
        const time = timeUnits(diff);

        return `${time.hours}:${time.minutes}:${time.seconds}`;
    }

    protected ConvertToLocalDate(date: Date): string {
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }

    //public indicators: IndicatorModel[] = [];
    public indicatorsAction = new ContactActionModel({
        text: 'Launch',
        action: 'executeindicator',
        icon: 'bolt',
        menuItems: [],
        isVisible: () => this.indicatorsAction.menuItems.length > 0,
    });

    public phoneElevationAction = new ContactActionModel({
        action: 'elevate_to_phone',
        text: 'Call',
        icon: 'phone',
        slot: 'elevations',
        isAvailable: () => this.canElevateToPhone(),
        isVisible: () => this.isPhoneElevationVisible(),
    });

    public emailElevationAction = new ContactActionModel({
        action: 'elevate_to_email',
        text: 'Email',
        icon: 'envelope-open',
        slot: 'elevations',
        isAvailable: () => this.canElevateToEmail(),
        isVisible: () => this.isEmailElevationVisible(),
    });

    constructor(public contact: T, public contactService: ContactServiceBase<T, ContactViewBaseModel<T>>, protected appContext: ApplicationContextService) {
        if (contact.isOmniGroupContact()) {
            this.omniGroupId = contact.omniGroupId;
            this.appContext.allContactsCollection.subscribe(allcontacts => {
                this.OmniGroupRelatedContacts = allcontacts.filter(
                    x =>
                        !x.contact.isDisconnected() &&
                        x.contact.contactId != this.contact.contactId &&
                        (x.contact.omniGroupId == this.contact.contactId ||
                            x.contact.contactId == this.contact.omniGroupId) /*  && this.contact.contactType == "Call" */,
                );
                this.OmniChildContacts = allcontacts.filter(
                    x => !x.contact.isDisconnected() && x.contact.contactId != x.contact.omniGroupId && x.contact.omniGroupId == this.contact.contactId,
                );
                console.log(this.OmniChildContacts);
            });
        }
        this.appContext.indicatorsService.indicatorEvents.subscribe(indicators => {
            this.indicatorsAction.menuItems = indicators
                .filter(item => item.ContactID == contact.contactId)
                .map(i => new ContactActionModel({text: i.ToolTip, action: 'executeindicator', data: i}));
        });

        this.actions.push(this.phoneElevationAction);
        this.actions.push(this.emailElevationAction);

        this.vmInit();
        this.settings = this.appContext.getCrmSettings(this.configMap.settingSectionName) ?? {};
        this.crmFieldMappings = this.appContext.getCrmMappings(this.configMap.settingSectionName);
        setTimeout(() => {
            this.afterVmInit();
        }, 1);
    }

    protected vmInit() {}
    protected afterVmInit() {
        this.actions.push(this.indicatorsAction);

        this.contactIcon = this.contact.contactIcon;
        this.contactType = this.contact.contactType;
    }

    getChildContacts(): ContactViewBaseModel<T>[] {
        return this.contactService.currentContactViewModels.value.filter(
            childVM => childVM.contact.contactId != this.contact.contactId && childVM.contact.masterId == this.contact.contactId,
        );
    }

    protected transferContact(data) {
        // this.modalSrv.show(TransferActiveContactModalComponent, {
        //     initialState: {
        //         context: {
        //             contactType: this.contact.contactType,
        //             contact: this.contact,
        //             agentId: this.appContext.agentService.agentId.value,
        //             contactService: this.contactService,
        //         },
        //     },
        // });

        this.appContext.dialogService.openModal(
            TransferActiveContactModalComponent,
            overlayConfigFactory({
                contactType: this.contact.contactType,
                contact: this.contact,
                agentId: this.appContext.agentSessionService.agentId.value,
                contactService: this.contactService,
                dialogClass: 'modal-dialog',
            }),
        );
        this.contactService.transferContact(this.contact, {});
    }

    public executeContactAction(action: string, data?: any) {
        switch (action) {
            case 'end':
                this.contactService.endContact(this.contact).subscribe();
                break;
            case 'transfer':
                //alert("Transferring this contact");
                this.transferContact(data);
                break;
            case 'hold':
                this.contactService.holdContact(this.contact).subscribe();
                break;
            case 'resume':
                this.contactService.resumeContact(this.contact).subscribe();
                break;
            case 'executeindicator':
                this.appContext.indicatorsService.executeIndicatorFromEvent(data);
                break;
            default:
                break;
        }
    }

    public getContactPropertyValueByKey(key: string) {
        let val = key;
        for (const prop in this.contact.rawData) {
            if (this.contact.rawData.hasOwnProperty(prop) && key.toLowerCase() == prop.toLowerCase()) {
                val = this.contact.rawData[prop] ?? this.contact[prop];
                return val;
            }
        }
        for (const prop in this.contactCustomFieldsMapping) {
            if (this.contactCustomFieldsMapping.hasOwnProperty(prop) && key.toLowerCase() == prop.toLowerCase()) {
                const fval = this.contactCustomFieldsMapping[prop];
                if (typeof fval == 'function') {
                    return fval();
                } else {
                    return fval;
                }
            }
        }
    }

    public toContactDetailsObject(isHtml: boolean = true): AnyObject {
        const data = {contact: this.contact.rawData};
        const htmlstr: AnyObject = {};
        const context = {mappings: this.crmFieldMappings, configMap: this.configMap};

        if (context.mappings) {
            const commentMappings = context.mappings.filter(item => item.addToComments == true);
            // if (commentMappings.length > 0) {
            //     if (isHtml) {
            //         htmlstr['Contact Title'] = '<strong>' + context.configMap.contactTitle + ' Details:</strong><br />---<br /> ';
            //     } else {
            //         htmlstr['Contact Title'] = context.configMap.contactTitle + ' Details:\n\n ';
            //     }
            // }
            for (let j = 0; j < commentMappings.length; j++) {
                if (commentMappings[j].name === '_recordingFilePath') {
                    const v = this.getContactPropertyValueByKey('_recordingFilePath');
                    if (isHtml) {
                        // if (v) htmlstr[commentMappings[j].label] = `<a href="${v}">${v}</a>`;
                        if (v) {
                            htmlstr.recordingUrl = `<a href="${v}">${v}</a>`;
                        }
                    } else {
                        if (v) {
                            htmlstr.recordingUrl = `\n${v}\n`;
                        }
                        // if (v) htmlstr[commentMappings[j].label] = `\n${v}\n`;
                    }
                } else if (commentMappings[j].name === '_starttime') {
                    const v = this.getContactPropertyValueByKey('_starttime');
                    if (isHtml) {
                        if (v) {
                            htmlstr[commentMappings[j].label] = `${v}`;
                        }
                    } else {
                        if (v) {
                            htmlstr[commentMappings[j].label] = `\n $${v}\n`;
                        }
                    }
                } else if (commentMappings[j].name === '_duration') {
                    const v = this.getContactPropertyValueByKey('_duration');
                    if (isHtml) {
                        if (v) {
                            htmlstr[commentMappings[j].label] = `${v}`;
                        }
                    } else {
                        if (v) {
                            htmlstr[commentMappings[j].label] = `\n${v}\n`;
                        }
                    }
                } else {
                    const otherVal = this.getContactPropertyValueByKey(commentMappings[j].name);
                    if (isHtml) {
                        if (otherVal) {
                            htmlstr[commentMappings[j].label] = `${otherVal}`;
                        }
                    } else {
                        if (otherVal) {
                            htmlstr[commentMappings[j].label] = `\n${otherVal}\n`;
                        }
                    }
                }
            }
        }
        return htmlstr;
    }

    public toContactString(isHtml: boolean = true): string {
        const data = {contact: this.contact.rawData};
        let htmlstr = '';
        const context = {mappings: this.crmFieldMappings, configMap: this.configMap};

        if (context.mappings) {
            const commentMappings = context.mappings.filter(item => item.addToComments == true);

            if (commentMappings.length > 0) {
                if (isHtml) {
                    htmlstr = '<strong>' + context.configMap.contactTitle + ' Details:</strong><br />---<br /> ';
                } else {
                    htmlstr = context.configMap.contactTitle + ' Details:\n\n ';
                }
            }
            for (let j = 0; j < commentMappings.length; j++) {
                if (commentMappings[j].name === '_recordingFilePath') {
                    const v = this.getContactPropertyValueByKey('_recordingFilePath');
                    if (isHtml) {
                        if (v) {
                            htmlstr += `<br /><strong>${commentMappings[j].label}</strong>: <a href="${v}">${v}</a>`;
                        }
                    } else {
                        if (v) {
                            htmlstr += `\n${commentMappings[j].label} ${v}\n`;
                        }
                    }
                } else if (commentMappings[j].name === '_starttime') {
                    const v = this.getContactPropertyValueByKey('_starttime');
                    if (isHtml) {
                        if (v) {
                            htmlstr += `<br /><strong>${commentMappings[j].label}:</strong> ${v}`;
                        }
                    } else {
                        if (v) {
                            htmlstr += `\n ${commentMappings[j].label}: ${v}\n`;
                        }
                    }
                } else if (commentMappings[j].name === '_duration') {
                    const v = this.getContactPropertyValueByKey('_duration');
                    if (isHtml) {
                        if (v) {
                            htmlstr += `<br /><strong>${commentMappings[j].label}</strong>: ${v}`;
                        }
                    } else {
                        if (v) {
                            htmlstr += `\n${commentMappings[j].label}: ${v}\n`;
                        }
                    }
                } else {
                    const otherVal = this.getContactPropertyValueByKey(commentMappings[j].name);
                    // console.log(commentMappings[j].name, this.contact.rawData);
                    if (isHtml) {
                        if (otherVal) {
                            htmlstr += `<br /><strong>${commentMappings[j].label}</strong>: ${otherVal}`;
                        }
                    } else {
                        if (otherVal) {
                            htmlstr += `\n${commentMappings[j].label}: ${otherVal}\n`;
                        }
                    }
                }
            }
        }
        return htmlstr;
    }

    transferData(viewmodel: ContactViewBaseModel<T>) {
        this.preDeispositionSet = viewmodel.preDeispositionSet;
    }

    replaceTokensWithValues(template: string) {
        let val = '';
        const me = this;
        const data = {contact: me.contact.rawData};
        if (template) {
            const regex = /\$\{([^}]+)\}/g;
            const subStrRegex = /\$sub\{([^}]+)\}/g;
            //var str = "I expect five hundred dollars ${500}. and ${300}";

            let m;

            val = template.replace(regex, function (a, b) {
                const v = me.getContactPropertyValueByKey(b);
                if (v) {
                    return v;
                } else {
                    return a;
                }
            });

            val = val.replace(subStrRegex, function (a, b) {
                const parts = b.split(':');
                const subStrParts = parts[1].split('-');
                const startIndex = parseInt(subStrParts[0], 10);
                const length = parseInt(subStrParts[1], 10);
                const v = me.getContactPropertyValueByKey(parts[0]);
                if (v) {
                    return v.substring(startIndex, length);
                } else {
                    return a;
                }
            });
        }

        return val;
    }

    isCrmProcessable(): boolean {
        return true;
    }
}

export class ContactActionModel {
    constructor(data?: Partial<ContactActionModel>) {
        this.action = data?.action;
        this.text = data?.text;
        this.icon = data?.icon;
        this.data = data?.data;

        if (data?.slot) {
            this.slot = data?.slot;
        }
        if (data?.isAvailable) {
            this.isAvailable = data?.isAvailable;
        }
        if (data?.isVisible) {
            this.isVisible = data?.isVisible;
        }
        if (data?.isFullRow) {
            this.isFullRow = data?.isFullRow;
        }
        if (data?.visibleInOmniRelatedMode) {
            this.visibleInOmniRelatedMode = data?.visibleInOmniRelatedMode;
        }
        if (data?.textAlwaysvisible) {
            this.textAlwaysvisible = data?.textAlwaysvisible;
        }
        if (data?.menuItems && data?.menuItems.length > 0) {
            this.menuItems = data?.menuItems;
        }
        if (data?.color) {
            this.color = data?.color;
        }
    }
    public action: string;
    public text: string;
    icon: string;
    slot = 'footer';
    color = '';
    isFullRow = false;
    isAvailable: Function = () => true;
    isVisible: Function = () => true;
    menuItems: ContactActionModel[] = [];

    hasMenuItems = () => this.menuItems.length > 0;
    data: any;
    visibleInOmniRelatedMode = false;
    textAlwaysvisible = false;
}
