import {AnyObject} from 'src/app/global';

export class KustomerAdapter {
    _currentUser: any;
    _fetchingCurrentUser: boolean;
    _messages: AnyObject;
    _taskStatus: any;
    _kustomerUserStatusesCodes: AnyObject;
    _appSettings: AnyObject;

    constructor() {
        this._currentUser = undefined;
        this._fetchingCurrentUser = false;
        this._messages = {};
        //   this._taskStatus;
        this._kustomerUserStatusesCodes = {};
        this._appSettings = {};
    }
    // Generic helper to make requests using the Kustomer API
    _kustomerRequest(params) {
        return new Promise((resolve, reject) => {
            window.Kustomer.request(params, (err, data) => {
                if (err) {
                    return reject(err);
                }
                return resolve(data);
            });
        });
    }

    findAppSettings(appName) {
        if (!appName) {
            return Promise.resolve();
        }

        return this._kustomerRequest({
            url: `/v1/settings/${appName}`,
            method: 'get',
        }).catch(err => {
            if (err && err.error && err.error.status === 404) {
                return Promise.resolve();
            }
            return Promise.reject(err);
        });
    }

    getRoutingUserStatues() {
        return this._kustomerRequest({
            url: '/v1/routing/statuses',
            method: 'get',
        }).catch(err => {
            if (err && err.error && err.error.status === 404) {
                return Promise.resolve();
            }
            return Promise.reject(err);
        });
    }

    // Attempt to find a customer by phone number
    findCustomerByPhoneNumber(formattedPhone) {
        if (!formattedPhone) {
            return Promise.resolve();
        }

        return this._kustomerRequest({
            url: `/v1/customers/phone=${formattedPhone}`,
            method: 'get',
        }).catch(err => {
            console.log(err, err?.error?.status);
            if (err && err.error && err.error.status === 404) {
                return Promise.resolve();
            }
            return Promise.reject(err);
        });
    }

    // Creates a customer with as much information as is available (ideally a phone number)
    createCustomer(params) {
        const body: any = {};

        const phoneNumber = params.task.attributes.caller;
        const formattedPhone = phoneNumber.replace(/\D*/g, '');
        if (!formattedPhone) {
            body.name = 'Unidentified Caller';
        } else {
            body.phones = [
                {
                    type: 'home',
                    phone: formattedPhone,
                },
            ];
        }
        return this._kustomerRequest({
            url: '/v1/customers',
            method: 'post',
            body,
        }).catch(err => {
            // Handle Race Condition - Customer Recently Created With Phone
            if (err && err.error && err.error.status === 409 && err.error.code === 'duplicate') {
                return this.findCustomerByPhoneNumber(formattedPhone);
            }
            return Promise.reject(err);
        });
    }

    // Attempting to find a customer by phone number in the system or creating them
    findOrCreateCustomer(params) {
        const callType = params.call.direction.toLowerCase();
        let phoneNumber;
        if (callType == 'outbound') {
            phoneNumber = params.call.to;
        } else {
            phoneNumber = params.call.from;
        }
        const formattedPhone = phoneNumber.replace(/\D*/g, '');

        return this.findCustomerByPhoneNumber(formattedPhone)
            .then(customer => {
                if (!customer) {
                    return this.createCustomer(params);
                }
                return Promise.resolve(customer);
            })
            .catch(err => {
                console.log('Kustomer findOrCreateCustomer error: ', err);
                return Promise.reject(new Error('unknown error - failed to find or create customer'));
            });
    }

    // This is the main part of creating the call for the first time. We adjust the to/from/direction infromation
    // based on whether the call was made OUT from an agent to customer, or IN from a customer to agent
    _getInitialMessageBody(params) {
        // Extract Start/End Timestamps (in case we're creating an already-ended call)
        const callData: any = {
            placedAt: params.call.placedAt,
        };
        // Direction-Based Information
        const callType = params.call.direction.toLowerCase();
        let customerPhoneNumber;
        callData.to = params.call.to;
        callData.from = params.call.from;
        if (callType == 'outbound') {
            callData.direction = 'out';
            customerPhoneNumber = callData.to;
        } else {
            callData.direction = 'in';
            customerPhoneNumber = callData.from;
        }
        return {
            app: 'Frontline Agent Console',
            channel: 'voice',
            externalId: params.call.id,
            preview: 'Voice Message',
            meta: {
                subject: callData.direction === 'out' ? `Outbound Call to ${customerPhoneNumber}` : `Inbound Call from ${customerPhoneNumber}`,
                to: callData.to,
                from: callData.from,
                placedAt: callData.placedAt,
                status: this._taskStatus,
                //recordingUrl : params.call.recordingUrl
            },
            size: 1,
            direction: callData.direction,
            sentAt: new Date().toISOString(),
            queue: {
                external: 'Frontline Agent Console',
            },
        };
    }

    _getEndedMessageBody(params) {
        // Update status to ended
        const messageBody = {
            meta: {
                endedAt: params.call.endedAt,
                status: 'ended',
                // ended: true,
            },
        };
        return messageBody;
    }

    _getAnswerMessageBody(params) {
        // Update status to ended
        const messageBody = {
            meta: {
                answeredAt: params.call.answeredAt,
            },
        };
        return messageBody;
    }

    _cacheMessage(message) {
        this._messages[message.attributes.externalId] = message;
        return Promise.resolve(message);
    }

    _findCachedMessage(externalId) {
        const cachedMessage = this._messages[externalId];
        return cachedMessage;
    }

    // Look up a message by externalId. In anticipation of this being called multiple times,
    // we cache message bodies in local state to minimize network requests.
    findMessage(params) {
        const externalId = params.call.id;
        const cachedMessage = this._findCachedMessage(externalId);
        if (cachedMessage) {
            return Promise.resolve(cachedMessage);
        }

        return this._kustomerRequest({
            url: `/v1/messages/externalId=${externalId}`,
            method: 'get',
        }).then(foundMessage => this._cacheMessage(foundMessage));
    }

    // Creating the inital message – _getInitialMessageBody contains more information
    // on the structure of the payload
    createMessage(customer, params) {
        return this._kustomerRequest({
            url: `/v1/customers/${customer.id}/messages`,
            method: 'post',
            body: this._getInitialMessageBody(params),
        })
            .then(createdMessage => this._cacheMessage(createdMessage))
            .catch(err => {
                // Handle Race Condition - Message Recently Created
                if (err && err.error && err.error.status === 409 && err.error.code === 'duplicate') {
                    return this.findMessage(params);
                }
                return Promise.reject(err);
            });
    }
    // Generic function to update a conversation
    updateConversation(conversationId, body) {
        return this._kustomerRequest({
            url: `/v1/conversations/${conversationId}`,
            method: 'put',
            body,
        });
    }

    // Updates the message to have the latest timestamps, duration, etc.
    updateMessageStatus(message, params) {
        if (params.call.endedAt) {
            return this._kustomerRequest({
                url: `/v1/messages/${message.id}`,
                method: 'put',
                body: this._getEndedMessageBody(params),
            }).then(updatedMessage => this._cacheMessage(updatedMessage));
        } else {
            return this._kustomerRequest({
                url: `/v1/messages/${message.id}`,
                method: 'put',
                body: this._getAnswerMessageBody(params),
            }).then(updatedMessage => this._cacheMessage(updatedMessage));
        }
    }

    // When a call is ended, we want to update the conversation to be "ended" and update
    // some metadata / timestamps on the message.
    // This is also a good place to set other custom data available to the widget or call recording information
    findAndUpdateMessageStatus(params) {
        return this.findMessage(params).then(message => {
            const conversationId = message.relationships.conversation.data.id;
            const updatedConvoBody = {
                ended: true,
            };

            const updates = [this.updateMessageStatus(message, params), this.updateConversation(conversationId, updatedConvoBody)];

            return Promise.all(updates);
        });
    }

    findAndUpdateMessageMeta(params, meta: AnyObject) {
        return this.findMessage(params).then(message => {
            const conversationId = message.relationships.conversation.data.id;
            const updatedConvoBody = {meta};

            return this._kustomerRequest({
                url: `/v1/messages/${message.id}`,
                method: 'put',
                body: updatedConvoBody,
            }).then(updatedMessage => this._cacheMessage(updatedMessage));
            // const updates = [this.updateMessageStatus(message, params), this.updateConversation(conversationId, updatedConvoBody)];

            // return Promise.all(updates);
        });
    }

    findAndUpdateMessageStatusAnswer(params) {
        return this.findMessage(params).then(message => {
            const updates = [this.updateMessageStatus(message, params)];
            return Promise.all(updates);
        });
    }

    createMessageAndAssignConversation(customer, params) {
        return this.createMessage(customer, params).then(message => {
            const currentUserId = this._currentUser.id;
            if (!currentUserId) {
                return Promise.resolve({
                    message,
                    customer,
                });
            }

            const conversationId = message.relationships.conversation.data.id;
            return this.updateConversation(conversationId, {
                assignedUsers: [currentUserId],
            }).then(conversation =>
                Promise.resolve({
                    message,
                    conversation,
                    customer,
                }),
            );
        });
    }

    // Find call by externalId. The externalId should be a unique identifier for the call and persist
    // across transfers.
    findCall(params): Promise<any> {
        const externalId = params.call.id;
        return this._kustomerRequest({
            url: `/v1/messages/externalId=${externalId}`,
            method: 'get',
        }).catch(err => {
            if (err && err.error && err.error.status === 404) {
                return Promise.resolve();
            }
            return Promise.reject(err);
        });
    }

    // When the agent is assigned a call for the first time, we always want to attempt to assign
    // the current agent to the call to keep Kustomer assignment in sync with call assignment.
    findCallAndReassignConversation(params): Promise<any> {
        return this.findCall(params).then((message: any): Promise<any> => {
            if (!message) {
                return Promise.resolve();
            }

            const currentUserId = this._currentUser.id;
            if (!currentUserId) {
                return Promise.resolve({
                    message,
                });
            }

            const conversationId = message.relationships.conversation.data.id;
            return this.updateConversation(conversationId, {
                assignedUsers: [currentUserId],
            }).then(conversation =>
                Promise.resolve({
                    conversation,
                    message,
                }),
            );
        });
    }

    // creating a call means finding or creating a customer by phone number then
    // creating the call itself (message/conversation)
    createCall(params) {
        return this.findOrCreateCustomer(params).then(customer => this.createMessageAndAssignConversation(customer, params));
    }

    // Attempts to find a call record (e.g. call transfer OR multiple tabs open)
    // or creates a new call record in the Kustomer timeline.
    findCallOrCreate(params) {
        return this.findCallAndReassignConversation(params).then(data => {
            if (!data || !data.message) {
                return this.createCall(params);
            }
            return Promise.resolve(data);
        });
    }

    // Fetches the current, logged-in user. To prevent making too many requests to Kustomer,
    // we maintain state here if the user has already been fetched.
    fetchCurrentUser(): Promise<any> {
        if (this._currentUser || this._fetchingCurrentUser) {
            return Promise.resolve(this._currentUser);
        }

        this._fetchingCurrentUser = true;
        return this._kustomerRequest({
            url: '/v1/users/current',
            method: 'get',
        })
            .then(user => {
                this._currentUser = user;
                this._fetchingCurrentUser = false;
                return Promise.resolve(user);
            })
            .catch(err => {
                this._fetchingCurrentUser = false;
                return Promise.reject(err);
            });
    }

    /***** Below are the three main functions to create a functional application *******/
    /***** These three functions rely on helper functions above to interact with Kustomer *******/

    // Example of a function to call when a call is accepted by the logged-in agent.
    // This one checks to see if the call already has a corresponding conversation resource
    // in Kustomer – if not, it creates one. Finally it navigates the user to the conversation
    // where the agent can see customer information, take notes, add details, etc.
    handleCallReceived(params) {
        return this.findCallOrCreate(params)
            .then(data => {
                const customer = data.message.relationships.customer.data.id;
                const conversation = data.message.relationships.conversation.data.id;

                // "Screen Pop" the found / created conversation
                window.Kustomer.openCustomerEvent(customer, conversation);

                return Promise.resolve();
            })
            .catch(err => Promise.reject(err));
    }

    // Example of initializing the widget. This example fetches the current user
    // that user's ID will later be used later to assign conversations
    handleInitialize(params) {
        return this.fetchCurrentUser();
    }

    handleAnswerCall(params) {
        return this.findAndUpdateMessageStatusAnswer(params);
    }

    // Example of a function that would be called when a call is ended.
    handleSaveLog(params) {
        // if (!params.meta) params.meta = {};
        // params.meta.status = this._taskStatus;
        return this.findAndUpdateMessageStatus(params);
    }

    //Example of a function that would create a note on a conversation.
    //https://developer.kustomer.com/kustomer-api-docs/reference/notes-conversations#createanotewithinconversation
    createNote(params) {
        return this.findCall(params).then(message => {
            if (!message) {
                return Promise.resolve();
            }
            const body = {
                body: params.call.note,
            };
            const conversationId = message.relationships.conversation.data.id;
            return this._kustomerRequest({
                url: `/v1/conversations/${conversationId}/notes`,
                method: 'post',
                body,
            }).catch(err => Promise.reject(new Error('unknown error - failed to create note')));
        });
    }

    getAppSettings(appName) {
        try {
            return this.findAppSettings(appName).then((appSettings: any) => {
                this._appSettings.entryPointId = appSettings.default[0].attributes.value;
                return Promise.resolve();
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }

    fetchUserStatuses() {
        try {
            return this.getRoutingUserStatues().then((userStatuses: any) => {
                if (userStatuses && userStatuses.length > 1) {
                    userStatuses.forEach(userStatus => {
                        if (userStatus.type === 'statuses') {
                            this._kustomerUserStatusesCodes[userStatus.id] = userStatus.attributes.name;
                        }
                    });
                }
                return Promise.resolve(userStatuses);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
