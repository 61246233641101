import {Component, Input, OnInit} from '@angular/core';
import {ContactViewBaseModel, ContactActionModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {ApplicationContextService} from 'src/app/core/_services/application-context.service';

declare let $: any;
declare let Bloodhound;

@Component({
    selector: 'app-contact-tagsinput',
    //template: '<input type="text" data-role="tagsinput" autocomplete="off" placeholder="Add Tag" attr.contactid="{{contact.contactId}}" />'
    templateUrl: './tags-inputbox.component.html',
    styleUrls: ['./tags-inputbox.component.scss'],
})
export class ContactTagsInputComponent implements OnInit {
    @Input()
    contact: ContactBase;
    tagsData: any[] = [];
    menuDynamicOffset = 0;
    maxDropdownWidth = 150;

    constructor(private appContext: ApplicationContextService) {}

    ngOnInit(): void {
        const me = this;
        this.appContext.inContactAPIService.getTagsBySkill(this.contact.skill).subscribe(tags => {
            // console.log(tags);

            const contact = this.contact;
            const data = tags.map(t => ({id: t.tagId, title: t.tagName}));

            me.tagsData = data;

            const tagsDataSource = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                identify(obj) {
                    return obj.title;
                },
                local: data,
            });
            tagsDataSource.initialize();

            function quickRepliesWithDefaults(q, sync) {
                if (q === '') {
                    sync(tagsDataSource.all());
                } else {
                    tagsDataSource.search(q, sync);
                }
            }

            const jqSelector = $(`input[contactid="tags_${contact.contactId}"]`);

            jqSelector.typeahead('destroy');

            jqSelector.typeahead(
                {
                    minLength: 0,
                    highlight: true,
                },
                {
                    name: 'contact-tags',
                    display: 'title',
                    source: quickRepliesWithDefaults,
                    updater(item) {
                        //item = selected item
                        //do your stuff.

                        //dont forget to return the item to reflect them into input
                        console.log(item);
                    },
                },
            );

            jqSelector.bind('typeahead:select', (ev, suggestion) => {
                console.log('selected');
                this.addTag(suggestion);

                setTimeout(() => {
                    jqSelector.typeahead('val', '');
                    $('.tt-input').blur();
                }, 100);
            });

            /*
                        jqSelector.tagsinput({
                            allowDuplicates: false,
                            itemValue: 'tagId',
                            itemText: 'tagName',
                            typeaheadjs: {
                                minLength: 0,
                                highlight: true,
                                name: "states",
                                displayKey: 'tagName',
                                source: quickRepliesWithDefaults
                            },
                            freeInput: false
                        });
                        `${contact.tags}`.split(',').forEach(v => {
                            data.filter(tag => tag.tagId == v.trim()).map(tag => {
                                jqSelector.tagsinput('add', tag, { preventPost: true });
                            });
                        })
                        //jqSelector.val(contact.tags);
                        jqSelector.on('itemAdded itemRemoved', function (event: any) {
                            // event.item: contains the item
                            let tagsValue = jqSelector.val();
                            contact.tags = tagsValue;
                        });
             */
            /* jqSelector.on('itemRemoved', function (event: any) {
                // event.item: contains the item
                let tagsValue = jqSelector.val();
                contact.tags = tagsValue;
            }); */
        });
    }

    addTag(suggestion: any) {
        const me = this;
        const existingTags = me.contact.tagObjects.filter(tagObj => tagObj.id == suggestion.id);
        if (existingTags.length == 0) {me.contact.tagObjects.push(suggestion);}
        setTimeout(() => {
            me.menuDynamicOffset = $('.tag-button-container').offset().left;
        }, 500);
    }

    removeTag(tagId) {
        const tagIndex = this.contact.tagObjects.findIndex(tag => tag.id == tagId);
        this.contact.tagObjects.splice(tagIndex, 1);
    }
}
