export class AgentInfoModel {
    public AgentId: string;
    public BusinessUnitId;
    public Custom1: string;
    public Custom2: string;
    public Custom3: string;
    public Custom4: string;
    public Custom5: string;
    public Email: string;
    public firstName: string;
    public InternalId: string;
    public IsActive: boolean;
    public IsSupervisor: string;
    public LastLogin: string;
    public LastModified: string;
    public lastName: string;
    public Location: string;
    public MiddleName: string;
    public ReportToFirstName: string;
    public ReportToId: string;
    public ReportToLastName: string;
    public ReportToMiddleName: string;
    public TeamId: string;
    public TeamName: string;
    public UserName: string;
}
