import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ScheduledCallbacksComponent} from './scheduled-callbacks.component';
import {CreateScheduledCallbacksFormComponent} from './create-schedule-callback-form/callbacks.form.component';

import {AuthGuard} from '../../core/_guards/auth.guard';
import {AppDefaultLayoutComponent} from 'src/app/layout/app-default-layout/app-default-layout.component';

const routes: Routes = [
    {
        path: '',
        component: AppDefaultLayoutComponent,
        children: [
            {
                path: 'callbacks',
                component: ScheduledCallbacksComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'callbacks/new',
                component: CreateScheduledCallbacksFormComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'callbacks/:id',
                component: CreateScheduledCallbacksFormComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ScheduledCallbacksRoutingModule {}
