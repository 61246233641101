import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {IcAgentSessionService} from 'src/app/sdk/incontact/services/agent-sessions/agent-session-events.service';
import {AuthenticationService} from '../services/authentication.service';

@Component({
    selector: 'app-login-step-two',
    templateUrl: './login-step-two.component.html',
    styleUrls: ['./login-step-two.component.scss'],
})
export class LoginStepTwoComponent implements OnInit, AfterViewInit {
    type = null;
    constructor(
        public translateSrv: TranslateService,
        public authenticationSrv: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        public icAgentSessionSrv: IcAgentSessionService,
    ) {}

    ngOnInit() {
        this.type = this.activatedRoute.snapshot.queryParams.type;
        if (this.type === 'code') {
        }
    }

    ngAfterViewInit() {}

    Login() {
        this.authenticationSrv.selectCRMApplication(this.authenticationSrv.agentPublicProfile);
    }
}
