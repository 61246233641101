import {Component, OnInit, Input, HostListener, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

import {AddressBookEntriesModel} from '../../../core/_models/address-book-entries.model';
import {Agent} from '../../../core/_models/agent.model';
import {AddressBookService} from '../address-book.service';
import {HotKeyPressSerivce} from '../../../core/_services/hot-key-press.service';
import {IcAgentSessionService} from '../../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {CallContactService} from '../../../core/_services/call-contact.service';
import {EmailContactService} from '../../../core/_services/email-contact.service';

@Component({
    selector: 'app-address-book-contacts',
    templateUrl: './address-book-contacts.component.html',
    styleUrls: ['./address-book-contacts.component.css'],
})
export class AddressBookContactsComponent implements OnInit {
    bookcontacts: AddressBookEntriesModel[];
    private _addressBookId: number;

    agent: any = {};

    @Input()
    keyword: string;

    @Input()
    type: string;

    @Input()
    set addressBookId(id: number) {
        this._addressBookId = id;

        this.loadAddressBookContacts();
    }

    get addressBookId() {
        return this._addressBookId;
    }

    constructor(
        private addressBookService: AddressBookService,
        private router: Router,
        private hotKeyPressSerivce: HotKeyPressSerivce,
        private agentSessionService: IcAgentSessionService,
        private emailContactService: EmailContactService,
        private callContactService: CallContactService,
    ) {
        this.agent = {};
        this.agent.outboundPhoneSkills = [];
        this.agent.outboundEmailSkills = [];

        this.agentSessionService.agentOutboundSkills.subscribe(skills => {
            this.agent.outboundPhoneSkills = skills.filter(
                e => e.isActive === 'True' && e.mediaTypeId === '4' && e.isNaturalCalling === 'False',
            );
            this.agent.outboundEmailSkills = skills.filter(
                e => e.isActive === 'True' && e.mediaTypeId === '1' && e.isNaturalCalling === 'False',
            );
        });
    }

    ngOnInit() {}

    loadAddressBookContacts() {
        this.bookcontacts = [];

        if (this.addressBookId === undefined) {return;}

        this.addressBookService.getAddressBookContacts(this.addressBookId, this.type).subscribe(res => {
            this.bookcontacts = res;

            this.bookcontacts.forEach(element => {
                if (element.mobile === undefined) {element.mobile = '';}
                if (element.phone === undefined) {element.phone = '';}
                if (element.email === undefined) {element.email = '';}
            });
        });
    }

    returnCall(phoneNumber: string) {
        if (phoneNumber !== '') {this.router.navigate(['/dialpad', {phoneNumber}]);}
    }

    dialPhone(number: string, skillId: string) {
        this.callContactService.dialPhone(number, skillId);
    }

    createNewOutboundEmailContact(skillId: number, toAddress: string) {
        this.emailContactService.newOutboundEmail(`${skillId}`, toAddress).subscribe(r => {});
    }

    @ViewChild('listItem') listItem: ElementRef;
    @HostListener('window:keydown', ['$event'])
    hotkeys(event: KeyboardEvent) {
        const key = event.keyCode;
        if (this.hotKeyPressSerivce.altPress(event)) {
            switch (key) {
                case 76: //L
                    $(this.listItem.nativeElement).focus();
                    event.preventDefault();
                    break;
            }
        }
    }
}
