<div>
    <div><i class="fas fa-search"></i></div>
    <input
        type="text"
        data-role="quickreplies"
        autocomplete="off"
        placeholder="Quick Replies"
        attr.contactid="qr_{{ contact.contactId }}"
    />
    <div>
        <a (click)="focusInput()"><i class="caret"></i></a>
    </div>
    <!-- <button *ngIf="false" (click)="openmodal()" type="button">Open Dialog</button>
<div *ngIf="selectedQuickReply">{{selectedQuickReply.content}}</div> -->
    <!-- <modal #modal>
        <modal-header [show-close]="false">
            <h4 class="modal-title"></h4>
        </modal-header>
        <modal-body>
            <iframe
                attr.qrbody="qr_body_{{contact.contactId}}"
                style="border: 0px; min-height: 300px; max-height: 350px; width: 100%;"
            ></iframe>
        </modal-body>
        <modal-footer>
            <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="insert()">Insert</button>
        </modal-footer>
    </modal> -->
    <ng-template #quickRepliesDialog>
        <div [id]="'quick-replies-modal-' + contact.contactId">
            <div class="modal-header"></div>
            <div class="modal-body" #quickRepliesDialogBody>
                <!-- <pre>{{selectedQuickReply.content}}</pre> -->
                <!-- <iframe
                    attr.qrbody="qr_body_{{contact.contactId}}"
                    style="border: 0px; min-height: 300px; max-height: 350px; width: 100%"
                ></iframe> -->
                <!-- <pre>{{ selectedQuickReply | json }}</pre> -->
                <span
                    [innerHtml]="selectedQuickReply.copiedContent | safeHtml"
                    style="border: 0px; min-height: 300px; max-height: 350px; width: 100%"
                ></span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="quickRepliesDialogRef.hide()">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="insert()">Insert</button>
            </div>
        </div>
    </ng-template>
</div>
