<ng-container *ngIf="emailContactService.email">
    <div class="">
        <div class="col-xs-12">
            <ul class="nav nav-pills pull-left">
                <li>
                    <i class="fa fa-envelope-o fa-lg" aria-hidden="true"></i>&nbsp; {{ 'Email.Title' | translate }}
                    {{ emailContactService.email.startTimeElapsed }}
                </li>
            </ul>
            <app-contact-actions
                displayStyle="small"
                [contactVM]="emailContactService.email"
                slot="detail_header"
                class="pull-right"
                [showPredisposition]="true"
                dispositionMenuAlignment="right"
            >
            </app-contact-actions>
            <app-contact-actions displayStyle="small" [contactVM]="emailContactService.email" slot="footer" class="pull-right"> </app-contact-actions>
            <app-contact-actions
                displayStyle="small"
                [contactVM]="emailContactService.email"
                tagClasses="contact-detail-contact-elevations"
                slot="elevations"
                class="pull-right"
                [dropdownMargin]="-135"
            >
            </app-contact-actions>
        </div>
        <div style="margin-left: 5px" *ngIf="emailContactService.email.OmniGroupRelatedContacts.length > 0">
            <ul style="display: flex; list-style-type: none; padding: 5px; margin: 0px">
                <li *ngFor="let groupContact of emailContactService.email.OmniGroupRelatedContacts">
                    <app-contact-item [contactVM]="groupContact" mode="related"></app-contact-item>
                </li>
            </ul>
        </div>
    </div>

    <ng-container *ngIf="!emailContactService.email.contact.isDispositionVisible()">
        <div *ngIf="!emailContactService.email.reply" class="">
            <!-- <input type="emailContactService.email" class="form-control" id="inputEmailFrom" value="{{emailContactService.email.fromAddress}}" disabled> -->
            <div class="col-md-12">
                <strong>{{ 'Email.Labels.From' | translate }}: </strong>{{ emailContactService.email.contact.fromAddress }}<br />
                <strong>{{ 'Email.Labels.To' | translate }}: </strong>{{ emailContactService.email.contact.toAddress }}<br />
                <p>
                    <strong>{{ 'Email.Labels.Subject' | translate }}: </strong>{{ emailContactService.email.contact.subject }}
                </p>
                <strong>{{ 'Email.Labels.Date' | translate }}: </strong>{{ emailContactService.email.contact.sentDate }}<br />
            </div>
        </div>
        <div *ngIf="emailContactService.email.reply" class="">
            <div class="col-md-12">
                <input type="text" *ngIf="false" class="form-control" [disabled]="true" [value]="emailContactService.email.reply.From" placeholder="From" />
                <input type="text" class="form-control" [(ngModel)]="emailContactService.email.reply.toAddress" placeholder="To" />
                <input type="text" class="form-control" [(ngModel)]="emailContactService.email.reply.subject" placeholder="Subject" />
                <a href="javascript:;" *ngIf="!emailContactService.email.reply.showCC" (click)="emailContactService.email.showCC()">CC </a>
                <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="emailContactService.email.reply.ccAddress"
                    name="CC"
                    #CC="ngModel"
                    placeholder="{{ 'Email.Labels.CC' | translate }}"
                    *ngIf="emailContactService.email.reply.showCC"
                />

                <a href="javascript:;" *ngIf="!emailContactService.email.reply.showBCC" (click)="emailContactService.email.showBCC()">BCC </a>
                <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="emailContactService.email.reply.bccAddress"
                    name="BCC"
                    #CC="ngModel"
                    placeholder="{{ 'Email.Labels.BCC' | translate }}"
                    *ngIf="emailContactService.email.reply.showBCC"
                />
                <hr style="margin-top: 5px; margin-bottom: 5px" />
                <app-contact-quick-replies
                    class="form-control p-pt-1"
                    [contact]="emailContactService.email.contact"
                    style="margin-bottom: 7px"
                    (onQuickReplyInserting)="handleOnQuickReplyInserting($event)"
                ></app-contact-quick-replies>
            </div>
        </div>

        <hr *ngIf="!emailContactService.email.reply" style="margin-top: 5px; margin-bottom: 5px" />

        <div style="display: flex; flex: 1; flex-direction: column; overflow-y: auto">
            <div class="col-md-12" style="flex: 1" *ngIf="emailContactService.email.reply">
                <textarea
                    name="editordata"
                    rows="14"
                    id="email_body"
                    class="form-control"
                    style="max-height: 100%; min-height: 100%; overflow-y: auto; width: 100%"
                    [(ngModel)]="emailContactService.email.reply.bodyHTML"
                >
                </textarea>
            </div>
            <!--
      <div *ngIf="!emailContactService.email.reply" style="flex:1" class="col-lg-12" style="overflow-y: auto;"
        [innerHtml]="emailContactService.email.decodeBodyHtml()">

      </div>
    -->

            <!-- <iframe *ngIf="!emailContactService.email.reply" class="col-lg-12" style="flex:1; padding: 0px; margin: 0px;" #emailDisplay
        [srcdoc]="emailContactService.email.decodeBodyHtml()">

      </iframe> -->

            <iframe
                *ngIf="!emailContactService.email.reply"
                class="col-lg-12"
                style="flex: 1; padding: 0px; margin: 0px"
                #emailDisplay
                class="emailDisplay_readonly"
                (load)="onEmailDisplayReady('iframe is loaded')"
                srcdoc="<html><html>"
            >
            </iframe>

            <div class="attachments-box" *ngIf="emailAttachments.length > 0">
                <h4>Attachment:</h4>
                <ul>
                    <li *ngFor="let attachment of emailAttachments" [title]="'Download ' + attachment.name" (click)="DownloadAttachment(attachment)">
                        {{ attachment.name }}
                        <i class="fas fa-file-download"></i>
                    </li>
                </ul>
            </div>

            <ul class="nav" style="display: flex">
                <li>
                    <contact-ticket-box [contactViewModel]="emailContactService.email"> </contact-ticket-box>
                </li>
                <li style="flex: 1"></li>
                <li style="display: flex; align-items: center; padding: 5px">
                    <app-contact-actions displayStyle="small" [contactVM]="emailContactService.email" slot="detail_footer" class="pull-right">
                    </app-contact-actions>
                </li>
            </ul>
        </div>
    </ng-container>

    <div class="">
        <active-contact-disposition
            *ngIf="emailContactService.email.contact.isDispositionVisible()"
            [contact]="emailContactService.email.contact"
            [contactService]="emailContactService"
        ></active-contact-disposition>
    </div>
</ng-container>
