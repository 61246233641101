import {of as observableOf, throwError as observableThrowError, Observable, BehaviorSubject, Subject} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Injectable, Inject, Optional} from '@angular/core';
import {CrmIntegrationService} from './crmIntegration.service';
import {ZendeskCrmIntegrationService} from './zendeskCrmIntegration.service';
import {DynamicsCrmIntegrationService} from './dynamicsCrmIntegration.service';
import {integrationServicesContainer} from './integrationServicesContainer.service';
import {ConfigService} from '../../core/_services/config.service';
import {InContactAPIService} from '../InContact/incontact-api.service';
import {IncomingContactService} from '../_services/incoming-contact.service';
import {CallContactService} from '../_services/call-contact.service';
import {ApplicationContextService} from '../_services/application-context.service';
import {BaseApi} from 'src/app/sdk/core';
import {HttpHeaders} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {AgentPublicProfileViewModel, CrmApplicationModel} from 'src/app/sdk/fac-crm/models';
import {CRM_APPLICATION_TYPES, inIframe} from 'src/app/global';

@Injectable()
export class integrationManagerService {
    apiBaseUrl = '';
    //"https://uacadmin.frontlineservicesinc.com/api"
    //public apiBaseUrl = "http://localhost:3030/api";
    agentProfile = new Subject();
    selectedCRMFromDialog: CrmApplicationModel = new CrmApplicationModel();
    selectedCRMSubject: BehaviorSubject<any>;
    crmIntegration: CrmIntegrationService = new CrmIntegrationService(
        null,
        this.servicesContainer,
        this.inContactAPIService,
        this.incomingContactService,
        this.callContactService,
        this.localStorageSrv,
    );
    crmApplications: Array<CrmApplicationModel> = [];
    public errorMsg = new BehaviorSubject({status: '', statusText: ''});
    constructor(
        protected baseApi: BaseApi,
        @Inject(integrationServicesContainer) protected servicesContainer: integrationServicesContainer,
        protected configService: ConfigService,
        protected inContactAPIService: InContactAPIService,
        protected incomingContactService: IncomingContactService,
        protected callContactService: CallContactService,
        protected appContext: ApplicationContextService,
        protected localStorageSrv: LocalStorageService,
    ) {
        // configService.configuations.subscribe(config => {
        //     this.apiBaseUrl = config.apiBaseUrl;
        // });
        this.apiBaseUrl = this.configService.ApiUrlUrl;
        this.selectedCRMSubject = new BehaviorSubject<any>(null);
    }

    tokenTimeout;

    init(email: string) {
        // let me = this;
        // let _method: string = 'GET';
        email = email.toLowerCase();
        const _url: string = this.configService.ApiUrlUrl + '/Agents/publicprofile';

        // let headers: Headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // let _routeParams: any = {};
        // let _postBody: any = {};
        // let _urlParams: any = {};
        // if (email) _urlParams.email = email;
        //if (apiKey) _urlParams.apiKey = apiKey;
        //if (options) _urlParams.options = options;

        return this.baseApi.GET_Request<AgentPublicProfileViewModel>(_url, null, {email});

        // let request: Request = new Request({
        //     headers: headers,
        //     method: _method,
        //     url: _url,
        //     body: _postBody ? JSON.stringify(_postBody) : undefined,
        // });
        // let observeable = new BehaviorSubject<any>(null);
        // return this.http
        //     .request(request).pipe(
        //     catchError(e => {
        //         //this.agentProfile.error(e);
        //         return observableThrowError(e);
        //         //return Observable.of(e);
        //     }),
        //     map((response: Response) => response.json()),);
    }

    getCRMToken(id: string): Observable<any> {
        // let me = this;
        // let _method: string = 'GET';
        const _url: string = this.apiBaseUrl + '/Agents/dynamicstoken';

        // let headers: Headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // let _routeParams: any = {};
        // let _postBody: any = {};
        // let _urlParams: any = {};
        // if (id) _urlParams.id = id;

        // let request: Request = new Request({
        //     headers: headers,
        //     method: _method,
        //     url: _url,
        //     body: _postBody ? JSON.stringify(_postBody) : undefined,
        // });

        return this.baseApi.GET_Request<any>(_url, null, {id});

        // return this.http.request(request).pipe(
        //     catchError(e => {
        //         return observableOf(e);
        //     }),
        //     map((response: Response) => response.json()),
        // );
    }

    sendReport(description: string, logMessages: any): Observable<any> {
        // let me = this;
        // let _method: string = 'POST';
        const _url: string = this.apiBaseUrl + '/Agents/sendreport';

        const data = {description, logMessages};

        // let headers: Headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // let _routeParams: any = {};
        const _postBody: any = data;
        // let _urlParams: any = {};
        // if (id) _urlParams.id = id;

        return this.baseApi.POST_Request<any>(_url, null, null, _postBody);

        // let request: Request = new Request({
        //     headers: headers,
        //     method: _method,
        //     url: _url,
        //     body: _postBody,
        // });

        // return this.http.request(request);
    }

    async loadDynamicsLibrary() {
        return new Promise(resolve => {
            if (inIframe() && !document.getElementById('dynamics-msdyn_ciLibrary')) {
                const el = document.createElement('script');

                el.id = 'dynamics-msdyn_ciLibrary';
                el.src = 'https://frontlinesandbox.crm.dynamics.com/webresources/Widget/msdyn_ciLibrary.js';
                el.type = 'text/javascript';

                el.setAttribute('data-crmurl', 'https://frontlinesandbox.crm.dynamics.com');
                el.setAttribute('data-cifid', 'CIFMainLibrary');

                el.addEventListener('load', function(event) {
                    resolve('loaded');
                });

                document.head.append(el);
            } else {
                resolve(null);
            }
        });
    }

    async loadKustomerLibrary() {
        return new Promise(resolve => {
            const id = 'kustomer-library';
            if (inIframe() && !document.getElementById(id)) {
                const el = document.createElement('script');

                el.id = id;
                el.src = 'https://cdn.kustomerapp.com/card-js/latest/kustomer-card.min.js';
                el.type = 'text/javascript';

                el.addEventListener('load', function(event) {
                    resolve('loaded');
                });

                document.head.append(el);
            } else {
                resolve(null);
            }
        });
    }

    async initSelectedCRM(selectedCrm: CrmApplicationModel) {
        this.appContext.setCrmSettings(selectedCrm);

        if (selectedCrm) {
            switch (selectedCrm.applicationType) {
                case CRM_APPLICATION_TYPES.ZENDESK:
                    this.crmIntegration = new ZendeskCrmIntegrationService(
                        selectedCrm,
                        this.servicesContainer,
                        this.inContactAPIService,
                        this.incomingContactService,
                        this.callContactService,
                        this.localStorageSrv,
                    );

                    this.appContext.initZendeskCrmService();

                    this.selectedCRMSubject.next(this.crmIntegration);
                    break;
                case CRM_APPLICATION_TYPES.DYNAMICS:
                    await this.loadDynamicsLibrary();
                    selectedCrm.apiBaseUrl = this.apiBaseUrl;

                    this.crmIntegration = new DynamicsCrmIntegrationService(
                        selectedCrm,
                        this.servicesContainer,
                        this.inContactAPIService,
                        this.incomingContactService,
                        this.callContactService,
                        this.baseApi,
                        this.localStorageSrv,
                    );

                    this.appContext.initDynamicsCrmService();

                    this.selectedCRMSubject.next(this.crmIntegration);

                    break;
                case CRM_APPLICATION_TYPES.KUSTOMER:
                    // await this.loadKustomerLibrary();

                    this.crmIntegration = new CrmIntegrationService(
                        selectedCrm,
                        this.servicesContainer,
                        this.inContactAPIService,
                        this.incomingContactService,
                        this.callContactService,
                        this.localStorageSrv,
                    );

                    this.appContext.initKustomerCrmService();

                    this.selectedCRMSubject.next(this.crmIntegration);

                    break;
                default:
                    this.crmIntegration = new CrmIntegrationService(
                        selectedCrm,
                        this.servicesContainer,
                        this.inContactAPIService,
                        this.incomingContactService,
                        this.callContactService,
                        this.localStorageSrv,
                    );

                    this.appContext.initCrmService();

                    this.selectedCRMSubject.next(this.crmIntegration);
                    break;
            }
        }
    }

    getTokenRepeatedly() {
        if (this.tokenTimeout) {
            clearTimeout(this.tokenTimeout);
        }
        this.tokenTimeout = setTimeout(() => {
            this.getCRMToken(this.crmIntegration.selectedCrm.id).subscribe(token => {
                this.crmIntegration.selectedCrm.token = token.access_token;
                this.crmIntegration.selectedCrm.refresh_token = token.refresh_token;

                console.log(new Date() + ' - Dynamics Token in Integration Manager: ' + this.crmIntegration.selectedCrm.token);

                if (!token || token.error) {
                    this.selectedCRMSubject.next({
                        status: '500',
                        statusText: 'Unable to login to Dynamics CRM. Please check Account Settings',
                    });
                }
            });
            this.getTokenRepeatedly();
        }, (this.crmIntegration.selectedCrm.expires_in - 600) * 1000);
    }
}
