<div class="panel panel-default panel-top pb-10">
    <div class="panel-heading">
        <div class="d-inline-block">
            <h4 class="my-0">Schedule Preview</h4>
        </div>

        <div class="pull-right">
            <i (click)="cancel()" class="fa fa-times fa-lg" title="{{ 'Chat.EndChat' }}" aria-hidden="true"></i>
        </div>
    </div>
    <form #callHistoryForm="ngForm" (ngSubmit)="saveSchedule()" class="form-horizontal">
        <div class="page-heading">
            <ul class="nav nav-pills">
                <li>
                    <h4>
                        <i
                            class="fa fa-tty fa-lg"
                            aria-hidden="true"
                            title="{{ 'App_Component.Footer.CallHistory' | translate }}"
                        >
                        </i
                        >&nbsp;{{ 'App_Component.Footer.Callbacks' | translate }}
                    </h4>
                </li>
                <li style="flex-grow: 1"></li>
                <li>
                    <button
                        class="btn btn-xs btn-circle btn-default"
                        title="Save"
                        style="margin: 2px; margin-top: 0px"
                        type="submit"
                    >
                        <i class="fa fa-save"></i>
                    </button>
                </li>
                <li *ngIf="this.model.callbackId && this.deleteCommitmentId > 1">
                    <button class="btn btn-xs btn-danger btn-circle" title="Remove" type="button" (click)="askRemove()">
                        <i class="fa fa-window-close"></i>
                    </button>
                </li>
            </ul>
        </div>

        <!-- <pre>{{ model | json }}</pre> -->

        <div class="page-content" style="top: 70px">
            <div class="px-15">
                <div class="row py-15">
                    <div class="col-xs-12">
                        <ul class="nav nav-pills">
                            <li>
                                <h5>&nbsp;Scheduled For&nbsp;</h5>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    class="btn"
                                    (click)="setScheduleType('Me')"
                                    [ngClass]="model.scheduleType == 'Me' ? 'btn-primary' : 'btn-default'"
                                >
                                    Me
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    class="btn"
                                    (click)="setScheduleType('Skill')"
                                    [ngClass]="model.scheduleType == 'Skill' ? 'btn-primary' : 'btn-default'"
                                >
                                    Skill
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="row py-5" *ngIf="model.scheduleType === 'Skill'">
              <div class="col-xs-12">
                  <select
                      class="form-control"
                      [(ngModel)]="model.skillId"
                      name="skillId"
                      id="skillId"
                      placeholder="Skill"
                      required
                  >
                      <option *ngFor="let skill of outboundSkills" value="{{ skill.skillId }}">
                          {{ skill.skillName }}
                      </option>
                  </select>
              </div>
          </div> -->
                <div class="row py-5">
                    <div class="col-xs-6">
                        <input [(ngModel)]="model.date" name="date" class="form-control" type="date" required />
                    </div>

                    <div class="col-xs-6 nopadding pull-right">
                        <ul class="nav nav-pills">
                            <li>
                                <input
                                    type="number"
                                    max="12"
                                    min="1"
                                    class="time-bit form-control"
                                    name="hour"
                                    [(ngModel)]="model.hour"
                                    step="1"
                                />
                            </li>
                            <li>
                                <input
                                    type="number"
                                    value="0"
                                    step="1"
                                    class="time-bit form-control"
                                    name="minute"
                                    [(ngModel)]="model.minute"
                                    max="59"
                                    min="0"
                                />
                            </li>
                            <li>
                                <select class="form-control" [(ngModel)]="model.ampm" name="ampm" id="ampm" required>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                                <!-- <div class="dropdown">
                              <button
                                  class="btn btn-default dropdown-toggle"
                                  type="button"
                                  id="dropdownMenu1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                  style="padding: 3px; margin: 2px"
                              >
                                  {{ model.ampm }}
                                  <span class="caret"></span>
                              </button>
                              <ul class="dropdown-menu form-control" aria-labelledby="dropdownMenu1">
                                  <li><a (click)="setAMPM('AM')">AM</a></li>
                                  <li><a (click)="setAMPM('PM')">PM</a></li>
                              </ul>
                          </div> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row py-5">
                    <div class="col-xs-12">
                        <label for="">Timezone</label>
                        <select
                            [(ngModel)]="displayTimezone"
                            class="form-control"
                            name="timezone"
                            id=""
                            placeholder="Time Zone"
                            (change)="onTimezoneSelected($event)"
                        >
                            <option *ngFor="let timezone of timezones" [value]="timezone.standardName">
                                {{ timezone.displayName }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <label for="">Notes: </label>
                        <textarea
                            [(ngModel)]="model.notes"
                            class="form-control"
                            name="notes"
                            id="notes"
                            rows="5"
                        ></textarea>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-xs-12" style="display: flex; justify-content: space-between; margin-top: 10px">
                        <button class="btn btn-default" (click)="cancel()">Cancel</button>
                        <button class="btn btn-success" type="submit">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!-- <modal #removeDialog>
  <form #deletCommitmentForm="ngForm" (submit)="removeCommitment($event)">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Remove Commitment?</h4>
      </div>
      <div class="modal-body">
          <h3>Are you sure you want to remove this commitment?</h3>
          <div style="display: flex;">
              <textarea
                  style="flex: 1;"
                  name="notes"
                  [(ngModel)]="deleteCommitmentNotes"
                  [attr.required]="deleteCommitmentId == 2 ? '' : null"
                  placeholder="Notes"
              ></textarea>
          </div>
      </div>
      <div class="modal-footer">
          <button
              class="btn btn-danger"
              [disabled]="deleteCommitmentId == 2 && (!deleteCommitmentNotes || deleteCommitmentNotes.length == 0)"
              type="submit"
          >
              Remove
          </button>
          <button class="btn btn-default" (click)="removeDialog.close()">Cancel</button>
      </div>
  </form>
</modal> -->

    <ng-template #removeDialog>
        <div class="modal-header">
            <button type="button" class="close" (click)="removeDialogRef.close()" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Remove Commitment?</h4>
        </div>
        <div class="modal-body">
            <form #deletCommitmentForm="ngForm" (submit)="removeCommitment($event)">
                <!-- <div class="modal-body"> -->
                <h3>Are you sure you want to remove this commitment?</h3>
                <div style="display: flex">
                    <textarea
                        style="flex: 1"
                        name="notes"
                        [(ngModel)]="deleteCommitmentNotes"
                        [attr.required]="deleteCommitmentId == 2 ? '' : null"
                        placeholder="Notes"
                    ></textarea>
                </div>
                <!-- </div> -->
                <div style="display: flex; justify-content: space-between; margin-top: 10px">
                    <button
                        class="btn btn-danger"
                        [disabled]="
                            deleteCommitmentId == 2 && (!deleteCommitmentNotes || deleteCommitmentNotes.length == 0)
                        "
                        type="submit"
                    >
                        Remove
                    </button>
                    <button
                        type="button"
                        class="btn btn-default"
                        data-dismiss="modal"
                        (click)="removeDialogRef.close()"
                    >
                        Reject
                    </button>
                    <!-- <button type="button" class="btn btn-primary" (click)="accept()">Accept</button> -->
                </div>
            </form>
        </div>
    </ng-template>
</div>
