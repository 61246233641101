import {Component, Input, OnInit, OnDestroy, ViewChild, Output, EventEmitter, TemplateRef, HostBinding, ElementRef} from '@angular/core';
import {ContactViewBaseModel, ContactActionModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';

import {ContactBase} from 'src/app/core/_models/contact-base';
import {ApplicationContextService} from 'src/app/core/_services/application-context.service';
import {ModalComponent, DialogRef, overlayConfigFactory} from 'ngx-modialog-7';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
// import {ModalComponent} from 'ng2-bs3-modal/ng2-bs3-modal';
import {Overlay} from 'ngx-modialog-7';
import {Modal, BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';

declare let $: any;
declare let Bloodhound;

@Component({
    selector: 'app-contact-quick-replies',
    templateUrl: './contact-quick-replies.component.html',
    styleUrls: ['./contact-quick-replies.component.scss'],
})
export class ContactQuickRepliesComponent implements OnInit, OnDestroy {
    @Input()
    contact: ContactBase;
    @Output() onQuickReplyInserting = new EventEmitter();

    @ViewChild('quickRepliesDialogBody') public quickRepliesDialogBodyElem: ElementRef;
    @ViewChild('quickRepliesDialog') public quickRepliesDialog: TemplateRef<any>;
    quickRepliesDialogRef: BsModalRef;

    selectedQuickReply: any = null;

    private iframeSelectorString = '';

    constructor(private appContext: ApplicationContextService, private dialogSrv: DialogService, public modalSrv: BsModalService) {}

    ngOnInit(): void {
        const me = this;
        const contact = this.contact;
        let quickRepliesData = [];
        this.appContext.inContactAPIService.getQuickReplies().subscribe(qr => {
            this.iframeSelectorString = `iframe[qrbody="qr_body_${contact.contactId}"`;
            quickRepliesData = qr
                .filter(item => item.skills.filter(x => x.skillId == contact.skill).length > 0)
                .map(item => ({id: item.quickReplyId, title: item.title, content: item.content, keyWords: item.keyWords}));

            const quickReplieDataSource = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title', 'content'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                local: quickRepliesData,
            });

            quickReplieDataSource.initialize();

            const jqSelector = $(`input[contactid="qr_${contact.contactId}"]`);

            function quickRepliesWithDefaults(q, sync) {
                if (q === '') {
                    sync(quickReplieDataSource.all());
                } else {
                    quickReplieDataSource.search(q, sync);
                }
            }

            jqSelector.typeahead(
                {
                    minLength: 0,
                    highlight: true,
                },
                {
                    name: 'quick-replies',
                    display: 'title',
                    source: quickRepliesWithDefaults,
                },
            );

            setTimeout(() => {
                $(`input[contactid="qr_${me.contact.contactId}"] ~  + .tt-menu`).css('z-index', '1000');
            }, 500);

            if (quickRepliesData.length == 0) {
                jqSelector.prop('readonly', true);
                jqSelector.css('opacity', 0.5);
            }

            jqSelector.bind('typeahead:select', (ev, suggestion) => {
                console.log(suggestion);
                me.selectedQuickReply = null;

                me.selectedQuickReply = JSON.parse(JSON.stringify(suggestion));

                me.selectedQuickReply.copiedContent = me.selectedQuickReply.content;

                me.selectedQuickReply.copiedContent = me.replaceParamsWithInputs(me.selectedQuickReply.content);

                // $(me.iframeSelectorString).contents().find('body').html(val);
                setTimeout(() => {
                    jqSelector.typeahead('val', '');
                    // this.removeDialogRef = me.dialogSrv.openModal(this.removeDialog);
                    this.quickRepliesDialogRef = this.modalSrv.show(this.quickRepliesDialog);
                }, 100);
            });
        });
    }
    replaceParamsWithInputs(content: string) {
        const regex = /\{([^}]+)\}/g;
        const val = content.replace(regex, function (a, b) {
            return `<input type="text" id="param_${b}" class="input_param" param_name="${b}" >`;
        });
        return val;
    }

    replaceParamInputsWithValues() {
        // let paramInputs = $(this.iframeSelectorString).contents().find('.input_param');
        const paramInputs = $('#quick-replies-modal-' + this.contact.contactId).find('.input_param');
        /* let bodyStr = $(this.iframeSelectorString).contents().find('body').html();

        let bodyCopy = $(bodyStr);
        */
        const qrCopy = Object.assign({}, this.selectedQuickReply);

        paramInputs.each((a, b) => {
            const inp = $(b);
            const regex = new RegExp(`{${inp.attr('param_name')}}`, 'g');
            qrCopy.content = qrCopy.content.replace(regex, inp.val());
            //bodyCopy.find(`input[param_name="${inp.attr('param_name')}"]`).replaceWith(`<span>${inp.val()}</span>`);
        });

        //return bodyCopy.html();
        return qrCopy;
    }

    ngOnDestroy(): void {
        //throw new Error("Method not implemented.");
        console.log('Qr Dialog destroyed');
        // this.modal.close();
        // this.modalRef.hide();
        if (this.quickRepliesDialogRef) {
            this.quickRepliesDialogRef.hide();
        }
    }

    insert() {
        const replyOutput = this.replaceParamInputsWithValues();
        this.onQuickReplyInserting.emit(replyOutput);
        //this.onQuickReplyInserting.emit(this.selectedQuickReply);
        // this.modal.close();
        // this.modalRef.hide();
        this.quickRepliesDialogRef.hide();
    }

    focusInput() {
        $(`input[contactid="qr_${this.contact.contactId}"]`).focus();
    }
}
