import {NgModule} from '@angular/core';

import {SupportComponent} from './support.component';
import {SupportRoutingModule} from './support.routing';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [SupportRoutingModule, SharedModule],
    exports: [],
    declarations: [SupportComponent],
    providers: [],
})
export class SupportModule {}
