import {DynamicsActivityPartyModel} from './dynamics-activity-party.model';

export class DynamicsContactModel {
    // @odata.etag: "W/\"33341646\""
    public accountrolecode: null;
    // public address1_addressid: "c08d651c-a3b9-4483-aa03-9fda00b04757"
    // public address1_addresstypecode: null
    // public address1_city: null
    // public address1_composite: null
    // public address1_country: null
    // public address1_county: null
    // public address1_fax: null
    // public address1_freighttermscode: null
    // public address1_latitude: null
    // public address1_line1: null
    // public address1_line2: null
    // public address1_line3: null
    // public address1_longitude: null
    // public address1_name: null
    // public address1_postalcode: null
    // public address1_postofficebox: null
    // public address1_primarycontactname: null
    // public address1_shippingmethodcode: null
    // public address1_stateorprovince: null
    // public address1_telephone1: null
    // public address1_telephone2: null
    // public address1_telephone3: null
    // public address1_upszone: null
    // public address1_utcoffset: null
    // public address2_addressid: "63c5b245-c5b2-4c53-b9ed-c8081bceec0c"
    // public address2_addresstypecode: 1
    // public address2_addresstypecode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // public address2_city: null
    // public address2_composite: null
    // public address2_country: null
    // public address2_county: null
    // public address2_fax: null
    // public address2_freighttermscode: 1
    // public address2_freighttermscode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // public address2_latitude: null
    // public address2_line1: null
    // public address2_line2: null
    // public address2_line3: null
    // public address2_longitude: null
    // public address2_name: null
    // public address2_postalcode: null
    // public address2_postofficebox: null
    // public address2_primarycontactname: null
    // public address2_shippingmethodcode: 1
    // public address2_shippingmethodcode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // public address2_stateorprovince: null
    // public address2_telephone1: null
    // public address2_telephone2: null
    // public address2_telephone3: null
    // public address2_upszone: null
    // public address2_utcoffset: null
    // public address3_addressid: "10d3f971-96d6-49c2-a20e-3fe0aa14778f"
    // public address3_addresstypecode: null
    // public address3_city: null
    // public address3_composite: null
    // public address3_country: null
    // public address3_county: null
    // public address3_fax: null
    // public address3_freighttermscode: null
    // public address3_latitude: null
    // public address3_line1: null
    // public address3_line2: null
    // public address3_line3: null
    // public address3_longitude: null
    // public address3_name: null
    // public address3_postalcode: null
    // public address3_postofficebox: null
    // public address3_primarycontactname: null
    // public address3_shippingmethodcode: null
    // public address3_stateorprovince: null
    // public address3_telephone1: null
    // public address3_telephone2: null
    // public address3_telephone3: null
    // public address3_upszone: null
    // public address3_utcoffset: null
    // public adx_createdbyipaddress: null
    // public adx_createdbyusername: null
    // public adx_identity_accessfailedcount: null
    // public adx_identity_emailaddress1confirmed: false
    // public adx_identity_emailaddress1confirmed@OData.Community.Display.V1.FormattedValue: "No"
    // public adx_identity_lockoutenabled: false
    // public adx_identity_lockoutenabled@OData.Community.Display.V1.FormattedValue: "No"
    // public adx_identity_lockoutenddate: null
    // public adx_identity_logonenabled: false
    // public adx_identity_logonenabled@OData.Community.Display.V1.FormattedValue: "No"
    // public adx_identity_mobilephoneconfirmed: false
    // public adx_identity_mobilephoneconfirmed@OData.Community.Display.V1.FormattedValue: "No"
    // public adx_identity_passwordhash: null
    // public adx_identity_securitystamp: null
    // public adx_identity_twofactorenabled: false
    // public adx_identity_twofactorenabled@OData.Community.Display.V1.FormattedValue: "No"
    // public adx_identity_username: null
    // public adx_modifiedbyipaddress: null
    // public adx_modifiedbyusername: null
    // public adx_organizationname: null
    // public adx_profilealert: false
    // public adx_profilealert@OData.Community.Display.V1.FormattedValue: "No"
    // public adx_profilealertdate: null
    // public adx_profilealertinstructions: null
    // public adx_profileisanonymous: false
    // public adx_profileisanonymous@OData.Community.Display.V1.FormattedValue: "No"
    // public adx_profilelastactivity: null
    // public adx_profilemodifiedon: null
    // public adx_publicprofilecopy: null
    // public adx_timezone: null
    // public aging30: null
    // public aging30_base: null
    // public aging60: null
    // public aging60_base: null
    // public aging90: null
    // public aging90_base: null
    // public anniversary: null
    // public annualincome: null
    // public annualincome_base: null
    // public assistantname: null
    // public assistantphone: null
    // public birthdate: null
    // public business2: null
    // public businesscard: null
    // public businesscardattributes: null
    // public callback: null
    // public childrensnames: null
    // public company: null
    public contactid: string;
    // public createdon: "2021-08-03T11:52:57Z"
    // public createdon@OData.Community.Display.V1.FormattedValue: "8/3/2021 4:52 PM"
    // public creditlimit: null
    // public creditlimit_base: null
    // public creditonhold: false
    // public creditonhold@OData.Community.Display.V1.FormattedValue: "No"
    // public customersizecode: 1
    // public customersizecode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // public customertypecode: 1
    // public customertypecode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // public department: null
    // public description: null
    // public donotbulkemail: false
    // public donotbulkemail@OData.Community.Display.V1.FormattedValue: "Allow"
    // public donotbulkpostalmail: false
    // public donotbulkpostalmail@OData.Community.Display.V1.FormattedValue: "No"
    // public donotemail: false
    // public donotemail@OData.Community.Display.V1.FormattedValue: "Allow"
    // public donotfax: false
    // public donotfax@OData.Community.Display.V1.FormattedValue: "Allow"
    // public donotphone: false
    // public donotphone@OData.Community.Display.V1.FormattedValue: "Allow"
    // public donotpostalmail: false
    // public donotpostalmail@OData.Community.Display.V1.FormattedValue: "Allow"
    // public donotsendmm: false
    // public donotsendmm@OData.Community.Display.V1.FormattedValue: "Send"
    // public educationcode: 1
    // public educationcode@OData.Community.Display.V1.FormattedValue: "Default Value"
    public emailaddress1: string;
    // public emailaddress2: null
    // public emailaddress3: null
    // public employeeid: null
    // public entityimage: null
    // public entityimage_timestamp: null
    // public entityimage_url: null
    // public entityimageid: null
    // public exchangerate: 1
    // public exchangerate@OData.Community.Display.V1.FormattedValue: "1.0000000000"
    // public externaluseridentifier: null
    // public familystatuscode: null
    // public fax: null
    // public firstname: "Muhammad Ali"
    // public followemail: true
    // public followemail@OData.Community.Display.V1.FormattedValue: "Allow"
    // public ftpsiteurl: null
    public fullname: string;
    // public gendercode: null
    // public governmentid: null
    // public haschildrencode: 1
    // public haschildrencode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // public home2: null
    // public importsequencenumber: null
    // public isbackofficecustomer: false
    // public isbackofficecustomer@OData.Community.Display.V1.FormattedValue: "No"
    // public jobtitle: null
    // public lastname: "Shahzad"
    // public lastonholdtime: null
    // public lastusedincampaign: null
    // public leadsourcecode: 1
    // public leadsourcecode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // public managername: null
    // public managerphone: null
    // public marketingonly: false
    // public marketingonly@OData.Community.Display.V1.FormattedValue: "No"
    // public merged: false
    // public merged@OData.Community.Display.V1.FormattedValue: "No"
    // public middlename: null
    // public mobilephone: "01114369351959087300011"
    // public modifiedon: "2021-08-03T11:59:40Z"
    // public modifiedon@OData.Community.Display.V1.FormattedValue: "8/3/2021 4:59 PM"
    // public msdyn_gdproptout: false
    // public msdyn_gdproptout@OData.Community.Display.V1.FormattedValue: "No"
    // public msdyn_orgchangestatus: 0
    // public msdyn_orgchangestatus@OData.Community.Display.V1.FormattedValue: "No Feedback"
    // public msdyusd_currentprofile: null
    // public msdyusd_facebook: null
    // public msdyusd_twitter: null
    // public nickname: null
    // public numberofchildren: null
    // public onholdtime: null
    // public overriddencreatedon: null
    // public pager: null
    // public participatesinworkflow: false
    // public participatesinworkflow@OData.Community.Display.V1.FormattedValue: "No"
    // public paymenttermscode: null
    // public preferredappointmentdaycode: null
    // public preferredappointmenttimecode: 1
    // public preferredappointmenttimecode@OData.Community.Display.V1.FormattedValue: "Morning"
    // public preferredcontactmethodcode: 1
    // public preferredcontactmethodcode@OData.Community.Display.V1.FormattedValue: "Any"
    // public processid: null
    // public salutation: null
    // public shippingmethodcode: 1
    // public shippingmethodcode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // public spousesname: null
    // public stageid: null
    // public statecode: 0
    // public statecode@OData.Community.Display.V1.FormattedValue: "Active"
    // public statuscode: 1
    // public statuscode@OData.Community.Display.V1.FormattedValue: "Active"
    // public subscriptionid: null
    // public suffix: null
    // public teamsfollowed: null
    public telephone1: string;
    public telephone2: null;
    public telephone3: null;
    // public territorycode: 1
    // public territorycode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // public timespentbymeonemailandmeetings: null
    // public timezoneruleversionnumber: null
    // public traversedpath: null
    // public utcconversiontimezonecode: null
    // public versionnumber: 33341646
    // public versionnumber@OData.Community.Display.V1.FormattedValue: "33,341,646"
    // public websiteurl: null
    // public yomifirstname: null
    // public yomifullname: "Muhammad Ali Shahzad"
    // public yomilastname: null

    constructor(data?: Partial<DynamicsContactModel>) {
        Object.assign(this, data);
    }

    public toActivityPartyModel(typemask: number) {
        const party = new DynamicsActivityPartyModel({
            participationtypemask: typemask,
        });

        party.bindWithContact(this.contactid);
        return party;
    }
}
