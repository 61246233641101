import {NgModule} from '@angular/core';
import {ScreenAgentApi, SecurityProfileApi} from './incontact/api';
import {IcSecurityProfileService, ScreenAgentService} from './incontact/services';
import {BaseApi, CustomErrorHandler} from './core';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        CustomErrorHandler,

        /**
         * Apis
         */
        BaseApi,
        SecurityProfileApi,
        ScreenAgentApi,

        /**
         * Services
         */
        IcSecurityProfileService,
        ScreenAgentService,
    ],
})
export class SdkModule {}
