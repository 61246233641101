/* eslint-disable */
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {throwError as observableThrowError, Observable, throwError} from 'rxjs';
import {TrackJS} from 'trackjs';
/**
 * Default error handler
 */
@Injectable()
export class CustomErrorHandler {
    constructor(private router: Router) {}

    // ErrorObservable when rxjs version < rc.5
    // ErrorObservable<string> when rxjs version = rc.5
    // I'm leaving any for now to avoid breaking apps using both versions
    public handleError(errorResponse: any) {
        // console.log('error', errorResponse.statusText);
        if ((errorResponse as any).Code) {
            // this.router.navigate(['/login']);
        }
        // if (errorResponse.error.error.name === HTTP_ERRORS.UnauthorizedError.key) {
        // }

        if ((errorResponse.statusText as string).indexOf('Agent session already started') === -1) {
            console.warn(errorResponse.message);
            TrackJS.track(errorResponse);
        }
        return throwError(errorResponse || 'Server error') as Observable<any>;
    }

    onSendRequestRawError(error: Response | any) {
        var self = this;
        let errMsg: string;
        if (error instanceof Response) {
            console.log(error);

            if (error.status == 401 && error.statusText == 'Unauthorized') {
                console.log('Session End Error:');
            } else {
                errMsg = `${error.status} - ${error.statusText || ''} ${error}`;

                if (error.status !== 304) console.log(errMsg);
            }
            //Return the Error
            return observableThrowError(error);
        }
    }
}
