import {Component, OnInit} from '@angular/core';

// import {DialogRef, ModalComponent, CloseGuard} from 'angular2-modal';
// import {BSModalContext} from 'angular2-modal/plugins/bootstrap';
import {APISystemObjectsService} from '../../core/_services/api-system-objects.service';

import {SkillsModel} from '../../core/InContact/models/skills.model';
import {AgentInfoModel} from '../../core/InContact/models/agent-info.model';
//import { ChatRoomModel } from '../.././core/_models/chat-room.model';
//import { WorkItemInfoModel } from '../.././core/_models/work-item-info.model';
//import { VoiceMailModel } from '../.././core/_models/voice-mail.model';
//import { ChatContactService } from '../.././core/_services/chat-contact.service';
//import { WorkItemContactService } from '../.././core/_services/work-item-contact.service';
//import { VoiceMailContactService } from '../.././core/_services/voice-mail-contact.service';
import {AgentStateModel} from 'src/app/core/_models/agent-state.model';
import {ContactBase} from '../../core/_models/contact-base';
import {ContactServiceBase} from '../../core/_services/contact.service.base';
import {ContactViewBaseModel} from '../../core/_models/_viewmodels/contact-view-base.model';
import {DialogRef} from 'ngx-modialog-7';
// import {BsModalRef} from 'ngx-bootstrap/modal';

export class ViewModel {
    public contactType: string;
    public agentId: number;
    public selectedAgent: AgentStateModel;
    public selectedSkill: SkillsModel;
    public options: string[];
    public skillslist: SkillsModel[];
    public agentslist: AgentInfoModel[];
    public agentStateList: AgentStateModel[];
    public offlineAgents: AgentStateModel[];
    public contact: ContactBase;
    public contactService: ContactServiceBase<ContactBase, ContactViewBaseModel<ContactBase>>;
    //public selectedWorkItem: WorkItemInfoModel;
    //public selectedVoiceMail: VoiceMailModel;
}

/**
 * A Sample of how simple it is to create a new window, with its own injects.
 */
@Component({
    selector: 'transfer-active-contact-modal',
    templateUrl: './transfer-active-contact-modal.component.html',
    styleUrls: ['./transfer-active-contact-modal.component.css'],
})
export class TransferActiveContactModalComponent implements /*CloseGuard,*/ OnInit {
    context: ViewModel;

    public selectedOption: string;
    public showAgents: boolean;
    public showSkills: boolean;

    constructor(public dialog: DialogRef<ViewModel>, private APIService: APISystemObjectsService) {
        this.context = this.dialog.context;
        this.context.options = ['Select  transfer type', 'Agents', 'Skills'];
        //dialog.setCloseGuard(this);
    }

    ngOnInit() {
        this.APIService.getAllSkills().subscribe(skills => {
            this.context.skillslist = skills.filter(
                e => e.MediaTypeId.toString() === this.context.contact.skillInfo.mediaTypeId.toString() && e.IsActive == 'True' && e.IsOutbound !== 'True',
            );
            /* if (this.context.contactType === "Chat")
          this.context.skillslist = skills.filter(e => e.MediaTypeId.toString() === this.context.contact.skillInfo.mediaTypeId.toString() && e.IsActive == "True");
        else if (this.context.contactType === "VoiceMail")
          this.context.skillslist = skills.filter(e => e.MediaTypeId.toString() === "5" && e.IsActive == "True");
        else if (this.context.contactType === "WorkItem")
          this.context.skillslist = skills.filter(e => e.MediaTypeId.toString() === "6" && e.IsActive == "True");
        else if (this.context.contactType === "Email")
          this.context.skillslist = skills.filter(e => e.MediaTypeId.toString() === "6" && e.IsActive == "True"); */
        });

        this.APIService.getAgentList().subscribe(agents => {
            // this.context.agentslist = agents;
            this.context.agentslist = agents.filter(e => e.IsActive === true && this.context.agentId);
        });

        this.APIService.getAgentStates().subscribe(agents => {
            this.context.agentStateList = agents.filter(e => e.agentStateId != '0' && e.agentId != this.context.agentId + '');
            this.context.offlineAgents = agents.filter(e => e.agentStateId == '0' && e.agentId != this.context.agentId + '');
        });
    }

    onChange(option) {
        this.selectedOption = option;

        if (option === 'Agents') {
            this.showAgents = true;
            this.showSkills = false;
        } else if (option === 'Skills') {
            this.showAgents = false;
            this.showSkills = true;
        }
    }
    onKeyUp(value) {
        this.dialog.close();
        // this._modalRef.hide();
    }

    transferChat() {
        let data: string;

        if (this.selectedOption === 'Agents') {
            if (this.context.selectedAgent !== null && this.context.selectedAgent !== undefined) {
                if (this.context.contactType === 'WorkItem') {
                    //for Work Item transfer Skill name is required
                    data = this.context.selectedAgent.userName;
                } else {
                    data = this.context.selectedAgent.agentId;
                }
            } else {
                return;
            }
        } else if (this.selectedOption === 'Skills') {
            if (this.context.selectedSkill !== null && this.context.selectedSkill !== undefined) {
                if (this.context.contactType === 'WorkItem') {
                    //for Work Item transfer Skill name is required
                    data = this.context.selectedSkill.SkillName;
                } else {
                    data = this.context.selectedSkill.SkillId.toString();
                }
            } else {
                return;
            }
        } else {
            return;
        }
        this.context.contactService.transferContact(this.context.contact, {transferType: this.selectedOption, targetId: data}).subscribe();
        /*
    if (this.context.contactType === "Chat") {
      //this.chatContactService.transferContact(this.context.contact, {transferType: this.selectedOption, targetId:data});
    }
    else if (this.context.contactType === "WorkItem") {
      //this.workItemContactService.transferWorkItem(this.context.selectedWorkItem.ContactID, this.selectedOption, data);
    }
    else if (this.context.contactType === "VoiceMail") {
      //this.voiceMailContactService.transferVoiceMail(this.context.selectedVoiceMail.ContactId,this.selectedOption,data);
    } */

        this.dialog.close();
        // this._modalRef.hide();
    }

    cancel() {
        this.dialog.close(false);
        // this._modalRef.hide();
    }
    // beforeDismiss(): boolean {
    //    return true;
    // }

    //   beforeClose(): boolean {
    //     return true;
    //   }
}
