import {ContactViewBaseModel, ContactActionModel} from './contact-view-base.model';
import {CallContactModel} from '../call-contact';
import {CRM_APPLICATION_TYPES} from 'src/app/global';

export class CallContactViewmodel extends ContactViewBaseModel<CallContactModel> {
    isTransferButtonAvailable = () => !this.contact.isIncoming() && !this.contact.isDialing() && !this.contact.isDisconnected() && !this.contact.isRinging();
    isDialpadButtonAvailable = () => !this.contact.isIncoming() && !this.contact.isDialing() && !this.contact.isRinging();
    isMuteButtonAvailable = () => !this.contact.isIncoming() && !this.contact.isDialing() && !this.contact.isRinging();
    isRecordButtonAvailable = () => !this.contact.isIncoming() && !this.contact.isDialing() && !this.contact.isRinging();

    vmInit() {
        const me = this;
        this.contactIcon = this.contact.contactIcon;

        if (this.contact.isPreview) {
            this.actions.push(
                new ContactActionModel({
                    icon: 'undo ',
                    text: 'Requeue',
                    action: 'requeue',
                    slot: 'footer',
                    textAlwaysvisible: true,
                    isVisible: () => me.contact.skillInfo?.prevRequeue && !me.contact.isDisconnected() && me.contact.status === 'Preview',
                }),
                new ContactActionModel({
                    icon: 'undo ',
                    text: 'Reschedule',
                    action: 'reschedule',
                    slot: 'footer',
                    textAlwaysvisible: true,
                    isVisible: () => me.contact.skillInfo?.prevReschedule && !me.contact.isDisconnected() && me.contact.status === 'Preview',
                }),
                new ContactActionModel({
                    icon: 'clock ',
                    text: 'Snooze',
                    action: 'snooze',
                    slot: 'footer',
                    textAlwaysvisible: true,
                    isVisible: () => me.contact.skillInfo?.prevSnooze && !me.contact.isDisconnected() && me.contact.status === 'Preview',
                }),

                new ContactActionModel({
                    action: 'end',
                    text: 'Hangup',
                    icon: 'phone-slash',
                    visibleInOmniRelatedMode: true,
                    isFullRow: me.contact.isMasterContact(),
                    color: 'red',
                    isAvailable: () => !this.contact.isHolding(),
                    isVisible: () =>
                        /* !me.contact.isHolding() && */ !me.contact.isIncoming() &&
                        !me.contact.isDisconnected() &&
                        !me.contact.canAcceptConsult() &&
                        me.contact.isActive(),
                }),
            );
        } else {
            this.actions.push(
                new ContactActionModel({
                    icon: 'check-square fa-2x',
                    text: 'Accept',
                    action: 'accept_consult',
                    slot: 'footer',
                    textAlwaysvisible: true,
                    isVisible: () => me.contact.canAcceptConsult(),
                }),
                /* new contactAction(
                {
                    icon: "envelope-open",
                    text: "Email",
                    action: "elevate_to_email",
                    slot: "elevations",
                    isVisible: () => !me.contact.isDisconnected()
                }), */
                new ContactActionModel({
                    action: 'unmask',
                    text: 'Un-Mask',
                    icon: 'theater-masks',
                    visibleInOmniRelatedMode: true,
                    isAvailable: () => me.contact.canUnmask() && !me.contact.isHolding(),
                    isVisible: () => me.contact.canUnmask(), //&& me.contact.isMasterContact()
                }),
                new ContactActionModel({
                    action: 'mask',
                    text: 'Mask',
                    icon: 'mask',
                    visibleInOmniRelatedMode: true,
                    isAvailable: () => me.contact.canMask && !me.contact.isHolding(),
                    isVisible: () => me.contact.canMask(), //&& me.contact.isMasterContact()
                }),
                new ContactActionModel({
                    action: 'record',
                    text: 'Record',
                    icon: 'circle',
                    visibleInOmniRelatedMode: true,
                    isAvailable: () => me.contact.canRecord() && !me.contact.isHolding(),
                    isVisible: /* me.contact.canRecord */ () => this.isRecordButtonAvailable() && !me.contact.isDisconnected(), //&& me.contact.isMasterContact()
                }),
                new ContactActionModel({
                    action: 'resume',
                    text: 'Resume',
                    icon: 'play-circle',
                    visibleInOmniRelatedMode: true,
                    isAvailable: me.contact.isHolding,
                    isVisible: me.contact.isHolding,
                }),
                new ContactActionModel({
                    action: 'hold',
                    text: 'Hold',
                    icon: 'pause',
                    visibleInOmniRelatedMode: true,
                    isAvailable: me.contact.canHold,
                    isVisible: () =>
                        !me.contact.isHolding() &&
                        !me.contact.isIncoming() &&
                        !me.contact.isDialing() &&
                        !me.contact.isRining() &&
                        !me.contact.isDisconnected(),
                }),
                /* new contactAction({
                "action": "transfer",
                "text": "Transfer",
                "icon": "exchange-alt",
                isAvailable: function () {
                    return me.contact.isInbound;
                },
                isVisible: () => !me.contact.isHolding() && !me.contact.isIncoming() && !me.contact.isDialing() && !me.contact.isDisconnected()
            }), */

                new ContactActionModel({
                    icon: 'microphone-alt-slash',
                    text: 'Mute',
                    action: 'mute',
                    isVisible: () => this.appContext.callContactService.isMuteAvailable() && this.isMuteButtonAvailable(),
                }),
                new ContactActionModel({
                    icon: 'microphone-alt',
                    text: 'Un-Mute',
                    action: 'unmute',
                    isVisible: () => this.appContext.callContactService.agentMuted && this.isMuteButtonAvailable(),
                }),
                new ContactActionModel({
                    icon: 'th',
                    text: 'Dialpad',
                    action: 'dialpad',
                    isVisible: () => this.isDialpadButtonAvailable() && me.contact.isMasterContact() && !me.contact.isDisconnected(),
                }),
                new ContactActionModel({
                    icon: 'exchange-alt',
                    text: 'Transfer',
                    action: 'transfer',
                    menuItems: [
                        new ContactActionModel({
                            icon: 'exchange-alt',
                            text: 'Call An Address Book',
                            action: 'adressbook',
                        }),
                        new ContactActionModel({
                            icon: 'exchange-alt',
                            text: 'Call From History',
                            action: 'history',
                        }),
                    ],
                    isVisible: () => this.isTransferButtonAvailable() && this.getChildContacts().length == 0, //&& me.contact.isMasterContact()
                }),
                new ContactActionModel({
                    icon: 'exchange-alt',
                    text: 'Transfer',
                    action: 'transfer',
                    menuItems: [
                        new ContactActionModel({
                            icon: 'exchange-alt',
                            text: 'Transfer',
                            action: 'transfer',
                        }),
                        new ContactActionModel({
                            icon: 'exchange-alt',
                            text: 'Conference',
                            action: 'conference',
                        }),
                    ],
                    isVisible: () => this.isTransferButtonAvailable() && this.getChildContacts().length > 0,
                }),
                new ContactActionModel({
                    action: 'end',
                    text: 'Hangup',
                    icon: 'phone-slash',
                    visibleInOmniRelatedMode: true,
                    isFullRow: me.contact.isMasterContact(),
                    color: 'red',
                    isAvailable: () => !this.contact.isHolding(),
                    isVisible: () =>
                        /* !me.contact.isHolding() && */ !me.contact.isIncoming() && !me.contact.isDisconnected() && !me.contact.canAcceptConsult(),
                }),
            );
        }

        if (this.contact.isInbound) {
            this.configMap = {
                settingSectionName: 'inboundCall',
                mappingSectionName: 'inboundCall',
                incontactSearchField: 'ANI',
                userTitle: 'Caller',
                isCallContact: true,
                statusField: 'Status',
                activeStatusValue: 'Active',
                contactTitle: 'Inbound Call',
            };
        } else {
            this.configMap = {
                settingSectionName: 'outboundCall',
                mappingSectionName: 'outboundCall',
                incontactSearchField: 'DNIS',
                userTitle: 'Caller',
                contactTitle: 'Outbound Call',
                isCallContact: true,
                statusField: 'Status',
                activeStatusValue: 'Active',
            };
        }

        if (this.appContext.selectedCrmApplication.applicationType === CRM_APPLICATION_TYPES.ZENDESK) {
            this.configMap.zendeskSearchField = 'phone';
            this.configMap.zendeskSearchOperator = '*';
        } else if (this.appContext.selectedCrmApplication.applicationType === CRM_APPLICATION_TYPES.DYNAMICS) {
            this.configMap.zendeskSearchField = 'telephone1';
            this.configMap.zendeskSearchOperator = '*';
        }
    }

    executeContactAction(action: string, data?: any) {
        switch (action) {
            case 'requeue':
                this.appContext.callContactService.rejectContact(this.contact).subscribe();
                break;
            case 'reschedule':
                this.appContext.callContactService.openPreviewScheduler(this.contact.contactId, this.contact.DNIS);
                break;
            case 'snooze':
                this.appContext.callContactService.snoozeContact(this.contact).subscribe();
                break;
            case 'mask':
                this.appContext.callContactService.maskRecording(this.contact).subscribe();
                break;
            case 'unmask':
                this.appContext.callContactService.unmaskRecording(this.contact).subscribe();
                break;
            case 'unmask':
                this.appContext.callContactService.unmaskRecording(this.contact).subscribe();
                break;
            case 'record':
                this.appContext.callContactService.startRecording(this.contact).subscribe();
                break;
            case 'accept_consult':
                this.appContext.inContactAPIService.acceptConsult(this.contact.contactId).subscribe();
                break;
            case 'mute':
                this.appContext.callContactService.muteAgent().subscribe();
                break;
            case 'unmute':
                this.appContext.callContactService.unMuteAgent().subscribe();
                break;
            case 'dialpad':
                this.appContext.appRouter.navigate(['dialpad']);
                break;
            case 'adressbook':
                this.appContext.appRouter.navigate(['address-book']);
                break;
            case 'history':
                this.appContext.appRouter.navigate(['call-history']);
                break;
            case 'transfer':
                this.appContext.callContactService.transferCalls().subscribe();
                break;
            case 'conference':
                this.appContext.callContactService.conferenceCalls().subscribe();
                break;
            default:
                super.executeContactAction(action, data);
                break;
        }
    }

    isCrmProcessable(): boolean {
        return this.contact.callType == 'Regular' || this.contact.callType == 'NaturalCalling';
    }
}
