import {AgentStateModel} from './agent-state.model';
import {AgentSkillAssignmentsModel} from '../.././core/InContact/models/agent-skill-assignments.model';
import {AgentSettingsModel} from 'src/app/sdk/incontact/models';

export class Agent {
    constructor(data?: Partial<Agent>, settings?: any) {
        // if (data) this.loadFromJSON(data);
        Object.assign(this, data);

        this.info = data;
        this.settings = settings;
    }
    userName: string;
    password: string;
    sessionType: string;
    sessionValue: string;
    language: string;
    selectedAgentState: AgentStateModel;
    currentAgentState: string;
    outboundSkills: AgentSkillAssignmentsModel[];

    agentId: number;
    apiKey: string;
    atHome: boolean;
    chatRefusalTimeout: number;
    chatThreshold: number;
    city: string;
    contactAutoFocus: boolean;
    country: string;
    countryName: string;
    createDate: string;
    crmUserName: string;
    custom1: string;
    custom2: string;
    custom3: string;
    custom4: string;
    custom5: string;
    defaultDialingPattern: string;
    defaultDialingPatternName: string;
    emailAddress: string;
    emailThreshold: number;
    employmentType: number;
    employmentTypeName: string;
    federatedId: string;
    firstName: string;
    hireDate: string;
    hiringSource: string;
    hourlyCost: number;
    inactiveDate: string;
    internalId: string;
    isActive: boolean;
    isOpenIdProfileComplete: boolean;
    isSupervisor: boolean;
    isWhatIfAgent: boolean;
    issuer: string;
    lastLogin: string;
    lastName: string;
    lastUpdated: string;
    location: string;
    maxConcurrentChats: number;
    maxEmailAutoParkingLimit: number;
    middleName: string;
    notes: string;
    ntLoginName: string;
    phoneRefusalTimeout: string;
    profileId: number;
    profileName: string;
    recordingNumbers: any[];
    referral: string;
    rehireStatus: boolean;
    reportToId: string;
    reportToName: string;
    requestContact: boolean;
    scheduleNotification: number;
    sendEmailNotifications: boolean;
    sipUser: string;
    state: string;
    subject: string;
    systemDomain: string;
    systemUser: string;
    teamId: string;
    teamName: string;
    teamUuid: string;
    telephone1: string;
    telephone2: string;
    terminationDate: string;
    timeDisplayFormat: string;
    timeZone: string;
    timeZoneOffset: string;
    useAgentTimeZone: boolean;
    useTeamChatThreshold: boolean;
    useTeamContactAutoFocus: boolean;
    useTeamEmailAutoParkingLimit: boolean;
    useTeamEmailThreshold: boolean;
    useTeamMaxConcurrentChats: boolean;
    useTeamRequestContact: boolean;
    useTeamWorkItemThreshold: boolean;
    userId: string;
    userType: string;
    voiceThreshold: number;
    workItemRefusalTimeout: string;
    workItemThreshold: number;

    info: any;
    settings: AgentSettingsModel = new AgentSettingsModel({});

    // private loadFromJSON(data: any) {
    //     if (data) {
    //         Object.keys(data).forEach((v, i) => {
    //             this[v] = data[v];
    //         });
    //     }
    // }
}
