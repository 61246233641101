export class CurrentContact {
    public ticketId: any;
    public contactId: any;
    public contact: any;

    constructor(ticketId, contactId) {
        this.ticketId = ticketId;
        this.contactId = contactId;
    }
}
