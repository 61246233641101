import {ContactServiceBase} from './contact.service.base';
import {WorkItemModel} from '../_models/work-item-info.model';
import {Injectable} from '@angular/core';
import {ApplicationContextService} from './application-context.service';
import {WorkitemContactViewmodel} from '../_models/_viewmodels/workitem-contact-viewmodel';
import {ContactBase} from '../_models/contact-base';
import {Observable, throwError} from 'rxjs';

@Injectable()
export class WorkItemContactService extends ContactServiceBase<WorkItemModel, WorkitemContactViewmodel> {
    constructor(appContext: ApplicationContextService) {
        super(WorkItemModel, WorkitemContactViewmodel as any, appContext);
        this.title = 'Work Items';

        appContext.workitemContactService = this;
    }

    transferContact(contact: ContactBase, data: any): Observable<ContactBase> {
        return new Observable(r => {
            this.appContext.inContactAPIService.transferWorkItem(contact.contactId, data).subscribe(
                res => {
                    //this.messageService.setCallMessage.next("Chat has been transfered successfully to " + data.transferType);
                    r.next(contact);
                },
                err => throwError(err),
            );
        });
    }

    protected onContactActivated(contact: WorkItemModel) {
        super.onContactActivated(contact);
        this.navigateTo(['active-contact']);
    }
}
