export class AgentSettingsModel {
    public deleteCommitmentId: number;
    public deleteCommitmentString: string;
    public googleAccountNumberMAX: string;
    public googleAccountNumberSupervisor: string;
    public helpSiteVersion: string;
    public maxClientVersion: string;
    public maxConferenceParties: number;
    public persistentPanels: Array<string>;
    public raygunApiKeyMAX: string;
    public raygunApiKeySupervisor: string;
    public webRTCDNIS: string;
    public webRTCEnterprise: string;
    public webRTCICEUrls: Array<string>;
    public webRTCServerDomain: string;
    public webRTCType: string;
    public webRTCUserId: number;
    public webRTCUserToken: string;
    public webRTCWSSUrls: Array<string>;
    public wfoApiUrl: string;
    public wfoWebServiceUrl: string;
    public wfoWebsiteUrl: string;

    constructor(data?: Partial<AgentSettingsModel>) {
        Object.assign(this, data);
    }
}
