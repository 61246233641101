<!-- <div class="bootstrap-tagsinput">
    <span *ngFor="let tag of contact.tagObjects" class="tag label label-info">{{tag.title}}<span data-role="remove"
            (click)="removeTag(tag.id)"></span></span>
    <input type="text" data-role="tags" autocomplete="off" placeholder="Add Tag"
        attr.contactid="tags_{{contact.contactId}}" />
</div> -->
<ul class="nav nav-pills tags-pills">
    <li *ngFor="let tag of contact.tagObjects">
        <span class="tag label label-info"
            >{{ tag.title }}<span data-role="remove" (click)="removeTag(tag.id)"></span
        ></span>
    </li>
    <li class="dropdown tag-button-container">
        <button
            class="btn btn-default btn-xs dropdown-toggle"
            type="button"
            id="dropdownMenu1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
        >
            +tag
            <!-- <span class="caret"></span> -->
        </button>
        <ul
            class="dropdown-menu"
            style="max-width: {{ maxDropdownWidth }}px;"
            [ngClass]="{'dropdown-menu-right': menuDynamicOffset > maxDropdownWidth}"
            aria-labelledby="dropdownMenu1"
        >
            <li *ngFor="let tag of tagsData">
                <a (click)="addTag(tag)" href="javascript:;">{{ tag.title }}</a>
            </li>
            <!-- <li><a href="#">Another action</a></li>
                <li><a href="#">Something else here</a></li>
                <li role="separator" class="divider"></li>
                <li><a href="#">Separated link</a></li> -->
        </ul>
    </li>
</ul>
<div *ngIf="false" class="dropdown">
    <button
        class="btn btn-default dropdown-toggle"
        type="button"
        id="dropdownMenu1"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
    >
        +
        <span class="caret"></span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
        <li *ngFor="let tag of tagsData">
            <a (click)="addTag(tag)" href="javascript:;">{{ tag.title }}</a>
        </li>
        <!-- <li><a href="#">Another action</a></li>
                <li><a href="#">Something else here</a></li>
                <li role="separator" class="divider"></li>
                <li><a href="#">Separated link</a></li> -->
    </ul>
</div>
