import {Component, OnInit, OnDestroy, Input, HostListener, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';

import {APISystemObjectsService} from '../../../core/_services/api-system-objects.service';
import {IcAgentSessionService} from '../../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {CallContactService} from '../../../core/_services/call-contact.service';
import {AgentStateModel} from '../../../core/_models/agent-state.model';
import {SessionInfo} from '../../../core/InContact/models/session-info.model';
import {HotKeyPressSerivce} from '../../../core/_services/hot-key-press.service';
import {CallContactModel} from 'src/app/core/_models/call-contact';

@Component({
    selector: 'app-agents',
    templateUrl: './agents.component.html',
    styleUrls: ['./agents.component.css'],
})
export class AgentsComponent implements OnInit, OnDestroy {
    agentslist: AgentStateModel[];
    agentStateTimer: any;
    sessionInfo: SessionInfo;
    showCallMenu: boolean;
    directCall: boolean;

    private _selectedStatus: string;
    // selectedStatus:string="All";

    @Input()
    set selectedStatus(value: string) {
        this._selectedStatus = value;

        this.onChange();
    }

    get selectedStatus(): string {
        return this._selectedStatus;
    }

    @Input()
    keyword: string;

    calls: CallContactModel[];

    constructor(
        private apiObjects: APISystemObjectsService,
        private agentService: IcAgentSessionService,
        public callContactService: CallContactService,
        private hotKeyPressSerivce: HotKeyPressSerivce,
        private router: Router,
    ) {}

    ngOnInit() {
        this.directCall = false;
        this.showCallMenu = false;

        this.sessionInfo = this.agentService.getSessionInfo();
        this.agentslist = [];
        this.getAgents();
        this.updateAgentStateTimer();

        this.callContactService.currentContacts.subscribe(res => {
            this.calls = res;

            if (this.calls.length > 0) {
                this.directCall = false;
                //this.showCallMenu = (this.calls[0].status !== "Active");
                this.showCallMenu = true;
            } else {
                this.directCall = true;
                this.showCallMenu = false;
            }
        });
    }

    ngOnDestroy() {
        clearInterval(this.agentStateTimer);
    }

    getColor(agentStateId: string, isACW: string): string {
        let color: string;
        switch (agentStateId) {
            case '1': // "Available":
                color = 'limegreen';
                break;
            case '2': // "Unavailable":
                color = 'red';
                break;
            case 'LoggedOut':
                color = 'grey';
                break;
            default:
                color = 'grey';
                break;
        }

        if (isACW === 'True' && agentStateId !== '1') {color = 'orange';}

        return color;
    }

    getAgents() {
        this.apiObjects.getAgentStates().subscribe(res => {
            if (this.selectedStatus === 'All')
                {this.agentslist = res.filter(e => +e.agentId !== this.sessionInfo.AgentId && e.isActive === 'True');}
            else if (this.selectedStatus === 'Logged In')
                {this.agentslist = res.filter(
                    e =>
                        +e.agentId !== this.sessionInfo.AgentId &&
                        e.isActive === 'True' &&
                        (e.agentStateId === '1' || e.agentStateId === '2'),
                );}
            else
                {this.agentslist = res.filter(
                    e =>
                        +e.agentId !== this.sessionInfo.AgentId &&
                        e.isActive === 'True' &&
                        e.agentStateId === this.selectedStatus,
                );}

            this.agentslist = this.agentslist.sort((n1, n2) => {
                if (n1.firstName > n2.firstName) {
                    return 1;
                }

                if (n1.firstName < n2.firstName) {
                    return -1;
                }

                return 0;
            });

            // this.agentslist = this.agentslist.filter( e=> e.firstName.indexOf('a') === 0 );
            this.agentslist.forEach(element => {
                this.setAgentStateTime(element);
            });
        });
    }

    onChange() {
        //this.selectedStatus = value;
        clearInterval(this.agentStateTimer);
        this.getAgents();
        this.updateAgentStateTimer();
    }

    updateAgentStateTimer() {
        clearInterval(this.agentStateTimer);
        let stateUpdateCount = 0;

        this.agentStateTimer = setInterval(() => {
            const current = new Date();

            for (let i = 0; i < this.agentslist.length; i++) {
                const rec = this.agentslist[i];
                this.setAgentStateTime(rec);
            }
            stateUpdateCount++;
            if (stateUpdateCount > 10) {
                this.getAgents();
                stateUpdateCount = 0;
            }
        }, 1000);
    }

    setAgentStateTime(agent: AgentStateModel) {
        let serverTimeOffset;
        if (+agent.agentStateId > 0) {
            this.agentService.serverTimeOffset
                .subscribe(offset => {
                    serverTimeOffset = offset;
                    const currentDate = new Date().valueOf() + serverTimeOffset;
                    const dif = new Date(currentDate.valueOf() - new Date(agent.lastUpdateTime).valueOf()).getTime();
                    agent.stateTime = this.msToTime(dif);
                })
                .unsubscribe();
        }
    }

    @ViewChild('listItem') listItem: ElementRef;
    @HostListener('window:keydown', ['$event'])
    hotkeys(event: KeyboardEvent) {
        const key = event.keyCode;
        if (this.hotKeyPressSerivce.altPress(event)) {
            switch (key) {
                case 76: //L
                    $(this.listItem.nativeElement).focus();
                    event.preventDefault();
                    break;
            }
        }
    }

    msToTime(duration: number) {
        const milliseconds = Math.floor((duration % 1000) / 100);
        const seconds = Math.floor((duration / 1000) % 60);
        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        const h = hours < 10 ? '0' + hours : hours;
        const m = minutes < 10 ? '0' + minutes : minutes;
        const s = seconds < 10 ? '0' + seconds : seconds;

        if (duration > 0) {
            return h + ':' + m + ':' + s;
        } else {
            return '';
        }
    }
    /*
    consultAgent(userName: string) {
      let call = this.calls[0];
      if (call && call.status !== 'Holding') {
        this.callContactService.holdContact(call)
          .subscribe(res => {
            this.callContactService.consultAgent(userName);
          });
      } else {
        this.callContactService.consultAgent(userName);
      }

    }

    dialAgent(userName: string) {
      let call = this.calls[0];
      if (call.status !== 'Holding') {
        this.callContactService.holdContact(call)
          .subscribe(res => {
            this.callContactService.dialAgent(userName);
          });
      } else {
        this.callContactService.dialAgent(userName);
      }
    } */
}
