import {Inject, Injectable} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {WebRTCService} from 'src/app/core/InContact/web-rtc/web-rtc.service';
import {IcAgentsApi} from '../../api/agents/ic-agents.api';
import {AgentSettingsModel} from '../../models';

@Injectable({
    providedIn: 'root',
})
export class IcAgentSettingsService {
    constructor(@Inject(LocalStorageService) private localStorageSrv: LocalStorageService, @Inject(IcAgentsApi) private icAgentApi: IcAgentsApi) {}

    loadAgentSettings(agentId: number) {
        return new Promise((resolve: (data: AgentSettingsModel) => void, reject: (err: any) => void) => {
            this.icAgentApi.getAgentSettings(agentId).subscribe(
                res => {
                    this.localStorageSrv.store('agentSettings', res);

                    resolve(res);
                },
                err => {
                    reject(err);
                },
            );
        });
    }
}
