import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthenticationContextModel} from '.././models/authentication-context.model';
import {FacLocalStorageService} from '../../../sdk/storage/fac-local-storage.service';
import {parseJwt} from '../../../global';
import {SecurityProfileModel, IncontactJwtPayloadViewModel} from 'src/app/sdk/incontact/models';
import {IcSecurityProfileService} from 'src/app/sdk/incontact/services/incontact/ic-security-profile.service';
import {InContactSdkConfig} from 'src/app/sdk/sdk.config';
import {InContactApiSettings} from '../incontact-api-settings';

@Injectable()
export class AuthenticationContextService {
    public token: string;
    public ErrorMsg: string;
    public userName: string;
    public password: string;
    public authenticationContextModel: AuthenticationContextModel;
    public securityProfile: SecurityProfileModel;

    constructor(private _facLocalStorageSrv: FacLocalStorageService, @Inject(IcSecurityProfileService) private securityProfileSrv: IcSecurityProfileService) {}

    get refresh_token_server_uri() {
        const authContext = this.getAuthContext();
        return authContext?.refresh_token_server_uri ?? '';
    }

    saveAuthContext(authenticationContextModel: AuthenticationContextModel) {
        InContactSdkConfig.BaseApiPath = authenticationContextModel.resource_server_base_uri;

        this._facLocalStorageSrv.saveSettings(authenticationContextModel);

        // console.log(authenticationContextModel);
        const jwtPayload = parseJwt<IncontactJwtPayloadViewModel>(authenticationContextModel.access_token);
        if (jwtPayload) {
            this._facLocalStorageSrv.saveJwtPayload(jwtPayload);

            this.securityProfileSrv.LoadSecurityProfile(jwtPayload.role?.id ?? jwtPayload.icSPId);
        }
    }

    clearAuthContext() {
        this._facLocalStorageSrv.clearSettings();
    }

    getAuthContext(): AuthenticationContextModel {
        return this._facLocalStorageSrv.getSettings();
    }
    getResourceServerBaseUri() {
        const authContext = this.getAuthContext();
        return authContext.resource_server_base_uri;
    }

    getUserName(): string {
        return this._facLocalStorageSrv.getItem('username');
    }

    getPassword(): string {
        return this._facLocalStorageSrv.getItem('password');
    }

    tryReloadState(): AuthenticationContextModel | null {
        this.userName = this.getUserName();
        if (this.userName == null) {
            return null;
        }

        this.password = this.getPassword();
        if (this.password == null && !InContactApiSettings.isSSOLogin) {
            return null;
        }

        this.authenticationContextModel = this.getAuthContext();
        return this.authenticationContextModel;
    }
}
