import {NgModule} from '@angular/core';

//Modules
import {AgentInfoRoutingModule} from './agent-info.routing';
import {SharedModule} from '../../shared/shared.module';

//Services
// import { QueueService }     from './address-book.service';

//Components
import {AgentInfoComponent} from './agent-info.component';

@NgModule({
    imports: [AgentInfoRoutingModule, SharedModule],
    declarations: [AgentInfoComponent],
    exports: [AgentInfoComponent],
    providers: [],
})
export class AgentInfoModule {}
