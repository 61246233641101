import {ContactBase} from './contact-base';

export class ChatRoomModel extends ContactBase {
    public roomId: string;
    public roomName: string;
    //    public contactId: number;
    public chatMessages: Message[];
    public selected: boolean;
    public chatStatusType: string;
    public chatMessageTimer: any;
    public messageTimeElapsed: string;
    public highlightedStyle: string;
    public unread: boolean;

    /*
        ContactID: "42946323729"
    CustomData: ""
    FinalState: "False"
    IISHost: "DAL-B2COR01"
    InFocus: "True"
    IsActive: "True"
    LastStateChangeTime: "2018-12-12T21:31:56.000Z"
    MasterID: "42946323729"
    OmniGroupId: "0"
    ParentContactId: "0"
    RefusalTimeout: "45"
    RoomId: "12503"
    ScreenPopUrl: ""
    Skill: "4015294"
    StartTime: "2018-12-12T20:37:44.000Z"
    Status: "Active" */

    // public chatStatusTimer:any;
    // public statusTimeElapsed:number;

    /* constructor(roomId:number, roomStatus:string, roomStartTime:Date, contact:number, roomSelected:boolean, Skill:string){
        this.roomId = roomId;
        this.status = roomStatus;
        this.startTime = roomStartTime;
        this.contactId = contact;
        this.selected = roomSelected;
        this.Skill = Skill;
    } */

    constructor(data: any) {
        super(data);

        this.contactType = 'Chat';
        this.contactIcon = 'comments';

        this.roomId = data.RoomId;
        this.chatMessages = [];
        this.selected = false;
        this.isInbound = true;

        this.supportsEmailElevation = true;
        this.supportsPhoneElevation = true;
    }

    public transferDataFromExisting(contact: ChatRoomModel) {
        if (contact) {
            this.chatMessages = contact.chatMessages;
            this.timers = contact.timers;
        }
        super.transferDataFromExisting(contact);
    }

    public isRequiredToBeAcceptedWhenIncoming(): boolean {
        return true;
    }

    public chatStatusIcon() {
        if (this.chatStatusType === 'Client') {
            return '../images/icon_feedback.png';
        } else {
            return '../images/icon_waiting.png';
        }
    }

    public updateTimers(serverTimeOffset: number) {
        super.updateTimers(serverTimeOffset);
        const currentServerTime = this.makeServerTime(serverTimeOffset);
        if (this.chatMessages.length > 0) {
            const lastMessage = this.chatMessages[this.chatMessages.length - 1];
            if (lastMessage) {
                const lastMessageTimeElapsed = new Date(
                    currentServerTime.valueOf() - new Date(lastMessage.TimeStamp).valueOf(),
                ).getTime();
                this.timers.lastMessageTimeElapsed = this.getFormattedTime(lastMessageTimeElapsed);
            }
        }
    }
}

export class Message {
    public Text: string;
    public TimeStamp: Date;
    public PartyType: string;
    public Label: string;
    public data: any;

    public isAgentMessage: Function = () => this.PartyType == 'Agent';
    public isSystemMessage: Function = () => this.PartyType == 'System';
    public isClientMessage: Function = () => this.PartyType == 'Client';

    constructor(contactData: any) {
        this.PartyType = contactData.PartyType;
        this.TimeStamp = contactData.TimeStamp;
        this.Text = this.localizedMessages(contactData.Text ? contactData.Text : contactData.Message);
        this.Label = !contactData.Label || contactData.Label.length == 0 ? contactData.PartyType : contactData.Label;
        this.data = contactData;
    }

    private localizedMessages(message: string) {
        switch (message) {
            case '$Localized:ChatSessionEnded':
                message = 'Chat session ended';
                break;
            case '$Localized:AgentLeftChat':
                message = 'The agent has left the chat.';
                break;
            default:
                return message;
        }
        return message;
    }
}
