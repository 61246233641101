import {Injectable} from '@angular/core';
// import {LocalStorageService, SessionStorageService} from 'ng2-webstorage';

import {AuthenticationContextModel} from '../../core/InContact/models/authentication-context.model';
import {AgentSettingsModel, IncontactJwtPayloadViewModel} from '../incontact/models';
import {SessionStorageService, LocalStorageService} from 'ngx-webstorage';
import {Agent} from 'src/app/core/_models/agent.model';

@Injectable()
export class FacLocalStorageService {
    public token: string;

    constructor(private _SessionStorageService: SessionStorageService, private localStorageService: LocalStorageService) {}

    saveSettings(authenticationContext: AuthenticationContextModel) {
        this.localStorageService.store('authContext', authenticationContext);
    }

    saveJwtPayload(jwtPayload: IncontactJwtPayloadViewModel) {
        this.localStorageService.store('incontactJwtPayload', jwtPayload);
    }
    getJwtPayload(): IncontactJwtPayloadViewModel {
        return this.localStorageService.retrieve('incontactJwtPayload');
    }
    clearJwtPayload() {
        this.localStorageService.clear('incontactJwtPayload');
    }

    clearSettings() {
        this.clearJwtPayload();
        this.localStorageService.clear('authContext');
    }

    getSettings(): AuthenticationContextModel {
        const authenticationContext = this.localStorageService.retrieve('authContext') as AuthenticationContextModel;
        return authenticationContext;
    }

    get access_token() {
        const authContext = this.getSettings();
        return authContext?.access_token ?? '';
    }

    get resource_server_base_uri() {
        const authContext = this.getSettings();
        return authContext?.resource_server_base_uri ?? '';
    }

    setItem(key: string, value: string) {
        this.localStorageService.store(key, value);
    }

    getItem(key: string): any {
        return this.localStorageService.retrieve(key) as string;
    }

    setSessionstorage(key: string, value: string) {
        this._SessionStorageService.store(key, value);
    }

    getSessionstorage(key: string): string {
        return this._SessionStorageService.retrieve(key) as string;
    }

    public get getAgent(): Agent {
        let agent = this.localStorageService.retrieve('currentAgent') as Agent;
        if (agent) {
            agent.info = this.localStorageService.retrieve('agentInfo');
            agent.settings = this.localStorageService.retrieve('agentsettings') as AgentSettingsModel;
        } else {
            agent = new Agent({
                sessionType: 'station',
            });
        }
        return agent;
    }
}
