export class DynamicsActivityPartyModel {
    // @odata.etag: "W/\"34008129\""
    activitypartyid: string;
    // addressused: null
    // addressusedemailcolumnnumber: null
    // donotemail: false
    // donotemail@OData.Community.Display.V1.FormattedValue: "Allow"
    // donotfax: false
    // donotfax@OData.Community.Display.V1.FormattedValue: "Allow"
    // donotphone: false
    // donotphone@OData.Community.Display.V1.FormattedValue: "Allow"
    // donotpostalmail: false
    // donotpostalmail@OData.Community.Display.V1.FormattedValue: "Allow"
    // effort: null
    // exchangeentryid: null
    // instancetypecode: 0
    // instancetypecode@OData.Community.Display.V1.FormattedValue: "Not Recurring"
    // ispartydeleted: false
    // ispartydeleted@OData.Community.Display.V1.FormattedValue: "No"
    public participationtypemask: number;
    'participationtypemask@OData.Community.Display.V1.FormattedValue': string;
    // scheduledend: null
    // scheduledstart: null
    // versionnumber: 34008129
    // versionnumber@OData.Community.Display.V1.FormattedValue: "34,008,129"
    // _activityid_value: "f82b3aa3-a710-ec11-b6e6-000d3a3b8000"
    // _activityid_value@Microsoft.Dynamics.CRM.associatednavigationproperty: "activityid_activitypointer"
    // _activityid_value@Microsoft.Dynamics.CRM.lookuplogicalname: "activitypointer"
    _ownerid_value: string;
    // _ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname: "systemuser"
    // _partyid_value: "e56be331-76fb-eb11-94ef-00224804735d"
    // _partyid_value@Microsoft.Dynamics.CRM.associatednavigationproperty: "partyid_contact"
    // _partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname: "contact"
    // _partyid_value@OData.Community.Display.V1.FormattedValue: "Caller 2013912445"

    'partyid_systemuser@odata.bind': string;
    'partyid_contact@odata.bind': string;

    constructor(data?: Partial<DynamicsActivityPartyModel>) {
        Object.assign(this, data);
    }

    public bindWithSystemUser(systemuserid: string) {
        if (systemuserid[0] === '{') {
            systemuserid = systemuserid.substr(1, systemuserid.length - 2);
        }
        this['partyid_systemuser@odata.bind'] = `/systemusers(${systemuserid})`;
    }

    public bindWithContact(contactid: string) {
        this['partyid_contact@odata.bind'] = `/contacts(${contactid})`;
    }
}
