import {AfterViewInit, Component, Inject, Input, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {WebRTCService} from 'src/app/core/InContact/web-rtc/web-rtc.service';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {ContactViewBaseModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ApplicationContextService} from 'src/app/core/_services/application-context.service';
import {SoftPhoneService} from 'src/app/sdk/counter-path/services/softphone/softphone.service';
import {DexieLogsService} from 'src/app/sdk/dexie/services';
import {IcSecurityProfileService} from 'src/app/sdk/incontact/services';

@Component({
    selector: 'app-incoming-call-contact-actions',
    templateUrl: './incoming-call-contact-actions.component.html',
    styleUrls: ['./incoming-call-contact-actions.component.scss'],
})
export class IncomingCallContactActionsComponent implements OnInit, AfterViewInit {
    @Input()
    contactVM: ContactViewBaseModel<any & ContactBase>;

    constructor(
        public softPhoneSrv: SoftPhoneService,
        public webRtcSrv: WebRTCService,
        public appContext: ApplicationContextService,
        private loaderSrv: NgxUiLoaderService,
        @Inject(IcSecurityProfileService) public securityProfileSrv: IcSecurityProfileService,
        @Inject(DexieLogsService) public dexieLogsSrv: DexieLogsService,
    ) {}

    ngOnInit(): void {
        this.securityProfileSrv.securityProfileBS.subscribe(sp => {
            // console.log(
            //     'permission for HideInboundHangup: ',
            //     sp?.permissions?.find(x => x.permissionKey === 'HideInboundHangup'),
            //     sp,
            // );
        });
    }

    ngAfterViewInit(): void {
        this.dexieLogsSrv
            .info('IncomingCallContactActionsComponent ngAfterViewInit', {
                initDone: this.webRtcSrv.initDone,
                showAnswerCall: this.webRtcSrv.showAnswerCall,
                isActive: !!this.contactVM?.contact?.isActive(),
                isDisconnected: !!this.contactVM?.contact?.isDisconnected(),
                showWebRTCAnsBtn: this.showWebRTCAnsBtn,
                contact: this.contactVM.contact?.rawData,
            })
            .then(res => {
                console.log('added', res);
            })
            .catch(err => {
                console.log('err', err);
            });
    }

    AnswerCall(callId) {
        this.softPhoneSrv.apiAnswerCall(callId, false);
    }

    AnswerCallWebRTC(contact: any) {
        this.webRtcSrv.answerCall(contact);
    }

    get showPreviewAnsBtn() {
        return (
            this.contactVM?.contact?.isPreview &&
            !this.contactVM?.contact?.isActive() &&
            !this.contactVM?.contact?.isDisconnected() &&
            !this.contactVM.contact.isNaturalCallRinging()
        );
    }

    get showWebRTCAnsBtn() {
        return this.webRtcSrv.showAnswerCall && !this.contactVM?.contact?.isActive() && !this.contactVM?.contact?.isDisconnected();
    }
    get showWebRTCRejectBtn() {
        return this.showWebRTCAnsBtn && !this.securityProfileSrv.hasPermission('HideInboundHangup', 'Hide');
    }

    AnswerPreview() {
        this.contactVM.contactService.acceptContact(this.contactVM?.contact).subscribe(
            res => {
                console.log('contact accepted: ', res);
            },
            err => {
                console.log('error in accepting contact: ', err);
            },
        );
    }

    endCall() {
        this.loaderSrv.start();
        this.appContext.inContactAPIService
            .endLeg()
            .subscribe()
            .add(() => {
                this.loaderSrv.stop();
            });
    }
}
