import {DynamicsActivityPartyModel} from '.';
import {DynamicsActivityPointerModel} from './dynamics-activity-pointer.model';

export class DynamicsEmailActivityModel extends DynamicsActivityPointerModel {
    public torecipients: string;
    public sender: string;
    public directioncode: boolean;

    public email_activity_parties: Array<DynamicsActivityPartyModel>;

    // 'emailsender_systemuser@data.bind': string;
    // 'emailsender_contact@data.bind': string;

    constructor(data?: Partial<DynamicsEmailActivityModel>) {
        super(data);
        Object.assign(this, data);
    }

    // public bindSenderWithSystemUser(systemuserid: string) {
    //     if (systemuserid[0] === '{') {
    //         systemuserid = systemuserid.substr(1, systemuserid.length - 2);
    //     }
    //     this['emailsender_systemuser@data.bind'] = `/systemusers(${systemuserid})`;
    // }

    // public bindSenderWithContact(contactid: string) {
    //     this['emailsender_contact@data.bind'] = `/contacts(${contactid})`;
    // }
}
