import {throwError as observableThrowError, BehaviorSubject, Observable} from 'rxjs';
import {Injectable, Inject} from '@angular/core';
import {ScreenAgentApi} from '../../api';

@Injectable()
export class ScreenAgentService {
    constructor(@Inject(ScreenAgentApi) public screenAgentApi: ScreenAgentApi) {}

    public async connectToScreenAgent(agentUuid: string) {
        return new Promise((resolve, reject) => {
            this.screenAgentApi.connectToAgent(agentUuid).subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    reject(err);
                },
            );
        });
    }

    public async disconnectToScreenAgent(agentUuid: string) {
        return new Promise((resolve, reject) => {
            this.screenAgentApi.disconnectToAgent(agentUuid).subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    reject(err);
                },
            );
        });
    }
}
