import {map} from 'rxjs/operators';
import {Injectable, Inject, Optional} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BaseApi} from 'src/app/sdk/core';
import * as config from './../../../config/config.json';

@Injectable()
export class ConfigService {
    configuations: BehaviorSubject<any> = new BehaviorSubject<any>({});
    configurationsLoaded = false;

    constructor(protected baseApi: BaseApi) {
        // console.log('test config', config);

        this.configuations.next((config as any).default);
        this.configurationsLoaded = true;

        // this.http
        //     .get('config.json').pipe(
        //     map(res => {
        //         this.configuations.next(res.json());
        //         this.configurationsLoaded = true;
        //     }))
        //     .subscribe();

        // this.baseApi.GET_Request<any>('config.json').subscribe(res => {
        //     console.log(res);
        //     this.configuations.next(res);
        //     this.configurationsLoaded = true;
        // });
    }

    getConfiguration(key) {
        return this.configuations.value[key];
        // return this.http.get('config.json').pipe(map(res => {
        //     this.configuations = res.json();
        //     return this.configuations[key];
        // }));
    }

    get ApiUrlUrl() {
        if (window.env === 'local') {
            return 'http://localhost:3030/api';
            // return 'https://frontlineagentconsole-v3-dev.azurewebsites.net/api';
        } else if (window.env === 'dev') {
            return 'https://frontlineagentconsole-v3-dev.azurewebsites.net/api';
        } else if (window.env === 'rc') {
            return 'https://frontlineagentconsole-v3-rc.azurewebsites.net/api';
        } else if (window.env === 'prod') {
            return 'https://facadmin.frontlineservicesinc.com/api';
        } else {
            return 'https://frontlineagentconsole-v3-dev.azurewebsites.net/api';
        }
    }
}
