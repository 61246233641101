import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import {DispositionModule} from './disposition/disposition.module';
import {ErrorModule} from './error/error.module';
import {StatusBarModule} from './status-bar/status-bar.module';

import {TransferActiveContactModalComponent} from './transfer-active-contact-modal/transfer-active-contact.component';
import {TinyMceEditorComponent} from './tiny-mce-editor/tiny-mce-editor.component';
// import { SpinnerComponent } from './spinner/spinner.component';
import {CurrentTicketComponent} from './current-ticket/current-ticket.component';

import {FilterPipe} from './pipes/filter.pipe';
import {ContactActionFilter, GroupByPipe} from './pipes/commonFilters';
import {IndicatorsFilterPipe} from './pipes/indicators.filter';
import {SafeHtmlPipe} from './pipes/safeHtml.pipe';
import {FocusDirective} from './directives/focus.directive';
// import {ToastyModule} from 'ng2-toasty';
// import {HotkeyModule, HotkeysService} from 'angular2-hotkeys';
// import {Ng2Bs3ModalModule} from 'ng2-bs3-modal/ng2-bs3-modal';
//import { ContactComponentsModule } from "./contact-components/contact-components.module"
import {ContactActionsComponent} from './contact-components/contact-actions/contact-actions.component';
import {ContactElevationComponent} from './contact-components/contact-actions/contact-elevation.component';
import {ContactTicketBoxComponent} from './contact-components/contact-ticket-box.component';
import {ContactItemComponent} from './contact-components/contact-item/contact-item.component';
import {ContactTagsInputComponent} from './contact-components/tags-inputbox.component';
import {ContactQuickRepliesComponent} from './contact-components/contact-quick-replies/contact-quick-replies.component';
import {TranslateModule} from '@ngx-translate/core';
import {InputTextModule} from 'primeng/inputtext';
import {ListboxModule} from 'primeng/listbox';
import {MenuModule} from 'primeng/menu';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {FormComponentsModules} from '../components/form/forms-components.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DropdownMenuComponent} from './components/dropdown-menu/dropdown-menu.component';
import {LbNgxDropdownModule} from '../plugins/lb-ngx-dropdown';
import {IncomingCallContactActionsComponent} from './contact-components/incoming-call-contact-actions/incoming-call-contact-actions.component';
import {AgentSettingsDialogComponent} from './modals/agent-settings-diloag/agent-settings-diloag.component';
import {ContactUserSelectionComponent} from './contact-components/contact-user-selection/contact-user-selection.component';
import {UserSelectionDialogComponent} from './modals/user-selection-dialog/user-selection-dialog.component';
import {DialogRef} from 'ngx-modialog-7';
import {TicketSelectionDialogComponent} from './modals/ticket-selection-dialog/ticket-selection-dialog.component';
import {SearchZendeskUsersDialogComponent} from './modals/search-zendesk-users-dialog/search-zendesk-users-dialog.component';

// export function createTranslateLoader(http: Http) {
//     return new TranslateStaticLoader(http, './assets/i18n', '.json');
// }

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ErrorModule,
        DispositionModule,
        StatusBarModule,
        // Ng2Bs3ModalModule,
        // TranslateModule.forRoot({
        //     provide: TranslateLoader,
        //     useFactory: createTranslateLoader,
        //     deps: [Http],
        // }),
        // ToastyModule.forRoot(),
        TranslateModule,
        InputTextModule,
        DropdownModule,
        MenuModule,
        ButtonModule,
        ListboxModule,

        BsDropdownModule.forRoot(),

        FormComponentsModules,

        LbNgxDropdownModule,
    ],
    declarations: [
        TransferActiveContactModalComponent,
        TinyMceEditorComponent,
        ContactActionsComponent,
        ContactElevationComponent,
        FilterPipe,
        GroupByPipe,
        CurrentTicketComponent,
        ContactTicketBoxComponent,
        ContactItemComponent,
        ContactUserSelectionComponent,

        IncomingCallContactActionsComponent,
        SafeHtmlPipe,
        ContactActionFilter,
        IndicatorsFilterPipe,
        ContactTagsInputComponent,
        ContactQuickRepliesComponent,

        DropdownMenuComponent,

        AgentSettingsDialogComponent,
        UserSelectionDialogComponent,
        TicketSelectionDialogComponent,
        SearchZendeskUsersDialogComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ErrorModule,
        DispositionModule,
        TinyMceEditorComponent,
        FilterPipe,
        GroupByPipe,
        SafeHtmlPipe,
        ContactActionFilter,
        IndicatorsFilterPipe,
        FocusDirective,
        ContactActionsComponent,
        ContactElevationComponent,
        CurrentTicketComponent,

        ContactItemComponent,
        ContactUserSelectionComponent,

        IncomingCallContactActionsComponent,
        ContactTicketBoxComponent /* , ContactComponentsModule */,
        BrowserModule,
        // ToastyModule,
        ContactTagsInputComponent,
        ContactQuickRepliesComponent,
        TranslateModule,

        InputTextModule,
        DropdownModule,
        MenuModule,
        ButtonModule,
        ListboxModule,

        BsDropdownModule,

        FormComponentsModules,

        DropdownMenuComponent,

        LbNgxDropdownModule,

        AgentSettingsDialogComponent,
        UserSelectionDialogComponent,
        TicketSelectionDialogComponent,
        SearchZendeskUsersDialogComponent,
    ],
    bootstrap: [],
})
export class SharedModule {}
