<div class="p-field text-field">
    <label [for]="fieldConfig.id">{{ label }}</label>
    <div class="p-inputgroup">
        <!-- {{ fieldConfig | json }} -->
        <span class="p-inputgroup-addon" *ngIf="fieldConfig.iconClass">
            <i [class]="fieldConfig.iconClass"></i>
        </span>
        <input
            pInputText
            [tabindex]="fieldConfig.tabindex"
            [type]="fieldConfig.type || 'text'"
            class="form-control"
            [id]="fieldConfig.id"
            [placeholder]="placeholder"
            [(ngModel)]="value"
            #element="ngModel"
            [name]="name"
            [required]="required"
        />
    </div>
</div>
