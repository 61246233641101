import {Subject} from 'rxjs';
import {AnyObject} from 'src/app/global';

import {DispositionModel} from './disposition.model';
import {SkillModel} from './skill.model';

export class ContactBase {
    public From: string;
    public ANI: string;
    public DNIS: string;

    public requireDisposition: boolean;
    public contactId: string;
    public startTime: Date;
    public lastStateChangeTime: Date;
    public skillName: string;
    public status: string;
    public skill: string;
    public finalState: boolean;
    public isInbound: boolean;
    public startTimeUTC: Date;
    public refusalTimeout: Date;
    public parentContactId: string;
    public omniGroupId: string;
    public masterId: string;
    public customData: string;
    public externalId: string;
    public label: string;
    public agentId: string;

    public contactType: 'Chat' | 'Email' | 'Call' | 'EmailContactEvent' | 'Voicemail' | 'WorkItem';
    public contactIcon: string;

    public ticketId: string;
    public timers: any = {};
    public skillInfo: SkillModel;
    public dispositions: DispositionModel[] = [];
    public appliedDisposition: DispositionModel;
    public appliedSecondaryDisposition: DispositionModel;
    public appliedDispositionComments: string;
    public ticketPayload: any = null;
    public dispositionSubmitted = false;

    public supportsPhoneElevation = false;
    public supportsEmailElevation = false;
    public tags = '';
    public tagObjects: any[] = [];
    public subStatus: string;
    public deliveryType: string;

    public ScreenPopUrl = '';
    public ScreenPopUrlVariables: Array<AnyObject> = null;

    public rawData: any = {};

    constructor(data: any) {
        const me = this;
        this.ANI = data.ANI;
        this.From = data.From;
        this.DNIS = data.DNIS;

        this.contactId = data.ContactID || data.ContactId;
        this.status = data.Status;
        this.startTime = data.StartTime;
        this.lastStateChangeTime = data.LastStateChangeTime;
        this.skill = data.Skill || data.SkillId;
        this.finalState = data.FinalState == 'True';
        this.isInbound = data.IsInbound == 'True';
        this.startTimeUTC = data.StartTimeUTC || data.StartTime;
        this.refusalTimeout = data.RefusalTimeout;
        this.parentContactId = data.ParentContactId;
        this.omniGroupId = data.OmniGroupId;
        this.masterId = data.MasterId || data.MasterID;
        this.customData = data.CustomData;
        this.externalId = data.ExternalId;
        this.label = data.Label;
        this.ticketId = '';
        this.ScreenPopUrl = data.ScreenPopUrl;
        this.ScreenPopUrlVariables = data.ScreenPopUrlVariables;

        try {
            this.ticketPayload = data.TicketPayload || data.WorkItemId ? JSON.parse(data.TicketPayload || data.WorkItemId) : undefined;
            /* if (!this.ticketPayload) {
                var objSetter = (prop) => {
                    prop = (prop + "").toLowerCase();
                    for (var p in data) {
                        if (data.hasOwnProperty(p) && prop == (p + "").toLowerCase()) {
                            this.ticketPayload = data[p];
                            break;
                        }
                    }
                }

                objSetter("TicketPayload");

                if (!this.ticketPayload) objSetter("WorkItemId");

            } */
        } catch (error) {
            console.error(error);
        }

        if (data.subStatus) {
            this.subStatus = data.subStatus;
        }

        this.rawData = data;
    }

    public get isPreview() {
        return this.status === 'Preview' || this.deliveryType === 'ClickToCall';
    }

    public setSkillInfo(data) {
        this.skillInfo = new SkillModel(data);
        this.skillName = this.skillInfo.skillName;
        if (this.skillInfo.dispositions) {
            this.dispositions = this.skillInfo.dispositions?.map(d => new DispositionModel(d)) ?? [];
        }
    }

    public transferDataFromExisting(contact: ContactBase) {
        if (contact) {
            if (contact.skillInfo) {
                this.skillInfo = contact.skillInfo;
                this.skillName = this.skillInfo.skillName;
            }
            this.ticketId = contact.ticketId;
            this.appliedDisposition = contact.appliedDisposition;
            this.appliedDispositionComments = contact.appliedDispositionComments;
            this.appliedSecondaryDisposition = contact.appliedSecondaryDisposition;
            this.dispositionSubmitted = contact.dispositionSubmitted;
            this.tags = contact.tags;
            this.tagObjects = contact.tagObjects;
        }
    }

    public isActive(): boolean {
        return this.status == 'Active';
    }

    public isNaturalCallRinging(): boolean {
        return this.status == 'NaturalCallRinging';
    }

    public isDisconnected(): boolean {
        return this.status == 'Disconnected' || this.status == 'Discarded';
    }

    public isIncoming(): boolean {
        return this.status == 'Incoming';
    }

    public isInterrupted(): boolean {
        return this.status == 'Interrupted';
    }

    canHaveDisposition = () => this.skillInfo && this.skillInfo.acwTypeId == 2;

    isMasterContact = () => this.contactId == this.masterId;

    public isHolding = () => this.status === 'Holding';
    public canHold = () => !this.isHolding() && !this.isInterrupted() && this.status != 'Masking' && !this.isIncoming();
    //public canEnd = () => !this.isDisconnected()
    public canEnd = () => !this.isHolding() && !this.isIncoming() && !this.isDisconnected() && !this.isInterrupted();
    public canTransfer = () => !this.isIncoming() && !this.isDisconnected();

    public isDispositionVisible() {
        return this.isDisconnected() && !this.finalState && this.skillInfo && this.skillInfo.acwTypeId == 2;
        // typeof this.skillInfo?.prevDisposition === 'undefined'
        // : this.isDisconnected() && !this.finalState && this.skillInfo?.prevDisposition;
        //(this.skillInfo.requireDisposition || (this.dispositions && this.dispositions.length > 0));
    }
    public isRequiredToBeAcceptedWhenIncoming(): boolean {
        return false;
    }
    public updateTimers(serverTimeOffset: number) {
        if (this.isDisconnected()) {
            return;
        }
        const currentDate = this.makeServerTime(serverTimeOffset);
        const startedTimeElapsed = new Date(currentDate.valueOf() - new Date(this.startTime).valueOf()).getTime();
        const lastUpdateTimeElapsed = new Date(currentDate.valueOf() - new Date(this.lastStateChangeTime).valueOf()).getTime();
        // selectedElapsedTime = this.getFormattedTime(dif);
        this.timers.startedTimeElapsed = this.getFormattedTime(startedTimeElapsed);
        this.timers.lastUpdateTimeElapsed = this.getFormattedTime(lastUpdateTimeElapsed);
        //console.log(this.timers);
    }
    protected makeServerTime(serverTimeOffset: number) {
        const currentDate = new Date(new Date().getTime() + serverTimeOffset);
        return currentDate;
    }

    protected getFormattedTime(duration: number): string {
        // duration = Math.abs(duration);

        const milliseconds = Math.floor((duration % 1000) / 100);
        const seconds = Math.floor((duration / 1000) % 60);
        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        const hours = Math.floor(duration / (1000 * 60 * 60)); //let hours = Math.floor (((duration/(1000*60*60))%24));

        const h = hours < 10 ? '0' + hours : hours;
        const m = minutes < 10 ? '0' + minutes : minutes;
        const s = seconds < 10 ? '0' + seconds : seconds;

        let returnStr;
        returnStr = m + ':' + s;

        if (hours !== 0) {
            returnStr = h + ':' + returnStr;
        }

        if (duration !== 0) {
            return returnStr;
        } else {
            return '';
        }
    }

    isOmniGroupContact(): boolean {
        return this.omniGroupId && this.omniGroupId != '0';
    }
}
