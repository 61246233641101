import {Injectable} from '@angular/core';
import {ContactServiceBase} from './contact.service.base';
import {VoiceMailModel} from '../_models/voice-mail.model';
import {ApplicationContextService} from './application-context.service';
import {Observable, throwError} from 'rxjs';
import {VoicemailContactViewmodel} from '../_models/_viewmodels/voicemail-contact-viewmodel';
import {ContactBase} from '../_models/contact-base';
import {WebRTCService} from '../InContact/web-rtc/web-rtc.service';

@Injectable()
export class VoiceMailContactService extends ContactServiceBase<VoiceMailModel, VoicemailContactViewmodel> {
    private playbackTimers: any = {};
    private legConnected = false;
    constructor(appContext: ApplicationContextService, public webRtcSrv: WebRTCService) {
        super(VoiceMailModel, VoicemailContactViewmodel as any, appContext);
        this.title = 'Voicemail Contacts';
        appContext.voicemailContactService = this;

        this.appContext.agentSessionService.legStatusLabel$.subscribe(status => {
            this.legConnected = status == 'Active';
            if (this.legConnected && this.currentContacts.value.length > 0) {
                this.play(this.currentContacts.value[0], 0).subscribe();
            }
        });

        this.currentContacts.subscribe(contacts => {
            if (contacts.length > 0 && contacts[0].isIncoming()) {
                this.webRtcSrv.playIncomingCallAudio();
            }
            if (contacts.length > 0 && (contacts[0].isActive() || contacts[0].isDisconnected())) {
                this.webRtcSrv.stopAudioPlay();
            }
        });
    }

    private clearVoicemailInterval(contactId: string) {
        if (this.playbackTimers[contactId]) {
            window.clearInterval(this.playbackTimers[contactId]);
        }
    }

    processPlaybackEvent(event: any) {
        this.clearVoicemailInterval(event.ContactID);
        this.currentContacts.value
            .filter((vmail, index) => vmail.contactId == event.ContactId)
            .map(vmail => {
                vmail.playBackPaused = event.PlayBackPaused == 'True';
                vmail.playBackPosition = event.PlayBackPosition;

                if (!vmail.playBackPaused) {
                    this.clearVoicemailInterval(vmail.contactId);
                    this.playbackTimers[vmail.contactId] = window.setInterval(() => {
                        vmail.playBackPosition++;
                        console.log(vmail.playBackPosition);
                    }, 1000);
                } else {
                    this.clearVoicemailInterval(vmail.contactId);
                }
            });
    }

    pause(contact: VoiceMailModel): Observable<VoiceMailModel> {
        this.clearVoicemailInterval(contact.contactId);
        return new Observable(r => {
            this.appContext.inContactAPIService.pauseVoiceMail(contact.contactId).subscribe(result => {
                r.next(result);
            });
        });
    }

    play(contact: VoiceMailModel, position?: number): Observable<VoiceMailModel> {
        this.clearVoicemailInterval(contact.contactId);
        return new Observable(r => {
            this.appContext.inContactAPIService.playVoiceMail(contact.contactId, false, position || 0).subscribe(result => r.next(result));
        });
    }

    skipBack(contact: VoiceMailModel): Observable<VoiceMailModel> {
        this.clearVoicemailInterval(contact.contactId);
        const newPos = contact.playBackPosition - 10 < 0 ? 0 : contact.playBackPosition - 10;
        return new Observable(obs => {
            this.play(contact, newPos).subscribe(v => {
                v.playBackPosition = newPos;
            });
        });
    }

    skipForward(contact: VoiceMailModel): Observable<VoiceMailModel> {
        this.clearVoicemailInterval(contact.contactId);
        const newPos = contact.playBackPosition + 10 > contact.fileDuration ? contact.fileDuration : contact.playBackPosition + 10;
        return new Observable(obs => {
            this.play(contact, newPos).subscribe(v => {
                v.playBackPosition = newPos;
            });
        });
    }

    transferContact(contact: ContactBase, data: any): Observable<ContactBase> {
        return new Observable(r => {
            this.appContext.inContactAPIService.transferVoiceMail(contact.contactId, data).subscribe(
                res => {
                    //this.messageService.setCallMessage.next("Chat has been transfered successfully to " + data.transferType);
                    r.next(contact);
                },
                err => throwError(err),
            );
        });
    }

    protected onContactActivated(contact: VoiceMailModel) {
        super.onContactActivated(contact);
        this.navigateTo(['active-contact']);
    }
}
