<div [class]="'lb-ngx-dropdown ' + side" #dropdownRoot>
    <div class="lb-ngx-dropdown-toggle">
        <ng-container #dropdownToggleBtnTemplate> </ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="menusTemplate" [ngTemplateOutletContext]="{items: items}"></ng-container>
</div>

<ng-template #menusTemplate let-items="items">
    <ul class="lb-ngx-dropdown-menu" role="menu">
        <li class="lb-ngx-dropdown-item" *ngFor="let menu of items" role="menuitem" #dropdownAction>
            <a role="link" href="javascript:void(0)" class="lb-ngx-dropdown-item-action" (click)="actionClick(dropdownAction, menu)">
                {{ menu.text }}

                <i *ngIf="menu.items" class="ml-10 fas fa-caret-right"></i>
            </a>

            <ng-container *ngIf="menu.items" [ngTemplateOutlet]="menusTemplate" [ngTemplateOutletContext]="{items: menu.items}"></ng-container>
        </li>
    </ul>
</ng-template>
