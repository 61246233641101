import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {catchError, finalize} from 'rxjs/operators';
import {of as observableOf, throwError} from 'rxjs';
import {integrationManagerService} from 'src/app/core/uac-crm-integration/integrationManager.service';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';
import {LocalStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {IcAuthService} from 'src/app/sdk/incontact/services';
import {AuthenticationService} from '../services/authentication.service';
import {IncomingContactService} from 'src/app/core/_services/incoming-contact.service';
import {AppToasterService} from 'src/app/plugins/ngx-toastr';
import {timeStamp} from 'console';
import {IcAgentSessionService} from 'src/app/sdk/incontact/services/agent-sessions/agent-session-events.service';

@Component({
    selector: 'app-login-step-one',
    templateUrl: './login-step-one.component.html',
    styleUrls: ['./login-step-one.component.scss'],
})
export class LoginStepOneComponent implements OnInit, AfterViewInit {
    constructor(
        private integrationManager: integrationManagerService,
        private loaderSrv: NgxUiLoaderService,
        private localStorageService: LocalStorageService,
        private router: Router,
        public authenticationSrv: AuthenticationService,
        public icAgentSessionSrv: IcAgentSessionService,
        private appToastrSrv: AppToasterService,
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {}

    Next() {
        this.loaderSrv.start();
        this.integrationManager
            .init(this.icAgentSessionSrv.agent.userName)
            .pipe(
                finalize(() => {
                    this.loaderSrv.stop();
                }),
                catchError((err: any) => {
                    if (err.status == 0) {
                        this.authenticationSrv.ProcessAPIError(0, 'Server is unavailable or there is a network problem.');
                    }
                    return throwError(err.error);
                }),
            )
            .subscribe(
                result => {
                    this.loaderSrv.stop();
                    this.authenticationSrv.agentPublicProfile = result;
                    if (!result.company) {
                        this.authenticationSrv.ProcessAPIError(401, 'Failed to login on UAC server. Either server is unavilable or check your user profile.');
                        return null;
                    }

                    this.localStorageService.store('agentPublicProfile', {
                        ...result,
                    });

                    if (result?.company?.inContactSettings) {
                        const icSettings = result.company.inContactSettings;

                        InContactApiSettings.agentSettings = result.settings;
                        InContactApiSettings.useCompanySSOSettings = result.useCompanySSOSettings;
                        InContactApiSettings.setSettings = icSettings;

                        this.localStorageService.store('autoReconnect', InContactApiSettings.autoReconnect);

                        if (InContactApiSettings.isSSOLogin) {
                            this.authenticationSrv.LoginWithSSO();
                        } else {
                            console.log('navigating to step two');
                            this.router.navigate(['authentication', 'login-step-two'], {
                                // queryParams: {
                                //     type: 'code',
                                // },
                            });
                        }
                    }
                },
                apiError => {
                    this.loaderSrv.stop();
                    // let body = apiError.json();
                    // return null;
                    this.appToastrSrv.show('ERROR', 'Login Error', apiError.error?.message).subscribe();
                },
            );
    }
}
