import {Injectable} from '@angular/core';
import {InContactAPIService} from '../InContact/incontact-api.service';

@Injectable()
export class RunAppService {
    private openedWindows: Record<string, Window> = {};

    constructor(private inContactApiService: InContactAPIService) {}

    buildCustomForm(contactId: string, rawHtml: string, completedFunction: any): Window {
        const params =
            'toolbar=0,location=0,menubar=0,directories=0,status=0,scrollbars=1,resizable=1,height=400,width=400';
        const customFormHtml = `<html><head><title></title></head><body><form id=\'icForm\'><input type=\'hidden\' name=\'contactId\' value=\'${contactId}\' class=\'icInfo\'><input type=\'hidden\' name=\'sender\' value=\'${contactId}\' class=\'icInfo\'>${rawHtml}</form></body></html>`;

        let icForm: HTMLFormElement;
        let customFormWindow: Window;

        const onFormLoad = (e: Event) => {
            customFormWindow.document.body.innerHTML = customFormHtml;
            icForm = customFormWindow.document.querySelector('#icForm') as HTMLFormElement;

            icForm.addEventListener('submit', onSubmitFunction, false);
        };

        const onSubmitFunction = (event: Event) => {
            // Find the form and parse out the input fields.
            let element: any;
            let elementIndex: number;
            let elementKey: string;
            const formElement: HTMLFormElement = event.target as HTMLFormElement;
            const rawDataMap = {};
            let selectedIndex: any;
            let valueString: string;

            // Prevent the default functionality for onSubmit
            event.preventDefault();

            // loop through all elements in the form to get values
            for (elementIndex = formElement.elements.length - 1; elementIndex >= 0; elementIndex--) {
                element = formElement.elements[elementIndex] as any;

                if (element.name !== 'contactId' && element.name !== 'sender') {
                    elementKey = element.name || element.id;

                    switch (element.type) {
                        case 'submit':
                        case 'text':
                        case 'textarea':
                        case 'password':
                        case 'hidden':
                            rawDataMap[elementKey] = element.value;
                            break;
                        case 'radio':
                        case 'checkbox':
                            if (element.checked) {
                                // Check to see if we already have a value for this key, if we do update the dictionary and comma delimit the values.
                                if (rawDataMap[elementKey]) {
                                    rawDataMap[elementKey] = element.value + ',' + rawDataMap[elementKey];
                                } else {
                                    rawDataMap[elementKey] = element.value;
                                }
                            }

                            break;
                        case 'select-one':
                            element;
                            if (element.options.length) {
                                // Try and find the selected option.
                                for (selectedIndex = 0; selectedIndex < element.options.length; selectedIndex++) {
                                    if (element.options[selectedIndex].selected) {
                                        rawDataMap[elementKey] = element.options[selectedIndex].value;

                                        break; // Break out of the loop once we have found the single selected item.
                                    }
                                }
                            }

                            break;
                        case 'select-multiple':
                            // Find each of the selected options and append. (Comma delimited.)
                            valueString = '';

                            for (selectedIndex = 0; selectedIndex < element.options.length; selectedIndex++) {
                                if (element.options[selectedIndex].selected) {
                                    if (valueString.length > 0) {
                                        valueString += ',';
                                    }

                                    valueString += element.options[selectedIndex].value;
                                }
                            }

                            rawDataMap[elementKey] = valueString;

                            break;
                    }
                }
            }

            // The API expects a '|' delimited string for each key/value combination. E.g. 'checkbox1=yes|textbox1=hello|multiSelect1=Option1,Option2'
            completedFunction(
                contactId,
                Object.keys(rawDataMap)
                    .map(function(key) {
                        return key + '=' + rawDataMap[key];
                    })
                    .join('|'),
            );
            this.handleRunAppEvent({ContactId: contactId, ActionType: 'CLOSE'});
        };

        customFormWindow = window.open('', '_blank', params);
        customFormWindow.onload = onFormLoad;

        if (!customFormWindow.document.forms[0]) {
            customFormWindow.document.body.innerHTML = customFormHtml;
            icForm = customFormWindow.document.querySelector('#icForm') as HTMLFormElement;

            icForm.addEventListener('submit', onSubmitFunction, false);
        }

        return customFormWindow;
    }
    /* TODO:
    this method and it's references need to be replaced by /agent-sessions/{sessionId}/interactions/{contactId}/custom-data service call
    ref: https://developer.niceincontact.com/API/AgentAPI#/Sessions/Post%20Custom%20Data
    */
    placeHolderCustomDataServiceCall = (contactId: string, data: string) => {
        console.log({contactId, data});
    };

    handleRunAppEvent(runAppEvent: any) {
        const ContactId: string = runAppEvent.ContactID || runAppEvent.ContactId;
        switch (runAppEvent.ActionType) {
            case 'ShowCustomForm':
                //this.processSpawnScriptIndicator(indicator);
                const win = this.buildCustomForm(
                    ContactId,
                    runAppEvent.ActionValue,
                    this.inContactApiService.sendCustomData.bind(this.inContactApiService),
                );
                this.openedWindows[ContactId] = win;
                break;
            case 'OpenURL':
                //this.processSpawnScriptIndicator(indicator);
                const win1 = window.open(runAppEvent.ActionValue);
                this.openedWindows[ContactId] = win1;
                break;
            case 'CLOSE':
                //this.processOpenUrlIndicator({ ActionUri: indicator.action });
                const winc = this.openedWindows[ContactId];
                if (winc) {
                    winc.close();
                }
                delete this.openedWindows[ContactId];
                break;
        }
    }
}
