export class SkillModel {
    public acceleration: string;
    public acwTypeId: number;
    public agentRestTime: string;
    public agentTypingIndicator: string;
    public agentless: string;
    public agentlessPorts: string;
    public allowSecondaryDisposition: string;
    public callSuppressionScriptId: string;
    public callerIdOverride: string;
    public campaignId: string;
    public campaignName: string;
    public chatWarningThreshold: string;
    public countOtherAbandons: string;
    public countReskillHours: string;
    public countShortAbandons: string;
    public displayThankyou: string;
    public emailBccAddress: string;
    public emailFromAddress: string;
    public emailFromEditable: boolean;
    public emailParking: boolean;
    public enableShortAbandon: string;
    public initialPriority: string;
    public interruptible: string;
    public isActive: string;
    public isOutbound: string;
    public isRunning: string;
    public makeTranscriptAvailable: string;
    public maxPriority: string;
    public maxSecondsACW: string;
    public mediaTypeId: string;
    public mediaTypeName: string;
    public messageTemplateId: string;
    public minWFIAgents: string;
    public minWorkingTime: string;
    public notes: string;
    public outboundStrategy: string;
    public patronTypingPreview: string;
    public popThankYou: string;
    public popThankYouURL: string;
    public priorityBlending: string;
    public requireDisposition: string;
    public reskillHours: string;
    public reskillHoursName: string;
    public screenPopDetail: string;
    public screenPopTriggerEvent: string;
    public scriptDisposition: string;
    public scriptId: string;
    public scriptName: string;
    public serviceLevelGoal: string;
    public serviceLevelThreshold: string;
    public shortAbandonThreshold: string;
    public skillId: string;
    public skillName: string;
    public smsTransportCode: string;
    public smsTransportCodeId: string;
    public stateIdACW: string;
    public stateNameACW: string;
    public thankYouLink: string;
    public transcriptFromAddress: string;
    public useCustomScreenPops: string;
    public useScreenPops: string;

    public preConnectCpaEnabled: boolean;
    public prevDisposition: boolean;
    public prevRequeue: boolean;
    public prevReschedule: boolean;
    public prevSnooze: boolean;

    public dispositions: any[];

    constructor(data?: any) {
        Object.assign(this, data);
        // if (data) {
        //     this.acceleration = data.acceleration;
        //     this.acwTypeId = data.acwTypeId;
        //     this.agentRestTime = data.agentRestTime;
        //     this.agentTypingIndicator = data.agentTypingIndicator;
        //     this.agentless = data.agentless;
        //     this.agentlessPorts = data.agentlessPorts;
        //     this.allowSecondaryDisposition = data.allowSecondaryDisposition;
        //     this.callSuppressionScriptId = data.callSuppressionScriptId;
        //     this.callerIdOverride = data.callerIdOverride;
        //     this.campaignId = data.campaignId;
        //     this.campaignName = data.campaignName;
        //     this.chatWarningThreshold = data.chatWarningThreshold;
        //     this.countOtherAbandons = data.countOtherAbandons;
        //     this.countReskillHours = data.countReskillHours;
        //     this.countShortAbandons = data.countShortAbandons;
        //     this.displayThankyou = data.displayThankyou;
        //     this.emailBccAddress = data.emailBccAddress;
        //     this.emailFromAddress = data.emailFromAddress;
        //     this.emailFromEditable = data.emailFromEditable;
        //     this.emailParking = data.emailParking;
        //     this.enableShortAbandon = data.enableShortAbandon;
        //     this.initialPriority = data.initialPriority;
        //     this.interruptible = data.interruptible;
        //     this.isActive = data.isActive;
        //     this.isOutbound = data.isOutbound;
        //     this.isRunning = data.isRunning;
        //     this.makeTranscriptAvailable = data.makeTranscriptAvailable;
        //     this.maxPriority = data.maxPriority;
        //     this.maxSecondsACW = data.maxSecondsACW;
        //     this.mediaTypeId = data.mediaTypeId;
        //     this.mediaTypeName = data.mediaTypeName;
        //     this.messageTemplateId = data.messageTemplateId;
        //     this.minWFIAgents = data.minWFIAgents;
        //     this.minWorkingTime = data.minWorkingTime;
        //     this.notes = data.notes;
        //     this.outboundStrategy = data.outboundStrategy;
        //     this.patronTypingPreview = data.patronTypingPreview;
        //     this.popThankYou = data.popThankYou;
        //     this.popThankYouURL = data.popThankYouURL;
        //     this.priorityBlending = data.priorityBlending;
        //     this.requireDisposition = data.requireDisposition;
        //     this.reskillHours = data.reskillHours;
        //     this.reskillHoursName = data.reskillHoursName;
        //     this.screenPopDetail = data.screenPopDetail;
        //     this.screenPopTriggerEvent = data.screenPopTriggerEvent;
        //     this.scriptDisposition = data.scriptDisposition;
        //     this.scriptId = data.scriptId;
        //     this.scriptName = data.scriptName;
        //     this.serviceLevelGoal = data.serviceLevelGoal;
        //     this.serviceLevelThreshold = data.serviceLevelThreshold;
        //     this.shortAbandonThreshold = data.shortAbandonThreshold;
        //     this.skillId = data.skillId;
        //     this.skillName = data.skillName;
        //     this.smsTransportCode = data.smsTransportCode;
        //     this.smsTransportCodeId = data.smsTransportCodeId;
        //     this.stateIdACW = data.stateIdACW;
        //     this.stateNameACW = data.stateNameACW;
        //     this.thankYouLink = data.thankYouLink;
        //     this.transcriptFromAddress = data.transcriptFromAddress;
        //     this.useCustomScreenPops = data.useCustomScreenPops;
        //     this.useScreenPops = data.useScreenPops;
        //     if (data.dispositions) this.dispositions = data.dispositions;
        // }
    }
}
