<div class="page-heading" *ngIf="false">
    <ul class="nav nav-tabs">
        <li
            *ngFor="let chatRoom of contacts"
            role="presentation"
            [ngClass]="{unread: activeViewModel.contact.unread,  btndefault: !activeViewModel.contact.unread, active:activeViewModel.contact.selected }"
        >
            <a (click)="showChatRoom(chatRoom)">{{chatRoom.skillName}}<br />{{chatRoom.timers.startedTimeElapsed}}</a>
        </li>
    </ul>

    <a role="button" href="javascript:void(0)" *ngIf="newButtonEnabled" class="btn btn-success btn-sm2" (click)="addChat()">New Chat </a>
    &nbsp;
</div>
<div class="people-list hidden-xs col-md-12 nopadding" id="people-list">
    <ul class="list py-5">
        <li class="clearfix" *ngFor="let chatRoom of contacts" [ngClass]="{active: chatRoom.contact.isActive(), unread: chatRoom.contact.unread}">
            <div class="about">
                <div class="name mr-5">
                    <a (click)="chatService.activateContact(chatRoom.contact).subscribe()" href="javascript:;">{{chatRoom.contact.skillName}}</a>
                </div>

                <div class="status">{{chatRoom.contact.timers.startedTimeElapsed}}</div>
            </div>
        </li>
    </ul>
</div>
<div class="chat col-xs-12 nopadding">
    <div class="chat-header clearfix">
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" *ngIf="false" alt="avatar" />

        <div style="display: flex; flex-direction: column">
            <div class="chat-about" style="display: flex; flex-grow: 1">
                <div *ngIf="false">
                    <i class="fa fa-{{activeViewModel.contactIcon}} fa-3x pull-left hidden-xs"></i>
                    <i class="fa fa-{{activeViewModel.contactIcon}} fa-2x pull-left hidden-md hidden-lg hidden-xl"></i>
                </div>
                <div style="flex-grow: 1; min-width: 200px">
                    <div class="chat-with"><i class="fa fa-{{activeViewModel.contactIcon}}"></i> {{activeViewModel.contact.skillName}}</div>
                    <div style="clear: both"></div>
                </div>
                <div style="flex-shrink: 100">
                    <contact-ticket-box [contactViewModel]="activeViewModel"> </contact-ticket-box>
                </div>
            </div>
            <div style="display: flex; flex: 1">
                <div>
                    <!-- <typeahead [(ngModel)]="stateList" [typeAheadSetup]="typeAheadSetup"></typeahead> -->
                    <!-- <input type="text" class="chat-typeahead" placeholder="Quick Replies"> -->
                    <app-contact-quick-replies
                        class="form-control p-pt-1"
                        [contact]="activeViewModel.contact"
                        (onQuickReplyInserting)="handleOnQuickReplyInserting($event)"
                    ></app-contact-quick-replies>
                </div>
                <div style="flex: 1"></div>
                <app-contact-actions displayStyle="small" [contactVM]="activeViewModel" slot="detail_header"> </app-contact-actions>
                <app-contact-actions displayStyle="small" [contactVM]="activeViewModel" slot="footer"> </app-contact-actions>
            </div>
            <div style="flex: 1">
                <app-contact-actions
                    displayStyle="small"
                    [contactVM]="activeViewModel"
                    class="pull-right"
                    slot="elevations"
                    [showPredisposition]="true"
                    [dropdownMargin]="-250"
                >
                </app-contact-actions>
            </div>
        </div>
        <div class="chat-list hidden-sm hidden-md hidden-lg hidden-xl" *ngIf="contacts.length > 1">
            <ul class="nav nav-pills">
                <li *ngFor="let chat of contacts" [ngClass]="{active: chat.contact.isActive(), unread: chat.contact.unread}">
                    <a (click)="chatService.activateContact(chat.contact).subscribe()" href="javascript:;"
                        >{{chat.contact.chatMessages.length}} | {{chat.contact.timers.startedTimeElapsed}}</a
                    >
                </li>
            </ul>
            <div *ngIf="activeViewModel.OmniGroupRelatedContacts.length > 0">
                <ul style="display: flex; list-style-type: none; padding: 5px; margin: 0px">
                    <li *ngFor="let groupContact of activeViewModel.OmniGroupRelatedContacts">
                        <app-contact-item [contactVM]="groupContact" mode="related"></app-contact-item>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- end chat-header -->

    <div class="chat-history" [ngClass]="{disconnected: activeViewModel.contact.isDispositionVisible()}" #chatPanel>
        <ul>
            <!-- <pre>{{activeViewModel.contact | json}}</pre> -->
            <ng-container *ngFor="let message of activeViewModel.contact.chatMessages">
                <li
                    *ngIf="!!message.PartyType && !!message.Text"
                    [ngClass]="{'clearfix': message.isAgentMessage()}"
                    class=" {{message.PartyType.toLowerCase()}}-message"
                >
                    <div
                        class="message"
                        [ngClass]="{'other-message': !message.isAgentMessage(), 'my-message': message.isAgentMessage(), 'float-right': message.isAgentMessage()}"
                    >
                        <div class="message-data" [ngClass]="{'align-right': message.isAgentMessage()}">
                            <span class="message-data-name">{{message.Label}}</span>
                            <span class="message-data-time mr-5">({{message.TimeStamp | date: 'hh:mm aa'}})</span>
                            <!-- <i class="fa fa-circle" [ngClass]="{'me': message.isAgentMessage(), 'online': !message.isAgentMessage()}"></i> -->
                        </div>
                        <div [innerHTML]="message.Text | safeHtml"></div>
                    </div>
                </li>
            </ng-container>

            <li *ngIf="ChatPatronTypingStatus.IsTyping == 'True' && ChatPatronTypingStatus.RoomId == activeViewModel.contact.roomId" class="typing">
                <i class="fa fa-circle online"></i>
                <!-- <i class="fa fa-circle online" style="color: #AED2A6"></i>
                <i class="fa fa-circle online" style="color:#DAE9DA"></i> -->
            </li>
        </ul>
    </div>
    <!-- end chat-history -->

    <div class="chat-message clearfix">
        <form *ngIf="!activeViewModel.contact.isDispositionVisible()" #chatForm="ngForm" (ngSubmit)="sendMessage()">
            <div class="textarea-holder">
                <textarea
                    name="message-to-send"
                    [(ngModel)]="message"
                    name="messageText"
                    id="message-to-send"
                    placeholder="Type your message"
                    (keypress)="sendTypingStatus()"
                    (keyup.enter)="sendMessage()"
                    rows="2"
                ></textarea>
            </div>

            <button type="submit">Send</button>
        </form>
        <active-contact-disposition
            *ngIf="activeViewModel.contact.isDispositionVisible()"
            [contact]="activeViewModel.contact"
            [contactService]="chatService"
            [viewModel]="activeViewModel"
        >
        </active-contact-disposition>
    </div>
    <!-- end chat-message -->
</div>
<!-- end chat -->
