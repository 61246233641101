<!-- <ng-template #diloag> -->
<div class="modal-header">
    <button type="button" class="close" (click)="dialog.close()" data-dismiss="modal">&times;</button>
    <h4 class="modal-title">Agent Settings</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-xs-12">
            <div class="form-group">
                <label for="">Auto Accept Inbound Calls</label>
                <div>
                    <span
                        ><button
                            type="button"
                            class="btn"
                            (click)="onAutoAccept('No')"
                            [ngClass]="autoAccept == 'No' ? 'btn-primary' : 'btn-default'"
                            [disabled]="!showAutoAcceptSetting"
                        >
                            No
                        </button></span
                    >
                    <span>
                        <button
                            type="button"
                            class="btn"
                            (click)="onAutoAccept('Yes')"
                            [ngClass]="autoAccept == 'Yes' ? 'btn-primary' : 'btn-default'"
                            [disabled]="!showAutoAcceptSetting"
                        >
                            Yes
                        </button>
                    </span>
                </div>
                <span class="save-msg" *ngIf="acceptSaved">Setting Saved <i class="far fa-check-circle"></i></span>
            </div>
        </div>

        <div class="col-xs-12">
            <div class="form-group">
                <label for="#RingTone">Select Ringtone</label>
                <div class="mb-5">
                    <select class="form-control ringtone-select" name="Ringtone" id="RingTone" [(ngModel)]="ringtone" (change)="RingtoneChanged()">
                        <option value="ring1">Ring 1</option>
                        <option value="ring2">Ring 2</option>
                        <option value="ring3">Ring 3</option>
                        <option value="bell">Ring 4</option>
                    </select>
                    <span (click)="Play()">
                        <i class="glyphicon glyphicon-play"></i>
                    </span>
                </div>
                <span class="save-msg" *ngIf="ringSaved">Setting Saved <i class="far fa-check-circle"></i></span>
            </div>
        </div>

        <div class="col-xs-12">
            <div class="form-group">
                <label for="">Ringtone Volume ({{ volume }})</label>
                <input type="range" min="1" max="10" [(ngModel)]="volume" (change)="volumeChange()" />
            </div>
        </div>
    </div>
</div>
<!-- </ng-template> -->
