import { Subscription } from 'rxjs';

export class MySubscriptionHandler {

    protected subscriptions$: Array<Subscription> = [];

    set addSubscription$(val: Subscription) {
        this.subscriptions$.push(val);
    }

    constructor() {
    }

    unsubscribeAllSubscriptions() {
        if (this.subscriptions$ && this.subscriptions$.length > 0) {
            for (const sub of this.subscriptions$) {
                sub.unsubscribe();
            }
            this.subscriptions$.splice(0, this.subscriptions$.length);
        }
    }

}
