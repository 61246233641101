import {Component, OnInit, HostListener, EventEmitter, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CallbackService} from '../../../core/_services/callbacks.service';
import {InContactAPIService} from '../../../core/InContact/incontact-api.service';
import {IcAgentSessionService} from '../../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {ErrorService} from '../../../core/_services/error.service';
import {ErrorModel} from '../../../core/_models/error.model';
// import {ModalComponent} from 'ng2-bs3-modal/ng2-bs3-modal';
import {Utility} from '../../../shared/utility.service';
import moment from 'moment';
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {DialogRef, overlayConfigFactory} from 'ngx-modialog-7';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
// import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-create-scheduled-callbacks-form',
    templateUrl: './callbacks-form.component.html',
    styleUrls: ['./callbacks-form.component.css'],
})
export class CreateScheduledCallbacksFormComponent implements OnInit {
    public timezones: any[] = [];
    public outboundSkills: any[] = [];
    public model: any = {displayTimezone: {}};
    displayTimezone = '';
    public deleteCommitmentId = 1;
    public deleteCommitmentNotes = '';
    constructor(
        private agentSessionEventsService: IcAgentSessionService,
        private callbackService: CallbackService,
        private incontactApiService: InContactAPIService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private errorService: ErrorService,
        private dialogSrv: DialogService,
    ) {}

    @ViewChild('removeDialog') removeDialog: TemplateRef<any>;
    removeDialogRef: DialogRef<any>;

    daylightTimezones: any[] = [
        {
            displayName: '(GMT-05:00) Central Daylight Time',
            offset: '-05:00',
            standardName: 'Central Daylight Time',
        },
        {
            displayName: '(GMT-04:00) Eastern Daylight Time',
            offset: '-04:00',
            standardName: 'Eastern Daylight Time',
        },
        {
            displayName: '(GMT-06:00) Mountain Daylight Time',
            offset: '-06:00',
            standardName: 'Mountain Daylight Time',
        },
        {
            displayName: '(GMT-07:00) Pacific Daylight Time',
            offset: '-07:00',
            standardName: 'Pacific Daylight Time',
        },
        {
            displayName: '(GMT-03:00) Atlantic Daylight Time',
            offset: '-03:00',
            standardName: 'Atlantic Daylight Time',
        },
    ];
    ngOnInit() {
        const agent = this.agentSessionEventsService.loadAgent();

        if (agent && agent.settings) {
            this.deleteCommitmentId = agent.settings.deleteCommitmentId;
        }

        const m = moment(new Date());

        const tempDt = m.add(1, 'hour'); //new Date();
        //tempDt.setHours(tempDt.getHours() + (tempDt.getMinutes() > 45 ? 2 : 1));

        //var formattedDateData = toFormattedDate(tempDt);

        this.model = {
            minute: tempDt.format('mm'),
            hour: tempDt.format('hh'),
            ampm: tempDt.format('A'),
            scheduleType: 'Me',
            date: tempDt.format('YYYY-MM-DD'),
            timezone: m.format('Z'),
            displayTimezone: {},
        };

        this.incontactApiService.getTimezones().subscribe((resp: any[]) => {
            this.timezones = resp;
            this.timezones.push(...this.daylightTimezones);
            const localTimezoneName = this.getTimezoneName();
            //localTimezoneName = localTimezoneName.replace('Daylight', 'Standard').trim();
            this.timezones
                .filter(tz => tz.offset == this.model.timezone.replace('+', ''))
                .filter(tz => `${tz.standardName}`.toLocaleLowerCase() == localTimezoneName.toLocaleLowerCase())
                //
                .map(tz => {
                    //if (`${tz.standardName}`.toLocaleLowerCase() == localTimezoneName.toLocaleLowerCase()) {
                    this.displayTimezone = tz.standardName;
                    //}
                });
            if (`standardName`.length == 0) {
                this.timezones.filter(tz => tz.offset == this.model.timezone).map(tz => (this.displayTimezone = tz));
            }
        });
        this.agentSessionEventsService.agentOutboundSkills.subscribe(skills => {
            this.outboundSkills = skills.filter(
                e => e.isActive === 'True' && e.isSkillActive === 'True' && e.mediaTypeId === '4' && e.isNaturalCalling === 'False',
            );
            if (this.outboundSkills.length > 0) {
                this.model.skillId = this.outboundSkills[0].skillId;
            }
        });
        this.activatedRoute.params.subscribe(data => {
            if (!data.id) {
                return;
            }
            const results = this.callbackService.callbacks.value.find(cb => cb.callbackId == data.id);
            if (results) {
                const obj = results;
                const cbMoment = moment(obj.callbackTime);

                //let timezoneDate = new Date(new Date(obj.callbackTime).toString());

                this.model.firstName = obj.firstName;
                this.model.lastName = obj.lastName;
                this.model.skillId = obj.skillId;
                this.model.date = cbMoment.format('YYYY-MM-DD');
                this.model.notes = obj.notes;
                this.model.agentId = obj.agentId;
                this.model.phoneNumber = obj.dialNumber;
                this.model.ampm = cbMoment.format('A');
                this.model.hour = cbMoment.format('hh');
                this.model.minute = cbMoment.format('mm');
                this.model.callbackId = obj.callbackId;
                if (obj.target == 'A') {
                    this.model.scheduleType = 'Me';
                } else {
                    this.model.scheduleType = 'Skill';
                }
            }
        });
    }
    setScheduleType(stype: string) {
        this.model.scheduleType = stype;
    }
    setHour(hr: string) {
        this.model.hour = hr;
    }

    setMinute(m: string) {
        this.model.minute = m;
    }
    setAMPM(m: string) {
        this.model.ampm = m;
    }

    saveSchedule() {
        const data: any = {};
        let hour = parseInt(this.model.hour);
        if (this.model.ampm == 'PM') {
            if (hour < 12) {
                hour = hour + 12;
            }
        } else if (this.model.ampm == 'AM') {
            if (hour == 12) {
                hour = 0;
            }
        }
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        const formattedMinute = `${this.model.minute}`.length == 1 ? `0${this.model.minute}` : `${this.model.minute}`;

        const formattedTimezone = (this.model.timezone + '').indexOf('-') == -1 ? `+${this.model.timezone}` : `${this.model.timezone}`;

        data.firstName = this.model.firstName;
        data.lastName = this.model.lastName;
        data.phoneNumber = Utility.cleanPhoneNumber(this.model.phoneNumber);
        data.scheduleDate = `${this.model.date}T${formattedHour}:${formattedMinute}:00${this.model.timezone}`;
        data.skillId = this.model.skillId;
        if (this.model.notes) {
            data.notes = this.model.notes;
        }
        if (this.model.scheduleType == 'Me') {
            data.targetAgentId = this.agentSessionEventsService.agentId.value;
        } else {
        }

        if (this.model.callbackId) {
            this.incontactApiService.updateCallback(this.model.callbackId, data).subscribe(
                res => {
                    //console.log(res);
                    this.router.navigate(['/callbacks']);
                },
                err => {
                    const errorModel = new ErrorModel(err.status, err.statusText);
                    this.errorService.onAPIError(errorModel);
                },
            );
        } else {
            this.incontactApiService.createCallback(data).subscribe(
                res => {
                    //console.log(res);
                    this.router.navigate(['/callbacks']);
                },
                err => {
                    const errorModel = new ErrorModel(err.status, err.statusText);
                    this.errorService.onAPIError(errorModel);
                },
            );
        }
    }

    getTimezoneName() {
        const today = new Date();
        const short = today.toLocaleDateString(undefined);
        const full = today.toLocaleDateString(undefined, {timeZoneName: 'long'});

        // Trying to remove date from the string in a locale-agnostic way
        const shortIndex = full.indexOf(short);
        if (shortIndex >= 0) {
            const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

            // by this time `trimmed` should be the timezone's name with some punctuation -
            // trim it from both sides
            return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
        } else {
            // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
            return full;
        }
    }

    onTimezoneSelected($event) {
        //console.log(arguments);
        console.log(this.displayTimezone);
        this.timezones
            .filter(tz => `${tz.standardName}`.toLowerCase() == `${this.displayTimezone}`.toLowerCase())
            .map(tz => {
                this.model.timezone = tz.offset;
            });
    }

    askRemove() {
        // this.removeDialog.open();
        this.removeDialogRef = this.dialogSrv.openModal(
            this.removeDialog,
            overlayConfigFactory({
                dialogClass: 'modal-dialog',
            }),
        );
    }

    removeCommitment($event) {
        $event.preventDefault();
        this.incontactApiService.removeCallback(this.model.callbackId, this.deleteCommitmentNotes).subscribe(r => {
            this.deleteCommitmentNotes = '';
            this.router.navigate(['callbacks']);
            this.removeDialogRef.close();
        });
    }
}
