import {NgModule} from '@angular/core';

import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {EmailRoutingModule} from './email.routing';
import {EmailInboundComponent} from './email-inbound/email-inbound.component';
// import { QueueService }     from './call-history.service';

import {SharedModule} from '../../shared/shared.module';
import {UacCrmIntegrationModule} from '../../core/uac-crm-integration/uac-crm-integration.module';
//import { TinyMceEditorComponent } from '../../shared/tiny-mce-editor/tiny-mce-editor.component';
import {TinyMceDirective} from '../../shared/directives/tinymce.directive';

@NgModule({
    imports: [BrowserModule, FormsModule, EmailRoutingModule, SharedModule, UacCrmIntegrationModule],
    declarations: [EmailInboundComponent, TinyMceDirective],
    exports: [EmailInboundComponent],
    providers: [],
})
export class EmailModule {}
