import {Observable, Subject, BehaviorSubject} from 'rxjs';
import {integrationServicesContainer} from './integrationServicesContainer.service';
// import {LocalStorageService, SessionStorageService} from 'ng2-webstorage';

import {CurrentContact} from '../.././core/_models/current-contact.model';
import {InContactAPIService} from '../InContact/incontact-api.service';
import {IncomingContactService} from '../_services/incoming-contact.service';
import {CallContactService} from '../_services/call-contact.service';
import {LocalStorageService} from 'ngx-webstorage';
import {CrmApplicationModel} from 'src/app/sdk/fac-crm/models';

export class CrmIntegrationService {
    selectedCrm: any = null;
    handledContacts: any = {};

    // private localStorageService: LocalStorageService;

    public ConfigurationMap = {
        CallContactEvent_Inbound: {
            settingSectionName: 'inboundCall',
            mappingSectionName: 'inboundCall',
            incontactSearchField: 'ANI',
            userTitle: 'Caller',
            isCallContact: true,
            statusField: 'Status',
            activeStatusValue: 'Active',
            contactTitle: 'Inbound Call',
            addCustomFields: this.addCallCustomFields,
            isProcessable: this.isInboundCallContactProcessable,
        },
        CallContactEvent_Outbound: {
            settingSectionName: 'outboundCall',
            mappingSectionName: 'outboundCall',
            incontactSearchField: 'DNIS',
            userTitle: 'Caller',
            contactTitle: 'Outbound Call',
            isCallContact: true,
            statusField: 'Status',
            activeStatusValue: 'Active',
            addCustomFields: this.addCallCustomFields,
            isProcessable: this.isOutboundCallContactProcessable,
        },
        ChatContactEvent: {
            settingSectionName: 'chat',
            mappingSectionName: 'chat',
            incontactSearchField: 'phone',
            userTitle: 'Chat',
            contactTitle: 'Chat',
            isCallContact: false,
            statusField: 'Status',
            activeStatusValue: 'Active',
            addCustomFields: this.addChatCustomFields,
            isProcessable: this.isChatContactProcessable,
        },
        EmailContactEvent_Inbound: {
            settingSectionName: 'inboundEmail',
            mappingSectionName: 'inboundEmail',
            incontactSearchField: 'FromAddress',
            userTitle: 'Email',
            contactTitle: 'Inbound Email',
            isCallContact: false,
            statusField: 'Status',
            activeStatusValue: 'Active',
            addCustomFields: this.addEmailCustomFields,
            isProcessable: this.isInboundEmailContactProcessable,
        },
        EmailContactEvent_Outbound: {
            settingSectionName: 'outboundEmail',
            mappingSectionName: 'outboundEmail',
            incontactSearchField: 'ToAddress',
            userTitle: 'Email',
            contactTitle: 'Outbound Email',
            isCallContact: false,
            statusField: 'Status',
            activeStatusValue: 'Active',
            addCustomFields: this.addEmailCustomFields,
            isProcessable: this.isOutboundEmailContactProcessable,
        },
        WorkItemContactEvent: {
            settingSectionName: 'workitem',
            mappingSectionName: 'workitem',
            incontactSearchField: 'From',
            userTitle: 'Workitem',
            contactTitle: 'Workitem',
            isCallContact: false,
            statusField: 'Status',
            activeStatusValue: 'Active',
            addCustomFields: this.addWorkitemCustomFields,
            isProcessable: this.isWorkitemContactProcessable,
        },
        VoiceMailContactEvent_Inbound: {
            settingSectionName: 'voicemail',
            mappingSectionName: 'voicemail',
            incontactSearchField: 'From',
            userTitle: 'Caller',
            isCallContact: true,
            statusField: 'Status',
            activeStatusValue: 'Active',
            contactTitle: 'Voicemail',
            isProcessable: this.isVoicemailProcessable,
        },
    };

    private isActiveContact(contactEvent) {
        return false;
    }

    constructor(
        selectedCrm: CrmApplicationModel,
        protected servicesContainer: integrationServicesContainer,
        protected inContactAPIService: InContactAPIService,
        protected incomingContactService: IncomingContactService,
        protected callContactService: CallContactService,
        protected localStorageService: LocalStorageService,
    ) {
        this.selectedCrm = selectedCrm;
        // this.localStorageService = new LocalStorageService();
    }

    handleIncontactEvent(contact: any): any {
        if (!contact.ContactID && contact.ContactId) {
            contact.ContactID = contact.ContactId;
        }
        if (!contact.ContactID) {
            return;
        }
        const ctx: any = {};
        ctx.configMap = this.getConfigurationMap(contact);
        if (!ctx.configMap) {
            //return;
            ctx.configMap = {};
        }

        try {
            ctx.settings = this.getContactSettings(contact);
            ctx.mappings = this.getContactMappings(contact);

            let c = this.handledContacts[contact.ContactID];

            if (contact.ContactID) {
                if (contact[ctx.configMap.statusField] == 'Incoming' /*ctx.configMap.activeStatusValue*/) {
                    if (ctx.settings && ctx.settings.ringtoneUrl && ctx.settings.ringtoneUrl.length > 0) {
                        contact.ringtoneUrl = ctx.settings.ringtoneUrl;
                        contact.ringtoneLoop = ctx.settings.ringtoneLoop;
                    }
                    this.incomingContactService.Add(contact);
                } else {
                    if (contact[ctx.configMap.statusField] && contact[ctx.configMap.statusField] != 'Incoming') {
                        this.incomingContactService.remove(contact.ContactID);
                    }
                }
            }

            if (contact.FinalState == 'True') {
                this.updateContactFields(contact);

                if (!c) {
                    c = {contact};
                } else if (c && !c.contact) {
                    c.contact = contact;
                }

                this.finalizeContact(c, ctx);
                return;
            }

            if (!contact[ctx.configMap.statusField]) {
                throw 'No status field in contact';
            }

            if (!c && contact.ContactID != this.checkExistingConactId(contact.ContactID)) {
                if (contact[ctx.configMap.statusField] == ctx.configMap.activeStatusValue) {
                    this.handledContacts[contact.ContactID] = {contact};

                    if (ctx.configMap.isProcessable && ctx.configMap.isProcessable.call(this, {contact}, ctx)) {
                        this.processNewContact(this.handledContacts[contact.ContactID], ctx);
                    }
                } else {
                }
            } else {
                this.updateContactFields(contact);

                this.processExistingContact(c, ctx);
            }
        } catch (error) {
            //console.log("Error in handleIncontactEvent. " + error);
            console.error(error);
        }
    }

    processNewContact(data: any, context: any) {
        this.addCommonCustomFields(data, context);
        const configMap = context.configMap;
        if (context.configMap.addCustomFields) {
            context.configMap.addCustomFields.call(this, data, context);
        }

        if (data.contact.ScreenPopUrl && data.contact.ScreenPopUrl.length > 0) {window.open(data.contact.ScreenPopUrl);}

        const values = this.servicesContainer.DataContainerService.CurrentContactArray.getValue();
        values.push(new CurrentContact('', data.contact.ContactID));
        this.servicesContainer.DataContainerService.CurrentContactArray.next(values);

        if (data.contact.WorkItemId && data.contact.WorkItemId.length > 0) {
            const workItemMetaData = JSON.parse(data.contact.WorkItemId);
            if (workItemMetaData.ticketId) {
                //window.open(data.contact.ScreenPopUrl);
                this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId = workItemMetaData.ticketId;
                data.contact.ticketId = workItemMetaData.ticketId;
                this.openTicket({id: workItemMetaData.ticketId}).subscribe(ticketData => {
                    const contact = this.servicesContainer.DataContainerService.updateContactTicketId(data.contact.ContactID, workItemMetaData.ticketId);
                    //this.saveExistingContacts();
                    /*
                    let values = this.servicesContainer.DataContainerService.CurrentContactArray.getValue();
                    values.push(new CurrentContact(workItemMetaData.ticketId, data.contact.ContactID));
                    this.servicesContainer.DataContainerService.CurrentContactArray.next(values);
                    */
                });
            }
        }

        const settings = context.settings;
        const mappings = context.mappings;
        const contact = data.contact;

        if (settings && settings.creatUserAlways) {
            if (settings.creatTicketAlways || settings.creatTicket) {
                //create ticket with user
                this.createTicket(data, context).subscribe(ticket => {
                    /*
                        this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId = ticket.id;
                        let values = this.servicesContainer.DataContainerService.CurrentContactArray.getValue();
                        values.push(new CurrentContact(ticket.id, data.contact.ContactID));
                        this.servicesContainer.DataContainerService.CurrentContactArray.next(values);
                        */

                    this.servicesContainer.DataContainerService.updateContactTicketId(data.contact.ContactID, ticket.id);

                    this.openTicket(ticket);
                    //this.saveExistingContacts();
                });
            } else {
                //just only create user
                this.alwaysCreateUser(data, context);
            }
        } else if (settings && settings.searchUser) {
            // user search is enabled
            const searchVal = contact[this.getConfigurationMap(contact).incontactSearchField];
            this.searchUser(data, context).subscribe(userResult => {
                if (userResult.count == 0) {
                    if (settings.creatTicketAlways || settings.creatTicket) {
                        // no user found
                        this.createTicket(data, context).subscribe(ticket => {
                            this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId = ticket.id;

                            this.servicesContainer.DataContainerService.updateContactTicketId(data.contact.ContactID, ticket.id);
                            this.openTicket(ticket);
                            //this.saveExistingContacts();
                        });
                    } else if (settings.creatUser) {
                        //Just create user if not found any
                        //just only create user
                        this.alwaysCreateUser(data, context);
                    }
                } else if (userResult.count == 1) {
                    // 1 user found
                    data.user = userResult.results[0];
                    if (settings.creatTicketAlways) {
                        // always create ticked is enabled no need to search any existing tickets, just create new ticket
                        this.createTicket(data, context).subscribe(ticket => {
                            this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId = ticket.id;
                            /* let values = this.servicesContainer.DataContainerService.CurrentContactArray.getValue();
                                    values.push(new CurrentContact(ticket.id, data.contact.ContactID));
                                    this.servicesContainer.DataContainerService.CurrentContactArray.next(values); */
                            this.servicesContainer.DataContainerService.updateContactTicketId(data.contact.ContactID, ticket.id);
                            this.openTicket(ticket);
                            //this.saveExistingContacts();
                        });
                    } else if (settings.searchTicket) {
                        // searching for tickets is enabled
                        this.searchTicket(data, context).subscribe(ticketResult => {
                            if (ticketResult.count == 0) {
                                // no ticket found
                                if (settings.creatTicket) {
                                    // if create ticket is enable should creat a ticket
                                    this.createTicket(data, context).subscribe(ticket => {
                                        this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId = ticket.id;
                                        this.servicesContainer.DataContainerService.updateContactTicketId(data.contact.ContactID, ticket.id);
                                        this.openTicket(ticket);
                                        //this.saveExistingContacts();
                                    });
                                } else {
                                    //do not create ticket for user found
                                    this.openUser(data.user); // just display it
                                }
                            } else if (ticketResult.count == 1) {
                                // found 1 ticket
                                // should display found ticket
                                const ticket = ticketResult.results[0];
                                this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId = ticket.id;
                                this.servicesContainer.DataContainerService.updateContactTicketId(data.contact.ContactID, ticket.id);
                                this.openTicket(ticket);
                                //this.saveExistingContacts();
                            } else if (ticketResult.count > 1) {
                                // more than 1 tickets found
                                //should display user profile
                                this.openUser(data.user);
                                this.saveExistingContacts();
                            }
                        });
                    } else if (settings.creatTicket) {
                        // create ticket is enabled, create ticket
                        this.createTicket(data, context).subscribe(ticket => {
                            //should open the created ticket
                            this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId = ticket.id;

                            this.servicesContainer.DataContainerService.updateContactTicketId(data.contact.ContactID, ticket.id);
                            this.openTicket(ticket);
                            //this.saveExistingContacts();
                        });
                    } else {
                        //should display user profile
                        /* let values = this.servicesContainer.DataContainerService.CurrentContactArray.getValue();
                            values.push(new CurrentContact('', data.contact.ContactID));
                            this.servicesContainer.DataContainerService.CurrentContactArray.next(values); */
                        this.openUser(data.user);
                        this.saveExistingContacts();
                    }
                } else if (userResult.count > 1) {
                    // more than 1 users found
                    if (settings.creatTicketAlways) {
                        // always create ticket is enabled, should create new user and new ticket
                        this.createTicket(data, context).subscribe(
                            ticket => {
                                //should open the created ticket
                                this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId = ticket.id;

                                this.servicesContainer.DataContainerService.updateContactTicketId(data.contact.ContactID, ticket.id);
                                this.openTicket(ticket);
                                //this.saveExistingContacts();
                            },
                            err => {},
                        );
                    } else {
                        // notify that there are more then 1 users in the system
                        //window.zClient.invoke('notify', "Multiple users found against " + searchVal);
                        this.notify('notify', 'Multiple users found against ' + searchVal);
                    }
                }
            });
        } else {
            //Dont search user
            if (settings && settings.creatTicket) {
                // create ticket is enabled, create ticket
                this.createTicket(data, context).subscribe(
                    ticket => {
                        //should open the created ticket
                        this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId = ticket.id;
                        /* let values = this.servicesContainer.DataContainerService.CurrentContactArray.getValue();
                        values.push(new CurrentContact(ticket.id, data.contact.ContactID));
                        this.servicesContainer.DataContainerService.CurrentContactArray.next(values); */
                        this.servicesContainer.DataContainerService.updateContactTicketId(data.contact.ContactID, ticket.id);
                        this.openTicket(ticket);
                        //this.saveExistingContacts();
                    },
                    err => {
                        throw err;
                    },
                );
            } else if (settings && settings.creatUser) {
                //Just only Create user enabled
                //just only create user
                this.alwaysCreateUser(data, context);
            }
        }
    }

    processExistingContact(data: any, context: any) {
        const currentContact = this.localStorageService.retrieve('handledContacts') || new Array();
        this.servicesContainer.DataContainerService.CurrentContactArray.next(currentContact);
    }

    checkExistingConactId(contactId) {
        const currentContact = this.localStorageService.retrieve('handledContacts') || new Array();
        const contact = currentContact.find(e => e.contactId == contactId);
        if (contact) {
            return contact.contactId;
        } else {
            return 0;
        }
    }

    saveExistingContacts() {
        const values = this.servicesContainer.DataContainerService.CurrentContactArray.getValue();
        this.localStorageService.store('handledContacts', values);
    }

    finalizeContact(data: any, context: any) {
        this.addCommonCustomFields(data, context);
        const configMap = context.configMap;
        if (context.configMap.addCustomFields) {
            context.configMap.addCustomFields.call(this, data, context);
        }
    }

    afterContactFinalized(data: any, context: any) {
        delete this.handledContacts[data.contact.ContactID];
        //this.localStorageService.store('handledContacts',this.handledContacts);

        this.servicesContainer.DataContainerService.CurrentContacts = {};
        this.servicesContainer.DataContainerService.DispositionData = {};

        this.servicesContainer.DataContainerService.removeCurrentTicket(data.contact.ContactID);
        this.saveExistingContacts();
    }

    public IsSupportedContactEvent(event): boolean {
        return this.getConfigurationMap(event) != null;
    }

    private addCommonCustomFields(data: any, context: any) {
        const agent = this.servicesContainer.AgentSessionEventsService.loadAgent();
        data.contact._agentName = agent.info.firstName + ' ' + agent.info.lastName;
        data.contact._agentNo = agent.info.agentId;

        if (data.contact.Skill === undefined) {data.contact.Skill = data.contact.SkillId;}

        const skillSearch = this.servicesContainer.AgentSessionEventsService.agentSkillAssignments.getValue().filter(item => item.skillId == data.contact.Skill);
        if (skillSearch.length > 0) {
            const skill = JSON.parse(JSON.stringify(skillSearch[0]));
            data.contact._skillName = skill.skillName;
            data.contact._campaignNo = skill.campaignId;
            data.contact._campaignName = skill.CampaignName;

            if (skill.useDisposition == 'True' && this.servicesContainer.DataContainerService.DispositionData) {
                if (this.servicesContainer.DataContainerService.DispositionData.primaryDisposition) {
                    data.contact._dispName = this.servicesContainer.DataContainerService.DispositionData.primaryDisposition.dispositionName;
                    data.contact._dispCode = this.servicesContainer.DataContainerService.DispositionData.primaryDisposition.dispositionId;
                }

                if (this.servicesContainer.DataContainerService.DispositionData.secondaryDisposition) {
                    data.contact._secDisp = this.servicesContainer.DataContainerService.DispositionData.secondaryDisposition.dispositionName;
                    data.contact._secDispCode = this.servicesContainer.DataContainerService.DispositionData.secondaryDisposition.dispositionId;
                }

                data.contact._dispNotes = this.servicesContainer.DataContainerService.DispositionData.notes;
            }
        }
    }

    private addCallCustomFields(data: any, context: any) {
        if (context.settings) {
            const path = this.replaceTokensWithValues(context.settings.recordingFilePath, data, context);
            const file = path; // + fname + '\\' + data.contact.ContactID + '.' + (context.settings.recordingFileExtension ? context.settings.recordingFileExtension : 'wav');
            data.contact._recordingFilePath = file;
        }
        data.contact._startTime = this.ConvertToLocalDate(new Date(data.contact.StartTimeUTC));
        data.contact._duration = this.getLengthString(new Date(data.contact.StartTimeUTC), new Date(data.contact.LastStateChangeTimeUTC));
    }

    private addChatCustomFields(data: any, context: any) {
        data.contact._startTime = this.ConvertToLocalDate(new Date(data.contact.StartTime));
        data.contact._calllength = this.getLengthString(new Date(data.contact.StartTime), new Date(data.contact.LastStateChangeTime));
    }

    private addEmailCustomFields(data: any, context: any) {
        data.contact._startTime = this.ConvertToLocalDate(new Date(data.contact.StartTimeUTC));
        data.contact._calllength = this.getLengthString(new Date(data.contact.StartTimeUTC), new Date(data.contact.LastStateChangeTimeUTC));
    }

    private addWorkitemCustomFields(data: any, context: any) {
        data.contact._startTime = this.ConvertToLocalDate(new Date(data.contact.StartTimeUTC));
        data.contact._calllength = this.getLengthString(new Date(data.contact.StartTimeUTC), new Date(data.contact.LastStateChangeTimeUTC));
    }

    protected getLengthString(date1: Date, date2: Date) {
        const diff = date2.valueOf() - date1.valueOf();
        const mins = Math.floor(diff / 1000 / 60);
        const secs = (diff / 1000) % 60;
        return (
            ('00' + mins).substr(('00' + mins).length - 2, ('00' + mins).length) + ':' + ('00' + secs).substr(('00' + secs).length - 2, ('00' + secs).length)
        );
    }
    protected ConvertToLocalDate(date: Date): string {
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }

    protected getContactString1(data: any, context: any): string {
        const me = this;

        let htmlstr = '';

        if (context.mappings) {
            const commentMappings = context.mappings.filter(item => item.addToComments == true);
            if (commentMappings.length > 0) {
                htmlstr = '**' + context.configMap.contactTitle + ' Details:**\n---\n ';
            }
            for (let j = 0; j < commentMappings.length; j++) {
                if (commentMappings[j].name === '_recordingFilePath' && data.contact._recordingFilePath) {
                    htmlstr += '\n**' + commentMappings[j].label + '**: ' + data.contact._recordingFilePath;
                } else if (commentMappings[j].name === '_starttime' && data.contact._starttime) {
                    htmlstr += '\n**' + commentMappings[j].label + ':** ' + data.contact._starttime; //this.fcc.common.getFormatedDateTime(new Date(contact.StartTime).valueOf());
                } else if (commentMappings[j].name === '_duration' && data.contact._duration) {
                    htmlstr += '\n**' + commentMappings[j].label + '**: ' + data.contact._duration;
                } else {
                    if (data.contact[commentMappings[j].name]) {htmlstr += '\n**' + commentMappings[j].label + '**: ' + data.contact[commentMappings[j].name];}
                }
            }
        }

        let sep = '\n\n-------------------------{title}-------------------------------\n\n';
        if (data.contact.Type == 'EmailContactEvent') {
            sep = sep.replace('{title}', '|Email Text|');
            htmlstr += sep;

            //Add subject
            if (data.contact.subject) {
                htmlstr += '\n Subject: ' + data.contact.subject + '\n';
            } else if (data.contact.Subject) {
                htmlstr += '\n Subject: ' + data.contact.Subject + '\n';
            }

            //Add Email Body
            if (data.contact.bodyHtml) {
                const StrippedString = data.contact.bodyHtml; //.replace(/(<([^>]+)>)/ig, "");
                htmlstr += '<br />' + StrippedString;
            } else if (data.contact.Body) {
                htmlstr += '<br />' + data.contact.Body;
            } else if (data.contact.BodyHTML) {
                htmlstr += '<br />' + data.contact.BodyHTML;
            }
        } else if (data.contact.Type == 'ChatContactEvent') {
            /*
            let chatRoomSearch = this.servicesContainer.ChatContactService.chatRooms.getValue().filter(cRoom => {
                return cRoom.contactId == data.contact.ContactID;
            });
            */
            //if (chatRoomSearch.length > 0) {
            if (this.servicesContainer.DataContainerService.CurrentContacts.chatRoom) {
                sep = sep.replace('{title}', '|Chat Transcript|');
                htmlstr += sep;
                const chatRoom = this.servicesContainer.DataContainerService.CurrentContacts.chatRoom;
                chatRoom.chatMessages.forEach(function(msg, index) {
                    htmlstr += '\n' + msg.Label + ' @ ' + me.ConvertToLocalDate(new Date(msg.TimeStamp));
                    htmlstr += '\n' + msg.Text;
                    htmlstr += '\n';
                });
            }
        }
        return htmlstr;
    }

    protected getContactString(data: any, context: any): string {
        const me = this;

        let htmlstr = '';

        if (context.mappings) {
            const commentMappings = context.mappings.filter(item => item.addToComments == true);
            if (commentMappings.length > 0) {
                htmlstr = '<strong>' + context.configMap.contactTitle + ' Details:</strong><br />---<br /> ';
            }
            for (let j = 0; j < commentMappings.length; j++) {
                if (commentMappings[j].name === '_recordingFilePath' && data.contact._recordingFilePath) {
                    htmlstr +=
                        '<br /><strong>' +
                        commentMappings[j].label +
                        '</strong>: <a href="' +
                        data.contact._recordingFilePath +
                        '">' +
                        data.contact._recordingFilePath +
                        '</a>';
                } else if (commentMappings[j].name === '_starttime' && data.contact._starttime) {
                    htmlstr += '<br /><strong>' + commentMappings[j].label + ':</strong> ' + data.contact._starttime; //this.fcc.common.getFormatedDateTime(new Date(contact.StartTime).valueOf());
                } else if (commentMappings[j].name === '_duration' && data.contact._duration) {
                    htmlstr += '<br /><strong>' + commentMappings[j].label + '</strong>: ' + data.contact._duration;
                } else {
                    if (data.contact[commentMappings[j].name])
                        {htmlstr += '<br /><strong>' + commentMappings[j].label + '</strong>: ' + data.contact[commentMappings[j].name];}
                }
            }
        }

        let sep = '<br /><br />-------------------------{title}-------------------------------<br /><br />';
        if (data.contact.Type == 'EmailContactEvent') {
            sep = sep.replace('{title}', '|Email Text|');
            htmlstr += sep;

            //Add subject
            if (data.contact.subject) {
                htmlstr += '<br /> Subject: ' + data.contact.subject + '<br />';
            } else if (data.contact.Subject) {
                htmlstr += '<br /> Subject: ' + data.contact.Subject + '<br />';
            }

            //Add Email Body
            if (data.contact.bodyHtml) {
                const StrippedString = data.contact.bodyHtml; //.replace(/(<([^>]+)>)/ig, "");
                htmlstr += '<br />' + StrippedString;
            } else if (data.contact.BodyHTML) {
                htmlstr += '<br />' + data.contact.BodyHTML;
            } else if (data.contact.Body) {
                htmlstr += '<br />' + data.contact.Body;
            }
        } else if (data.contact.Type == 'ChatContactEvent') {
            /*
            let chatRoomSearch = this.servicesContainer.ChatContactService.chatRooms.getValue().filter(cRoom => {
                return cRoom.contactId == data.contact.ContactID;
            });
            */
            //if (chatRoomSearch.length > 0) {
            if (this.servicesContainer.DataContainerService.CurrentContacts.chatRoom) {
                sep = sep.replace('{title}', '|Chat Transcript|');
                htmlstr += sep;
                const chatRoom = this.servicesContainer.DataContainerService.CurrentContacts.chatRoom;
                chatRoom.chatMessages.forEach(function(msg, index) {
                    htmlstr += '<br />' + (msg.Label || msg.PartyType) + ' @ ' + me.ConvertToLocalDate(new Date(msg.TimeStamp));
                    htmlstr += '<br />' + msg.Text;
                    htmlstr += '<br />';
                });
            }
            this.inContactAPIService.getChatTranscript(data.contact.ContactID).subscribe(r => {
                console.log(r);
            });
        }
        return htmlstr;
    }

    protected getContactStringAsync(data: any, context: any): Subject<string> {
        const subject = new Subject<string>();
        const me = this;

        let htmlstr = '';

        if (context.mappings) {
            const commentMappings = context.mappings.filter(item => item.addToComments == true);
            if (commentMappings.length > 0) {
                htmlstr = '<h4>' + context.configMap.contactTitle + ' Details:</h4> ';
            }
            for (let j = 0; j < commentMappings.length; j++) {
                if (commentMappings[j].name === '_recordingFilePath' && data.contact._recordingFilePath) {
                    htmlstr +=
                        '<br /><strong>' +
                        commentMappings[j].label +
                        '</strong>: <a href="' +
                        data.contact._recordingFilePath +
                        '">' +
                        data.contact._recordingFilePath +
                        '</a>';
                } else if (commentMappings[j].name === '_starttime' && data.contact._starttime) {
                    htmlstr += '<br /><strong>' + commentMappings[j].label + ':</strong> ' + data.contact._starttime; //this.fcc.common.getFormatedDateTime(new Date(contact.StartTime).valueOf());
                } else if (commentMappings[j].name === '_duration' && data.contact._duration) {
                    htmlstr += '<br /><strong>' + commentMappings[j].label + '</strong>: ' + data.contact._duration;
                } else {
                    if (data.contact[commentMappings[j].name])
                        {htmlstr += '<br /><strong>' + commentMappings[j].label + '</strong>: ' + data.contact[commentMappings[j].name];}
                }
            }
        }

        let sep = '<br /><br />-------------------------{title}-------------------------------<br /><br />';
        if (data.contact.Type == 'EmailContactEvent') {
            sep = sep.replace('{title}', '|Email Text|');
            htmlstr += sep;

            //Add subject
            if (data.contact.subject) {
                htmlstr += '<br /> Subject: ' + data.contact.subject + '<br />';
            } else if (data.contact.Subject) {
                htmlstr += '<br /> Subject: ' + data.contact.Subject + '<br />';
            }

            //Add Email Body
            if (data.contact.bodyHtml) {
                const StrippedString = data.contact.bodyHtml; //.replace(/(<([^>]+)>)/ig, "");
                htmlstr += '<br />' + StrippedString;
            } else if (data.contact.BodyHTML) {
                htmlstr += '<br />' + data.contact.BodyHTML;
            } else if (data.contact.Body) {
                htmlstr += '<br />' + data.contact.Body;
            }
            setTimeout(() => {
                subject.next(htmlstr);
            }, 100);
        } else if (data.contact.Type == 'ChatContactEvent') {
            /*
            let chatRoomSearch = this.servicesContainer.ChatContactService.chatRooms.getValue().filter(cRoom => {
                return cRoom.contactId == data.contact.ContactID;
            });
            */
            //if (chatRoomSearch.length > 0) {
            if (this.servicesContainer.DataContainerService.CurrentContacts.chatRoom) {
            }
            this.inContactAPIService.getChatTranscript(data.contact.ContactID).subscribe(r => {
                sep = sep.replace('{title}', '|Chat Transcript|');
                htmlstr += sep;
                const chatRoom = {chatMessages: r};
                chatRoom.chatMessages.forEach(function(msg, index) {
                    let txt = msg.Text;
                    if (txt == '$Localized:ChatSessionEnded') {
                        txt = 'Chat Session ended.';
                    }
                    //$Localized:ChatSessionEnded
                    htmlstr += '<br />' + (msg.Label || msg.PartyType) + ' @ ' + me.ConvertToLocalDate(new Date(msg.TimeStamp));
                    htmlstr += '<br />' + txt;
                    htmlstr += '<br />';
                });
                subject.next(htmlstr);
            });
        } else {
            setTimeout(() => {
                subject.next(htmlstr);
            }, 100);
        }
        //return htmlstr;
        return subject;
    }

    replaceTokensWithValues(str: string, data: any, context: any): string {
        let val = '';

        if (str) {
            const regex = /\$\{([^}]+)\}/g;
            const subStrRegex = /\$sub\{([^}]+)\}/g;
            //var str = "I expect five hundred dollars ${500}. and ${300}";

            let m;

            val = str.replace(regex, function(a, b) {
                const v = data.contact[b];
                if (v) {
                    return v;
                } else {
                    return a;
                }
            });

            val = val.replace(subStrRegex, function(a, b) {
                const parts = b.split(':');
                const subStrParts = parts[1].split('-');
                const startIndex = parseInt(subStrParts[0]);
                const length = parseInt(subStrParts[1]);
                const v = data.contact[parts[0]];
                if (v) {
                    return v.substring(startIndex, length);
                } else {
                    return a;
                }
            });
        }

        return val;
    }

    getCurrentTicketCaseId(data: any) {}
    createNewTicketCase(): BehaviorSubject<any> {
        const obs = new BehaviorSubject('');
        setTimeout(function() {
            obs.next('');
        }, 100);
        return obs;
    }

    getContactSettings(contact: any) {
        const configMap = this.getConfigurationMap(contact);
        if (configMap && this.selectedCrm.contactSettings) {
            const settingsSectionName = configMap.settingSectionName;
            return this.selectedCrm.contactSettings[settingsSectionName];
        }
    }

    getContactMappings(contact: any) {
        const configMap = this.getConfigurationMap(contact);
        if (configMap && this.selectedCrm.contactMappings) {
            const settingsSectionName = configMap.settingSectionName;
            return this.selectedCrm.contactMappings[settingsSectionName];
        }
    }

    private createMappingName(contact: any) {
        if (!contact.IsInbound) {return contact.Type;}
        return contact.Type + (contact.IsInbound == 'True' ? '_Inbound' : '_Outbound');
    }

    protected getConfigurationMap(contact: any): any {
        return this.ConfigurationMap[this.createMappingName(contact)];
    }

    public updateContactFields(contact) {
        if (!this.handledContacts[contact.ContactID]) {
            this.handledContacts[contact.ContactID] = {contact};
        }
        const existingContact = this.handledContacts[contact.ContactID].contact;

        if (existingContact) {
            for (const property in contact) {
                existingContact[property] = contact[property];
            }
        }
    }

    createTicket(data: any, context: any): Subject<any> {
        return null;
    }

    openTicket(ticket: any): Subject<any> {
        return null;
    }

    createUser(data: any, context: any): Subject<any> {
        return null;
    }

    openUser(user: any) {}

    searchUser(data: any, context: any): Subject<any> {
        return null;
    }

    searchTicket(data: any, context: any): Subject<any> {
        return null;
    }

    alwaysCreateUser(data, context) {}

    notify(action, message) {}

    // Contact Validation
    isInboundCallContactProcessable(data: any, context: any) {
        const contact = data.contact;
        if (contact.CallType == 'Consult') {
            return false;
        }
        return true;
    }
    isOutboundCallContactProcessable(data: any, context: any) {
        const contact = data.contact;
        const existingInboundContacts = this.callContactService.currentContacts.value.filter(x => x.contactId != contact.ContactID);
        if (existingInboundContacts.length > 0) {
            return false;
        }
        return true;
    }

    isChatContactProcessable(data: any, context: any) {
        return true;
    }
    isInboundEmailContactProcessable(data: any, context: any) {
        return true;
    }
    isOutboundEmailContactProcessable(data: any, context: any) {
        return true;
    }
    isWorkitemContactProcessable(data: any, context: any) {
        return true;
    }
    isVoicemailProcessable(data: any, context: any) {
        return true;
    }
    //End Contact Validation
}
