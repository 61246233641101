import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {throwIfAlreadyLoaded} from './_guards/module-import-guard';

// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
// import { LocalStorageService, LOCAL_STORAGE_SERVICE_CONFIG } from 'angular-2-local-storage';

import {Agent} from './_models/agent.model';
import {AgentService} from './_services/agent.service';
import {IndicatorsService} from './_services/indicators.service';

import {MessageService} from './_services/message.service';
import {ErrorService} from './_services/error.service';
import {SpinnerModule} from './_components/spinner/spinner.module';
import {InContactModule} from './InContact/incontact.module';
import {NextEventService} from './_services/next-events.service';
import {APISystemObjectsService} from './_services/api-system-objects.service';
import {CallContactService} from './_services/call-contact.service';
import {ChatContactService} from './_services/chat-contact.service';
import {WorkItemContactService} from './_services/work-item-contact.service';
import {DispositionService} from './_services/disposition.service';
import {DialogService} from '../shared/modals/dialog/dialog.service';
import {EmailContactService} from './_services/email-contact.service';
import {VoiceMailContactService} from './_services/voice-mail-contact.service';
import {EventLogService} from './_services/event-log.service';
import {FccCommonService} from './_services/fcc-common.service';
import {HotKeyPressSerivce} from './_services/hot-key-press.service';
import {ContactDataContainerService} from './_services/contactDataContainer.service';
import {MarqueeMessagesService} from './_services/marquee-messages-service';
import {CallbackService, PromiseKeeperNotificationModalComponent} from './_services/callbacks.service';
import {PageOpenService} from './_services/pageOpen.service';
import {RunAppService} from './_services/runapp.service';
import {ApplicationContextService} from './_services/application-context.service';
import {ZendeskCrmSerivce} from './_services/crm-integration/zendesk.crm-service';
import {DynamicsCrmSerivce} from './_services/crm-integration/dynamics.crm-service';

import {UacCrmIntegrationModule} from './uac-crm-integration/uac-crm-integration.module';
import {integrationManagerService} from './uac-crm-integration/integrationManager.service';
import {IncomingContactService} from './_services/incoming-contact.service';
import {SharedModule} from '../shared/shared.module';
import {DynamicsCrmIntegrationService} from './uac-crm-integration/dynamicsCrmIntegration.service';
import {ZendeskCrmIntegrationService} from './uac-crm-integration/zendeskCrmIntegration.service';
import {KustomerCrmSerivce} from './_services/crm-integration/kustomer.crm-service';

// export function createTranslateLoader(http: Http) {
//     return new TranslateStaticLoader(http, './assets/i18n', '.json');
// }

@NgModule({
    imports: [
        CommonModule,
        InContactModule,
        UacCrmIntegrationModule,
        SpinnerModule,
        SharedModule,
        // TranslateModule.forRoot({
        //     provide: TranslateLoader,
        //     useFactory: createTranslateLoader,
        //     deps: [Http],
        // }),
    ],
    declarations: [PromiseKeeperNotificationModalComponent],
    exports: [
        // TranslateModule,
        InContactModule,
        UacCrmIntegrationModule,
    ],
    entryComponents: [PromiseKeeperNotificationModalComponent],
    providers: [
        integrationManagerService,
        AgentService,
        MessageService,
        FccCommonService,
        HotKeyPressSerivce,
        NextEventService,
        CallbackService,
        APISystemObjectsService,
        CallContactService,
        ChatContactService,
        EmailContactService,
        VoiceMailContactService,
        WorkItemContactService,
        DispositionService,
        DialogService,
        ErrorService,
        SpinnerModule,
        EventLogService,
        DynamicsCrmSerivce,
        ZendeskCrmSerivce,
        KustomerCrmSerivce,
        ContactDataContainerService,
        IndicatorsService,
        MarqueeMessagesService,
        IncomingContactService,
        PageOpenService,
        RunAppService,
        ApplicationContextService,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
