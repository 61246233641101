import {ContactViewBaseModel, ContactActionModel} from './contact-view-base.model';
import {VoiceMailModel} from '../voice-mail.model';
import {CRM_APPLICATION_TYPES} from 'src/app/global';

export class VoicemailContactViewmodel extends ContactViewBaseModel<VoiceMailModel> {
    vmInit() {
        const me = this;
        this.contactIcon = this.contact.contactIcon;
        const callbackAction = new ContactActionModel({
            action: 'callback',
            text: 'Callback',
            icon: 'phone',
            isAvailable: () => !me.contact.isDisconnected(),
            isVisible: () => !me.contact.isIncoming() && !me.contact.isDisconnected(),
            menuItems: [],
        });

        this.appContext.agentSessionService.agentOutboundSkills.subscribe(skills => {
            callbackAction.menuItems = skills
                .filter(s => s.isOutboundCallSkill())
                .map(
                    s =>
                        new ContactActionModel({
                            text: s.skillName,
                            action: 'callback',
                            data: {skillName: s.skillName, skillId: s.skillId, from: this.contact.from},
                        }),
                );
        });
        //        let callbackActions: contactAction[] = [] = this.appContext.agentService.agentOutboundSkills.value.filter(s => s.isOutboundCallSkill())
        //            .map(s => new contactAction({ text: s.skillName, action: "callback", data: { skillName: s.skillName, skillId: s.skillId } }));

        this.actions.push(
            /* new contactAction({
                "action": "elevate_to_phone",
                "text": "Call",
                "icon": "phone",
                "slot": "elevations",
                isAvailable: () => !me.contact.isDisconnected()
            }),
            new contactAction({
                "action": "elevate_to_email",
                "text": "Email",
                "icon": "envelope-open",
                "slot": "elevations",
                isAvailable: () => !me.contact.isDisconnected()
            }), */
            new ContactActionModel({
                action: 'resume',
                text: 'Resume',
                icon: 'play-circle',
                isAvailable: me.contact.isHolding,
                isVisible: me.contact.isHolding,
            }),
            new ContactActionModel({
                action: 'hold',
                text: 'Hold',
                icon: 'pause',
                isAvailable: me.contact.canHold,
                isVisible: () => !me.contact.isHolding() && !me.contact.isIncoming() && !me.contact.isDisconnected(),
            }),
            new ContactActionModel({
                action: 'transfer',
                text: 'Transfer',
                icon: 'exchange-alt',
                isAvailable() {
                    return me.contact.isInbound;
                },
                isVisible: () => !me.contact.isHolding() && !me.contact.isIncoming() && !me.contact.isDisconnected(),
            }),
            new ContactActionModel({
                action: 'end',
                text: 'Hangup',
                icon: 'phone-slash',
                color: 'red',
                isAvailable: () => !this.contact.isHolding(),
                isVisible: () => !me.contact.isHolding() && !me.contact.isIncoming() && !me.contact.isDisconnected(),
            }),
        );

        this.configMap = {
            settingSectionName: 'voicemail',
            mappingSectionName: 'voicemail',
            incontactSearchField: 'From',
            userTitle: 'Caller',
            isCallContact: true,
            statusField: 'Status',
            activeStatusValue: 'Active',
            contactTitle: 'Voicemail',
        };

        if (this.appContext.selectedCrmApplication.applicationType === CRM_APPLICATION_TYPES.ZENDESK) {
            this.configMap.zendeskSearchField = 'phone';
            this.configMap.zendeskSearchOperator = '*';
        } else if (this.appContext.selectedCrmApplication.applicationType === CRM_APPLICATION_TYPES.DYNAMICS) {
            this.configMap.zendeskSearchField = 'telephone1';
            this.configMap.zendeskSearchOperator = '*';
        }

        //this.contactCustomFieldsMapping["_starttime"] = () => {return this.ConvertToLocalDate(new Date(this.contact.startTimeUTC));}
    }

    executeContactAction(action: string, data: any) {
        switch (action) {
            case 'callback':
                this.callback(data);
                break;
            default:
                super.executeContactAction(action, data);
                break;
        }
    }

    private callback(data: any) {
        if (!this.contact.isHolding())
            {this.contactService.holdContact(this.contact).subscribe(r => this.appContext.callContactService.dialPhone(data.from, data.skillName));}
        else {this.appContext.callContactService.dialPhone(data.from, data.skillName);}
    }
}
