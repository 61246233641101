import {Injectable, Inject, Optional} from '@angular/core';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {DispositionService} from '../_services/disposition.service';
import {ContactDataContainerService} from '../_services/contactDataContainer.service';
import {ChatContactService} from '../_services/chat-contact.service';

@Injectable()
export class integrationServicesContainer {
    public AgentSessionEventsService: IcAgentSessionService;
    public DispositionSerive: DispositionService;
    public DataContainerService: ContactDataContainerService;
    public ChatContactService: ChatContactService;

    constructor(
        @Inject(IcAgentSessionService) agentSessionEventsService: IcAgentSessionService,
        @Inject(DispositionService) dispositionSerive: DispositionService,
        @Inject(ContactDataContainerService) dataContainerService: ContactDataContainerService,
        @Inject(ChatContactService) chatContactService: ChatContactService,
    ) {
        this.AgentSessionEventsService = agentSessionEventsService;
        this.DispositionSerive = dispositionSerive;
        this.DataContainerService = dataContainerService;
        this.ChatContactService = chatContactService;
    }
}
