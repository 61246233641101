import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {of as observableOf, throwError} from 'rxjs';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';
import {BaseApi, CustomErrorHandler} from 'src/app/sdk/core';
import {FacLocalStorageService} from 'src/app/sdk/storage';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root',
})
export class IcAgentSessionsApi extends BaseApi {
    constructor(
        @Inject(HttpClient) httpClient: HttpClient,
        @Inject(FacLocalStorageService) protected facLocalStorageSrv: FacLocalStorageService,
        @Optional() @Inject(CustomErrorHandler) protected errorHandler: CustomErrorHandler,
    ) {
        super(httpClient, facLocalStorageSrv, errorHandler);
    }

    startSession(sessionType: string, sessionValue: string): Observable<any> {
        let relativeUri = this.resource_server_base_uri;
        let parameters: any;
        parameters = {};

        if (sessionType === 'phone') {
            relativeUri += InContactApiSettings.servicesURl + 'agent-sessions?stationPhoneNumber=' + sessionValue;
        } else if (sessionType === 'WebRTC') {
            relativeUri += `${InContactApiSettings.servicesURl}agent-sessions`;
            parameters = {
                stationID: null,
                stationPhoneNumber: 'WebRTC',
            };
        } else {
            relativeUri += InContactApiSettings.servicesURl + 'agent-sessions?stationId=' + sessionValue;
        }

        return this.POST_Request<any>(relativeUri, null, null, parameters).pipe(
            map(res => res),
            catchError((e: any) => this.errorHandler.handleError(e)),
        );
    }

    joinSession() {
        const fullUri = this.resource_server_base_uri + InContactApiSettings.servicesURl + 'agent-sessions/join';

        const params = {};

        return this.POST_Request<any>(fullUri, null, null, params);
    }
}
