import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {ChatRoutingModule} from './chat.routing';
import {ChatComponent} from './chat.component';
// import { QueueService }     from './call-history.service';

// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import {SharedModule} from '../../shared/shared.module';
import {TransferActiveContactModalComponent} from '../../shared/transfer-active-contact-modal/transfer-active-contact.component';
import {UacCrmIntegrationModule} from '../../core/uac-crm-integration/uac-crm-integration.module';

@NgModule({
    imports: [BrowserModule, FormsModule, ChatRoutingModule, SharedModule, UacCrmIntegrationModule],
    declarations: [ChatComponent],
    exports: [ChatComponent],
    providers: [],
    entryComponents: [TransferActiveContactModalComponent],
})
export class ChatModule {}
