import {Injectable} from '@angular/core';

@Injectable()
export class FccCommonService {
    getFormattedTime(duration: number): string {
        // duration = Math.abs(duration);

        const milliseconds = Math.floor((duration % 1000) / 100);
        const seconds = Math.floor((duration / 1000) % 60);
        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        const hours = Math.floor(duration / (1000 * 60 * 60)); //let hours = Math.floor (((duration/(1000*60*60))%24));

        const h = hours < 10 ? '0' + hours : hours;
        const m = minutes < 10 ? '0' + minutes : minutes;
        const s = seconds < 10 ? '0' + seconds : seconds;

        let returnStr;
        returnStr = m + ':' + s;

        if (hours !== 0) {returnStr = h + ':' + returnStr;}

        if (duration !== 0) {
            return returnStr;
        } else {
            return '';
        }
    }
}
