import Dexie, {Table} from 'dexie';
import {LogsDexieModel} from './models';

export class DexieDB extends Dexie {
    logsList!: Table<LogsDexieModel, number>;

    constructor() {
        super('FAC_DexieDB');
        this.version(3).stores({
            logsList: '++id,username,date',
        });
        // this.on('populate', () => this.populate());
    }
}

export const dexieDb = new DexieDB();
