<div class="page-heading">
    <ul class="nav nav-pills">
        <li>
            <h4>Contacts</h4>
        </li>
        <li style="flex-grow: 1"></li>

        <li>
            <lb-ngx-dropdown
                [configs]="{
                    idKey: 'id',
                    textKey: 'text',
                    orderBy: 'priority',
                    childKey: 'children'
                }"
                [side]="'bottom-right'"
                [items]="addContactDropdownMenus"
                (onMenuClick)="onAddContactDropdownClick($event)"
            >
                <ng-template #dropdownToggleBtnTemplate>
                    <button class="btn btn-xs" style="margin-right: 5px">Contact +</button>
                </ng-template>
            </lb-ngx-dropdown>
            <!-- <dropdown-menu
                [menuConfig]="{
                    idKey: 'id',
                    textKey: 'text',
                    orderBy: 'priority',
                    childKey: 'children'
                }"
                [menus]="addContactDropdownMenus"
            >
                <ng-template #dropdownBtnTemplate>
                    <button class="btn btn-xs" style="margin-right: 5px;">
                        Contact +
                    </button>
                </ng-template>
            </dropdown-menu> -->
        </li>

        <!-- <li>
            <button
                class="dropdown-toggle btn btn-xs"
                style="margin-right: 5px;"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                Contact +
                <span *ngIf="false" class="caret"></span>
            </button>
            <div style="min-width: 200px;" class="dropdown-menu dropdown-menu-right">
                <ul class="nav">
                    <li class="dropdown-submenu" *ngIf="agent && agent.info && agent.info.requestContact">
                        <a
                            href="javascript:;"
                            (click)="addContact()"
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >New Inbound Contact</a
                        >
                    </li>
                    <li>
                        <a (click)="btnClick($event, 'contact_ob_call_menu')" href="javascript:;">
                            New Outbound Call
                            <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu" [style.display]="submenus.contact_ob_call_menu ? 'block' : 'none'">
                            <li><a routerLink="/dialpad">Dial Number</a></li>
                            <li><a routerLink="/address-book">Call an Address book</a></li>
                            <li *ngIf="false"><a href="#">Call a Skill</a></li>
                            <li role="separator" class="divider"></li>
                            <li><a routerLink="/call-history">Dial from History</a></li>
                        </ul>
                    </li>
                    <li *ngIf="outboundEmailSkills.length > 0">
                        <a (click)="btnClick($event, 'contact_ob_email_menu')" href="javascript:;"
                            >New Outbound Email <span class="caret"></span
                        ></a>
                        <ul class="dropdown-menu" [style.display]="submenus.contact_ob_email_menu ? 'block' : 'none'">
                            <li *ngFor="let skill of outboundEmailSkills">
                                <a tabindex="-1" href="javascript:void(0);" (click)="outboundEmail(skill.skillId)"
                                    >
                                    {{skill.skillName}}
                                    </a
                                >
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </li> -->
        <li>&nbsp;</li>
        <li>
            <button href="javascript:;" class="btn btn-xs" *ngIf="!legStatus || legStatus == 'Disconnected'" (click)="connectLeg()">Connect</button>

            <button *ngIf="legStatus != 'Disconnected'" class="btn btn-xs">{{legStatus}}</button>
        </li>
        <li>&nbsp;</li>
    </ul>
</div>
<div style="height: 35px" *ngIf="false">
    <ul class="nav nav-pills pull-right">
        <li *ngFor="let action of callContactService.actions">
            <button
                role="button"
                title="{{ action.text | translate }}"
                (click)="callContactService.executeServiceAction(action.action)"
                routerLinkActive="active"
                class="btn btn-primary-outline no-border"
                [disabled]="!action.isAvailable()"
                *ngIf="action.isVisible()"
            >
                <i class="fa fa-{{action.icon}}" aria-hidden="true"></i> {{action.text}}
            </button>
        </li>
    </ul>
</div>

<div class="page-content">
    <div>
        <!-- <iframe
            src="https://na1.nice-incontact.com/wfm/myschedule/?embeddedClient=true&postMsg=true"
            width="100%"
            style="height: calc(100vh - 141px)"
            frameborder="0"
        ></iframe> -->
        <!-- <iframe
            src="https://na1.nice-incontact.com/coaching/#/myCoachings?embeddedClient=true&postMsg=true"
            width="100%"
            style="height: calc(100vh - 141px)"
            frameborder="0"
        ></iframe> -->
        <!-- <iframe
            src="https://na1.nice-incontact.com/sso/#/landingPage?embeddedClient=true&postMsg=true"
            width="100%"
            style="height: calc(100vh - 141px)"
            frameborder="0"
        ></iframe> -->
    </div>
    <app-contact-group [contactService]="callContactService"> </app-contact-group>

    <app-contact-group [contactService]="chatContactService"> </app-contact-group>

    <app-contact-group [contactService]="emailcontactService"> </app-contact-group>

    <app-contact-group [contactService]="voicemailContactService"> </app-contact-group>

    <app-contact-group [contactService]="workitemContactService"> </app-contact-group>

    <ul *ngFor="let object of appContext.omnichannelGroups" class="list-group list-group-flush">
        <li *ngFor="let contactVM of object.value" class="list-group-item no-padding">
            <app-contact-item [contactVM]="contactVM"></app-contact-item>
        </li>
    </ul>

    <!-- <div>
        <button (click)="TestDynamics()">Test Dynamics</button>
    </div> -->
</div>
