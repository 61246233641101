import {ContactViewBaseModel, ContactActionModel} from './contact-view-base.model';
import {ChatRoomModel} from '../chat-room.model';

export class ChatContactViewmodel extends ContactViewBaseModel<ChatRoomModel> {
    vmInit() {
        const me = this;

        this.actions.push(
            /* new contactAction({
                "action": "elevate_to_phone",
                "text": "Call",
                "icon": "phone",
                "slot": "elevations",
                isAvailable: () => me.canElevateToCall()
            }),
            new contactAction({
                "action": "elevate_to_email",
                "text": "Email",
                "icon": "envelope-open",
                "slot": "elevations",
                isAvailable: () => !me.contact.isDisconnected()
            }), */
            new ContactActionModel({
                action: 'transfer',
                text: 'Transfer',
                icon: 'exchange-alt',
                isAvailable() {
                    return me.contact.isInbound;
                },
            }),
            new ContactActionModel({
                action: 'end',
                text: 'End',
                icon: 'comment-slash',
                color: 'red',
                isAvailable() {
                    return me.contact.isActive();
                },
            }),
        );

        this.configMap = {
            settingSectionName: 'chat',
            mappingSectionName: 'chat',
            // incontactSearchField: 'phone',
            userTitle: 'Chat',
            contactTitle: 'Chat',
            isCallContact: false,
            statusField: 'Status',
            activeStatusValue: 'Active',
        };
    }

    public toContactString() {
        let htmlstr = super.toContactString();

        let sep = '<br /><br />-------------------------{title}-------------------------------<br /><br />';

        sep = sep.replace('{title}', '|Chat Text|');
        htmlstr += sep;

        this.contact.chatMessages.forEach(function(msg, index) {
            htmlstr +=
                '<br />' +
                (msg.Label || msg.PartyType) +
                ' @ ' +
                new Date(msg.TimeStamp).toLocaleDateString() +
                ' : ' +
                new Date(msg.TimeStamp).toLocaleTimeString();
            htmlstr += '<br />' + msg.Text;
            htmlstr += '<br />';
        });
        return htmlstr;
    }
}
