<ng-container *ngIf="contacts.length > 0">
    <div *ngIf="false" class="form-section">
        <h4 *ngIf="false" class="pull-left">{{contactService.title}} <small>-({{contactService.count}})</small></h4>
        <ul class="nav nav-pills pull-right">
            <li *ngFor="let action of contactService.actions">
                <button
                    role="button"
                    title="{{ action.text | translate }}"
                    (click)="contactService.executeServiceAction(action.action)"
                    routerLinkActive="active"
                    class="btn btn-primary-outline no-border"
                    [disabled]="!action.isAvailable()"
                    *ngIf="action.isVisible()"
                >
                    <i class="fa fa-{{action.icon}}" aria-hidden="true"></i> {{action.text}}
                </button>
            </li>
        </ul>
        <div style="clear: both"></div>
    </div>
    <ul class="list-group list-group-flush">
        <ng-container *ngFor="let contactVM of contacts">
            <li *ngIf="!contactVM.contact.isOmniGroupContact()" class="list-group-item no-padding">
                <app-contact-item [contactVM]="contactVM"></app-contact-item>
            </li>
        </ng-container>
    </ul>
</ng-container>
