export class Events {
    public Type: string;

    //Agent State
    public CurrentState: string;
    public CurrentOutReason: string;
    public StartTimeUTC: Date;
    public IsAcw: string;
    //public AcwTimer:number;
    public AcwTimeout: number;
    public NextStates: any[];

    //Agent Session Start
    public BusNo: string;
    public AgentId: number;
    public AgentUUId: string;
    public SessionId: string;
    public StationPhoneNumber: string;
    public StationCallerId: string;
    public DialerCampaign: string;
    public DialerCampaignStartTime: string;
    public SupervisorPermissionLevel: string;
    public CanMask: string;
    public AgentSchedulePermission: string;
    public ScoreRecordingsPermission: string;
    public HideAgentStatePermission: string;
    public StationId: string;

    //Call Contact

    //public Type:string;//repeated
    public ContactID: number;
    public Status: string;
    public OriginalState: boolean;
    public CallType: string;
    public DNIS: number;
    public ANI: number;
    public Skill: string;
    public IsInbound: string;
    // public StartTimeUTC:string; /Repeated
    public LastStateChangeTimeUTC: Date;
    public ScreenPopUrl: string;
    public DisconnectCode: string;
    public IsLogging: string;
    public Timeout: string;
    public AllowDispositions: string;
    public Label: string;
    public IsLinked: boolean;
    public TimeZones: string;
    public FinalState: string;
    public OtherInformation: string;
    public BlendingToSkillName: string;

    //Mute Agent
    public AgentMuted: string;

    //Chat Events
    public CustomData: string;
    public IISHost: string;
    public IsActive: boolean;
    public LastStateChangeTime: Date;
    public MasterID: number;
    public RefusalTimeout: number;
    public RoomId: number;
    public StartTime: Date;
    public VCHost: string;
    public Messages: Message[];

    //ChatTextEvent
    public Message: string;
    public PartyType: string;
    public TimeStamp: Date;

    //WorkItemContactEvent
    public WorkItemId: number;
    public WorkItemPayload: string;
    public WorkItemType: string;
    public SkillId: number;
    public ScreenPopUrlVariables: string[];

    //EmailContactEvents
    public ContactId: number;
    public FromAddress: string;
    public SentDate: Date;
    public Body: string;
    public BodyHTML: string;
    public Subject: string;
    public ToAddress: string;
    public CcAddress: string;
    public BccAddress: string;

    //VoiceMailContactEvent
    CreatedDate;
    FileDuration: number;
    FileName: string;
    From: string;
    To: string;
    VoiceMailType: string;

    //VoiceMailPlayBackEvent
    PlayBackPaused: string;
    PlayBackPosition: number;
}

export class NextStates {
    State: string;
    OutReason: string;
    originalNextState: string;

    constructor(State, OutReason, originalNextState?) {
        this.State = State;
        this.OutReason = OutReason;
        if (originalNextState) {this.originalNextState = originalNextState;}
    }
}

export class Message {
    public Text: string;
    public TimeStamp: Date;
    public PartyType: string;
}
