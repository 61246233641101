import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-work-items',
    templateUrl: './work-items.component.html',
    styleUrls: ['./work-items.component.css'],
})
export class WorkItemsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
