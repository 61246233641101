export class AgentSkillAssignmentsModel {
    constructor(data: any) {
        this.BusinessUnitId = data.BusinessUnitId;
        this.agentId = data.agentId;
        this.internalId = data.internalId;
        this.agentName = data.agentName;
        this.agentProficiencyValue = data.agentProficiencyValue;
        this.agentProficiencyName = data.agentProficiencyName;
        this.LastModified = data.LastModified;
        this.skillName = data.skillName;
        this.skillId = data.skillId;
        this.campaignId = data.campaignId;
        this.campaignName = data.campaignName;
        this.emailFromAddress = data.campaignName;
        this.isActive = data.isActive;
        this.isNaturalCalling = data.isNaturalCalling;
        this.isNaturalCallingRunning = data.isNaturalCallingRunning;
        this.isOutbound = data.isOutbound;
        this.isSkillActive = data.isSkillActive;
        this.lastUpdateTime = data.lastUpdateTime;
        this.mediaTypeId = data.mediaTypeId;
        this.mediaTypeName = data.mediaTypeName;
        this.notes = data.notes;
        this.outboundStrategy = data.outboundStrategy;
        this.priorityBlending = data.priorityBlending;
        this.requireDisposition = data.requireDisposition;
        this.screenPopTriggerEvent = data.screenPopTriggerEvent;
        this.scriptDisposition = data.scriptDisposition;
        this.useACW = data.useACW;
        this.useDisposition = data.useDisposition;
        this.useSecondaryDispositions = data.useSecondaryDispositions;
        this.teamId = data.teamId;
    }
    public BusinessUnitId: string;
    public agentId: number;
    public internalId: string;
    public agentName: string;
    public agentProficiencyValue: string;
    public agentProficiencyName: string;
    public LastModified: string;
    public skillName: string;
    public skillId: number;
    public campaignId: number;
    public campaignName: string;
    public emailFromAddress;
    public isActive: string;
    public isNaturalCalling: string;
    public isNaturalCallingRunning: string;
    public isOutbound: string;
    public isSkillActive: string;
    public lastUpdateTime: string;
    public mediaTypeId: string;
    public mediaTypeName: string;
    public notes: string;
    public outboundStrategy: string;
    public priorityBlending: string;
    public requireDisposition: string;
    public screenPopTriggerEvent: string;
    public scriptDisposition: string;
    public useACW: boolean;
    public useDisposition: boolean;
    public useSecondaryDispositions: boolean;
    public teamId: number;

    isOutboundCallSkill: Function = () =>
        this.isActive === 'True' &&
        this.mediaTypeId === '4' &&
        this.isOutbound === 'True' &&
        this.isNaturalCalling === 'False';
    isOutboundEmaillSkill: Function = () =>
        this.mediaTypeId === '1' && this.isActive === 'True' && this.isOutbound === 'True';
}
