<ul class="call-actions text-center">
    <li>
        <div class="call-single-action softphone-btn my-10" *ngIf="softPhoneSrv.showAnsBtn">
            <button
                class="btn btn-circle btn-lg btn-success"
                role="button"
                routerlinkactive="active"
                title="Answer From Soft Phone"
                (click)="AnswerCall(softPhoneSrv.callList[0].id)"
            >
                <i class="fas fa-phone"></i>
            </button>
            Answer
        </div>

        <div class="call-single-action webrtc-btn my-10" *ngIf="showWebRTCAnsBtn">
            <button
                class="btn btn-circle btn-lg btn-success"
                role="button"
                routerlinkactive="active"
                title="Answer From Soft Phone"
                (click)="AnswerCallWebRTC(contactVM.contact)"
            >
                <i class="fas fa-phone"></i>
            </button>
            Answer
        </div>

        <div class="call-single-action preview-btn my-10" *ngIf="showPreviewAnsBtn">
            <button class="btn btn-circle btn-lg btn-success" role="button" routerlinkactive="active" title="Answer From Soft Phone" (click)="AnswerPreview()">
                <i class="fas fa-phone"></i>
            </button>
            Answer
        </div>

        <!-- <div class="call-single-action">
            <button class="btn btn-circle btn-lg btn-success" role="button" routerlinkactive="active" title="Answer From Soft Phone">
                <i class="fas fa-phone"></i>
            </button>
            Answer
        </div> -->
    </li>

    <li></li>

    <li>
        <div class="call-single-action my-10" *ngIf="showWebRTCRejectBtn">
            <button class="btn btn-circle btn-lg btn-red btn-hangup" role="button" title="Hangup" title="Answer From Soft Phone" (click)="endCall()">
                <i class="fas fa-phone-slash"></i>
            </button>
            Reject
        </div>
    </li>
</ul>
