import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError, of as observableOf, Observable, BehaviorSubject, throwError, merge, zip, of} from 'rxjs';
import {InContactApiSettings} from './incontact-api-settings';
import {AuthenticationContextModel} from './models/authentication-context.model';
import {AgentInfoModel} from './models/agent-info.model';
import {SkillsModel} from './models/skills.model';
import {IndicatorModel} from './models/indicators.model';
import {AgentSkillAssignmentsModel} from './models/agent-skill-assignments.model';
import {FacLocalStorageService} from '../../sdk/storage/fac-local-storage.service';
import {AuthenticationContextService} from './_services/authentication-context.service';
import {ContactStates} from './models/contact-states.model';
import {integrationManagerService} from '../uac-crm-integration/integrationManager.service';
import {ResourceOwnerPasswordAuthorizerApi} from 'src/app/sdk/incontact/api';
import {IcAgentSessionsApi} from 'src/app/sdk/incontact/api/agent-sessions/ic-agent-sessions.api';
import {IcAgentsApi} from 'src/app/sdk/incontact/api/agents/ic-agents.api';
import {SkillModel} from '../_models/skill.model';
import {MarqueeMessageModel, PreviewScheduleViewModel} from 'src/app/sdk/incontact/models';

@Injectable()
export class InContactAPIService {
    private isAuthenticated: boolean;
    private authenticationContext: AuthenticationContextModel;
    private firstSessionId: string;
    private sessionId: string;
    private agentInfo: AgentInfoModel;
    private skills: SkillsModel[];
    private indicators: IndicatorModel[];
    private agentSkills: AgentSkillAssignmentsModel[];
    private scripts: any[];
    private messages: any[];

    //private _InContactApiSettings:InContactApiSettings;

    constructor(
        private localStorageService: FacLocalStorageService,
        private authenticationContextService: AuthenticationContextService,
        private passwrodAuthorizer: ResourceOwnerPasswordAuthorizerApi,
        private icSessionApi: IcAgentSessionsApi,
        private icAgentApi: IcAgentsApi,
    ) {
        this.firstSessionId = InContactApiSettings.firstSessionId; //this.localStorageService.getSessionstorage("firstSessionId");
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
    }

    getIsAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    OnError(error: any) {
        //if(error instanceof(Inc))
    }

    clearAuthContext() {
        this.authenticationContextService.clearAuthContext();
    }

    // getAuthContext() {
    //     return this.authenticationContextService.getAuthContext();
    // }

    tryReloadState(): boolean {
        const authContext = this.authenticationContextService.tryReloadState();

        if (authContext !== null) {
            this.firstSessionId = this.localStorageService.getSessionstorage('firstSessionId');

            if (this.firstSessionId == null) {
                return false;
            }

            this.updateSessionId(this.firstSessionId, true);
            return true;
        } else {
            return false;
        }
    }

    // startSession(sessionType: string, sessionValue: string): Observable<any> {
    //     return this.icSessionApi.startSession(sessionType, sessionValue).pipe(
    //         map(res => {
    //             this.updateSessionId(res.sessionId, true);
    //             return observableOf(true);
    //         }),
    //         catchError(error => {
    //             return throwError(error);
    //         }),
    //     );
    // }

    // joinSession(): Observable<any> {
    //     let result: any;
    //     let relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/join';
    //     const
    //     parameters = {};
    //     return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
    //         map(
    //             res => {
    //                 // res => result = res;
    //                 this.onSessionJoined(res);
    //                 return observableOf(true);
    //             },
    //             error => {
    //                 //return Observable.of(error);
    //                 return this.onSessionError(error);
    //                 //return Observable.of(error);
    //             },
    //         ),
    //     );

    //     // return this._PasswrodAuthorizer.sendHttpPost(relativeUri,parameters)
    //     // .map(this.onSessionJoined, this.onSessionError);
    // }

    onSessionJoined(res: any) {
        const sessionInfo: any = res;
        //this._facLocalStorageSrv.setItem("sessionId", sessionInfo.sessionId);
        this.updateSessionId(sessionInfo.sessionId, true);

        return JSON.stringify(res);
    }

    onSessionError(error: any) {
        this.updateSessionId('', false);
        this.sessionId = '';
        this.localStorageService.setSessionstorage('sessionId', '');
        InContactApiSettings.sessionId = '';
    }

    onSessionStarted(res: Response) {
        const sessionInfo = res.json();
        return JSON.stringify(res.json());
    }

    endSession(forceLogout, endContacts, ignorePersonalQueue): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId;
        //let relativeUri = InContactApiSettings.servicesURl + "agent-sessions/" + encodeURIComponent(this.sessionId) +
        //  "?forceLogoff=" + encodeURIComponent(forceLogout) + "&endContacts=" + encodeURIComponent(endContacts) +
        // "&ignorePersonalQueue=" + encodeURIComponent(ignorePersonalQueue);
        const relativeUri =
            'services/v3.0/' +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '?forceLogoff=' +
            encodeURIComponent(forceLogout) +
            '&endContacts=' +
            encodeURIComponent(endContacts) +
            '&ignorePersonalQueue=' +
            encodeURIComponent(ignorePersonalQueue);
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/" + encodeURIComponent(this.sessionId)
        const parameters: any = {};
        return this.passwrodAuthorizer.sendHttpDelete(relativeUri, parameters).pipe(
            map(
                res => {
                    this.updateSessionId(null, true);
                    return observableOf(true);
                },
                error => observableOf(error),
                // return this.onSessionError(error);
                //return Observable.of(error);
            ),
        );
    }

    updateSessionId(newSessionId: string, updateFirstSessionId: boolean) {
        if (updateFirstSessionId) {
            this.firstSessionId = newSessionId;
            this.localStorageService.setSessionstorage('firstSessionId', newSessionId);
            InContactApiSettings.firstSessionId = this.firstSessionId;
        }

        this.sessionId = newSessionId;
        this.localStorageService.setSessionstorage('sessionId', newSessionId);
        InContactApiSettings.sessionId = this.sessionId;
    }

    NextEvents(): Observable<any> {
        let result: any;
        let _events: any;
        const _eventsArray = [];
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");

        if (this.sessionId !== undefined) {
            //let relativeUri = "services/v4.0/"+ "agent-sessions/" + encodeURIComponent(this.sessionId) + "/get-next-event?timeout=" +
            // encodeURIComponent(""+InContactApiSettings.getLongPoolTimeout());
            const relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/get-next-event?timeout=' +
                encodeURIComponent('' + InContactApiSettings.getLongPoolTimeout()) +
                '&';
            const parameters: any = {};

            return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
                map(
                    res => {
                        result = res;
                        this.updateSessionId(res.sessionId, false);
                        return res;
                    },
                    error => error,
                ),
            );
        }
    }

    getAgentInfo(agentId: number): Observable<AgentInfoModel | any> {
        return this.icAgentApi.getAgentInfo(agentId).pipe(
            map(res => {
                this.agentInfo = res;
                return res;
            }),
        );
    }

    requestSkills(): Observable<SkillsModel[]> {
        let skills: SkillsModel[];
        const relativeUri = InContactApiSettings.servicesURl + 'skills/';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    skills = res.skills;
                    return skills;
                },
                error => observableOf(error),
            ),
        );
    }

    requestAgentSkillAssignments(agentId: number): Observable<AgentSkillAssignmentsModel[] | any> {
        let _agentSkills: AgentSkillAssignmentsModel[];
        _agentSkills = [];

        const relativeUri = InContactApiSettings.servicesURl + 'agents/' + encodeURIComponent(agentId.toString()) + '/skills';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => (_agentSkills = res.resultSet.agentSkillAssignments.map((s: any) => new AgentSkillAssignmentsModel(s))),
                err => err,
            ),
        );
    }

    getAddressBook(agentId: number): Observable<any> {
        let result: any;
        const relativeUri = InContactApiSettings.servicesURl + 'agents/' + agentId + '/address-books?includeEntries=false';
        const parameters: any = {includeEntries: true};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    result = res ? res.addressBooks : [];
                    return result;
                },
                err => err,
            ),
        );
    }

    getAddressBookContacts(bookId: number, isDynamic?: boolean): Observable<any> {
        let result: any;
        let relativeUri = InContactApiSettings.servicesURl + 'address-books/' + bookId + '/entries';

        const parameters: any = {includeEntries: true};

        if (isDynamic == true) {
            relativeUri = InContactApiSettings.servicesURl + 'address-books/' + bookId + '/dynamic-entries?FullLoad=true';
            //parameters = { 'FullLoad': true };
        }

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    result = res.addressBook.addressBookEntries;
                    return result;
                },
                err => err,
            ),
        );
    }
    getServerTime(): Observable<string | any> {
        let ServerTime: string;
        const relativeUri = InContactApiSettings.servicesURl + 'server-time';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    ServerTime = res.ServerTime;
                    return ServerTime;
                },
                err => err,
            ),
        );
    }

    getParkedEmails(agentId: string): Observable<string | any> {
        const relativeUri =
            InContactApiSettings.servicesURl +
            'contacts/parked?agentId=' +
            agentId +
            '&fields=contactId%2CcontactStart%2ClastUpdateTime%2CfromAddr%2CskillId%2CskillName%2Csubject%2CtargetAgentId';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    const results = res ? res.resultSet.parkedContacts : [];
                    return results;
                },
                err => err,
            ),
        );
    }

    getUnavailableCodes(teamId: string): Observable<any> {
        let unavailableList: any;
        const relativeUri = InContactApiSettings.servicesURl + 'admin/teams/' + encodeURIComponent(teamId) + '/unavailable-codes';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    unavailableList = res.unavailableList;
                    return unavailableList;
                },
                err => err,
            ),
        );
    }

    setAgentState(state: string, outstateReason: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/state';
        const parameters: any = {state, reason: outstateReason};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    dialPhone(phoneNumber: string, skillId: string, masterContactId?: string): Observable<any> {
        const ph = phoneNumber.replace(/[^+*0-9]/g, ''); //.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '');
        //var relativeUri = "services/v2.0/agent-sessions/" + encodeURIComponent(_sessionId) + "/dial-phone?phoneNumber=" +
        //  encodeURIComponent(ph) + "&skillName=" + encodeURIComponent(skillName);
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        //let relativeUri = InContactApiSettings.servicesURl + "agent-sessions/" + encodeURIComponent(this.sessionId) +
        //  "/dial-phone?phoneNumber=" + encodeURIComponent(ph) + "&skillName=" + encodeURIComponent(skillName);
        const relativeUri = `services/v13.0/agent-sessions/${encodeURIComponent(this.sessionId)}/dial-phone`;
        const parameters: any = {
            phoneNumber: ph, //encodeURIComponent(ph),
            skillId,
        };
        if (masterContactId) {
            parameters.parentContactId = masterContactId;
        }

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    sendDtmf(dtmfSequence, toneDurationMS, toneSpacingMS): Observable<any> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/send-dtmf';
        const parameters: any = {DtmfSequence: dtmfSequence, ToneDurationMS: toneDurationMS, ToneSpacingMS: toneSpacingMS};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    consultAgent(agentUsername: string): Observable<any> {
        let result: any;
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/consult-agent?agentUsername=' +
            encodeURIComponent(agentUsername);
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    dialAgent(agentUsername: string): Observable<any> {
        let result: any;
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/dial-agent?agentUsername=' +
            encodeURIComponent(agentUsername);
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    dialSkill(skillName: string): Observable<any> {
        let result: any;
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/dial-skill?skillName=' +
            encodeURIComponent(skillName);
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    endContact(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId) +
            '/end';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    holdContact(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId) +
            '/hold';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    resumeContact(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId) +
            '/resume';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    transferCalls(): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/interactions/transfer-calls';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    conferenceCalls(): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/interactions/conference-calls';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    acceptConsult(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/accept-consult';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    muteAgent(): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/agent-phone/mute';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    unMuteAgent(): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/agent-phone/unmute';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    dialLeg(): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/agent-phone/dial';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    endLeg(): Observable<any> {
        console.log(this.sessionId);
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/agent-phone/end';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    recordCall(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/record';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    maskRecording(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/mask';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    unmaskRecording(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/unmask';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    getContactState(agentId: string): Observable<ContactStates | any> {
        const relativeUri = InContactApiSettings.servicesURl + 'agents/' + encodeURIComponent(agentId) + '/queues';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    if (res.resultSet.queues === '') {
                        res.resultSet.queues = [];
                    }
                    return res.resultSet.queues;
                },
                error => observableThrowError(error),
            ),
        );
    }

    addChat(): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = `services/v15.0/agent-sessions/${encodeURIComponent(this.sessionId)}/interactions/add-text`;
        const parameters: any = {mediaType: 3};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    addEmail(): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/interactions/add-email';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    acceptContact(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/accept';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    rejectContact(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/reject';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    endChat(contactId: number): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/end';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    getChatTranscript(contactId: number): Observable<any> {
        let result: any;
        let _events: any;
        const _eventsArray = [];
        const relativeUri = InContactApiSettings.servicesURl + 'contacts/' + encodeURIComponent(contactId.toString()) + '/chat-transcript';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    result = res.messages.Messages;
                    return result;
                },
                error => error,
            ),
        );
    }

    getMessages(agentId: number): Observable<any> {
        let result: any;
        let _events: any;
        const _eventsArray = [];
        const relativeUri = InContactApiSettings.servicesURl + 'agents/' + encodeURIComponent(agentId.toString()) + '/messages';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    result = res.messages.Messages;
                    return result;
                },
                error => error,
            ),
        );
    }

    acceptWorkItem(contactId: number): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/accept';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    endWorkItem(contactId: number): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/end';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    holdWorkItem(contactId: number): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/hold';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    resumeWorkItem(contactId: number): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/resume';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    //Disposition ------------------------
    getSkillInfo(skillId: number): Observable<any> {
        let result: any;
        let _events: any;
        const _eventsArray = [];
        const relativeUri = InContactApiSettings.servicesURl + 'skills/' + encodeURIComponent(skillId.toString());
        const relativeUri11 = InContactApiSettings.servicesURL11 + 'skills/' + encodeURIComponent(skillId.toString());
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri11).pipe(map(x => [x]));
        // return zip(
        //     this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
        //         map(
        //             res => res.skills,
        //             error => {
        //                 return error;
        //             },
        //         ),
        //     ),
        // ).pipe(
        //     map(x => {
        //         return [
        //             new SkillModel({
        //                 ...x[0][0],
        //                 ...x[1],
        //             }),
        //         ];
        //     }),
        // );
    }

    getDispositionList(skillName: string): Observable<any> {
        let result: any;
        let _events: any;
        const _eventsArray = [];
        const relativeUri = InContactApiSettings.servicesURl + 'skills/' + encodeURIComponent(skillName) + '/dispositions';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    result = res.resultSet.dispositions;
                    return result;
                },
                error => error,
            ),
        );
    }

    dispositionAContact(contactId: string, data: PreviewScheduleViewModel) {
        return this.passwrodAuthorizer.sendHttpPost(
            `${InContactApiSettings.servicesURL9}/agent-sessions/${encodeURIComponent(this.sessionId)}/interactions/${encodeURIComponent(
                contactId,
            )}/disposition`,
            data as any,
        );
    }

    snoozeAContact(contactId: string) {
        return this.passwrodAuthorizer.sendHttpPost(
            `${InContactApiSettings.servicesURL9}/agent-sessions/${encodeURIComponent(this.sessionId)}/interactions/${encodeURIComponent(contactId)}/snooze`,
            {} as any,
        );
    }

    setDisposition(
        contactId: number,
        primaryDispositionId: string,
        dispositionNotes: string,
        secondaryDispositionId: string,
        useSecondaryDisp?: boolean,
        primaryCallbackNumber?: string,
        primaryCallbackTime?: string,
    ): Observable<any> {
        let result: any;
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/disposition';
        let parameters: any = {};

        if (useSecondaryDisp) {
            parameters = {
                primaryDispositionId,
                primaryDispositionNotes: dispositionNotes,
                secondaryDispositionId,
                primaryCallbackNumber,
                primaryCallbackTime,
            };
        } else {
            parameters = {primaryDispositionId, primaryDispositionNotes: dispositionNotes, primaryCallbackNumber, primaryCallbackTime};
        }

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    sendChatMessage(contactId: string, message: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/send-chat-text';
        const parameters: any = {ChatText: message};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    activeChat(contactId: number): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/activate-chat';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    activateContact(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        //let relativeUri = InContactApiSettings.servicesURl + "agent-sessions/" + encodeURIComponent(this.sessionId) + "/interactions/" +
        // encodeURIComponent(contactId.toString()) + "/activate";
        const relativeUri =
            'services/v10.0/' +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/activate';
        //let relativeUri = "services/v4.0/agents/426622/scheduled-callbacks?agentId=" + agentId;
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    getAgentList(): Observable<AgentInfoModel[]> {
        let agents: AgentInfoModel[];
        const relativeUri = InContactApiSettings.servicesURl + 'agents/';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    agents = res.agents;
                    return agents;
                },
                error => observableOf(error),
            ),
        );
    }

    getAgentStates(): Observable<any> {
        let agents: AgentInfoModel[];
        //let relativeUri = InContactApiSettings.servicesURl+ "agents/states";
        //let relativeUri = InContactApiSettings.servicesURl+ "agents/states?Fields=agentId,firstName,lastName,skillId,teamId,startDate,agentStateId,
        // agentStateName,isACW,outStateId,outStateDescription";
        const relativeUri =
            '/services/v8.0/cache/agentstate?Fields=agentId,firstName,lastName,skillId,' +
            'teamId,lastUpdateTime,agentStateId,userName,isACW,isActive,outStateCode,outStateDescription&UpdatedSince=1970-01-01T00:00:00Z';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    //agents=res.agentStates;
                    agents = res.resultSet.agentState;
                    return agents;
                },
                error => observableOf(error),
            ),
        );
    }

    getFile(filePath: string) {
        const relativeUri = InContactApiSettings.servicesURl + `files?fileName=${filePath}`;
        return this.passwrodAuthorizer.sendHttpGet(relativeUri).pipe(
            map(
                res => res,
                error => observableOf(error),
            ),
        );
    }

    transferChat(contactId: string, data: any): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        let relativeUri;
        let parameters: any = {};

        if (data.transferType === 'Skills') {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/transfer-chat-to-skill';
            parameters = {targetSkillId: data.targetId};
        } else {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/transfer-chat-to-agent';
            parameters = {targetAgentId: data.targetId};
        }

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    transferWorkItem(contactId: string, data: any): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        let relativeUri;
        let parameters: any = {};

        if (data.transferType === 'Skills') {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/transfer-work-item-to-skill';
            //parameters = {'targetSkillId': data};
            parameters = {targetSkillName: data.targetId};
        } else {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/transfer-work-item-to-agent';
            parameters = {targetAgentName: data.targetId};
        }

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    transferVoiceMail(contactId: string, data: any): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        let relativeUri;
        let parameters: any = {};

        if (data.transferType === 'Skills') {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/transfer-voicemail-to-skill';
            parameters = {targetSkillId: data.targetId};
        } else {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/transfer-voicemail-to-agent';
            parameters = {targetAgentId: data.targetId};
        }

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    emailSend(contactId: string, data: any): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/email-send';

        // parameters = {'data':data};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, data).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    replyEmail(contactId: string, replyMode: string, emailData: any): Observable<any> {
        let result: any;
        let relativeUri;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");

        if (replyMode === 'R' || replyMode === 'RA' || replyMode === 'reply' || replyMode === 'replyAll') {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/email-reply';
        } else if (replyMode === 'F' || replyMode === 'forward') {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/email-forward';
        }
        return this.passwrodAuthorizer.sendHttpPost(relativeUri, emailData).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    transferEmail(contactId: string, data: any): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        //let relativeUri = InContactApiSettings.servicesURl + "agent-sessions/" + encodeURIComponent(this.sessionId) +
        // '/interactions/' + encodeURIComponent(contactId.toString()) + '/transfer-email-to-skill?targetSkillId=' + targetSkillId;
        let relativeUri = '';
        let parameters: any = {};

        if (data.transferType === 'Skills') {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/transfer-email-to-skill';
            //parameters = {'targetSkillId': data};
            parameters = {targetSkillId: data.targetId};
        } else {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agent-sessions/' +
                encodeURIComponent(this.sessionId) +
                '/interactions/' +
                encodeURIComponent(contactId.toString()) +
                '/transfer-email-to-agent';
            parameters = {targetAgentId: data.targetId};
        }

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    emailOutbound(skillId: string, toAddress?: string, masterContactId?: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        //let relativeUri = InContactApiSettings.servicesURl + "agent-sessions/" + encodeURIComponent(this.sessionId) + "/interactions/email-outbound";
        const relativeUri = 'services/v10.0/' + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/interactions/email-outbound';
        const parameters: any = {skillId};

        if (toAddress) {
            parameters.toAddress = toAddress;
        }

        if (masterContactId) {
            parameters.parentContactId = masterContactId;
        }

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    parkEmail(contactId: string, contactData: any) {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        //let relativeUri = InContactApiSettings.servicesURl + "agent-sessions/" + encodeURIComponent(this.sessionId) + "/interactions/email-outbound";
        const relativeUri = `${InContactApiSettings.servicesURl}agent-sessions/${encodeURIComponent(this.sessionId)}/interactions/${encodeURIComponent(
            contactId.toString(),
        )}/email-park`;
        const parameters: any = contactData;

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    unparkEmail(contactId: string, isImmediate: boolean = false) {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        //let relativeUri = InContactApiSettings.servicesURl + "agent-sessions/" + encodeURIComponent(this.sessionId) + "/interactions/email-outbound";
        const relativeUri = `${InContactApiSettings.servicesURl}agent-sessions/${encodeURIComponent(this.sessionId)}/interactions/${encodeURIComponent(
            contactId.toString(),
        )}/email-unpark`;
        const parameters: any = {isImmediate};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    playVoiceMail(contactId: string, playTimestamp: boolean, position: number): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/play-voicemail';
        const parameters: any = {playTimestamp, position};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    pauseVoiceMail(contactId: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri =
            InContactApiSettings.servicesURl +
            'agent-sessions/' +
            encodeURIComponent(this.sessionId) +
            '/interactions/' +
            encodeURIComponent(contactId.toString()) +
            '/pause-voicemail';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    loginPCSkill(skillName: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/dialer-login';
        const parameters: any = {skillName};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    logoutPCSkill(skillName: string): Observable<any> {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = InContactApiSettings.servicesURl + 'agent-sessions/' + encodeURIComponent(this.sessionId) + '/dialer-logout';
        const parameters: any = {skillName};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
    }

    getContactHistory(agentId: number, startDate: string, endDate: string, orderBy: string): Observable<any> {
        let result: any;
        let relativeUri;

        if (orderBy === 'contactStartDate') {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agents/' +
                encodeURIComponent(agentId.toString()) +
                '/interaction-history?startDate=' +
                startDate +
                '&endDate=' +
                endDate +
                ' &orderBy=contactStartDate desc';
        } else {
            relativeUri =
                InContactApiSettings.servicesURl +
                'agents/' +
                encodeURIComponent(agentId.toString()) +
                '/interaction-history?startDate=' +
                startDate +
                '&endDate=' +
                endDate +
                ' &orderBy=' +
                orderBy +
                ' asc&orderBy=contactStartDate desc';
        }
        const parameters: any = {};
        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => res.contactList.contactStates,
                error => error,
            ),
        );
    }

    addContact(data?: any): Observable<any> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = 'services/v10.0/agent-sessions/' + encodeURIComponent(this.sessionId) + '/add-contact';
        const parameters: any = data ? data : {chat: true, email: true, workitem: true};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return observableOf(true);
                },
                error => observableOf(error),
            ),
        );
        //return Observable.of(true);
    }

    getIndicators(agentId: number): Observable<IndicatorModel[] | any> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = 'services/v10.0/agents/' + encodeURIComponent(agentId.toString()) + '/indicators';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    this.indicators = res.indicators;
                    return this.indicators;
                },
                error => error,
            ),
        );
    }

    getScriptsByName(scriptName: string): Observable<any[]> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = 'services/v4.0/scripts?scriptName=' + encodeURIComponent(scriptName);
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    this.scripts = res.scripts;
                    return this.scripts;
                },
                error => error,
            ),
        );
    }

    getCallbacks(agentId: number): Observable<any[]> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = `services/v4.0/agents/${agentId}/scheduled-callbacks`;
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res =>
                    //this.scripts = res.callbacks;
                    res.callbacks,
                error => error,
            ),
        );
    }

    createCallback(callbackData: any) {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = 'services/v4.0/' + 'scheduled-callbacks';
        const parameters: any = callbackData;

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return res;
                },
                error => error,
            ),
        );
    }

    removeCallback(callbackId: any, notes?: string) {
        let result: any;
        //this.sessionId = InContactApiSettings.sessionId;//this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = `services/v4.0/scheduled-callbacks/${callbackId}`;
        const parameters: any = {};

        if (notes && `${notes}`.trim().length > 0) {
            parameters.description = notes;
        }
        return this.passwrodAuthorizer.sendHttpDelete(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return res;
                },
                error => error,
            ),
        );
    }

    updateCallback(callbackId, callbackData: any) {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = 'services/v4.0/scheduled-callbacks/' + callbackId;
        const parameters: any = callbackData;

        return this.passwrodAuthorizer.sendHttpPut(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return res;
                },
                error => error,
            ),
        );
    }

    dialCallback(callbackId: string) {
        ///interactions/20309/dial
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = `services/v4.0/agent-sessions/${encodeURIComponent(this.sessionId)}/interactions/${callbackId}/dial`;
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return res;
                },
                error => error,
            ),
        );
    }

    rescheduleCallback(callbackId: string, rescheduleDate: string) {
        let result: any;
        this.sessionId = InContactApiSettings.sessionId; //this.localStorageService.getSessionstorage("sessionId");
        const relativeUri = `services/v4.0/agent-sessions/${encodeURIComponent(this.sessionId)}/interactions/${callbackId}/reschedule`;
        const parameters: any = {};
        parameters.rescheduleDate = rescheduleDate;

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => {
                    result = res;
                    return res;
                },
                error => error,
            ),
        );
    }

    getTimezones() {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = 'services/v5.0/timezones';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res =>
                    //this.scripts = res.callbacks;
                    res.timeZones,
                error => error,
            ),
        );
    }

    getMarqueeMessages(agentId: number): Observable<MarqueeMessageModel[]> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = 'services/v4.0/agents/' + encodeURIComponent(agentId.toString()) + '/messages';
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<{messages: Array<MarqueeMessageModel>}>(relativeUri).pipe(
            map(
                res => {
                    this.messages = res.messages;
                    return this.messages;
                },
                error => error,
            ),
        );
    }

    startScript(scriptId: string, skillId: string, parameters: any): Observable<any> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = 'services/v4.0/scripts/' + scriptId + '/start';

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => res,
                error => error,
            ),
        );
    }

    signalScript(contactId: string, parameters: any): Observable<any> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = 'services/v3.0/interactions/' + contactId + '/signal';

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => res,
                error => error,
            ),
        );
    }

    sendTypingStatus(data: any) {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = 'services/v10.0/agent-sessions/' + encodeURIComponent(this.sessionId) + '/interactions/' + data.contactId + '/typing';

        const parameters: any = {isTyping: data.isTyping, isTextEntered: data.isTextEntered};
        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => res,
                error => error,
            ),
        );
    }

    resetPassword(data: any) {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        //let relativeUri = "services/v10.0/agents/" + data.agentId + "/reset-password";
        const relativeUri = 'services/v10.0/agents/change-password';

        //let parameters:any = {agentId: data.agentId, requestedPassword: data.requestedPassword, forceChangeOnLogon: false};
        const parameters: any = {
            agentId: data.agentId,
            newPassword: data.requestedPassword,
            currentPassword: data.currentPassword,
            forceChangeOnLogon: false,
        };
        return this.passwrodAuthorizer.sendHttpPostWithToken(relativeUri, parameters, data.accessToken, data.serverUri, 'PUT').pipe(
            map(
                res => res,
                error => error,
            ),
        );
    }

    getBusinessUnit(): Observable<any> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        //let relativeUri = "services/v10.0/agents/" + data.agentId + "/reset-password";
        const relativeUri = 'services/v13.0/business-unit';

        //let parameters:any = {agentId: data.agentId, requestedPassword: data.requestedPassword, forceChangeOnLogon: false};
        const parameters: any = {};
        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => res.businessUnits,
                error => error,
            ),
        );
    }

    getTagsBySkill(skillId: string): Observable<any[]> {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = `services/v16.0/skills/${skillId}/tags?isActive=true`;
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    //this.scripts = res.callbacks;
                    if (res) {
                        return res.resultSet.tags;
                    } else {
                        return [];
                    }
                },
                error => error,
            ),
        );
    }

    addTags(contactId: string, tagString: string) {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        //let relativeUri = "services/v10.0/agents/" + data.agentId + "/reset-password";
        const relativeUri = `services/v10.0/contacts/${contactId}/tags`;

        const tagIds = tagString.split(',');

        //let parameters:any = {agentId: data.agentId, requestedPassword: data.requestedPassword, forceChangeOnLogon: false};
        const parameters: any = {tags: []};

        tagIds.forEach((val, index) => {
            if (`${val}`.trim().length > 0) {
                parameters.tags.push({tagId: val});
            }
        });

        if (parameters.tags.length == 0) {
            return of({});
        }

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).pipe(
            map(
                res => res,
                error => error,
            ),
        );
    }

    getQuickReplies() {
        let result: any;
        //let relativeUri = InContactApiSettings.servicesURl+ "agent-sessions/"+ encodeURIComponent(this.sessionId) + "/dial-phone" ;
        const relativeUri = `services/v16.0/agents/quick-replies`;
        const parameters: any = {};

        return this.passwrodAuthorizer.sendHttpGet<any>(relativeUri).pipe(
            map(
                res => {
                    //this.scripts = res.callbacks;
                    if (res) {
                        return res.quickReplies;
                    } else {
                        return [];
                    }
                },
                error => error,
            ),
        );
    }

    sendCustomData(contactId: string, data: string) {
        this.sessionId = InContactApiSettings.sessionId;
        const relativeUri = `services/v16.0/agent-sessions/${encodeURIComponent(this.sessionId)}/interactions/${contactId}/custom-data`;
        const parameters: any = {
            data,
        };

        console.log(contactId, data);

        return this.passwrodAuthorizer.sendHttpPost(relativeUri, parameters).subscribe();
    }
}
