import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'contactActionFilter',
    pure: false,
})
export class ContactActionFilter implements PipeTransform {
    transform(items: any[], filter: any): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        let newItems = [];

        const f = filter; //JSON.parse(filter);

        if (f.slot) {
            newItems = items.filter(item => item.slot == f.slot);
        }
        return newItems;
    }
}

@Pipe({name: 'groupBy'})
export class GroupByPipe implements PipeTransform {
    transform(collection: Array<any>, property: string): Array<any> {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection) {
            return null;
        }

        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current[property]]) {
                previous[current[property]] = [current];
            } else {
                previous[current[property]].push(current);
            }

            return previous;
        }, {});

        // this will return an array of objects, each object containing a group of objects
        return Object.keys(groupedCollection).map(key => ({key, value: groupedCollection[key]}));
    }
}
