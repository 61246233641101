import {CrmServiceBase, CustomSearchResult} from './base.crm-service';
import {Injectable} from '@angular/core';
import {ContactViewBaseModel} from '../../_models/_viewmodels/contact-view-base.model';
import {ContactBase} from '../../_models/contact-base';
import {Observable, of} from 'rxjs';
import {CrmDataHolderViewModel} from '../../_models/crm';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {parseQueryString} from 'src/app/global';
import {
    DynamicsActivityPointerModel,
    DynamicsCaseModel,
    DynamicsContactModel,
    DynamicsEmailActivityModel,
    DynamicsEnvironmentModel,
    DynamicsPhoneCallModel,
} from '../../_models/crm/dynamics';
import {EmailContactViewmodel} from '../../_models/_viewmodels/email-contact-viewmodel';
import {DynamicsTaskModel} from '../../_models/crm/dynamics/models/dynamics-task.model';
import {LocalStorageService} from 'ngx-webstorage';
import {MessageService} from '../message.service';
import {DexieLogsService} from 'src/app/sdk/dexie/services';
import {Router} from '@angular/router';

@Injectable()
export class DynamicsCrmSerivce extends CrmServiceBase {
    private currentSystemEnv: DynamicsEnvironmentModel = null;

    constructor(
        protected localStorageSrv: LocalStorageService,
        protected messagesSrv: MessageService,
        protected dexieLogsSrv: DexieLogsService,
        public router: Router,
    ) {
        super(localStorageSrv, messagesSrv, dexieLogsSrv);
    }

    init() {
        const me = this;
        try {
            Microsoft.CIFramework.getEnvironment().then(res => {
                const envObj = JSON.parse(res);

                this.currentSystemEnv = new DynamicsEnvironmentModel(envObj);

                this.onNumberDialed();

                me.initDone$.next(true);
                me.initDone$.complete();
            });
        } catch (error) {
            console.log('Error getEnvironment: ' + error);
        }
    }

    public onNumberDialed() {
        const me = this;
        Microsoft.CIFramework.addHandler('onclicktoact', e => {
            let n = '';
            if (typeof e === 'string') {
                n = JSON.parse(e)?.value;
            } else {
                n = e.value;
            }

            me.router.navigate(['/dialpad'], {queryParams: {phoneNumber: n, fromDynamics: 1}});
        });
    }

    updateCrmTicketWithContactDetails(contactVM: ContactViewBaseModel<ContactBase>): Observable<ContactViewBaseModel<ContactBase>> {
        super.updateCrmTicketWithContactDetails(contactVM);

        const context = {mappings: contactVM.crmFieldMappings};
        const htmlstr = contactVM.toContactString(false);

        const currentContact = contactVM.contact;

        const agentPartyObj = this.currentSystemEnv.toActivityPartyModel(currentContact.isInbound ? 2 : 1);
        const secondParty = this.crmDataHolder
            ? new DynamicsContactModel(this.crmDataHolder.dynamicsUser).toActivityPartyModel(currentContact.isInbound ? 1 : 2)
            : null;

        let entityName = 'task';
        let entityObj: DynamicsActivityPointerModel = null;

        if (currentContact.contactType === 'Call' || currentContact.contactType === 'Voicemail') {
            entityName = 'phonecall';
            entityObj = new DynamicsPhoneCallModel({
                phonecall_activity_parties: [agentPartyObj, secondParty],
                phonenumber: currentContact.DNIS,
                directioncode: !currentContact.isInbound,
                description: htmlstr,
                subject: `${currentContact.isInbound ? 'Inbound' : 'Outbound'} ${currentContact.contactType}`,
            });
        } else if (currentContact.contactType === 'Email') {
            entityName = 'email';
            // console.log('after end contact: ', currentContact.isInbound, this.crmDataHolder.dynamicsUser);
            entityObj = new DynamicsEmailActivityModel({
                directioncode: !currentContact.isInbound,
                description: htmlstr,

                subject: `${currentContact.isInbound ? 'Inbound' : 'Outbound'} Email`,
                email_activity_parties: [agentPartyObj, secondParty],
            });
        } else if (currentContact.contactType === 'Chat') {
            entityName = 'task';
            entityObj = new DynamicsTaskModel({
                description: htmlstr,

                subject: `${currentContact.isInbound ? 'Inbound' : 'Outbound'} Chat`,
                task_activity_parties: [agentPartyObj, secondParty],
            });
        } else if (currentContact.contactType === 'WorkItem') {
            entityName = 'task';
            entityObj = new DynamicsTaskModel({
                description: htmlstr,

                subject: 'Workitem Activity',
                task_activity_parties: [agentPartyObj, ...(secondParty ? [secondParty] : [])],
            });
        }

        return new Observable(obsrver => {
            if (!!entityName && !!entityObj) {
                if (context.mappings) {
                    const mappings = context.mappings.filter(map1 => map1.value && map1.value.length > 0);

                    if (mappings) {
                        for (const mapping of mappings) {
                            entityObj[mapping.value] =
                                (!entityObj[mapping.value] ? '' : entityObj[mapping.value] + ', ') + contactVM.getContactPropertyValueByKey(mapping.name);
                        }
                    }
                }

                this.createActivity(entityName, entityObj)
                    .then(
                        (res: any) => {
                            // console.log('create activity res: ', res);

                            obsrver.next(contactVM);
                        },
                        err => {
                            console.log('create activity error: ', err);

                            obsrver.next(contactVM);
                        },
                    )
                    .finally(() => {
                        obsrver.complete();
                    });
            } else {
                obsrver.complete();
            }
        });
    }

    public updateEmailContactUserData(email: EmailContactViewmodel) {
        if (this.crmDataHolder?.dynamicsUser && !!email.reply.toAddress) {
            Microsoft.CIFramework.updateRecord(
                'contact',
                this.crmDataHolder.dynamicsUser.contactid,
                JSON.stringify({
                    emailaddress1: email.reply.toAddress,
                    lastname: email.reply.toAddress,
                }),
            ).then(
                res => {
                    this.crmDataHolder.dynamicsUser.emailaddress1 = email.reply.toAddress;
                    // console.log('update contact data: ', res);
                },
                err => {
                    console.log('update contact error: ', err);
                },
            );
        }
    }

    createActivity(type: string, phoneCallActivity: DynamicsActivityPointerModel) {
        return new Promise((resolve, reject) => {
            const jsonData = JSON.stringify(phoneCallActivity);
            Microsoft.CIFramework.createRecord(type, jsonData).then(
                result => {
                    const res = JSON.parse(result);

                    resolve(res);
                },
                error => {
                    // console.log(error);
                    //handle error conditions

                    reject(error);
                },
            );
        });
    }

    protected customCrmSearch(viewmodel: ContactViewBaseModel<ContactBase>, searchString: string, data: any) {
        return new Observable<{found: boolean; type: string}>(obsvr => {
            searchString = searchString.split('+').join(' ');
            const parts = searchString.split('?$');

            if (parts.length < 2) {
                obsvr.next({found: false, type: null});
                obsvr.complete();
                return;
            }

            const entityName = parts[0];
            searchString = `$${parts[1]}`;

            // console.log('searching for: ', entityName, searchString);

            Microsoft.CIFramework.searchAndOpenRecords(entityName, `?${decodeURI(searchString)}`, false)
                .then(
                    result => {
                        const resultData: {[key: string]: DynamicsContactModel} = JSON.parse(result);

                        if (entityName === 'contact') {
                            data.dynamicsUserResult = [];
                            for (const key in resultData) {
                                data.dynamicsUserResult.push(resultData[key]);
                            }

                            if (data.dynamicsUserResult.length === 1) {
                                data.dynamicsUser = data.dynamicsUserResult[0];

                                obsvr.next({found: true, type: 'user'});
                                return;
                            }
                        } else if (entityName === 'case') {
                            data.dynamicsCaseResult = [];
                            for (const key in resultData) {
                                data.dynamicsCaseResult.push(resultData[key]);
                            }

                            if (data.dynamicsCaseResult.length === 1) {
                                data.dynamicsCase = data.dynamicsCaseResult[0];

                                obsvr.next({found: true, type: 'case'});
                                return;
                            }
                        }

                        obsvr.next({found: false, type: null});
                    },
                    error => {
                        console.log(error.message);
                        // handle error conditions
                    },
                )
                .catch(err => {
                    console.log('findContact Error: ', err);

                    obsvr.error(err);
                })
                .finally(() => {
                    obsvr.complete();
                });
        });
    }

    processContact(contactVM: ContactViewBaseModel<ContactBase>) {
        this.contactVM = contactVM;

        const settings = contactVM.settings;
        this.crmDataHolder = null;

        this.data = new CrmDataHolderViewModel({
            zendeskUserResult: null,
            ticketResult: null,
            zendeskUser: null,
            dynamicsUserResult: null,
            dynamicsUser: null,
            dynamicsCase: null,
            dynamicsCaseResult: null,
        });

        let process: Observable<any> = new Observable(obsv => {
            obsv.next(this.data);
            obsv.complete();
        });

        if (contactVM.contact.ticketPayload?.ticketId) {
            process = process.pipe(
                mergeMap((v, d) => this.getCrmTicket(contactVM, this.data, contactVM?.contact?.ticketPayload?.ticketId)),
                mergeMap((v, d) => this.OpenCrmTicket(contactVM, this.data)),
            );

            process.subscribe(r => {
                if (this.data.ticket) {
                    contactVM.contact.ticketId = `${this.data.ticket.id}`;

                    (contactVM as any).ticketId = this.data.ticket.id;

                    contactVM.contactService.currentContactViewModels.value
                        .filter(x => x.contact.contactId == contactVM.contact.contactId)
                        .forEach(x => {
                            x.contact.ticketId = this.data.ticket.id;
                        });
                }
                this.addToProcessedContacts(contactVM);
            });
        } else if (!contactVM.isCrmProcessable()) {
            return;
        } else {
            const obj = this.getProcessedContact(contactVM);

            if (obj) {
                if (!contactVM.contact.ticketId || `${contactVM.contact.ticketId}`.trim().length == 0) {
                    this.data.ticket = {id: obj.ticketId};
                    contactVM.contact.ticketId = obj.ticketId;
                    //process = process.flatMap((v, d) => this.OpenCrmTicket(contactVM, this.data));
                } else if (contactVM.contact.ticketId && `${contactVM.contact.ticketId}`.trim().length > 0) {
                    contactVM.contact.ticketId = obj.ticketId;
                    this.data.ticket = {id: contactVM.contact.ticketId};
                    //process = process.flatMap((v, d) => this.OpenCrmTicket(contactVM, this.data));
                }
                return;
            } else {
                this.addToProcessedContacts(contactVM);

                let customSearch = false;

                const screenPopUrl = contactVM.contact.ScreenPopUrl;

                if (!!screenPopUrl && screenPopUrl.includes('ScreenPopPrintVars')) {
                    const parts = screenPopUrl.split('?');
                    if (parts.length === 2) {
                        const params = parseQueryString(parts[1]);
                        if (Object.keys(params).length > 0) {
                            contactVM.contact.ScreenPopUrlVariables = [params];
                        }
                    }
                }

                const customSearchResult: CustomSearchResult = {foundUser: false, foundTicket: false};
                const customSearchPromise = new Promise(async (resolve: (sRes: CustomSearchResult) => void, reject) => {
                    if (contactVM.contact.ScreenPopUrlVariables?.length) {
                        for (const popVar of contactVM.contact.ScreenPopUrlVariables) {
                            for (const key of Object.keys(popVar)) {
                                const result = await this.customCrmSearch(contactVM, popVar[key], this.data).toPromise();

                                // console.log('custom search result', result, this.data);

                                if (result.found) {
                                    customSearch = true;
                                    if (result.type === 'case') {
                                        this.OpenCrmTicket(contactVM, this.data);

                                        customSearchResult.foundTicket = true;
                                        resolve(customSearchResult);
                                        return;
                                    } else if (result.type === 'user') {
                                        customSearchResult.foundUser = true;
                                        this.OpenCrmUser(contactVM, this.data);
                                    }
                                }
                            }
                        }
                    }
                    resolve(customSearchResult);
                });

                customSearchPromise.then(customSearchPromiseResult => {
                    if (!customSearchPromiseResult.foundTicket) {
                        if (settings.creatTicketAlways) {
                            //Priority 1
                            process = process.pipe(
                                mergeMap((v, d) => {
                                    if (!this.data.dynamicsUser) {
                                        // if (settings.searchUser) {
                                        if (settings.searchForLevel1 === 'Contact') {
                                            return this.findCrmUser(contactVM, this.data).pipe(
                                                catchError(
                                                    err =>
                                                        new Observable(r => {
                                                            r.next(this.data);
                                                            r.complete();
                                                        }),
                                                ),
                                            );
                                        } else {
                                            return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                mergeMap((v1, d1) =>
                                                    this.createCrmUser(contactVM, this.data).pipe(
                                                        catchError(
                                                            err =>
                                                                new Observable(r => {
                                                                    r.next(this.data);
                                                                    r.complete();
                                                                }),
                                                        ),
                                                    ),
                                                ),
                                            );
                                        }
                                    } else {
                                        return new Observable(sub => {
                                            sub.next(this.data);
                                            sub.complete();
                                        });
                                    }
                                }),
                                mergeMap((v1, d1) => {
                                    if (this.data.dynamicsUser) {
                                        if (settings.creatUserAlways) {
                                            return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                mergeMap((v, d) =>
                                                    this.createCrmUser(contactVM, this.data).pipe(
                                                        catchError(
                                                            err =>
                                                                new Observable(r => {
                                                                    r.next(this.data);
                                                                    r.complete();
                                                                }),
                                                        ),
                                                    ),
                                                ),
                                                mergeMap((v, d) => this.buildCrmTicketObject(contactVM, this.data)),
                                                mergeMap((v, d) => this.createCrmTicket(contactVM, this.data)),
                                                mergeMap((v, d) => this.OpenCrmTicket(contactVM, this.data)),
                                            );
                                        } else {
                                            return this.buildCrmTicketObject(contactVM, this.data).pipe(
                                                mergeMap((v, d) => this.createCrmTicket(contactVM, this.data)),
                                                mergeMap((v, d) => this.OpenCrmTicket(contactVM, this.data)),
                                            );
                                        }
                                    } else {
                                        if (this.data.dynamicsUserResult && this.data.dynamicsUserResult.length > 1) {
                                            //return this.notifyUser(contactVM, { "Subject": "Multiple Records Found", "MessageText": `${this.data.userResult.count}
                                            // users found against ${contactVM.configMap.incontactSearchField} ${contactVM.getContactPropertyValueByKey(
                                            //     contactVM.configMap.incontactSearchField)}` });

                                            // return this.buildCrmUserObject(contactVM, this.data).pipe(
                                            //     mergeMap((v, d) =>
                                            //         this.createCrmUser(contactVM, this.data).pipe(
                                            //             catchError(
                                            //                 err =>
                                            //                     new Observable(r => {
                                            //                         r.next(this.data);
                                            //                         r.complete();
                                            //                     }),
                                            //             ),
                                            //         ),
                                            //     ),
                                            //     mergeMap((v, d) => this.buildCrmTicketObject(contactVM, this.data)),
                                            //     mergeMap((v, d) => this.createCrmTicket(contactVM, this.data)),
                                            //     mergeMap((v, d) => this.OpenCrmTicket(contactVM, this.data)),
                                            // );

                                            return of(this.data);
                                        } else {
                                            return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                mergeMap((v, d) =>
                                                    this.createCrmUser(contactVM, this.data).pipe(
                                                        catchError(
                                                            err =>
                                                                new Observable(r => {
                                                                    r.next(this.data);
                                                                    r.complete();
                                                                }),
                                                        ),
                                                    ),
                                                ),
                                                mergeMap((v, d) => this.buildCrmTicketObject(contactVM, this.data)),
                                                mergeMap((v, d) => this.createCrmTicket(contactVM, this.data)),
                                                mergeMap((v, d) => this.OpenCrmTicket(contactVM, this.data)),
                                            );
                                        }
                                    }
                                }),
                            );
                        } else {
                            if (!this.data.dynamicsUser) {
                                if (settings.creatUserAlways) {
                                    process = process.pipe(
                                        mergeMap((v, d) => this.buildCrmUserObject(contactVM, this.data)),
                                        mergeMap((v, d) => this.createCrmUser(contactVM, this.data)),
                                    );
                                    // } else if (settings.searchUser) {
                                } else if (settings.searchForLevel1 === 'Contact') {
                                    process = process.pipe(
                                        mergeMap((v, d) => this.findCrmUser(contactVM, this.data)),
                                        mergeMap((v1, d1) => {
                                            // console.log(this.data.dynamicsUserResult, this.data.dynamicsUserResult?.length);
                                            if (this.data?.dynamicsUserResult?.length == 1) {
                                                // this.data.zendeskUser = this.data.dynamicsUserResult.results[0];
                                                return this.OpenCrmUser(contactVM, this.data);
                                                // return Observable.create(r => r.next(contactVM, this.data));

                                                // return new Observable<any>(obr => {
                                                //     obr.next({...this.data});
                                                //     obr.complete();
                                                // });
                                            } else if (this.data.dynamicsUserResult && this.data.dynamicsUserResult.length > 1) {
                                                return this.notifyUser(contactVM, {
                                                    Subject: 'Multiple Records Found',
                                                    MessageText: `${this.data.dynamicsUserResult.length} users found against ${
                                                        contactVM.configMap.incontactSearchField
                                                    } ${contactVM.getContactPropertyValueByKey(contactVM.configMap.incontactSearchField)}`,
                                                });
                                            } else {
                                                // console.log('create new user: ', settings);
                                                if (settings.creatTicketAlways || settings.creatUser) {
                                                    return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                        mergeMap(d => this.createCrmUser(contactVM, this.data)),
                                                        mergeMap(d => this.OpenCrmUser(contactVM, this.data)),
                                                    );
                                                }
                                            }
                                            return new Observable(sub => {
                                                sub.next(this.data);
                                                sub.complete();
                                            });
                                        }),
                                    );
                                } else if (settings.creatUser) {
                                    process = process.pipe(
                                        mergeMap((v, d) => this.buildCrmUserObject(contactVM, this.data)),
                                        mergeMap(d => this.createCrmUser(contactVM, this.data)),
                                    );
                                }
                            }

                            // if (settings.searchTicket) {
                            if (settings.findRelated && settings.searchForLevel2 === 'Incident') {
                                process = process.pipe(
                                    mergeMap((v, d) => this.findCrmTicket(contactVM, this.data)),
                                    mergeMap(d => {
                                        // console.log('going to open ticket', contactVM.contact.ticketId);
                                        if (this.data.dynamicsCase) {
                                            return this.OpenCrmTicket(contactVM, this.data);
                                        } else if (this.data.dynamicsCaseResult && this.data.dynamicsCaseResult.length == 0) {
                                            if (settings.creatTicket) {
                                                return this.buildCrmTicketObject(contactVM, this.data).pipe(
                                                    mergeMap(dt => this.createCrmTicket(contactVM, this.data)),
                                                    mergeMap(d1 => this.OpenCrmTicket(contactVM, this.data)),
                                                );
                                            }
                                        } else if (d.dynamicsCaseResult && d.dynamicsCaseResult.length == 1) {
                                            return this.OpenCrmTicket(contactVM, this.data);
                                        } else if (d.dynamicsCaseResult && d.dynamicsCaseResult.length > 1) {
                                            return this.OpenCrmUser(contactVM, this.data);
                                        }
                                        return new Observable<any>(sub => {
                                            sub.next(this.data);
                                            sub.complete();
                                        });
                                    }),
                                );
                            } else if (settings.creatTicket) {
                                process = process.pipe(
                                    mergeMap((v, d) => this.buildCrmTicketObject(contactVM, this.data)),
                                    mergeMap(dt => this.createCrmTicket(contactVM, this.data)),
                                    mergeMap(d => this.OpenCrmTicket(contactVM, this.data)),
                                );
                            }
                        }
                    }

                    process.subscribe(r => {
                        this.crmDataHolder = this.data;
                        if (this.data.dynamicsCase) {
                            // contactVM.contact.ticketId = 'testing_123';
                            contactVM.contact.ticketId = `${this.data.dynamicsCase.ticketnumber}`;

                            if (contactVM.contact.contactType === 'WorkItem') {
                                (contactVM as any).ticketId = this.data.dynamicsCase.incidentid;
                            } else {
                                (contactVM as any).ticketId = this.data.dynamicsCase.ticketnumber;
                            }

                            contactVM.contactService.currentContactViewModels.value
                                .filter(x => x.contact.contactId == contactVM.contact.contactId)
                                .forEach(x => {
                                    x.contact.ticketId = this.data.dynamicsCase.ticketnumber;
                                });

                            /*  setTimeout(() => {
                                 this.processContact(contactVM);
                             }, 5000); */
                        }

                        this.addToProcessedContacts(contactVM);
                    });
                });
            }
        }

        /*
        if (settings && settings.searchUser) { // user search is enabled
            var searchVal = contact[context.configMap.incontactSearchField];
            process = process.flatMap(d => this.findCrmUser());
        }
        if (settings && settings.creatTicket) { // create ticket is enabled, create ticket
            process = process.flatMap(d => this.createCrmTicket()).flatMap(d => this.OpenCrmTicket());

        }
        else { //Dont search user
            if (settings && settings.creatUser) {//Just only Create user enabled
                //just only create user
                this.alwaysCreateUser(data, context);
            }
        }
            */
    }

    public onManualUserSelection(contactVM: ContactViewBaseModel<ContactBase>, userId: number) {
        if (!!userId) {
            this.data.zendeskUser = this.data.zendeskUserResult.results?.find(x => x.id === userId);

            this.OpenCrmUser(contactVM, this.data).subscribe();
        }
    }

    public onManualTicketSelection(contactVM: ContactViewBaseModel<ContactBase>, ticketId: number | string) {
        if (!!ticketId) {
            this.data.dynamicsCase = this.data.dynamicsCaseResult?.find(x => x.ticketnumber === ticketId);

            this.OpenCrmTicket(this.contactVM, this.data).subscribe(res => {
                if (this.data.dynamicsCase) {
                    // contactVM.contact.ticketId = 'testing_123';
                    this.contactVM.contact.ticketId = `${this.data.dynamicsCase.ticketnumber}`;

                    if (this.contactVM.contact.contactType === 'WorkItem') {
                        (this.contactVM as any).ticketId = this.data.dynamicsCase.incidentid;
                    } else {
                        (this.contactVM as any).ticketId = this.data.dynamicsCase.ticketnumber;
                    }

                    this.contactVM.contactService.currentContactViewModels.value
                        .filter(x => x.contact.contactId == this.contactVM.contact.contactId)
                        .forEach(x => {
                            x.contact.ticketId = this.data.dynamicsCase.ticketnumber;
                        });

                    this.addToProcessedContacts(this.contactVM);
                }
            });
        }
    }

    public createCrmTicketManuallay() {
        this.buildCrmTicketObject(this.contactVM, this.data)
            .pipe(
                mergeMap(dt => this.createCrmTicket(this.contactVM, this.data)),
                mergeMap(d1 => this.OpenCrmTicket(this.contactVM, this.data)),
            )
            .subscribe(res => {
                if (this.data.dynamicsCase) {
                    // contactVM.contact.ticketId = 'testing_123';
                    this.contactVM.contact.ticketId = `${this.data.dynamicsCase.ticketnumber}`;

                    if (this.contactVM.contact.contactType === 'WorkItem') {
                        (this.contactVM as any).ticketId = this.data.dynamicsCase.incidentid;
                    } else {
                        (this.contactVM as any).ticketId = this.data.dynamicsCase.ticketnumber;
                    }

                    this.contactVM.contactService.currentContactViewModels.value
                        .filter(x => x.contact.contactId == this.contactVM.contact.contactId)
                        .forEach(x => {
                            x.contact.ticketId = this.data.dynamicsCase.ticketnumber;
                        });

                    this.addToProcessedContacts(this.contactVM);
                }
            });
    }

    protected findCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable<any>(observer => {
            const searchField = viewmodel.configMap.zendeskSearchField;
            const searchOperator = viewmodel.configMap.zendeskSearchOperator;
            const value = viewmodel.contact.rawData[viewmodel.configMap.incontactSearchField];

            if (!value || value.trim().length == 0) {
                data.dynamicsUserResult = [];
                // data.userResult.count = 0;
                observer.next(data);
                observer.complete();
                return;
            }

            Microsoft.CIFramework.searchAndOpenRecords('contact', `?$filter=${searchField} eq '${value}'`, false)
                .then(
                    result => {
                        const userData: {[key: string]: DynamicsContactModel} = JSON.parse(result);
                        // console.log('search crm user result: ', userData, typeof userData, userData.length, Object.keys(userData));

                        data.dynamicsUserResult = [];
                        for (const key in userData) {
                            data.dynamicsUserResult.push(userData[key]);
                        }

                        // console.log(`Record values: Full Name: ${res[0].fullname}, Telephone Number: ${res[0].telephone1}`);
                        // perform operations on record retrieval and opening

                        if (data.dynamicsUserResult?.length == 1) {
                            data.dynamicsUser = data.dynamicsUserResult[0];
                        }
                        if (data.dynamicsUserResult.length === 0) {
                            this.messagesSrv.notify({
                                Type: 'info',
                                Subject: 'User Not found',
                                MessageText: 'No User found on Dynamics.',
                                timeout: 10000,
                            });
                        }
                        observer.next(data);
                        observer.complete();
                    },
                    error => {
                        console.log(error.message);
                        // handle error conditions
                    },
                )
                .catch(err => {
                    console.log('findContact Error: ', err);
                });
        });
    }

    protected createCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(r => {
            // try {
            //     window.zClient
            //         .request({
            //             url: '/api/v2/users.json',
            //             type: 'POST',
            //             data: {user: data.userObject},
            //         })
            //         .then(
            //             result => {
            //                 data.zendeskUser = result.user;
            //                 r.next(data);
            //             },
            //             error => {
            //                 // r.error(this.handleError('user', error));
            //                 // r.next(data);
            //             },
            //         );
            // } catch (error) {
            //     //throw this.handleError('user', error);
            //     //r.next(data);
            // } finally {
            //     //return subject;
            // }

            const jsonData = JSON.stringify(data.userObject);
            Microsoft.CIFramework.createRecord('contact', jsonData).then(
                result => {
                    const res = JSON.parse(result);

                    data.dynamicsUser = new DynamicsContactModel();
                    data.dynamicsUser.contactid = res.id;
                    // console.log('created user: ', res);

                    r.next(res);
                    r.complete();
                },
                error => {
                    console.log(error);
                    //handle error conditions

                    r.complete();
                },
            );
        });
    }

    protected OpenCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable<any>(observer => {
            const contactid = data.dynamicsUser.contactid;

            Microsoft.CIFramework.searchAndOpenRecords('contact', `?$filter=contactid eq '` + `${contactid}` + `'`, false)
                .then(
                    result => {
                        const userData: Array<DynamicsContactModel> = JSON.parse(result);
                        // console.log('search result: ', userData, typeof userData);
                        // data.dynamicsUserResult = userData;
                        // // console.log(`Record values: Full Name: ${res[0].fullname}, Telephone Number: ${res[0].telephone1}`);
                        // // perform operations on record retrieval and opening

                        // if (data.dynamicsUserResult?.length == 1) {
                        //     data.dynamicsUser = data.dynamicsUserResult[0];
                        // }
                        // if (userData?.length === 0) {
                        //     this.messagesSrv.notify({
                        //         Type: 'info',
                        //         Subject: 'User Not found',
                        //         MessageText: 'No User found on Dynamics.',
                        //         timeout: 10000,
                        //     });
                        // }

                        data.dynamicsUserResult = [];
                        for (const key in userData) {
                            data.dynamicsUserResult.push(userData[key]);
                        }

                        if (data.dynamicsUserResult?.length == 1) {
                            data.dynamicsUser = data.dynamicsUserResult[0];
                        }

                        observer.next(data);
                        observer.complete();
                    },
                    error => {
                        console.log(error.message);
                        // handle error conditions
                    },
                )
                .catch(err => {
                    console.log('findContact Error: ', err);
                });
        });
    }

    //bb002798-a605-ec11-94ef-000d3a59bf1c

    protected findCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable<any>(observer => {
            if (data.dynamicsUser) {
                Microsoft.CIFramework.searchAndOpenRecords('incident', `$filter=customerid_contact/contactid eq ${data.dynamicsUser.contactid}`, true).then(
                    contactRes => {
                        const caseResObj = JSON.parse(contactRes);
                        // console.log('incident response object: ', caseResObj);

                        data.dynamicsCaseResult = [];
                        for (const key in caseResObj) {
                            data.dynamicsCaseResult.push(caseResObj[key]);
                        }

                        if (data.dynamicsCaseResult?.length == 1) {
                            data.dynamicsCase = data.dynamicsCaseResult[0];
                            viewmodel.contact.ticketId = data.dynamicsCase.ticketnumber;
                        }

                        if (data.dynamicsCaseResult.length === 0) {
                            this.caseNotFound();
                        }

                        observer.next(data);
                        observer.complete();
                    },
                    error => {
                        console.log(error);
                        observer.complete();
                        //handle error conditions
                    },
                );
                //             // const jsonData = JSON.stringify({
                //             //     // 'primarycontactid@odata.bind': `/contacts(${data.dynamicsUser.contactid})`,
                //             //     'customerid_contact@odata.bind': `/contacts(${data.dynamicsUser.contactid})`,
                //             //     description: 'Testing create case functionality',
                //             //     title: 'demo title',
                //             // });
                //             // console.log('new case data', jsonData);
                //             Microsoft.CIFramework.searchAndOpenRecords('incident',
                // "?$filter=customerid_contact eq '" + `${data.dynamicsUser.contactid}` + "'", false).then(
                //                 contactRes => {
                //                     const contactResObj = JSON.parse(contactRes);

                //                     console.log('incident response object: ', contactResObj);
                //                 },
                //                 error => {
                //                     console.log(error);
                //                     //handle error conditions
                //                 },
                //             );
            } else {
                observer.complete();
            }
        });
    }

    protected getCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: any, id: string): Observable<any> {
        return new Observable<any>(observer => {
            Microsoft.CIFramework.retrieveRecord('incident', id + '').then(
                contactRes => {
                    const caseResObj = JSON.parse(contactRes);

                    console.log('getCrm ticket', caseResObj);

                    data.dynamicsCaseResult = [];
                    if (!!caseResObj) {
                        data.dynamicsCaseResult = [caseResObj];
                    }

                    if (data.dynamicsCaseResult?.length == 1) {
                        data.dynamicsCase = data.dynamicsCaseResult[0];
                        viewmodel.contact.ticketId = data.dynamicsCase.ticketnumber;
                    }

                    if (data.dynamicsCaseResult.length === 0) {
                        this.caseNotFound();
                    }

                    observer.next(data);
                    observer.complete();
                },
                error => {
                    this.caseNotFound();
                    console.log('error: ', error);
                    observer.complete();
                    //handle error conditions
                },
            );
        });
    }

    private caseNotFound() {
        this.messagesSrv.notify({
            Type: 'info',
            Subject: 'Case Not found',
            MessageText: 'No Case found on Dynamics.',
            timeout: 10000,
        });
    }

    protected OpenCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(observer => {
            Microsoft.CIFramework.searchAndOpenRecords('incident', `$filter=incidentid eq ${data.dynamicsCase.incidentid}`, false).then(
                contactRes => {
                    observer.next(data);
                    observer.complete();
                },
                error => {
                    console.log(error);
                    observer.complete();
                },
            );
        });
    }

    protected createCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(observer => {
            const obj = JSON.stringify(data.caseObject);
            Microsoft.CIFramework.createRecord('incident', obj).then(res => {
                const resObj = JSON.parse(res);
                // console.log('create incident res', resObj);

                if (resObj.id) {
                    Microsoft.CIFramework.searchAndOpenRecords('incident', `$filter=incidentid eq ${resObj.id}`, true).then(
                        contactRes => {
                            const caseResObj = JSON.parse(contactRes);

                            data.dynamicsCaseResult = [];
                            for (const key in caseResObj) {
                                data.dynamicsCaseResult.push(caseResObj[key]);
                            }

                            if (data.dynamicsCaseResult?.length == 1) {
                                data.dynamicsCase = data.dynamicsCaseResult[0];
                                viewmodel.contact.ticketId = data.dynamicsCase.ticketnumber;
                            }

                            observer.next(data);
                            observer.complete();
                        },
                        error => {
                            console.log(error);
                            observer.complete();
                            //handle error conditions
                        },
                    );
                } else {
                    observer.complete();
                }
            });

            // window.zClient
            //     .request({
            //         url: '/api/v2/tickets.json',
            //         type: 'POST',
            //         data: {ticket: data.ticketObject},
            //     })
            //     .then(tic => {
            //         data.ticket = tic.ticket;
            //         observer.next(data);
            //         viewmodel.contact.ticketId = tic.ticket.id;
            //         observer.complete();
            //     })
            //     .catch(error => {
            //         // observer.throw(error);
            //         observer.error(error);
            //     });
        });
    }

    protected buildCrmUserObject(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(oberver => {
            const obj: any = {};
            const settings = viewmodel.settings;
            const configMap = viewmodel.configMap;

            console.log('config map: ', configMap);
            // console.log('viewmodel : ', viewmodel);

            const icSearchFieldValue = viewmodel.getContactPropertyValueByKey(configMap.incontactSearchField ? configMap.incontactSearchField : 'ContactID');
            //data.contact[(configMap.incontactSearchField ? configMap.incontactSearchField : "ContactID")];
            const zdSearchFieldName = configMap.zendeskSearchField;
            obj.firstname = configMap.userTitle;
            obj.lastname = icSearchFieldValue;
            if (zdSearchFieldName) {
                obj[zdSearchFieldName] = icSearchFieldValue;
            }

            if (configMap.isCallContact && settings.e164enabled) {
                obj.shared_phone_number = true;
            }
            data.userObject = obj;

            oberver.next(data);
            oberver.complete();
        });
    }

    protected buildCrmTicketObject(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(oberver => {
            const contactSettings = viewmodel.settings; //this.getContactSettings(data.contact);
            const configMap = viewmodel.configMap;
            const phone = viewmodel.contact.rawData[configMap.incontactSearchField];
            const user = data.zendeskUser;

            const obj: DynamicsCaseModel = new DynamicsCaseModel({
                title: !contactSettings.ticketSubject ? `New Ticket` : viewmodel.replaceTokensWithValues(contactSettings.ticketSubject),
            });
            // if (contactSettings.defaultGroup && parseInt(contactSettings.defaultGroup)) {
            //     obj.group_id = parseInt(contactSettings.defaultGroup);
            // }
            // if (contactSettings.ticketTags) {
            //     obj.tags = viewmodel.replaceTokensWithValues(contactSettings.ticketTags);
            // }

            obj.description = '';
            if (viewmodel.contact.contactType == 'EmailContactEvent') {
                //obj.comment.body = this.getContactString(data, context);
                obj.description = viewmodel.toContactString();
            } else {
                //obj.comment.body = (!contactSettings.ticketComments ? 'New ticket details...' :
                // this.replaceTokensWithValues(contactSettings.ticketComments, data, context));
                obj.description = !contactSettings.ticketComments ? 'New ticket details...' : viewmodel.replaceTokensWithValues(contactSettings.ticketComments);
            }

            // obj.comment.public = false;
            // obj._customerid_value = data.dynamicsUser.contactid;
            obj.bindCustomer(data.dynamicsUser.contactid);

            // obj.assignee_id = this.currentZendeskUser.id;
            // obj.via_id = this.setTicketAudit(viewmodel.contact.rawData);

            data.caseObject = obj;

            oberver.next(obj);
        });
    }
}
