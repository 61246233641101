<div class="modal-header">
    <button type="button" class="close" (click)="dialog.close()" data-dismiss="modal">&times;</button>
    <h4 class="modal-title">Select Ticket</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-xs-12">
            <div style="max-width: 300px; margin: auto">
                <p-listbox
                    *ngIf="appContext.selectedCrmApplication?.applicationType === CRM_APPLICATION_TYPES.ZENDESK"
                    [options]="appContext.crmIntegrationService.CrmTicketsList"
                    [(ngModel)]="selectedTicket"
                    optionLabel="subject"
                    optionValue="id"
                    (onClick)="listItemClick($event)"
                ></p-listbox>

                <p-listbox
                    *ngIf="appContext.selectedCrmApplication?.applicationType === CRM_APPLICATION_TYPES.DYNAMICS"
                    [options]="appContext.crmIntegrationService.CrmTicketsList"
                    [(ngModel)]="selectedTicket"
                    optionLabel="title"
                    optionValue="ticketnumber"
                    (onClick)="listItemClick($event)"
                ></p-listbox>
            </div>
        </div>
        <div class="col-xs-12 p-mt-3">
            <div class="p-d-flex">
                <div class="p-ml-auto">
                    <!-- <p-button class="p-mr-2" label="Create Ticket" [disabled]="!selectedTicket" (click)="createTicket()"></p-button> -->
                    <p-button label="Ok" [disabled]="!selectedTicket" (click)="ok()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div>
