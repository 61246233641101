import {Component, OnInit} from '@angular/core';

import {Location} from '@angular/common';

import {integrationManagerService} from '../../core/uac-crm-integration/integrationManager.service';
import {EventLogService} from '../../core/_services/event-log.service';
import {ErrorService} from '../.././core/_services/error.service';

@Component({
    selector: 'app-report-feedback',
    templateUrl: './report-feedback.component.html',
    styleUrls: ['./report-feedback.component.css'],
})
export class ReportFeedbackComponent implements OnInit {
    problemDescription: string;

    constructor(
        private location: Location,
        private integrationManagerService: integrationManagerService,
        private eventLogService: EventLogService,
        private errorService: ErrorService,
    ) {}

    ngOnInit() {}

    close() {
        this.location.back();
    }

    sendReport() {
        const eventData = this.eventLogService.getEventData();

        const logMessage = {id: 1, message: 'error 1'};

        this.integrationManagerService.sendReport(this.problemDescription, eventData).subscribe(
            res => {
                if (res.status == '200') {
                    this.location.back();
                }
            },
            err => {
                this.errorService.onSystemError(err);
            },
        );
    }
}
