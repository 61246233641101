import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

// import { FocusDirective } from '../directives/focus.directive';

// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';

@NgModule({
    imports: [BrowserModule, FormsModule],
    declarations: [],
    exports: [],
    providers: [],
})
export class ErrorModule {}
