import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ContentChild,
    TemplateRef,
    AfterViewInit,
    EmbeddedViewRef,
    ViewChild,
    ViewContainerRef,
    OnDestroy,
    ElementRef,
    HostListener,
} from '@angular/core';
import {LbNgxDropdownConfigModel, LbNgxDropdownModel} from '../models';
import {AnyObject} from 'src/app/global';
import {Router, ChildrenOutletContexts} from '@angular/router';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'lb-ngx-dropdown',
    templateUrl: './lb-ngx-dropdown.component.html',
    styleUrls: ['./lb-ngx-dropdown.component.scss'],
})
export class LbNgxDropdownComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() type: 'strings' | 'objects' = 'objects';
    @Input() side: 'top-left' | 'bottom-left' | 'top-right' | 'bottom-right' = 'bottom-left';

    @Input() openers: Array<HTMLElement>;

    private _configs: LbNgxDropdownConfigModel;

    @Input() set configs(val: LbNgxDropdownConfigModel) {
        if (!val) {
            this._configs = new LbNgxDropdownConfigModel();
            return;
        }

        if (this.type === 'objects' && !val.idKey) {
            throw Error('idKey not provided');
        }
        if (this.type === 'objects' && !val.textKey) {
            throw Error('textKey not provided');
        }

        this._configs = new LbNgxDropdownConfigModel(val);
    }
    get configs() {
        return this._configs;
    }

    private _originalItems: Array<AnyObject> = [];
    private _items: Array<LbNgxDropdownModel> = [];

    @Input() set items(vals: Array<LbNgxDropdownModel>) {
        if (!vals) {
            throw Error('No Menu List provided');
        }

        this._originalItems = vals;

        if (this.type === 'strings') {
            this._items = vals.map(
                x =>
                    new LbNgxDropdownModel({
                        id: x as any,
                        text: x as any,
                    }),
            );
        } else {
            this._items = this.recursiveParseData(vals);
        }
    }
    get items() {
        return this._items;
    }

    private recursiveParseData(opts: Array<AnyObject>) {
        if (!opts || !opts.length) {
            return;
        }
        const menus: Array<LbNgxDropdownModel> = [];
        for (const opt of opts) {
            // console.log(opt);
            menus.push(
                new LbNgxDropdownModel({
                    id: opt[this.configs.idKey],
                    text: opt[this.configs.textKey],
                    icon: opt[this.configs.iconKey],
                    routerLink: opt.routerLink,
                    priority: opt[this.configs.orderBy],
                    items: this.recursiveParseData(opt[this.configs.childKey]),
                    original: opt,
                }),
            );
        }

        // console.log(opts.length, menus);

        if (this.configs.orderBy) {
            const sorted = menus.sort((a, b) => {
                let c = 0;
                if (a.priority > b.priority) {
                    c = 1;
                } else if (a.priority < b.priority) {
                    c = -1;
                }

                return c;
            });

            return sorted;
        }

        return menus;
    }

    @Output() menuClick: EventEmitter<any> = new EventEmitter();

    @ViewChild('dropdownToggleBtnTemplate', {static: false, read: ViewContainerRef})
    public dropdownToggleBtnTemplateHost: ViewContainerRef;

    @ContentChild('dropdownToggleBtnTemplate', {static: false}) dropdownBtnTemplateRef: TemplateRef<any>;
    dropdownToggleBtnTemplateEmbededView: EmbeddedViewRef<any>;

    @ViewChild('dropdownRoot') dropdownRoot: ElementRef;

    subSide = 'bottom';

    constructor(private _root: ElementRef, private router: Router) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.dropdownToggleBtnTemplateEmbededView = this.dropdownToggleBtnTemplateHost.createEmbeddedView(this.dropdownBtnTemplateRef);
        this.dropdownToggleBtnTemplateEmbededView.detectChanges();

        this.dropdownToggleBtnTemplateEmbededView.rootNodes[0].addEventListener('click', (event: any) => {
            if (!this.openers?.length) {
                this.toggleDropdown();
            }
        });
    }

    closeDropdown() {
        this.dropdownRoot?.nativeElement.classList.remove('open');
        this.closeAllSub();
    }
    closeAllSub() {
        (this.dropdownRoot?.nativeElement as HTMLElement).querySelectorAll('.lb-ngx-dropdown-item').forEach(x => {
            x.classList.remove('open-sub');
            x.classList.remove('left');
            x.classList.remove('right');
            x.classList.remove('open-visible');
        });
    }
    openDropdown() {
        this.dropdownRoot?.nativeElement.classList.add('open');
    }

    public toggleDropdown() {
        this.dropdownRoot?.nativeElement.classList.toggle('open');
    }

    @HostListener('click', ['$event'])
    onClick(event) {
        event.preventDefault();
    }

    @HostListener('document:click', ['$event'])
    documentClick(event: any) {
        for (const o of this.openers ?? []) {
            if (o.contains(event.target)) {
                this.toggleDropdown();
                return;
            }
        }
        if (!this._root.nativeElement.contains(event.target)) {
            this.closeDropdown();
        }
    }

    ngOnDestroy() {
        if (this.dropdownToggleBtnTemplateEmbededView) {
            this.dropdownToggleBtnTemplateEmbededView.detach();
            this.dropdownToggleBtnTemplateEmbededView.destroy();
        }
    }

    actionClick(dropdownAction: HTMLElement, menu: LbNgxDropdownModel) {
        if (menu.items?.length) {
            // console.log(window.outerWidth, dropdownAction.offsetWidth, dropdownAction.getBoundingClientRect());

            const childDropdown = this.getChildElement(dropdownAction, 'lb-ngx-dropdown-menu');

            if (childDropdown) {
                this.closeAllSub();
                // console.log(childDropdown.offsetWidth, childDropdown.getBoundingClientRect());
                // console.log(
                //     dropdownAction.getBoundingClientRect().x + dropdownAction.offsetWidth + childDropdown.offsetWidth,
                // );

                dropdownAction.classList.toggle('open-sub');

                const size = dropdownAction.getBoundingClientRect().x + childDropdown.offsetWidth + dropdownAction.offsetWidth + 10;

                if (this.subSide != 'bottom') {
                    if (window.outerWidth < size) {
                        dropdownAction.classList.remove('right');
                        dropdownAction.classList.add('left');
                    } else {
                        dropdownAction.classList.remove('left');
                        dropdownAction.classList.add('right');
                    }
                } else {
                    dropdownAction.classList.add('bottom');
                }

                dropdownAction.classList.toggle('open-visible');

                return;
            }
        }

        if (!!menu.routerLink) {
            this.router.navigate([menu.routerLink]);
            this.closeDropdown();
            return;
        }

        // const found = this._originalItems.find(x => x[this.configs.idKey] === menu.id);
        this.menuClick.emit(menu.original);

        this.closeDropdown();
    }

    private getChildElement(parent: HTMLElement, findClass: string) {
        let childDropdown: HTMLElement = null;

        parent.childNodes.forEach((x: HTMLElement) => {
            if (x?.classList?.contains(findClass)) {
                childDropdown = x;
            }
        });

        return childDropdown;
    }
}
