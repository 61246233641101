import {ContactBase} from './contact-base';

export class VoiceMailModel extends ContactBase {
    createdDate: Date;
    customData: string;
    fileDuration: number;
    fileName: string;
    from: string;
    to: string;
    type: string;
    voiceMailType: string;
    playBackPosition: number;
    playBackPaused: boolean;

    constructor(data: any) {
        super(data);

        this.contactType = 'Voicemail';
        this.contactIcon = 'volume-up';

        this.createdDate = data.CreatedDate;
        this.fileDuration = data.FileDuration;
        this.fileName = data.FileName;
        this.from = data.From;
        this.type = data.Type;
        this.voiceMailType = data.VoiceMailType;
        this.playBackPosition = data.PlayBackPosition;
        this.startTimeUTC = data.StartTime;

        this.playBackPaused = data.PlayBackPaused == 'True' || !data.PlayBackPaused;

        this.supportsEmailElevation = true;
        this.supportsPhoneElevation = true;
    }

    canPlay = () => this.playBackPosition === -1;

    toPlaybackTime(duration: number) {
        if (isNaN(duration) || duration < 0) {return '00:00';}

        const secs = Math.round(duration % 60);
        const min = Math.round(duration / 60);

        return (min < 10 ? '0' + min : min) + ':' + (secs < 10 ? '0' + secs : secs);
        /*
                if (secs < 10)
                    return min + "0" + secs;
                else
                    return min + ":" + secs;
         */
        /* if (duration < 60) {
            if (duration < 10)
                return ":0" + duration;
            else
                return ":" + duration;
        }
        else {
            let secs = duration % 60;
            let min = duration / 60;

            if (secs < 10)
                return min + "0" + secs;
            else
                return min + ":" + secs;
        } */
    }
}
