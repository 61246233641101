import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {IndicatorModel} from '.././InContact/models/indicators.model';
import {InContactAPIService} from '.././InContact/incontact-api.service';

@Injectable()
export class IndicatorsService {
    public indicatorEvents: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    private spaceSplitterRegx = /(?:[^\s"']+|['"][^'"]*["'])+/g;

    constructor(private inContactAPIService: InContactAPIService) {}

    executeIndicator(indicator: IndicatorModel) {
        switch (indicator.actionType) {
            case 'SpawnScript':
                this.processSpawnScriptIndicator(indicator);
                break;
            case 'OpenURL':
                this.processOpenUrlIndicator({ActionUri: indicator.action});
                break;
            case 'SignalScript':
                this.processSignalScriptIndicator({ActionUri: indicator.action, ContactID: indicator.senderContactId});
                break;
            case 'ShowCustomForm':
                this.processCustomForm({ActionUri: indicator.action, ContactID: indicator.senderContactId});
                break;
        }
    }

    /*
    --------------- Spawn Script---------------------------------------------------------------
    {
        "indicatorName":"inditest",
        "senderContactId":39079543111,
        "imageFile":"https://icons.incontact.com/incontact/images/indicator/computer.png",
        "actionType":"SpawnScript",
        "action":"\"Test Contacts Website\\indicatespawntargetsendsanemail\" 362218 \"ParameterText\" \"mutahir.mehmood@ps-pa.com\" \"\" \"\" \"\"",
        "toolTip":"spawn script",
        "enable":true,
        "targetType":"Agent"
    }
    ---Maps to
    {
        "scriptName":"Test Contacts Website\\indicatespawntargetsendsanemail",
        "scriptId":"25046327",
        "skillId":362218,
        "parameters":"ParameterText|mutahir.mehmood@ps-pa.com|||",
        "startDate":""
    }
    */
    private processSpawnScriptIndicator(spawnScriptIndicator: any): Subject<any> {
        const bhv = new Subject();
        const actionArray = (spawnScriptIndicator.action || spawnScriptIndicator.ActionUri).match(this.spaceSplitterRegx);
        const scriptName = actionArray[0].replace(/"/g, '');
        const skillId = actionArray[1].replace(/"/g, '');
        const parametersArray = actionArray.slice(2, actionArray.length);
        const parametersText = parametersArray.join('|').replace(/"/g, '');

        this.inContactAPIService.getScriptsByName(scriptName).subscribe(scripts => {
            if (scripts.length > 0) {
                const script = scripts[0];
                const parameters: any = {
                    parameters: parametersText,
                    scriptId: script.scriptId,
                    scriptName,
                    skillId,
                    startDate: '',
                };
                this.inContactAPIService.startScript(script.scriptId, skillId, parameters).subscribe(resp => {
                    console.log(resp);
                    bhv.next(resp);
                });
            }
        });

        return bhv;
    }

    processIndicatorEvent(event: any) {
        const indicatorValues = this.indicatorEvents.getValue();
        const existing = indicatorValues.filter(x => x.Name == event.Name);
        if (existing.length == 1) {
            const index = indicatorValues.indexOf(existing[0]);
            if (index != -1) {
                indicatorValues[index] = event;
            }
        } else {
            indicatorValues.push(event);
        }
        this.indicatorEvents.next(indicatorValues.filter(val => val.IndicatorState == 'On'));
    }

    executeIndicatorFromEvent(indicatorEvent: any) {
        switch (indicatorEvent.ActionType) {
            case 'SignalScript':
                this.processSignalScriptIndicator(indicatorEvent);
                break;
            case 'OpenURL':
                this.processOpenUrlIndicator(indicatorEvent);
                break;
            case 'SpawnScript':
                this.processSpawnScriptIndicator(indicatorEvent);
                break;
            default:
                break;
        }
    }

    processSignalScriptIndicator(indicatorEvent: any) {
        const actionUriArray = indicatorEvent.ActionUri.match(this.spaceSplitterRegx);
        const parameters = {};
        for (let index = 1; index < actionUriArray.length; index++) {
            const element: string = actionUriArray[index].replace(/"/g, '');
            if (!element || element.trim().length == 0) {
                continue;
            }
            parameters['p' + index] = element;
        }
        this.inContactAPIService.signalScript(indicatorEvent.ContactID, parameters).subscribe(result => {
            console.log(result);
        });
    }

    processOpenUrlIndicator(indicatorEvent: any) {
        open(indicatorEvent.ActionUri, '_blank');
    }

    processCustomForm(indicatorEvent: any) {
        const win = open('', 'win', 'width=500,height=600');
        win.document.open('text/html', 'replace');
        win.document.write(indicatorEvent.ActionUri);
        win.document.close();
    }
}
