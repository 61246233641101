import {NgModule} from '@angular/core';
import {LbNgxDropdownComponent} from './dropdown/lb-ngx-dropdown.component';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [LbNgxDropdownComponent],
    exports: [CommonModule, LbNgxDropdownComponent],
    providers: [],
})
export class LbNgxDropdownModule {
    constructor() {}
}
