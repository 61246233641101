import {AnyObject} from 'src/app/global';

export class AnsweringMachineModel {
    use: boolean;
    startDelay: number;
    recordDuration: number;
    newRecord: boolean;

    constructor(data?: Partial<AnsweringMachineModel>) {
        Object.assign(this, data);
    }
}
interface VideoConfigModel {
    size: string;
    top: string;
    left: string;
}
export class VideoCustomModel {
    local: VideoConfigModel;
    remote: VideoConfigModel;

    constructor(data?: Partial<VideoCustomModel>) {
        Object.assign(this, data);
    }
}

export class ConferenceModel {
    layout: string;
    fps: number;
    size: string;

    constructor(data?: Partial<ConferenceModel>) {
        Object.assign(this, data);
    }
}

export class UserPreferencesModel {
    public hideLocalVideo: boolean;
    public videoSize: string;
    public answeringMachine: AnsweringMachineModel;
    public videoCustom: VideoCustomModel;
    public conference: ConferenceModel;
    public version: string;
    public selectedRing: string;

    constructor(data?: Partial<UserPreferencesModel>) {
        if (data?.answeringMachine && !(data.answeringMachine instanceof AnsweringMachineModel)) {
            data.answeringMachine = new AnsweringMachineModel(data.answeringMachine);
        }
        Object.assign(this, data);
    }
}
