export class FieldConfigModel {
    type?: string;
    min?: number;
    max?: number;
    tabindex?: number;
    id?: number;
    iconClass?: string;

    constructor(data?: Partial<FieldConfigModel>) {
        Object.assign(this, data);
    }
}
