import {DynamicsActivityPartyModel} from './dynamics-activity-party.model';

export class DynamicsEnvironmentModel {
    // appUrl: "https://frontlinesandbox.crm.dynamics.com/main.aspx?appid=3db19a12-3ab3-e811-a95f-000d3a360c68"
    public appid: string;
    public clientUrl: string;
    // crmVersion: "9.2.21082.00150"
    // customParams: null
    // etn: "incident"
    // id: "7382a751-bd09-ec11-b6e6-002248043e41"
    // isDirty: false
    // orgId: "33ec5698-c8bd-41bf-bbf4-f338021f8b26"
    // orgLcid: 1033
    // orgUniqueName: "frontlinesandbox"
    // pagetype: "entityrecord"
    public userId: string;
    // userLcid: 1033
    public username: string;

    constructor(data?: Partial<DynamicsEnvironmentModel>) {
        Object.assign(this, data);
    }

    public toActivityPartyModel(typemask: number) {
        const party = new DynamicsActivityPartyModel({
            participationtypemask: typemask,
        });

        party.bindWithSystemUser(this.userId);
        return party;
    }
}
