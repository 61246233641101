import {throwError as observableThrowError, Observable, BehaviorSubject} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable, Inject, Optional} from '@angular/core';
import {InContactApiSettings} from '../../../core/InContact/incontact-api-settings';
import {AuthenticationContextModel} from '../../../core/InContact/models/authentication-context.model';
import {FacLocalStorageService} from '../../storage/fac-local-storage.service';
import {AuthenticationContextService} from '../../../core/InContact/_services/authentication-context.service';
import {BaseApi, CustomErrorHandler} from 'src/app/sdk/core';
import {HttpHeaders, HttpClient} from '@angular/common/http';

@Injectable()
export class ResourceOwnerPasswordAuthorizerApi extends BaseApi {
    // private userName: string;
    // private password: string;
    private authContext: AuthenticationContextModel;

    // private firstSessionId: string;
    // private sessionId: string;

    constructor(
        @Inject(HttpClient) protected httpClient: HttpClient,
        private authContextService: AuthenticationContextService,
        @Inject(FacLocalStorageService) protected facLocalStorageSrv: FacLocalStorageService,
        @Optional() @Inject(CustomErrorHandler) protected errorHandler: CustomErrorHandler,
    ) {
        super(httpClient, facLocalStorageSrv, errorHandler);

        this.authContext = authContextService.getAuthContext();
        // this.userName = authContextService.getUserName();
        // this.password = authContextService.getPassword();
    }

    // OnSuccess(res: Response) {
    //     return res.json(); //JSON.stringify(res.json());
    // }

    // OnError(error: Response | any) {
    //     let errMsg: string;
    //     if (error instanceof Response) {
    //         const body = error.json() || '';
    //         const err = body.error || JSON.stringify(body);

    //         errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    //     } else {
    //         errMsg = error.message ? error.message : error.toString();
    //     }
    //     console.log(errMsg);
    //     return observableThrowError(error);
    // }

    sendHttpPost(relativeUrl: string, params: JSON): Observable<any> {
        this.authContext = this.facLocalStorageSrv.getSettings();
        /*let fullUri = this.authContext.resource_server_base_uri + relativeUrl;
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authContext.access_token, 'Content-Type': 'application/json' });

        let options = new RequestOptions({ headers: headers });

        return this.http.post(fullUri, params, options)
            .map(this.OnSuccess)
            .catch((err)=>this.onSendRequestRawError(err));
        */
        return this.sendHttpPostWithToken(relativeUrl, params, this.authContext.access_token, this.authContext.resource_server_base_uri, 'POST');
    }

    sendHttpPostWithToken(relativeUrl: string, params: JSON, access_token: string, serverUri: string, method: string): Observable<any> {
        this.authContext = this.facLocalStorageSrv.getSettings();
        const fullUri = serverUri + relativeUrl;
        // let headers = new Headers({Authorization: 'Bearer ' + access_token, 'Content-Type': 'application/json'});

        // let options = new RequestOptions({headers: headers});

        if (method == 'PUT') {
            return this.PUT_Request(fullUri, null, null, params, (headers: HttpHeaders) => {
                headers = headers.set('Authorization', `Bearer ${access_token}`);
                return headers;
            });

            // return this.http.put(fullUri, params, options).pipe(
            //     map(this.OnSuccess),
            //     catchError(err => this.onSendRequestRawError(err)),
            // );
        } else {
            return this.POST_Request(fullUri, null, null, params, (headers: HttpHeaders) => {
                headers = headers.set('Authorization', `Bearer ${access_token}`);
                return headers;
            });
            // return this.http.post(fullUri, params, options).pipe(
            //     map(this.OnSuccess),
            //     catchError(err => this.onSendRequestRawError(err)),
            // );
        }
    }

    sendHttpGet<T>(relativeUrl: string): Observable<T> {
        this.authContext = this.facLocalStorageSrv.getSettings();
        let fullUri = '';

        if (this.authContext) {
            fullUri = this.authContext.resource_server_base_uri + relativeUrl;
        }

        // let headers = new Headers({
        //     Authorization: 'Bearer ' + this.authContext.access_token,
        //     'Content-Type': 'application/json',
        // });

        // let options = new RequestOptions({headers: headers});

        return this.GET_Request<T>(fullUri, null, null);

        // return this.http.get(fullUri, options).pipe(
        //     map(this.OnSuccess),
        //     catchError(err => this.onSendRequestRawError(err)),
        // );
    }

    sendHttpPut(relativeUrl: string, params: JSON): Observable<any> {
        this.authContext = this.facLocalStorageSrv.getSettings();
        const fullUri = this.authContext.resource_server_base_uri + relativeUrl;

        // let headers = new Headers({
        //     Authorization: 'Bearer ' + this.authContext.access_token,
        //     'Content-Type': 'application/json',
        // });

        // let options = new RequestOptions({headers: headers});

        // return this.http.put(fullUri, params, options).pipe(
        //     map(this.OnSuccess),
        //     catchError(err => this.onSendRequestRawError(err)),
        // );

        return this.PUT_Request(fullUri, null, null, params);
    }

    sendHttpDelete(relativeUrl: string, params: JSON): Observable<any> {
        this.authContext = this.facLocalStorageSrv.getSettings();

        const fullUri = this.authContext.resource_server_base_uri + relativeUrl;
        // let headers = new Headers({
        //     Authorization: 'Bearer ' + this.authContext.access_token,
        //     'Content-Type': 'application/json',
        // });

        // let options = new RequestOptions({headers: headers, body: params || {}});

        // return this.http.delete(fullUri, options).pipe(
        //     map(this.OnSuccess),
        //     catchError(err => this.onSendRequestRawError(err)),
        // );

        return this.DELETE_Request(fullUri, null, null);
    }

    onSendRequestRawError(error: Response | any) {
        const self = this;
        let errMsg: string;
        if (error instanceof Response) {
            console.log(error);

            if (error.status == 401 && error.statusText == 'Unauthorized') {
                console.log('Session End Error:');
            } else {
                errMsg = `${error.status} - ${error.statusText || ''} ${error}`;

                if (error.status !== 304) {
                    console.log(errMsg);
                }
            }
            //Return the Error
            return observableThrowError(error);
        }
    }
}
