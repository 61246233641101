import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {Router} from '@angular/router';

import {InContactAPIService} from '.././InContact/incontact-api.service';
import {AuthenticationContextService} from '.././InContact/_services/authentication-context.service';
import {Events} from '.././InContact/models/events.model';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {CallContactService} from '.././_services/call-contact.service';
import {ChatContactService} from '.././_services/chat-contact.service';
import {EmailContactService} from './email-contact.service';
import {WorkItemContactService} from '.././_services/work-item-contact.service';
import {VoiceMailContactService} from '../_services/voice-mail-contact.service';
import {CallbackService} from '../_services/callbacks.service';

import {MessageService} from '.././_services/message.service';
import {ErrorService} from '.././_services/error.service';
import {EventLogService} from './event-log.service';
import {IndicatorsService} from './indicators.service';
import {integrationManagerService} from '../uac-crm-integration/integrationManager.service';
import {ContactDataContainerService} from '../../core/_services/contactDataContainer.service';
import {MarqueeMessagesService} from './marquee-messages-service';
import {AutomatedOutboundService} from './automated-outbound.service';
import {PageOpenService} from './pageOpen.service';
import {RunAppService} from './runapp.service';

import {Agent} from '../_models/agent.model';
import {IcAuthService} from 'src/app/sdk/incontact/services';
import {WebRTCService} from '../InContact/web-rtc/web-rtc.service';

//import { Observable } from 'rxjs';

@Injectable()
export class NextEventService {
    // Observable string sources
    private events = new Subject<Events>();
    private eventModel: Events[];
    public nextEventTimeout: any;

    // Observable string streams
    updateEvent$ = this.events.asObservable();

    constructor(
        private inContactAPIService: InContactAPIService,
        private icAgentSessionSrv: IcAgentSessionService,
        private callContactService: CallContactService,
        private chatContactService: ChatContactService,
        private emailContactService: EmailContactService,
        private workItemContactService: WorkItemContactService,
        private voiceMailContactService: VoiceMailContactService,
        private messageService: MessageService,
        private errorService: ErrorService,
        private eventLogService: EventLogService,
        private router: Router,
        private integrationManager: integrationManagerService,
        private authenticationContextService: AuthenticationContextService,
        private contactDataContainer: ContactDataContainerService,
        private indicatorsService: IndicatorsService,
        private marqueeMessagesService: MarqueeMessagesService,
        private automatedOutboundService: AutomatedOutboundService,
        private callbackService: CallbackService,
        private pageOpenService: PageOpenService,
        private runAppService: RunAppService,
        private icAuthSrv: IcAuthService,
        private webRtcSrv: WebRTCService,
    ) {
        this.contactDataContainer.serviceError.subscribe(err => {
            if (err !== null) {
                this.errorService.onSystemError(err);
            }
        });

        this.errorService.clearError();
    }

    // Service message commands
    updateEvent(event: Events) {
        this.events.next(event);
    }

    GetNextEvent() {
        this.eventModel = [];
        const sessionStarted = this.icAgentSessionSrv.isSessionStarted.getValue();

        if (sessionStarted) {
            this.inContactAPIService.NextEvents().subscribe(
                res => {
                    if (res.events) {
                        this.eventModel = res.events;
                        this.ProcessEvent(this.eventModel);

                        this.GetNextEvent(); //Continue to get next event loop
                    }
                },
                err => {
                    this.onGetNextEventsError(err);
                },
            );
        } else {
            //this.router.navigate(['/login']);
        }
    }

    onGetNextEventsError(err: any) {
        if (err.status === '404' || err.status === '409' || err.status === 404 || err.status === 409) {
            clearTimeout(this.nextEventTimeout);
            this.icAgentSessionSrv.clearLocalStorage();
            this.icAgentSessionSrv.onSessionEnd(null);
            this.router.navigate(['/login']);
        } else if (err.status === '0' || err.status === 0) {
            if (this.nextEventTimeout) {
                clearTimeout(this.nextEventTimeout);
            }

            this.nextEventTimeout = setTimeout(() => {
                //wait for 30 sec next event;
                this.GetNextEvent(); //Continue to get next event loop
            }, 10000);

            this.errorService.onSystemError(err);
        } else if (err.status === 401 && this.authenticationContextService.tryReloadState() !== null) {
            this.reAuthenticateUser();
        } else {
            this.GetNextEvent(); //Continue to get next event loop
        }
    }

    ProcessEvent(eventModelArray: Events[]) {
        eventModelArray.forEach(event => {
            /* this.eventLogService.logEventData(event);
            try {

                this.integrationManager.crmIntegration.handleIncontactEvent(event);
            }
            catch (e) {
                console.log("Error is catched in next-event service: " + e);
                //this.errorService.onAPIError
            } */
            try {
                switch (event.Type || (event as any).type) {
                    case 'AgentState':
                        this.icAgentSessionSrv.ProcessChangeState(event);
                        break;
                    case 'AgentSessionStart':
                        this.icAgentSessionSrv.processStartSession(event);
                        break;
                    case 'AgentSessionEnd':
                        this.icAgentSessionSrv.onSessionEnd(event);
                        break;
                    case 'CallContactEvent':
                        if (this.icAgentSessionSrv.isWebRTCSession) {
                            this.webRtcSrv.dexieLogsSrv.info('CallContactEvent WebRTC', {event});
                        }
                        //this.callContactService.processCallContact(event);
                        this.callContactService.processContactEvent(event);
                        break;
                    case 'WorkItemContactEvent':
                        this.workItemContactService.processContactEvent(event);
                        break;
                    case 'Mute':
                        this.callContactService.processMute(event);
                        //alert("Implemente mute");
                        break;
                    case 'ChatContactEvent':
                        this.chatContactService.processContactEvent(event);
                        break;
                    case 'ChatText':
                        this.chatContactService.processChatText(event);
                        break;
                    case 'UpdateChatTranscript':
                        this.chatContactService.getChatTranscript(event);
                        break;
                    case 'EmailContactEvent':
                        this.emailContactService.processContactEvent(event);
                        break;
                    case 'VoiceMailContactEvent':
                        this.voiceMailContactService.processContactEvent(event);
                        break;
                    case 'VoiceMailPlayBackEvent':
                        this.voiceMailContactService.processPlaybackEvent(event);
                        break;
                    case 'AgentLeg':
                        // if (event.Status === 'Dialing') {
                        //     this.webRtcSrv.agentLeg = (event as any).AgentLegId;
                        // }
                        this.webRtcSrv.setAgentLeg(event);
                        this.icAgentSessionSrv.updateLegStatusLabel(event.Status);
                        break;
                    case 'HoursOfOperation':
                        break;
                    case 'UpdateDialerCampaigns':
                        this.icAgentSessionSrv.processUpdateSkills(event);
                        break;
                    case 'UpdateIndicators':
                        this.icAgentSessionSrv.processUpdateIndicators(event);
                        break;
                    case 'Indicator':
                        this.indicatorsService.processIndicatorEvent(event);
                        break;
                    case 'UpdateMessages':
                        this.marqueeMessagesService.UpdateMarqueeMessages(event);
                        break;
                    case 'NaturalCallingSkillList':
                        this.automatedOutboundService.proccessNaturalCallingSkillListEvent(event);
                        break;
                    case 'ChatPatronTyping':
                        this.chatContactService.processChatPatronTyping(event);
                        break;
                    case 'UpdateCallbacks':
                        this.callbackService.processUpdateCallbacksEvent(event);
                        break;
                    case 'PageOpen':
                        this.pageOpenService.handlePageOpenEvent(event);
                        break;
                    case 'RunApp':
                        this.runAppService.handleRunAppEvent(event);
                        break;
                    case 'PromiseKeeper':
                        this.callbackService.processPromiseKeeperEvent(event);
                        break;
                    case 'CustomEvent':
                        this.webRtcSrv.processWebRtcEvent(event as any);
                        break;
                    default:
                        break;
                }
                this.updateEvent(event);
                this.errorService.clearError();
                this.messageService.setWindowTitle.next('__Event');
            } catch (error) {
                this.errorService.onSystemError(error);
            }
        });
    }

    reAuthenticateUser() {
        const agent = this.icAgentSessionSrv.loadAgent();
        this.icAuthSrv.authenticateUser(agent.userName, agent.password).subscribe(res => {
            this.icAgentSessionSrv
                .startSession(agent.sessionType, agent.sessionValue)
                .then(sessionStart => {
                    this.GetNextEvent();
                })
                .catch(err => {
                    if (err.status === 409) {
                        this.icAgentSessionSrv
                            .joinSession(agent.sessionType)
                            .then(join => {
                                this.GetNextEvent();
                            })
                            .catch(sessionErr => {});
                    }
                });
        });
    }
}
