export class AddressBookEntriesModel {
    addressBookEntryId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    company: string;
    phone: string;
    mobile: string;
    email: string;
}
