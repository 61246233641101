import {NgModule} from '@angular/core';
import {Router} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {ScheduledCallbacksRoutingModule} from './scheduled-callbacks.routing';
import {ScheduledCallbacksComponent} from './scheduled-callbacks.component';
import {CreateScheduledCallbacksFormComponent} from './create-schedule-callback-form/callbacks.form.component';
// import {Ng2Bs3ModalModule} from 'ng2-bs3-modal/ng2-bs3-modal';

import {SharedModule} from '../../shared/shared.module';
import {PreviewRescheduleComponent} from './preview-reschedule/preview-reschedule.component';
// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        SharedModule,
        ScheduledCallbacksRoutingModule,
        // Ng2Bs3ModalModule
    ],
    declarations: [ScheduledCallbacksComponent, CreateScheduledCallbacksFormComponent, PreviewRescheduleComponent],
    exports: [PreviewRescheduleComponent],
    providers: [],
    entryComponents: [PreviewRescheduleComponent],
})
export class ScheduledCallbacksModule {}
