<div style="overflow: visible" class="app-footer uac-nav uac-bottom-nav">
    <ul class="pull-left nav nav-pills">
        <li>
            <a routerLinkActive="active" class="a-linkcolor" routerLink="/active-contact">
                <i class="fa fa-home fa-2x" aria-hidden="true"></i><br /><small>Home</small>
                <span class="sr-only" id="Active Contacts">{{ 'App_Component.Footer.CountContact' | translate }}</span>
                <span class="badge badge-align">{{ totalActiveContacts }}</span>
            </a>
        </li>
        <li class="dropup">
            <a
                role="link"
                title="Indicate"
                class="a-linkcolor cursor-pointer"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-controls="menu2"
                aria-expanded="false"
                href="javascript:void(0)"
                *ngIf="agentIndicators.length > 0"
                style="padding-left: 10px !important; padding-right: 10px !important"
            >
                <i class="fa fa-bolt footer-icon-padding fa-2x" aria-hidden="true"></i>
            </a>
            <ul id="menu2" role="menu" aria-labelledby="new-button" class="dropdown-menu dropdown-menu-left more-menu" aria-expanded="false">
                <li role="menuitem" class="menu-item" *ngFor="let indicator of agentIndicators">
                    <a
                        role="link"
                        style="color: #000; width: 100%"
                        href="javascript:void(0)"
                        class="scrollable-menu-uac-items"
                        (click)="icAgentSessionSrv.executeIndicator(indicator)"
                    >
                        <img *ngIf="indicator.imageFile && indicator.imageFile.length > 0" src="{{ indicator.imageFile }}" title="indicator.toolTip" />
                        {{ indicator.toolTip }}
                    </a>
                </li>
            </ul>
        </li>
    </ul>
    <ul class="pull-right nav nav-pills">
        <li>
            <a role="link" title="{{ 'App_Component.Footer.Queue' | translate }}" routerLink="/queue" routerLinkActive="active" class="a-linkcolor">
                <i class="fa fa-list-ol fa-2x footer-icon-padding" aria-hidden="true"></i>
                <br /><small>Queue</small>
            </a>
        </li>
        <li>
            <a role="link" title="{{ 'App_Component.Footer.Dialpad' | translate }}" routerLink="/dialpad" routerLinkActive="active" class="a-linkcolor">
                <i class="fa fa-th fa-2x footer-icon-padding" aria-hidden="true"></i>
                <br /><small>Dialpad</small>
            </a>
        </li>
        <li>
            <a
                role="link"
                title="{{ 'App_Component.Footer.AddressBook' | translate }}"
                routerLink="/address-book"
                routerLinkActive="active"
                class="a-linkcolor"
            >
                <i class="fa fa-address-book fa-2x footer-icon-padding" aria-hidden="true"></i>
                <br /><small>Contacts</small>
            </a>
        </li>
        <li>
            <a
                *ngIf="false"
                role="link"
                title="{{ 'App_Component.Footer.CallHistory' | translate }}"
                routerLink="/call-history"
                routerLinkActive="active"
                class="a-linkcolor"
            >
                <i class="fa fa-tty fa-2x footer-icon-padding" aria-hidden="true"></i>
            </a>
        </li>
        <li>
            <a
                role="link"
                title="{{ 'App_Component.Footer.AutomatedOutbound' | translate }}"
                *ngIf="false && personalConnectionEnabled"
                routerLink="/automated-outbound"
                routerLinkActive="active"
                class="a-linkcolor"
            >
                <i class="fa fa-stream fa-2x footer-icon-padding" aria-hidden="true"></i>
            </a>
        </li>
        <li>
            <a role="link" title="{{ 'App_Component.Footer.Callbacks' | translate }}" routerLink="/callbacks" routerLinkActive="active" class="a-linkcolor">
                <i class="fa fa-calendar fa-2x footer-icon-padding" aria-hidden="true"></i>
                <br /><small>Schedule</small>
            </a>
        </li>
        <li>
            <a
                role="link"
                title="{{ 'App_Component.Footer.AgentInfo' | translate }}"
                routerLink="/agent-info"
                routerLinkActive="active"
                class="a-linkcolor"
                *ngIf="false"
            >
                <i class="glyphicon glyphicon-info-sign fa-2x" aria-hidden="true"></i>
            </a>
        </li>
        <li class="dropup">
            <a role="link" title="Indicate" class="a-linkcolor" data-toggle="dropdown" aria-haspopup="true" aria-controls="menu1" aria-expanded="false">
                <i class="fa fa-ellipsis-h fa-2x footer-icon-padding" aria-hidden="true"></i>
                <br />
                <small> More </small>
                <span class="sr-only" id="Indicators">{{ 'App_Component.Footer.CountContact' | translate }}</span>
                <!-- <span class="badge badge-align">{{agentIndicators.length}}</span> -->
            </a>
            <ul id="menu1" role="menu" aria-labelledby="new-button" class="dropdown-menu dropdown-menu-right more-menu" aria-expanded="false">
                <li class="menu-item">
                    <a
                        role="link"
                        title="{{ 'App_Component.Footer.CallHistory' | translate }}"
                        routerLink="/call-history"
                        routerLinkActive="active"
                        class="a-linkcolor"
                    >
                        <i class="fa fa-tty footer-icon-padding" aria-hidden="true"></i>
                        Call History
                    </a>
                </li>
                <li role="menuitem" class="menu-item">
                    <a
                        role="link"
                        title="{{ 'App_Component.Footer.AutomatedOutbound' | translate }}"
                        *ngIf="personalConnectionEnabled"
                        routerLink="/automated-outbound"
                        routerLinkActive="active"
                        class="a-linkcolor"
                    >
                        <i class="fa fa-stream" aria-hidden="true"></i>
                        Automated Outbound
                    </a>
                </li>
                <li role="menuitem" class="menu-item">
                    <a role="link" routerLink="/agent-info">
                        <i class="glyphicon glyphicon-info-sign" aria-hidden="true"></i>
                        Agent Info
                    </a>
                </li>
                <li role="menuitem" class="menu-item" (click)="SettingsClick()">
                    <a role="link" href="javascript:void(0)">
                        <i class="glyphicon glyphicon-wrench"></i>
                        Settings
                    </a>
                </li>
                <li *ngIf="false" role="separator" class="divider"></li>
                <!-- <li *ngIf="false">
                    <a href="javascript:;" (click)="confirmEndSession()" style="color: red"
                        ><i class="fa fa-power-off"></i> <span>Sign Out</span></a
                    >
                </li> -->
            </ul>
        </li>
    </ul>
</div>
