import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AddressBookModel} from '../.././core/_models/address-book.model';
import {AddressBookEntriesModel} from '../.././core/_models/address-book-entries.model';
import {InContactAPIService} from '../.././core/InContact/incontact-api.service';

@Injectable()
export class AddressBookService {
    constructor(private inContactAPIService: InContactAPIService) {}

    getAddressBook(agentId: number): Observable<AddressBookModel[]> {
        return this.inContactAPIService.getAddressBook(agentId);
    }

    getAddressBookContacts(bookId: number, type?: string): Observable<AddressBookEntriesModel[]> {
        return this.inContactAPIService.getAddressBookContacts(bookId, type == 'Dynamic');
    }
}
