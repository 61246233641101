import {BaseApi} from 'src/app/sdk/core/base.api';
import {Inject, Optional, Injectable} from '@angular/core';
import {FacLocalStorageService} from 'src/app/sdk/storage/fac-local-storage.service';
import {CustomErrorHandler} from 'src/app/sdk/core/error.handler';
import {InContactSdkConfig} from 'src/app/sdk/sdk.config';
import {SecurityProfileModel} from '../../models';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SecurityProfileApi extends BaseApi {
    constructor(
        @Inject(HttpClient) protected httpClient: HttpClient,
        @Inject(FacLocalStorageService) protected facLocalStorageSrv: FacLocalStorageService,
        @Optional() @Inject(CustomErrorHandler) protected errorHandler: CustomErrorHandler,
    ) {
        super(httpClient, facLocalStorageSrv, errorHandler);
    }

    public getSecurityProfile(profileId: string) {
        const url = `${this.facLocalStorageSrv.resource_server_base_uri}services/v18.0/security-profiles/:profileId`;
        return this.GET_Request<SecurityProfileModel>(url, {profileId});
    }
}
