import {Injectable} from '@angular/core';

@Injectable()
export class SpinnerService {
    public isRunning: boolean;

    constructor() {
        this.isRunning = false;
    }
}
