import {Component, OnInit, HostListener, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {ContactHistoryModel} from '../../core/_models/contact-history.model';
import {Agent} from '../../core/_models/agent.model';
import {HotKeyPressSerivce} from '../../core/_services/hot-key-press.service';
import {CallContactService} from '../../core/_services/call-contact.service';

@Component({
    selector: 'app-call-history',
    templateUrl: './call-history.component.html',
    styleUrls: ['./call-history.component.css'],
})
export class CallHistoryComponent implements OnInit {
    keyword: string;
    daycount: number;
    orderBy: string;
    contacthistory: ContactHistoryModel[];
    agent: Agent;

    constructor(
        private agentSessionService: IcAgentSessionService,
        private router: Router,
        private hotKeyPressSerivce: HotKeyPressSerivce,
        private callContactService: CallContactService,
    ) {
        // this.orderBy = "contactStartDate";

        this.agent = new Agent();
        this.agent.outboundSkills = [];

        this.agentSessionService.agentOutboundSkills.subscribe(skills => {
            this.agent.outboundSkills = skills.filter(
                e => e.isActive === 'True' && e.mediaTypeId === '4' && e.isNaturalCalling === 'False',
            );
        });
    }

    ngOnInit() {
        this.keyword = '';
        this.daycount = 0;
        this.orderBy = '0';
        this.getHistory();
    }

    onDateFilterChange(value: number) {
        this.daycount = value;
        this.getHistory();
    }

    onOrderByFilterChange(value: string) {
        this.orderBy = value;
        this.getHistory();
    }

    getHistory() {
        let orderByString: string;
        this.contacthistory = [];
        const agentId = this.agentSessionService.getSessionInfo().AgentId;
        const d = new Date();

        if (this.orderBy === '0') {orderByString = 'contactStartDate';}
        if (this.orderBy === '1') {orderByString = 'fromAddr';}
        if (this.orderBy === '2') {orderByString = 'skillName';}

        if (!this.daycount) {
            this.daycount = 0;
        }
        const curr_date = d.getDate();
        let curr_month = d.getMonth();
        curr_month++;
        const curr_year = d.getFullYear();
        const m_date = new Date(d.setDate(d.getDate() - this.daycount));
        const sdate = m_date.getFullYear() + '-' + (m_date.getMonth() + 1) + '-' + m_date.getDate() + 'T00:00:00Z';
        const edate = curr_year + '-' + curr_month + '-' + curr_date + 'T23:59:59Z';

        this.agentSessionService.getContactHistory(agentId, sdate, edate, orderByString).subscribe(history => {
            this.contacthistory = history;
        });
    }

    dialPhone(number: string, skillName: string) {
        this.callContactService.dialPhone(number, skillName);
    }

    returnCall(phoneNumber: string) {
        if (phoneNumber !== '') {this.router.navigate(['/dialpad', {phoneNumber}]);}
    }

    @ViewChild('listItem') listItem: ElementRef;
    @HostListener('window:keydown', ['$event'])
    hotkeys(event: KeyboardEvent) {
        const key = event.keyCode;
        if (this.hotKeyPressSerivce.altPress(event)) {
            switch (key) {
                case 76: //L
                    $(this.listItem.nativeElement).focus();
                    event.preventDefault();
                    break;
            }
        }
    }
}
