import {Component, OnInit, Input} from '@angular/core';
import {ContactServiceBase} from '../../../../core/_services/contact.service.base';
import {ContactBase} from '../../../../core/_models/contact-base';
import {ContactViewBaseModel, ContactActionModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';

declare let $: JQueryStatic;

@Component({
    selector: 'app-contact-group',
    templateUrl: './contact-group.template.html',
    styleUrls: ['./contact-group.component.scss'],
})
export class ContactGroupComponent implements OnInit {
    @Input()
    contactService: ContactServiceBase<ContactBase, ContactViewBaseModel<ContactBase>> = null;
    public contacts: any = [];

    constructor() {}

    ngOnInit() {
        this.contactService.currentContactViewModels.subscribe(currentContacts => {
            this.contacts = currentContacts;

            setTimeout(() => {}, 1000);
        });
    }
}
