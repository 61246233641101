import {AnyObject} from 'src/app/global';
import {DynamicsCaseModel, DynamicsContactModel} from './dynamics';
import {ZendeskSearchResponseModel, ZendeskUserModel} from './zendesk';

export class CrmDataHolderViewModel {
    public processed = false;

    public zendeskUserResult: ZendeskSearchResponseModel<ZendeskUserModel>;
    public ticketResult: any;
    public ticket: any;
    public ticketObject: any;
    public zendeskUser: ZendeskUserModel;
    public userObject: any;

    public dynamicsUserResult: Array<DynamicsContactModel>;
    public dynamicsUser: DynamicsContactModel;

    public kustommerUserResult: AnyObject;
    public kustommerUser: AnyObject;

    public dynamicsCaseResult: Array<DynamicsCaseModel>;
    public dynamicsCase: DynamicsCaseModel;
    public caseObject: any;

    constructor(data?: Partial<CrmDataHolderViewModel>) {
        Object.assign(this, data);
    }
}
