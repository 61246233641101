import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {InContactAPIService} from '../.././core/InContact/incontact-api.service';
import {ContactStates} from '../.././core/InContact/models/contact-states.model';
import {Agent} from '../.././core/_models/agent.model';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';

export enum mediaTypeNames {
    'E-Mail' = 1,
    'Chat' = 3,
    'Phone Call' = 4,
    'Voice Mail' = 5,
    'Work Item' = 6,
}

@Injectable()
export class QueueService {
    private queuelist: ContactStates[];
    private runQueueTimer: boolean;
    private agentId: string;
    private serverTimeOffset;

    public personalQueue: ContactStates[];
    public generalQueue: ContactStates[];
    public queueUpdateCount: number;

    private queueTimer: any;

    constructor(private inContactAPIService: InContactAPIService, private agentSessionService: IcAgentSessionService) {}

    getContactState(agentId): Observable<ContactStates[]> {
        return this.inContactAPIService.getContactState(agentId);
    }
}
