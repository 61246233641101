import {Inject, Injectable} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {AnyObject, downloadFile, downloadObjectAsJson} from 'src/app/global';
import {IcAgentSessionService} from '../../incontact/services/agent-sessions/agent-session-events.service';
import {FacLocalStorageService} from '../../storage';
import {dexieDb} from '../dexie.db';
import {environment} from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root',
})
export class DexieLogsService {
    constructor(@Inject(FacLocalStorageService) private localStorageService: FacLocalStorageService) {
        dexieDb.logsList.where('date').below(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).valueOf()).delete();
    }

    public async info(key: string, value: AnyObject) {
        await dexieDb.logsList.add({
            type: 'info',
            key,
            version: environment.VERSION,
            value,
            username: this.localStorageService.getAgent?.userName,
            date: new Date().valueOf(),
        });
    }

    public async warning(key: string, value: AnyObject) {
        await dexieDb.logsList.add({
            type: 'warning',
            key,
            version: environment.VERSION,
            value,
            username: this.localStorageService.getAgent?.userName,
            date: new Date().valueOf(),
        });
    }

    public async error(key: string, value: AnyObject) {
        await dexieDb.logsList.add({
            type: 'error',
            key,
            version: environment.VERSION,
            value,
            username: this.localStorageService.getAgent?.userName,
            date: new Date().valueOf(),
        });
    }

    public async dowloadJson() {
        const logs = await dexieDb.logsList.toArray();
        downloadObjectAsJson({logs}, `${this.localStorageService.getAgent?.info?.agentId}-${new Date().valueOf()}-logs`);
    }
}
