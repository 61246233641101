import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {SupportComponent} from './support.component';
import {AuthGuard} from '../../core/_guards/auth.guard';
import {AppDefaultLayoutComponent} from 'src/app/layout/app-default-layout/app-default-layout.component';

const routes: Routes = [
    {
        path: '',
        component: AppDefaultLayoutComponent,
        children: [{path: 'support', component: SupportComponent, canActivate: [AuthGuard]}],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SupportRoutingModule {}
