import {CrmServiceBase} from './base.crm-service';
import {ContactViewBaseModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {observable, Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApplicationContextService} from '../application-context.service';
import {LocalStorageService} from 'ngx-webstorage';
import {MessageService} from '../message.service';
import {ZendeskSearchResponseModel, ZendeskUserModel} from '../../_models/crm/zendesk';
import {CrmDataHolderViewModel} from '../../_models/crm';
import {map, mergeMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DexieLogsService} from 'src/app/sdk/dexie/services';

// declare var window.zClient;

@Injectable()
export class ZendeskCrmSerivce extends CrmServiceBase {
    private currentZendeskUser: any = null;

    constructor(
        protected localStorageSrv: LocalStorageService,
        protected messagesSrv: MessageService,
        protected dexieLogsSrv: DexieLogsService,
        public router: Router,
    ) {
        super(localStorageSrv, messagesSrv, dexieLogsSrv);
    }

    init() {
        const me = this;
        try {
            window.zClient.get('currentUser').then(function (data) {
                me.currentZendeskUser = data.currentUser;

                me.onNumberDialed();

                me.initDone$.next(true);
                me.initDone$.complete();
            });
        } catch (error) {
            console.log('Error zendeskCrmIntegrationService constrcutor: ' + error);
        }
    }

    public onNumberDialed() {
        const me = this;
        window.zClient?.on('voice.dialout', e => {
            me.router.navigate(['/dialpad'], {queryParams: {phoneNumber: e.number, fromZendesk: 1}});
        });
    }

    updateCrmTicketWithContactDetails(contactVM: ContactViewBaseModel<ContactBase>): Observable<ContactViewBaseModel<ContactBase>> {
        super.updateCrmTicketWithContactDetails(contactVM);

        const context = {mappings: contactVM.crmFieldMappings};
        const htmlstr = contactVM.toContactString();

        const currentContact = contactVM.contact; //this.servicesContainer.DataContainerService.getCurrentContact(data.contact.ContactID);
        //if (this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId &&
        //  (this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId + "").trim().length > 0) {
        if (!currentContact.ticketId && (currentContact.ticketId + '').trim().length == 0) {
            // this.afterContactFinalized(contactVM);
            // return;
            return new Observable(observer => {
                observer.next(contactVM);
            });
        }

        return new Observable(observer => {
            this.getTicketById(currentContact.ticketId).subscribe(ticket => {
                const tic: any = {};
                tic.fields = {};

                if (context.mappings) {
                    const mappings = context.mappings.filter(m => m.value && m.value.length > 0);
                    if (mappings) {
                        for (let i = 0; i < mappings.length; i++) {
                            const mapField = mappings[i];
                            const fieldSearch = ticket.fields.filter(f => f.id == parseInt(mapField.value, 10));
                            if (fieldSearch.length > 0) {
                                const field = fieldSearch[0];
                                if (mapField.name === '_recordingFilePath') {
                                    tic.fields[mapField.value] =
                                        (!field || field.value === null ? '' : field.value + ', ') +
                                        contactVM.getContactPropertyValueByKey('_recordingFilePath');
                                } else if (mapField.name === '_startTime') {
                                    tic.fields[mapField.value] =
                                        (field.value === null ? '' : field.value + ', ') + contactVM.getContactPropertyValueByKey('_startTime');
                                    //this.fcc.common.getFormatedDateTime(new Date(contact.startTime()));
                                } else if (mapField.name === '_duration') {
                                    tic.fields[mapField.value] =
                                        (field.value === null ? '' : field.value + ', ') + contactVM.getContactPropertyValueByKey('_duration');
                                } else {
                                    tic.fields[mapField.value] =
                                        (field.value === null ? '' : field.value + ', ') + contactVM.getContactPropertyValueByKey(mapField.name);
                                }
                            }
                        }
                    }
                }

                // let htmlstr = this.getContactString(data, context);
                if (htmlstr && htmlstr.length > 0) {
                    tic.comment = {};
                    tic.comment.public = false;
                    //tic.comment.body = htmlstr;
                    tic.comment.html_body = htmlstr;
                }

                if (contactVM.contact.tagObjects.length > 0) {
                    const allTags = (ticket.tags || []).filter(x => true);
                    const contactTags = contactVM.contact.tagObjects.map(tag => (tag.title + '').replace(/ /g, '_'));
                    allTags.push(...contactTags);
                    tic.tags = allTags; //.join(',');
                }

                tic.via_id = this.setTicketAudit(contactVM.contact.rawData);
                this.updateTicket(ticket.id, tic);
                // this.afterContactFinalized(contactVM);
                observer.next(contactVM);
            });
        });
    }

    public onManualUserSelection(contactVM: ContactViewBaseModel<ContactBase>, userId: number) {
        if (!!userId) {
            this.data.zendeskUser = this.data.zendeskUserResult.results?.find(x => x.id === userId);

            this.OpenCrmUser(contactVM, this.data)
                .pipe(
                    map(x => {
                        this.dexieLogsSrv.info('OpenCrmUser manual selection', {data: this.data});
                        return x;
                    }),
                )
                .subscribe();
        }
    }

    public onManualTicketSelection(contactVM: ContactViewBaseModel<ContactBase>, ticketId: number | string) {
        if (!!ticketId) {
            this.data.ticket = this.data.ticketResult.results?.find(x => x.id === ticketId);

            this.OpenCrmTicket(this.contactVM, this.data)
                .pipe(
                    map(x => {
                        this.dexieLogsSrv.info('OpenCrmTicket manual selection', {data: this.data});
                        return x;
                    }),
                )
                .subscribe(res => {
                    if (this.data.ticket) {
                        // this.contactVM.contact.ticketId = 'testing_123';
                        this.contactVM.contact.ticketId = `${this.data.ticket.id}`;

                        (this.contactVM as any).ticketId = this.data.ticket.id;

                        this.contactVM.contactService.currentContactViewModels.value
                            .filter(x => x.contact.contactId == this.contactVM.contact.contactId)
                            .forEach(x => {
                                x.contact.ticketId = this.data.ticket.id;
                            });

                        this.addToProcessedContacts(this.contactVM);
                    }
                });
        }
    }

    public createCrmTicketManuallay() {
        this.buildCrmTicketObject(this.contactVM, this.data)
            .pipe(
                mergeMap(dt =>
                    this.createCrmTicket(this.contactVM, this.data).pipe(
                        map(x => {
                            this.dexieLogsSrv.info('createCrmTicket manually', {data: this.data});
                            return x;
                        }),
                    ),
                ),
                mergeMap(d1 =>
                    this.OpenCrmTicket(this.contactVM, this.data).pipe(
                        map(x => {
                            this.dexieLogsSrv.info('OpenCrmTicket manually', {data: this.data});
                            return x;
                        }),
                    ),
                ),
            )
            .subscribe(res => {
                if (this.data.ticket) {
                    // this.contactVM.contact.ticketId = 'testing_123';
                    this.contactVM.contact.ticketId = `${this.data.ticket.id}`;

                    (this.contactVM as any).ticketId = this.data.ticket.id;

                    this.contactVM.contactService.currentContactViewModels.value
                        .filter(x => x.contact.contactId == this.contactVM.contact.contactId)
                        .forEach(x => {
                            x.contact.ticketId = this.data.ticket.id;
                        });

                    this.addToProcessedContacts(this.contactVM);
                }
            });
    }

    private getTicketById(Id) {
        const obs = new Subject<any>();
        window.zClient
            ?.request({
                url: '/api/v2/tickets/' + Id + '.json',
                type: 'GET',
            })
            .then(function (data) {
                obs.next(data.ticket);
            });
        return obs;
    }

    private updateTicket(ticketId, data): Subject<any> {
        const obs = new Subject<any>();
        window.zClient
            ?.request({
                url: '/api/v2/tickets/' + ticketId + '.json',
                type: 'PUT',
                data: {
                    ticket: data,
                },
            })
            .then(function (data1) {
                console.dir(data1);
                obs.next(data1);
            });
        return obs;
    }

    protected customCrmSearch(viewmodel: ContactViewBaseModel<ContactBase>, searchString: string, data: any) {
        return new Observable<{found: boolean; type: string}>(obsvr => {
            window.zClient
                ?.request({
                    url: '/api/v2/search.json?query=' + searchString,
                    type: 'GET',
                })
                .then(result => {
                    if (result.count === 1) {
                        const obj = result.results[0];
                        if (obj.result_type === 'ticket') {
                            data.ticketResult = result;
                            data.ticket = data.ticketResult.results[0];
                            viewmodel.contact.ticketId = data.ticket.id;

                            obsvr.next({found: true, type: 'ticket'});
                            return;
                        } else {
                            data.userResult = result;
                            data.zendeskUserResult = result;
                            data.user = data.userResult.results[0];
                            data.zendeskUser = data.zendeskUserResult.results[0];

                            obsvr.next({found: true, type: 'user'});
                            return;
                        }
                    }

                    obsvr.next({found: false, type: null});
                })
                .catch(err => {
                    obsvr.error(err);
                })
                .finally(() => {
                    obsvr.complete();
                });
        });
    }

    public customCrmUsersSearch(text: string) {
        return new Observable<{found: boolean; type: string}>(obsvr => {
            window.zClient
                ?.request({
                    url: `/api/v2/search.json?query=type:user ${text}`,
                    type: 'GET',
                })
                .then(result => {
                    this.data.zendeskUserResult = result;
                    obsvr.next(result);
                })
                .catch(err => {
                    obsvr.error(err);
                })
                .finally(() => {
                    obsvr.complete();
                });
        });
    }

    protected findCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel) {
        return new Observable<CrmDataHolderViewModel>(observer => {
            const searchField = viewmodel.configMap.zendeskSearchField;
            const searchOperator = viewmodel.configMap.zendeskSearchOperator;
            const value = viewmodel.contact.rawData[viewmodel.configMap.incontactSearchField];
            if (!value || value.trim().length == 0) {
                data.zendeskUserResult = new ZendeskSearchResponseModel<ZendeskUserModel>();
                data.zendeskUserResult.count = 0;
                observer.next(data);
                observer.complete();
                return;
            }
            window.zClient
                ?.request({
                    //url: '/api/v2/search.json?query=role:end-user+' + searchField + ':' + searchOperator + value,
                    url: '/api/v2/search.json?query=' + searchField + ':' + searchOperator + value,
                    type: 'GET',
                })
                .then((userData: ZendeskSearchResponseModel<ZendeskUserModel>) => {
                    data.zendeskUserResult = userData;
                    if (data.zendeskUserResult.count == 1) {
                        data.zendeskUser = data.zendeskUserResult.results[0];
                    }
                    if (userData?.count === 0) {
                        this.messagesSrv.notify({
                            Type: 'info',
                            Subject: 'User Not found',
                            MessageText: 'No User found on Zendesk.',
                            timeout: 10000,
                        });
                    }

                    console.log('zendesk user data: ', data);
                    observer.next(data);
                    observer.complete();
                })
                .catch(err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    protected findCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable<any>(observer => {
            if (data.zendeskUser) {
                window.zClient
                    ?.request({
                        url: '/api/v2/search.json?query=status<=open+requester_id:' + data.zendeskUser.id,
                        type: 'GET',
                    })
                    .then(result => {
                        data.ticketResult = result;
                        if (data.ticketResult.count == 1) {
                            data.ticket = data.ticketResult.results[0];
                            viewmodel.contact.ticketId = data.ticket.id;
                        }
                        if (result?.count === 0) {
                            this.messagesSrv.notify({
                                Type: 'info',
                                Subject: 'Ticket Not found',
                                MessageText: 'No ticket found on Zendesk.',
                                timeout: 10000,
                            });
                        }

                        observer.next(data);
                        observer.complete();
                    })
                    .catch(err => {
                        observer.error(err);
                        observer.complete();
                    });
            } else {
                observer.next(data);
                observer.complete();
            }
        });
    }

    protected getCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: any, id: string): Observable<any> {
        return new Observable<any>(observer => {
            window.zClient
                ?.request({
                    url: `/api/v2/tickets/${id}.json`,
                    type: 'GET',
                })
                .then(result => {
                    if (result.ticket) {
                        data.ticketResult = {
                            results: [result.ticket],
                            count: 1,
                        };
                    }

                    if (data.ticketResult?.count == 1) {
                        data.ticket = data.ticketResult.results[0];
                        viewmodel.contact.ticketId = data.ticket.id;
                    }

                    if (data.ticketResult?.count === 0) {
                        this.ticketNodFound();
                    }

                    observer.next(data);
                    observer.complete();
                })
                .catch(err => {
                    this.ticketNodFound();
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    private ticketNodFound() {
        this.messagesSrv.notify({
            Type: 'info',
            Subject: 'Ticket Not found',
            MessageText: 'No ticket found on Zendesk.',
            timeout: 10000,
        });
    }

    protected OpenCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: any): Observable<any> {
        return new Observable(observer => {
            window.zClient
                ?.request({
                    url: '/api/v2/channels/voice/agents/' + this.currentZendeskUser.id + '/tickets/' + data.ticket.id + '/display.json',
                    type: 'POST',
                })
                .then(
                    result => {
                        observer.next(data);
                        observer.complete();
                    },
                    error => {
                        observer.next(data);
                        observer.complete();
                    },
                );
        });
    }

    protected OpenCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable<any>(observer => {
            window.zClient
                ?.request({
                    url: '/api/v2/channels/voice/agents/' + this.currentZendeskUser.id + '/users/' + data.zendeskUser.id + '/display.json',
                    type: 'POST',
                })
                .then(result => {
                    observer.next(data);
                    observer.complete();
                })
                .catch(error => {
                    observer.next(data);
                    observer.complete();
                });
        });
    }
    protected buildCrmTicketObject(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(oberver => {
            const contactSettings = viewmodel.settings; //this.getContactSettings(data.contact);
            const configMap = viewmodel.configMap;
            const phone = viewmodel.contact.rawData[configMap.incontactSearchField];
            const user = data.zendeskUser;

            const obj: any = {
                subject: !contactSettings.ticketSubject ? 'New Ticket' : viewmodel.replaceTokensWithValues(contactSettings.ticketSubject),
            };
            if (contactSettings.defaultGroup && parseInt(contactSettings.defaultGroup, 10)) {
                obj.group_id = parseInt(contactSettings.defaultGroup, 10);
            }
            if (contactSettings.ticketTags) {
                obj.tags = viewmodel.replaceTokensWithValues(contactSettings.ticketTags);
            }

            obj.comment = {};
            if (viewmodel.contact.contactType == 'EmailContactEvent') {
                //obj.comment.body = this.getContactString(data, context);
                obj.comment.html_body = viewmodel.toContactString();
            } else {
                //obj.comment.body = (!contactSettings.ticketComments ? 'New ticket details...' :
                //  this.replaceTokensWithValues(contactSettings.ticketComments, data, context));
                obj.comment.html_body = !contactSettings.ticketComments
                    ? 'New ticket details...'
                    : viewmodel.replaceTokensWithValues(contactSettings.ticketComments);
            }

            obj.comment.public = false;
            obj.requester_id = data.zendeskUser.id;

            obj.assignee_id = this.currentZendeskUser.id;
            obj.via_id = this.setTicketAudit(viewmodel.contact.rawData);

            data.ticketObject = obj;

            oberver.next(obj);
        });
    }
    protected buildCrmUserObject(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(oberver => {
            const obj: any = {};
            const settings = viewmodel.settings;
            const configMap = viewmodel.configMap;

            const icSearchFieldValue = viewmodel.getContactPropertyValueByKey(configMap.incontactSearchField ? configMap.incontactSearchField : 'ContactID');
            // data.contact[(configMap.incontactSearchField ? configMap.incontactSearchField : "ContactID")];
            const zdSearchFieldName = configMap.zendeskSearchField;
            obj.name = configMap.userTitle + ' ' + icSearchFieldValue;
            if (zdSearchFieldName) {
                obj[zdSearchFieldName] = icSearchFieldValue;
            }

            if (configMap.isCallContact && settings.e164enabled) {
                obj.shared_phone_number = true;
            }
            data.userObject = obj;

            oberver.next(data);
            oberver.complete();
        });
    }
    protected createCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(r => {
            try {
                window.zClient
                    ?.request({
                        url: '/api/v2/users.json',
                        type: 'POST',
                        data: {user: data.userObject},
                    })
                    .then(
                        result => {
                            data.zendeskUser = result.user;
                            r.next(data);
                            r.complete();
                        },
                        error => {
                            // r.error(this.handleError('user', error));
                            // r.next(data);
                            r.error(error);
                        },
                    );
            } catch (error) {
                //throw this.handleError('user', error);
                //r.next(data);
                r.complete();
            } finally {
                //return subject;
            }
        });
    }
    protected createCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(observer => {
            //data.user = u;
            //var ticket = this.createTicketObject(data, context);
            window.zClient
                ?.request({
                    url: '/api/v2/tickets.json',
                    type: 'POST',
                    data: {ticket: data.ticketObject},
                })
                .then(tic => {
                    data.ticket = tic.ticket;
                    observer.next(data);
                    viewmodel.contact.ticketId = tic.ticket.id;
                    observer.complete();
                })
                .catch(error => {
                    // observer.throw(error);
                    observer.error(error);
                });
        });
    }

    private setTicketAudit(contact) {
        let viaId: number;

        switch (contact.Type) {
            case 'VoiceMailContactEvent':
                viaId = 44;
                break;
            case 'CallContactEvent':
                if (contact.IsInbound === 'True') {
                    viaId = 45;
                } else {
                    viaId = 46;
                }
                break;
            default:
                break;
        }

        return viaId;
    }
}
