<div class="row contact-item" *ngIf="mode == 'normal'">
    <div class="contact-icon hidden-xs col-sm-2 col-md-2 col-lg-1">
        <i class="fa fa-{{contactVM.contactIcon}} fa-4x"></i>
    </div>
    <div class="contact-icon hidden-sm hidden-md hidden-lg hidden-xl col-xs-1">
        <i class="fa fa-{{contactVM.contactIcon}} fa-2x"></i>
    </div>
    <div>
        <!-- <pre style="text-align: left">{{contactVM.contact.isPreview + ''}}</pre> -->
        <h4 class="contact-heading list-group-item-heading">
            <a href="javascript:;" (click)="contactVM.contactService.activateContact(contactVM.contact).subscribe()"> {{contactVM.contact.skillName}} </a>
            <small>{{contactVM.contact.timers.startedTimeElapsed}}</small>
        </h4>

        <div [ngSwitch]="contactVM.contactType">
            <div class="contact-status-timers">
                <strong
                    >{{contactVM.contact.timers.lastUpdateTimeElapsed}} | {{contactVM.contact.status}}
                    <span *ngIf="contactVM.contact.subStatus"> - {{contactVM.contact.subStatus}}</span>
                </strong>

                <app-contact-actions
                    [contactVM]="contactVM"
                    slot="elevations"
                    class="contact-elevation-items"
                    tagClasses="contact-display-contact-elevations"
                    [dropdownMargin]="-115"
                >
                    <ng-container first-one> </ng-container>
                </app-contact-actions>
            </div>
            <div *ngSwitchDefault></div>
            <div *ngSwitchCase="'Call'">
                <span class="contact-ani" *ngIf="contactVM.contact.isInbound">{{contactVM.contact.ANI}}</span>
                <span class="contact-ani" *ngIf="!contactVM.contact.isInbound">{{contactVM.contact.DNIS}}</span>
            </div>
            <div *ngSwitchCase="'Chat'">
                <div *ngIf="contactVM.contact.unread" class="alert-warning">
                    <div
                        *ngIf="contactVM.contact.chatMessages.length > 0"
                        [innerHTML]="contactVM.contact.chatMessages[contactVM.contact.chatMessages.length - 1].Text"
                    ></div>
                </div>
            </div>
            <div *ngSwitchCase="'Email'">
                <ul class="nav">
                    <li *ngIf="contactVM.contact.fromAddress" class="contact-ani"><strong>From:</strong>{{contactVM.contact.fromAddress}}</li>
                    <li *ngIf="contactVM.contact.toAddress" class="contact-ani"><strong>To:</strong>{{contactVM.contact.toAddress}}</li>
                    <li *ngIf="contactVM.contact.subject" class="contact-ani"><strong>Subject:</strong>{{contactVM.contact.subject}}</li>
                </ul>
            </div>
            <div *ngSwitchCase="'Voicemail'">
                <ng-container *ngIf="contactVM.contact.isActive()">
                    <span class="contact-ani"><strong>From:</strong>{{contactVM.contact.from}}</span>
                    <input
                        type="range"
                        [min]="0"
                        [max]="contactVM.contact.fileDuration"
                        value="50"
                        class="slider"
                        name="vm-slider"
                        class="slider"
                        (input)="contactVM.contactService['play'](contactVM.contact, $event.target.value).subscribe()"
                        [(ngModel)]="contactVM.contact.playBackPosition"
                    />
                    <ul class="nav nav-pills vm-playback">
                        <li>
                            <button
                                role="button"
                                class="btn btn-circle btn-default btn-lg"
                                (click)="contactVM.contactService['skipBack'](contactVM.contact).subscribe()"
                            >
                                <i class="fa fa-forward fa-flip-horizontal" title="Rewind 10 Seconds"></i>
                            </button>
                        </li>
                        <li>
                            <button
                                role="button"
                                *ngIf="contactVM.contact.playBackPaused"
                                class="btn btn-circle btn-default btn-lg"
                                (click)="contactVM.contactService['play'](contactVM.contact, (contactVM.contact.playBackPosition == -1 || contactVM.contact.playBackPosition == contactVM.contact.fileDuration ) ? 0 : contactVM.contact.playBackPosition).subscribe()"
                            >
                                <!-- <i class="fa fa-play" title="{{ 'WorkItems.Buttons.End' | translate }}"></i> -->
                                <i class="fa fa-play" title="{{ 'WorkItems.Buttons.End'}}"></i>
                            </button>

                            <button
                                role="button"
                                *ngIf="!contactVM.contact.playBackPaused"
                                class="btn btn-circle btn-default btn-lg"
                                (click)="contactVM.contactService['pause'](contactVM.contact).subscribe()"
                            >
                                <i class="fa fa-pause" title="{{ 'WorkItems.Buttons.Hold' }}"></i>
                                <!-- <i class="fa fa-pause" title="{{ 'WorkItems.Buttons.Hold' | translate }}"></i> -->
                            </button>
                        </li>
                        <li>
                            <button
                                role="button"
                                class="btn btn-circle btn-default btn-lg"
                                (click)="contactVM.contactService['skipForward'](contactVM.contact).subscribe()"
                            >
                                <i class="fa fa-forward" title="Forward 10 Seconds"></i>
                            </button>
                        </li>
                        <li class="playback-timer">
                            <span>
                                {{contactVM.contact.toPlaybackTime(contactVM.contact.playBackPosition)}} /
                                {{contactVM.contact.toPlaybackTime(contactVM.contact.fileDuration)}}
                            </span>
                        </li>
                    </ul>
                </ng-container>
            </div>
            <div *ngSwitchCase="'WorkItem'"></div>
        </div>

        <div style="max-width: 500px">
            <app-incoming-call-contact-actions *ngIf="contactVM" [contactVM]="contactVM"></app-incoming-call-contact-actions>
            <!-- <div class="softphone-answer-call my-10" *ngIf="softPhoneSrv.showAnsBtn">
                <button
                    class="btn btn-circle btn-lg btn-success"
                    role="button"
                    routerlinkactive="active"
                    title="Answer From Soft Phone"
                    (click)="AnswerCall(softPhoneSrv.callList[0].id)"
                >
                    <i class="fas fa-phone"></i>
                </button>
            </div>

            <div class="softphone-answer-call my-10" *ngIf="showWebRTCAnsBtn">
                <button
                    class="btn btn-circle btn-lg btn-success"
                    role="button"
                    routerlinkactive="active"
                    title="Answer From Soft Phone"
                    (click)="AnswerCallWebRTC(contactVM.contact)"
                >
                    <i class="fas fa-phone"></i>
                </button>
            </div>

            <div class="softphone-answer-call my-10" *ngIf="showPreviewAnsBtn">
                <button
                    class="btn btn-circle btn-lg btn-success"
                    role="button"
                    routerlinkactive="active"
                    title="Answer From Soft Phone"
                    (click)="AnswerPreview()"
                >
                    <i class="fas fa-phone"></i>
                </button>
            </div> -->

            <ng-container *ngIf="!contactVM.contact.isDispositionVisible()">
                <!-- {{appContext.crmIntegrationService.data.zendeskUserResult | json}} -->

                <!-- && (!appContext.crmIntegrationService.data.zendeskUser && !appContext.crmIntegrationService.data.dynamicsUser) -->
                <!-- <app-contact-user-selection
                    *ngIf="appContext.crmIntegrationService.CrmUsersList.length > 1"
                    [crmUsers]="appContext.crmIntegrationService.CrmUsersList"
                    (userSelection)="onUserSelection($event)"
                ></app-contact-user-selection> -->

                <ul class="nav tick-tags-container">
                    <li>
                        <contact-ticket-box [contactViewModel]="contactVM"> </contact-ticket-box>
                    </li>
                    <li>
                        <app-contact-tagsinput
                            style="display: flex; justify-content: center"
                            *ngIf="contactVM.contact.skill != '0'"
                            [contact]="contactVM.contact"
                        >
                        </app-contact-tagsinput>
                    </li>
                    <li>
                        <!-- && !appContext.crmIntegrationService.data?.zendeskUser && !appContext.crmIntegrationService.data?.dynamicsUser -->
                        <button
                            pButton
                            type="button"
                            class="p-button-outlined p-ml-1"
                            *ngIf="appContext.crmIntegrationService.CrmUsersList.length > 1 && contactVM?.contact?.isActive() && !contactVM?.settings?.creatUserAlways"
                            (click)="onSelectUser($event)"
                        >
                            Select User
                        </button>
                    </li>

                    <li>
                        <button
                            pButton
                            type="button"
                            class="p-button-outlined p-ml-1"
                            *ngIf="(appContext.crmIntegrationService.CrmTicketsList.length > 1) && contactVM?.contact?.isActive()"
                            (click)="onSelectTicket($event)"
                        >
                            Select Ticket
                        </button>
                    </li>
                </ul>

                <div class="mt-5" *ngIf="allowUserSearch">
                    <button pButton type="button" class="p-button-outlined p-ml-1" (click)="onCustomUserSearch()">Search User</button>
                </div>

                <app-contact-actions [contactVM]="contactVM" slot="footer" class="" [showPredisposition]="true" dispositionMenuAlignment="right">
                </app-contact-actions>
                <div style="clear: both"></div>
            </ng-container>

            <active-contact-disposition
                *ngIf="contactVM.contact.isDispositionVisible()"
                [contact]="contactVM.contact"
                [viewModel]="contactVM"
                [contactService]="contactVM.contactService"
            >
                <ng-container last-one>
                    <div>
                        <app-contact-tagsinput *ngIf="contactVM.contact.skill != '0'" [contact]="contactVM.contact"> </app-contact-tagsinput>
                    </div>
                </ng-container>
            </active-contact-disposition>

            <div style="clear: both"></div>
        </div>
    </div>
</div>

<div class="row" *ngIf="mode == 'related'">
    <app-contact-actions [contactVM]="contactVM" slot="footer" [mode]="mode">
        <ng-container first-one>
            <!-- <a href="javascript:;"
                (click)="contactVM.contactService.activateContact(contactVM.contact).subscribe()">{{contactVM.contact.skillName}}</a> -->
            <span>{{contactVM.contact.skillName}}: {{contactVM.contact.timers.lastUpdateTimeElapsed}}</span>
        </ng-container>
        <ng-container last-one>
            <!-- <span class="dropdown">
                <button role="button" data-toggle='dropdown' title="Disposition"
                    class="btn btn-primary-outline no-border">
                    <i class="fa fa-check" aria-hidden="true" *ngIf="!contactVM.preDeispositionSet"></i> <i
                        class="fa fa-check-square" style="background: ff0000;" aria-hidden="true"
                        *ngIf="contactVM.preDeispositionSet"></i> <span class="hidden-xs">Disposition</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" style="padding:10px; min-width:200px;">
                    <li>
                        <h4>Add Disposition</h4>
                    </li>
                    <li>
                        <active-contact-disposition [contact]="contactVM.contact" [viewModel]="contactVM"
                            [contactService]="contactVM.contactService" [mode]="mode"></active-contact-disposition>
                    </li>
                    <li>
                        <button type="button" href="javascript:;" class="btn btn-primary pull-right"
                            [disabled]="!contactVM.contact.appliedDisposition"
                            (click)="savePreselectedDisposition()">Save</button>
                    </li>
                </ul>
            </span> -->
        </ng-container>
    </app-contact-actions>
</div>
