import {IcAuthenticationApi} from 'src/app/sdk/incontact/api';

export class InContactApiSettings {
    public static authToken: null;
    public static hostUrl: 'http://incontactconsole.frontlineservicesinc.com/api';
    public static SessionInfo: null;

    public static clientId = 'FCCinContactZendesk@FCC';
    public static clientSecret = 'OWNjOGJmYWYzNjlkNGM4OGE0OTdlNjE5ZTU1NDY2ZTE=';
    public static agentLoginUrl = 'https://api.incontact.com/InContactAuthorizationServer/token';
    //public static agentLoginUrl = 'https://api-e1.incontact.eu/InContactAuthorizationServer/Token';
    public static servicesURl = 'services/v8.0/';
    public static servicesURL9 = 'services/v9.0/';
    public static servicesURL11 = 'services/v11.0/';
    public static forceLogout = false;
    public static endContacts = false;
    public static ignorePersonalQueue = false;
    public static autoReconnect = false;

    public static ssoBaseUri: string = null;
    public static loginType: string = null;
    public static useCompanySSOSettings = true;
    public static agentSettings: any = null;

    // public static get API_ENDPOINT(): string { return 'http://127.0.0.1:6666/api/'; }

    public static getLongPoolTimeout(): number {
        return 60;
    }

    public static get isSSOLogin() {
        if (InContactApiSettings.useCompanySSOSettings === false) {
            if (InContactApiSettings.agentSettings?.loginType === 'SSO' && !!InContactApiSettings.agentSettings?.ssoBaseUri) {
                return true;
            } else if (InContactApiSettings.agentSettings?.loginType === 'password') {
                return false;
            }
        }
        return InContactApiSettings.loginType === 'SSO' && !!InContactApiSettings.ssoBaseUri;
    }

    public static sessionId: string;
    public static firstSessionId: string;

    public static SSO_Callback = 'https://fac.frontlineservicesinc.com/sso/login';
    // public static SSO_Callback = 'https://fac.frontlineservicesinc.com/sso/authentication/sso-callback';
    // public static SSO_Callback = 'https://localhost:4200/sso/login';
    // public static SSO_Callback = 'https://fac.frontlineservicesinc.com/sso/login';
    // public static SSO_Callback = 'https://frontlineagentconsoleclient-dev.azurewebsites.net/sso/login';

    // public static get getSettings() {
    //     return {
    //         clientId: InContactApiSettings.clientId,
    //         clientSecret: InContactApiSettings.clientSecret,
    //         forceLogout: InContactApiSettings.forceLogout,
    //         endContacts: InContactApiSettings.endContacts,
    //         ignorePersonalQueue: InContactApiSettings.ignorePersonalQueue,
    //         autoReconnect: InContactApiSettings.autoReconnect,
    //         agentLoginUrl: InContactApiSettings.agentLoginUrl,
    //         ssoBaseUri: InContactApiSettings.ssoBaseUri,
    //         loginType: InContactApiSettings.loginType,
    //     };
    // }

    public static set setSettings(icSettings: any) {
        console.log(icSettings);
        InContactApiSettings.clientId = icSettings.clientId ? icSettings.clientId : InContactApiSettings.clientId;

        InContactApiSettings.clientSecret = icSettings.clientSecret ? icSettings.clientSecret : InContactApiSettings.clientSecret;

        InContactApiSettings.forceLogout = icSettings.forceLogout ? icSettings.forceLogout : InContactApiSettings.forceLogout;

        InContactApiSettings.endContacts = icSettings.endContacts ? icSettings.endContacts : InContactApiSettings.endContacts;

        InContactApiSettings.ignorePersonalQueue = icSettings.ignorePersonalQueue ? icSettings.ignorePersonalQueue : InContactApiSettings.ignorePersonalQueue;

        InContactApiSettings.autoReconnect = icSettings.autoReconnect ? icSettings.autoReconnect : InContactApiSettings.autoReconnect;

        InContactApiSettings.agentLoginUrl = icSettings.tokenServerUri ? icSettings.tokenServerUri : InContactApiSettings.agentLoginUrl;

        InContactApiSettings.loginType = icSettings.loginType ? icSettings.loginType : InContactApiSettings.loginType;

        if (InContactApiSettings.useCompanySSOSettings === false) {
            if (InContactApiSettings.agentSettings?.loginType === 'SSO' && !!InContactApiSettings.agentSettings?.ssoBaseUri) {
                InContactApiSettings.ssoBaseUri = InContactApiSettings.agentSettings.ssoBaseUri;
            }
        } else {
            if (InContactApiSettings.loginType === 'SSO') {
                InContactApiSettings.ssoBaseUri = icSettings.ssoBaseUri;
            }
        }
    }
}
