import {AnyObject} from 'src/app/global';
import {CrmAccountSettingsModel} from './crm-account-settings.model';

export class CrmOtherSettingsModel {
    public lockTicketField?: boolean;

    constructor(data?: Partial<CrmOtherSettingsModel>) {
        Object.assign(this, data);
    }
}

export class CrmApplicationModel {
    active: boolean;
    applicationType: string;
    companyId: number;
    contactMappings: AnyObject;
    contactSettings: AnyObject;
    crmAccountSettings: CrmAccountSettingsModel;
    agentLoginTypeSettings: any;
    otherSettings: CrmOtherSettingsModel;
    description: string;
    id: number;
    title: string;
    apiBaseUrl?: string;
    token?: string;
    refresh_token?: string;
    expires_in?: number;

    constructor(data?: Partial<CrmApplicationModel>) {
        Object.assign(this, data);
    }
}
