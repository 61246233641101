export class DispositionModel {
    public dispositionId: string;
    public dispositionName: string;
    public displayOrder: number;
    public classification: string;
    public reportingGroup: string;
    public systemOutcome: string;
    public requireCommitmentAmount: string;
    public requireRescheduleDate: string;
    public agentSpecific: boolean;

    constructor(data?: DispositionModel) {
        this.agentSpecific = data.agentSpecific;
        this.displayOrder = data.displayOrder;
        this.classification = data.classification;
        this.dispositionId = data.dispositionId;
        this.dispositionName = data.dispositionName;
        this.reportingGroup = data.reportingGroup;
        this.requireCommitmentAmount = data.requireCommitmentAmount;
        this.requireRescheduleDate = data.requireRescheduleDate;
        this.systemOutcome = data.systemOutcome;
        // Object.assign(this, data);
    }
}
