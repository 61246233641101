import {ContactViewBaseModel, ContactActionModel} from './contact-view-base.model';
import {WorkItemModel} from '../work-item-info.model';

export class WorkitemContactViewmodel extends ContactViewBaseModel<WorkItemModel> {
    vmInit() {
        console.log('vmInit');
        const me = this;

        this.actions.push(
            /*  new contactAction({
                 "action": "elevate_to_phone",
                 "text": "Call",
                 "icon": "phone",
                 "slot": "elevations",
                 isAvailable: () => !me.contact.isDisconnected()
             }),
             new contactAction({
                 "action": "elevate_to_email",
                 "text": "Email",
                 "icon": "envelope-open",
                 "slot": "elevations",
                 isAvailable: () => !me.contact.isDisconnected()
             }), */
            new ContactActionModel({
                action: 'resume',
                text: 'Resume',
                icon: 'play-circle',
                isAvailable: me.contact.isHolding,
                isVisible: me.contact.isHolding,
            }),
            new ContactActionModel({
                action: 'hold',
                text: 'Hold',
                icon: 'pause',
                isAvailable: me.contact.canHold,
                isVisible: () => !me.contact.isHolding() && !me.contact.isIncoming() && !me.contact.isDisconnected(),
            }),
            new ContactActionModel({
                action: 'transfer',
                text: 'Transfer',
                icon: 'exchange-alt',
                isAvailable: me.contact.canTransfer,
                isVisible: me.contact.canTransfer,
            }),
            new ContactActionModel({
                action: 'end',
                text: 'End',
                icon: 'phone-slash',
                isAvailable: me.contact.canEnd,
                //isVisible: () => me.contact.canEnd
            }),
        );

        this.configMap = {
            settingSectionName: 'workitem',
            mappingSectionName: 'workitem',
            incontactSearchField: 'From',
            userTitle: 'Workitem',
            contactTitle: 'Workitem',
            isCallContact: false,
            statusField: 'Status',
            activeStatusValue: 'Active',
        };
    }
}
