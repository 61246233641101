export class AgentStateModel {
    public agentStateName: string;
    public agentStateDescription: string;
    public OutStateDescription: string;
    public isAcw: boolean;

    constructor(agentStateName: string, OutStateDescription: string, agentStateDescription: string, isAcw: boolean) {
        this.agentStateName = agentStateName;
        this.OutStateDescription = OutStateDescription;
        this.agentStateDescription = agentStateDescription;
        this.isAcw = isAcw;
    }

    public agentId: string;
    public firstName: string;
    public lastName: string;
    public skillId: string;
    public teamId: string;
    //public startDate:Date;
    public agentStateId: string;
    // public  agentStateName;
    public isACW: string;
    //public outStateId:number;
    public outStateDescription: string;

    public stateTime: string;

    lastUpdateTime: Date;
    userName: string;
    isActive: string;
    outStateCode: string;
}
