import {mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
// import {Modal} from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal/plugins/bootstrap';
import {Observable, Subject, Subscription, BehaviorSubject, throwError} from 'rxjs';
import {ContactStates} from '.././InContact/models/contact-states.model';
import {Events} from '.././InContact/models/events.model';
import {ChatRoomModel, Message} from '.././_models/chat-room.model';
import {ErrorModel} from '.././_models/error.model';
import {ApplicationContextService} from './application-context.service';
import {ContactServiceBase} from './contact.service.base';
import {ErrorService} from './error.service';
import {FccCommonService} from './fcc-common.service';
import {MessageService} from './message.service';
import {ChatContactViewmodel} from '../_models/_viewmodels/chat-contact-viewmodel';
import {ContactBase} from '../_models/contact-base';

@Injectable()
/** Dummy version of an authenticated user service */
export class ChatContactService extends ContactServiceBase<ChatRoomModel, ChatContactViewmodel> {
    public result: any;
    public error: any;
    private chatRoom: ChatRoomModel;
    //private chatRoomsArray: ChatRoomModel[];
    public statusMsg: string;
    public errorModel: ErrorModel;
    public newButtonEnabled = new BehaviorSubject<boolean>(false);
    public newChatMessage = new Subject();
    public ChatPatronTypingStatus = new Subject();
    private chatMessageIntervalId: any = null;

    //public chatRooms: BehaviorSubject<ChatRoomModel[]>;
    public selectedRoom = new BehaviorSubject<ChatRoomModel>(null);
    public showChatDisposition = new BehaviorSubject<boolean>(false);
    public skillRequestCompleted = false;

    dialogRes: any;
    private skillQueueTimerId: any;

    constructor(
        private router: Router,
        private messageService: MessageService,
        private errorService: ErrorService,
        // public modal: Modal,
        private fccCommonService: FccCommonService,
        appContext: ApplicationContextService,
    ) {
        super(ChatRoomModel, ChatContactViewmodel as any, appContext);
        this.title = 'Chat Contacts'; //--> Get Translation

        appContext.chatContactService = this;
    }

    /*
    IISHost: "DAL-B2COR01"
Label: ""
Message: "hello"
PartyType: "Client"
RoomId: "12532"
TimeStamp: "2018-12-12T23:14:47.570Z"
Type: "ChatText"
VCHost: "DAL-B2COR01"
    */
    processChatText(event: any) {
        const roomIndex = this.currentContacts.value.findIndex(v => v.roomId == event.RoomId);
        if (roomIndex > -1) {
            const contactObject = this.currentContacts.value[roomIndex];
            if (contactObject) {
                const msg: Message = new Message(event);
                contactObject.chatMessages.push(msg);
                this.newChatMessage.next(msg);
                if (!contactObject.isActive()) {
                    contactObject.unread = true;
                    if (msg.PartyType == 'Client') {
                        this.appContext.messageService.notify({
                            Type: 'warning',
                            Subject: (contactObject.skillInfo || {}).skillName || 'Chat',
                            MessageText: msg.Text,
                        });
                    }
                }
            }
        }
    }

    processMessageEvent(event: Events) {
        this.UpdateMessages(event);
    }

    UpdateMessages(event: Events) {
        this.appContext.inContactAPIService.getMessages(event.AgentId).subscribe(
            res => (this.statusMsg = 'UpdateMessages'),
            error => (this.errorModel = error),
        );
    }

    onContactActivated(contact: ChatRoomModel) {
        super.onContactActivated(contact);
        //this.router.navigate(['chat', contact.roomId]);
        this.navigateTo(['chat', contact.roomId]);
    }

    updateElapsedTime(chatRoom: ChatRoomModel, selectedElapsedTime, selectedStartTime) {
        let serverTimeOffset;
        if (selectedStartTime === undefined) {
            return;
        }
        this.agentService.serverTimeOffset.subscribe(time => {
            serverTimeOffset = time;

            const currentDate = new Date(new Date().getTime() + serverTimeOffset);
            const lastDate = new Date(selectedStartTime);
            const dif = new Date(currentDate.valueOf() - new Date(selectedStartTime).valueOf()).getTime();
            selectedElapsedTime = this.fccCommonService.getFormattedTime(dif);
            chatRoom.messageTimeElapsed = selectedElapsedTime;
        });
    }

    //----------- Chat Rooms ----------------------------------------------------
    getChatRoom(roomId: string): ChatRoomModel {
        let rooms: ChatRoomModel[];
        let chatRoom: ChatRoomModel;

        rooms = this.currentContacts.getValue();
        rooms = rooms.filter(e => e.roomId === roomId);
        if (rooms.length > 0) {
            chatRoom = rooms[0];
            return chatRoom;
        } else {
            return null;
        }
    }

    getRoomsSkill() {
        const rooms = this.currentContacts.getValue();

        rooms.forEach(element => {
            this.appContext.inContactAPIService.getSkillInfo(+element.skill).subscribe(res => {
                element.skillName = res[0].skillName;
            });
        });
    }

    getChatTranscript(data: any) {
        //return this.appContext.inContactAPIService.getChatTranscript(contactId);
        this.findExistingContact(data.ContactID)
            .pipe(
                mergeMap(existingContactData => {
                    if (existingContactData.contactIndex > -1) {
                        this.appContext.inContactAPIService.getChatTranscript(existingContactData.contactObject.contactId).subscribe((messages: any[]) => {
                            const messagelist = messages.map(v => new Message(v));
                            existingContactData.contactObject.chatMessages = messagelist;
                            /* messages.forEach(element => {
                                //if (element.PartyType !== "System") {
                                this.showMessage(
                                    existingContactData.contactObject, element.PartyType, element.TimeStamp, element.Text, element.Label || element.PartyType);
                                //}
                            }); */
                        });
                    }
                    return new Observable(r => r.next(existingContactData));
                }),
            )
            .subscribe(r => {
                // console.log(r);
            });
    }

    sendChatMessage(contactId: string, message: string) {
        return this.appContext.inContactAPIService.sendChatMessage(contactId, message).subscribe(
            res => (this.result = res),
            err => (this.error = err),
        );
    }

    startCheckSkillQueueTimer() {
        this.skillRequestCompleted = true;

        if (!this.skillQueueTimerId) {
            this.skillQueueTimerId = setInterval(() => {
                if (this.skillRequestCompleted) {
                    this.checkSkillQueue();
                }
            }, 10000);
        }
    }

    stopCheckSkillQueueTimer() {
        clearInterval(this.skillQueueTimerId);
    }

    checkSkillQueue() {
        let queues: ContactStates[];
        const currentDate = new Date();

        const agentId = this.agentService.agentId.getValue();

        this.skillRequestCompleted = false;

        this.appContext.inContactAPIService.getContactState(agentId.toString()).subscribe(
            queuesList => {
                if (queuesList !== '') {
                    queues = queuesList;
                    queues = queues.filter(e => e.mediaType === '3');

                    const agentState = this.agentService.currentAgentState.getValue();
                    this.newButtonEnabled.next(queues.length > 0 && this.currentContacts.getValue().length >= 1 && agentState !== 'Unavailable');
                }
                this.skillRequestCompleted = true;
            },
            err => {
                this.skillRequestCompleted = true;
            },
        );
    }

    transferContact(contact: ContactBase, data: any): Observable<ContactBase> {
        return new Observable(r => {
            this.appContext.inContactAPIService.transferChat(contact.contactId, data).subscribe(
                res => {
                    this.messageService.setCallMessage.next('Chat has been transfered successfully to ' + data.transferType);
                    r.next(contact);
                },
                err => throwError(err),
            );
        });
    }

    sendTypingStatus(data: any) {
        this.appContext.inContactAPIService.sendTypingStatus(data).subscribe(result => {
            console.log('status sent');
        });
    }

    processChatPatronTyping(event) {
        this.ChatPatronTypingStatus.next(event);
    }
    private addTextCounter = 0;
    private timerSubscription: Subscription = null;
    onServiceStarted() {
        this.appContext.agentSessionService.businessUnit.subscribe(bu => {
            if (bu.businessUnitId && !bu.isMultiContactHandling && !this.timerSubscription) {
                this.timerSubscription = this.appContext.applicationTimer.subscribe(r => {
                    this.addTextCounter++;
                    if (this.addTextCounter >= 15) {
                        this.addTextCounter = 0;
                        try {
                            const activeChats = this.currentContactViewModels.value.length;
                            if (activeChats > 0) {
                                const agent = this.appContext.agentSessionService.loadAgent();
                                if (agent && agent.info && activeChats < agent.info.maxConcurrentChats) {
                                    this.appContext.inContactAPIService.addChat().subscribe();
                                }
                            }
                        } catch (addTextError) {}
                    }
                });
            }
        });
    }

    onServiceStoped() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
    }
}
