export class SessionInfo {
    public AgentId: number;
    public AgentUUId: string;
    public StationId: string;
    public StationPhoneNumber: string;
    public StationCallerId: string;
    public SessionId: string;

    constructor(agentId: number, stationId: string, stationPhone: string, stationCallerId: string, sessionId: string, agentUUId: string) {
        this.AgentId = agentId;
        this.AgentUUId = agentUUId;
        this.StationId = stationId;
        this.StationPhoneNumber = stationPhone;
        this.StationCallerId = stationCallerId;
        this.SessionId = sessionId;
    }
}
