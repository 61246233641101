import {AnyObject} from 'src/app/global';

export interface IPhoneModes {
    chrome_rtp_timeout_fix: number;
}

export class PhoneConfigModel {
    reconnectIntervalMin: number;
    reconnectIntervalMax: number;
    registerExpires: number;
    useSessionTimer: boolean;
    keepAlivePing: number;
    keepAlivePong: number;
    keepAliveStats: number;
    keepAliveDist: boolean;
    restoreCall: boolean;
    restoreCallMaxDelay: number;
    dtmfUseWebRTC: boolean;
    dtmfDuration: number;
    dtmfInterToneGap: number;
    avoidTwoWayHold: boolean;
    enableAddVideo: boolean;
    addLoggerTimestamp: boolean;
    useWebrtcTracer: boolean;
    modes: IPhoneModes;
    constraints: AnyObject;
    version: string;
    chromeAudioConstraints: string;

    constructor(data?: Partial<PhoneConfigModel>) {
        Object.assign(this, data);
    }
}
