import {Component, Inject, Input} from '@angular/core';
import {ContactViewBaseModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {ApplicationContextService} from 'src/app/core/_services/application-context.service';

declare let $;

@Component({
    selector: 'contact-ticket-box',
    template: `<button class="btn btn-default btn-xs" (click)="showHideTicketBox(true)" *ngIf="contactViewModel.contact.ticketId && !showingTicketBox">
            T#: {{ contactViewModel.contact.ticketId }}
        </button>
        <button class="btn btn-default btn-xs" (click)="showHideTicketBox(true)" *ngIf="!contactViewModel.contact.ticketId && !showingTicketBox">
            ticket#
        </button>
        <form class="contact-ticket-form" *ngIf="showingTicketBox" (submit)="showHideTicketBox(false)">
            <div class="input-group">
                <input
                    type="text"
                    name="ticketbox"
                    [(ngModel)]="contactViewModel.contact.ticketId"
                    class="form-control"
                    placeholder=""
                    aria-describedby="basic-addon1"
                />
                <span *ngIf="false" class="input-group-addon" id="basic-addon1">T#</span>
            </div>
        </form>`,
    styleUrls: ['./contact-ticket-form.scss'],
})
export class ContactTicketBoxComponent {
    @Input()
    contactViewModel: ContactViewBaseModel<ContactBase>;
    showingTicketBox = false;

    constructor(@Inject(ApplicationContextService) public appContext: ApplicationContextService) {}

    showHideTicketBox(visible: boolean) {
        this.showingTicketBox = visible;

        this.appContext.crmIntegrationService.onContactTicketIdAddition(this.contactViewModel);
        setTimeout(() => {
            $('.contact-ticket-form input[type="text"]').focus();
        }, 100);
    }
}
