import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {AppToasterService} from 'src/app/plugins/ngx-toastr';
// import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty';

export interface MessageConfig {
    Type: 'info' | 'error' | 'warning' | 'success';
    Subject: string;
    MessageText: string;
    timeout?: number;
    ExpireTimer?: number;
    [key: string]: any;
}

@Injectable()
export class MessageService {
    private statusMsg = new Subject<string>();
    public setCallMessage = new BehaviorSubject<string>('');
    public setCallMessage2 = new BehaviorSubject<string>('');
    public setWindowTitle = new BehaviorSubject<string>('');

    timeout = 10000;

    constructor(
        // private notificationService: ToastyService,
        private tostrService: AppToasterService,
    ) {}

    updateStatusMsg$ = this.statusMsg.asObservable();

    updateStatusMsg(status: string) {
        this.statusMsg.next(status);
    }

    notify(item: MessageConfig) {
        // var toastOptions: ToastOptions = {
        //     title: item.Subject,
        //     msg: item.MessageText,
        //     showClose: true,
        //     timeout: item.timeout || 5000,
        //     //theme: 'default',
        //     onAdd: (toast: ToastData) => {
        //         //console.log('Toast ' + toast.id + ' has been added!');
        //     },
        //     onRemove: function (toast: ToastData) {
        //         //console.log('Toast ' + toast.id + ' has been removed!');
        //     },
        // };
        let milisec = null;
        if (!!item.ExpireTimer) {
            milisec = item.ExpireTimer * 60 * 1000;
        }

        switch (item.Type) {
            case 'info':
                // this.notificationService.info(toastOptions);
                this.tostrService
                    .show(
                        'INFO',
                        item.Subject || 'Info',
                        item.MessageText,
                        undefined,
                        milisec ?? item.timeout ?? this.timeout,
                    )
                    .subscribe();
                break;
            case 'error':
                // this.notificationService.error(toastOptions);
                this.tostrService
                    .show(
                        'ERROR',
                        item.Subject || 'Error',
                        item.MessageText,
                        undefined,
                        milisec ?? item.timeout ?? this.timeout,
                    )
                    .subscribe(err => console.log);
                break;
            case 'warning':
                // this.notificationService.warning(toastOptions);
                this.tostrService
                    .show(
                        'WARNING',
                        item.Subject || 'Warning',
                        item.MessageText,
                        undefined,
                        milisec ?? item.timeout ?? this.timeout,
                    )
                    .subscribe();
                break;
            default:
                // this.notificationService.default(toastOptions);
                this.tostrService
                    .show(
                        'INFO',
                        item.Subject || 'Info',
                        item.MessageText,
                        undefined,
                        milisec ?? item.timeout ?? this.timeout,
                    )
                    .subscribe();
                break;
        }
    }
}
