import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ApiEventTypes, ApiMessageTypes, ApiRequestTypes, ApiStatusEventTypes, xmlDeclarationString} from './constants';

@Injectable({
    providedIn: 'root',
})
export class SoftPhoneService {
    websocket: WebSocket = null;
    apiUri = '';

    connected = false;
    connectionStatus = '';
    connectionError = false;

    incomingMessage: any = null;

    lastTransactionID = 0;

    disconnectedIntentially = false;

    userAgentString = 'Bria API JavaScript Sample';

    private _callList: Array<any>;

    get callList() {
        return this._callList;
    }
    set callList(val: Array<any>) {
        this._callList = val;
    }

    constructor() {
        this.apiUri = 'wss://cpclientapi.softphone.com:9002/counterpath/socketapi/v1/';

        this.callList = [];
    }

    initialize() {
        if (!this.connected) {
            this.connectToWebSocket();
        }
    }

    get showAnsBtn() {
        if (!this.callList?.length) {
            return false;
        }

        if (!this.callList[0].participants?.length) {
            return false;
        }

        if (this.callList[0].participants[0].state === 'ringing') {
            return true;
        }

        return false;
    }

    connectToWebSocket() {
        // var connectionError = false;

        this.disconnectedIntentially = false;
        this.connectionStatus = '';
        /* Create new WebSocket object */
        try {
            this.websocket = new WebSocket(this.apiUri);
        } catch (e) {
            console.log('WebSocket exception: ' + e);
        }

        /* Event handler for connection established */
        this.websocket.onopen = evt => {
            this.connectionError = false;
            // setConnectionStatus('CONNECTED');
            console.log('socket connected');
            this.connected = true;
            this.onConnectedToWebSocket();
        };

        /* Event handler for disconnection from Web-Socket */
        this.websocket.onclose = evt => {
            if (!this.connectionError) {
                // setConnectionStatus('DISCONNECTED - retrying connection after 5 seconds ...');
                if (!this.disconnectedIntentially) {
                    this.connectionStatus = 'DISCONNECTED - retrying connection after 5 seconds ...';
                }
                this.connected = false;
            }
            console.log('OnClose: ' + evt.code);

            /* Set timer to attempt re-connection in 5 seconds */
            if (!this.disconnectedIntentially) {
                setTimeout(() => {
                    this.connectToWebSocket();
                }, 5000);
            }
        };

        /* Event handler for Errors on the Web-Socket connection */
        this.websocket.onerror = (evt: any) => {
            this.connectionError = true;
            this.connected = false;
            // setConnectionStatus('ERROR Could not connect to WebSocket - retry will happen after 5 seconds ...');
            this.connectionStatus = 'ERROR Could not connect to WebSocket - retry will happen after 5 seconds ...';
            console.log('OnError: ' + evt.name);
            this.websocket.close();
        };

        /* Event handler for received messages via web-socket connection */
        this.websocket.onmessage = evt => {
            // appendToLog('--- RECEIVED ---\n' + evt.data);
            console.log('onmessage', evt);
            this.processApiMessageReceived(evt.data);
        };
    }

    disconnect() {
        this.connectionStatus = 'DISCONNECTED';
        this.disconnectedIntentially = true;
        this.connected = false;
        if (this.websocket) {
            this.websocket.close();
            this.websocket = null;
        }
        this.callList = [];
        this.lastTransactionID = 0;
    }

    onConnectedToWebSocket() {
        /* Do some stuff here when the connection is first established ... */
        /* Request CALL status to sync the list of active calls */
        this.apiGetStatus(ApiStatusEventTypes.properties[ApiStatusEventTypes.CALL].text);
        /* Request CALLHISTORY status to sync the content of the Call History item list */
        // apiGetStatusWithParameters(
        //     ApiStatusEventTypes.properties[ApiStatusEventTypes.CALLHISTORY].text,
        //     ' <count>15</count>\r\n <entryType>all</entryType>\r\n',
        // );
        /* Request SCREENSHARE status to sync the status in the Screen-Sharing box */
        // apiGetStatus(ApiStatusEventTypes.properties[ApiStatusEventTypes.SCREENSHARE].text);
    }

    parseMessage(msg) {
        let messageType = ApiMessageTypes.UNKNOWN;
        let eventType = ApiEventTypes.UNKNOWN;
        let errorCode = 0;
        let errorText = '';

        let transactionId = '';
        let userAgentString = '';
        let contentType = '';
        let contentLength = 0;
        let content = '';

        /* Replace any Windows-Style line-endings with \n */
        const lines = msg.replace(/\r\n/g, '\n').split('\n');
        let line = lines[0];

        /* Parse the first line to determine the type of message */
        if (line.substr(0, 4) == 'POST') {
            messageType = ApiMessageTypes.EVENT;
            line = line.substr(5).trim();
            if (line.substr(0, 13) == '/statusChange') {
                eventType = ApiEventTypes.STATUSCHANGE;
            }
        } else if (line.substr(0, 8) == 'HTTP/1.1') {
            line = line.substr(8).trim();
            if (line.substr(0, 6) == '200 OK') {
                messageType = ApiMessageTypes.RESPONSE;
            } else if (line[0] == '4' || line[0] == '5') {
                messageType = ApiMessageTypes.ERROR;
                errorCode = line.substr(0, 3);
                errorText = line.substr(4);
            }
        }

        /* Parse the remaining lines in the header and extract values */
        let i = 1;
        for (; i < lines.length; i++) {
            line = lines[i];

            if (line[0] == '<') {
                /* Start of the content section */
                break;
            } else if (line.substr(0, 15) == 'Transaction-ID:') {
                transactionId = line.substr(15).trim();
            } else if (line.substr(0, 11) == 'User-Agent:') {
                userAgentString = line.substr(11).trim();
            } else if (line.substr(0, 13) == 'Content-Type:') {
                contentType = line.substr(13).trim();
            } else if (line.substr(0, 15) == 'Content-Length:') {
                contentLength = Number(line.substr(15).trim());
            } else {
                /* Ignore any unknown headers */
                continue;
            }
        }

        /* Re-assemble the content portion from the remaining lines */
        for (; i < lines.length; i++) {
            content += lines[i];
            if (i < lines.length - 1) {
                content += '\n';
            }
        }

        /* Return object with all the details from the message */
        return {
            messageType,
            eventType,
            errorCode,
            errorText,
            transactionId,
            userAgentString,
            contentType,
            contentLength,
            content,
        };
    }

    processApiMessageReceived(msg) {
        this.incomingMessage = this.parseMessage(msg);

        console.log('ApiMessageTypes: ', this.incomingMessage);
        switch (this.incomingMessage.messageType) {
            case ApiMessageTypes.RESPONSE:
                /* This is a response to a status query */
                this.processResponse(this.incomingMessage.content);
                break;

            case ApiMessageTypes.EVENT:
                /* Check the event type */
                if (this.incomingMessage.eventType == ApiEventTypes.STATUSCHANGE) {
                    /* Handle the Status Change Event */
                    console.log('Status Changed: ', this.incomingMessage.content);
                    this.processStatusChangeEvent(this.incomingMessage.content);
                } else {
                    /* Unknown event type received */
                    console.log('Unknown Event Type received ' + this.incomingMessage.eventType);
                }
                break;

            case ApiMessageTypes.ERROR:
                /* Handle Error from API */
                console.log(
                    'API Returned Error: ' + this.incomingMessage.errorCode + ' - ' + this.incomingMessage.errorText,
                );
                break;

            default:
                /* Unknown message type received */
                console.log('Unknown API Message Type received');
                break;
        }
    }

    processStatusChangeEvent(eventXML) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(eventXML, 'text/xml');

        const eventElement = xmlDoc.getElementsByTagName('event')[0];
        const eventType = eventElement.getAttributeNode('type');

        this.handleStatusChangeEvent(eventType.nodeValue);
    }

    handleStatusChangeEvent(eventType) {
        console.log('Status Change Event - type: ' + eventType);

        /* Process events that we care about, ignore the rest */

        switch (eventType) {
            case ApiStatusEventTypes.properties[ApiStatusEventTypes.CALL].text:
            case ApiStatusEventTypes.properties[ApiStatusEventTypes.SCREENSHARE].text:
                /* Received a simple status event - request details with Get Status without parameters */
                this.apiGetStatus(eventType);
                break;
            case ApiStatusEventTypes.properties[ApiStatusEventTypes.CALLHISTORY].text:
                /* Status events like CallHistory require a Get Status with parameters */
                this.apiGetStatusWithParameters(eventType, ' <count>15</count>\r\n <entryType>all</entryType>\r\n');
                break;
        }
    }

    processResponse(responseXML) {
        /* Basic responses carry no content, so we don't need to process them */
        /* For responses with XML data, figure out the type and distribute to sub-processing functions */
        if (responseXML.length > 0) {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(responseXML, 'text/xml');

            const statusElement = xmlDoc.getElementsByTagName('status')[0];
            if (statusElement != null) {
                const statusType = statusElement.getAttributeNode('type');
                console.log('Status Response type: ' + statusType.nodeValue);

                switch (statusType.nodeValue) {
                    case ApiStatusEventTypes.properties[ApiStatusEventTypes.CALL].text:
                        this.handleCallStatusResponse(xmlDoc);
                        break;
                    case ApiStatusEventTypes.properties[ApiStatusEventTypes.CALLHISTORY].text:
                        // handleCallHistoryStatusResponse(xmlDoc);
                        break;
                    case ApiStatusEventTypes.properties[ApiStatusEventTypes.SCREENSHARE].text:
                        // handleScreenShareStatusResponse	(xmlDoc);
                        break;
                    default:
                        console.log('Unknown Response type: ' + responseXML);
                        break;
                }
            }
        }
    }

    handleCallStatusResponse(callStatusDoc) {
        /* Each Call Status Response contain zero or more 'call' elements with information about each ongoing call */
        const calls = callStatusDoc.getElementsByTagName('call');

        /* Create an array to hold the list of calls */
        const currentCallList = [];

        /* Iterate through the list of calls and pick out the information */
        for (let i = 0; i < calls.length; i++) {
            const call = calls[i];

            const id = this.getStringValueFromXMLTag(call, 'id');
            const hold = this.getStringValueFromXMLTag(call, 'holdStatus');
            const recordingStatus = this.getStringValueFromXMLTag(call, 'recordingStatus');
            const recordingFile = this.getStringValueFromXMLTag(call, 'recordingFile');

            /* Each call has a list of one or more remote participants */
            const participants = call.getElementsByTagName('participants')[0].getElementsByTagName('participant');

            /* Create an array to hold the list of participants in each call */
            const participantList = [];

            /* Iterate through the list of remote participants and pick out the information */
            for (let p = 0; p < participants.length; p++) {
                const participant = participants[p];

                const number = this.getStringValueFromXMLTag(participant, 'number');
                const displayName = this.getStringValueFromXMLTag(participant, 'displayName');
                const state = this.getStringValueFromXMLTag(participant, 'state');
                const startTime = this.getStringValueFromXMLTag(participant, 'timeInitiated');
                const participantInfo = {
                    number,
                    displayName,
                    state,
                    startTime,
                };

                /* Add this participant to the list */
                participantList.push(participantInfo);
            }

            /* Create an object to store the information for this call */
            const callInfo = {
                id,
                hold,
                recording: recordingStatus,
                file: recordingFile,
                participants: participantList,
            };

            /* Add this call to the list */
            currentCallList.push(callInfo);
        }

        /* Call Helper Function to update HTML with current Call Information */
        console.log('call list', currentCallList);
        this.callList = currentCallList;
        // this.updateCallActivity(currentCallList);
    }

    constructApiMessage(requestType, body) {
        const contentLength = body.length;
        let msg =
            'GET /' +
            ApiRequestTypes.properties[requestType].text +
            '\r\nUser-Agent: ' +
            this.userAgentString +
            '\r\nTransaction-ID: ' +
            this.getNextTransactionID() +
            '\r\nContent-Type: application/xml\r\nContent-Length: ' +
            contentLength;

        if (contentLength > 0) {msg += '\r\n\r\n' + body;}

        return msg;
    }

    apiAnswerCall(callId, withVideo) {
        const content =
            xmlDeclarationString +
            '<answerCall>\r\n <callId>' +
            callId +
            '</callId>\r\n <withVideo>' +
            withVideo +
            '</withVideo>\r\n</answerCall>';
        const msg = this.constructApiMessage(ApiRequestTypes.ANSWER, content);
        this.sendMessage(msg);
    }

    getNextTransactionID() {
        this.lastTransactionID++;
        return this.lastTransactionID;
    }

    apiGetStatus(statusType) {
        const content = xmlDeclarationString + '<status>\r\n <type>' + statusType + '</type>\r\n</status>';
        const msg = this.constructApiMessage(ApiRequestTypes.STATUS, content);
        this.sendMessage(msg);
    }

    apiGetStatusWithParameters(statusType, parameterXML) {
        const content =
            xmlDeclarationString + '<status>\r\n <type>' + statusType + '</type>\r\n' + parameterXML + '</status>';
        const msg = this.constructApiMessage(ApiRequestTypes.STATUS, content);
        this.sendMessage(msg);
    }

    sendMessage(msg) {
        this.websocket.send(msg);
    }

    /****************************************************************************
                             BRIA API EVENT HANDLING
    ****************************************************************************/

    /* 'getStringValueFromXMLTag' is a helper function for digging through XML *
     * structures to retrieve values from the first occurrence of a named tag  */
    getStringValueFromXMLTag(xmlItem, tagName) {
        /* Get the first XML element with the given tag name */
        const element = xmlItem.getElementsByTagName(tagName)[0];

        if (element != null) {
            /* Get the first child node from the element */
            const childNode = element.childNodes[0];

            /* If the child node exist, return the value for it */
            if (childNode != null) {return childNode.nodeValue;}
        }

        /* If tag isn't found or has no value, return a blank string */
        return '';
    }
}
