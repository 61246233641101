import {CrmIntegrationService} from './crmIntegration.service';
import {Observable, Subject} from 'rxjs';

import {integrationServicesContainer} from './integrationServicesContainer.service';
import {CurrentContact} from '../.././core/_models/current-contact.model';
import {InContactAPIService} from '../InContact/incontact-api.service';
import {IncomingContactService} from '../_services/incoming-contact.service';
import {CallContactService} from '../_services/call-contact.service';
import {LocalStorageService} from 'ngx-webstorage';

// declare var zClient;

export class ZendeskCrmIntegrationService extends CrmIntegrationService {
    private currentZendeskUser: any = null;
    constructor(
        selectedCrm: any,
        servicesContainer: integrationServicesContainer,
        inContactAPIService: InContactAPIService,
        protected incomingContactService: IncomingContactService,
        protected callContactService: CallContactService,
        protected localStorageSrv: LocalStorageService,
    ) {
        super(selectedCrm, servicesContainer, inContactAPIService, incomingContactService, callContactService, localStorageSrv);
        const me = this;
        (this.ConfigurationMap.CallContactEvent_Inbound as any).zendeskSearchField = 'phone';
        (this.ConfigurationMap.CallContactEvent_Outbound as any).zendeskSearchField = 'phone';
        (this.ConfigurationMap.CallContactEvent_Inbound as any).zendeskSearchOperator = '*';
        (this.ConfigurationMap.CallContactEvent_Outbound as any).zendeskSearchOperator = '*';
        (this.ConfigurationMap.EmailContactEvent_Inbound as any).zendeskSearchField = 'email';
        (this.ConfigurationMap.EmailContactEvent_Outbound as any).zendeskSearchField = 'email';
        (this.ConfigurationMap.EmailContactEvent_Inbound as any).zendeskSearchOperator = '';
        (this.ConfigurationMap.EmailContactEvent_Outbound as any).zendeskSearchOperator = '';
        (this.ConfigurationMap.VoiceMailContactEvent_Inbound as any).zendeskSearchField = 'phone';
        (this.ConfigurationMap.VoiceMailContactEvent_Inbound as any).zendeskSearchOperator = '*';

        try {
            window.zClient.get('currentUser').then(function (data) {
                me.currentZendeskUser = data.currentUser;
            });
        } catch (error) {
            console.log('Error zendeskCrmIntegrationService constrcutor: ' + error);
        }

        //this.zendeskClient = ZAFClient.init();
    }

    getCurrentTicketCaseId(contactId) {
        const ticketId = this.getRandomInt(1111, 9999);
        const values = this.servicesContainer.DataContainerService.CurrentContactArray.getValue();
        const index = this.servicesContainer.DataContainerService.findCurrentTicketByContact(contactId);

        if (index > -1) {
            values[index].ticketId = ticketId;
        } else {
            values.push(new CurrentContact(ticketId, contactId));
        }

        this.servicesContainer.DataContainerService.CurrentContactArray.next(values);
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    processNewContact(data: any, context: any) {
        super.processNewContact(data, context);
    }

    processExistingContact(data: any, context: any) {
        super.processExistingContact(data, context);
    }

    finalizeContact(data: any, context: any) {
        super.finalizeContact(data, context);

        this.getContactStringAsync(data, context).subscribe(htmlstr => {
            const currentContact = this.servicesContainer.DataContainerService.getCurrentContact(data.contact.ContactID);
            //if (this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId &&
            // (this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId + "").trim().length > 0) {
            if (currentContact.ticketId && (currentContact.ticketId + '').trim().length > 0) {
            } else {
                this.afterContactFinalized(data, context);
                return;
            }

            //this.getTicketById(this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId).subscribe(ticket => {
            this.getTicketById(currentContact.ticketId).subscribe(ticket => {
                const contact = data.contact;
                const tic: any = {};
                tic.fields = {};

                if (context.mappings) {
                    const mappings = context.mappings.filter(map => map.value && map.value.length > 0);
                    if (mappings) {
                        for (let i = 0; i < mappings.length; i++) {
                            const mapField = mappings[i];
                            const fieldSearch = ticket.fields.filter(f => f.id == parseInt(mapField.value, 10));
                            if (fieldSearch.length > 0) {
                                const field = fieldSearch[0];
                                if (mapField.name === '_recordingFilePath') {
                                    tic.fields[mapField.value] = (!field || field.value === null ? '' : field.value + ', ') + data.contact._recordingFilePath;
                                } else if (mapField.name === '_startTime') {
                                    tic.fields[mapField.value] = (field.value === null ? '' : field.value + ', ') + data.contact._startTime;
                                    //this.fcc.common.getFormatedDateTime(new Date(contact.startTime()));
                                } else if (mapField.name === '_duration') {
                                    tic.fields[mapField.value] = (field.value === null ? '' : field.value + ', ') + data.contact._duration;
                                } else {
                                    tic.fields[mapField.value] = (field.value === null ? '' : field.value + ', ') + data.contact[mapField.name];
                                }
                            }
                        }
                    }
                }

                // let htmlstr = this.getContactString(data, context);
                if (htmlstr && htmlstr.length > 0) {
                    tic.comment = {};
                    tic.comment.public = false;
                    //tic.comment.body = htmlstr;
                    tic.comment.html_body = htmlstr;
                }

                tic.via_id = this.setTicketAudit(data.contact);
                this.updateTicket(ticket.id, tic);
                this.afterContactFinalized(data, context);
            });
        });

        //super.finalizeContact(data, context);
    }

    private setTicketAudit(contact) {
        let viaId: number;

        switch (contact.Type) {
            case 'VoiceMailContactEvent':
                viaId = 44;
                break;
            case 'CallContactEvent':
                if (contact.IsInbound === 'True') {
                    viaId = 45;
                } else {
                    viaId = 46;
                }
                break;
            default:
                break;
        }

        return viaId;
    }

    private createTicketObject(data: any, context: any) {
        const contactSettings = context.settings; //this.getContactSettings(data.contact);
        const configMap = context.configMap;
        const phone = data.contact[configMap.incontactSearchField];
        const user = data.user;

        const obj: any = {
            subject: !contactSettings.ticketSubject ? 'New Ticket' : this.replaceTokensWithValues(contactSettings.ticketSubject, data, context),
        };
        if (contactSettings.defaultGroup && parseInt(contactSettings.defaultGroup, 10)) {
            obj.group_id = parseInt(contactSettings.defaultGroup, 10);
        }
        if (contactSettings.ticketTags) {
            obj.tags = this.replaceTokensWithValues(contactSettings.ticketTags, data, context);
        }

        obj.comment = {};
        if (data.contact.Type == 'EmailContactEvent') {
            //obj.comment.body = this.getContactString(data, context);
            obj.comment.html_body = this.getContactString(data, context);
        } else {
            //obj.comment.body = (!contactSettings.ticketComments ? 'New ticket details...' :
            // this.replaceTokensWithValues(contactSettings.ticketComments, data, context));
            obj.comment.html_body = !contactSettings.ticketComments
                ? 'New ticket details...'
                : this.replaceTokensWithValues(contactSettings.ticketComments, data, context);
        }

        obj.comment.public = false;
        obj.requester_id = data.user.id;

        obj.assignee_id = this.currentZendeskUser.id;
        obj.via_id = this.setTicketAudit(data.contact);

        return obj;
    }

    createUserObject(data: any, context: any): any {
        const obj: any = {};
        const settings = this.getContactSettings(data.contact);

        const icSearchFieldValue =
            data.contact[
                this.getConfigurationMap(data.contact).incontactSearchField ? this.getConfigurationMap(data.contact).incontactSearchField : 'ContactID'
            ];
        const zdSearchFieldName = this.getConfigurationMap(data.contact).zendeskSearchField;
        obj.name = this.getConfigurationMap(data.contact).userTitle + ' ' + icSearchFieldValue;
        if (zdSearchFieldName) {
            obj[zdSearchFieldName] = icSearchFieldValue;
        }

        if (this.getConfigurationMap(data.contact).isCallContact && settings.e164enabled) {
            obj.shared_phone_number = true;
        }

        return obj;
    }

    createTicket(data: any, context: any): Subject<any> {
        const subject = new Subject();
        const postTicket = (u: any) => {
            data.user = u;
            const ticket = this.createTicketObject(data, context);
            window.zClient
                ?.request({
                    url: '/api/v2/tickets.json',
                    type: 'POST',
                    data: {ticket},
                })
                .then(tic => {
                    subject.next(tic.ticket);
                })
                .catch(error => {
                    throw error;
                });
        };
        if (!data.user) {
            //var user = this.createUserObject(data.contact);
            this.createUser(data, context).subscribe(result => postTicket(result.user));
        } else {
            postTicket(data.user);
        }
        return subject;
    }

    createUser(data: any, context: any): Subject<any> {
        const subject = new Subject();
        const userObj = this.createUserObject(data, context);
        try {
            window.zClient
                ?.request({
                    url: '/api/v2/users.json',
                    type: 'POST',
                    data: {user: userObj},
                })
                .then(
                    result => {
                        subject.next(result);
                    },
                    error => {
                        subject.error(this.handleError('user', error));
                    },
                );
        } catch (error) {
            throw this.handleError('user', error);
        } finally {
            return subject;
        }
    }

    alwaysCreateUser(data, context) {
        this.createUser(data, context).subscribe(result => {
            let user;
            try {
                user = result.user;
            } catch (error) {
                user = result;
            }
            data.user = user;
            this.openUser(data.user);
        });
    }

    customSearch(searchString: string) {
        const obs = new Subject<any>();
        window.zClient
            ?.request({
                url: '/api/v2/search.json?query=' + searchString,
                type: 'GET',
            })
            .then(result => {
                obs.next(result);
            })
            .finally(() => {
                obs.complete();
            });
        return obs;
    }

    searchTicket(data: any, context: any): Subject<any> {
        const obs = new Subject<any>();
        window.zClient
            ?.request({
                url: '/api/v2/search.json?query=status<=open+requester_id:' + data.user.id,
                type: 'GET',
            })
            .then(result => {
                obs.next(result);
            });
        return obs;
    }

    searchUser(data: any, context: any): Subject<any> {
        const obs = new Subject<any>();
        const searchField = context.configMap.zendeskSearchField;
        const searchOperator = context.configMap.zendeskSearchOperator;
        const value = data.contact[context.configMap.incontactSearchField];
        window.zClient
            ?.request({
                url: '/api/v2/search.json?query=role:end-user+' + searchField + ':' + searchOperator + value,
                type: 'GET',
            })
            .then(function (userData) {
                obs.next(userData);
            });
        return obs;
    }

    private getTicketById(Id) {
        const obs = new Subject<any>();
        window.zClient
            ?.request({
                url: '/api/v2/tickets/' + Id + '.json',
                type: 'GET',
            })
            .then(function (data) {
                obs.next(data.ticket);
            });
        return obs;
    }

    openTicket(ticket: any): Subject<any> {
        const obs = new Subject<any>();
        window.zClient
            ?.request({
                url: '/api/v2/channels/voice/agents/' + this.currentZendeskUser.id + '/tickets/' + ticket.id + '/display.json',
                type: 'POST',
            })
            .then(function (data) {
                obs.next(data);
            });
        return obs;
    }

    openUser(user: any) {
        window.zClient?.request({
            url: '/api/v2/channels/voice/agents/' + this.currentZendeskUser.id + '/users/' + user.id + '/display.json',
            type: 'POST',
        });
    }

    private updateTicket(ticketId, data): Subject<any> {
        const obs = new Subject<any>();
        window.zClient
            ?.request({
                url: '/api/v2/tickets/' + ticketId + '.json',
                type: 'PUT',
                data: {
                    ticket: data,
                },
            })
            .then(function (data1) {
                console.dir(data1);
                obs.next(data1);
            });
        return obs;
    }

    handleError(type: string, error: any) {
        switch (type) {
            case 'user':
                if (error.responseJSON.details.email[0] !== undefined) {
                    this.servicesContainer.DataContainerService.handleError(
                        new Error('Ticket not created: ' + error.responseJSON.details.email[0].description),
                    );
                }
                break;
        }
    }

    notify(action, message) {
        window.zClient.invoke('notify', message);
    }
}
