import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {ReportFeedbackRoutingModule} from './report-feedback.routing';
import {ReportFeedbackComponent} from './report-feedback.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [BrowserModule, FormsModule, SharedModule, ReportFeedbackRoutingModule],
    exports: [ReportFeedbackRoutingModule],
    declarations: [ReportFeedbackComponent],
    providers: [],
})
export class ReportFeedbackModule {}
