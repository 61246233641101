import {throwError as observableThrowError, BehaviorSubject, Observable} from 'rxjs';
import {Injectable, Inject} from '@angular/core';
import {SecurityProfileModel} from '../../models';
import {SecurityProfileApi} from '../../api/security-profile/security-profile.api';

@Injectable()
export class IcSecurityProfileService {
    securityProfileBS: BehaviorSubject<SecurityProfileModel> = new BehaviorSubject<SecurityProfileModel>(null);

    constructor(@Inject(SecurityProfileApi) protected securityProfileApi: SecurityProfileApi) {}

    LoadSecurityProfile(profileId: string) {
        this.securityProfileApi.getSecurityProfile(profileId).subscribe(
            res => {
                this.securityProfileBS.next(res);
                return observableThrowError(true);
            },
            err => {
                //
            },
        );
    }

    public getSecurityPermission(key: string) {
        return this.securityProfileBS.value?.permissions?.find(x => x.permissionKey === key);
    }

    public hasPermission(key: string, value: string) {
        const ind = this.getSecurityPermission(key)?.optionKeys?.indexOf(value);
        return ind != null && ind !== -1;
    }
}
