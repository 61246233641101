import {interval as observableInterval, Observable, Subject, BehaviorSubject} from 'rxjs';

import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {InContactAPIService} from '../InContact/incontact-api.service';

// import {DialogService} from './dialog.service';
import {CallContactService} from './call-contact.service';
import {EmailContactService} from './email-contact.service';
import {VoiceMailContactService} from './voice-mail-contact.service';
import {WorkItemContactService} from './work-item-contact.service';
import {ChatContactService} from './chat-contact.service';
import {ContactBase} from '../_models/contact-base';
import {IndicatorsService} from './indicators.service';
import {ContactViewBaseModel} from '../_models/_viewmodels/contact-view-base.model';
import {ContactServiceBase} from './contact.service.base';
import {ZendeskCrmSerivce} from './crm-integration/zendesk.crm-service';
import {DynamicsCrmSerivce} from './crm-integration/dynamics.crm-service';
import {CrmServiceBase} from './crm-integration/base.crm-service';
import {MessageService} from './message.service';
import {MarqueeMessagesService} from './marquee-messages-service';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {CrmApplicationModel} from 'src/app/sdk/fac-crm/models';
import {KustomerCrmSerivce} from './crm-integration/kustomer.crm-service';
import {DexieLogsService} from 'src/app/sdk/dexie/services';
import {WebRTCService} from '../InContact/web-rtc/web-rtc.service';
import {AgentStateModel} from '../_models/agent-state.model';

@Injectable()
/** Dummy version of an authenticated user service */
export class ApplicationContextService {
    public serverTimeOffset: number;
    public applicationTimer: Observable<number>;
    public applicationTicks = 0;

    public callContactService: CallContactService;
    public emailContactService: EmailContactService;
    public voicemailContactService: VoiceMailContactService;
    public workitemContactService: WorkItemContactService;
    public chatContactService: ChatContactService;
    public selectedCrmApplication: CrmApplicationModel = new CrmApplicationModel();

    public crmIntegrationService: CrmServiceBase;
    private allContactServicesCollection: ContactServiceBase<ContactBase, ContactViewBaseModel<ContactBase>>[] = [];
    public allContactsCollection: BehaviorSubject<ContactViewBaseModel<ContactBase>[]> = new BehaviorSubject<ContactViewBaseModel<ContactBase>[]>([]);
    public omnichannelGroups: any[] = [];

    private cachedContactsLoaded = false;
    private applicationProcessStarted = false;

    //public indicatorService: IndicatorsService;

    public contactActivated: Subject<ContactViewBaseModel<ContactBase>> = new Subject<ContactViewBaseModel<ContactBase>>();
    public contactFinished: Subject<ContactViewBaseModel<ContactBase>> = new Subject<ContactViewBaseModel<ContactBase>>();

    constructor(
        public agentSessionService: IcAgentSessionService,
        public inContactAPIService: InContactAPIService,
        public dialogService: DialogService,
        public indicatorsService: IndicatorsService,
        public appRouter: Router,
        public messageService: MessageService,
        public marqueeMessageService: MarqueeMessagesService,
        public localStorageSrv: LocalStorageService,
        public router: Router,
        @Inject(WebRTCService) public webRtcSrv: WebRTCService,
        @Inject(DexieLogsService) public dexieLogsSrv: DexieLogsService,
    ) {
        const me = this;
        me.applicationProcessStarted = false;
        this.applicationTimer = observableInterval(1000).pipe(map(x => this.applicationTicks++));

        this.agentSessionService.serverTimeOffset.subscribe(time => {
            this.serverTimeOffset = time;
        });

        this.contactActivated.subscribe(vm => {
            this.crmIntegrationService.processContact(vm);
        });

        this.contactFinished.subscribe(vm => {
            this.crmIntegrationService.processFinishedContact(vm);
        });

        /* this.agentService.isSessionJoined.subscribe(r => {
            if (r && !this.applicationProcessStarted) {
                this.startApplicationProcess();
                this.applicationProcessStarted = true;
            }
        }) */

        this.agentSessionService.isSessionStarted.subscribe(r => {
            if (r && !this.applicationProcessStarted) {
                this.startApplicationProcess();
                this.applicationProcessStarted = true;
            }
        });

        this.agentSessionService.sessionState.subscribe(r => {
            if (r === 'ended') {
                this.allContactServicesCollection.map(service => {
                    service.currentContactViewModels.next([]);
                    service.currentContacts.next([]);
                });
                this.stopApplicationProcess();
                this.applicationProcessStarted = false;
            }
        });
    }

    public updateAllContacts() {
        let allContacts: ContactViewBaseModel<ContactBase>[] = [];
        this.allContactServicesCollection.map(service => {
            allContacts.push(...service.currentContactViewModels.value);
        });
        allContacts = allContacts.sort((a, b) => (a.contact.contactId < b.contact.contactId ? 0 : 1));
        this.allContactsCollection.next(allContacts);
        this.omnichannelGroups = this.groupContacts(
            allContacts.filter(c => c.contact.isOmniGroupContact()),
            'omniGroupId',
        );
    }

    groupContacts(collection: Array<any>, property: string): Array<any> {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection) {
            return null;
        }

        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current[property]]) {
                previous[current[property]] = [current];
            } else {
                previous[current[property]].push(current);
            }

            return previous;
        }, {});

        // this will return an array of objects, each object containing a group of objects
        return Object.keys(groupedCollection).map(key => ({key, value: groupedCollection[key]}));
    }

    private appTimerID = null;

    public setCrmSettings(settings: CrmApplicationModel) {
        this.selectedCrmApplication = settings;

        this.allContactServicesCollection = [
            this.callContactService,
            this.emailContactService,
            this.voicemailContactService,
            this.voicemailContactService,
            this.workitemContactService,
            this.chatContactService,
        ];
    }

    public initZendeskCrmService() {
        this.crmIntegrationService = new ZendeskCrmSerivce(this.localStorageSrv, this.messageService, this.dexieLogsSrv, this.router);
    }
    public initDynamicsCrmService() {
        this.crmIntegrationService = new DynamicsCrmSerivce(this.localStorageSrv, this.messageService, this.dexieLogsSrv, this.router);
    }
    public initKustomerCrmService() {
        this.crmIntegrationService = new KustomerCrmSerivce(this.localStorageSrv, this.messageService, this.dexieLogsSrv, this.router);
        this.crmIntegrationService.initDone$.subscribe(res => {
            this.crmIntegrationService.on('routing-status-change', (data: any) => {
                if (data?.statusType !== 'busy') {
                    const status = data.statusType === 'available' ? 'Available' : 'Unavailable';

                    this.agentSessionService.isSessionStarted.subscribe(isStarted => {
                        if (isStarted) {
                            this.agentSessionService.agentStateChanged(new AgentStateModel(status, '', status, false));
                        }
                    });
                }
            });

            this.agentSessionService.agentStatus.subscribe(s => {
                const currentState = this.agentSessionService.sessionStorageService.retrieve('currentState') as string;
                // console.log('agent status: ', s, currentState);
                if (currentState.toLocaleLowerCase() === 'available' || currentState.toLocaleLowerCase() === 'unavailable') {
                    window.Kustomer.requestUpdateUserStatus({fallbackStatusType: currentState.toLocaleLowerCase()});
                } else {
                    window.Kustomer.requestUpdateUserStatus({fallbackStatusType: 'busy'});
                }
            });
        });
    }
    public initCrmService() {
        this.crmIntegrationService = new CrmServiceBase(this.localStorageSrv, this.messageService, this.dexieLogsSrv);
    }

    public getCrmSettings(settingsSectionName: string): any {
        //let configMap = this.getConfigurationMap(contact);
        if (this.selectedCrmApplication.contactSettings) {
            return this.selectedCrmApplication.contactSettings[settingsSectionName] ?? {};
        }
    }

    public getCrmMappings(settingsSectionName: string): any {
        if (this.selectedCrmApplication.contactMappings) {
            return this.selectedCrmApplication.contactMappings[settingsSectionName];
        }
    }

    public stopApplicationProcess() {
        this.allContactServicesCollection.forEach((service, index) => {
            service.onServiceStoped();
        });
    }

    public startApplicationProcess() {
        this.allContactServicesCollection.forEach((service, index) => {
            service.onServiceStarted();
        });
    }
}
