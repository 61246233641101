<form novalidate #agentForm="ngForm" (ngSubmit)="Login()">
    <fieldset>
        <div>
            <text-field
                class="mb-10"
                [(ngModel)]="icAgentSessionSrv.agent.password"
                [fieldConfig]="{
                    type: 'password',
                    id: 'password',
                    tabindex: 2,
                    iconClass: 'fa fa-key'
                }"
                [required]="true"
                name="password"
                [placeholder]="'Login.Labels.Password' | translate"
                [label]="'Login.Labels.Password' | translate"
                *ngIf="type != 'code'"
            ></text-field>

            <!-- <select-field
                [(ngModel)]="currentLang"
                [label]="'HOME.SELECT' | translate"
                [fieldConfig]="{
                    id: 'select-language',
                    tabindex: 3,
                    iconClass: 'fa fa-language'
                }"
                name="lang"
                (change)="langChange($event)"
                [options]="langs"
                [textKey]="'#index'"
                [valueKey]="'#index'"
            ></select-field> -->

            <div
                class="form-group"
                style="display: flex; justify-content: left; align-items: baseline"
                *ngIf="authenticationSrv.stationIdSessionAllowed || authenticationSrv.phoneNumberSessionAllowed || authenticationSrv.webRTCAllowed"
            >
                <div class="radio radio-info" *ngIf="authenticationSrv.stationIdSessionAllowed">
                    <label class="radio-inline" aria-label="Session Type" for="sessionId">
                        <input
                            type="radio"
                            id="sessionId"
                            tabindex="4"
                            aria-label="Station Id"
                            [(ngModel)]="icAgentSessionSrv.agent.sessionType"
                            #sessionType1="ngModel"
                            name="sessionType"
                            value="station"
                        />
                        {{ 'Login.Labels.StationId' | translate }}
                    </label>
                </div>
                <div class="radio radio-info" style="margin-left: 10px" *ngIf="authenticationSrv.phoneNumberSessionAllowed">
                    <label class="radio-inline active radio-button" for="phoneNo">
                        <input
                            type="radio"
                            id="phoneNo"
                            tabindex="5"
                            aria-label="Phone Number"
                            [(ngModel)]="icAgentSessionSrv.agent.sessionType"
                            #sessionType="ngModel"
                            name="sessionType"
                            value="phone"
                        />
                        {{ 'Login.Labels.PhoneNo' | translate }}
                    </label>
                </div>
                <div class="radio radio-info" style="margin-left: 10px" *ngIf="authenticationSrv.webRTCAllowed">
                    <label class="radio-inline active radio-button" for="webRTC">
                        <input
                            type="radio"
                            id="webRTC"
                            tabindex="5"
                            aria-label="Web RTC"
                            [(ngModel)]="icAgentSessionSrv.agent.sessionType"
                            #sessionType="ngModel"
                            name="sessionType"
                            value="WebRTC"
                        />
                        {{ 'Login.Labels.WebRTC' | translate }}
                    </label>
                </div>

                <!-- <div class="radio radio-info" style="margin-left: 10px">
                    <label class="radio-inline active radio-button" for="SoftPhone">
                        <input
                            type="radio"
                            id="SoftPhone"
                            tabindex="5"
                            aria-label="Soft Phone"
                            [(ngModel)]="icAgentSessionSrv.agent.sessionType"
                            #sessionType="ngModel"
                            name="sessionType"
                            value="SoftPhone"
                        />
                        {{ 'Login.Labels.SoftPhoneLbl' | translate }}
                    </label>
                </div> -->

                <div [hidden]="!!icAgentSessionSrv.agent.sessionType" class="alert alert-danger">Session Type is required</div>
            </div>

            <text-field
                *ngIf="icAgentSessionSrv.agent.sessionType !== 'WebRTC'"
                class="mb-10"
                [(ngModel)]="icAgentSessionSrv.agent.sessionValue"
                [fieldConfig]="{
                    type: 'text',
                    id: 'contactNo',
                    tabindex: 6,
                    iconClass: 'fa fa-phone-square'
                }"
                [required]="true"
                name="contactNo"
                [placeholder]="(icAgentSessionSrv.agent.sessionType ? 'Login.Labels.' + icAgentSessionSrv.agent.sessionType : 'Station Id') | translate"
                [label]="(icAgentSessionSrv.agent.sessionType ? 'Login.Labels.' + icAgentSessionSrv.agent.sessionType : 'Session Id') | translate"
            ></text-field>

            <div class="form-group text-right">
                <button type="button" class="btn btn-grey p-px-5 pull-left" routerLink="/authentication/login-step-one">
                    <i class="fas fa-arrow-left"></i>
                    {{ 'Login.Labels.Back' | translate }}
                </button>
                <button [disabled]="agentForm.invalid" type="submit" class="btn btn-success p-px-5">
                    {{ 'Login.Labels.Login' | translate }}
                </button>
            </div>
        </div>
    </fieldset>
</form>
