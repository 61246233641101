import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TextFieldComponent} from './text-field/text-field.component';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {CommonModule} from '@angular/common';
import {SelectFieldComponent} from './select-field/select-field.component';

@NgModule({
    imports: [FormsModule, CommonModule, InputTextModule, DropdownModule],
    declarations: [TextFieldComponent, SelectFieldComponent],
    exports: [FormsModule, CommonModule, SelectFieldComponent, InputTextModule, DropdownModule, TextFieldComponent],
    providers: [],
})
export class FormComponentsModules {
    constructor() {}
}
