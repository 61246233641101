import {DynamicsActivityPartyModel, DynamicsActivityPointerModel} from '.';

export class DynamicsTaskModel extends DynamicsActivityPointerModel {
    public task_activity_parties: Array<DynamicsActivityPartyModel>;

    constructor(data?: Partial<DynamicsTaskModel>) {
        super(data);

        Object.assign(this, data);
    }
}
