import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ConfigService} from 'src/app/core/_services/config.service';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit, AfterViewInit {
    version = '';
    devMode = false;

    constructor(protected configServices: ConfigService) {}

    ngOnInit() {
        // this.configServices.configuations.subscribe(config => {
        //     this.version = config.version;
        //     this.devMode = config.dev;
        // });
        this.version = environment.VERSION;
        this.devMode = window.env === 'dev';
    }

    ngAfterViewInit() {}
}
