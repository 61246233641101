import {AfterViewChecked, Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DialogRef, overlayConfigFactory} from 'ngx-modialog-7';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';
import {SpinnerService} from 'src/app/core/_components/spinner/spinner.service';
import {AgentStateModel} from 'src/app/core/_models/agent-state.model';
import {ErrorService} from 'src/app/core/_services/error.service';
import {NextEventService} from 'src/app/core/_services/next-events.service';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
import {AuthenticationService} from 'src/app/screens/authentication/services/authentication.service';
import {SoftPhoneService} from 'src/app/sdk/counter-path/services/softphone/softphone.service';
import {IcAgentSessionService} from 'src/app/sdk/incontact/services/agent-sessions/agent-session-events.service';
import {MySubscriptionHandler} from 'src/app/shared/classes';
import {LbNgxDropdownComponent} from 'src/app/plugins/lb-ngx-dropdown/dropdown/lb-ngx-dropdown.component';
import {WebRTCService} from 'src/app/core/InContact/web-rtc/web-rtc.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends MySubscriptionHandler implements OnInit, AfterViewChecked {
    visiableAgentStates: AgentStateModel[] = [];

    startTimeElapsed = '';
    public acwtimeout: string;
    public isStateCounterVisible: boolean;

    @ViewChild('statusDropdown') el: ElementRef;

    @ViewChild('logoutModal') logoutModal: TemplateRef<any>;
    logoutModalRef: DialogRef<any>;

    public nextAgentStatus: string;
    public nextNextAgentStatus: string;

    constructor(
        public authSrv: AuthenticationService,
        public icAgentSessionSrv: IcAgentSessionService,
        private dialogSrv: DialogService,
        private spinnerSrv: SpinnerService,
        private nextEventSrv: NextEventService,
        private softPhoneSrv: SoftPhoneService,
        private errorSrv: ErrorService,
        public webrtcSrv: WebRTCService,
    ) {
        super();
    }

    ngOnInit() {
        this.addSubscription$ = this.icAgentSessionSrv.agentStates.subscribe(event => {
            this.visiableAgentStates = this.icAgentSessionSrv.getVisibleAgentStates(event);
            // console.log(this.visiableAgentStates);
            this.addSubscription$ = this.icAgentSessionSrv.selectedAgentState.subscribe(state => {
                this.icAgentSessionSrv.agent.selectedAgentState = this.visiableAgentStates[1];
            });
        });

        this.addSubscription$ = this.icAgentSessionSrv.currentAgentState.subscribe(state => {
            this.icAgentSessionSrv.agent.currentAgentState = state;
        });

        this.addSubscription$ = this.icAgentSessionSrv.nextAgentStatus.subscribe(nextState => {
            if (nextState.OutReason !== '') {
                this.nextAgentStatus = nextState.OutReason;
            } else {
                this.nextAgentStatus = nextState.State;
            }
        });

        this.addSubscription$ = this.icAgentSessionSrv.nextNextAgentStatus.subscribe(nextNextState => {
            if (nextNextState.OutReason !== '') {
                this.nextNextAgentStatus = nextNextState.OutReason;
            } else {
                this.nextNextAgentStatus = nextNextState.State;
            }
        });

        this.addSubscription$ = this.icAgentSessionSrv.startTimeElapsed.subscribe(time => (this.startTimeElapsed = time));

        this.addSubscription$ = this.icAgentSessionSrv.acwtimeout.subscribe(value => (this.acwtimeout = value));

        this.addSubscription$ = this.icAgentSessionSrv.isStateCounterVisible.subscribe(value => (this.isStateCounterVisible = value));
    }

    ngAfterViewChecked() {
        if (this.el !== undefined) {
            $(this.el.nativeElement).on('keypress', function () {
                const $current = $(this);
                // attach key listener when dropdown is shown
                $(document).keypress(function (e) {
                    // get the key that was pressed
                    const key = String.fromCharCode(e.which);
                    // look at all of the items to find a first char match
                    $current.find('li').each(function (idx, item) {
                        $(item).removeClass('active'); // clear previous active item

                        //let ch = $(item).text().trim().charAt(0).toLowerCase();

                        if ($(item).text().trim().charAt(0).toLowerCase() === key) {
                            // set the item to selected (active)
                            $(item).addClass('active');
                            $(item).find('a').focus();
                        }
                    });

                    e.stopImmediatePropagation();
                });
            });

            // unbind key event when dropdown is hidden
            $(this.el.nativeElement).on('hide.bs.dropdown', function () {
                $(document).unbind('keypress');
            });
        }
    }

    public get getColorClass() {
        const currentState = this.icAgentSessionSrv.sessionStorageService.retrieve('currentState') as string;

        if (currentState === 'Available') {
            return 'app-header-green';
        } else if (currentState === 'Unavailable') {
            return 'app-header-red';
        } else {
            return 'app-header-orange';
        }
    }

    agentStateChanged(state: AgentStateModel) {
        let result;
        // if (this.icAgentSessionSrv.agent?.currentAgentState === 'Dialer' || this.nextAgentStatus === 'Dialer') {
        this.icAgentSessionSrv.logoutDialer().subscribe(
            res => {},
            err => {
                // console.log(err);
            },
        );
        // }

        if (state !== undefined) {
            this.icAgentSessionSrv.agentStateChanged(state);
        }
    }

    confirmEndSession() {
        // this.logoutModalRef = this.modalSrv.open(
        //     this.logoutModal,

        // );
        this.logoutModalRef = this.dialogSrv.openModal(
            this.logoutModal,
            overlayConfigFactory({
                isBlocking: true,
                dialogClass: 'modal-dialog',
            }),
        );
        // if (this.appContext.allContactsCollection.value.length > 0) {
        //     this.logoutModalRef = this.dialogService.openModal(
        //         this.logoutModal,
        //         overlayConfigFactory({
        //             isBlocking: true,
        //             dialogClass: 'modal-dialog',
        //         }),
        //     );
        // } else this.endSession();
    }

    endSession() {
        // if (this.logoutModal) {
        //     this.logoutModalRef.close();
        // }
        this.dialogSrv.close(this.logoutModalRef);

        this.spinnerSrv.isRunning = true;
        return this.icAgentSessionSrv
            .endSession(InContactApiSettings.forceLogout, InContactApiSettings.endContacts, InContactApiSettings.ignorePersonalQueue)
            .subscribe(
                res => {
                    this.icAgentSessionSrv.clearLocalStorage();
                    clearTimeout(this.nextEventSrv.nextEventTimeout);

                    //location.reload();

                    if (this.icAgentSessionSrv.applicationType && this.icAgentSessionSrv.applicationType !== '') {
                        //this.router.navigate(['/login'], { queryParams: { applicationType: this.agentEventsService.applicationType } });
                    } else {
                        //this.router.navigate(['/login']);
                    }
                    this.spinnerSrv.isRunning = true;
                    //this.router.navigate(['/login']);

                    setTimeout(() => {
                        this.spinnerSrv.isRunning = false;
                    }, 500);

                    this.softPhoneSrv.disconnect();

                    if (this.icAgentSessionSrv.isWebRTCSession) {
                        this.webrtcSrv?.logout();
                        this.webrtcSrv?.destroy();
                    }
                },
                error => {
                    this.spinnerSrv.isRunning = false;
                    this.errorSrv.onSystemError(error);
                    //this.isRequesting=false;
                    //this.router.navigate(['/login']);
                },
            );
    }
}
