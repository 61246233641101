import {Injectable} from '@angular/core';

import {Events} from '.././InContact/models/events.model';
// import {DialogService} from './dialog.service';

@Injectable()
export class EventLogService {
    public eventLogData: Events[];
    private result: any;

    constructor() // private dialogService: DialogService
    {
        this.eventLogData = [];
    }

    logEventData(event: Events) {
        if (this.eventLogData === undefined) {this.eventLogData = [];}

        if (this.eventLogData.length >= 50) {
            this.eventLogData.splice(this.eventLogData.length - 1, 1, event);
        } else {
            this.eventLogData.splice(this.eventLogData.length, 1, event);
        }
    }

    getEventData() {
        return this.eventLogData;
    }
}
