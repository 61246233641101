import {Inject, Injectable} from '@angular/core';
import {ServerConfigModel, SoundConfigModel, UserPreferencesModel} from './models';
import {DefaultPhoneConfig, DefaultServerConfig, DefaultSoundConfig, DefaultUserPref, WEB_RTC_USER_AGENT} from './config';
import {PhoneConfigModel} from './models/phone-config.model';
import {AgentSettingsModel} from 'src/app/sdk/incontact/models';
import {LocalStorageService} from 'ngx-webstorage';
import {AgentInfoModel} from '../models/agent-info.model';
import {Agent} from '../../_models/agent.model';
import {CallContactService} from '../../_services/call-contact.service';
import {CustomEventModel} from '../models/custom-event.model';
import {IcSecurityProfileService} from 'src/app/sdk/incontact/services';
import {FacLocalStorageService} from 'src/app/sdk/storage';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {InContactAPIService} from '../incontact-api.service';
import {DexieLogsService} from 'src/app/sdk/dexie/services';
import {AnyObject} from 'src/app/global';
import {IcAgentSessionService} from 'src/app/sdk/incontact/services/agent-sessions/agent-session-events.service';
import {AppToasterService} from 'src/app/plugins/ngx-toastr';

// import './../../../../assets/webrtc/js/jssip';
// import './../../../../assets/webrtc/js/ac_webrtc.min';
// import './../../../../assets/webrtc/icAudioCodes';

declare let AudioPlayer: any;
declare let AudioCodesUA: any;

@Injectable()
export class WebRTCService {
    audioPlayer: any = null;

    userPreferences: UserPreferencesModel = null;
    soundConfig: SoundConfigModel = null;
    phoneConfig: PhoneConfigModel = null;
    serverConfig: ServerConfigModel = null;

    localStream: any = null;
    remoteStream: any = null;

    activeCall = null;
    transferCall = null;

    isLogin = false;

    propertyStrings = {
        createTime: '_create_time',
        logRecord: 'log_record',
        video: '_video',
        user: '_user',
        dn: '_display_name',
    };

    phone: any = null;

    public isConnecting = false;
    public softPhoneDNIS: string = null;

    agentInfo: Agent = null;

    restoreData: any = null;
    delay: number = null;
    initDone = false;

    public isOutgoingCall = false;

    private _agentLeg: string = null;
    private _agentLegStatus: string = null;
    // get agentLeg() {
    //     return this._agentLeg;
    // }
    // set agentLeg(val: string) {
    //     this._agentLeg = val;
    //     // this.setupAndCallSoftPhone();
    // }

    public connectToAgentLegPromise: Promise<any> = null;

    public customEvent: CustomEventModel = null;

    public autoAccept = 'No';

    private stateDescription: string | null = null;

    private _isConnectAgentLeg = false;
    public get isConnectAgentLeg() {
        return this._isConnectAgentLeg;
    }
    public set isConnectAgentLeg(v: boolean) {
        this._isConnectAgentLeg = v;
    }

    constructor(
        public localStorageSrv: LocalStorageService, //  private callContactService: CallContactService
        public facLocalStorageSrv: FacLocalStorageService,
        public securityProfileSrv: IcSecurityProfileService,
        public dialogSrv: DialogService,
        private loaderSrv: NgxUiLoaderService,
        public inContactAPIService: InContactAPIService,
        @Inject(DexieLogsService) public dexieLogsSrv: DexieLogsService,
        @Inject(AppToasterService) public toasterSrv: AppToasterService,
    ) {
        this.autoAccept = this.facLocalStorageSrv.getItem('AutoAcceptInboundCall');

        this.userPreferences = this.storageLoadConfig('phoneUserPref', DefaultUserPref, true, false);
        this.phoneConfig = this.storageLoadConfig('phoneConfig', DefaultPhoneConfig);
        this.serverConfig = this.storageLoadConfig('phoneServerConfig', DefaultServerConfig);

        this.soundConfig = DefaultSoundConfig;

        if (this.userPreferences.selectedRing) {
            this.soundConfig.downloadSounds[0] = {ring: this.userPreferences.selectedRing};
        }

        this.securityProfileSrv.securityProfileBS.subscribe(sp => {
            if (sp?.permissions?.length) {
                this.decideAutoAccept();
            }
        });

        window.addEventListener('beforeunload', this.onBeforeUnload);
        // console.log(this.soundConfig);
    }

    public webrtc_log(...data: Array<any>) {
        this.dexieLogsSrv.info('webrtc_log', {data});
        console.log(...data);
    }

    public setStateDescription(state: string) {
        this.stateDescription = state;
    }

    public decideAutoAccept() {
        this.autoAccept = this.facLocalStorageSrv.getItem('AutoAcceptInboundCall');

        if (this.securityProfileSrv.hasPermission('AgentSoftPhoneAutoAccept', 'Enable')) {
            if (!this.securityProfileSrv.hasPermission('AgentSoftPhoneAutoAccept', 'AgentConfig')) {
                this.autoAccept = 'Yes';
            }
        } else {
            this.autoAccept = 'No';
        }

        this.facLocalStorageSrv.setItem('AutoAcceptInboundCall', this.autoAccept);

        this.dexieLogsSrv.info('decideAutoAccept', {v: this.autoAccept});
    }

    public currentRingTone() {
        const rt = this.soundConfig.downloadSounds[0]?.ring;

        this.dexieLogsSrv.info('currentRingTone', {rt});
        return rt;
    }

    public setRingtone(selectedRing: string) {
        if (selectedRing) {
            this.userPreferences.selectedRing = selectedRing;
            this.soundConfig.downloadSounds[0] = {ring: selectedRing};
            this.storageSaveConfig('phoneUserPref', this.userPreferences, true);
            // this.soundConfig.play.incomingCall.ring = selectedRing;

            this.downloadSounds();
        }
    }

    public setVolume(vol: number) {
        if (this.soundConfig.play.incomingCall?.volume) {
            this.soundConfig.play.incomingCall.volume = vol / 10;
        }
    }

    public setAgentLeg(leg: any) {
        console.log('FAC: setAgentLeg');
        this._agentLegStatus = leg.Status;
        this._agentLeg = leg.AgentLegId;

        if (this._agentLegStatus === 'Disconnected') {
            this.isConnectAgentLeg = false;
            this.onAgentLegDisconnected();
        }

        if (this._agentLegStatus === 'Dialing' && this.isConnectAgentLeg) {
            this.setupAndCallSoftPhone();
        } else if (this.autoAccept === 'Yes') {
            this.setupAndCallSoftPhone();
        }
    }

    public get showAnswerCall() {
        return this.initDone && this.customEvent && !this.activeCall;
    }

    onBeforeUnload = () => {
        this.webrtc_log('FAC: ', 'onBeforeUnload');

        let restoreData = {};
        const connectedServer = this.phone?.getServerAddress();

        if (this.activeCall?.isEstablished()) {
            restoreData = {
                callTo: this.activeCall.data[this.propertyStrings.user],
                video: this.activeCall.data[this.propertyStrings.video],
                replaces: this.activeCall.getReplacesHeader(),
                time: new Date().getTime(),
                agentLegId: this._agentLeg,
                connectedServer,
            };

            localStorage.setItem('phoneRestoreCall', JSON.stringify(restoreData));
        } else {
            localStorage.removeItem('phoneRestoreCall');
        }

        return true;
    };

    isIniting = false;
    initWebrtcCount = 0;

    initWebRtc(settings: AgentSettingsModel) {
        this.initWebrtcCount++;
        this.dexieLogsSrv.info('Init WebRTC', {
            initDone: this.initDone,
            agentSettings: settings,
            isIniting: this.isIniting,
            initWebrtcCount: this.initWebrtcCount,
        });

        if (this.isIniting) {
            setTimeout(() => {
                if (this.initWebrtcCount < 3) {
                    this.initWebRtc(settings);
                }
            }, 1000);
        }

        if (this.initDone) {
            return;
        }
        this.isIniting = true;

        this.webrtc_log('FAC: Init WebRTC');

        try {
            this.softPhoneDNIS = settings.webRTCDNIS;

            this.agentInfo = this.localStorageSrv.retrieve('agentInfo');
            if (!settings) {
                throw new Error('Agent Settings not found.');
            }
            if (!this.agentInfo) {
                throw new Error('Agent Info not found.');
            }

            this.restoreData = localStorage.getItem('phoneRestoreCall');

            this.setVolume(+this.facLocalStorageSrv.getItem('volume') || 10);

            // this.agentLeg = this.agentInfo.agentId;

            this.serverConfig.domain = settings.webRTCServerDomain;
            this.serverConfig.addresses = settings.webRTCWSSUrls;
            // this.serverConfig.iceServers =

            this.phone = new AudioCodesUA();
            this.audioPlayer = new AudioPlayer();
            this.audioPlayer.init(console.log);

            this.webrtc_log('Single call phone');
            // this.webrtc_log('Browser: ' + this.phone.getBrowserName());
            // this.webrtc_log('SIP: %s', JsSIP.C.USER_AGENT);
            // this.webrtc_log('AudioCodes API: %s', this.phone.version());
            // this.webrtc_log(`webrtc adapter ${window.adapter ? 'is' : 'is not'} used`);

            this.downloadSounds();

            const r = null;

            // if (this.restoreData) {
            //     r = JSON.parse(this.restoreData);
            //     this.delay = Math.ceil(Math.abs(r.time - new Date().getTime()) / 1000);

            //     if (this.delay > 12) {
            //         this.webrtc_log('Clearing restore data');
            //         this.restoreData = null;
            //         localStorage.removeItem('phoneRestoreCall');
            //     } else {
            //         // webRTCWSSUrls = this.getPrimaryIceServer(maxSettings.webRTCWSSUrls, r.connectedServer);
            //     }
            // }

            this.phone.setServerConfig(this.serverConfig.addresses, this.serverConfig.domain, this.serverConfig.iceServers);
            this.webrtc_log('FAC Agent Id', this.agentInfo.agentId, this.phoneConfig);
            this.phone.setAccount(this.agentInfo.agentId, this.agentInfo.firstName, '', '');

            this.phone.setAcLogger(console.log);
            this.phone.setJsSipLogger(console.log);

            this.phone.setReconnectIntervals(this.phoneConfig.reconnectIntervalMin, this.phoneConfig.reconnectIntervalMax);
            this.phone.setRegisterExpires(this.phoneConfig.registerExpires);

            this.phone.setUseSessionTimer(this.phoneConfig.useSessionTimer);
            this.phone.setBrowsersConstraints(this.phoneConfig.constraints);
            this.phone.setChromeAudioConstraints(this.phoneConfig.chromeAudioConstraints);
            this.phone.setWebSocketKeepAlive(
                this.phoneConfig.keepAlivePing,
                this.phoneConfig.keepAlivePong,
                this.phoneConfig.keepAliveStats,
                this.phoneConfig.keepAliveDist,
            );
            this.phone.setDtmfOptions(this.phoneConfig.dtmfUseWebRTC, this.phoneConfig.dtmfDuration, this.phoneConfig.dtmfInterToneGap);
            this.phone.setEnableAddVideo(this.phoneConfig.enableAddVideo);

            // Set some strings to testing. Please don't use them in production.
            this.phone.setUserAgent(WEB_RTC_USER_AGENT);
            this.phone.setRegisterExtraHeaders(['X-SBC: AudioCodes Mediant']);

            this.phone.setListeners({
                loginStateChanged: this.loginStateChanged,
                outgoingCallProgress: this.outgoingCallProgress,
                callTerminated: this.callTerminated,
                callConfirmed: this.callConfirmed,
                callShowStreams: this.callShowStreams,
                incomingCall: this.incomingCall,
                callHoldStateChanged: this.callHoldStateChanged,
                incomingNotify: this.incomingNotify,
                incomingMessage: this.incomingMessage,
                pongTimeout: this.pongTimeout,
            });

            this.phone.setModes(this.phoneConfig.modes);
            this.phone.init(true);

            this.initDone = true;
            this.isIniting = false;
        } catch (error) {
            this.webrtc_log(error);
            this.dexieLogsSrv.info('Init Exception', {initDone: this.initDone, agentSettings: settings, agentInfo: this.agentInfo});
        }
    }

    playIncomingCallAudio() {
        this.audioPlayer?.play(this.soundConfig.play.incomingCall);
    }
    stopAudioPlay() {
        this.audioPlayer?.stop();
    }

    public onAgentLegDisconnected() {
        if (!!this.activeCall) {
            this.activeCall.terminate();
        }
    }

    processWebRtcEvent(event: CustomEventModel) {
        this.customEvent = event;

        if (this.isOutgoingCall) {
            this.answerCall(null);
            this.isOutgoingCall = false;
        }

        this.dexieLogsSrv.info('processWebRtcEvent', {event, stateDescription: this.stateDescription, connectToAgentLegPromise: this.connectToAgentLegPromise});

        if (this.stateDescription === 'DialerPending' && !this.connectToAgentLegPromise) {
            this.connectToAgentLegPromise = this.dialogSrv.confirmDialog('Integrated Softphone', 'Connect to agent leg.', 'Accept', 'Reject').result;
            this.connectToAgentLegPromise
                .then(v => {
                    if (v) {
                        this.setupAndCallSoftPhone();
                    } else {
                        this.loaderSrv.start();
                        this.inContactAPIService
                            .endLeg()
                            .subscribe()
                            .add(() => {
                                this.loaderSrv.stop();
                            });
                    }
                })
                .catch(err => {
                    if (err === undefined) {
                        this.loaderSrv.start();
                        this.inContactAPIService
                            .endLeg()
                            .subscribe()
                            .add(() => {
                                this.loaderSrv.stop();
                            });
                    }
                })
                .finally(() => {
                    this.connectToAgentLegPromise = null;
                });
        }
        // if (this.callContactService.currentContacts.value.length) {
        //     this.webrtc_log(this.callContactService.currentContacts);
        // }
        // debugger;
        // this.webrtc_log(event, this.activeCall, this.phone);
        // if (event?.softPhoneDNIS && !this.activeCall && !!this.phone) {
        //     var params = ['X-InContact-AgentLegId: ' + this.agentLeg];
        //     this.activeCall = this.phone.call(false, event.softPhoneDNIS, params);
        //     this.webrtc_log('trying to initiate call >> ', this.activeCall);
        //     let extraHeaders = ['X-Greeting: You are welcome !'];
        //     setTimeout(() => {
        //         this.activeCall.answer(this.phone.AUDIO, extraHeaders);
        //     }, 3000);
        // }
    }

    setupAndCallSoftPhone() {
        this.webrtc_log('FAC: setupAndCallSoftPhone');
        let errorMessage;

        const params = ['X-InContact-AgentLegId: ' + this._agentLeg];

        if (!this.isConnecting && !this.activeCall) {
            if (this.softPhoneDNIS && this._agentLeg) {
                this.isConnecting = true;
                // this.eventLog(this.enums.LogEntry.Severity.Debug, 'Softphone setup call with softPhoneDNIS ' +
                // this.softPhoneDNIS + ' and agentLegId: ' + this.agentLegId);
                this.activeCall = this.phone.call(false, this.softPhoneDNIS, params);
                this.stopAudioPlay();
            } else {
                errorMessage = 'Softphone: Could not connect the agent leg, because the WebRTC event did not provide a softPhoneDNIS.';
                this.webrtc_log(errorMessage);
            }
        } else {
            console.error(
                'FAC: attempting to connect multiple agent legs. isConnecting: ' + this.isConnecting + ', activeCall: ' + (this.activeCall ? 'true' : 'false'),
                new Error(),
            );
        }

        this.dexieLogsSrv.info('setupAndCallSoftPhone', {
            icConnecting: this.isConnecting,
            activeCall: !!this.activeCall,
            softPhoneDNIS: this.softPhoneDNIS,
            _agentLeg: this._agentLeg,
            isLogin: this.isLogin,
        });
    }

    setOutgoingCall() {
        this.isOutgoingCall = true;
    }

    answerCall(contact: AnyObject | null) {
        this.dexieLogsSrv.info('answerCall', {
            contact: contact?.rawData,
            activeCall: !!this.activeCall,
            isConnecting: this.isConnecting,
            _agentLeg: this._agentLeg,
            isLogin: this.isLogin,
        });

        if (!this.isLogin) {
            this.toasterSrv
                .show('INFO', 'Softphone not Connected', 'Softphone is not connected, please reload the page to continue using application.', true)
                .subscribe(() => {});
        }

        if (!this.isConnecting && !this.activeCall) {
            if (!!this.softPhoneDNIS && !!this._agentLeg && !!this.phone) {
                this.webrtc_log('FAC: answerCall', this.softPhoneDNIS, this._agentLeg);
                const params = ['X-InContact-AgentLegId: ' + this._agentLeg];
                this.activeCall = this.phone.call(false, this.softPhoneDNIS, params);
                this.stopAudioPlay();
            } else {
                this.webrtc_log('FAC: ', 'Softphone: Could not connect the agent leg, because the WebRTC event did not provide a softPhoneDNIS.');
            }
        } else {
            console.error(
                'FAC: attempting to connect multiple agent legs. isConnecting: ' + this.isConnecting + ', activeCall: ' + (this.activeCall ? 'true' : 'false'),
                new Error(),
            );
        }
    }

    incomingMessage = (call, from, contentType, body, request) => {
        this.webrtc_log('FAC: incoming message', call, from, contentType, body, request);
    };

    incomingNotify = (call, eventName, from, contentType, body, request) => {
        this.webrtc_log('FAC: incomingNotify');
    };

    callHoldStateChanged = (call, isHold, isRemote) => {
        this.webrtc_log('FAC: callHoldStateChanged');
    };

    incomingCall = (call, invite, replacedCall, hasSDP) => {
        this.webrtc_log('FAC: incoming call', call);
    };

    callShowStreams = (call, localStream, remoteStream) => {
        this.webrtc_log('FAC: callShowStream');
        this.localStream = localStream;
        this.remoteStream = remoteStream;
        const audioRemoteElement = document.getElementById('audio_remote') as HTMLAudioElement;
        audioRemoteElement.srcObject = remoteStream;
        audioRemoteElement.volume = 1;
    };

    callConfirmed = (call, message, cause) => {
        this.isConnecting = false;
        // this.webrtc_log(call, message, cause);
    };

    outgoingCallProgress = (call, response) => {
        this.webrtc_log('phone>>> outgoing call progress');
        if (call === this.transferCall) {
            return;
        }
        // document.getElementById('outgoing_call_progress').innerText = 'dzzz dzzz';
        // this.audioPlayer.play(this.soundConfig.play.outgoingCallProgress);
    };

    loginStateChanged = (isLogin, cause) => {
        this.dexieLogsSrv.info('loginStateChanged', {
            isLogin,
            cause,
        });

        this.isLogin = isLogin;

        switch (cause) {
            case 'connected':
                // after this can be called 'login', but better use init(autoLogin=true)
                this.webrtc_log('phone>>> loginStateChanged: connected');
                break;
            case 'disconnected':
                this.webrtc_log('phone>>> loginStateChanged: disconnected');
                // guiError('SBC server: disconnected');
                if (this.activeCall === null) {
                    // if no active call
                    // guiShowPanel('setting_panel');
                }
                break;
            case 'login failed':
                this.webrtc_log('phone>>> loginStateChanged: login failed');
                // guiError('SBC server: login failed');
                // guiShowPanel('setting_panel');
                break;
            case 'login':
                this.webrtc_log('phone>>> loginStateChanged: login');
                // this.publishEvent(this.enums.softPhone.loginSuccess);

                this.toasterSrv
                    .show('SUCCESS', 'Softphone Connected.', 'Application Softphone has been connected. Continue using application.', true)
                    .subscribe(() => {});

                let r = null;
                this.restoreData = localStorage.getItem('phoneRestoreCall');

                if (this.activeCall?.isEstablished()) {
                    this.webrtc_log('Re-login done, exists open call (maybe SBC switch over to backup)');
                } else if (this.restoreData !== null && DefaultPhoneConfig.restoreCall) {
                    localStorage.removeItem('phoneRestoreCall');
                    r = JSON.parse(this.restoreData);
                    this.delay = Math.ceil(Math.abs(r.time - new Date().getTime()) / 1000);

                    if (this.delay > 20) {
                        this.webrtc_log('Cannot restore call, delay is too big');
                    } else {
                        const params = ['X-InContact-AgentLegId: ' + r.agentLegId, 'Replaces: ' + r.replaces];

                        this.webrtc_log('Try restore call...' + 'callTo: ' + r.callTo + ' video: ' + r.video + ' replaces: ' + r.replaces);
                        this.activeCall = this.phone.call(false, r.callTo, params);
                    }
                }

                break;
            case 'logout':
                this.webrtc_log('phone>>> loginStateChanged: logout');
                // guiInfo('SBC server: logout');
                if (this.activeCall === null || !this.activeCall?.isEstablished()) {
                    // guiShowPanel('setting_panel');
                }
                break;
        }
    };

    public pongTimeout = (e: any) => {
        this.webrtc_log('pong timeout', e);
        this.toasterSrv
            .show(
                'INFO',
                'Softphone Disconnected.',
                'Application Softphone connection has been disconnected. Please reload the page to continue using application.',
                true,
            )
            .subscribe(() => {});
    };

    public logout() {
        this.phone?.logout();
    }

    callTerminated = (call, message, cause, redirectTo) => {
        // this.webrtc_log('phone>>> call terminated callback, cause=%o', cause);
        this.webrtc_log('phone>>> call terminated callback, cause=' + cause);

        this.activeCall = null;
        this.isConnecting = false;
        this.localStream = null;
        this.remoteStream = null;
        this.isConnectAgentLeg = false;

        if (cause === 'Redirected') {
            // video = call.data[this.propertyStings.video];
            this.webrtc_log('Redirect call to %s video=%o', redirectTo);
        }
    };

    downloadSounds() {
        this.audioPlayer.downloadSounds('/assets/webrtc/sounds/', this.soundConfig.downloadSounds);
    }

    storageLoadConfig(name, defValue = null, useLog = true, storeBack = false) {
        const str_value = localStorage.getItem(name);
        let value = null;
        let isLoaded = false;
        let isReplaced = false;
        let isDefault;
        if (str_value) {
            isLoaded = true;
            value = JSON.parse(str_value);
        }
        if (value === null || (defValue !== null && value.version !== defValue.version)) {
            if (isLoaded) {
                isReplaced = true;
            }
            isLoaded = false;
            isDefault = true;
            if (defValue !== null) {
                value = Object.assign({}, defValue);
            }
        } else {
            isDefault = this.dataEquals(value, defValue);
        }
        if (useLog) {
            this.webrtc_log('Used %s %s', value !== null ? (isDefault ? 'default' : 'custom') : 'null', name);
        }

        if (value !== null && (isReplaced || (storeBack && !isLoaded))) {
            localStorage.setItem(name, JSON.stringify(value));
        }
        return value;
    }

    storageSaveConfig(name, value, defValue = null) {
        if (defValue === null || !this.dataEquals(value, defValue)) {
            if (defValue !== null && defValue.version && !value.version) {
                value.version = defValue.version;
            }
            localStorage.setItem(name, JSON.stringify(value));
        } else {
            localStorage.removeItem(name);
        }
    }

    dataEquals(obj1, obj2) {
        if (obj1 === null || obj2 === null) {
            return obj1 === obj2;
        }
        for (const p in obj1) {
            if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
                return false;
            }
            switch (typeof obj1[p]) {
                case 'object':
                    if (!this.dataEquals(obj1[p], obj2[p])) {
                        return false;
                    }
                    break;
                case 'function': // No compare functions.
                    break;
                default:
                    if (obj1[p] != obj2[p]) {
                        return false;
                    }
            }
        }
        for (const p in obj2) {
            if (typeof obj1[p] == 'undefined') {
                return false;
            }
        }
        return true;
    }

    destroy() {
        this.activeCall = null;
        this._agentLeg = null;
        this._agentLegStatus = null;
        this.activeCall = null;
        this.initDone = null;
        this.isConnecting = null;
        this.localStream = null;
        this.phone = null;
        this.propertyStrings = null;
        this.remoteStream = null;
        this.softPhoneDNIS = null;
    }
}
