import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
//import { HttpModule } from '@angular/http';

import {AboutComponent} from './about.component';
import {AboutRoutingModule} from './about.routing';

@NgModule({
    declarations: [AboutComponent, AboutComponent],
    imports: [BrowserModule, FormsModule, AboutRoutingModule],
    providers: [],
})
export class AboutModule {}
