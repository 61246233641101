<div *ngIf="false" class="page-heading">
    <ul class="nav nav-pills">
        <li>
            <h4><i class="fa fa-fax fa-lg" aria-hidden="true" title="Dialpad"> </i>&nbsp;Dialpad</h4>
        </li>
    </ul>
</div>

<div class="key-wrapper">
    <div class="form-group form-group-number">
        <div class="input-group">
            <input
                #dialnumber
                type="tel"
                accesskey="P"
                style="border: 0px; font-weight: 999; text-align: center"
                class="form-control"
                id="dial-number"
                autofocus
                [(ngModel)]="dialedNumber"
            />
            <span *ngIf="false" class="input-group-addon"
                ><a (click)="onBackspace()"><i class="fa fa-angle-double-left" aria-hidden="true"></i></a
            ></span>
        </div>
    </div>
    <button role="button" class="btn btn-circle btn-lg" rel="1" (click)="dialDigit('1')">1</button>
    <button role="button" class="btn btn-circle btn-lg" rel="2" (click)="dialDigit('2')">
        2
        <!-- <span>abc</span> -->
    </button>
    <button role="button" class="btn btn-circle btn-lg" rel="3" (click)="dialDigit('3')">
        3
        <!-- <span>def</span> -->
    </button>
    <!-- <div class="clear"></div> -->
    <button role="button" class="btn btn-circle btn-lg" rel="4" (click)="dialDigit('4')">
        4
        <!-- <span>ghi</span> -->
    </button>
    <button role="button" class="btn btn-circle btn-lg" rel="5" (click)="dialDigit('5')">
        5
        <!-- <span>jkl</span> -->
    </button>
    <button role="button" class="btn btn-circle btn-lg" rel="6" (click)="dialDigit('6')">
        6
        <!-- <span>mno</span> -->
    </button>
    <!-- <div class="clear"></div> -->
    <button role="button" class="btn btn-circle btn-lg" rel="7" (click)="dialDigit('7')">
        7
        <!-- <span>pqrs</span> -->
    </button>
    <button role="button" class="btn btn-circle btn-lg" rel="8" (click)="dialDigit('8')">
        8
        <!-- <span>tuv</span> -->
    </button>
    <button role="button" class="btn btn-circle btn-lg" rel="9" (click)="dialDigit('9')">
        9
        <!-- <span>wxyz</span> -->
    </button>
    <!-- <div class="clear"></div> -->
    <button role="button" class="btn btn-circle btn-lg special" rel="*" (click)="dialDigit('*')">*</button>
    <button role="button" class="btn btn-circle btn-lg" rel="1" (click)="dialDigit('0')">
        0
        <!-- <span>+</span> -->
    </button>
    <button role="button" class="btn btn-circle btn-lg special" rel="#" (click)="dialDigit('#')">#</button>
    <!-- <div class="clear"></div> -->
    <!-- <div class="key nb"></div> -->
    <!--<div class="key phone" rel="Make Call" (click)="dialNumber()"><i class="fa fa-phone"></i></div>-->
    <div></div>
    <div role="button" class="">
        <!-- <div class="dropup" dropdown [isOpen]="isopen"> -->
        <div class="dropup" dropdown>
            <label for="outbound-list" accesskey="A" style="display: none"></label>
            <button
                #skillsDropdownActivator
                id="single-button"
                title="dial number"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                aria-labelledby="skill"
                aria-controls="outbound-list"
                class="btn btn-circle btn-lg btn-success"
            >
                <i class="fa fa-phone"></i>
            </button>
            <ul
                aria-labelledby="skill"
                #outboundSkills
                id="outbound-list"
                dropdownMenu
                keyboardNav="true"
                class="dropdown-menu scrollable-menu btn-block scrollable-menu-uac"
            >
                <li role="menuitem" *ngFor="let skill of agent.outboundSkills" style="color: #444; line-height: 20px">
                    <a
                        style="color: #444; line-height: 20px"
                        aria-labelledby="skill"
                        role="link"
                        href="javascript:void(0)"
                        class="scrollable-menu-uac-items"
                        (click)="callContactService.dialPhone(dialedNumber, skill.skillId)"
                        >{{ skill.skillName }}</a
                    >
                    <span id="skill" class="sr-only">{{ skill.skillName }}</span>
                </li>

                <li role="menuitem" *ngIf="agent.outboundSkills.length == 0" style="color: #444; line-height: 20px">
                    <span
                        style="color: #444; line-height: 20px"
                        aria-labelledby="skill"
                        role="link"
                        href="javascript:void(0)"
                        class="scrollable-menu-uac-items alert"
                        >You do not have an outbound skill assigned to you. Please contact your administrator.</span
                    >
                </li>
            </ul>
        </div>
    </div>
    <div>
        <a (click)="onBackspace()" style="color: #333"><i class="fa fa-backspace fa-2x" aria-hidden="true"></i></a>
    </div>

    <!-- <div class="key nb"></div>
        <div class="clear"></div> -->
</div>
