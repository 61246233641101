import {Inject, Injectable} from '@angular/core';
import {Subject, BehaviorSubject, Observable} from 'rxjs';

import {Router} from '@angular/router';

import {Agent} from '../../../../core/_models/agent.model';
import {AgentStateModel} from '../../../../core/_models/agent-state.model';
import {IndicatorModel} from '../../../../core/InContact/models/indicators.model';
import {Events, NextStates} from '../../../../core/InContact/models/events.model';

// import {LocalStorageService, SessionStorageService} from 'ng2-webstorage';
import {InContactAPIService} from '../../../../core/InContact/incontact-api.service';
import {AuthenticationContextService} from '../../../../core/InContact/_services/authentication-context.service';
import {AgentSkillAssignmentsModel} from '../../../../core/InContact/models/agent-skill-assignments.model';
import {SkillsModel} from '../../../../core/InContact/models/skills.model';
import {SessionInfo as SessionInfoModel} from '../../../../core/InContact/models/session-info.model';
import {UnavailableCodes} from '../../../../core/InContact/models/unavailable-codes.model';
import {MessageService} from '../../../../core/_services/message.service';
import {ErrorService} from '../../../../core/_services/error.service';
import {FccCommonService} from '../../../../core/_services/fcc-common.service';
// import {DialogService} from './dialog.service';
import {IndicatorsService} from '../../../../core/_services/indicators.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
import {IcAuthService} from 'src/app/sdk/incontact/services';
import {IcAgentsApi} from 'src/app/sdk/incontact/api/agents/ic-agents.api';
import {IcAgentSettingsService} from 'src/app/sdk/incontact/services/agents/ic-agent-settings.service';
import {AgentSettingsModel} from 'src/app/sdk/incontact/models';
import {WebRTCService} from '../../../../core/InContact/web-rtc/web-rtc.service';
import {IcAgentSessionsApi} from '../../api/agent-sessions/ic-agent-sessions.api';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';
import {SoftPhoneService} from 'src/app/sdk/counter-path/services/softphone/softphone.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Utility} from 'src/app/shared/utility.service';
import {ErrorModel} from 'src/app/core/_models/error.model';
import {IcSecurityProfileService} from '../incontact/ic-security-profile.service';
import {FacLocalStorageService} from 'src/app/sdk/storage';
import {ScreenAgentService} from '../screen-agent/screen-agent.service';
import {IcOpenIdApi} from '../../api/openid/ic-openid.api';
import {map} from 'rxjs/operators';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class IcAgentSessionService {
    agent: Agent;

    // Observable string sources
    private allUnavailableStates: UnavailableCodes[];

    //Properties that subscribers needs for updated values;
    public serverTimeOffset = new BehaviorSubject<number>(0);
    public serverTime = new BehaviorSubject<Date>(null);
    public agentId = new BehaviorSubject<number>(0);
    public teamId = new BehaviorSubject<string>('');
    public agentSkillAssignments: BehaviorSubject<AgentSkillAssignmentsModel[]>;
    public agentOutboundSkills: BehaviorSubject<AgentSkillAssignmentsModel[]>;
    public agentStates: BehaviorSubject<AgentStateModel[]>;
    public agentIndicators: BehaviorSubject<IndicatorModel[]>;
    public sessionStatus = new BehaviorSubject<string>('');
    public legStatusLabel$ = new BehaviorSubject<string>('');
    public nextAgentStatus = new BehaviorSubject<NextStates>(new NextStates('', ''));
    public nextNextAgentStatus = new BehaviorSubject<NextStates>(new NextStates('', ''));
    public startTimeElapsed = new BehaviorSubject<string>('');
    public agentStatesArray: AgentStateModel[];
    private agentSkills: AgentSkillAssignmentsModel[];
    public isSessionJoined = new BehaviorSubject<boolean>(false);
    public isSessionStarted = new BehaviorSubject<boolean>(false);
    // public isAuthenticated = new BehaviorSubject<boolean>(false);
    public personalConnectionEnabled = new BehaviorSubject<boolean>(false);
    public dialerCampaign = new BehaviorSubject<string>('');
    public totalActiveContacts = new BehaviorSubject<number>(0);
    public elapsedTimeId: any;
    public acwTimerId: any;
    public applicationType: string;
    public businessUnit: BehaviorSubject<any> = new BehaviorSubject<any>({});

    public sessionState: Subject<string> = new Subject();

    private screenRecordingEnabled = false;

    //    private messageService = new MessageService();
    updateServerTimeOffsetId: any;

    private result: any;

    constructor(
        @Inject(SessionStorageService) public sessionStorageService: SessionStorageService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(FacLocalStorageService) private localStorageSettingsSrv: FacLocalStorageService,
        private inContactAPIService: InContactAPIService,
        public authenticationContextService: AuthenticationContextService,
        @Inject(ErrorService) private errorService: ErrorService,
        private fccCommonService: FccCommonService,
        private dialogService: DialogService,
        private indicatorsService: IndicatorsService,
        private router: Router,
        public messageService: MessageService,
        public icAuthSrv: IcAuthService,
        public icAgentApi: IcAgentsApi,
        public icAgentSettingsSrv: IcAgentSettingsService,
        @Inject(IcAgentSessionsApi) public icAgentSessionApi: IcAgentSessionsApi,
        @Inject(SoftPhoneService) public softPhoneSrv: SoftPhoneService,
        private loaderSrv: NgxUiLoaderService,
        @Inject(IcSecurityProfileService) public securityProfileSrv: IcSecurityProfileService,
        @Inject(ScreenAgentService) public screenAgentSrv: ScreenAgentService,
        @Inject(WebRTCService) private webRtcSrv: WebRTCService,
        private icOpenIdApi: IcOpenIdApi,
    ) {
        this.agent = this.loadAgent();
        this.agent.currentAgentState = 'Available';

        this.agentStatesArray = [];
        this.agentStates = new BehaviorSubject(this.agentStatesArray);

        this.agentSkills = [];
        this.agentSkillAssignments = new BehaviorSubject(this.agentSkills);
        this.agentOutboundSkills = new BehaviorSubject(this.agentSkills);
        this.agentIndicators = new BehaviorSubject([]);

        this.sessionState.subscribe(state => {
            if (state === 'joined' || state === 'started') {
                const jwtPayload = this.localStorageSettingsSrv.getJwtPayload();
                if (!!jwtPayload) {
                    this.securityProfileSrv.LoadSecurityProfile(jwtPayload.role?.id ?? jwtPayload.icSPId);
                }

                if (this.isWebRTCSession) {
                    navigator.mediaDevices
                        .getUserMedia({video: false, audio: true})
                        .then(stream => {
                            // window.localStream = stream;
                            // window.localAudio.srcObject = stream;
                            // window.localAudio.autoplay = true;
                        })
                        .catch(err => {
                            console.error(`you got an error: ${err}`);
                        });
                }
            }
        });
    }

    isUserAuthenticated() {
        const authContext = this.getAuthContext();

        if (!authContext?.expires_at) {
            this.clear();
            return false;
        }

        const diff = moment(new Date(authContext.expires_at * 1000)).diff(new Date());

        if (!!authContext?.access_token && diff > 0) {
            this.icAuthSrv.authenticationContext = authContext;
            return true;
        }
        return false;
    }

    onAuthenticated() {
        this.icAuthSrv.scheduleRefreshToken();
        // this.updateisAuthenticated(true);
        this.updateIsSessionStarted(false);
        this.agent = this.loadAgent();

        this.loaderSrv.start();
        const phoneOrStation = Utility.cleanPhoneNumber(this.agent.sessionValue);

        return new Promise((resolve, reject) => {
            this.startSession(this.agent.sessionType, phoneOrStation)
                .then(res => {
                    this.errorService.clearError();
                    this.updateIsSessionStarted(true);
                    this.saveAgentToLocalStorage(this.agent);
                    this.reload();
                    this.sessionState.next('started');
                    resolve(res);
                })
                .catch(error => {
                    this.ProcessError(error);
                    reject(error);
                })
                .finally(() => {
                    this.loaderSrv.stop();
                });
        });
    }

    onSessionJoined() {
        this.updateIsSessionStarted(true);
        this.updateIsSessionJoined(true); //also update the session storage
        this.saveAgentToLocalStorage(this.agent);
        this.reload();
        this.sessionState.next('joined');
    }

    sessionError(error) {
        if (error.error?.error_description === 'InvalidAgentSession' || error.error?.error_description === 'InvalidPhoneNumberOrStation') {
            this.clear();
            this.clearLocalStorage();
            if (error.error?.error_description === 'InvalidPhoneNumberOrStation') {
                this.router.navigate(['/authentication/login-step-two']);
            } else {
                this.router.navigate(['/authentication/login-step-one']);
            }
        }
    }

    ProcessError(error: any) {
        const errorModel = new ErrorModel(error.status, error.statusText);
        if (errorModel.getErrorCode() == '409') {
            // && errorModel.getErrorDescription().includes('Agent session already started')){
            if (error.error) {
                const body = error.error;
                const msg = error.headers.get('icstatusdescription');
                if (body.error_description && body.error_description.toLowerCase() == 'SessionInProgress'.toLowerCase()) {
                    this.joinSession(this.agent.sessionType)
                        .then(res => {
                            this.onSessionJoined();
                        })
                        .catch(error1 => {
                            this.errorService.onSystemError(error1);
                        });
                } else if (msg && msg.match(/password/g) != null && msg.match(/change/g) != null) {
                    //alert("show Password change dialog");
                    //var body = JSON.parse(error._body);

                    // this.showPasswordChangeDialog = true;
                    // this.passwordResetAccessToekn = body.access_token;
                    // this.passwordResetAgentId = body.agent_id;
                    // this.passwordResetServerUri = body.resource_server_base_uri;
                    this.errorService.onSystemError(error);
                } else {
                    this.errorService.onSystemError(error);
                }
            } else {
                this.errorService.onSystemError(error);
            }
        } else {
            //  this.ErrorMsg = this.error.getErrorDescription();
            //  this.messageService.updateErrorMsg(this.ErrorMsg);
            this.errorService.onSystemError(error);
        }
    }

    startSession(sessionType: string, sessionValue: string) {
        return new Promise((resolve, reject) => {
            this.loaderSrv.start();
            this.icAgentSessionApi.startSession(sessionType, sessionValue).subscribe(
                res => {
                    this.loaderSrv.stop();
                    this.inContactAPIService.updateSessionId(res.sessionId, true);
                    if (sessionType === 'SoftPhone') {
                        this.softPhoneSrv.initialize();
                    }
                    resolve(res);
                },
                error => {
                    this.sessionError(error);
                    this.loaderSrv.stop();
                    reject(error);
                },
            );
        });
    }

    joinSession(sessionType: string) {
        return new Promise((resolve, reject) => {
            this.loaderSrv.start();
            this.icAgentSessionApi.joinSession().subscribe(
                res => {
                    this.loaderSrv.stop();
                    if (sessionType === 'SoftPhone') {
                        this.softPhoneSrv.initialize();
                    }
                    // res => result = res;
                    this.inContactAPIService.onSessionJoined(res);
                    resolve(res);
                },
                error => {
                    this.sessionError(error);
                    this.loaderSrv.stop();
                    //return Observable.of(error);
                    reject(error);
                    //return Observable.of(error);
                },
            );
        });

        // return this._PasswrodAuthorizer.sendHttpPost(relativeUri,parameters)
        // .map(this.onSessionJoined, this.onSessionError);
    }

    //Functions that updates the Observable properties for updating there values
    updateAgentState(states: AgentStateModel[]) {
        this.agentStates.next(states);
    }

    updateSessionStatus(status: string) {
        this.sessionStatus.next(status);
    }

    updateTotalActiveContact(current: number) {
        const count = this.totalActiveContacts.getValue();
        this.totalActiveContacts.next(count + current);
    }

    updateLegStatusLabel(status: string) {
        if (status === '') {
            status = 'Disconnected';
        }
        this.legStatusLabel$.next(status);
    }

    updateAgentID(agentID: number) {
        this.agentId.next(agentID);
    }

    updateTeamID(teamID: string) {
        this.teamId.next(teamID);
    }

    updateServerTimeOffset(serverTime: number) {
        this.serverTimeOffset.next(serverTime);
    }

    updateServerTime(serverTime: Date) {
        this.serverTime.next(serverTime);
    }

    updateAgentSkillAssignments(agentSkills: AgentSkillAssignmentsModel[]) {
        this.agentSkillAssignments.next(agentSkills);
    }

    updateAgentOutboundSkills(agentSkills: AgentSkillAssignmentsModel[]) {
        this.agentOutboundSkills.next(agentSkills);
    }

    updateAgentIndicators(indicators: IndicatorModel[]) {
        this.agentIndicators.next(indicators);
    }

    updateNextAgentStatus(status: NextStates) {
        if (status.OutReason !== '') {
            status.State = status.OutReason;
        }

        this.nextAgentStatus.next(status);
    }

    updateNextNextAgentState(status: NextStates | any) {
        if (status.OutReason !== '') {
            status.State = status.OutReason;
        }
        this.nextNextAgentStatus.next(status);
    }

    loadAgent(): Agent {
        //let agentData = this.localStorageService.retrieve("currentAgent");
        //let agent = new Agent(agentData);
        //console.log(agent);
        let agent = this.localStorageService.retrieve('currentAgent') as Agent;
        if (agent) {
            agent.info = this.localStorageService.retrieve('agentInfo');
            agent.settings = this.localStorageService.retrieve('agentsettings') as AgentSettingsModel;
        } else {
            agent = new Agent({
                sessionType: 'station',
            });
        }
        return agent;
    }

    getAuthContext() {
        return this.authenticationContextService.getAuthContext();
    }

    saveAgentToLocalStorage(agent: Agent) {
        this.localStorageService.store('currentAgent', agent);
        this.localStorageService.store('username', agent.userName);
        this.localStorageService.store('password', agent.password);
    }

    clearLocalStorage() {
        this.sessionStorageService.clear('businessunitinfo');
        this.sessionStorageService.clear('firstSessionId');
        this.sessionStorageService.clear('sessionId');
        this.saveSessionInfo(null);
        this.inContactAPIService.clearAuthContext();
    }

    logoutDialer() {
        return this.inContactAPIService.logoutPCSkill('');
    }

    endSession(forceLogout, endContacts, ignorePersonalQueue): Observable<any> {
        if (this.screenRecordingEnabled) {
            this.screenAgentSrv
                .disconnectToScreenAgent(this.getSessionInfo().AgentUUId)
                .then(res => {
                    console.log('disconnected screen agent: ', res);
                })
                .catch(err => {
                    console.log('Error diconnecting scren agent: ', err);
                });
        }
        return this.inContactAPIService.endSession(forceLogout, endContacts, ignorePersonalQueue).pipe(
            map(x => {
                if (InContactApiSettings.isSSOLogin) {
                    this.icOpenIdApi.logoutOpenId();
                }
                return x;
            }),
        );
    }

    onSessionEnd(event: Events) {
        this.clear();
        this.icAuthSrv.clearScheduleRefreshTimer();
        setTimeout(() => {
            console.log('redirecting to login from agent session event service');
            this.router.navigate(['/authentication/login-step-one']);
        }, 100);
        this.sessionState.next('ended');
    }

    reload(): boolean {
        if (!this.inContactAPIService.tryReloadState()) {
            return false;
        } else {
            if (this.sessionStorageService.retrieve('isSessionJoined') === true) {
                this.updateIsSessionJoined(true);
                // this.sessionStorageService.store('isSessionJoined', false);
            }

            this.doReload('showSessionStarted');
            // this.updateLegStatusLabel(this.sessionStorageService.retrieve('agentLegStatus'));
        }
    }

    saveSessionInfo(sessionInfo: SessionInfoModel) {
        this.sessionStorageService.store('sessionInfo', sessionInfo);
    }

    getSessionInfo(): SessionInfoModel {
        return this.sessionStorageService.retrieve('sessionInfo');
    }

    doReload(state: string) {
        let hideLoginScreen = true;
        switch (state) {
            case 'attemptingToConnect':
                break;

            case 'showSessionStarted':
                break;

            case 'showLoginScreen':
                hideLoginScreen = false;
                break;
        }

        //this.updateIsSessionStarted(hideLoginScreen);
    }

    updateIsSessionStarted(status: boolean) {
        this.isSessionStarted.next(status);
    }

    updateIsSessionJoined(status: boolean) {
        this.sessionStorageService.store('isSessionJoined', status);
        this.isSessionJoined.next(status);
    }

    // updateisAuthenticated(status: boolean) {
    //     this.isAuthenticated.next(status);
    // }

    //Main event processing
    processStartSession(event: Events) {
        // if (this.isSessionStarted.value) {
        //     return;
        // }

        this.updateAgentID(event.AgentId);
        const sessionInfo = new SessionInfoModel(
            event.AgentId,
            event.StationId,
            event.StationPhoneNumber,
            event.StationCallerId,
            event.SessionId,
            event.AgentUUId,
        );

        this.sessionStorageService.store('agentId', event.AgentId);
        this.saveSessionInfo(sessionInfo);

        this.updateSessionStatus('Connected');

        /**
         * TODO: If the business unit has productId 105 enabled, then we need to use ScreenAgent
         * should be available on this.businessUnit.features
         */
        this.loadBusinessUnit()
            .then((res: Array<any>) => {
                if (res?.length) {
                    for (const bu of res) {
                        this.screenRecordingEnabled = !!bu?.features?.find(x => x.productId === 105 && x.isEnabled === true);
                    }
                }
                if (this.screenRecordingEnabled) {
                    if (sessionInfo.AgentUUId) {
                        this.screenAgentSrv
                            .connectToScreenAgent(sessionInfo.AgentUUId)
                            .then((val: any) => {
                                // console.log('screen agent response: ', val);
                            })
                            .catch(err => {
                                console.log('screen agent error: ', err);
                            });
                    }
                }
            })
            .catch(err => {
                // console.log('business unit error: ', err);
            });

        this.loadAgentInfo(event.AgentId);

        /**
         * Connect to softfone
         */

        // this.updateLegStatusLabel('Disconnected');

        this.getAgentSkillAssignment(event.AgentId);
        this.dialerCampaign.next(event.DialerCampaign);
        this.getServerTimeOffSetInterval(); //Start offsetTimmer
        this.loadIndicators(event.AgentId);
    }

    processUpdateSkills(event: Events) {
        let agentId;

        if (event.AgentId !== undefined) {
            agentId = event.AgentId;
        } else {
            agentId = this.getSessionInfo().AgentId;
        }

        const skillUpdateTimeout = setTimeout(() => {
            this.getAgentSkillAssignment(agentId);
            clearTimeout(skillUpdateTimeout);
        }, 8000);
    }

    processUpdateIndicators(event: Events) {
        //console.log(event);
        let agentId;

        if (event.AgentId !== undefined) {
            agentId = event.AgentId;
        } else {
            agentId = this.getSessionInfo().AgentId;
        }

        this.loadIndicators(agentId);
    }

    //InContact API method calls to fetch Agent Session and Event related data

    getServerTimeOffSetInterval() {
        this.getServerTime();
        this.updateServerTimeOffsetId = setInterval(() => {
            this.getServerTime();
        }, 360000);
    }

    getServerTime() {
        this.inContactAPIService.getServerTime().subscribe(
            res => {
                const offSet = new Date(res).getTime() - new Date().getTime();
                this.updateServerTime(new Date(res));
                this.updateServerTimeOffset(offSet);
            },
            err => {
                if (err.status === 0) {
                    setTimeout(() => err, 5000);
                } else if (err.status === 401) {
                    this.reAuthenticateUser();
                } else {
                    return err;
                }
            },
        );
    }

    reAuthenticateUser() {
        if (this.authenticationContextService.tryReloadState() !== null) {
            const agent = this.loadAgent();
            this.icAuthSrv.authenticateUser(agent.userName, agent.password).subscribe(res => {
                this.startSession(agent.sessionType, agent.sessionValue)
                    .then(sessionStart => {
                        //this.inContactAPIService.scheduleRefreshToken();
                    })
                    .catch(err => {
                        if (err.status === 409) {
                            this.joinSession(agent.sessionType)
                                .then(join => {
                                    //this.inContactAPIService.scheduleRefreshToken();
                                })
                                .catch(sessionErr => {
                                    this.clear();
                                });
                        } else {
                            this.clear();
                        }
                    });
            });
        }
    }

    public get isWebRTCSession() {
        return this.agent?.sessionType === 'WebRTC';
    }

    loadAgentInfo(agentId: number) {
        this.loadDefaultStates();

        if (this.isUserAuthenticated() && `${this.agent?.info?.agentId}` === `${agentId}` && !!this.agent?.info && !!this.agent?.settings) {
            if (this.isWebRTCSession) {
                this.webRtcSrv.initWebRtc(this.agent.settings);
            }
        }

        this.inContactAPIService
            .getAgentInfo(agentId)
            .subscribe(
                res => {
                    //this.teamId = res.teamId;
                    // this.localStorageService.store('agentInfo', res);
                    this.updateTeamID(res.teamId);
                    this.loadUnavailableCodes(res.teamId);
                },
                error => error,
            )
            .add(() => {
                this.icAgentSettingsSrv.loadAgentSettings(agentId).then(settings => {
                    if (this.isWebRTCSession) {
                        this.webRtcSrv.initWebRtc(settings);
                    }
                });
            });
        // this.icAgentApi.getAgentSettings(agentId).subscribe(settings => {
        //     // this.localStorageService.store('agentSettings', settings);
        // });
    }

    loadBusinessUnit() {
        return new Promise((resolve, reject) => {
            this.inContactAPIService.getBusinessUnit().subscribe(
                res => {
                    //this.teamId = res.teamId;
                    if (res.length > 0) {
                        this.businessUnit.next(res[0]);
                        this.localStorageService.store('businessUnitInfo', res[0]);
                    }
                    resolve(res);
                    //this.updateTeamID(res.teamId);
                    //this.loadUnavailableCodes(res.teamId);
                },
                error => {
                    console.log(error);
                    reject(error);
                },
            );
        });
    }

    getAgentSkillAssignment(agentId: number) {
        let outboundSkills: AgentSkillAssignmentsModel[];
        this.agentSkills = [];
        outboundSkills = [];

        this.inContactAPIService.requestAgentSkillAssignments(agentId).subscribe(skillAssgin => {
            if (skillAssgin === undefined) {
                skillAssgin = [];
            }
            this.updateAgentSkillAssignments(skillAssgin);
            if (skillAssgin.filter(element => element.isNaturalCalling === 'True' && element.isNaturalCallingRunning === 'True').length > 0) {
                this.personalConnectionEnabled.next(true);
            } else {
                this.personalConnectionEnabled.next(false);
            }

            outboundSkills = skillAssgin.filter(element => element.isOutbound === 'True' && element.isActive);
            this.updateAgentOutboundSkills(outboundSkills);
        });
    }

    loadDefaultStates() {
        this.agentStatesArray = [];
        this.agentStatesArray.push(new AgentStateModel('Available', '', 'Available', false));
        // only load default Unavailable if no other codes assigned
        if (this.allUnavailableStates === undefined || this.allUnavailableStates.length == 0) {
            this.agentStatesArray.push(new AgentStateModel('Unavailable', '', 'Unavailable', false));
        }
        this.updateAgentState(this.agentStatesArray);
    }

    loadUnavailableCodes(teamId: string) {
        this.allUnavailableStates = [];

        this.inContactAPIService.getUnavailableCodes(teamId).subscribe(
            codes => {
                this.allUnavailableStates = codes;

                this.loadUnavailableStates(false);
            },
            error => error,
        );
    }

    loadUnavailableStates(acw: boolean) {
        if (this.allUnavailableStates === undefined || this.allUnavailableStates.length == 0) {
            return;
        }

        if (!acw) {
            this.loadDefaultStates();
        }

        this.allUnavailableStates.forEach(element => {
            if (element.IsActive === true) {
                if (element.IsAcw === acw) {
                    this.agentStatesArray.push(new AgentStateModel('Unavailable', element.OutStateDescription.trim(), element.OutStateDescription, acw));
                }
            }
        });

        this.updateAgentState(this.agentStatesArray);

        this.getVisibleAgentStates(this.agentStatesArray);

        //Update the selected Agent state with the current agent state
        this.agentStatus.subscribe(status => this.updateSelectedAgentStates(status));
    }

    loadIndicators(agentId: number) {
        let indicators: IndicatorModel[];

        this.inContactAPIService.getIndicators(agentId).subscribe(indicatorsData => {
            indicators = indicatorsData.filter(element => element.enable === true);
            this.updateAgentIndicators(indicators);
        });
    }

    getVisibleAgentStates(allStates: AgentStateModel[]): AgentStateModel[] {
        const visibleStates: AgentStateModel[] = [];
        const agentStatus: string = this.agentStatus.getValue();
        const nextAgentStatus: NextStates = this.nextAgentStatus.getValue();
        const nextNextAgentStatus: NextStates = this.nextNextAgentStatus.getValue();

        if (allStates !== undefined) {
            allStates.forEach(element => {
                if (
                    element.agentStateDescription !==
                    agentStatus /*&& element.agentStateDescription !== nextAgentStatus.State && element.agentStateDescription !== nextNextAgentStatus.State*/
                ) {
                    visibleStates.push(element);
                }
            });
        }
        return visibleStates;
    }

    cleanNextStates() {
        this.updateNextAgentStatus(new NextStates('', ''));
        this.updateNextNextAgentState(new NextStates('', ''));
    }

    public agentStatus = new BehaviorSubject<string>('');
    public startDate = new BehaviorSubject<Date>(new Date());
    public currentAgentState = new BehaviorSubject<string>('');
    public selectedAgentState = new BehaviorSubject<string>('');
    public isStateCounterVisible = new BehaviorSubject<boolean>(false);
    public acwtimeout = new BehaviorSubject<string>('00:00');

    ProcessChangeState(event: Events) {
        // this.dialogService.closeDialog.next(true);
        this.stopStateTimer();
        this.startStateTimer();
        this.stopAcwTimerId();

        this.sessionStorageService.store('currentState', event.CurrentState);

        this.setAgentState(event.CurrentState, event.CurrentOutReason, event.StartTimeUTC);

        if (event.IsAcw === 'True') {
            this.updateIsStateCounterVisible(false);
            if (event.AcwTimeout > 0) {
                this.startAcwTimeout(event.AcwTimeout);
            } else {
                this.updateIsStateCounterVisible(true);
                this.updateStartAcwTimeout('');
            }
        } else {
            this.startTimeElapsed.next('00:00');
            this.updateIsStateCounterVisible(true);
        }

        this.cleanNextStates();
        if (event.NextStates.length >= 1) {
            if (event.NextStates[0].State !== this.selectedAgentState.getValue() && event.NextStates[0].OutReason !== this.selectedAgentState.getValue()) {
                this.updateNextAgentStatus(new NextStates(event.NextStates[0].State, event.NextStates[0].OutReason, event.NextStates[0].State));
            }
        }
        if (event.NextStates.length >= 2) {
            if (event.NextStates[1].State !== this.selectedAgentState.getValue() && event.NextStates[1].OutReason !== this.selectedAgentState.getValue()) {
                this.updateNextNextAgentState(new NextStates(event.NextStates[1].State, event.NextStates[1].OutReason, event.NextStates[1].State));
            }
        }

        this.loadUnavailableStates(false);
        this.agentStates.next(this.agentStatesArray);
    }

    startAcwTimeout(timeout: number) {
        let time = this.fccCommonService.getFormattedTime(timeout * 1000);
        this.acwtimeout.next(time);

        const intervalTimeout = 1000;
        this.acwTimerId = setInterval(() => {
            if (timeout > 0) {
                timeout = timeout - 1;
                time = this.fccCommonService.getFormattedTime(timeout * 1000);
                this.acwtimeout.next(time);
            } else {
                this.acwtimeout.next('');
            }
        }, intervalTimeout);
    }

    startStateTimer() {
        this.startDate.next(new Date());
        this.elapsedTimeId = setInterval(() => {
            this.updateElapsedTime();
        }, 1000);
    }

    stopStateTimer() {
        clearInterval(this.elapsedTimeId);
    }

    stopAcwTimerId() {
        clearInterval(this.acwTimerId);
    }

    clear() {
        this.updateIsSessionStarted(false);
        clearInterval(this.acwTimerId);
        clearInterval(this.updateServerTimeOffsetId);
        this.stopStateTimer();
        this.localStorageService.clear('autoReconnect');
    }

    updateElapsedTime() {
        if (this.startDate.getValue() === undefined) {
            return;
        }
        const currentDate = new Date(new Date().valueOf() + this.serverTimeOffset.getValue());
        const lastDate = new Date(this.startDate.getValue());
        const diff = currentDate.valueOf() - lastDate.getTime();
        this.startTimeElapsed.next(this.fccCommonService.getFormattedTime(diff));
    }

    updateAgentStatus(status: string) {
        this.agentStatus.next(status);
    }

    updateStartDate(startdate: Date) {
        this.startDate.next(startdate);
    }

    updateCurrentAgentState(state: string) {
        this.currentAgentState.next(state);
    }

    updateSelectedAgentState(selectedState: string) {
        this.selectedAgentState.next(selectedState);
    }

    updateIsStateCounterVisible(visible: boolean) {
        this.isStateCounterVisible.next(visible);
    }

    updateStartAcwTimeout(timeout: string) {
        this.acwtimeout.next(timeout);
    }

    setAgentState(currentState, currentOutReason, currentStartDate) {
        let stateDescription = currentState;

        if (currentOutReason) {
            stateDescription = currentOutReason;
        }

        this.webRtcSrv.setStateDescription(stateDescription);

        this.updateAgentStatus(stateDescription);
        this.updateStartDate(currentStartDate);

        this.updateSelectedAgentState(stateDescription);
        this.updateCurrentAgentState(stateDescription); //ZN.Temp

        if (stateDescription == 'Available') {
            this.addContact();
        }
    }

    addContact() {
        this.inContactAPIService.addContact().subscribe(res => {
            // console.log('Add Contact: ' + res);
        });
    }

    updateSelectedAgentStates(stateDescription: string) {
        this.updateCurrentAgentState(stateDescription);
        this.updateSelectedAgentState(stateDescription);
    }

    agentStateChanged(selectedAgentState: AgentStateModel) {
        this.loaderSrv.startBackground();
        this.inContactAPIService.setAgentState(selectedAgentState.agentStateName, selectedAgentState.OutStateDescription).subscribe(
            res => {
                this.result = res;
                this.loaderSrv.stopBackground();
            },
            error => {
                this.loaderSrv.stopBackground();
                this.errorService.onSystemError(error);
            },
        );
    }

    unloadAcwStates() {
        const agentId = this.sessionStorageService.retrieve('agentId');
        this.loadDefaultStates();
        this.loadUnavailableCodes(this.teamId.getValue());
    }

    getContactHistory(agentId: number, startDate: string, endDate: string, orderBy: string): Observable<any> {
        return this.inContactAPIService.getContactHistory(agentId, startDate, endDate, orderBy);
    }

    executeIndicator(indicator: IndicatorModel) {
        this.indicatorsService.executeIndicator(indicator);
    }
}
