export class ZendeskSearchResponseModel<T> {
    count: number;
    facets: number;
    next_page: number;
    previous_page: number;
    results: Array<T>;

    constructor(data?: Partial<T>) {
        Object.assign(this, data);
    }
}
