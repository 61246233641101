export class Utility {
    public static formatAMPM(date: Date): any {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        return {hours: formattedHours, minutes: formattedMinutes, amPm: ampm};
    }

    public static addMinutes(dt: Date, h): Date {
        const date = new Date(dt.toString());
        date.setTime(date.getTime() + h * 60 * 60 * 1000);
        return date;
    }

    public static cleanPhoneNumber(phNo) {
        const ph = `${phNo}`.replace(/[^+*0-9]/g, '');
        return ph;
    }
}
