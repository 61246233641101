import {AnyObject} from 'src/app/global';

export class SoundConfigModel {
    generateTones: AnyObject;
    downloadSounds: Array<any>;
    play: AnyObject;
    version: string;

    constructor(data?: Partial<SoundConfigModel>) {
        Object.assign(this, data);
    }
}
