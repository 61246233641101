export class DynamicsCaseModel {
    // @odata.etag: "W/\"33641083\""
    // activitiescomplete: false
    // activitiescomplete@OData.Community.Display.V1.FormattedValue: "No"
    // actualserviceunits: null
    // adx_createdbyipaddress: null
    // adx_createdbyusername: null
    // adx_modifiedbyipaddress: null
    // adx_modifiedbyusername: null
    // adx_publishtoweb: false
    // adx_publishtoweb@OData.Community.Display.V1.FormattedValue: "No"
    // adx_resolution: null
    // adx_resolutiondate: null
    // adx_stepstoreproduce: null
    // billedserviceunits: null
    // blockedprofile: false
    // blockedprofile@OData.Community.Display.V1.FormattedValue: "No"
    // caseorigincode: null
    // casetypecode: null
    // checkemail: false
    // checkemail@OData.Community.Display.V1.FormattedValue: "No"
    // contractservicelevelcode: null
    public createdon: string;
    // createdon@OData.Community.Display.V1.FormattedValue: "8/26/2021 3:34 PM"
    // customercontacted: false
    // customercontacted@OData.Community.Display.V1.FormattedValue: "No"
    // customersatisfactioncode: null
    // decremententitlementterm: true
    // decremententitlementterm@OData.Community.Display.V1.FormattedValue: "Yes"
    public description: string;
    // emailaddress: null
    // entityimage: null
    // entityimage_timestamp: null
    // entityimage_url: null
    // entityimageid: null
    // escalatedon: null
    // exchangerate: null
    // firstresponsesent: false
    // firstresponsesent@OData.Community.Display.V1.FormattedValue: "No"
    // firstresponseslastatus: 1
    // firstresponseslastatus@OData.Community.Display.V1.FormattedValue: "In Progress"
    // followupby: null
    // followuptaskcreated: false
    // followuptaskcreated@OData.Community.Display.V1.FormattedValue: "No"
    // importsequencenumber: null
    public incidentid: string;
    // incidentstagecode: 1
    // incidentstagecode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // influencescore: null
    // isdecrementing: false
    // isdecrementing@OData.Community.Display.V1.FormattedValue: "No"
    // isescalated: false
    // isescalated@OData.Community.Display.V1.FormattedValue: "No"
    // lastonholdtime: null
    // merged: false
    // merged@OData.Community.Display.V1.FormattedValue: "No"
    // messagetypecode: null
    // modifiedon: "2021-08-26T10:34:58Z"
    // modifiedon@OData.Community.Display.V1.FormattedValue: "8/26/2021 3:34 PM"
    // numberofchildincidents: null
    // onholdtime: null
    // overriddencreatedon: null
    // prioritycode: 2
    // prioritycode@OData.Community.Display.V1.FormattedValue: "Normal"
    // processid: null
    // productserialnumber: null
    // resolveby: null
    // resolvebyslastatus: 1
    // resolvebyslastatus@OData.Community.Display.V1.FormattedValue: "In Progress"
    // responseby: null
    // routecase: true
    // routecase@OData.Community.Display.V1.FormattedValue: "Yes"
    // sentimentvalue: null
    // servicestage: 0
    // servicestage@OData.Community.Display.V1.FormattedValue: "Identify"
    // severitycode: 1
    // severitycode@OData.Community.Display.V1.FormattedValue: "Default Value"
    // stageid: null
    // statecode: 0
    // statecode@OData.Community.Display.V1.FormattedValue: "Active"
    // statuscode: 1
    // statuscode@OData.Community.Display.V1.FormattedValue: "In Progress"
    public ticketnumber: string;
    // timezoneruleversionnumber: null
    public title: string;
    // traversedpath: null
    // utcconversiontimezonecode: null
    // versionnumber: 33641083
    // versionnumber@OData.Community.Display.V1.FormattedValue: "33,641,083"
    // _accountid_value: null
    // _contactid_value: null
    // _contractdetailid_value: null
    // _contractid_value: null
    // _createdby_value: "4d43f6e0-dad2-eb11-bacc-000d3a36328a"
    // _createdby_value@Microsoft.Dynamics.CRM.lookuplogicalname: "systemuser"
    // _createdby_value@OData.Community.Display.V1.FormattedValue: "Ali Shahzad"
    // _createdbyexternalparty_value: null
    // _createdonbehalfby_value: null
    public _customerid_value: string;
    // _customerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty: "customerid_contact"
    // _customerid_value@Microsoft.Dynamics.CRM.lookuplogicalname: "contact"
    // _customerid_value@OData.Community.Display.V1.FormattedValue: "Caller 01114369351959087300011"
    // _entitlementid_value: null
    // _existingcase_value: null
    // _firstresponsebykpiid_value: null
    // _kbarticleid_value: null
    // _masterid_value: null
    // _modifiedby_value: "4d43f6e0-dad2-eb11-bacc-000d3a36328a"
    // _modifiedby_value@Microsoft.Dynamics.CRM.lookuplogicalname: "systemuser"
    // _modifiedby_value@OData.Community.Display.V1.FormattedValue: "Ali Shahzad"
    // _modifiedbyexternalparty_value: null
    // _modifiedonbehalfby_value: null
    // _msa_partnercontactid_value: null
    // _msa_partnerid_value: null
    // _msdyn_iotalert_value: null
    // _ownerid_value: "4d43f6e0-dad2-eb11-bacc-000d3a36328a"
    // _ownerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty: "ownerid"
    // _ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname: "systemuser"
    // _ownerid_value@OData.Community.Display.V1.FormattedValue: "Ali Shahzad"
    // _owningbusinessunit_value: "4507aa0b-9a4a-e711-815e-e0071b715b51"
    // _owningbusinessunit_value@Microsoft.Dynamics.CRM.associatednavigationproperty: "owningbusinessunit"
    // _owningbusinessunit_value@Microsoft.Dynamics.CRM.lookuplogicalname: "businessunit"
    // _owningteam_value: null
    // _owninguser_value: "4d43f6e0-dad2-eb11-bacc-000d3a36328a"
    // _owninguser_value@Microsoft.Dynamics.CRM.lookuplogicalname: "systemuser"
    // _parentcaseid_value: null
    // _primarycontactid_value: null
    // _productid_value: null
    // _resolvebykpiid_value: null
    // _slaid_value: null
    // _slainvokedid_value: null
    // _socialprofileid_value: null
    // _subjectid_value: null

    'customerid_contact@odata.bind': string;

    constructor(data?: Partial<DynamicsCaseModel>) {
        Object.assign(this, data);
    }

    public bindCustomer(customerId: string) {
        this['customerid_contact@odata.bind'] = `/contacts(${customerId})`;
    }
}
