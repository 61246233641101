<ul class="list-group">
    <li
        *ngFor="let agent of agentslist | filter: 'agents':'firstName':keyword; let i = index"
        class="list-group-item list-item-uac"
        (keyup.enter)="callContactService.consultAgent(agent.userName)"
    >
        <a tabindex="i" #listItem name="listItem" role="link" href="javascript:void(0)">
            <div class="row list-item-row">
                <div class="col-xs-9" style="display: flex;">
                    <i
                        class="fa fa-address-card fa-2x"
                        [ngStyle]="{color: getColor(agent.agentStateId, agent.isACW)}"
                        style="padding-right: 10px;"
                        aria-hidden="true"
                        title="{{ agent.outStateDescription }}"
                    ></i>
                    <div class="agentName item-text">
                        <span style="color: #0caaea;">{{ agent.firstName }} {{ agent.lastName }}</span> <br /><span
                            style="color: dimgrey;"
                            >{{ agent.stateTime }}</span
                        >
                    </div>
                    <div class="item-text">{{ agent.outStateDescription }}</div>
                </div>
                <div class="col-xs-3 pull-right timer-column">
                    <div *ngIf="agent.agentStateId === '1' || agent.agentStateId === '2'">
                        <button
                            type="submit"
                            *ngIf="directCall && agent.outStateDescription !== 'LoggedOut' && (agent.isACW == 'False' || agent.agentStateId == 1)"
                            class="btn btn-circle btn-success"
                            (click)="callContactService.consultAgent(agent.userName)"
                        >
                            <!-- {{ 'AddressBook.Agents.Call' | translate }} -->
                            <i class="fa fa-phone"></i>
                        </button>
                        <div
                            *ngIf="showCallMenu && agent.agentStateId !== '0' && (agent.isACW == 'False' || agent.agentStateId == 1)"
                            class="dropdown panel-controls"
                        >
                            <button
                                class="btn btn-circle btn-success dropdown-toggle"
                                type="submit"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                            >
                                <!-- {{ 'AddressBook.Agents.Call' | translate }} -->
                                <!-- <span class="caret"></span> -->
                                <i class="fa fa-phone"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                                <li>
                                    <a
                                        class="menu-items-uac"
                                        style="text-align: right;"
                                        (click)="callContactService.consultAgent(agent.userName)"
                                    >
                                        {{ 'AddressBook.Agents.Consult' | translate }}</a
                                    >
                                </li>
                                <li>
                                    <a
                                        class="menu-items-uac"
                                        style="text-align: right;"
                                        (click)="callContactService.dialAgent(agent.userName)"
                                    >
                                        {{ 'AddressBook.Agents.Transfer' | translate }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </li>
</ul>
