import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {of as observableOf, throwError} from 'rxjs';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';
import {BaseApi, CustomErrorHandler} from 'src/app/sdk/core';
import {FacLocalStorageService} from 'src/app/sdk/storage';
import {Observable} from 'rxjs/internal/Observable';
import {AgentInfoModel} from 'src/app/core/InContact/models/agent-info.model';
import {LocalStorageService} from 'ngx-webstorage';
import {AgentSettingsModel} from '../../models';
import {Agent} from 'src/app/core/_models/agent.model';

@Injectable({
    providedIn: 'root',
})
export class IcAgentsApi extends BaseApi {
    constructor(
        @Inject(HttpClient) httpClient: HttpClient,
        @Inject(FacLocalStorageService) protected facLocalStorageSrv: FacLocalStorageService,
        @Inject(LocalStorageService) protected localStorageSrv: LocalStorageService,
        @Optional() @Inject(CustomErrorHandler) protected errorHandler: CustomErrorHandler,
    ) {
        super(httpClient, facLocalStorageSrv, errorHandler);
    }

    getAgentSettings(agentId: number): Observable<AgentSettingsModel> {
        const url = `${this.facLocalStorageSrv.resource_server_base_uri}services/v14.0/agents/${encodeURIComponent(agentId.toString())}/agent-settings`;
        return this.GET_Request<AgentSettingsModel>(url).pipe(
            map(res => res),
            // catchError(error => {
            //     return this.errorHandler.handleError(error);
            // }),
        );
    }

    getAgentInfo(agentId: number): Observable<Agent | any> {
        const relativeUri = `${this.facLocalStorageSrv.resource_server_base_uri}services/v13.0/agents/${encodeURIComponent(agentId.toString())}`;
        return this.GET_Request<any>(relativeUri).pipe(
            map(res => {
                const agentInfo = res.agents[0];
                this.localStorageSrv.store('agentInfo', agentInfo);

                return agentInfo;
            }),
            // catchError(err => this.errorHandler.handleError),
        );
    }
}
