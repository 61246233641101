import {Component, OnInit, AfterViewInit, OnDestroy, forwardRef, Input, Self, Optional} from '@angular/core';

import {FieldConfigModel} from './../../models/field-config.model';

import {NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl} from '@angular/forms';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextFieldComponent),
    multi: true,
};

@Component({
    selector: 'text-field',
    templateUrl: 'text-field.component.html',
    styleUrls: ['./../fields.scss', './text-field.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TextFieldComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
    private _fieldConfig: FieldConfigModel;
    @Input() set fieldConfig(val: FieldConfigModel) {
        this._fieldConfig = new FieldConfigModel(val) ?? new FieldConfigModel();
    }
    get fieldConfig() {
        return this._fieldConfig;
    }

    @Input() label = '';
    @Input() placeholder = '';
    @Input() name: string;
    @Input() required: boolean;

    private innerValue = '';

    get value(): any {
        return this.innerValue;
    }

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    constructor() { // @Self() @Optional() public control: NgControl
        // this.control && (this.control.valueAccessor = this);
    }

    onBlur() {
        this.onTouchedCallback();
    }
    onChange() {
        this.onChangeCallback(this.innerValue);
    }

    writeValue(value: any): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    ngOnInit() {}

    ngAfterViewInit() {}

    ngOnDestroy() {}
}
