<div class="app-header" [class]="getColorClass">
    <div style="flex: 1; display: flex; align-items: center; cursor: pointer" #dropdownTrigger>
        <lb-ngx-dropdown
            *ngIf="icAgentSessionSrv.agent && icAgentSessionSrv.agent.currentAgentState"
            [configs]="{
                idKey: 'agentStateDescription',
                textKey: 'agentStateDescription'
            }"
            [items]="visiableAgentStates"
            (menuClick)="agentStateChanged($event)"
            style="height: 23px"
            [openers]="[dropdownTrigger]"
            #agentStatusesDropdownRef
        >
            <ng-template #dropdownToggleBtnTemplate>
                <a href="javascript:void(0);" class="" style="padding-left: 0px !important" data-toggle="dropdown">
                    <span class="agent-state">
                        {{ icAgentSessionSrv.agent.currentAgentState }} &nbsp;
                        <span *ngIf="isStateCounterVisible">{{ startTimeElapsed }}</span>
                        <span *ngIf="!isStateCounterVisible">{{ acwtimeout }}</span>
                    </span>
                    <span class="next-state" *ngIf="nextAgentStatus">
                        <i class="fa fa-chevron-circle-right mr-5" aria-hidden="true"></i>
                        {{ nextAgentStatus }}
                    </span>
                </a>
            </ng-template>
        </lb-ngx-dropdown>
        <!-- <dropdown-menu
          *ngIf="agent && agent.currentAgentState"
          [menuConfig]="{
              idKey: 'agentStateDescription',
              textKey: 'agentStateDescription'
          }"
          [menus]="visiableAgentStates"
          (onMenuClick)="agentStateChanged($event)"
          style="height: 23px;"
      >
          <ng-template #dropdownBtnTemplate>
              <a
                  href="javascript:void(0);"
                  class="dropdown-toggle state-dropdown-trigger"
                  style="padding-left: 0px !important;"
                  data-toggle="dropdown"
              >
                  <span class="agent-state"
                      >{{agent.currentAgentState}} &nbsp;
                      <span *ngIf="isStateCounterVisible">{{startTimeElapsed}}</span
                      ><span *ngIf="!isStateCounterVisible">{{acwtimeout}}</span></span
                  >
                  <span class="next-state" *ngIf="nextAgentStatus">{{nextAgentStatus}}</span>
              </a>
          </ng-template>
      </dropdown-menu> -->
    </div>

    <div>
        <ul *ngIf="false" class="nav nav-pills pull-left">
            <li *ngIf="nextAgentStatus">
                <a><i class="fa fa-chevron-circle-right" aria-hidden="true"></i> {{ nextAgentStatus }}</a>
            </li>
            <li *ngIf="nextNextAgentStatus">
                <a><i class="fa fa-chevron-circle-right" aria-hidden="true"></i> {{ nextNextAgentStatus }}</a>
            </li>
        </ul>

        <ul *ngIf="true" class="nav nav-pills pull-right">
            <li>
                <a class="sign-out" href="javascript:;" (click)="confirmEndSession()">
                    <i class="fa fa-power-off" style="margin-right: 5px"></i> <span class="hidden-xs">Sign Out</span>
                </a>
            </li>
        </ul>
    </div>

    <div style="clear: both"></div>
</div>
<ng-template #logoutModal>
    <div class="modal-header">
        <h4 class="modal-title">Confirm Logout</h4>
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button> -->
    </div>
    <div class="modal-body">You have contacts in progress. If you log out now, your current contact will end. Are you sure you want to log out?</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="logoutModalRef.close()">No</button>
        <button type="button" class="btn btn-primary" (click)="endSession()">Yes</button>
    </div>
</ng-template>
