import {Injectable} from '@angular/core';
import {Observable, Subject, BehaviorSubject} from 'rxjs';

import {SkillsModel} from '.././InContact/models/skills.model';
import {AgentInfoModel} from '.././InContact/models/agent-info.model';
import {InContactAPIService} from '.././InContact/incontact-api.service';
import {AgentStateModel} from '../_models/agent-state.model';

@Injectable()
export class APISystemObjectsService {
    constructor(private inContactAPIService: InContactAPIService) {}

    getAgentList(): Observable<AgentInfoModel[]> {
        return this.inContactAPIService.getAgentList();
    }

    getAllSkills(): Observable<SkillsModel[]> {
        return this.inContactAPIService.requestSkills();
    }

    getSkillInfo(skillId: number): Observable<SkillsModel[]> {
        return this.inContactAPIService.getSkillInfo(skillId);
    }

    getAgentStates(): Observable<AgentStateModel[]> {
        return this.inContactAPIService.getAgentStates();
    }
}
