<div class="panel panel-default panel-top">
    <div class="panel-heading">
        <i class="fa fa-comment-o fa-lg" aria-hidden="true" [title]="'Chat.TransferChat' | translate"></i>&nbsp; Select
        CRM Application
        <!--{{ context.selectedRoom.roomName }}-->
        &nbsp;

        <div class="pull-right">
            <!--<a  (click)="openTransferChat()"><i class="fa fa-mail-forward" title="{{ 'ActiveContact.Buttons.Transfer' | translate }}"></i></a>-->
            <i (click)="cancel()" class="fa fa-times fa-lg" [title]="'Chat.EndChat' | translate" aria-hidden="true"></i>
        </div>
    </div>

    <div>
        <!-- Default panel contents -->
        <!--<div class="panel-heading"><i class="fa fa-user-times fa-lg" aria-hidden="true" title="{{ 'Disposition.Title' | translate }}"></i>&nbsp;  {{ 'Disposition.Title' | translate }}</div>-->

        <form #dispositionForm="ngForm" class="chat-form">
            <div class="form-group">
                <label for="exampleInputEmail1">CRM Applications</label>
                <select [(ngModel)]="selectedOption" name="type" #type="ngModel" class="form-control">
                    <option *ngFor="let option of crmApplication" [ngValue]="option"> {{ option.title }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">CRM Type</label>
                <input
                    type="text"
                    disabled
                    class="form-control"
                    id="exampleInputEmail1"
                    placeholder="Email"
                    value="{{ selectedOption.applicationType }}"
                />
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Description</label>
                <input
                    type="text"
                    disabled
                    class="form-control"
                    id="exampleInputEmail1"
                    placeholder="Email"
                    value="{{ selectedOption.description }}"
                />
            </div>

            <div class="form-group">
                <div class="col-md-6 col-md-offset-3">
                    <button (click)="OK()" class="btn btn-primary btn-sm">OK</button>&nbsp;
                    <button (click)="cancel()" class="btn btn-warning btn-sm">Cancel</button>
                </div>
            </div>
        </form>
        <!--</div>   -->
    </div>
</div>
