export class DynamicsActivityPointerModel {
    public activityid: string;
    public activitytypecode: string;
    public description: string;
    public subject: string;

    [key: string]: any;

    constructor(data?: Partial<DynamicsActivityPointerModel>) {
        Object.assign(this, data);
    }
}
