import {Component, Input, ViewChildren, ChangeDetectorRef, OnInit, AfterContentChecked, AfterViewChecked} from '@angular/core';
import {ContactViewBaseModel, ContactActionModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {IcAgentSessionService} from 'src/app/sdk/incontact/services/agent-sessions/agent-session-events.service';
import {IcSecurityProfileService} from 'src/app/sdk/incontact/services';
import {SecurityProfileModel} from 'src/app/sdk/incontact/models';

@Component({
    selector: 'app-contact-actions',
    templateUrl: './contact-elevation.template.html',
    styleUrls: ['./contact-elevation.scss'],
})
export class ContactActionsComponent implements OnInit, AfterViewChecked {
    @Input()
    contactVM: ContactViewBaseModel<ContactBase>;
    @Input() slot = '';
    @Input() mode = 'normal';
    @Input() showPredisposition = false;
    @Input() dispositionMenuAlignment = 'left';
    @Input() dropdownMargin = -75;
    @Input() displayStyle = 'normal';
    @Input() tagClasses = '';

    @ViewChildren('actionItems') filteredItems;

    private securityProfile: SecurityProfileModel = null;

    public businessUnitInfo: any = {};

    related = 'normal';

    constructor(
        private agentSessionEventsService: IcAgentSessionService,
        private cdRef: ChangeDetectorRef,
        private securityProfileSrv: IcSecurityProfileService,
    ) {
        this.agentSessionEventsService.businessUnit.subscribe(bu => {
            this.businessUnitInfo = bu;
        });
    }

    savePreselectedDisposition() {
        if (this.contactVM.contact.appliedDisposition) {
            this.contactVM.preDeispositionSet = true;
        }
    }

    ngOnInit() {
        this.securityProfileSrv.securityProfileBS.subscribe(securityProfile => {
            this.securityProfile = securityProfile;
        });
    }

    ngAfterViewChecked() {
        //console.log("! changement de la date du composant !");
        //this.dateNow = new Date();
        this.cdRef.detectChanges();
    }

    isAllowed(action: ContactActionModel) {
        if (action) {
            let key = '';
            if (action.action === 'record') {
                key = 'Record';
            }
            if (action.action === 'mask') {
                key = 'Masking';
            }
            if (action.action === 'mute') {
                key = 'MuteAgent';
            }
            if (this.securityProfile && !!key) {
                const found = this.securityProfile.permissions.find(x => x.permissionKey === key);
                return !!found;
            }
        }

        return true;
    }
}
