import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';

// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';

import {CoreModule} from './core/core.module';
//import { SharedModule } from './shared/shared.module';

import {AppComponent} from './app.component';
import {AboutModule} from './about/about.module';
import {AppRoutingModule} from './app.routing';
import {HomeModule} from './home/home.module';

import {NavbarComponent} from './shared/navbar/navbar.component';
import {FooterComponent} from './shared/footer/footer.component';
import {StatusBarComponent} from './shared/status-bar/status-bar.component';

import {QueueModule} from './screens/queue/queue.module';
import {DialpadModule} from './screens/dialpad/dialpad.module';
import {AddressBookModule} from './screens/address-book/address-book.module';
import {CallHistoryModule} from './screens/call-history/call-history.module';
import {AgentInfoModule} from './screens/agent-info/agent-info.module';
import {AutomatedOutboundModule} from './screens/automated-outbound/automated-outbound.module';
import {ActiveContactModule} from './screens/active-contact/active-contact.module';
import {ChatModule} from './screens/chat/chat.module';
import {EmailModule} from './screens/email/email.module';
import {AuthenticationModule} from './screens/authentication/authentication.module';
import {WorkItemModule} from './screens/work-items/work-items.module';
import {ScheduledCallbacksModule} from './screens/scheduled-callbacks/scheduled-callbacks.module';

import {AuthGuard} from './core/_guards/auth.guard';
import {AgentService} from './core/_services/agent.service';
import {ConfigService} from './core/_services/config.service';
import {ErrorComponent} from './shared/error/error.component';
// import {Ng2Webstorage} from 'ng2-webstorage';

// import {ModalModule} from 'angular2-modal';
// import {Ng2BootstrapModule} from 'ng2-bootstrap';
// import {Ng2Bs3ModalModule} from 'ng2-bs3-modal/ng2-bs3-modal';
import {ModalModule as BsModalModule} from 'ngx-bootstrap/modal';
// import {DropdownModule} from 'ng2-bootstrap';
// import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

// import {BootstrapModalModule} from 'angular2-modal/plugins/bootstrap';
import {SpinnerModule} from './core/_components/spinner/spinner.module';
import {ReportFeedbackModule} from './screens/report-feedback/report-feedback.module';
import {SupportModule} from './screens/support/support.module';

import {SharedModule} from './shared/shared.module';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {SdkModule} from './sdk/sdk.module';

import {InContactSdkConfig} from './sdk/sdk.config';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {AppToasterService} from './plugins/ngx-toastr';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {DialogComponent} from './shared/modals/dialog/dialog.component';
import {ModalModule} from 'ngx-modialog-7';
import {BootstrapModalModule} from 'ngx-modialog-7/plugins/bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {APP_BASE_HREF} from '@angular/common';
import {AppDefaultLayoutComponent} from './layout/app-default-layout/app-default-layout.component';
import {HeaderComponent} from './layout/header/header.component';
import {DefaultFooterComponent} from './layout/default-footer/default-footer.component';
import {CustomErrorHandler} from './sdk/core';
import {DexieLogsModule} from './sdk/dexie';

// import './../assets/webrtc/js/ac_webrtc.min.js';
// import './../assets/webrtc/js/utils.js';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        AppDefaultLayoutComponent,
        NavbarComponent,
        HeaderComponent,
        DefaultFooterComponent,
        FooterComponent,
        ErrorComponent,
        StatusBarComponent,
        DialogComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        NgxWebstorageModule.forRoot({
            prefix: 'uac',
            separator: '.',
        }),
        SharedModule,
        CoreModule,
        AuthenticationModule,
        HomeModule,
        AboutModule,
        AppRoutingModule,
        QueueModule,
        DialpadModule,
        AddressBookModule,
        CallHistoryModule,
        AgentInfoModule,
        AutomatedOutboundModule,
        ActiveContactModule,
        ChatModule,
        EmailModule,
        WorkItemModule,
        ScheduledCallbacksModule,
        SpinnerModule,
        ReportFeedbackModule,
        SupportModule,
        ModalModule.forRoot(),
        BootstrapModalModule,

        NgxUiLoaderModule.forRoot({
            bgsColor: '#159aca',
            bgsOpacity: 0.5,
            bgsPosition: 'top-right',
            bgsSize: 80,
            bgsType: 'cube-grid',
            blur: 2,
            delay: 0,
            fastFadeOut: true,
            fgsColor: '#159aca',
            fgsPosition: 'center-center',
            fgsSize: 100,
            fgsType: 'cube-grid',
            gap: 24,
            logoPosition: 'center-center',
            logoSize: 120,
            logoUrl: '',
            masterLoaderId: 'master',
            overlayBorderRadius: '0',
            overlayColor: 'rgba(40, 40, 40, 0.8)',
            pbColor: 'red',
            pbDirection: 'ltr',
            pbThickness: 3,
            hasProgressBar: true,
            text: 'Loading...',
            textColor: '#FFFFFF',
            textPosition: 'center-center',
            maxTime: -1,
            minTime: 300,
        }),

        SdkModule,

        DexieLogsModule,

        BsModalModule.forRoot(),

        BsDropdownModule.forRoot(),

        ToastrModule.forRoot(),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: 'en',
        }),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [TranslateModule],
    providers: [
        Title,
        AuthGuard,
        AgentService,
        ConfigService,
        AppToasterService,
        // {provide: ErrorHandler, useClass: CustomErrorHandler},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {}
}
