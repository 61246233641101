import {Component, Inject, OnInit} from '@angular/core';
import {AgentInfoModel} from '../../core/InContact/models/agent-info.model';
import {SessionInfo} from '../../core/InContact/models/session-info.model';
import {InContactAPIService} from '../../core/InContact/incontact-api.service';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {AgentSkillAssignmentsModel} from '../../core/InContact/models/agent-skill-assignments.model';
import {MenuItem} from 'primeng/api';
import {DexieLogsService} from 'src/app/sdk/dexie/services';

@Component({
    selector: 'app-agent-info',
    templateUrl: './agent-info.component.html',
    styleUrls: ['./agent-info.component.css'],
})
export class AgentInfoComponent implements OnInit {
    // private _AgentStates:AgentStateModel[];
    public agentInfo = new AgentInfoModel();
    public sessionInfo: SessionInfo;
    agentSkillAssignments: AgentSkillAssignmentsModel[];

    menuItems: MenuItem[] = [];

    constructor(
        private inContactAPIService: InContactAPIService,
        private agentSessionService: IcAgentSessionService,
        @Inject(DexieLogsService) public dexieLogsSrv: DexieLogsService,
    ) {}

    ngOnInit() {
        this.menuItems = [
            {
                label: 'Download Logs',
                icon: 'pi pi-fw pi-download',
                command: async (event: any) => {
                    await this.downloadLogs();
                },
            },
        ];

        this.sessionInfo = this.agentSessionService.getSessionInfo();

        this.inContactAPIService.getAgentInfo(this.sessionInfo.AgentId).subscribe(
            res => {
                this.agentInfo = res;
            },
            error => error,
        );

        this.agentSkillAssignments = [];
        this.agentSessionService.agentSkillAssignments.subscribe(skills => (this.agentSkillAssignments = skills));
    }

    async downloadLogs() {
        this.dexieLogsSrv.dowloadJson();
    }

    getContactNo() {
        if (this.sessionInfo.StationId === '0') {
            return this.sessionInfo.StationPhoneNumber;
        } else {
            return this.sessionInfo.StationId;
        }
    }
}
