import {NgModule} from '@angular/core';

import {AddressBookRoutingModule} from './address-book.routing';
import {AddressBookComponent} from './address-book.component';
import {AgentsComponent} from './agents/agents.component';
import {SkillsComponent} from './skills/skills.component';
import {AddressBookService} from './address-book.service';

// import { QueueService }     from './address-book.service';

import {SharedModule} from '../../shared/shared.module';
//import { FilterPipe } from '../../shared/pipes/filter.pipe';
import {AddressBookContactsComponent} from './address-book-contacts/address-book-contacts.component';
import {FocusDirective} from '../../shared/directives/focus.directive';

@NgModule({
    imports: [AddressBookRoutingModule, SharedModule],
    declarations: [AddressBookComponent, AgentsComponent, SkillsComponent, AddressBookContactsComponent],
    exports: [AddressBookComponent],
    providers: [AddressBookService],
})
export class AddressBookModule {}
