import {ContactViewBaseModel, ContactActionModel} from './contact-view-base.model';
import {Email} from '../email.model';
import {Subject} from 'rxjs';
import {CRM_APPLICATION_TYPES} from 'src/app/global';

export class EmailContactViewmodel extends ContactViewBaseModel<Email> {
    public startTimeElapsed: string;

    reply: any = null;
    stateFlag = 'new';
    replyModeEntered: Subject<boolean> = new Subject<boolean>();

    emailSent: Subject<any> = new Subject<any>();

    decodeBodyHtml() {
        if (this.contact.rawData.bodyHtml || this.contact.rawData.BodyHTML) {
            return JSON.parse('"' + this.getContactPropertyValueByKey('bodyHTML') + '"').replace(/<!--[\s\S]*?-->/g, ''); //decodeURIComponent(JSON.parse( '"' + event.BodyHTML + '"')).replace(/<!--[\s\S]*?-->/g, '');//event.BodyHTML;
        } else {
            return JSON.parse('"' + this.getContactPropertyValueByKey('body') + '"').replace(/<!--[\s\S]*?-->/g, '');
        }
    }
    vmInit() {
        const me = this;
        this.contactIcon = this.contact.contactIcon;
        this.actions.push(
            new ContactActionModel({
                action: 'send',
                text: 'Send',
                icon: 'paper-plane',
                slot: 'detail_footer',
                isAvailable: () => me.reply && !me.contact.isDisconnected(),
                isVisible: () => me.reply && !me.contact.isDisconnected(),
            }),
            new ContactActionModel({
                action: 'discard',
                text: 'Discard',
                icon: 'trash-alt',
                slot: 'detail_footer',
                isAvailable: () => me.reply && !me.contact.isDisconnected(),
                isVisible: () => me.reply && !me.contact.isDisconnected(),
            }),
            new ContactActionModel({
                action: 'replyallmode',
                text: 'Reply All',
                icon: 'reply-all',
                slot: 'detail_header',
                isAvailable: () => me.contact.isInbound && !me.contact.isDisconnected(),
                isVisible: () => !me.reply && !me.contact.isDisconnected(),
            }),
            new ContactActionModel({
                action: 'replymode',
                text: 'Reply',
                icon: 'reply',
                slot: 'detail_header',
                isAvailable: () => me.contact.isInbound && !me.contact.isDisconnected(),
                isVisible: () => !me.reply && !me.contact.isDisconnected(),
            }),
            new ContactActionModel({
                action: 'forward',
                text: 'Forward',
                icon: 'forward',
                slot: 'detail_header',
                isAvailable: () => me.contact.isInbound && !me.contact.isDisconnected(),
                isVisible: () => !me.reply && !me.contact.isDisconnected(),
            }),
            /* new contactAction({
                "action": "elevate_to_phone",
                "text": "Call",
                "icon": "phone",
                "slot": "elevations",
                isAvailable: () => true// me.canElevateToCall() && !me.contact.isParked()
            }), */
            new ContactActionModel({
                action: 'transfer',
                text: 'Transfer',
                icon: 'exchange-alt',
                isAvailable: () => me.contact.isInbound && !me.contact.isDisconnected() && !me.contact.isParked() && !me.contact.isInterrupted(),
            }),
            new ContactActionModel({
                action: 'unparkqueue',
                text: 'Unpark To Queue',
                icon: 'sync-alt',
                isVisible: () => me.contact.isInbound && !me.contact.isDisconnected() && me.contact.isParked() && !me.contact.isParkedInqueue,
            }),
            new ContactActionModel({
                action: 'unpark',
                text: 'Unpark',
                icon: 'sync-alt',
                isVisible: () => me.contact.isInbound && !me.contact.isDisconnected() && me.contact.isParked() && !me.contact.isParkedInqueue,
            }),
            new ContactActionModel({
                action: 'park',
                text: 'Park',
                icon: 'parking',
                isAvailable: () => me.contact.isInbound && !me.contact.isDisconnected() && me.contact.canPark() && !me.contact.isInterrupted(),
            }),
            new ContactActionModel({
                action: 'end',
                text: 'End',
                icon: 'window-close',
                color: 'red',
                visibleInOmniRelatedMode: true,
                isAvailable: () => me.contact.isActive() && !me.reply,
            }),
        );

        if (this.contact.isInbound) {
            this.configMap = {
                settingSectionName: 'inboundEmail',
                mappingSectionName: 'inboundEmail',
                incontactSearchField: 'FromAddress',
                userTitle: 'Email',
                contactTitle: 'Inbound Email',
                isCallContact: false,
                statusField: 'Status',
                activeStatusValue: 'Active',
            };
        } else {
            this.configMap = {
                settingSectionName: 'outboundEmail',
                mappingSectionName: 'outboundEmail',
                incontactSearchField: 'ToAddress',
                userTitle: 'Email',
                contactTitle: 'Outbound Email',
                isCallContact: false,
                statusField: 'Status',
                activeStatusValue: 'Active',
            };
        }

        if (this.appContext.selectedCrmApplication.applicationType === CRM_APPLICATION_TYPES.ZENDESK) {
            this.configMap.zendeskSearchField = 'email';
            this.configMap.zendeskSearchOperator = '';
        } else if (this.appContext.selectedCrmApplication.applicationType === CRM_APPLICATION_TYPES.DYNAMICS) {
            this.configMap.zendeskSearchField = 'emailaddress1';
            this.configMap.zendeskSearchOperator = '';
        }
    }
    afterVmInit() {
        if (!this.contact.isInbound) {
            //this.enterReplyMode({});
            this.reply = {
                toAddress: this.contact.toAddress ? this.contact.toAddress : '',
                subject: '',
                bodyHTML: ``,
                showCC: false,
                showBCC: false,
                ccAddress: '',
                bccAddress: '',
                skillId: this.contact.skill,
                replyMode: '',
            };
            this.replyModeEntered.next(true);
        }
    }

    public executeContactAction(action: string, data?: any) {
        switch (action) {
            case 'replymode':
                this.enterReplyMode(data);
                break;
            case 'replyallmode':
                this.enterReplyAllMode(data);
                break;
            case 'forward':
                this.enterForwardMode(data);
                break;
            case 'discard':
                this.discard(data);
                break;
            case 'send':
                this.send(data);
                break;
            case 'park':
                this.appContext.emailContactService.parkEmail(this.contact).subscribe(r => {
                    this.appContext.emailContactService.updateParkedEmails();
                });
                break;
            case 'unpark':
                this.appContext.emailContactService.unparkEmail(this.contact).subscribe();
                break;
            case 'unparkqueue':
                this.appContext.emailContactService.unparkEmail(this.contact, false).subscribe(r => {
                    this.appContext.emailContactService.updateParkedEmails();
                });
                break;
            default:
                super.executeContactAction(action, data);
                break;
        }
    }

    private enterReplyMode(data: any) {
        this.reply = {
            toAddress: this.contact.fromAddress,
            subject: 'RE: ' + this.contact.subject.replace('FW:', '').replace('RE:', '').trim(),
            bodyHTML: ``,
            showCC: false,
            showBCC: false,
            ccAddress: '',
            bccAddress: '',
            skillId: this.contact.skill,
            replyMode: 'R',
        };

        this.replyModeEntered.next(true);
    }

    private enterReplyAllMode(data: any) {
        this.enterReplyMode(data);

        const replyAllAddresses = this.contact.toAddress
            .split(';')
            .filter(x => x.toLowerCase() != this.contact.fromAddress.toLowerCase())
            .filter(x => x.toLowerCase() != this.contact.pocAddress.toLowerCase());
        replyAllAddresses.splice(0, 0, this.contact.fromAddress);

        this.reply.toAddress = replyAllAddresses.join(';');

        this.reply.replyMode = 'RA';
        if (this.contact.ccAddress) {
            this.reply.ccAddress = this.contact.ccAddress;
            this.showCC();
        }

        if (this.contact.bccAddress) {
            this.reply.bccAddress = this.contact.bccAddress;
            this.showBCC();
        }
    }

    private enterForwardMode(data: any) {
        this.enterReplyMode(data);
        this.reply.replyMode = 'F';
        this.reply.toAddress = '';
        this.reply.subject = `FW: ${this.contact.subject.replace('FW:', '').replace('RE:', '').trim()}`;
    }

    private discard(data: any) {
        if (this.contact.isInbound) {
            const dialogRef = this.appContext.dialogService.confirmDialog(
                `Discard Draft`,
                `Are you sure you want to discard this draft`,
                'Yes',
                'Cancel',
                `discard-draft-${this.contact.contactId}`,
            );

            // dialogRef.content.onClose.subscribe((res: boolean) => {
            //     if (res) {
            //         if (this.contact.isInbound) {
            //             this.reply = null;
            //             this.replyModeEntered.next(false);
            //         }
            //     }
            // });
            dialogRef.result.then(dialogResult => {
                if (dialogResult) {
                    if (this.contact.isInbound) {
                        this.reply = null;
                        this.replyModeEntered.next(false);
                    }
                }
            });
        } else {
            this.contactService.endContact(this.contact).subscribe(r => {
                this.stateFlag = 'discarded';
            });
        }
    }
    private send(data: any) {
        const rep = Object.assign({}, this.reply);
        delete rep.showBCC;
        delete rep.showCC;
        delete rep.replyMode;
        rep.skillId = this.contact.skill;
        if (this.contact.isInbound) {
            this.appContext.inContactAPIService.replyEmail(this.contact.contactId, this.reply.replyMode, rep).subscribe();
        } else {
            this.appContext.inContactAPIService.emailSend(this.contact.contactId, rep).subscribe(res => {
                // this.emailSent.next(this.reply);
            });
        }
    }

    showCC() {
        if (this.reply) {
            this.reply.showCC = true;
        }
    }

    showBCC() {
        if (this.reply) {
            this.reply.showBCC = true;
        }
    }

    isCrmProcessable(): boolean {
        return this.stateFlag != 'discarded';
    }

    toContactString() {
        let htmlstr = super.toContactString();
        const data = this.contact.rawData;
        let sep = '<br /><br />-------------------------{title}-------------------------------<br /><br />';

        sep = sep.replace('{title}', '|Email Text|');
        htmlstr += sep;

        //Add Email Body
        if (this.reply) {
            //Add subject
            htmlstr += `<br />Subject:${this.reply.subject}<br />`;
            const StrippedString = this.reply.bodyHTML; //data.contact.bodyHtml;//.replace(/(<([^>]+)>)/ig, "");
            htmlstr += '<br />' + StrippedString;
        } else {
            //Add subject
            htmlstr += `<br />Subject:${this.getContactPropertyValueByKey('subject')}<br />`;
            const StrippedString = this.decodeBodyHtml(); //data.contact.bodyHtml;//.replace(/(<([^>]+)>)/ig, "");
            htmlstr += '<br />' + StrippedString;
        }

        return htmlstr;
    }

    public transferData(viewmodel: EmailContactViewmodel) {
        super.transferData(viewmodel);
        if (viewmodel.reply) {
            this.reply = viewmodel.reply;
        }

        if (this.contact.isDisconnected()) {
            this.contact.rawData.Subject = viewmodel.contact.subject;
            this.contact.subject = viewmodel.contact.subject;

            this.contact.body = viewmodel.contact.body;
            this.contact.rawData.Body = viewmodel.contact.body;

            this.contact.bodyHTML = viewmodel.contact.bodyHTML;
            this.contact.rawData.BodyHTML = viewmodel.contact.bodyHTML;
            this.stateFlag = viewmodel.stateFlag;
        } else {
            this.contact.timers = viewmodel.contact.timers;
        }
    }
}
