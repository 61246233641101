<div class="panel panel-default panel-top-most">
    <!-- Default panel contents -->
    <div role="heading" aria-level="3" class="panel-heading">
        <i class="fa fa-building-o fa-lg" aria-hidden="true" title="{{ 'WorkItems.Title' | translate }}"></i>&nbsp;
        {{ 'WorkItems.Title' | translate }}
    </div>

    <div class="panel-scrollable">
        <div class="list-group">
            <a href="#" class="list-group-item">
                <span class="badge">14:10</span>
                <h5 class="list-group-item-heading">ZDAC Demo Work Item</h5>
                <p class="list-group-item-text">You have incoming voice mail from kevin@zdac.net</p>
                <div class="button-group-icons">
                    <div class="btn-group" role="group" aria-label="...">
                        <a class="btn btn-default btn-sm" href="#"
                            ><i class="fa fa-reply" title="{{ 'WorkItems.Buttons.End' | translate }}"></i
                        ></a>
                        <a class="btn btn-default btn-sm" href="#"
                            ><i class="fa fa-pause-circle-o" title="{{ 'WorkItems.Buttons.Hold' | translate }}"></i
                        ></a>
                        <a class="btn btn-default btn-sm" href="#"
                            ><i class="fa fa-tty" title="{{ 'WorkItems.Buttons.Transfer' | translate }}"></i
                        ></a>
                    </div>
                </div>
            </a>

            <a href="#" class="list-group-item">
                <span class="badge">14:10</span>
                <h5 class="list-group-item-heading">ZDAC Demo Work Item</h5>
                <p class="list-group-item-text">You have incoming voice mail from kevin@zdac.net</p>
                <div class="button-group-icons">
                    <div class="btn-group" role="group" aria-label="...">
                        <a class="btn btn-default btn-sm" href="#"
                            ><i class="fa fa-reply" title="{{ 'WorkItems.Buttons.End' | translate }}"></i
                        ></a>
                        <a class="btn btn-default btn-sm" href="#"
                            ><i class="fa fa-pause-circle-o" title="{{ 'WorkItems.Buttons.Hold' | translate }}"></i
                        ></a>
                        <a class="btn btn-default btn-sm" href="#"
                            ><i class="fa fa-tty" title="{{ 'WorkItems.Buttons.Transfer' | translate }}"></i
                        ></a>
                    </div>
                </div>
            </a>

            <a href="#" class="list-group-item">
                <span class="badge">14:10</span>
                <h5 class="list-group-item-heading">ZDAC Demo Work Item</h5>
                <p class="list-group-item-text">You have incoming voice mail from kevin@zdac.net</p>
                <div class="button-group-icons">
                    <div class="btn-group" role="group" aria-label="...">
                        <a class="btn btn-default btn-sm" href="#"
                            ><i class="fa fa-reply" title="{{ 'WorkItems.Buttons.End' | translate }}"></i
                        ></a>
                        <a class="btn btn-default btn-sm" href="#"
                            ><i class="fa fa-pause-circle-o" title="{{ 'WorkItems.Buttons.Hold' | translate }}"></i
                        ></a>
                        <a class="btn btn-default btn-sm" href="#"
                            ><i class="fa fa-tty" title="{{ 'WorkItems.Buttons.Transfer' | translate }}"></i
                        ></a>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
