<div class="panel panel-default panel-top-most">
    <!-- Default panel contents -->
    <div role="heading" aria-level="3" class="panel-heading">
        <i class="fa fa-address-card-o fa-lg" aria-hidden="true"></i>&nbsp; {{ 'Support.Title' | translate }}
        <div class="pull-right">
            <i
                (click)="close()"
                class="fa fa-times-circle fa-lg"
                title="{{ 'Report.Close' | translate }}"
                aria-hidden="true"
            ></i>
        </div>
    </div>
    <div class="panel-scrollable">
        <div class="form-horizontal">
            <div class="form-group disposition-form-group">
                <div class="col-md-6 col-md-offset-3">
                    <p>
                        {{ 'Support.SupportText' | translate }}
                        <a target="_blank" href="https://success.ringcentral.com/RCContactSupp">
                            {{ 'Support.RingCentralContact' | translate }} </a
                        >.
                    </p>
                </div>
            </div>
            <div class="form-group disposition-form-group">
                <button type="button" (click)="close()" class="btn btn-default center-block">
                    {{ 'Buttons.Close' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
