import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

//Modules

import {AutomatedOutboundRoutingModule} from './automated-outbound.routing';
import {SharedModule} from '../../shared/shared.module';

//Services
import {AutomatedOutboundService} from '../../core/_services/automated-outbound.service';

//Components
import {AutomatedOutboundComponent} from './automated-outbound.component';

@NgModule({
    imports: [FormsModule, AutomatedOutboundRoutingModule, SharedModule],
    declarations: [AutomatedOutboundComponent],
    exports: [AutomatedOutboundComponent],
    providers: [AutomatedOutboundService],
})
export class AutomatedOutboundModule {}
