import {of as observableOf, Observable, Subject} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {CrmIntegrationService} from './crmIntegration.service';

import {integrationServicesContainer} from './integrationServicesContainer.service';
import {CurrentContact} from '../.././core/_models/current-contact.model';

// import {LocalStorageService, SessionStorageService} from 'ng2-webstorage';
import {InContactAPIService} from '../InContact/incontact-api.service';
import {IncomingContactService} from '../_services/incoming-contact.service';
import {CallContactService} from '../_services/call-contact.service';
import {BaseApi} from 'src/app/sdk/core';
import {HttpHeaders} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {CrmApplicationModel} from 'src/app/sdk/fac-crm/models';

declare let hostOpenUrl;
declare let global;

const apiUrl = 'https://frontlineagentconsoledynamicsmiddleware.azurewebsites.net';
//let apiUrl = 'http://localhost:4400';
// let localStorageService = new LocalStorageService();

export class DynamicsCrmIntegrationService extends CrmIntegrationService {
    crmwebapipath = '';
    crmorg = '';
    apiBaseUrl = '';

    private currentZendeskUser: any = null;
    constructor(
        selectedCrm: CrmApplicationModel,
        servicesContainer: integrationServicesContainer,
        protected inContactAPIService: InContactAPIService,
        protected incomingContactService: IncomingContactService,
        protected callContactService: CallContactService,
        protected baseApi: BaseApi,
        protected localStorageService: LocalStorageService,
    ) {
        super(selectedCrm, servicesContainer, inContactAPIService, incomingContactService, callContactService, localStorageService);
        const me = this;
        (this.ConfigurationMap.CallContactEvent_Inbound as any).zendeskSearchField = 'phone';
        (this.ConfigurationMap.CallContactEvent_Outbound as any).zendeskSearchField = 'phone';
        (this.ConfigurationMap.CallContactEvent_Inbound as any).zendeskSearchOperator = '*';
        (this.ConfigurationMap.CallContactEvent_Outbound as any).zendeskSearchOperator = '*';
        (this.ConfigurationMap.EmailContactEvent_Inbound as any).zendeskSearchField = 'email';
        (this.ConfigurationMap.EmailContactEvent_Outbound as any).zendeskSearchField = 'email';
        (this.ConfigurationMap.EmailContactEvent_Inbound as any).zendeskSearchOperator = '';
        (this.ConfigurationMap.EmailContactEvent_Outbound as any).zendeskSearchOperator = '';
        (this.ConfigurationMap.VoiceMailContactEvent_Inbound as any).zendeskSearchField = 'phone';
        (this.ConfigurationMap.VoiceMailContactEvent_Inbound as any).zendeskSearchOperator = '*';
        (this.ConfigurationMap.ChatContactEvent as any).zendeskSearchField = 'phone';
        (this.ConfigurationMap.WorkItemContactEvent as any).zendeskSearchField = 'phone';

        //this.dynamicsToken = selectedCrm.token;
        this.crmwebapipath = selectedCrm.crmAccountSettings?.crmwebapipath;
        this.crmorg = selectedCrm.crmAccountSettings?.crmorg;
        this.apiBaseUrl = selectedCrm.apiBaseUrl;

        // this.singlematch();
    }

    singlematch() {
        // The customer phone number matches one contact in Dynamics 365
        const contactno = '923084773301'; // The contact number to be searched
        const entityname = 'account'; // Entity type whose records are to be searched

        Microsoft.CIFramework.searchAndOpenRecords(
            entityname,
            `?$select=name,telephone1&$filter=telephone1 eq '` + `${contactno}` + `'` + '&$search=' + `${contactno}`,
            false,
        )
            .then(
                result => {
                    console.log(result);
                    if (result) {
                        const res = JSON.parse(result);
                        // Display the name and telephone number of the retrieved contact on the console
                        console.log(`Record values: Name: ${res[0].name}, Telephone number: ${res[0].telephone1}`);
                    }
                },
                error => {
                    console.log(error.message);
                },
            )
            .catch(err => {
                console.log('ci framework error: ', err);
            });
    }

    getAuthenticationToken(id: string): Observable<any> {
        const me = this;
        // let _method: string = 'GET';
        const _url: string = me.apiBaseUrl + '/Agents/dynamicstoken';

        // let headers: Headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // let _routeParams: any = {};
        // let _postBody: any = {};
        // let _urlParams: any = {};
        // if (id) _urlParams.id = id;

        // let request: Request = new Request({
        //     headers: headers,
        //     method: _method,
        //     url: _url,
        //     body: _postBody ? JSON.stringify(_postBody) : undefined,
        // });

        return this.baseApi.GET_Request(_url, null, {id});

        // return this.http.request(request).pipe(
        //     catchError(e => {
        //         return observableOf(e);
        //     }),
        //     map((response: Response) => response.json()),
        // );
    }

    getCurrentTicketCaseId(contactId) {
        const ticketId = this.getRandomInt(1111, 9999);
        const values = this.servicesContainer.DataContainerService.CurrentContactArray.getValue();
        const index = this.servicesContainer.DataContainerService.findCurrentTicketByContact(contactId);

        if (index > -1) {
            values[index].ticketId = ticketId;
        } else {
            values.push(new CurrentContact(ticketId, contactId));
        }

        this.servicesContainer.DataContainerService.CurrentContactArray.next(values);
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    processNewContact(data: any, context: any) {
        super.processNewContact(data, context);
    }

    processExistingContact(data: any, context: any) {
        super.processExistingContact(data, context);
    }

    finalizeContact(data: any, context: any) {
        super.finalizeContact(data, context);
        const currentContact = this.servicesContainer.DataContainerService.getCurrentContact(data.contact.ContactID);
        //if (this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId &&
        //(this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId + "").trim().length > 0) {
        if (currentContact && currentContact.ticketId && (currentContact.ticketId + '').trim().length > 0) {
        } else {
            this.afterContactFinalized(data, context);
            return;
        }

        //this.getTicketById(this.servicesContainer.DataContainerService.CurrentContacts.currentTicketId).subscribe(ticket => {
        this.getTicketById(currentContact.ticketId).subscribe(ticket => {
            const contact = data.contact;
            const tic: any = {};
            //tic.fields = {};

            // if (context.mappings) {

            //     let mappings = context.mappings.filter(map => { return map.value && map.value.length > 0 });
            //     if (mappings) {
            //         for (var i = 0; i < mappings.length; i++) {
            //             var mapField = mappings[i];
            //             var fieldSearch = ticket.fields.filter(f => {
            //                 return f.id == parseInt(mapField.value, 0);
            //             });
            //             if (fieldSearch.length > 0) {
            //                 let field = fieldSearch[0];
            //                 if (mapField.name === '_recordingFilePath') {
            //                     tic.fields[mapField.value] = (!field || field.value === null ? '' : field.value + ', ') + data.contact._recordingFilePath;
            //                 } else if (mapField.name === '_startTime') {
            //                     tic.fields[mapField.value] = (field.value === null ? '' : field.value + ', ') + data.contact._startTime;
            //this.fcc.common.getFormatedDateTime(new Date(contact.startTime()));
            //                 } else if (mapField.name === '_duration') {
            //                     tic.fields[mapField.value] = (field.value === null ? '' : field.value + ', ') + data.contact._duration;
            //                 } else {
            //                     tic.fields[mapField.value] = (field.value === null ? '' : field.value + ', ') + data.contact[mapField.name];
            //                 }
            //             }
            //         }
            //     }
            // }

            //-->let htmlstr = this.getTicketNoteString(data, context);
            // if (htmlstr && htmlstr.length > 0) {
            //     tic.comment = {};
            //     tic.comment.public = false;
            //     tic.comment.body = htmlstr;
            // }
            this.getContactStringAsync(data, context).subscribe(htmlstr => {
                tic.description = htmlstr;
                //this.updateTicket(ticket.id, tic);
                this.createTicketNote(ticket, htmlstr, data);
                this.afterContactFinalized(data, context);
            });
        });

        //super.finalizeContact(data, context);
    }

    private createTicketObject(data: any, context: any) {
        const contactSettings = context.settings; //this.getContactSettings(data.contact);
        const configMap = context.configMap;
        const phone = data.contact[configMap.incontactSearchField];
        const user = data.user;

        const obj: any = {
            title: !contactSettings.ticketSubject ? 'New Ticket' : this.replaceTokensWithValues(contactSettings.ticketSubject, data, context),
            'customerid_account@odata.bind': '/accounts(' + data.user.id + ')',
        };
        if (contactSettings.defaultGroup && parseInt(contactSettings.defaultGroup, 10)) {
            obj.group_id = parseInt(contactSettings.defaultGroup, 10);
        }
        // if (contactSettings.ticketTags) {
        //     obj.tags = this.replaceTokensWithValues(contactSettings.ticketTags, data, context);
        // }

        //obj.comment = {};
        if (data.contact.Type == 'EmailContactEvent') {
            obj.description = this.getContactString(data, context);
        } else {
            obj.description = !contactSettings.ticketComments
                ? 'New ticket details...'
                : this.replaceTokensWithValues(contactSettings.ticketComments, data, context);
        }
        //obj.comment.public = false;
        obj.messagetypecode = 1;

        //obj.requester_id = data.user.accountid;
        //obj.assignee_id = this.currentZendeskUser.id;

        return obj;
    }

    createUserObject(data: any, context: any): any {
        const obj: any = {};
        //obj = data;
        const settings = this.getContactSettings(data.contact);

        const icSearchFieldValue =
            data.contact[
                this.getConfigurationMap(data.contact).incontactSearchField ? this.getConfigurationMap(data.contact).incontactSearchField : 'ContactID'
            ];
        let zdSearchFieldName = this.getConfigurationMap(data.contact).zendeskSearchField;
        obj.name = this.getConfigurationMap(data.contact).userTitle + ' ' + icSearchFieldValue;

        if (zdSearchFieldName) {
            zdSearchFieldName = this.getDynamicsSearchFieldName(zdSearchFieldName);
            obj[zdSearchFieldName] = icSearchFieldValue;
        }

        if (this.getConfigurationMap(data.contact).isCallContact && settings.e164enabled) {
            //obj.shared_phone_number = true;
        }

        const agent = this.localStorageService.retrieve('currentAgent');

        switch (data.contact.Type) {
            case 'CallContactEvent':
                break;
            case 'EmailContactEvent':
                if (data.contact.IsInbound == 'False') {
                    if (agent) {
                        obj.emailaddress1 = agent.userName;
                    }
                } else {
                    obj.emailaddress1 = data.contact.FromAddress;
                }
                break;
            default:
                break;
        }

        return obj;
    }

    // private getRequestOptionsHeader(): RequestOptions {
    //     let header = new Headers({
    //         dynamicsToken: this.selectedCrm.token,
    //         crmwebapipath: this.crmwebapipath,
    //         crmorg: this.crmorg,
    //     });
    //     let options = new RequestOptions({headers: header});

    //     console.log('Dynamics Token in dynamics API Call: ' + this.selectedCrm.token);
    //     return options;
    // }

    private getRequestOptionsHeader(headers: HttpHeaders): HttpHeaders {
        headers = headers.append('dynamicsToken', this.selectedCrm.token);
        headers = headers.append('crmwebapipath', this.crmwebapipath);
        headers = headers.append('crmorg', this.crmorg);

        console.log('Dynamics Token in dynamics API Call: ' + this.selectedCrm.token);
        return headers;
    }

    createTicket(data: any, context: any): Subject<any> {
        const subject = new Subject();
        const postTicket = (u: any) => {
            data.user = u;
            const ticket = this.createTicketObject(data, context);
            // let options = this.getRequestOptionsHeader();

            this.baseApi.POST_Request(`${apiUrl}/incidents`, null, null, ticket, (headers: HttpHeaders) => this.getRequestOptionsHeader(headers));
            // this.http.post(apiUrl + '/incidents', ticket, options).subscribe(
            //     res => {
            //         let obj;
            //         //obj.ticket = res.json();
            //         subject.next(res.json().results);
            //     },
            //     error => {
            //         console.log(error);
            //     },
            // );
        };
        if (!data.user) {
            //var user = this.createUserObject(data.contact);
            this.createUser(data, context).subscribe(result => {
                let user;
                try {
                    user = result.json();
                } catch (error) {
                    user = result;
                }
                data.user = user;
                postTicket(data.user);
            });
        } else {
            postTicket(data.user);
        }
        return subject;
    }

    private createTicketNote(ticket: any, notes: string, data: any): Subject<any> {
        const subject = new Subject();
        let contactType = '';

        const obj: any = {
            subject: 'New Comment',
            description: notes,
            'regardingobjectid_incident@odata.bind': '/incidents(' + ticket.incidentid + ')',
            //'regardingobjectid_incident_email@odata.bind': '/incidents(' + ticket.incidentid + ')',
            activitypointer_activity_parties: [
                {
                    'partyid_account@odata.bind': '/accounts(' + ticket.customerid_account.accountid + ')',
                    participationtypemask: '1',
                },
                {
                    'partyid_account@odata.bind': '/accounts(' + ticket.customerid_account.accountid + ')',
                    participationtypemask: '2',
                },
            ],
        };

        // let obj: any = {
        //     'subject': 'New Comment',
        //     'description': notes,
        //     'regardingobjectid_incident': '/incidents(' + ticket.incidentid + ')',
        //     'accountid':ticket.customerid_account.accountid
        // };

        try {
            switch (data.contact.Type) {
                case 'CallContactEvent':
                    contactType = 'phonecalls';
                    break;
                case 'EmailContactEvent':
                    contactType = 'emails';
                    break;
                default:
                    contactType = 'tasks';
                    break;
            }

            this.baseApi
                .POST_Request(apiUrl + '/incident-activity', null, {contactType}, obj, (headers: HttpHeaders) => this.getRequestOptionsHeader(headers))
                .subscribe(
                    res => {
                        //obj.ticket = res.json();
                        subject.next(res);
                    },
                    error => {
                        subject.error(this.handleError('ticket-note', error));
                    },
                );

            // let options = this.getRequestOptionsHeader();
            // this.http.post(apiUrl + '/incident-activity?contactType=' + contactType, obj, options).subscribe(
            //     res => {
            //         let obj;
            //         //obj.ticket = res.json();
            //         subject.next(res.json());
            //     },
            //     error => {
            //         subject.error(this.handleError('ticket-note', error));
            //     },
            // );
        } catch (error) {
            throw this.handleError('ticket-note', error);
        } finally {
            return subject;
        }
    }

    createUser(data: any, context: any): Subject<any> {
        const subject = new Subject();
        const userObj = this.createUserObject(data, context);
        try {
            this.baseApi
                .POST_Request(`${apiUrl}/accounts`, null, null, userObj, (headers: HttpHeaders) => this.getRequestOptionsHeader(headers))
                .subscribe(
                    res => {
                        let obj;
                        //obj.ticket = res.json();
                        subject.next(res);
                    },
                    error => {
                        subject.error(this.handleError('user', error));
                    },
                );

            // let options = this.getRequestOptionsHeader();
            // this.http.post(apiUrl + '/accounts', userObj, options).subscribe(
            //     res => {
            //         let obj;
            //         //obj.ticket = res.json();
            //         subject.next(res.json());
            //     },
            //     error => {
            //         subject.error(this.handleError('user', error));
            //     },
            // );
        } catch (error) {
            throw this.handleError('user', error);
        } finally {
            return subject;
        }
    }

    searchTicket(data: any, context: any): Subject<any> {
        const obs = new Subject<any>();
        const id = data.user.id; //'5ba6e5b9-88df-e311-b8e5-6c3be5a8b200';

        this.baseApi
            .GET_Request<any>(apiUrl + '/incidents?where=customerid_account/accountid eq ' + id + ' and statecode eq 0', null, null, (headers: HttpHeaders) =>
                this.getRequestOptionsHeader(headers),
            )
            .subscribe(
                function (result) {
                    const tickets = result;
                    tickets.count = tickets.results.length;
                    // ticket.id = ticket.incidentid;
                    obs.next(tickets);
                },
                error => {
                    console.log(error);
                },
            );
        // let options = this.getRequestOptionsHeader();
        // this.http
        //     .get(apiUrl + '/incidents?where=customerid_account/accountid eq ' + id + ' and statecode eq 0', options)
        //     .subscribe(
        //         function (result) {
        //             let tickets = result.json();
        //             tickets.count = tickets.results.length;
        //             // ticket.id = ticket.incidentid;
        //             obs.next(tickets);
        //         },
        //         error => {
        //             console.log(error);
        //         },
        //     );

        return obs;
    }

    searchUser(data: any, context: any): Subject<any> {
        const me = this;
        //let headers = new Headers({ 'Access-Control-Allow-Origin': 'http://localhost/4200', 'Origin':'http://localhost/4200',
        //'Access-Control-Allow-Headers':'Origin, X-Requested-With, Content-Type, Accept' });

        //let options = new RequestOptions({ headers: headers });
        const obs = new Subject<any>();
        let searchField = context.configMap.zendeskSearchField;
        const searchOperator = 'eq'; //context.configMap.zendeskSearchOperator,
        const value = data.contact[context.configMap.incontactSearchField]; // 8136064482;

        searchField = this.getDynamicsSearchFieldName(searchField);

        // debugger;

        Microsoft.CIFramework.searchAndOpenRecords(
            'contact',
            `?$select=fullname,telephone1&$filter=telephone1 eq '` + `${value}` + `'` + '&$search=' + `${value}`,
        ).then(
            result => {
                const res = JSON.parse(result);
                console.log('search result: ', res);
                // console.log(`Record values: Full Name: ${res[0].fullname}, Telephone Number: ${res[0].telephone1}`);
                // perform operations on record retrieval and opening
            },
            error => {
                console.log(error.message);
                // handle error conditions
            },
        );

        // this.baseApi
        //     .GET_Request<any>(apiUrl + '/accounts?where=' + searchField + ' ' + searchOperator + " '" + value + "'", null, null, (headers: HttpHeaders) => {
        //         return this.getRequestOptionsHeader(headers);
        //     })
        //     .subscribe(
        //         userData => {
        //             let users = userData;
        //             users.count = users.results.length;
        //             obs.next(users);
        //         },
        //         error => {
        //             console.log(error);
        //         },
        //     );

        // let options = this.getRequestOptionsHeader();
        // this.http
        //     .get(apiUrl + '/accounts?where=' + searchField + ' ' + searchOperator + " '" + value + "'", options)

        //     .subscribe(
        //         (userData: Response) => {
        //             let users = userData.json();
        //             users.count = users.results.length;
        //             obs.next(users);
        //         },
        //         error => {
        //             console.log(error);
        //         },
        //     );
        return obs;
    }

    private getTicketById(Id) {
        const obs = new Subject<any>();

        this.baseApi
            .GET_Request<any>(apiUrl + `/incidents?where=ticketnumber eq '` + Id + `'`, null, null, (headers: HttpHeaders) =>
                this.getRequestOptionsHeader(headers),
            )
            .subscribe(
                result => {
                    const ticket = result.results[0];
                    obs.next(ticket);
                },
                error => {
                    console.log(error);
                },
            );

        // let options = this.getRequestOptionsHeader();
        // //ticketnumber
        // //this.http.get(apiUrl + "/incidents?where=incidentid eq " + Id, options)
        // this.http.get(apiUrl + "/incidents?where=ticketnumber eq '" + Id + "'", options).subscribe(
        //     function (result) {
        //         let ticket = result.json().results[0];
        //         obs.next(ticket);
        //     },
        //     error => {
        //         console.log(error);
        //     },
        // );

        return obs;
    }

    openTicket(ticket: any): Subject<any> {
        const obs = new Subject<any>();
        const screenpopupUrl = decodeURIComponent(ticket.screenpopupUrl);
        if (screenpopupUrl) {
            if (global) {
                if (global.hostOpenUrl) {
                    hostOpenUrl(screenpopupUrl);
                } else {
                    window.open(screenpopupUrl);
                }
                obs.next(ticket);
            }
        }
        return obs;
    }

    openUser(user: any) {
        if (user.screenpopupUrl) {
            if (global) {
                console.log('Global object: ' + global);
            } else {
                console.log('No Global object');
            }

            if (global) {
                if (global.hostOpenUrl) {
                    hostOpenUrl(user.screenpopupUrl);
                } else {
                    window.open(user.screenpopupUrl);
                }
            }
        }
    }

    alwaysCreateUser(data, context) {
        this.createUser(data, context).subscribe(result => {
            let user;
            try {
                user = result.json();
            } catch (error) {
                user = result;
            }
            data.user = user;
            this.openUser(data.user);
        });
    }

    private updateTicket(ticketId, data): Subject<any> {
        const obs = new Subject<any>();

        this.baseApi
            .PATCH_Request<any>(apiUrl + '/incidents?id=' + ticketId, null, null, data, (headers: HttpHeaders) => this.getRequestOptionsHeader(headers))
            .subscribe(
                function (result) {
                    const ticket = result.json();
                    obs.next(ticket);
                },
                error => {
                    console.log(error);
                },
            );
        // let options = this.getRequestOptionsHeader();
        // this.http.patch(apiUrl + '/incidents?id=' + ticketId, data, options).subscribe(
        //     function (result) {
        //         let ticket = result.json();
        //         obs.next(ticket);
        //     },
        //     error => {
        //         console.log(error);
        //     },
        // );

        return obs;
    }

    handleError(type: string, error: any) {
        switch (type) {
            case 'user':
                if (error.responseJSON.details.email[0] !== undefined) {
                    this.servicesContainer.DataContainerService.handleError(
                        new Error('Ticket not created: ' + error.responseJSON.details.email[0].description),
                    );
                }
                break;
            case 'notify':
                this.servicesContainer.DataContainerService.handleError(new Error('Ticket not created: ' + error));
        }
    }

    getDynamicsSearchFieldName(searchField: string) {
        let field = '';
        if (searchField === 'phone') {
            field = 'telephone1';
        }
        if (searchField === 'email') {
            field = 'emailaddress1';
        }

        return field;
    }

    private getTicketNoteString(data: any, context: any): string {
        const me = this;
        let linefeed = '\n';

        if (data.contact.Type == 'EmailContactEvent') {
            linefeed = '<br/>';
        }

        let htmlstr = '**' + context.configMap.contactTitle + ' Details:**' + linefeed + '---' + linefeed;

        if (context.mappings) {
            const commentMappings = context.mappings.filter(item => item.addToComments == true);
            for (let j = 0; j < commentMappings.length; j++) {
                if (commentMappings[j].name === '_recordingFilePath' && data.contact._recordingFilePath) {
                    htmlstr += linefeed + '**' + commentMappings[j].label + '**: ' + data.contact._recordingFilePath;
                } else if (commentMappings[j].name === '_starttime' && data.contact._starttime) {
                    htmlstr += linefeed + '**' + commentMappings[j].label + ':** ' + data.contact._starttime;
                    //this.fcc.common.getFormatedDateTime(new Date(contact.StartTime).valueOf());
                } else if (commentMappings[j].name === '_duration' && data.contact._duration) {
                    htmlstr += linefeed + '**' + commentMappings[j].label + '**: ' + data.contact._duration;
                } else {
                    if (data.contact[commentMappings[j].name]) {
                        htmlstr += linefeed + commentMappings[j].label + '**: ' + data.contact[commentMappings[j].name];
                    }
                }
            }
        }

        let sep = linefeed + linefeed + '-------------------------{title}-------------------------------' + linefeed + linefeed;
        if (data.contact.Type == 'EmailContactEvent') {
            sep = sep.replace('{title}', '|Email Text|');
            htmlstr += sep;

            //Add subject
            if (data.contact.subject) {
                htmlstr += linefeed + ' Subject: ' + data.contact.subject + linefeed;
            } else if (data.contact.Subject) {
                htmlstr += linefeed + ' Subject: ' + data.contact.Subject + linefeed;
            }

            //Add Email Body
            if (data.contact.bodyHtml) {
                const StrippedString = data.contact.bodyHtml.replace(/(<([^>]+)>)/gi, '');
                htmlstr += linefeed + StrippedString;
            }
            if (data.contact.Body) {
                htmlstr += linefeed + data.contact.Body;
            } else if (data.contact.BodyHTML) {
                htmlstr += linefeed + data.contact.BodyHTML;
            }
        } else if (data.contact.Type == 'ChatContactEvent') {
            if (this.servicesContainer.DataContainerService.CurrentContacts.chatRoom) {
                sep = sep.replace('{title}', '|Chat Transcript|');
                htmlstr += sep;
                const chatRoom = this.servicesContainer.DataContainerService.CurrentContacts.chatRoom;
                chatRoom.chatMessages.forEach(function (msg, index) {
                    htmlstr += linefeed + msg.Label + ' @ ' + me.ConvertToLocalDate(new Date(msg.TimeStamp));
                    htmlstr += linefeed + msg.Text;
                    htmlstr += linefeed;
                });
            }
        }
        return htmlstr;
    }

    notify(action, message) {
        //window.zClient.invoke('notify', message);
        this.handleError(action, message);
    }
}
