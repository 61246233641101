import {NgModule} from '@angular/core';
import {DexieLogsService} from './services';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [DexieLogsService],
})
export class DexieLogsModule {}

export * from './dexie.db';
