<div id="address_book">
    <ul *ngIf="false" class="list-group">
        <li
            *ngFor="let bookcontact of bookcontacts | filter: 'addressbook':'':keyword; let i = index"
            class="list-group-item list-item-uac"
            (click)="returnCall(bookcontact.phone)"
        >
            <a tabindex="i" #listItem name="listItem" role="listitem" href="javascript:void(0)">
                <h5 class="list-group-item-heading">{{ bookcontact.firstName }} {{ bookcontact.lastName }}</h5>
                <p *ngIf="bookcontact.mobile !== ''" class="list-group-item-text item-text">
                    <i class="fa fa-mobile" aria-hidden="true"></i> {{ bookcontact.mobile }}
                </p>
                <p *ngIf="bookcontact.phone !== ''" class="list-group-item-text item-text">
                    <i class="fa fa-phone" aria-hidden="true" (click)="returnCall(bookcontact.phone)"></i>
                    {{ bookcontact.phone }}
                </p>
                <p *ngIf="bookcontact.email !== ''" class="list-group-item-text item-text">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i> {{ bookcontact.email }}
                </p>
            </a>
        </li>
    </ul>
    <ul class="list-group">
        <li
            *ngFor="let bookcontact of bookcontacts | filter: 'addressbook':'':keyword; let i = index"
            class="list-group-item list-item-uac"
        >
            <h5 class="list-group-item-heading">{{ bookcontact.firstName }} {{ bookcontact.lastName }}</h5>
            <ul class="list-inline">
                <li>
                    <div *ngIf="bookcontact.mobile !== ''" class="dropdown">
                        <button
                            class="btn btn-secondary btn-sm dropdown-toggle"
                            type="submit"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                        >
                            <i class="fa fa-mobile" aria-hidden="true"></i> {{ bookcontact.mobile }}
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li class="dropdown-item" *ngFor="let skill of agent.outboundPhoneSkills">
                                <a
                                    style="color: #444; line-height: 20px;"
                                    aria-labelledby="skill"
                                    role="link"
                                    href="javascript:void(0)"
                                    class="scrollable-menu-uac-items"
                                    (click)="dialPhone(bookcontact.mobile, skill.skillId)"
                                    >{{ skill.skillName }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div *ngIf="bookcontact.phone !== ''" class="dropdown">
                        <button
                            class="btn btn-secondary btn-sm dropdown-toggle"
                            type="submit"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                        >
                            <i class="fa fa-phone" aria-hidden="true"></i> {{ bookcontact.phone }}
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li class="dropdown-item" *ngFor="let skill of agent.outboundPhoneSkills">
                                <a
                                    style="color: #444; line-height: 20px;"
                                    aria-labelledby="skill"
                                    role="link"
                                    href="javascript:void(0)"
                                    class="scrollable-menu-uac-items"
                                    (click)="dialPhone(bookcontact.phone, skill.skillId)"
                                    >{{ skill.skillName }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div *ngIf="bookcontact.email !== ''" class="dropdown">
                        <button
                            class="btn btn-secondary btn-sm dropdown-toggle"
                            type="submit"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                        >
                            <i class="fa fa-envelope-o" aria-hidden="true"></i> {{ bookcontact.email }}
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li class="dropdown-item" *ngFor="let skill of agent.outboundEmailSkills">
                                <a
                                    style="color: #444; line-height: 20px;"
                                    aria-labelledby="skill"
                                    role="link"
                                    href="javascript:void(0)"
                                    class="scrollable-menu-uac-items"
                                    (click)="createNewOutboundEmailContact(skill.skillId, bookcontact.email)"
                                    >{{ skill.skillName }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>
