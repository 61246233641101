import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// import { HttpModule, Http } from '@angular/http';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {QueueRoutingModule} from './queue.routing';
import {QueueComponent} from './queue.component';
import {QueueService} from './queue.service';

import {SharedModule} from '../../shared/shared.module';
import {HighlightDirective} from '../../shared/directives/media-type.directive';

// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';

@NgModule({
    imports: [FormsModule, CommonModule, BrowserModule, QueueRoutingModule, SharedModule],
    declarations: [QueueComponent, HighlightDirective],
    exports: [QueueComponent],
    providers: [QueueService],
})
export class QueueModule {}
