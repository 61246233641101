import {
    Component,
    OnInit,
    Input,
    Output,
    AfterContentInit,
    ContentChild,
    ElementRef,
    AfterViewChecked,
    AfterViewInit,
    ViewChild,
    ViewChildren,
} from '@angular/core';

import {HostListener} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';

import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {CallContactService} from '../.././core/_services/call-contact.service';
import {Agent} from '../.././core/_models/agent.model';
import {AgentSkillAssignmentsModel} from '../.././core/InContact/models/agent-skill-assignments.model';
import {SkillsModel} from '../.././core/InContact/models/skills.model';
import {ErrorModel} from '../.././core/_models/error.model';
import {MessageService} from '../.././core/_services/message.service';
import {CallContactModel} from '../../core/_models/call-contact';
import {BsDropdownContainerComponent, BsDropdownDirective, BsDropdownToggleDirective} from 'ngx-bootstrap/dropdown';
import {debounceTime} from 'rxjs/operators';

declare let $: JQueryStatic;

@Component({
    selector: 'app-dialpad',
    // host: {'(window:keydown)': 'hotkeys($event)'},
    templateUrl: './dialpad.component.html',
    styleUrls: ['./dialpad.component.css'],
})
export class DialpadComponent implements OnInit, AfterViewInit {
    //public outboundSkills:AgentSkillAssignmentsModel[];
    private inContactError: ErrorModel;
    public ErrorMsg: string;

    calls: CallContactModel[];

    @ViewChild('skillsDropdownActivator') skillsDropdownActivator: ElementRef;
    @ViewChildren('dialnumber') vc;
    @ViewChild('outboundSkills') outboundSkillDropdown: ElementRef;

    public dialedNumber: string;
    public agent: Agent;

    public queryParams: Params = null;

    constructor(
        private agentSessionEventsService: IcAgentSessionService,
        private messageService: MessageService,
        public callContactService: CallContactService,
        private route: ActivatedRoute,
    ) {
        this.agent = new Agent();
        this.agent.outboundSkills = [];
    }

    ngOnInit() {
        this.callContactService.currentContacts.subscribe(res => {
            this.calls = res;
        });

        this.dialedNumber = '';

        this.route.queryParams.subscribe(query => {
            this.queryParams = query;
            if (query.phoneNumber !== undefined) {
                this.dialedNumber = query.phoneNumber;
            }
        });
        this.route.params.subscribe(params => {
            if (params.phoneNumber !== undefined) {
                this.dialedNumber = params.phoneNumber;
            }
        });

        this.agentSessionEventsService.agentOutboundSkills.pipe(debounceTime(1000)).subscribe(skills => {
            this.agent.outboundSkills = skills.filter(
                e => e.isActive === 'True' && e.isSkillActive === 'True' && e.mediaTypeId === '4' && e.isNaturalCalling === 'False',
            );

            if (
                this.dialedNumber &&
                this.queryParams &&
                (this.queryParams.fromKustomer === '1' || this.queryParams.fromZendesk === '1' || this.queryParams.fromDynamics === '1')
            ) {
                if (this.agent.outboundSkills.length === 1) {
                    this.callContactService.dialPhone(this.dialedNumber, this.agent.outboundSkills[0].skillId as any);
                } else {
                    setTimeout(() => {
                        this.openDropdown();
                    }, 2000);
                }
            }
        });

        this.messageService.setWindowTitle.next('Dialer');
    }

    //
    ngAfterViewInit() {
        this.vc.first.nativeElement.focus();
        this.agentSessionEventsService.agentId.subscribe(agentId => {
            this.agentSessionEventsService.getAgentSkillAssignment(agentId);
        });
    }

    dialDigit(digit: string) {
        this.dialedNumber += digit;
        if (this.callContactService.count > 0) {
            this.callContactService.sendDtmf('' + digit, '350', '0');
        }
    }
    /*
    dialPhone(skill: AgentSkillAssignmentsModel) {
      let call = this.calls[0];
      if (call && call.status !== 'Holding') {
        this.callContactService.holdContact(call)
          .subscribe(res => {

            this.callContactService.dialPhone(this.number, skill.skillName);
          });
      } else {

        this.callContactService.dialPhone(this.number, skill.skillName);
      }
      //this.callContactService.dialPhone(this.number, skill.skillName);
    }
   */
    onBackspace() {
        this.dialedNumber = this.dialedNumber.slice(0, this.dialedNumber.length - 1);
    }

    onKey(event: KeyboardEvent) {}

    doSomething($event: KeyboardEvent) {
        if ($event.code === 'd') {
            this.toggleDropdown();
        } else {
            this.toggleDropdown();
        }
    }

    public isopen: boolean;
    //public status: {isopen: boolean} = {isopen: false};

    openDropdown() {
        if (!this.isopen) {
            this.skillsDropdownActivator.nativeElement.click();
            this.isopen = true;
        }
    }

    toggleDropdown(): void {
        this.isopen = !this.isopen;

        setTimeout(() => {
            const ele = $(this.outboundSkillDropdown.nativeElement).find('li:first').find('a');
            if (ele.length > 0) {
                ele[0].focus();
            }
        }, 500);
    }

    @HostListener('window:keydown', ['$event'])
    hotkeys(event: KeyboardEvent) {
        let key;
        let isShift;
        let isAlt;

        if (window.event) {
            key = event.keyCode;
            isShift = !!event.shiftKey; // typecast to boolean
            isAlt = !!event.altKey;
        } else {
            key = event.which;
            isShift = !!event.shiftKey;
            isAlt = !!event.altKey;

            if (isAlt) {
                event.preventDefault();
            }
        }
        if (isAlt) {
            switch (key) {
                case 18:
                    event.preventDefault();
                    break;
                case 68:
                    this.toggleDropdown();
                    event.preventDefault();
                    break;
            }
        }
    }
}
