import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

//import { AppComponent } from './app.component';

import {AboutRoutingModule} from './about/about.routing';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'active-contact',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: false}), AboutRoutingModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
