import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {UacCrmIntegrationComponent} from './uac-crm-integration.component';
import {integrationServicesContainer} from './integrationServicesContainer.service';
import {SelectCrmModalComponent} from './select-crm-form/select-crm.component';
import {SharedModule} from 'src/app/shared/shared.module';

// import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';

// export function createTranslateLoader(http: Http) {
//     return new TranslateStaticLoader(http, './assets/i18n', '.json');
// }

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        // TranslateModule.forRoot({
        //     provide: TranslateLoader,
        //     useFactory: createTranslateLoader,
        //     deps: [Http],
        // }),
    ],
    declarations: [UacCrmIntegrationComponent, SelectCrmModalComponent],
    providers: [integrationServicesContainer],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [UacCrmIntegrationComponent],
})
export class UacCrmIntegrationModule {}
