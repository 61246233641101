import {catchError, map, mergeMap, retry} from 'rxjs/operators';
import {ContactBase} from 'src/app/core/_models/contact-base';
import {Observable, Subject, BehaviorSubject, Observer, of} from 'rxjs';
// import {LocalStorageService} from 'ng2-webstorage/dist/services';
import {ContactViewBaseModel} from 'src/app/core/_models/_viewmodels/contact-view-base.model';
import {ApplicationContextService} from '../application-context.service';
import {LocalStorageService} from 'ngx-webstorage';
import {MessageService} from '../message.service';
import {CrmDataHolderViewModel} from '../../_models/crm';
import {EmailContactViewmodel} from '../../_models/_viewmodels/email-contact-viewmodel';
import {parseQueryString} from 'src/app/global';
import {DexieLogsService} from 'src/app/sdk/dexie/services';

export type CustomSearchResult = {
    foundTicket: boolean;
    foundUser: boolean;
};

export class CrmServiceBase {
    protected crmDataHolder: CrmDataHolderViewModel = null;
    // private localStorageService: LocalStorageService;
    private handledCotnacts: Array<{contactId: string; ticketId: string}> = [];

    contactVM: ContactViewBaseModel<any & ContactBase>;

    public initDone$: Subject<boolean>;

    protected subjects$: {[key: string]: Subject<any>} = {};

    public data: CrmDataHolderViewModel = new CrmDataHolderViewModel({
        processed: false,
        zendeskUserResult: null,
        ticketResult: null,
        zendeskUser: null,
    });

    constructor(protected localStorageSrv: LocalStorageService, protected messagesSrv: MessageService, protected dexieLogsSrv: DexieLogsService) {
        this.initDone$ = new Subject();
        // this.localStorageService = new LocalStorageService();
        this.init();
    }

    public on(eventName: string, callback: Function) {
        // console.log('registering: ', eventName);
        if (!callback) {
            callback = (a: any) => {};
        }
        const sub = this.subjects$[eventName];
        if (sub) {
            sub.subscribe(obs => {
                callback(obs);
            });
        }
    }

    public get CrmUsersList() {
        if (this.data.zendeskUserResult?.count > 0) {
            return this.data.zendeskUserResult.results;
        }
        if (this.data.dynamicsUserResult?.length > 0) {
            return this.data.dynamicsUserResult;
        }

        return [];
    }

    public get CrmTicketsList() {
        if (this.data.ticketResult?.count > 0) {
            return this.data.ticketResult.results;
        }
        if (this.data.dynamicsCaseResult?.length > 0) {
            return this.data.dynamicsCaseResult;
        }

        return [];
    }

    protected init(): any {
        this.initDone$.next(true);
        this.initDone$.complete();
    }

    public onNumberDialed() {
        //
    }

    private updateProcessedContacts() {
        this.localStorageSrv.store('handledContacts', this.handledCotnacts);
    }

    public updateEmailContactUserData(email: EmailContactViewmodel) {}

    getProcessedContact(contactVM: ContactViewBaseModel<ContactBase>) {
        this.handledCotnacts = this.localStorageSrv.retrieve('handledContacts') || new Array();
        const found = this.handledCotnacts.find(
            c => `${c.contactId}` == `${contactVM.contact.contactId}` || `${c.contactId}` == `${contactVM.contact.omniGroupId}`,
        );
        if (found) {
            return found;
        }
        return null;
    }

    protected removeHandledContact(contactId: string) {
        const index = this.handledCotnacts.findIndex(c => c.contactId == contactId);
        if (index > -1) {
            this.handledCotnacts.splice(index, 1);
            this.updateProcessedContacts();
        }
    }

    protected addToProcessedContacts(contactVM: ContactViewBaseModel<ContactBase>) {
        /* if (!contactVM.contact.ticketId)
            return; */
        const index = this.handledCotnacts.findIndex(c => c.contactId == contactVM.contact.contactId);
        const obj = {contactId: contactVM.contact.contactId, ticketId: contactVM.contact.ticketId};
        if (index > -1) {
            this.handledCotnacts[index] = obj;
        } else {
            this.handledCotnacts.push(obj);
        }
        this.updateProcessedContacts();
    }

    public onContactTicketIdAddition(contactVM: ContactViewBaseModel<ContactBase>) {
        this.addToProcessedContacts(contactVM);
    }

    public isProcessedContact(contact: ContactBase): boolean {
        return this.handledCotnacts.some(e => e.contactId == contact.contactId);
    }

    replaceTokensWithValues(str: string, data: any, context: any): string {
        let val = '';

        if (str) {
            const regex = /\$\{([^}]+)\}/g;
            const subStrRegex = /\$sub\{([^}]+)\}/g;
            //var str = "I expect five hundred dollars ${500}. and ${300}";

            let m;

            val = str.replace(regex, function (a, b) {
                const v = data.contact[b];
                if (v) {
                    return v;
                } else {
                    return a;
                }
            });

            val = val.replace(subStrRegex, function (a, b) {
                const parts = b.split(':');
                const subStrParts = parts[1].split('-');
                const startIndex = parseInt(subStrParts[0], 10);
                const length = parseInt(subStrParts[1], 10);
                const v = data.contact[parts[0]];
                if (v) {
                    return v.substring(startIndex, length);
                } else {
                    return a;
                }
            });
        }

        return val;
    }

    public processIncomingCallContact(contctVM: ContactBase) {
        //
    }

    public processEndedCallContact(contactVM: ContactBase) {
        //
    }

    processContact(contactVM: ContactViewBaseModel<ContactBase>) {
        this.contactVM = contactVM;

        this.dexieLogsSrv.info('processContact', {contact: contactVM?.contact?.rawData});
        /* let settings = context.settings;
        let mappings = context.mappings;
        let contact = data.contact; */

        const settings = contactVM.settings ?? {};

        this.data = new CrmDataHolderViewModel({
            processed: false,
            zendeskUserResult: null,
            ticketResult: null,
            zendeskUser: null,
        });

        let process: Observable<any> = new Observable(obsv => {
            obsv.next(this.data);
            // obsv.complete();
        });

        this.dexieLogsSrv.info('ticketPayload', {contact: contactVM?.contact?.ticketPayload, isProcessable: contactVM.isCrmProcessable()});
        if (contactVM.contact.ticketPayload?.ticketId) {
            // console.log('contact vm', contactVM.contact.ticketPayload);
            // contactVM.contact.ticketPayload.ticketId = 7449;
            // this.data.ticket = {id: contactVM.contact.ticketPayload.ticketId};
            process = process.pipe(
                mergeMap((v, d) => this.getCrmTicket(contactVM, this.data, contactVM?.contact?.ticketPayload?.ticketId)),
                mergeMap((v, d) => this.OpenCrmTicket(contactVM, this.data)),
            );

            process.subscribe(r => {
                if (this.data.ticket) {
                    // contactVM.contact.ticketId = 'testing_123';
                    contactVM.contact.ticketId = `${this.data.ticket.id}`;

                    (contactVM as any).ticketId = this.data.ticket.id;

                    contactVM.contactService.currentContactViewModels.value
                        .filter(x => x.contact.contactId == contactVM.contact.contactId)
                        .forEach(x => {
                            x.contact.ticketId = this.data.ticket.id;
                        });

                    /*  setTimeout(() => {
                     this.processContact(contactVM);
                 }, 5000); */
                }
                this.addToProcessedContacts(contactVM);
            });
        } else if (!contactVM.isCrmProcessable()) {
            return;
        } else {
            const obj = this.getProcessedContact(contactVM);

            if (obj) {
                if (!contactVM.contact.ticketId || `${contactVM.contact.ticketId}`.trim().length == 0) {
                    this.data.ticket = {id: obj.ticketId};
                    contactVM.contact.ticketId = obj.ticketId;
                    //process = process.flatMap((v, d) => this.OpenCrmTicket(contactVM, this.data));
                } else if (contactVM.contact.ticketId && `${contactVM.contact.ticketId}`.trim().length > 0) {
                    contactVM.contact.ticketId = obj.ticketId;
                    this.data.ticket = {id: contactVM.contact.ticketId};
                    //process = process.flatMap((v, d) => this.OpenCrmTicket(contactVM, this.data));
                }
                return;
            } else {
                this.addToProcessedContacts(contactVM);

                let customSearch = false;

                const screenPopUrl = contactVM.contact.ScreenPopUrl;

                if (!!screenPopUrl && screenPopUrl.includes('ScreenPopPrintVars')) {
                    const parts = screenPopUrl.split('?');
                    if (parts.length === 2) {
                        const params = parseQueryString(parts[1]);
                        if (Object.keys(params).length > 0) {
                            contactVM.contact.ScreenPopUrlVariables = [params];
                        }
                    }
                }

                const customSearchResult: CustomSearchResult = {foundUser: false, foundTicket: false};
                const customSearchPromise = new Promise(async (resolve: (sRes: CustomSearchResult) => void, reject) => {
                    // console.log('custom search: ', contactVM.contact.ScreenPopUrlVariables);
                    if (contactVM.contact.ScreenPopUrlVariables?.length) {
                        for (const popVar of contactVM.contact.ScreenPopUrlVariables) {
                            for (const key of Object.keys(popVar)) {
                                const result = await this.customCrmSearch(contactVM, popVar[key], this.data).toPromise();

                                // console.log(result, this.data);

                                if (result.found) {
                                    customSearch = true;
                                    if (result.type === 'ticket') {
                                        // process = process.pipe(
                                        // mergeMap((v, d) =>
                                        this.OpenCrmTicket(contactVM, this.data)
                                            .pipe(
                                                map(x => {
                                                    this.dexieLogsSrv.info('OpenCrmTicket0', {data: this.data});
                                                    return x;
                                                }),
                                            )
                                            .subscribe();
                                        // ),
                                        // );

                                        customSearchResult.foundTicket = true;
                                        // resolve(customSearchResult);
                                        return;
                                    } else if (result.type === 'user') {
                                        customSearchResult.foundUser = true;
                                        // process = process.pipe(
                                        // mergeMap((v, d) =>
                                        this.OpenCrmUser(contactVM, this.data)
                                            .pipe(
                                                map(x => {
                                                    this.dexieLogsSrv.info('OpenCrmUser0', {data: this.data});
                                                    return x;
                                                }),
                                            )
                                            .subscribe();
                                        //     ),
                                        // );
                                    }
                                }
                            }
                        }
                    }
                    resolve(customSearchResult);
                });

                customSearchPromise.then(customSearchPromiseResult => {
                    this.dexieLogsSrv.info('customSearchPromise', {customSearchPromiseResult, data: this.data, settings});

                    console.log(customSearchPromiseResult, this.data, settings);
                    if (!customSearchPromiseResult.foundTicket) {
                        if (settings.creatTicketAlways) {
                            //Priority 1
                            process = process.pipe(
                                mergeMap((v, d) => {
                                    if (!this.data.zendeskUser) {
                                        if (settings.searchUser) {
                                            return this.findCrmUser(contactVM, this.data).pipe(
                                                map(x => {
                                                    this.dexieLogsSrv.info('findCrmUser', {data: this.data});
                                                    return x;
                                                }),
                                                catchError(
                                                    err =>
                                                        new Observable(r => {
                                                            r.next(this.data);
                                                            r.complete();
                                                        }),
                                                ),
                                            );
                                        } else {
                                            return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                mergeMap((v1, d1) =>
                                                    this.createCrmUser(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('createCrmUser', {data: this.data});
                                                            return x;
                                                        }),
                                                        catchError(
                                                            err =>
                                                                new Observable(r => {
                                                                    r.next(this.data);
                                                                    r.complete();
                                                                }),
                                                        ),
                                                    ),
                                                ),
                                            );
                                        }
                                    } else {
                                        return new Observable(sub => {
                                            sub.next(this.data);
                                            sub.complete();
                                        });
                                    }
                                }),
                                mergeMap((v, d) => {
                                    if (this.data.zendeskUser) {
                                        if (settings.creatUserAlways) {
                                            return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                mergeMap((v1, d1) =>
                                                    this.createCrmUser(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('createCrmUser1', {data: this.data});
                                                            return x;
                                                        }),
                                                        catchError(
                                                            err =>
                                                                new Observable(r => {
                                                                    r.next(this.data);
                                                                    r.complete();
                                                                }),
                                                        ),
                                                    ),
                                                ),
                                                mergeMap((v1, d1) => this.buildCrmTicketObject(contactVM, this.data)),
                                                mergeMap((v2, d2) =>
                                                    this.createCrmTicket(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('createCrmTicket', {data: this.data});
                                                            return x;
                                                        }),
                                                    ),
                                                ),
                                                mergeMap((v3, d3) =>
                                                    this.OpenCrmTicket(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('OpenCrmTicket', {data: this.data});
                                                            return x;
                                                        }),
                                                    ),
                                                ),
                                            );
                                        } else {
                                            return this.buildCrmTicketObject(contactVM, this.data).pipe(
                                                mergeMap((v1, d1) =>
                                                    this.createCrmTicket(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('createCrmTicket1', {data: this.data});
                                                            return x;
                                                        }),
                                                    ),
                                                ),
                                                mergeMap((v1, d1) =>
                                                    this.OpenCrmTicket(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('OpenCrmTicket1', {data: this.data});
                                                            return x;
                                                        }),
                                                    ),
                                                ),
                                            );
                                        }
                                    } else {
                                        if (this.data.zendeskUserResult && this.data.zendeskUserResult.count > 1) {
                                            if (settings.creatUserAlways) {
                                                return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                    mergeMap((v1, d1) =>
                                                        this.createCrmUser(contactVM, this.data).pipe(
                                                            map(x => {
                                                                this.dexieLogsSrv.info('createCrmUser7', {data: this.data});
                                                                return x;
                                                            }),
                                                            catchError(
                                                                err =>
                                                                    new Observable(r => {
                                                                        r.next(this.data);
                                                                        r.complete();
                                                                    }),
                                                            ),
                                                        ),
                                                    ),
                                                    mergeMap((v1, d1) => this.buildCrmTicketObject(contactVM, this.data)),
                                                    mergeMap((v2, d2) =>
                                                        this.createCrmTicket(contactVM, this.data).pipe(
                                                            map(x => {
                                                                this.dexieLogsSrv.info('createCrmTicket7', {data: this.data});
                                                                return x;
                                                            }),
                                                        ),
                                                    ),
                                                    mergeMap((v3, d3) =>
                                                        this.OpenCrmTicket(contactVM, this.data).pipe(
                                                            map(x => {
                                                                this.dexieLogsSrv.info('OpenCrmTicket7', {data: this.data});
                                                                return x;
                                                            }),
                                                        ),
                                                    ),
                                                );
                                            } else {
                                                return of(this.data);
                                            }

                                            //return this.notifyUser(contactVM, { "Subject": "Multiple Records Found", "MessageText": `${this.data.userResult.count}
                                            // users found against ${contactVM.configMap.incontactSearchField} ${contactVM.getContactPropertyValueByKey(
                                            // contactVM.configMap.incontactSearchField)}` });

                                            // return this.buildCrmUserObject(contactVM, this.data).pipe(
                                            //     mergeMap((v1, d1) =>
                                            //         this.createCrmUser(contactVM, this.data).pipe(
                                            //             map(x => {
                                            //                 this.dexieLogsSrv.info('createCrmUser1', {data: this.data});
                                            //                 return x;
                                            //             }),
                                            //             catchError(
                                            //                 err =>
                                            //                     new Observable(r => {
                                            //                         r.next(this.data);
                                            //                         r.complete();
                                            //                     }),
                                            //             ),
                                            //         ),
                                            //     ),
                                            //     mergeMap((v1, d1) => this.buildCrmTicketObject(contactVM, this.data)),
                                            //     mergeMap((v1, d1) =>
                                            //         this.createCrmTicket(contactVM, this.data).pipe(
                                            //             map(x => {
                                            //                 this.dexieLogsSrv.info('createCrmTicket2', {data: this.data});
                                            //                 return x;
                                            //             }),
                                            //         ),
                                            //     ),
                                            //     mergeMap((v1, d1) =>
                                            //         this.OpenCrmTicket(contactVM, this.data).pipe(
                                            //             map(x => {
                                            //                 this.dexieLogsSrv.info('OpenCrmTicket2', {data: this.data});
                                            //                 return x;
                                            //             }),
                                            //         ),
                                            //     ),
                                            // );
                                        } else {
                                            return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                mergeMap((v1, d1) =>
                                                    this.createCrmUser(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('createCrmUser2', {data: this.data});
                                                            return x;
                                                        }),
                                                        catchError(
                                                            err =>
                                                                new Observable(r => {
                                                                    r.next(this.data);
                                                                    r.complete();
                                                                }),
                                                        ),
                                                    ),
                                                ),
                                                mergeMap((v1, d1) => this.buildCrmTicketObject(contactVM, this.data)),
                                                mergeMap((v1, d1) =>
                                                    this.createCrmTicket(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('createCrmTicket3', {data: this.data});
                                                            return x;
                                                        }),
                                                    ),
                                                ),
                                                mergeMap((v1, d1) =>
                                                    this.OpenCrmTicket(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('OpenCrmTicket3', {data: this.data});
                                                            return x;
                                                        }),
                                                    ),
                                                ),
                                            );
                                        }
                                    }
                                }),
                            );
                        } else {
                            if (!this.data.zendeskUser) {
                                if (settings.creatUserAlways) {
                                    process = process.pipe(
                                        mergeMap((v, d) => this.buildCrmUserObject(contactVM, this.data)),
                                        mergeMap((v, d) =>
                                            this.createCrmUser(contactVM, this.data).pipe(
                                                map(x => {
                                                    this.dexieLogsSrv.info('createCrmUser3', {data: this.data});
                                                    return x;
                                                }),
                                            ),
                                        ),
                                    );
                                } else if (settings.searchUser) {
                                    process = process.pipe(
                                        mergeMap((v, d) => {
                                            if (!this.data.zendeskUser) {
                                                if (settings.searchUser) {
                                                    return this.findCrmUser(contactVM, this.data).pipe(
                                                        map(x => {
                                                            this.dexieLogsSrv.info('findCrmUser1', {data: this.data});
                                                            return x;
                                                        }),
                                                        catchError(err => of(this.data)),
                                                    );
                                                } else {
                                                    return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                        mergeMap((v1, d1) =>
                                                            this.createCrmUser(contactVM, this.data).pipe(
                                                                map(x => {
                                                                    this.dexieLogsSrv.info('createCrmUser4', {data: this.data});
                                                                    return x;
                                                                }),
                                                                catchError(err => of(this.data)),
                                                            ),
                                                        ),
                                                    );
                                                }
                                            } else {
                                                return new Observable(sub => {
                                                    sub.next(this.data);
                                                    sub.complete();
                                                });
                                            }
                                        }),
                                        mergeMap((v, d) => {
                                            if (this.data?.zendeskUserResult?.count == 1) {
                                                this.data.zendeskUser = this.data.zendeskUserResult.results[0];
                                                return this.OpenCrmUser(contactVM, this.data).pipe(
                                                    map(x => {
                                                        this.dexieLogsSrv.info('OpenCrmUser1', {data: this.data});
                                                        return x;
                                                    }),
                                                );
                                                // return Observable.create(r => r.next(contactVM, this.data));

                                                // return new Observable<any>(obr => {
                                                //     obr.next({...this.data});
                                                //     obr.complete();
                                                // });
                                            } else if (this.data.zendeskUserResult && this.data.zendeskUserResult.count > 1) {
                                                return this.notifyUser(contactVM, {
                                                    Subject: 'Multiple Records Found',
                                                    MessageText: `${this.data.zendeskUserResult.count} users found against ${
                                                        contactVM.configMap.incontactSearchField
                                                    } ${contactVM.getContactPropertyValueByKey(contactVM.configMap.incontactSearchField)}`,
                                                });
                                            } else {
                                                if (settings.creatTicketAlways || settings.creatUser) {
                                                    return this.buildCrmUserObject(contactVM, this.data).pipe(
                                                        mergeMap(d1 =>
                                                            this.createCrmUser(contactVM, this.data).pipe(
                                                                map(x => {
                                                                    this.dexieLogsSrv.info('createCrmUser5', {data: this.data});
                                                                    return x;
                                                                }),
                                                            ),
                                                        ),
                                                        mergeMap(d1 =>
                                                            this.OpenCrmUser(contactVM, this.data).pipe(
                                                                map(x => {
                                                                    this.dexieLogsSrv.info('OpenCrmUser2', {data: this.data});
                                                                    return x;
                                                                }),
                                                            ),
                                                        ),
                                                    );
                                                } else {
                                                    return new Observable(sub => {
                                                        sub.next(this.data);
                                                        sub.complete();
                                                    });
                                                }
                                            }
                                        }),
                                    );
                                } else if (settings.creatUser) {
                                    process = process.pipe(
                                        mergeMap((v, d) => this.buildCrmUserObject(contactVM, this.data)),
                                        mergeMap(d =>
                                            this.createCrmUser(contactVM, this.data).pipe(
                                                map(x => {
                                                    this.dexieLogsSrv.info('createCrmUser6', {data: this.data});
                                                    return x;
                                                }),
                                            ),
                                        ),
                                        mergeMap(d =>
                                            this.OpenCrmUser(contactVM, this.data).pipe(
                                                map(x => {
                                                    this.dexieLogsSrv.info('OpenCrmUser3', {data: this.data});
                                                    return x;
                                                }),
                                            ),
                                        ),
                                    );
                                }
                            }

                            if (settings.searchTicket) {
                                process = process.pipe(
                                    mergeMap((v, d) => {
                                        console.log('going to search ticket: ', this.data);
                                        return this.findCrmTicket(contactVM, this.data);
                                    }),
                                    mergeMap(d => {
                                        if (this.data.ticket) {
                                            return this.OpenCrmTicket(contactVM, this.data).pipe(
                                                map(x => {
                                                    this.dexieLogsSrv.info('OpenCrmTicket4', {data: this.data});
                                                    return x;
                                                }),
                                            );
                                        } else if (this.data.ticketResult && this.data.ticketResult.count == 0) {
                                            if (settings.creatTicket) {
                                                return this.buildCrmTicketObject(contactVM, this.data).pipe(
                                                    mergeMap(dt =>
                                                        this.createCrmTicket(contactVM, this.data).pipe(
                                                            map(x => {
                                                                this.dexieLogsSrv.info('createCrmTicket4', {data: this.data});
                                                                return x;
                                                            }),
                                                        ),
                                                    ),
                                                    mergeMap(d1 =>
                                                        this.OpenCrmTicket(contactVM, this.data).pipe(
                                                            map(x => {
                                                                this.dexieLogsSrv.info('OpenCrmTicket5', {data: this.data});
                                                                return x;
                                                            }),
                                                        ),
                                                    ),
                                                );
                                            } else {
                                                return of(this.data);
                                            }
                                        } else if (d.ticketResult && d.ticketResult.count == 1) {
                                            return this.OpenCrmTicket(contactVM, this.data).pipe(
                                                map(x => {
                                                    this.dexieLogsSrv.info('OpenCrmTicket6', {data: this.data});
                                                    return x;
                                                }),
                                            );
                                        } else if (d.ticketResult && d.ticketResult.count > 1) {
                                            return this.OpenCrmUser(contactVM, this.data).pipe(
                                                map(x => {
                                                    this.dexieLogsSrv.info('OpenCrmUser4', {data: this.data});
                                                    return x;
                                                }),
                                            );
                                        }
                                        return new Observable<any>(sub => {
                                            sub.next(this.data);
                                            sub.complete();
                                        });
                                    }),
                                );
                            } else if (settings.creatTicket) {
                                process = process.pipe(
                                    mergeMap((v, d) => this.buildCrmTicketObject(contactVM, this.data)),
                                    mergeMap(dt =>
                                        this.createCrmTicket(contactVM, this.data).pipe(
                                            map(x => {
                                                this.dexieLogsSrv.info('createCrmTicket5', {data: this.data});
                                                return x;
                                            }),
                                        ),
                                    ),
                                    mergeMap(d =>
                                        this.OpenCrmTicket(contactVM, this.data).pipe(
                                            map(x => {
                                                this.dexieLogsSrv.info('OpenCrmTicket7', {data: this.data});
                                                return x;
                                            }),
                                        ),
                                    ),
                                );
                            }
                        }
                    }

                    process.subscribe(r => {
                        this.data.processed = true;
                        if (this.data.ticket) {
                            // contactVM.contact.ticketId = 'testing_123';
                            contactVM.contact.ticketId = `${this.data.ticket.id}`;

                            (contactVM as any).ticketId = this.data.ticket.id;

                            contactVM.contactService.currentContactViewModels.value
                                .filter(x => x.contact.contactId == contactVM.contact.contactId)
                                .forEach(x => {
                                    x.contact.ticketId = this.data.ticket.id;
                                });

                            /*  setTimeout(() => {
                                 this.processContact(contactVM);
                             }, 5000); */
                        }
                        this.addToProcessedContacts(contactVM);
                    });
                });
            }
        }

        /*
                if (settings && settings.searchUser) { // user search is enabled
                    var searchVal = contact[context.configMap.incontactSearchField];
                    process = process.flatMap(d => this.findCrmUser());
                }
                if (settings && settings.creatTicket) { // create ticket is enabled, create ticket
                    process = process.flatMap(d => this.createCrmTicket()).flatMap(d => this.OpenCrmTicket());

                }
                else { //Dont search user
                    if (settings && settings.creatUser) {//Just only Create user enabled
                        //just only create user
                        this.alwaysCreateUser(data, context);
                    }
                }
                 */
    }

    public onManualUserSelection(contactVM: ContactViewBaseModel<ContactBase>, userId: number) {
        console.log(userId);
    }

    public onManualTicketSelection(contactVM: ContactViewBaseModel<ContactBase>, ticketId: number | string) {
        console.log(ticketId);
    }

    public createCrmTicketManuallay() {
        //
    }

    processFinishedContact(contactVM: ContactViewBaseModel<ContactBase>) {
        if (!contactVM.isCrmProcessable()) {
            return;
        }

        this.updateCrmTicketWithContactDetails(contactVM).subscribe(c => {
            this.afterContactFinalized(c);
        });
    }

    protected updateCrmTicketWithContactDetails(contactVM: ContactViewBaseModel<ContactBase>): Observable<ContactViewBaseModel<ContactBase>> {
        return new Observable(observer => {
            observer.next(contactVM);
            observer.complete();
        });
    }

    protected customCrmSearch(viewmodel: ContactViewBaseModel<ContactBase>, searchString: string, data: CrmDataHolderViewModel) {
        return new Observable<{found: boolean; type: string}>(r => {
            console.log('searching for ticket');
            data.ticketResult = {ticket: {}, count: 1};
            console.log('customCrmSearch Parent: ', data);
            r.next({found: false, type: null});
            r.complete();
        });
    }

    public customCrmUsersSearch(text: string) {
        return new Observable<{found: boolean; type: string}>(r => {
            console.log('searching for ticket');

            r.next({found: false, type: null});
            r.complete();
        });
    }

    protected findCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        return new Observable(r => {
            console.log('searching for ticket');
            data.ticketResult = {ticket: {}, count: 1};
            console.log('findCrmTicket Parent: ', data);
            r.next(data);
            r.complete();
        });
    }

    protected getCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: any, id: string): Observable<any> {
        return new Observable(r => {
            console.log('searching for ticket');
            data.ticketResult = {ticket: {}, count: 1};
            console.log('findCrmTicket Parent: ', data);
            r.next(data);
            r.complete();
        });
    }

    protected findCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: any): Observable<any> {
        console.log('searching for user');
        data.zendeskUser = {
            id: 377426871772,
            url: 'https://frontlineservices.zendesk.com/api/v2/users/377426871772.json',
            name: 'Chat undefined',
            email: null,
            created_at: '2019-02-23T15:08:01Z',
            updated_at: '2019-02-23T15:08:01Z',
            time_zone: 'Pacific Time (US & Canada)',
            iana_time_zone: 'America/Los_Angeles',
            phone: null,
            shared_phone_number: null,
            photo: null,
            locale_id: 1,
            locale: 'en-US',
            organization_id: null,
            role: 'end-user',
            external_id: null,
            tags: [],
            alias: null,
            active: true,
            shared: false,
            shared_agent: false,
            last_login_at: null,
            two_factor_auth_enabled: false,
            signature: null,
            details: null,
            notes: null,
            role_type: null,
            custom_role_id: null,
            moderator: false,
            ticket_restriction: 'requested',
            only_private_comments: false,
            restricted_agent: true,
            suspended: false,
            default_group_id: null,
            report_csv: false,
            user_fields: {agent_id: null},
        } as any;

        return of(data);
    }
    protected createCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        console.log('creating user..');
        data.zendeskUser = {name: 'test'} as any;

        return of(data);
    }
    protected createCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        console.log('creating ticket..');
        data.ticket = {
            url: 'https://frontlineservices.zendesk.com/api/v2/tickets/6945.json',
            id: 6945,
            external_id: null,
            via: {channel: 'api', source: {from: {}, to: {}, rel: null}},
            created_at: '2019-02-23T15:08:02Z',
            updated_at: '2019-02-23T15:08:02Z',
            type: null,
            subject: 'New Ticket',
            raw_subject: 'New Ticket',
            description: 'New ticket details...',
            priority: null,
            status: 'open',
            recipient: null,
            requester_id: 377426871772,
            submitter_id: 377426871772,
            assignee_id: 380251610,
            organization_id: null,
            group_id: 20747830,
            collaborator_ids: [],
            follower_ids: [],
            email_cc_ids: [],
            forum_topic_id: null,
            problem_id: null,
            has_incidents: false,
            is_public: false,
            due_at: null,
            tags: ['test'],
            custom_fields: [
                {id: 22927630, value: null},
                {id: 22927640, value: null},
                {id: 22944030, value: null},
                {id: 22927650, value: null},
                {id: 22927660, value: null},
                {id: 22763134, value: null},
                {id: 22763144, value: null},
                {id: 22763154, value: null},
                {id: 22578584, value: null},
                {id: 22763164, value: null},
                {id: 22763174, value: null},
                {id: 22781634, value: null},
                {id: 24248266, value: null},
                {id: 22781664, value: null},
                {id: 22927590, value: null},
                {id: 22927600, value: null},
                {id: 360000067066, value: null},
                {id: 22927610, value: null},
            ],
            satisfaction_rating: null,
            sharing_agreement_ids: [],
            fields: [
                {id: 22927630, value: null},
                {id: 22927640, value: null},
                {id: 22944030, value: null},
                {id: 22927650, value: null},
                {id: 22927660, value: null},
                {id: 22763134, value: null},
                {id: 22763144, value: null},
                {id: 22763154, value: null},
                {id: 22578584, value: null},
                {id: 22763164, value: null},
                {id: 22763174, value: null},
                {id: 22781634, value: null},
                {id: 24248266, value: null},
                {id: 22781664, value: null},
                {id: 22927590, value: null},
                {id: 22927600, value: null},
                {id: 360000067066, value: null},
                {id: 22927610, value: null},
            ],
            followup_ids: [],
            ticket_form_id: 37290,
            brand_id: 413754,
            satisfaction_probability: null,
            allow_channelback: false,
            allow_attachments: true,
        };

        return of(data);
    }
    protected buildCrmTicketObject(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        console.log('building ticket..');
        data.ticket = {ticket: {}, count: 1};

        return of(data);
    }
    protected buildCrmUserObject(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        console.log('building user..');
        data.ticket = {user: {}, count: 1};

        return of(data);
    }
    protected OpenCrmTicket(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        console.log('opening ticket..');
        data.ticket = {user: {}, count: 1};

        return of(data);
    }
    protected OpenCrmUser(viewmodel: ContactViewBaseModel<ContactBase>, data: CrmDataHolderViewModel): Observable<any> {
        console.log('opening user..');
        data.ticket = {user: {}, count: 1};

        return of(data);
    }
    protected notifyUser(viewmodel: ContactViewBaseModel<ContactBase>, data: any): Observable<any> {
        const d = Object.assign({timeout: 5000, type: 'warning'}, data);
        this.messagesSrv.notify(d);

        return of(data);
    }

    protected afterContactFinalized(contactVM: ContactViewBaseModel<ContactBase>) {
        this.removeHandledContact(contactVM.contact.contactId);
    }
}
