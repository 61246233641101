export class ErrorModel {
    constructor(private errorCode: string, private errorDescription: string) {
        // code...
    }

    getErrorCode(): string {
        return this.errorCode;
    }

    getErrorDescription(): string {
        return this.errorDescription;
    }
}
