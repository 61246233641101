<div class="panel panel-default panel-top-most">
    <!-- Default panel contents -->
    <div role="heading" aria-level="3" class="panel-heading">
        <i class="fa fa-address-card-o fa-lg" aria-hidden="true"></i>&nbsp; {{ 'Report.Title' | translate }}
        <div class="pull-right">
            <i
                (click)="close()"
                class="fa fa-times-circle fa-lg"
                title="{{ 'Report.Close' | translate }}"
                aria-hidden="true"
            ></i>
        </div>
    </div>
    <div class="panel-scrollable">
        <form #feedbackForm="ngForm" (ngSubmit)="sendReport()" class="form-horizontal">
            <div class="form-group disposition-form-group">
                <div class="col-md-6 col-md-offset-3">
                    <p>
                        <b>{{ 'Report.Disclaimer' | translate }}</b> {{ 'Report.ReportText' | translate }}
                    </p>
                </div>
            </div>

            <div class="form-group disposition-form-group">
                <div class="col-md-6 col-md-offset-3">
                    <label for="description" class="col-sm-2 control-label">{{ 'Report.Label' | translate }} </label>
                    <textarea
                        [(ngModel)]="problemDescription"
                        name="description"
                        #description="ngModel"
                        class="form-control"
                        id="description"
                        rows="3"
                    ></textarea>
                </div>
            </div>
            <div class="form-group disposition-form-group">
                <div class="col-md-6 col-md-offset-3 pull-right">
                    <button type="button" class="btn btn-warning">{{ 'Buttons.Cancel' | translate }}</button>&nbsp;
                    <button type="submit" class="btn btn-primary">{{ 'Report.Send' | translate }}</button>
                </div>
            </div>
        </form>
    </div>
</div>
