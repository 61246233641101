import {HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {of as observableOf, throwError} from 'rxjs';
import {InContactApiSettings} from 'src/app/core/InContact/incontact-api-settings';
import {BaseApi, CustomErrorHandler} from 'src/app/sdk/core';
import {FacLocalStorageService} from 'src/app/sdk/storage';
import {Observable} from 'rxjs/internal/Observable';
import {AgentInfoModel} from 'src/app/core/InContact/models/agent-info.model';
import {LocalStorageService} from 'ngx-webstorage';
import {AgentSettingsModel, LoginWithCodeResponse} from '../../models';
import {Agent} from 'src/app/core/_models/agent.model';
import {HttpEncodeUriComponent} from 'src/app/shared/classes';
import {ConfigService} from 'src/app/core/_services/config.service';

@Injectable({
    providedIn: 'root',
})
export class IcOpenIdApi extends BaseApi {
    constructor(
        @Inject(HttpClient) httpClient: HttpClient,
        @Inject(FacLocalStorageService) protected facLocalStorageSrv: FacLocalStorageService,
        @Inject(LocalStorageService) protected localStorageSrv: LocalStorageService,
        @Inject(ConfigService) protected configService: ConfigService,
        @Optional() @Inject(CustomErrorHandler) protected errorHandler: CustomErrorHandler,
    ) {
        super(httpClient, facLocalStorageSrv, errorHandler);
    }

    getTokenByCode(email: string, code: string, redirect_uri: string) {
        // let token64 = btoa(InContactApiSettings.clientId + ':' + InContactApiSettings.clientSecret);

        // return this.POST_Request<LoginWithCodeResponse>(
        //     `https://cxone.niceincontact.com/openid/token`,
        //     undefined,
        //     undefined,
        //     new HttpParams({
        //         fromObject: {
        //             code,
        //             grant_type: 'authorization_code',
        //             redirect_uri,
        //         },
        //     }).toString(),
        //     (headers: HttpHeaders) => {
        //         headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        //         headers = headers.set('Authorization', `Basic ${token64}`);
        //         return headers;
        //     },
        // );

        return this.POST_Request<LoginWithCodeResponse>(
            `${this.configService.ApiUrlUrl}/account/login-agent-with-code`,
            undefined,
            {
                redirect_uri,
            },
            {
                code,
                email,
            },
        );
    }

    refreshTokenCxone(email: string, refresh_token: string) {
        // const token64 = btoa(InContactApiSettings.clientId + ':' + InContactApiSettings.clientSecret);

        // let refreshToken = refresh_token;
        // if (refresh_token.substr(refresh_token.length - 2, refresh_token.length) === '==') {
        //     refreshToken = refresh_token.substr(0, refresh_token.length - 2);
        // }

        // const data = new HttpParams({
        //     fromObject: {
        //         grant_type: 'refresh_token',
        //         refresh_token,
        //         scope: 'openid',
        //     },
        //     encoder: new HttpEncodeUriComponent(),
        // });

        // return this.POST_Request<any>(`https://cxone.niceincontact.com/openid/token`, undefined, undefined, data.toString(), (headers: HttpHeaders) => {
        //     headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        //     headers = headers.set('Authorization', `Basic ${token64}`);

        //     return headers;
        // });

        return this.POST_Request<LoginWithCodeResponse>(`${this.configService.ApiUrlUrl}/account/refresh-openid-agent-token`, undefined, undefined, {
            code: refresh_token,
            email,
        });
    }

    logoutOpenId() {
        const result = window.open(
            `${InContactApiSettings.ssoBaseUri}/auth/authorize/logout`,
            '_blank',
            'toolbar=yes,scrollbars=yes,resizable=yes,width=400,height=400',
        );

        setTimeout(() => {
            result?.close();
        }, 2000);
    }
}
