<li
    *ngFor="let skill of allskills | filter: 'skills':'SkillName':keyword; let i = index"
    (click)="dialSkill(skill.SkillName)"
    class="list-group-item list-item-uac"
>
    <a tabindex="i" #listItem name="listItem" role="link" href="javascript:void(0)">
        <div class="row list-item-row">
            <div class="col-xs-12">
                <i
                    class="fa fa-reply-all"
                    aria-hidden="true"
                    title="{{ 'App_Component.Footer.ActiveCalls' | translate }}"
                ></i>
                <div class="item-text">{{ skill.SkillName }}</div>
            </div>
        </div>
    </a>
</li>
