import {Injectable} from '@angular/core';
import {Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanLoad, Route, UrlSegment} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from 'src/app/screens/authentication/services/authentication.service';

import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    private isAuthenticated: boolean;

    constructor(private router: Router, private icAgentSessionSrv: IcAgentSessionService, private authenticationSrv: AuthenticationService) {
        // this.agentSessionEventsService.isAuthenticated.subscribe(auth => (this.isAuthenticated = auth));
    }
    canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
        return this.isVerified();
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.isVerified();
    }

    canActivate() {
        return this.isVerified();
    }

    isVerified() {
        if (this.icAgentSessionSrv.isUserAuthenticated()) {
            return true;
        } else {
            console.log('redircting to login page from guard');
            this.router.navigate(['/authentication/login-step-one']);
            // return false;
        }
    }
}
