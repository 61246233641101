<ul
    class="nav nav-pills contact-actions display-style-{{displayStyle}} contact-action-items-{{filteredItems?.length}} {{tagClasses}}"
>
    <li class="contact-header-action">
        <!-- <pre style="text-align: left">{{contactVM.contact.isPreview + ''}}</pre> -->
        <ng-content select="[first-one]"></ng-content>
    </li>

    <!-- <ng-container *ngIf="!contactVM.contact.isPreview"> -->
    <ng-container *ngFor="let action of contactVM.actions | contactActionFilter: {slot: slot}">
        <li
            *ngIf="action.isVisible() &&  isAllowed(action)"
            #actionItems
            [style.display]="(
                (slot == 'elevations' &&  !businessUnitInfo.isMultiContactHandling) ||
                (mode == 'related' && !action.visibleInOmniRelatedMode))  ? 'none' : ''
                "
            class="{{action.color}} contact_action_{{action.action}}"
            [ngClass]="{
                'dropdown': action.hasMenuItems() || slot == 'elevations',
                'elevation-action-item':slot == 'elevations',
                'contact-fullRow-action': action.isFullRow,
                'contact-action-item': displayStyle == 'normal'
            }"
        >
            <button
                role="button"
                [ngClass]="{'dropdown-toggle': action.hasMenuItems(), 'slot-button': slot == 'elevations', 'btn-circle btn-lg': displayStyle == 'normal' }"
                [attr.data-toggle]="action.hasMenuItems() || slot == 'elevations'  ? 'dropdown' : null"
                title="{{action.text}}"
                (click)="!action.hasMenuItems() && contactVM.executeContactAction(action.action, action.data)"
                routerLinkActive="active"
                class="btn"
                [disabled]="!action.isAvailable() || !isAllowed(action)"
            >
                <i class="fa fa-plus-circle" *ngIf="false && slot == 'elevations'" aria-hidden="true"></i>
                <i class="fa fa-{{action.icon}}" aria-hidden="true"></i>
                <!-- <span
                    [ngClass]="{'hidden-xs':!action.textAlwaysvisible}">{{action.text}}</span> -->
            </button>
            <span class="action-label">{{action.text}}</span>
            <ng-container *ngIf="action.menuItems.length > 0">
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let item of action.menuItems">
                        <a href="javascript:;" (click)="contactVM.executeContactAction(item.action, item.data)"
                            >{{item.text}}</a
                        >
                    </li>
                </ul>
            </ng-container>
            <app-contact-elevation
                *ngIf="slot == 'elevations'"
                class="dropdown-menu"
                [style.margin-left.px]="dropdownMargin"
                [elevationAction]="action.action"
                [contactService]="contactVM.contactService"
                [masterContactId]="contactVM.contact.contactId"
            >
            </app-contact-elevation>
        </li>
    </ng-container>
    <!-- </ng-container> -->

    <ng-container *ngIf="slot == 'elevations' && !contactVM.contact.isPreview">
        <li *ngFor="let ochild of contactVM.OmniChildContacts">
            <button
                role="button"
                class="btn btn-primary-outline slot-button"
                style="color: #008080"
                (click)="ochild.contactService.activateContact(ochild.contact).subscribe()"
            >
                <i class="fa fa-window-restore" aria-hidden="true"></i>
                <i class="fa fa-{{ochild.contact.contactIcon}}" aria-hidden="true"></i>
                <!-- <span
                        [ngClass]="{'hidden-xs':!action.textAlwaysvisible}">{{ochild.contact.contactType}}</span> -->
            </button>
        </li>
    </ng-container>
    <!-- <li *ngIf="showPredisposition && contactVM.contact.canHaveDisposition()"> -->
    <!-- <li *ngIf="false && contactVM.contact.canHaveDisposition() && !contactVM.contact.isPreview">
        <span class="dropdown">
            <button role="button" data-toggle="dropdown" title="Disposition" class="btn btn-primary-outline no-border">
                <i class="fa fa-check" aria-hidden="true" *ngIf="!contactVM.preDeispositionSet"></i>
                <i
                    class="fa fa-check-square"
                    style="background: ff0000"
                    aria-hidden="true"
                    *ngIf="contactVM.preDeispositionSet"
                ></i>
                <span class="hidden-xs">Disposition</span>
            </button>
            <ul
                class="dropdown-menu"
                [ngClass]="{'dropdown-menu-right':dispositionMenuAlignment == 'right' }"
                style="padding: 10px; min-width: 200px"
            >
                <li>
                    <h4>Add Disposition</h4>
                </li>
                <li>
                    <active-contact-disposition
                        [contact]="contactVM.contact"
                        [viewModel]="contactVM"
                        [contactService]="contactVM.contactService"
                        [mode]="related"
                    ></active-contact-disposition>
                </li>
                <li>
                    <button
                        type="button"
                        href="javascript:;"
                        class="btn btn-primary pull-right"
                        [disabled]="!contactVM.contact.appliedDisposition"
                        (click)="savePreselectedDisposition()"
                    >
                        Save
                    </button>
                </li>
            </ul>
        </span>
    </li> -->
    <li class="contact-footer-action">
        <ng-content select="[last-one]"></ng-content>
    </li>
</ul>
<div style="clear: both"></div>
