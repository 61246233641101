export class InContactSdkConfig {
    private static ApiUrl = 'localhost:3010';
    private static _ApiVersion = '';
    private static _AuthPrefix = 'Bearer ';

    static get BaseApiPath() {
        return this.ApiUrl;
    }
    static set BaseApiPath(path: string) {
        this.ApiUrl = path;
    }

    static get ApiPath() {
        return this.ApiUrl + (InContactSdkConfig.ApiVersion !== '' ? `${InContactSdkConfig.ApiVersion}` : '');
    }

    static get ApiVersion() {
        return this._ApiVersion;
    }
    static set ApiVersion(v: string) {
        this._ApiVersion = v;
    }

    static set AuthPrefix(v: string) {
        this._AuthPrefix = v;
    }
    static get AuthPrefix() {
        return this._AuthPrefix;
    }
}

export class ScreenAgentConfig {
    private static ApiUrl = 'http://127.0.0.1:31322';

    static get BaseApiPath() {
        return this.ApiUrl;
    }
    static set BaseApiPath(path: string) {
        this.ApiUrl = path;
    }

    static get ApiPath() {
        return this.ApiUrl;
    }
}
