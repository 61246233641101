import {Component, OnInit} from '@angular/core';

import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {AgentSkillAssignmentsModel} from '../../core/InContact/models/agent-skill-assignments.model';
import {AutomatedOutboundService} from '../../core/_services/automated-outbound.service';

@Component({
    selector: 'app-automated-outbound',
    templateUrl: './automated-outbound.component.html',
    styleUrls: ['./automated-outbound.component.css'],
})
export class AutomatedOutboundComponent implements OnInit {
    preSelected: AgentSkillAssignmentsModel;
    selectedSkill: AgentSkillAssignmentsModel;
    skillslist: AgentSkillAssignmentsModel[];
    isPCSKillLoggedIn: boolean;
    dialerCampaign: string;

    constructor(
        private automatedOutboundService: AutomatedOutboundService,
        private agentService: IcAgentSessionService,
    ) {
        this.selectedSkill = new AgentSkillAssignmentsModel({});
        this.preSelected = new AgentSkillAssignmentsModel({});
        this.preSelected.skillId = -1;
        this.preSelected.skillName = 'Select One';
    }

    ngOnInit() {
        this.skillslist = [];
        this.agentService.agentSkillAssignments.subscribe(skills => {
            this.skillslist = skills.filter(e => e.isNaturalCalling === 'True' && e.isNaturalCallingRunning === 'True');

            this.agentService.dialerCampaign.subscribe(skillName => {
                this.dialerCampaign = skillName;

                if (this.dialerCampaign !== '') {
                    const index = this.skillslist.findIndex(e => e.skillName === this.dialerCampaign);
                    if (index > -1) {
                        this.selectedSkill = this.skillslist[index];
                    }
                    this.isPCSKillLoggedIn = true;
                } else {
                    this.selectedSkill = this.preSelected;
                }
            });
        });
    }

    loginPCSkill(skill: AgentSkillAssignmentsModel) {
        this.automatedOutboundService.loginPCSkill(skill).subscribe(res => {
            this.isPCSKillLoggedIn = true;
            this.dialerCampaign = skill.skillName;
            this.agentService.dialerCampaign.next(this.dialerCampaign);
        });
    }

    logoutPCSkill(skill: AgentSkillAssignmentsModel) {
        this.automatedOutboundService.logoutPCSkill(skill).subscribe(res => {
            this.isPCSKillLoggedIn = false;
            this.dialerCampaign = '';
            this.agentService.dialerCampaign.next(this.dialerCampaign);
        });
    }

    isCurrentlyInDialerState(): boolean {
        return (
            this.agentService.currentAgentState.value == 'Dialer' ||
            (this.agentService.nextAgentStatus.value &&
                this.agentService.nextAgentStatus.value.originalNextState == 'Dialer')
        );
    }
}
