export class ServerConfigModel {
    domain: string;
    addresses: Array<string>;
    iceServers: Array<string>;
    version: string;

    constructor(data?: Partial<ServerConfigModel>) {
        Object.assign(this, data);
    }
}
