<div class="page-heading">
    <div class="agent-name">
        <h4 class="panel-heading">{{ agentInfo.firstName }} {{ agentInfo.lastName }}</h4>
        <!-- <button type="button" class="btn btn-default btn-sm mr-5">
            <i class="fas fa-ellipsis-v"></i>
        </button> -->

        <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
        <button class="p-button-raised p-button-rounded mr-5" type="button" pButton icon="pi pi-list" (click)="menu.toggle($event)"></button>
    </div>
    <ul style="display: block" class="nav nav-pills">
        <li style="min-width: 415px">
            <div class="row row-margin">
                <div class="col-xs-6 nopadding"><i class="fa fa-id-badge" aria-hidden="true"></i>&nbsp; {{ 'AgentInfo.AgentID' | translate }}</div>
                <div class="col-xs-6 nopadding">&nbsp; {{ sessionInfo.AgentId }}</div>
            </div>
            <div class="row row-margin">
                <div class="col-xs-6 nopadding"><i class="fa fa-phone" aria-hidden="true"></i>&nbsp; {{ 'AgentInfo.PhoneNo' | translate }}</div>
                <div class="col-xs-6 nopadding">&nbsp; {{ getContactNo() }}</div>
            </div>
            <div class="row row-margin">
                <div class="col-xs-6 nopadding"><i class="fa fa-id-card-o" aria-hidden="true"></i>&nbsp; {{ 'AgentInfo.CallerID' | translate }}</div>
                <div class="col-xs-6 nopadding">&nbsp; {{ sessionInfo.StationCallerId }}</div>
            </div>
            <div class="row row-margin">
                <div class="col-xs-6 nopadding"><i class="fa fa-key" aria-hidden="true"></i>&nbsp; {{ 'AgentInfo.SessionID' | translate }}</div>
                <div class="col-xs-6 nopadding">&nbsp; {{ sessionInfo.SessionId }}</div>
            </div>
        </li>
    </ul>
</div>

<div class="page-content" style="top: 125px">
    <div class="list-group">
        <a *ngFor="let skill of agentSkillAssignments" class="list-group-item list-item-uac">
            <div class="row list-item-row">
                <div class="col-xs-12 nopadding">
                    <a *ngIf="skill.isOutbound === 'True'"><i class="fa fa-reply" aria-hidden="true" title="{{ 'AgentInfo.Outbound' | translate }}"></i></a>
                    <a *ngIf="skill.isOutbound === 'False'"><i class="fa fa-share" aria-hidden="true" title="{{ 'AgentInfo.Inbound' | translate }}"></i></a>
                    <div class="item-text">{{ skill.skillName }}</div>
                </div>
            </div>
        </a>
    </div>
</div>
