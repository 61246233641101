import {DynamicsActivityPartyModel} from './dynamics-activity-party.model';
import {DynamicsActivityPointerModel} from './dynamics-activity-pointer.model';

export class DynamicsPhoneCallModel extends DynamicsActivityPointerModel {
    public directioncode: boolean;
    public phonenumber: string;

    // @odata.etag: "W/\"34008132\""
    // activityadditionalparams: null

    // activitytypecode@OData.Community.Display.V1.FormattedValue: "Phone Call"
    // actualdurationminutes: null
    // actualend: "2021-09-08T14:20:19Z"
    // actualend@OData.Community.Display.V1.FormattedValue: "9/8/2021"
    // actualstart: null
    // category: null
    // community: null
    // createdon: "2021-09-08T13:21:22Z"
    // createdon@OData.Community.Display.V1.FormattedValue: "9/8/2021 6:21 PM"
    // deliverylastattemptedon: null
    // deliveryprioritycode: null

    // directioncode@OData.Community.Display.V1.FormattedValue: "Incoming"
    // exchangeitemid: null
    // exchangerate: null
    // exchangeweblink: null
    // importsequencenumber: null
    // instancetypecode: null
    // isbilled: false
    // isbilled@OData.Community.Display.V1.FormattedValue: "No"
    // ismapiprivate: null
    // isregularactivity: true
    // isregularactivity@OData.Community.Display.V1.FormattedValue: "Yes"
    // isworkflowcreated: false
    // isworkflowcreated@OData.Community.Display.V1.FormattedValue: "No"
    // lastonholdtime: null
    // leftvoicemail: false
    // leftvoicemail@OData.Community.Display.V1.FormattedValue: "No"
    // modifiedon: "2021-09-08T14:20:19Z"
    // modifiedon@OData.Community.Display.V1.FormattedValue: "9/8/2021 7:20 PM"
    // onholdtime: null
    // overriddencreatedon: null
    // postponeactivityprocessinguntil: null
    // prioritycode: 1
    // prioritycode@OData.Community.Display.V1.FormattedValue: "Normal"
    // processid: null
    // scheduleddurationminutes: null
    // scheduledend: null
    // scheduledstart: null
    // senton: null
    // seriesid: null
    // sortdate: null
    // stageid: null
    // statecode: 1
    // statecode@OData.Community.Display.V1.FormattedValue: "Completed"
    // statuscode: 4
    // statuscode@OData.Community.Display.V1.FormattedValue: "Received"
    // subcategory: null

    // subscriptionid: null
    // timezoneruleversionnumber: 18
    // timezoneruleversionnumber@OData.Community.Display.V1.FormattedValue: "18"
    // traversedpath: null
    // utcconversiontimezonecode: null
    // versionnumber: 34008132
    // versionnumber@OData.Community.Display.V1.FormattedValue: "34,008,132"
    // _createdby_value: "4d43f6e0-dad2-eb11-bacc-000d3a36328a"
    // _createdby_value@Microsoft.Dynamics.CRM.lookuplogicalname: "systemuser"
    // _createdby_value@OData.Community.Display.V1.FormattedValue: "Ali Shahzad"
    // _createdonbehalfby_value: null
    // _modifiedby_value: "4d43f6e0-dad2-eb11-bacc-000d3a36328a"
    // _modifiedby_value@Microsoft.Dynamics.CRM.lookuplogicalname: "systemuser"
    // _modifiedby_value@OData.Community.Display.V1.FormattedValue: "Ali Shahzad"
    // _modifiedonbehalfby_value: null
    // _ownerid_value: "4d43f6e0-dad2-eb11-bacc-000d3a36328a"
    // _ownerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty: "ownerid_phonecall"
    // _ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname: "systemuser"
    // _ownerid_value@OData.Community.Display.V1.FormattedValue: "Ali Shahzad"
    // _owningbusinessunit_value: "4507aa0b-9a4a-e711-815e-e0071b715b51"
    // _owningbusinessunit_value@Microsoft.Dynamics.CRM.associatednavigationproperty: "owningbusinessunit_phonecall"
    // _owningbusinessunit_value@Microsoft.Dynamics.CRM.lookuplogicalname: "businessunit"
    // _owningteam_value: null
    // _owninguser_value: "4d43f6e0-dad2-eb11-bacc-000d3a36328a"
    // _owninguser_value@Microsoft.Dynamics.CRM.lookuplogicalname: "systemuser"
    // _regardingobjectid_value: null
    // _sendermailboxid_value: null
    // _serviceid_value: null
    // _slaid_value: null
    // _slainvokedid_value: null
    // _transactioncurrencyid_value: null

    public phonecall_activity_parties: Array<DynamicsActivityPartyModel>;

    constructor(data?: Partial<DynamicsPhoneCallModel>) {
        super(data);
        Object.assign(this, data);
    }
}
