import {Injectable, Component, OnInit} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {InContactAPIService} from '.././InContact/incontact-api.service';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
// import {DialogService} from '../_services/dialog.service';
// import {
//     DialogRef,
//     ModalComponent,
//     overlayConfigFactory,
// } from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal';
// import {BSModalContext} from 'src/app/shared/transfer-active-contact-modal/node_modules/angular2-modal/plugins/bootstrap';
import {ApplicationContextService} from './application-context.service';
import {Utility} from '../../shared/utility.service';
import moment from 'moment';
import {DialogRef, overlayConfigFactory} from 'ngx-modialog-7';
import {DialogService} from 'src/app/shared/modals/dialog/dialog.service';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
// import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Injectable()
export class CallbackService {
    public callbacks: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    // private promiseDialogRef: DialogRef<any>;

    modalRef: DialogRef<any>;
    constructor(private inContactAPIService: InContactAPIService, private agentService: IcAgentSessionService, private dialogService: DialogService) {}

    processUpdateCallbacksEvent(event: any) {
        this.closeOpenedDialog();
        this.inContactAPIService.getCallbacks(this.agentService.agentId.value).subscribe((r: any) => {
            if (r.length) {
                this.callbacks.next(r);
            } else {
                this.callbacks.next([]);
            }
        });
    }

    processPromiseKeeperEvent(event: any) {
        this.closeOpenedDialog();

        setTimeout(() => {
            this.modalRef = this.dialogService.openModal(
                PromiseKeeperNotificationModalComponent,
                overlayConfigFactory({callbackData: event, dialogClass: 'modal-dialog', ignoreBackdropClick: true}, CallbackWindowViewModel),
            );
        }, 1000);

        // this.dialogService.modal
        //     .open(
        //         PromiseKeeperNotificationComponent,
        //         overlayConfigFactory(
        //             {
        //                 callbackData: event,
        //             },
        //             CallbackWindowViewModel,
        //         ),
        //     )
        //     .then(dlg => {
        //         this.promiseDialogRef = dlg;
        //     });
    }

    private closeOpenedDialog() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        // if (this.modalRef) {
        //     this.modalRef.hide();
        //     this.modalRef = null;
        // }
    }
}

export class CallbackWindowViewModel extends BSModalContext {
    /*
    AgentId: "2629249"
    CallbackExpireTime: "2019-02-11T18:20:00.000Z"
    CallbackId: "20343"
    CallbackTime: "2019-02-11T17:20:00.000Z"
    DeliveryTime: "2019-02-11T17:20:33.000Z"
    DialNumber: "8552120734"
    FirstName: "Mutahir"
    IISHost: "DAL-B2COR01"
    LastName: "Mehmood"
    Notes: "testing"
    OrigNumber: ""
    SkillId: "4015389"
    TargetType: "Agent"
    Type: "PromiseKeeper"
    VCHost: "DAL-B2COR01"
    */
    public callbackData: any = {};
}

/**
 * A Sample of how simple it is to create a new window, with its own injects.
 */
@Component({
    selector: 'promisekeeper-notificatoin',
    template: ` <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Scheduled Call</h4>
        </div>
        <div class="modal-body">
            <h3>{{ data.FirstName }} {{ data.LastName }}</h3>
            <p>{{ data.DialNumber }}<br />{{ data.SkillName }}: {{ data.Notes }}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="dialCallback()">Dial</button>
            <button type="button" class="btn btn-default" data-dismiss="modal" (click)="toggleShowReschedule()">Reschedule</button>
            <ul class="dropdown-menu" [style.display]="showReschedule ? 'block' : 'none'" style="left: -1px;margin-top: -10px; padding:7px; max-width:300px">
                <li><h4>Reschedule</h4></li>
                <li style="display: flex; min-width: 300px; min-height: 100px; align-items: center; justify-content: center;">
                    <ul class="nav">
                        <li>
                            <input [(ngModel)]="model.date" name="date" class="form-control" type="date" required />
                        </li>
                        <li>
                            <ul class="nav nav-pills">
                                <li>
                                    <input type="number" max="12" min="1" class="time-bit" name="hour" [(ngModel)]="model.hour" step="1" />
                                    <div *ngIf="false" class="dropdown">
                                        <button
                                            class="btn btn-default dropdown-toggle"
                                            type="button"
                                            id="dropdownMenu1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="true"
                                        >
                                            {{ model.hour }}
                                            <span class="caret"></span>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                            <div class="container" style="width:200px;">
                                                <div class="row" style="margin:0px;">
                                                    <ul class="nav nav-pills nav-justified">
                                                        <li>
                                                            <button type="button" (click)="setHour('01')" class="btn btn-default">01</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setHour('02')" class="btn btn-default">02</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setHour('03')" class="btn btn-default">03</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setHour('04')" class="btn btn-default">04</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="row" style="margin:0px;">
                                                    <ul class="nav nav-pills nav-justified">
                                                        <li>
                                                            <button type="button" (click)="setHour('05')" class="btn btn-default">05</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setHour('06')" class="btn btn-default">06</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setHour('07')" class="btn btn-default">07</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setHour('08')" class="btn btn-default">08</button>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div class="row" style="margin:0px;">
                                                    <ul class="nav nav-pills nav-justified">
                                                        <li>
                                                            <button type="button" (click)="setHour('09')" class="btn btn-default">09</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setHour('10')" class="btn btn-default">10</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setHour('11')" class="btn btn-default">11</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setHour('12')" class="btn btn-default">12</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <input type="number" value="0" step="5" class="time-bit" name="minute" [(ngModel)]="model.minute" max="59" min="0" />
                                    <div *ngIf="false" class="dropdown">
                                        <button
                                            class="btn btn-default dropdown-toggle"
                                            type="button"
                                            id="dropdownMenu1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="true"
                                        >
                                            {{ model.minute }}
                                            <span class="caret"></span>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                            <div class="container" style="width:200px;">
                                                <div class="row" style="margin:0px;">
                                                    <ul class="nav nav-pills nav-justified">
                                                        <li>
                                                            <button type="button" (click)="setMinute('00')" class="btn btn-default">00</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setMinute('05')" class="btn btn-default">05</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setMinute('10')" class="btn btn-default">10</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setMinute('15')" class="btn btn-default">15</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="row" style="margin:0px;">
                                                    <ul class="nav nav-pills nav-justified">
                                                        <li>
                                                            <button type="button" (click)="setMinute('20')" class="btn btn-default">20</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setMinute('25')" class="btn btn-default">25</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setMinute('30')" class="btn btn-default">30</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setMinute('35')" class="btn btn-default">35</button>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div class="row" style="margin:0px;">
                                                    <ul class="nav nav-pills nav-justified">
                                                        <li>
                                                            <button type="button" (click)="setMinute('40')" class="btn btn-default">40</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setMinute('45')" class="btn btn-default">45</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setMinute('50')" class="btn btn-default">50</button>
                                                        </li>
                                                        <li>
                                                            <button type="button" (click)="setMinute('55')" class="btn btn-default">55</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="dropdown">
                                        <button
                                            class="btn btn-default dropdown-toggle"
                                            type="button"
                                            id="dropdownMenu1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="true"
                                        >
                                            {{ model.ampm }}
                                            <span class="caret"></span>
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                            <li><a (click)="setAMPM('AM')">AM</a></li>
                                            <li><a (click)="setAMPM('PM')">PM</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <button class="btn btn-primary pull-right" [disabled]="!isValidRescheduleTime()" (click)="reschedule()">Update</button>
                </li>
            </ul>
        </div>`,
    styles: [
        `
            .time-bit {
                max-width: 50px;
                text-align: center;
            }
        `,
    ],
})
export class PromiseKeeperNotificationModalComponent implements /*CloseGuard,*/ OnInit {
    model: any = {};
    context: any;
    data: any = {};
    showReschedule = false;
    constructor(public dialog: DialogRef<CallbackWindowViewModel>, private appContext: ApplicationContextService) {}
    ngOnInit() {
        //this.model.hour = callbackDate.getHours();
        //this.model.hour = callbackDate.getMinutes();

        this.data = this.dialog.context.callbackData;
        // this.data = this.context.callbackData;

        this.appContext.agentSessionService.agentOutboundSkills.subscribe(r => {
            const skill = r.filter(s => s.skillId == this.data.SkillId)[0];
            if (skill) {
                this.data.SkillName = skill.skillName;
            }
        });
    }

    dialCallback() {
        const id = this.dialog.context.callbackData.CallbackId;
        // let id = this.data.CallbackId;
        this.appContext.inContactAPIService.dialCallback(id).subscribe(r => {
            // this.dialog.close();
            this.dialog.close();
        });
    }

    private getCallbackDate(): string {
        let hour = parseInt(this.model.hour);
        if (this.model.ampm == 'PM') {
            if (hour < 12) {
                hour = hour + 12;
            }
        } else if (this.model.ampm == 'AM') {
            if (hour == 12) {
                hour = 0;
            }
        }
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        const formattedMinute = `${this.model.minute}`.length == 1 ? `0${this.model.minute}` : `${this.model.minute}`;

        const formattedTimezone = (this.model.timezone + '').indexOf('-') == -1 ? `+${this.model.timezone}` : `${this.model.timezone}`;
        return `${this.model.date}T${formattedHour}:${formattedMinute}:00${formattedTimezone}`;
    }

    reschedule() {
        const id = this.data.CallbackId;
        const date = this.getCallbackDate();
        this.appContext.inContactAPIService.rescheduleCallback(id, date).subscribe(r => {
            this.showReschedule = false;
            this.dialog.close();
        });
    }

    toFormattedDate(oDate: Date) {
        const dt = oDate;
        //`${dt.getFullYear()}-${dt.getMonth()}-${dt.getDate()}`
        const year = `${dt.getFullYear()}`;
        const month = dt.getMonth() + 1 < 10 ? `0${dt.getMonth() + 1}` : `${dt.getMonth() + 1}`;
        const day = dt.getDate() < 10 ? `0${dt.getDate()}` : `${dt.getDate()}`;
        const timezone = (dt.getTimezoneOffset() / 60) * -1;
        const formattedTimezone =
            timezone < 0 ? `-${Math.abs(timezone) < 10 ? '0' : ''}${Math.abs(timezone)}:00` : `${Math.abs(timezone) < 10 ? '0' : ''}${Math.abs(timezone)}:00`;
        return {
            formattedTimezone,
            formattedDate: `${year}-${month}-${day}`,
            timezone,
            formattedHour: `${dt.getHours() + timezone}`,
            originalDate: oDate,
        };
    }

    setMinute(m: string) {
        this.model.minute = m;
    }

    setAMPM(m: string) {
        this.model.ampm = m;
    }

    setHour(hr: string) {
        this.model.hour = hr;
    }

    toggleShowReschedule() {
        this.showReschedule = !this.showReschedule;
        if (this.showReschedule) {
            const callbackDate = moment().add(5, 'm').toDate(); //new Date(this.data.CallbackTime);
            const formattedDateData = this.toFormattedDate(callbackDate);
            moment(this.data.CallbackTime).diff(moment());
            //callbackDate.di

            const amPmData = Utility.formatAMPM(formattedDateData.originalDate);
            this.model = {
                minute: amPmData.minutes,
                hour: amPmData.hours,
                ampm: amPmData.amPm,
                scheduleType: 'Me',
                date: formattedDateData.formattedDate,
                timezone: formattedDateData.formattedTimezone,
            };
        }
    }

    isValidRescheduleTime(): boolean {
        return moment(this.getCallbackDate()).diff(moment()) > 0;
    }
}
