import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {DialpadRoutingModule} from './dialpad.routing';
import {DialpadComponent} from './dialpad.component';
// import { QueueService }     from './dialpad.service';

// import {Ng2BootstrapModule} from 'ng2-bootstrap';
// import {DropdownModule} from 'ng2-bootstrap/dropdown';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DialpadRoutingModule,
        // Ng2BootstrapModule,
        // DropdownModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [DialpadComponent],
    exports: [DialpadComponent],
    providers: [],
})
export class DialpadModule {}
