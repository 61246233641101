import {of as observableOf, AsyncSubject, BehaviorSubject, Observable, SubscriptionLike as ISubscription, Scheduler, of} from 'rxjs';

import {map, delay, catchError, mergeMap} from 'rxjs/operators';
import {IcAgentSessionService} from '../../sdk/incontact/services/agent-sessions/agent-session-events.service';
import {ContactBase} from '../_models/contact-base';
import {ApplicationContextService} from './application-context.service';
import {DispositionModel} from '../_models/disposition.model';
import {ContactViewBaseModel} from '../_models/_viewmodels/contact-view-base.model';
import {DialogRef} from 'ngx-modialog-7';
import {AnyObject, CRM_APPLICATION_TYPES} from 'src/app/global';
import {KustomerCrmSerivce} from './crm-integration/kustomer.crm-service';
// import {BsModalRef} from 'ngx-bootstrap/modal';

declare let $: JQueryStatic;

export class ContactServiceBase<T extends ContactBase, VT extends ContactViewBaseModel<T>> {
    public currentContacts: BehaviorSubject<T[]>;
    public currentContactViewModels: BehaviorSubject<VT[]>;
    protected agentService: IcAgentSessionService;
    protected acceptDialogRef: DialogRef<any>;
    protected endDialogRef: DialogRef<any>;
    private subscription: ISubscription;
    private sendRejectRequest = true;

    public ScreenPopUrl = '';
    public ScreenPopUrlVariables: Array<AnyObject> = null;

    public title: string;
    public count = 0;
    public actions: contactServiceAction[] = [];
    private routerTimeout: any = null;

    private contactIds: Array<any> = [];

    constructor(
        private ctor: new (data: any) => T,
        private vtctor: new (contact: T, contactService: ContactServiceBase<T, ContactViewBaseModel<T>>, appctx: ApplicationContextService) => VT,
        protected appContext: ApplicationContextService,
    ) {
        this.currentContacts = new BehaviorSubject<T[]>([]);
        this.currentContactViewModels = new BehaviorSubject<VT[]>([]);
    }

    processContactEvent(event: any) {
        try {
            const obj = new this.ctor(event);

            const isCurrent = this.currentContacts.value.some(x => x.contactId === obj.contactId);

            if (obj.contactType === 'Voicemail' && obj.isDisconnected() && obj.finalState && !obj.From) {
                return;
            }

            if (obj.isActive() && !this.appContext.crmIntegrationService.isProcessedContact(obj) && !isCurrent) {
                if (this.contactIds.indexOf(event.ContactID || event.ContactId) !== -1) {
                    return;
                }
                this.contactIds.push(event.ContactID || event.ContactId);
            }

            this.findExistingContact(event.ContactID || event.ContactId)
                .pipe(
                    mergeMap(existingContactData => {
                        if (existingContactData.contactIndex > -1) {
                            console.log('existing contact');
                            const copyOfExistingContact = JSON.parse(JSON.stringify(existingContactData.contactObject));
                            const initialData = {
                                newContact: obj,
                                existingContact: existingContactData.contactObject,
                                existingContactIndex: existingContactData.contactIndex,
                            };
                            return this.loadContactSkill(obj).pipe(
                                mergeMap(newContact =>
                                    this.onBeforeContactUpdated(initialData).pipe(
                                        catchError(error => {
                                            this.log(error);
                                            return observableOf(initialData);
                                        }),
                                    ),
                                ),
                                mergeMap(replacementData =>
                                    this.replaceContact(replacementData).pipe(
                                        catchError(error => {
                                            this.log(error);
                                            return observableOf(replacementData);
                                        }),
                                    ),
                                ),
                                mergeMap(replacementData =>
                                    this.onAfterContactUpdated({
                                        newContact: replacementData.newContact,
                                        oldContact: copyOfExistingContact,
                                    }).pipe(
                                        catchError(error => {
                                            this.log(error);
                                            return observableOf({newContact: obj, oldContact: copyOfExistingContact});
                                        }),
                                    ),
                                ),
                                mergeMap(replacementData =>
                                    this.activateContactTimers(replacementData.newContact).pipe(
                                        catchError(error => {
                                            this.log(error);
                                            return observableOf(obj);
                                        }),
                                    ),
                                ),
                            );
                            //.flatMap(newContact => { return new Observable(r => r.next(newContact)) });
                        } else {
                            console.log('non existing contact');
                            if (event.ScreenPopUrl) {
                                this.ScreenPopUrl = event.ScreenPopUrl;
                            }
                            return this.loadContactSkill(obj).pipe(
                                mergeMap(newContact =>
                                    this.onBeforeNewContactAdded(newContact).pipe(
                                        catchError(error => {
                                            this.log(error);
                                            return observableOf(newContact);
                                        }),
                                    ),
                                ),
                                mergeMap(newContact =>
                                    this.addContact(newContact).pipe(
                                        catchError(error => {
                                            this.log(error);
                                            return observableOf(newContact);
                                        }),
                                    ),
                                ),
                                mergeMap(newContact =>
                                    this.onAfterNewContactAdded(newContact).pipe(
                                        catchError(error => {
                                            this.log(error);
                                            return observableOf(newContact);
                                        }),
                                    ),
                                ),
                                mergeMap(newContact =>
                                    this.activateContactTimers(newContact).pipe(
                                        catchError(error => {
                                            this.log(error);
                                            return observableOf(newContact);
                                        }),
                                    ),
                                ),

                                mergeMap(x => of(obj)),
                            );
                            //.flatMap(newContact => { return new Observable(r => r.next(obj)) });
                        }
                    }),
                    mergeMap((contact: T) => {
                        console.log('showing: ', contact, contact?.isIncoming());

                        if (contact?.isIncoming()) {
                            return this.processIncomingContact(contact);
                        } else {
                            if (this.acceptDialogRef && !event.isFakeEvent) {
                                this.sendRejectRequest = false;
                                this.acceptDialogRef.close();
                                // this.appContext.dialogService.close(this.acceptDialogRef);
                                this.acceptDialogRef = null;
                            }
                            if (this.endDialogRef && !event.isFakeEvent) {
                                // this.appContext.dialogService.close(this.endDialogRef);
                                this.endDialogRef.close();
                                this.endDialogRef = null;
                            }
                            if (contact?.isDisconnected()) {
                                this.appContext.marqueeMessageService.ClearAll();
                                if (contact.finalState) {
                                    //return this.removeContact(contact);
                                    return new Observable(r => {
                                        r.next(contact);
                                    });
                                    //return this.
                                } else {
                                    this.handleDisconnectedContat(contact);
                                    return this.loadContactDispositions(contact);
                                }
                            } else {
                                return new Observable(r => {
                                    r.next(contact);
                                });
                            }
                        }
                    }),
                )
                //.flatMap(data => this.test3(data))
                .subscribe(data => {
                    //this.log('Complete');
                    //this.log(data);
                    const vals = this.getContactListToDisplay();

                    this.currentContacts.next(vals);

                    this.updateAllViewmodels();
                });
        } catch (error) {
            this.log(error);
        }
    }

    public handleDisconnectedContat(contact: T) {
        //
    }

    getContactListToDisplay(): Array<T> {
        const vals = this.currentContacts.value.filter((v, i) => !v.finalState);
        return vals;
    }

    updateAllViewmodels() {
        this.count = this.currentContacts.value.length;
        const vmodels = this.currentContactViewModels.value;
        const vms = this.currentContacts.value.map(c => {
            const viewModel = new this.vtctor(c, this, this.appContext);
            const vmIndex = vmodels.findIndex(x => x.contact.contactId == c.contactId);
            const oldOne = vmodels[vmIndex];
            if (vmIndex > -1) {
                //let newOne = vmodels[vmIndex] = new this.vtctor(c, this, this.appContext);
                viewModel.transferData(oldOne);
            }

            return viewModel;
        });
        //this.currentContacts.value.filter(contact => contact.omniGroupId != '0')
        this.currentContactViewModels.next(vms);
        this.appContext.updateAllContacts();
        this.onContactsUpdated();
    }

    protected findExistingContact(contactId: any): Observable<any> {
        return new Observable(r => {
            const contacts = this.currentContacts.value;
            const thisContactIndex = contacts.findIndex((x: any) => x.contactId == contactId);
            r.next({contactIndex: thisContactIndex, contactObject: contacts[thisContactIndex]});
        });
    }
    protected replaceContact(data: any): Observable<any> {
        return new Observable(r => {
            this.log('replacing contact...');
            const contacts = this.currentContacts.value;
            contacts[data.existingContactIndex] = data.newContact;
            this.currentContacts[data.existingContactIndex] = data.newContact;
            this.currentContacts.next(contacts);

            const vmodels = this.currentContactViewModels.value;
            const vmIndex = vmodels.findIndex(x => x.contact.contactId == data.newContact.contactId);
            const oldOne = vmodels[vmIndex];
            if (vmIndex > -1) {
                const newOne = (vmodels[vmIndex] = new this.vtctor(data.newContact, this, this.appContext));
                newOne.transferData(oldOne);
            }

            r.next(data);
        });
    }
    protected addContact(data: any): Observable<any> {
        // console.log('adddContact', data);

        const contacts = this.currentContacts.value;
        contacts.push(data);
        const viewmodels = this.currentContactViewModels.value;
        viewmodels.push(new this.vtctor(data, this, this.appContext));
        this.currentContactViewModels.next(viewmodels);
        this.currentContacts.next(contacts);

        return of(data);

        // return new Observable(r => {
        //     // this.log('adding contact...');
        //     const contacts = this.currentContacts.value;
        //     contacts.push(data);
        //     const viewmodels = this.currentContactViewModels.value;
        //     viewmodels.push(new this.vtctor(data, this, this.appContext));
        //     this.currentContactViewModels.next(viewmodels);
        //     this.currentContacts.next(contacts);
        //     r.next(data);
        //     // r.complete();
        // });
    }

    protected activateContactTimers(contact: T): Observable<any> {
        // console.log('activateContactTimers', contact);
        const contacts = this.currentContacts.value;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (contacts.length > 0) {
            this.subscription = this.appContext.applicationTimer.pipe(delay(1)).subscribe(ticks => {
                this.currentContacts.value.forEach(item => {
                    item.updateTimers(this.appContext.serverTimeOffset);
                });
                //console.log("Timer Ticked -- " + ticks);
            });
        }

        return of(contact);

        // return new Observable(r => {
        //     let contacts = this.currentContacts.value;
        //     if (this.subscription) {
        //         this.subscription.unsubscribe();
        //     }
        //     if (contacts.length > 0) {
        //         this.subscription = this.appContext.applicationTimer.pipe(delay(1)).subscribe(ticks => {
        //             this.currentContacts.value.forEach(item => {
        //                 item.updateTimers(this.appContext.serverTimeOffset);
        //             });
        //             //console.log("Timer Ticked -- " + ticks);
        //         });
        //     }
        //     r.next(contact);
        //     // r.complete();
        // });
    }

    protected removeContact(contact: T): Observable<any> {
        const ind = this.contactIds.indexOf(contact.contactId);
        if (ind !== -1) {
            this.contactIds.splice(ind, 1);
        }

        return this.findExistingContact(contact.contactId).pipe(
            mergeMap(
                existingContactData =>
                    new Observable(r => {
                        if (existingContactData.contactIndex > -1) {
                            const me = this;
                            let contacts = me.currentContacts.getValue();
                            contacts = contacts.splice(existingContactData.contactIndex, 1);
                            me.currentContacts.next(contacts);
                            r.next(contact);
                        }
                    }),
            ),
        );
    }

    protected loadContactSkill(contact: T): Observable<T> {
        this.appContext.inContactAPIService.getSkillInfo(+contact.skill).subscribe(
            skills => {
                contact.skillName = skills[0].skillName;
                contact.setSkillInfo(skills[0]);
                if (this.acceptDialogRef) {
                    $(`.accept-contact-${contact.contactId} span`).text(contact.skillName);
                    //this.acceptDialogRef.context.body = `<div role="alert" > ${contact.skillName} </div>`;
                }
                //r.next(contact);
            },
            err => {
                //r.next(contact);
            },
        );

        return of(contact);

        // return new Observable(r => {
        //     this.appContext.inContactAPIService.getSkillInfo(+contact.skill).subscribe(
        //         skills => {
        //             contact.skillName = skills[0].skillName;
        //             contact.setSkillInfo(skills[0]);
        //             if (this.acceptDialogRef) {
        //                 $(`.accept-contact-${contact.contactId} span`).text(contact.skillName);
        //                 //this.acceptDialogRef.context.body = `<div role="alert" > ${contact.skillName} </div>`;
        //             }
        //             //r.next(contact);
        //         },
        //         err => {
        //             //r.next(contact);
        //         },
        //     );

        //     r.next(contact);
        // });
    }

    protected loadContactDispositions(contact: T): Observable<T> {
        return new Observable(r => {
            this.appContext.inContactAPIService.getDispositionList(contact.skill).subscribe(
                (dispositions: any[]) => {
                    const list = (dispositions ?? []).map(x => new DispositionModel(x));
                    // console.log(list);
                    contact.dispositions = list;
                    r.next(contact);
                },
                err => {
                    r.next(contact);
                },
            );
        });
    }

    protected onContactsUpdated() {}

    acceptContact(contact: T): Observable<any> {
        return new Observable(r => {
            this.appContext.inContactAPIService.acceptContact(contact.contactId).subscribe(
                res => {
                    //this.statusMsg = "Chat has been accepted";
                    r.next(contact);
                },

                error => {
                    //error => this.errorModel = error,
                    r.next(contact);
                },
            );
        });
    }

    rejectContact(contact: T): Observable<any> {
        return new Observable(r => {
            this.appContext.inContactAPIService
                .rejectContact(contact.contactId)
                .subscribe(
                    res => {
                        //this.statusMsg = "Chat has been rejected"
                        r.next(contact);
                    },
                    error => {
                        //this.errorModel = error
                        r.next(contact);
                    },
                )
                .add(() => {
                    r.complete();
                });
        });
    }

    snoozeContact(contact: T): Observable<any> {
        return new Observable(r => {
            this.appContext.inContactAPIService
                .snoozeAContact(contact.contactId)
                .subscribe(
                    res => {
                        //this.statusMsg = "Chat has been rejected"
                        r.next(contact);
                    },
                    error => {
                        //this.errorModel = error
                        r.next(contact);
                    },
                )
                .add(() => {
                    r.complete();
                });
        });
    }

    applyDisposition(contact: T, primaryCallbackNumber?: string, primaryCallbackTime?: string): Observable<T> {
        return new Observable(r => {
            this.appContext.inContactAPIService
                .setDisposition(
                    +contact.contactId,
                    contact.appliedDisposition.dispositionId,
                    contact.appliedDispositionComments,
                    contact.appliedSecondaryDisposition ? contact.appliedSecondaryDisposition.dispositionId : null,
                    contact.appliedSecondaryDisposition ? true : false,
                    primaryCallbackNumber,
                    primaryCallbackTime,
                )
                .subscribe(
                    result => {
                        r.next(contact);
                    },
                    error => {
                        r.next(contact);
                    },
                )
                .add(() => {
                    r.complete();
                });
        });
    }

    transferContact(contact: ContactBase, data: any): Observable<ContactBase> {
        return new Observable(r => {
            alert(`Transfer not implemented for ${contact.contactType} yet.`);
            r.next(contact);
        });
    }

    protected processIncomingContact(contact: T): Observable<any> {
        return new Observable(r => {
            this.sendRejectRequest = true;
            // if (contact.isIncoming()) {
            //     if (this.appContext.selectedCrmApplication?.applicationType === CRM_APPLICATION_TYPES.KUSTOMER) {
            //         this.appContext.crmIntegrationService.processIncomingCallContact(contact);
            //     }
            // }

            if (contact.isRequiredToBeAcceptedWhenIncoming()) {
                // this.acceptDialogRef = this.appContext.dialogService.confirmDialog(
                //     `Incoming ${contact.contactType}`,
                //     `<i class="fa fa-4x fa-${contact.contactIcon}"></i> <span contact="${contact.contactId}" >${contact.contactId}</span>`,
                //     'Accept',
                //     'Reject',
                //     `accept-contact-${contact.contactId}`,
                // );

                // this.acceptDialogRef.content.onClose.subscribe((res: boolean) => {
                //     if (res) {
                //         this.acceptContact(contact).subscribe();
                //     } else {
                //         if (this.sendRejectRequest) {
                //             this.rejectContact(contact).subscribe();
                //         }
                //     }
                // });

                this.acceptDialogRef = this.appContext.dialogService.confirmDialog(
                    `Incoming ${contact.contactType}`,
                    `<i class="fa fa-4x fa-${contact.contactIcon}"></i> <span contact="${contact.contactId}" >${contact.contactId}</span>`,
                    'Accept',
                    'Reject',
                    `accept-contact-${contact.contactId}`,
                );

                this.acceptDialogRef.result
                    .catch(err => {})
                    .then(result => {
                        //alert(`The result is: ${result}`)
                        //dialogRef.
                        if (result) {
                            this.acceptContact(contact).subscribe();
                        } else {
                            if (this.sendRejectRequest) {
                                this.rejectContact(contact).subscribe();
                            }
                        }
                    });
            }
            r.next(contact);
        });
    }

    protected openScreenPopUrl() {
        if (!!this.ScreenPopUrl && !this.ScreenPopUrl.includes('ScreenPopPrintVars')) {
            window.open(this.ScreenPopUrl, '_blank');
            window.focus();

            this.ScreenPopUrl = '';
        }
    }

    protected onBeforeContactUpdated(data: any): Observable<any> {
        data.newContact.transferDataFromExisting(data.existingContact);
        return of(data);
    }

    protected onAfterContactUpdated(data: any): Observable<any> {
        if (data.newContact.status == 'Active') {
            this.onContactActivated(data.newContact);

            this.openScreenPopUrl();
        } else if (data.newContact.isDisconnected() && data.newContact.finalState) {
            this.onContactFinished(data.newContact);
        }

        return of(data);
    }

    protected onContactActivated(contact: T) {
        const matched = this.currentContactViewModels.value.filter(x => x.contact.contactId == contact.contactId);
        for (let index = 0; index < matched.length; index++) {
            const vm = matched[index];
            try {
                console.log('processContact called');
                this.appContext.crmIntegrationService.processContact(vm);
            } catch (error) {
                console.error(error);
            }
            return;
        }
    }
    protected onContactFinished(contact: T) {
        const matched = this.currentContactViewModels.value.filter(x => x.contact.contactId == contact.contactId);
        for (let index = 0; index < matched.length; index++) {
            const vm = matched[index];
            try {
                this.appContext.crmIntegrationService.processFinishedContact(vm);
            } catch (error) {
                console.error(error);
            }
            try {
                vm.contact.tags = vm.contact.tagObjects.map(x => x.id.toString()).join(',');
                this.appContext.inContactAPIService.addTags(vm.contact.contactId, vm.contact.tags).subscribe();
            } catch (error) {
                console.error(error);
            }
            return;
        }
    }

    protected onBeforeNewContactAdded(newContact: T): Observable<any> {
        // console.log('before Contact adding...', newContact);
        // return new Observable(r => {
        //     r.next(newContact);
        //     // r.complete();
        // });

        return of(newContact);
    }

    protected onAfterNewContactAdded(newContact: any): Observable<any> {
        if (newContact.status == 'Active') {
            this.onContactActivated(newContact);
        }

        return of(newContact);

        // return new Observable(r => {
        //     if (newContact.status == 'Active') {
        //         this.onContactActivated(newContact);
        //     }

        //     r.next(newContact);
        //     // r.complete();
        // });
    }

    protected onBeforeContactRemoved: AsyncSubject<any> = new AsyncSubject<any>();

    protected onAfterContactRemoved: AsyncSubject<any> = new AsyncSubject<any>();

    public log(data: any) {
        console.log(data);
    }

    //#region contact actions

    public activateContact(contact: T): Observable<T> {
        return new Observable(r => {
            if (!contact.isActive()) {
                this.appContext.inContactAPIService.activateContact(contact.contactId).subscribe(
                    result => {
                        this.log(result);
                        r.next(contact);
                        r.complete();
                    },
                    err => {
                        this.handleContactError(err);
                        r.error(err);
                    },
                );
            } else {
                this.onContactActivated(contact);
                r.next(contact);
                r.complete();
            }
        });
    }

    public endContact(contact: T): Observable<T> {
        return new Observable(r => {
            this.endDialogRef = this.appContext.dialogService.confirmDialog(
                `${contact.skillName}`,
                `Are you sure you want to end contact: ${contact.contactId}`,
                'Yes',
                'No',
            );

            this.endDialogRef.result
                .catch(err => {
                    this.log(err);
                })
                .then((dialogResult: boolean) => {
                    if (dialogResult) {
                        this.appContext.inContactAPIService.endContact(contact.contactId).subscribe(
                            res => {
                                //this.statusMsg = "Chat has been ended"
                                r.next(contact);
                                this.currentContactViewModels.value
                                    .filter(x => x.contact.contactId == contact.contactId && x.preDeispositionSet)
                                    .map(x => {
                                        this.applyDisposition(x.contact).subscribe(r1 => {});
                                    });
                            },
                            error => {
                                r.next(contact);
                                this.handleContactError(error);
                                /* let errorModel = this.errorService.onSystemError(error);
                                    if (+errorModel.getErrorCode() === 404) {
                                        let chatRooms = this.currentContacts.getValue();
                                        chatRooms.forEach(element => {
                                            let index = chatRooms.findIndex(e => e.contactId === chat.contactId);
                                            this.stopTimer(chatRooms[index].chatMessageTimer);
                                            chatRooms.splice(index, 1);
                                            this.agentService.updateTotalActiveContact(-1);
                                        });
                                    } */
                            },
                        );
                    }
                });
            // .then(dialogRef => {
            //     this.endDialogRef = dialogRef;
            //     return dialogRef.result;
            // })
            // .catch(err => {
            //     this.log(err);
            // })
            // .then(dialogResult => {
            //     if (dialogResult) {
            //         this.appContext.inContactAPIService.endContact(contact.contactId).subscribe(
            //             res => {
            //                 //this.statusMsg = "Chat has been ended"
            //                 r.next(contact);
            //                 this.currentContactViewModels.value
            //                     .filter(x => x.contact.contactId == contact.contactId && x.preDeispositionSet)
            //                     .map(x => {
            //                         this.applyDisposition(x.contact).subscribe(r => {});
            //                     });
            //             },
            //             error => {
            //                 r.next(contact);
            //                 this.handleContactError(error);
            //                 /* let errorModel = this.errorService.onSystemError(error);
            //                 if (+errorModel.getErrorCode() === 404) {
            //                     let chatRooms = this.currentContacts.getValue();
            //                     chatRooms.forEach(element => {
            //                         let index = chatRooms.findIndex(e => e.contactId === chat.contactId);
            //                         this.stopTimer(chatRooms[index].chatMessageTimer);
            //                         chatRooms.splice(index, 1);
            //                         this.agentService.updateTotalActiveContact(-1);
            //                     });
            //                 } */
            //             },
            //         );
            //     }
            // });
        });
    }
    holdContact(contact: T): Observable<T> {
        return new Observable(r => {
            this.appContext.inContactAPIService
                .holdContact(contact.contactId.toString())
                .pipe(
                    map(res => {
                        //this.messageService.setCallMessage.next("Your call is on hold now.");
                    }),
                )
                .subscribe(
                    result => {
                        r.next(contact);
                    },
                    err => this.handleContactError(err),
                );
        });
    }

    resumeContact(contact: T): Observable<T> {
        return new Observable(r => {
            this.appContext.inContactAPIService
                .resumeContact(contact.contactId.toString())
                .pipe(
                    map(res => {
                        //this.messageService.setCallMessage.next("Your call is on hold now.");
                    }),
                )
                .subscribe(
                    result => {
                        r.next(contact);
                    },
                    err => this.handleContactError(err),
                );
        });
    }

    //#endregion

    executeServiceAction(action: string) {
        this.log(action);
    }

    elevateContact(data: any) {
        //alert("elevation is not implemented yet");
        switch (data.elevateTo) {
            case 'phone':
                this.appContext.inContactAPIService.dialPhone(data.poc, data.skillId, data.masterContactId).subscribe(
                    result => {
                        this.log(result);
                    },
                    err => this.handleContactError(err),
                );
                break;
            case 'email':
                this.appContext.inContactAPIService.emailOutbound(data.skillId, data.poc, data.masterContactId).subscribe(
                    result => {
                        this.log(result);
                    },
                    err => this.handleContactError(err),
                );
                break;
            default:
                break;
        }
    }

    protected handleContactError(error: any) {
        //this.appContext
        //console.log(error);
        this.appContext.messageService.notify({Subject: 'Error', MessageText: error.statusText, Type: 'error'});
    }

    public navigateTo(route: any, options: any = {}) {
        const me = this;
        window.clearTimeout(me.routerTimeout);
        me.routerTimeout = window.setTimeout(() => {
            me.appContext.appRouter.navigate(route, options);
        }, 1000);
    }

    public onServiceStarted() {}

    public onServiceStoped() {}
}

export class contactServiceAction {
    constructor(data: any) {
        this.action = data.action;
        this.text = data.text;
        this.icon = data.icon;
        if (data.isAvailable) {
            this.isAvailable = data.isAvailable;
        }
        if (data.isVisible) {
            this.isVisible = data.isVisible;
        }
    }

    icon: string;
    text: string;
    action: string;
    isAvailable: Function = () => true;
    isVisible: Function = () => true;
    public slot: string;
}
