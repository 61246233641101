import {Injectable, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

import {InContactAPIService} from '../../core/InContact/incontact-api.service';

import {AgentSkillAssignmentsModel} from '../../core/InContact/models/agent-skill-assignments.model';
import {AppToasterService} from 'src/app/plugins/ngx-toastr';
// import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty';

@Injectable()
export class AutomatedOutboundService {
    isPCSKillLoggedIn: boolean;

    constructor(
        private inContactAPIService: InContactAPIService,
        // private toastyService: ToastyService,
        // private toastyConfig: ToastyConfig,
        private tostrSrv: AppToasterService,
    ) {}

    ProcessingInstruction;

    loginPCSkill(skill: AgentSkillAssignmentsModel): Observable<any> {
        return this.inContactAPIService.loginPCSkill(skill.skillName);
    }

    logoutPCSkill(skill: AgentSkillAssignmentsModel): Observable<any> {
        return this.inContactAPIService.logoutPCSkill(skill.skillName);
    }

    proccessNaturalCallingSkillListEvent(event: any) {
        const me = this;
        if (event.Empty === 'False') {
            // var toastOptions: ToastOptions = {
            //     title: 'Personal Connection',
            //     msg: 'Record inventory for skill is running low.',
            //     showClose: true,
            //     timeout: 20000,
            //     //theme: 'default',
            //     onAdd: (toast: ToastData) => {
            //         //console.log('Toast ' + toast.id + ' has been added!');
            //     },
            //     onRemove: function (toast: ToastData) {
            //         //console.log('Toast ' + toast.id + ' has been removed!');
            //     },
            // };
            // me.toastyService.warning(toastOptions);

            this.tostrSrv
                .show('WARNING', 'Personal Connection', 'Record inventory for skill is running low.', undefined, 20000)
                .subscribe(res => {
                    console.log('toster response', res);
                });
        }
    }
}
