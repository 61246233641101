import {ContactBase} from './contact-base';

export class CallContactModel extends ContactBase {
    isLogging = false;
    complianceRecord: boolean;
    confirmationRequired: boolean;

    callType: string;
    ANI: string;
    DNIS: string;
    deliveryType: string;
    disconnectCode: string;

    isDialing = () => this.status == 'Dialing';
    isRinging = () => this.status == 'Ringing';
    canUnmask = () => this.status == 'Masking';
    canMask = () =>
        this.status != 'Masking' &&
        !((this.isRining() || this.isIncoming()) /* || this.isHolding() */) &&
        !this.isDisconnected() /*  && this.isLogging */;
    canRecord = () => /* this.isActive() && */ !this.isLogging;
    canAcceptConsult = () => this.callType == 'Consult' && (this.isRining() || this.isIncoming());

    constructor(data: any) {
        super(data);

        this.contactType = 'Call';
        this.contactIcon = 'phone-square';

        this.isLogging = data.IsLogging == 'True';
        this.ANI = data.ANI;
        this.callType = data.CallType;
        this.complianceRecord = data.ComplianceRecord == 'True';
        this.confirmationRequired = data.ConfirmationRequired == 'True';
        this.DNIS = data.DNIS;
        this.deliveryType = data.DeliveryType;
        this.disconnectCode = data.DisconnectCode;

        this.supportsPhoneElevation = false;
        this.supportsEmailElevation = true;

        const me = this;
    }

    public isRining(): boolean {
        return this.status === 'Ringing';
    }
}
