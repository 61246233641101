import {AfterViewInit, Component, HostBinding, OnInit} from '@angular/core';

@Component({
    selector: 'app-default-layout',
    templateUrl: './app-default-layout.component.html',
    styleUrls: ['./app-default-layout.component.scss'],
})
export class AppDefaultLayoutComponent implements OnInit, AfterViewInit {
    @HostBinding('class') classes = 'app-layout';
    constructor() {}

    ngOnInit() {}

    ngAfterViewInit() {}
}
