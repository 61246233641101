import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {ErrorModel} from '.././_models/error.model';
import {HttpResponse} from '@angular/common/http';

import {MessageService} from './message.service';
import {ApplicationContextService} from './application-context.service';

@Injectable()
export class ErrorService {
    public inContactError = new Subject<ErrorModel>();

    constructor(private messageService: MessageService) {
        this.inContactError.subscribe(err => {
            if (err) {
                if (err.getErrorDescription() !== '') {
                    const msg = err.getErrorDescription();
                    this.messageService.notify({Type: 'error', Subject: 'Error', MessageText: msg});
                }
            }
        });
    }

    clearError() {
        this.inContactError.next(null);
    }

    onSystemError(error: any): ErrorModel {
        let msg = 'Unknown page error.';
        let status;

        if (error !== null || error! == undefined) {
            if (error instanceof HttpResponse) {
                // const body = error;
                // const err = body.error || JSON.stringify(body);

                status = error.status;
                msg = ` ${error.status} - ${error.statusText || ''}`;

                if (error.status === 0) {
                    msg = 'Connection failed. If the error continues contact your System Administrator.';
                }
                if (error.statusText === 'Invalid state.')
                    {this.messageService.setCallMessage2.next(
                        'You already have an active call. Please put that call on hold first.',
                    );}
            } else {
                status = error.message ? error.message : error.toString();

                msg = error.message ? error.message : error.toString();
                if (!!error.error?.error_description) {
                    msg = error.error.error_description;
                }
            }

            //this.messageService.notify({ type: "error", MessageText: msg });

            const errorModel = new ErrorModel(status, msg);
            this.inContactError.next(errorModel);

            return errorModel;
        }
    }

    onAPIError(errorModel) {
        this.inContactError.next(errorModel);
        return errorModel;
    }
}
