<form #callHistoryForm="ngForm" (ngSubmit)="saveSchedule()" class="form-horizontal">
    <div class="page-heading">
        <ul class="nav nav-pills">
            <li>
                <h4>
                    <i
                        class="fa fa-tty fa-lg"
                        aria-hidden="true"
                        title="{{ 'App_Component.Footer.CallHistory' | translate }}"
                    >
                    </i
                    >&nbsp;{{ 'App_Component.Footer.Callbacks' | translate }}
                </h4>
            </li>
            <li style="flex-grow: 1"></li>
            <li>
                <button
                    class="btn btn-xs btn-circle btn-default"
                    title="Save"
                    style="margin: 2px; margin-top: 0px"
                    type="submit"
                >
                    <i class="fa fa-save"></i>
                </button>
            </li>
            <li *ngIf="this.model.callbackId && this.deleteCommitmentId > 1">
                <button class="btn btn-xs btn-danger btn-circle" title="Remove" type="button" (click)="askRemove()">
                    <i class="fa fa-window-close"></i>
                </button>
            </li>
        </ul>
    </div>

    <div class="page-content" style="top: 70px">
        <div class="row">
            <div class="col-xs-6">
                <input
                    type="text"
                    [(ngModel)]="model.firstName"
                    placeholder="First Name"
                    name="firstName"
                    aria-placeholder="First Name"
                    class="form-control"
                />
            </div>
            <div class="col-xs-6">
                <input
                    type="text"
                    [(ngModel)]="model.lastName"
                    name="lastName"
                    class="form-control"
                    placeholder="Last Name"
                    aria-placeholder="Last Name"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <input
                    type="tel"
                    [(ngModel)]="model.phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    aria-placeholder="Phone Number"
                    class="form-control"
                    required
                />
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <ul class="nav nav-pills">
                    <li>
                        <h5>&nbsp;Scheduled For&nbsp;</h5>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="btn"
                            (click)="setScheduleType('Me')"
                            [ngClass]="model.scheduleType == 'Me' ? 'btn-primary' : 'btn-default'"
                        >
                            Me
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="btn"
                            (click)="setScheduleType('Skill')"
                            [ngClass]="model.scheduleType == 'Skill' ? 'btn-primary' : 'btn-default'"
                        >
                            Skill
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <select
                    class="form-control"
                    [(ngModel)]="model.skillId"
                    name="skillId"
                    id="skillId"
                    placeholder="Skill"
                    required
                >
                    <option *ngFor="let skill of outboundSkills" value="{{ skill.skillId }}">
                        {{ skill.skillName }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <input [(ngModel)]="model.date" name="date" class="form-control" type="date" required />
            </div>

            <div class="col-xs-6 nopadding pull-right">
                <ul class="nav nav-pills">
                    <li>
                        <input
                            type="number"
                            max="12"
                            min="1"
                            class="time-bit"
                            name="hour"
                            [(ngModel)]="model.hour"
                            step="1"
                        />
                        <!-- <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                {{model.hour}}
                                <span class="caret"></span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenu1">

                                <div class="container" style="width:200px;">
                                    <div class="row" style="margin:0px;">
                                        <ul class="nav nav-pills nav-justified">
                                            <li><button type="button" (click)="setHour('01')"
                                                    class="btn btn-default">01</button></li>
                                            <li><button type="button" (click)="setHour('02')"
                                                    class="btn btn-default">02</button></li>
                                            <li><button type="button" (click)="setHour('03')"
                                                    class="btn btn-default">03</button></li>
                                            <li><button type="button" (click)="setHour('04')"
                                                    class="btn btn-default">04</button></li>
                                        </ul>
                                    </div>
                                    <div class="row" style="margin:0px;">
                                        <ul class="nav nav-pills nav-justified">
                                            <li><button type="button" (click)="setHour('05')"
                                                    class="btn btn-default">05</button></li>
                                            <li><button type="button" (click)="setHour('06')"
                                                    class="btn btn-default">06</button></li>
                                            <li><button type="button" (click)="setHour('07')"
                                                    class="btn btn-default">07</button></li>
                                            <li><button type="button" (click)="setHour('08')"
                                                    class="btn btn-default">08</button></li>
                                        </ul>
                                    </div>

                                    <div class="row" style="margin:0px;">
                                        <ul class="nav nav-pills nav-justified">
                                            <li><button type="button" (click)="setHour('09')"
                                                    class="btn btn-default">09</button></li>
                                            <li><button type="button" (click)="setHour('10')"
                                                    class="btn btn-default">10</button></li>
                                            <li><button type="button" (click)="setHour('11')"
                                                    class="btn btn-default">11</button></li>
                                            <li><button type="button" (click)="setHour('12')"
                                                    class="btn btn-default">12</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </li>
                    <li>
                        <input
                            type="number"
                            value="0"
                            step="1"
                            class="time-bit"
                            name="minute"
                            [(ngModel)]="model.minute"
                            max="59"
                            min="0"
                        />
                        <div *ngIf="false" class="dropdown">
                            <button
                                class="btn btn-default dropdown-toggle"
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                            >
                                {{ model.minute }}
                                <span class="caret"></span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                <div class="container" style="width: 200px">
                                    <div class="row" style="margin: 0px">
                                        <ul class="nav nav-pills nav-justified">
                                            <li>
                                                <button type="button" (click)="setMinute('00')" class="btn btn-default">
                                                    00
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="setMinute('05')" class="btn btn-default">
                                                    05
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="setMinute('10')" class="btn btn-default">
                                                    10
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="setMinute('15')" class="btn btn-default">
                                                    15
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="row" style="margin: 0px">
                                        <ul class="nav nav-pills nav-justified">
                                            <li>
                                                <button type="button" (click)="setMinute('20')" class="btn btn-default">
                                                    20
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="setMinute('25')" class="btn btn-default">
                                                    25
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="setMinute('30')" class="btn btn-default">
                                                    30
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="setMinute('35')" class="btn btn-default">
                                                    35
                                                </button>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="row" style="margin: 0px">
                                        <ul class="nav nav-pills nav-justified">
                                            <li>
                                                <button type="button" (click)="setMinute('40')" class="btn btn-default">
                                                    40
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="setMinute('45')" class="btn btn-default">
                                                    45
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="setMinute('50')" class="btn btn-default">
                                                    50
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="setMinute('55')" class="btn btn-default">
                                                    55
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown">
                            <button
                                class="btn btn-default dropdown-toggle"
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                                style="padding: 3px; margin: 2px"
                            >
                                {{ model.ampm }}
                                <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                <li><a (click)="setAMPM('AM')">AM</a></li>
                                <li><a (click)="setAMPM('PM')">PM</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <select
                    [(ngModel)]="displayTimezone"
                    class="form-control"
                    name="timezone"
                    id=""
                    placeholder="Time Zone"
                    (change)="onTimezoneSelected($event)"
                >
                    <option *ngFor="let timezone of timezones" [value]="timezone.standardName">
                        {{ timezone.displayName }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <textarea [(ngModel)]="model.notes" class="form-control" name="notes" id="notes" rows="5"></textarea>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-xs-12"></div>
        </div>
    </div>
</form>

<!-- <modal #removeDialog>
    <form #deletCommitmentForm="ngForm" (submit)="removeCommitment($event)">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Remove Commitment?</h4>
        </div>
        <div class="modal-body">
            <h3>Are you sure you want to remove this commitment?</h3>
            <div style="display: flex;">
                <textarea
                    style="flex: 1;"
                    name="notes"
                    [(ngModel)]="deleteCommitmentNotes"
                    [attr.required]="deleteCommitmentId == 2 ? '' : null"
                    placeholder="Notes"
                ></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-danger"
                [disabled]="deleteCommitmentId == 2 && (!deleteCommitmentNotes || deleteCommitmentNotes.length == 0)"
                type="submit"
            >
                Remove
            </button>
            <button class="btn btn-default" (click)="removeDialog.close()">Cancel</button>
        </div>
    </form>
</modal> -->

<ng-template #removeDialog>
    <div class="modal-header">
        <button type="button" class="close" (click)="removeDialogRef.close()" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Remove Commitment?</h4>
    </div>
    <div class="modal-body">
        <form #deletCommitmentForm="ngForm" (submit)="removeCommitment($event)">
            <!-- <div class="modal-body"> -->
            <h3>Are you sure you want to remove this commitment?</h3>
            <div style="display: flex">
                <textarea
                    style="flex: 1"
                    name="notes"
                    [(ngModel)]="deleteCommitmentNotes"
                    [attr.required]="deleteCommitmentId == 2 ? '' : null"
                    placeholder="Notes"
                ></textarea>
            </div>
            <!-- </div> -->
            <div style="display: flex; justify-content: space-between; margin-top: 10px">
                <button
                    class="btn btn-danger"
                    [disabled]="
                        deleteCommitmentId == 2 && (!deleteCommitmentNotes || deleteCommitmentNotes.length == 0)
                    "
                    type="submit"
                >
                    Remove
                </button>
                <button type="button" class="btn btn-default" data-dismiss="modal" (click)="removeDialogRef.close()">
                    Reject
                </button>
                <!-- <button type="button" class="btn btn-primary" (click)="accept()">Accept</button> -->
            </div>
        </form>
    </div>
</ng-template>
