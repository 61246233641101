<div class="page-heading">
    <ul class="nav nav-pills">
        <li *ngIf="false">
            <h4>Address Book</h4>
        </li>
        <li>
            <div class="col-xs-12 col-md-12 nopadding">
                <div class="input-group zac-input-group">
                    <span class="input-group-addon"><i class="fa fa-filter" aria-hidden="true"></i></span>
                    <select
                        [focus]="dropdownFocus"
                        name="TypeFilter"
                        #TypeFilter="ngModel"
                        [ngModel]="selectedOption"
                        (ngModelChange)="onChange($event)"
                        class="form-control"
                    >
                        <option *ngFor="let option of options" [ngValue]="option">
                            {{ option.optionName }}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="selectedOption.optionName === 'Agents'" class="col-xs-12 col-md-12 nopadding">
                <div class="input-group zac-input-group">
                    <span class="input-group-addon"><i class="fa fa-filter" aria-hidden="true"></i></span>
                    <select
                        name="StatusFilter"
                        #StatusFilter="ngModel"
                        [ngModel]="selectedStatus"
                        (ngModelChange)="onStatusChange($event)"
                        class="form-control"
                    >
                        <option value="All">All</option>
                        <option value="Logged In">Logged In</option>
                        <option value="1">Available</option>
                        <option value="2">Unavailable</option>
                    </select>
                </div>
            </div>
            <div class="col-xs-12 col-md-12 nopadding">
                <div class="input-group zac-input-group">
                    <span class="input-group-addon" id="basic-addon1"><i class="fa fa-search"></i></span>
                    <input
                        type="text"
                        accesskey="S"
                        class="form-control"
                        [(ngModel)]="keyword"
                        name="inputKeyword"
                        #inputKeyword="ngModel"
                        placeholder="{{ 'AddressBook.SearchPlaceholder' | translate }}"
                    />
                    <span *ngIf="false" class="input-group-btn">
                        <a class="btn btn-default"><i class="fa fa-search" aria-hidden="true"></i></a>
                    </span>
                </div>
            </div>
        </li>
    </ul>
</div>
<div class="page-content" style="top: 160px;">
    <app-agents
        keyword="{{ keyword }}"
        *ngIf="selectedOption.optionName === 'Agents'"
        [selectedStatus]="selectedStatus"
    >
    </app-agents>

    <!-- ********************  Skills -->

    <app-skills keyword="{{ keyword }}" *ngIf="selectedOption.optionName === 'Skills'"></app-skills>

    <!-- ******************* Address Book Contacts -->

    <app-address-book-contacts
        *ngIf="selectedOption.optionName !== 'Agents' && selectedOption.optionName !== 'Skills'"
        [addressBookId]="selectedOption.optionId"
        type="{{ selectedOption.type }}"
        keyword="{{ keyword }}"
    >
    </app-address-book-contacts>
</div>
<div *ngIf="false" class="panel panel-default panel-top-most">
    <!-- Default panel contents -->
    <div role="heading" aria-level="3" class="panel-heading">
        <i class="fa fa-address-card-o fa-lg" aria-hidden="true"></i>&nbsp; {{ 'AddressBook.Title' | translate }}
    </div>
    <div class="panel-body">
        <div class="form-horizontal">
            <div class="form-group">
                <div class="col-md-6 col-md-offset-3">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-filter" aria-hidden="true"></i></span>
                        <select
                            [focus]="dropdownFocus"
                            name="TypeFilter"
                            #TypeFilter="ngModel"
                            [ngModel]="selectedOption"
                            (ngModelChange)="onChange($event)"
                            class="form-control"
                        >
                            <option *ngFor="let option of options" [ngValue]="option">
                                {{ option.optionName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6 col-md-offset-3">
                    <div class="input-group">
                        <input
                            type="text"
                            accesskey="S"
                            class="form-control"
                            [(ngModel)]="keyword"
                            name="inputKeyword"
                            #inputKeyword="ngModel"
                            placeholder="{{ 'AddressBook.SearchPlaceholder' | translate }}"
                        />
                        <span class="input-group-btn">
                            <a class="btn btn-default"><i class="fa fa-search" aria-hidden="true"></i></a>
                        </span>
                    </div>
                    <!-- /input-group -->
                </div>
            </div>

            <div *ngIf="selectedOption.optionName === 'Agents'" class="form-group">
                <div class="col-md-6 col-md-offset-3">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-filter" aria-hidden="true"></i></span>
                        <select
                            name="StatusFilter"
                            #StatusFilter="ngModel"
                            [ngModel]="selectedStatus"
                            (ngModelChange)="onStatusChange($event)"
                            class="form-control"
                        >
                            <option value="All">All</option>
                            <option value="Logged In">Logged In</option>
                            <option value="1">Available</option>
                            <option value="2">Unavailable</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!---  ***************** Address Book **************************** -->
        <!--<div   class="panel-scrollable">-->

        <app-agents
            keyword="{{ keyword }}"
            *ngIf="selectedOption.optionName === 'Agents'"
            [selectedStatus]="selectedStatus"
        >
        </app-agents>

        <!-- ********************  Skills -->

        <app-skills keyword="{{ keyword }}" *ngIf="selectedOption.optionName === 'Skills'"></app-skills>

        <!-- ******************* Address Book Contacts -->
        <app-address-book-contacts
            *ngIf="selectedOption.optionName !== 'Agents' && selectedOption.optionName !== 'Skills'"
            [addressBookId]="selectedOption.optionId"
            type="{{ selectedOption.type }}"
            keyword="{{ keyword }}"
        >
        </app-address-book-contacts>
    </div>
</div>
