import {AnyObject} from 'src/app/global';
import {DynamicsSettingsModel} from './dynamics-settings.model';
import {LookupModel} from './lookup.model';

export class CompanyModel {
    active: boolean;
    apiKey: string;
    contactMappings: AnyObject;
    contactSettings: AnyObject;
    defualtCRM: string;
    dynamicsSettings: DynamicsSettingsModel;
    email: string;
    id: number;
    inContactBusinessUnit: AnyObject;
    inContactSettings: AnyObject;
    phone: string;
    protected: boolean;
    title: string;
    webRTCEnabled: boolean;

    settings?: Array<LookupModel>;

    constructor(data?: Partial<CompanyModel>) {
        Object.assign(this, data);
    }

    public get getClientId() {
        return this.settings?.find(x => x.key === 'incontact-client-id')?.value ?? '';
    }
}
