import {Injectable} from '@angular/core';

@Injectable()
export class PageOpenService {
    private openedWindows: any = {};

    constructor() {}

    handlePageOpenEvent(pageOpenEvent: any) {
        switch (pageOpenEvent.Action) {
            case 'OPEN':
                //this.processSpawnScriptIndicator(indicator);
                const win = window.open(pageOpenEvent.PageUri);
                this.openedWindows[pageOpenEvent.ContactID] = win;
                break;
            case 'CLOSE':
                //this.processOpenUrlIndicator({ ActionUri: indicator.action });
                const winc = this.openedWindows[pageOpenEvent.ContactID];
                if (winc) {
                    winc.close();
                }
                delete this.openedWindows[pageOpenEvent.ContactID];
                break;
        }
    }
}
